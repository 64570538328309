import React, { FC } from 'react';
import { Box, ButtonGroup, Flex, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCells } from '@fortawesome/pro-regular-svg-icons';
import { faListUl } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SearchInput } from '../../../../atoms';
import Dropdown from '../../../../atoms/Dropdown';

interface IProps {
  viewType: 'grid' | 'list';
  onViewChange: (viewType: 'grid' | 'list') => void;
  searchQuery: string;
  onSearchChange?: (searchQuery: string) => void;
}

const LibraryHeader: FC<IProps> = ({
  viewType,
  onViewChange,
  searchQuery,
  onSearchChange,
}) => {
  return (
    <Flex gap={4}>
      <SearchInput
        placeholder='Search training, safety etc'
        hideShortcuts
        value={searchQuery}
        onChange={(e) => onSearchChange?.(e.target.value)}
      />
      {/*<Box>*/}
      {/*  <Dropdown*/}
      {/*    isSearchable={false}*/}
      {/*    placeholder='Filters'*/}
      {/*    inputStyle={{*/}
      {/*      width: '170px',*/}
      {/*      cursor: 'pointer',*/}
      {/*      backgroundColor: 'white',*/}
      {/*    }}*/}
      {/*    options={[*/}
      {/*      { value: 'template1', label: 'Template 1' },*/}
      {/*      { value: 'template2', label: 'Template 2' },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*</Box>*/}

      <ButtonGroup isAttached>
        <IconButton
          aria-label='list'
          aria-checked={viewType === 'list'}
          onClick={() => onViewChange('list')}
          borderRightWidth={0}
          color='#6F767E'
          _checked={{ color: 'white', borderColor: 'blue.500' }}
          variant={viewType === 'list' ? 'solid' : 'outline'}
          colorScheme={viewType === 'list' ? 'blue' : 'gray'}
          borderWidth='1px 0px 1px 1px'
        >
          <FontAwesomeIcon fontSize='20px' icon={faListUl as IconProp} />
        </IconButton>
        <IconButton
          aria-label='grid'
          aria-checked={viewType === 'grid'}
          onClick={() => onViewChange('grid')}
          borderLeftWidth={0}
          color='#6F767E'
          _checked={{ color: 'white', borderColor: 'blue.500' }}
          variant={viewType === 'grid' ? 'solid' : 'outline'}
          colorScheme={viewType === 'grid' ? 'blue' : 'gray'}
          borderWidth='1px 1px 1px 0px'
        >
          <FontAwesomeIcon fontSize='22px' icon={faTableCells as IconProp} />
        </IconButton>
      </ButtonGroup>
    </Flex>
  );
};

export default LibraryHeader;
