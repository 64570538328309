import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Pagination, PaginationProps } from 'antd';

export const paginationStyles = css`
  padding-top: 4px;
  .ant-pagination-item {
    border: none;
    border-radius: 5px;

    a {
      font-size: 14px;
      font-weight: 700;
      color: #231f20;
    }

    &.ant-pagination-item-active {
      background: #2a85ff;

      a {
        color: white;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
    &.ant-pagination-disabled {
      button {
        opacity: 0.4;
      }
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-link-icon {
      color: #2a85ff;
    }
  }

  .ant-pagination-next {
  }
`;

const AntdPagination = styled(Pagination)`
  ${paginationStyles}
`;

export { AntdPagination };

export type { PaginationProps };
