import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';

import { HeaderColors } from '../../../../configs';
import { useCountdown } from '../../../../hooks';
import { AppRoute, useHistory } from '../../../../routes';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';

interface IProps {
  name: string;
  date: string;
}

interface CProps extends IProps {
  onTimeEnd: Function;
}

const Content: FC<CProps> = ({ name, date, onTimeEnd }) => {
  useEffect(() => {}, []);

  const [_, remaining] = useCountdown({
    leftTime: 6000,
    onEnd: () => onTimeEnd?.(),
  });

  return (
    <Flex flexDir='column' gap={4} pb='4px'>
      <Box fontSize='15px' fontWeight='600' color='#6F767E'>
        This audit was paused by the {name} {moment(date).fromNow()}. Hence the
        session has been made inactive. Contact your team for further details
      </Box>
      <Box fontSize='15px' fontWeight='600' color='#1A1D1F'>
        Redirecting to home page in {remaining.seconds} secs
      </Box>
    </Flex>
  );
};

type AuditPausedWarning = (props: IProps) => void;

export const useAuditPausedWarning = (): AuditPausedWarning => {
  const history = useHistory();
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    const onTimeEnd = () => {
      setTimeout(() => confirmRef.current?.destroy());
      history.replace({
        pathname: AppRoute.AUDIT_LIST,
      });
    };

    confirmRef.current = confirm({
      title: (
        <BoxHeader
          color={HeaderColors.Purple}
          title={`Audit paused by ${props.name}`}
        />
      ),
      content: <Content {...props} onTimeEnd={onTimeEnd} />,

      isCentered: true,
      contentProps: {
        maxW: '620px',
        paddingTop: '4px',
        paddingBottom: '12px',
      },
      footer: null,
    });
  }, []);
};
