import { gql, useLazyQuery } from '@apollo/client';
import { GET_AUDITS_LIST } from '../../audit-tab.graphql';
import {
  AuditPaginateResponse,
  AuditPaginateVariables,
  AuditSessionPaginateResponse,
  AuditSessionPaginateVariables,
} from '../../supervised/types';
import { ServiceLayer } from '../../supervised/types';

export const GET_MY_AUDITS = gql`
  query GetMyAudits(
    $filter: FilterFindManyAuditSessionInput
    $page: Int
    $perPage: Int
    $locationIds: [String]
  ) {
    AuditSessionPagination(filter: $filter, page: $page, perPage: $perPage) {
      items {
        assignments(locationIds: $locationIds) {
          auditors
          comments
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          maxScore
          reportsUrl
          response
          startedBy
          totalFlagged
          totalScore
        }
        audit {
          status
        }
        eid
        name
        isCompleted
        completedAt
        title
        auditId
        entityId
        repeatCycle
        dueDate
        startDate
        auditType
        questions
        auditors {
          eid
          name
          profilePic
        }
      }
    }
  }
`;

export const useServiceLayer = (): ServiceLayer => {
  const [getAuditsList, getAuditsListResult] = useLazyQuery<
    AuditPaginateResponse,
    AuditPaginateVariables
  >(GET_AUDITS_LIST, {
    fetchPolicy: 'network-only',
  });
  const [getAuditSessionList, getAuditSessionListResult] = useLazyQuery<
    AuditSessionPaginateResponse,
    AuditSessionPaginateVariables
  >(GET_MY_AUDITS, {
    fetchPolicy: 'network-only',
  });
  return {
    auditsList: { getAuditsList, getAuditsListResult },
    auditsSessionList: { getAuditSessionList, getAuditSessionListResult },
  };
};
