import React, { createContext, FC, useContext, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { createUploadStore, UploadAction } from './upload.store';
import { toArray } from '../../../../../utils';
import { useStore } from '../../../../../zustandStore';
import { useUploadImage } from '../../../../../hooks';

type UploadStore = ReturnType<typeof createUploadStore>;

const UploadContext = createContext<UploadStore>({} as never);

interface ProviderProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

const ImageUploadProvider: FC<ProviderProps> = ({
  children,
  value,
  onChange,
}) => {
  const storeRef = useRef<UploadStore>();

  const imageUpload = useUploadImage();

  useEffect(() => {
    if (storeRef.current) {
      storeRef.current.setState({
        value: toArray(value),
      });
    }
  }, [value]);

  if (!storeRef.current) {
    storeRef.current = createUploadStore({
      uploadAction: imageUpload,
      onChange: onChange,
    });
  }

  return (
    <UploadContext.Provider value={storeRef.current}>
      <Flex gap={3} flexWrap='wrap'>
        {children}
      </Flex>
    </UploadContext.Provider>
  );
};

export function useUploadContext<T>(selector: (state: UploadAction) => T): T {
  const store = useContext(UploadContext);
  if (!store) throw new Error('Missing UploadContext.Provider in the tree');
  return useStore(store, selector);
}

export default ImageUploadProvider;
