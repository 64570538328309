import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Authorize, AuthRole } from '../../../../authorization';
import { AppRoute, useRouteState } from '../../../../routes';
import { Button } from '../../../../atoms';
import EmptyState from '../../../EmptyState';

interface IProps {
  searchActive?: boolean;
}

const EmptyMyTemplates: FC<IProps> = ({ searchActive }) => {
  const history = useHistory();

  const { updateState } = useRouteState('template', {
    templateView: 'template',
  });

  if (searchActive) {
    return (
      <EmptyState
        image='Search'
        imageSize='160px'
        title='Hmm, couldn’t find a template with that name'
        description='Try searching for it in the template library'
        showButton
        buttonProps={
          <Box paddingTop={3}>
            <Button onClick={() => updateState({ templateView: 'library' })}>
              Go to template library
            </Button>
          </Box>
        }
      />
    );
  }

  return (
    <EmptyState
      image='Form'
      imageSize='200px'
      title='It all starts with a template'
      description='Choose from our vast library of templates and customize it or create your own'
      showButton
      buttonProps={
        <Flex gap={3} paddingTop={3}>
          <Button
            variant='outline'
            onClick={() => updateState({ templateView: 'library' })}
          >
            Find a template
          </Button>
          <Authorize
            permittedFor={'user'}
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <Button
              colorScheme='blue'
              onClick={() => history.push(AppRoute.CREATE_AUDIT_TEMPLATE)}
            >
              Create your own
            </Button>
          </Authorize>
        </Flex>
      }
    />
  );
};

export default EmptyMyTemplates;
