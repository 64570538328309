import React, { FC } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFlag } from '@fortawesome/pro-solid-svg-icons';
import { SectionEntity } from '../../../../../../sub-components/audits/take-audit/types';

const AccordionWrapper = styled.div`
  width: 100%;
  .chakra-accordion__item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .chakra-accordion__item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

interface IProps {
  questionData: SectionEntity[];
}

const AccordionSection: FC<IProps> = ({ questionData }) => {
  return (
    <AccordionWrapper>
      <Accordion allowToggle allowMultiple w='full'>
        {questionData?.map((question, index) => {
          return (
            <AccordionItem bg='rgba(239, 239, 239, 1)' key={index}>
              <AccordionButton justifyContent='space-between'>
                <Flex align='center' gap='5px'>
                  <Box as='span' flex='1' textAlign='left' fontWeight={600}>
                    {`${index + 1}. ${question?.sectionName}`}
                  </Box>
                  {question?.isSectionFlagged && (
                    <FontAwesomeIcon
                      icon={faFlag as IconProp}
                      color='rgba(255, 106, 85, 1)'
                    />
                  )}
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel bg='white'>
                <VStack divider={<StackDivider />}>
                  {question?.data?.map((_data: any, qIndex) => {
                    return (
                      <Flex
                        w='full'
                        justify='space-between'
                        key={qIndex}
                        cursor='pointer'
                      >
                        <Box>{_data?.label}</Box>
                        <FontAwesomeIcon icon={faArrowRight as IconProp} />
                      </Flex>
                    );
                  })}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AccordionSection;
