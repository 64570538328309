import { useReactiveVar } from '@apollo/client';
import { useUserDataSelector } from 'hooks';
import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import { AuditSessionEntity } from '../../supervised/types';
import { auditStatus } from '../../supervised/utils/processData';
import { ProcessData } from '../types';

interface IProps {
  data: AuditSessionEntity[];
}

type IProcessData = (props?: IProps) => ProcessData[];

export const useProcessData = (): IProcessData => {
  const entityUsers = useReactiveVar(usersEntityObj);
  const loggedInUserEid = useUserDataSelector((state) => state?.eid);
  return useCallback(
    (props) => {
      let processData: ProcessData[] = [];
      props?.data
        ?.filter(
          (audit) =>
            audit?.audit?.status !== 'AUDIT_DELETED' &&
            audit?.audit?.status !== 'AUDIT_PAUSED' &&
            audit?.audit?.status !== 'AUDIT_TERMINATED'
        )
        ?.map((auditData) => {
          let assignedLocations: ProcessData['assignedLocations'] = [];
          let auditDueDate = auditData?.dueDate;
          let totalAssignments = auditData?.assignments?.length || 0;
          auditData?.assignments
            ?.filter((assignment) =>
              assignment?.auditors?.includes(loggedInUserEid)
            )
            ?.map((assignment) => {
              let isAuditStarted = !!assignment?.startedBy;
              entityUsers?.map((entity) => {
                if (entity?.eid === assignment?.locationId) {
                  assignedLocations?.push({
                    auditAction: status as string,
                    auditSessionId: auditData?.eid,
                    auditAssignedOn: auditData?.startDate,
                    auditExpectedToFinish: auditData?.dueDate,
                    auditStartedBy: assignment?.startedBy,
                    auditStatus: auditStatus(
                      assignment?.isCompleted,
                      auditDueDate,
                      isAuditStarted
                    ),
                    locationName: entity?.name,
                    locationId: entity?.eid,
                    reportsUrl: assignment?.reportsUrl,
                  });
                  return;
                }
              });
            });
          processData.push({
            key: '',
            assignedLocations,
            auditTitle: auditData?.title,
            auditSessionId: auditData?.eid,
            auditId: auditData?.auditId,
            totalAssignments,
          });
        });
      return processData;
    },
    [entityUsers]
  );
};
