import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { Any } from '../../../../../types';
import { QuestionsState, SectionEntity } from '../../types';
import { ShortText } from '../question-types';
import { AuditTake } from '../../tabke-audit.helpers';
import { responseIdValidate } from './input.validator';

interface IProps {
  question: QuestionsState;
  sectionIndex: number;
  questionIndex: number;
}

const ShortTextSection: FC<IProps> = ({
  question,
  sectionIndex,
  questionIndex,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveHandler = (...args: Any) => {
    AuditTake.save(`data.${questionIndex}`, question.eid);
  };

  return (
    <Controller<SectionEntity, `data.${number}.response`>
      name={`data.${questionIndex}.response`}
      rules={{
        required: {
          value: question?.isRequired,
          message: 'This field is required',
        },
        maxLength: {
          value: 100,
          message: 'This field allows a maximum of 100 characters.',
        },
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <ShortText
            value={field.value}
            onChange={field.onChange}
            onBlur={saveHandler}
          />
          <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default ShortTextSection;
