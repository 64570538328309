import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from '@apollo/client/utilities';
import produce from 'immer';
import { Any } from 'types';
import { createAppStore } from 'zustandStore';
import { createJSONStorage, persist } from 'zustandStore/middleware';
import { toArray } from '../utils';

type SetStateAction<S> = Partial<S> | ((prevState: S) => Partial<S>);

type Dispatch<A> = (value: A, reset?: boolean) => void;
type ResetDispatch<A> = (value?: A) => void;

function parseValue<S>(value: S, item: Any) {
  return typeof value === 'function' ? value(cloneDeep(item)) : value;
}

const parseArgument = <S>(_argument: IArguments): [S, string | undefined] => {
  if (_argument?.length === 1) {
    // @ts-ignore
    return [_argument[0], undefined] as const;
  } else if (_argument?.length === 2 && _argument[1]) {
    // @ts-ignore
    return [_argument[1], _argument[0]] as const;
  }
  throw Error('Please specify a valid argument to useRouteState');
};

interface State {
  localState: Record<string, Any>;
  set: (callback: (state: State) => void) => void;
}

const _useRouteState = createAppStore<State>()(
  persist(
    (set) => ({
      localState: {},
      set: (fn) => set(produce(fn)),
    }),
    {
      name: '@route-state-delightree',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

interface RouteState<S> {
  state: S;
  updateState: Dispatch<SetStateAction<S>>;
  resetState: ResetDispatch<SetStateAction<S>>;
  resetChildState: ResetDispatch<string>;
}

function useRouteState<S extends Record<string, unknown>>(
  suffixKey: string,
  initialValue: S
): RouteState<S>;

// eslint-disable-next-line no-redeclare
function useRouteState<S extends Record<string, unknown>>(
  initialValue: S
): RouteState<S>;

/**
 * use useRouteState hook to store page data like sorting, pagination, etc.
 *
 * All the stored data will be persisted 💾 during session for the page
 *
 *
 * @example
 * import { useRouteState } from "routes";
 *
 *const {state, updateState} = useRouteState({
 *  page: 1,
 *  sortBy: 'ASC',
 *});
 *
 * const {state, updateState} = useRouteState('response', {
 *  page: 1,
 *  sortBy: 'ASC',
 * });
 *
 * // update state method 1
 * updateState({
 *   page: 2
 * })
 *
 * // update state method 2
 * updateState(prevState => {
 *   return {
 *     page: prevState.page + 1
 *   }
 * })
 *
 * */
// eslint-disable-next-line no-redeclare
function useRouteState<S extends Record<string, unknown>>(): RouteState<S> {
  const mountedRef = useRef(false);
  const locationKey = useLocation().key || '@delightree';

  const [initialValue, suffixKey] = parseArgument(arguments);

  const _innerKey = suffixKey ? `${locationKey}@@${suffixKey}` : locationKey;

  const _state = _useRouteState<S>((store) => store.localState[_innerKey]);
  const _setState = _useRouteState((store) => store.set);

  useEffect(() => {
    if (!mountedRef.current) {
      _setState((state) => {
        const item = state.localState[_innerKey];

        if (!item) {
          state.localState[_innerKey] = initialValue || {};
        }
      });

      mountedRef.current = true;
    }
  }, []);

  const resetChildState = useCallback(
    (childKey?: string | string[]) => {
      const _childKeys = toArray(childKey);
      if (_childKeys.length) {
        _setState((prevState) => {
          for (const _key of _childKeys) {
            if (_key) {
              delete prevState.localState[`${locationKey}@@${_key}`];
            }
          }
        });
      }
    },
    [locationKey, _setState]
  );

  const resetState = useCallback(
    (value?: SetStateAction<S>) => {
      _setState((prevState) => {
        if (value) {
          prevState.localState[_innerKey] = parseValue(
            value,
            prevState.localState[_innerKey]
          );
        } else {
          prevState.localState[_innerKey] = initialValue;
        }
      });
    },
    [_innerKey, _setState]
  );

  const updateState = useCallback(
    (value: SetStateAction<S>, reset?: boolean) => {
      if (reset) {
        return resetState(value);
      }

      _setState((state) => {
        const item = state.localState[_innerKey];

        const newValue = parseValue(value, item);

        if (item) {
          Object.assign(item, newValue);
        } else {
          state.localState[_innerKey] = newValue;
        }
      });
    },
    [_innerKey, _setState]
  );

  return {
    state: _state || initialValue,
    updateState,
    resetState,
    resetChildState,
  };
}

export { useRouteState };
