import React, { FC } from 'react';
import { AuditCreation } from 'sub-components/audits/create-audit';
import DashboardContainer from 'sub-components/DashboardContainer';

const CreateAudit: FC = () => {
  return (
    <DashboardContainer>
      <AuditCreation />
    </DashboardContainer>
  );
};

export default CreateAudit;
