import React, { FC, useEffect, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { canAccessChat, HeaderColors } from '../../../../configs';
import { Authorize } from '../../../../authorization';
import { BoxHeader } from 'ui-components';
import ShareReportViaChat from './ShareReportViaChat';
import ShareReportViaEmail from './ShareReportViaEmail';

import { getAuditId } from 'pages/Audits/services/template-listing.graphql';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  assignment: any;
  finalUrl: string;
}

const ShareReportDrawer: FC<IProps> = ({
  isOpen,
  onClose,
  assignment,
  finalUrl,
}) => {
  const [auditId, setAuditId] = useState('');
  const { sessionId } = useParams<{
    sessionId: string;
  }>();

  const [fetchAuditId, { data, loading }] = useLazyQuery(getAuditId, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAuditId(data?.AuditSessionById?.auditId);
    },
  });

  useEffect(() => {
    fetchAuditId({
      variables: {
        eid: sessionId,
      },
    });
  }, []);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size='lg'>
      <DrawerContent paddingX='8px' maxW='560px'>
        <DrawerHeader>
          <Flex align='center' justify='space-between'>
            <BoxHeader
              color={HeaderColors.Green}
              boxWidth={3}
              fontSize='18px'
              title='Share Report'
            />
            <DrawerCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        </DrawerHeader>
        <DrawerBody paddingBottom={4}>
          <Flex gap={6} flexDir='column'>
            <ShareReportViaEmail
              assignment={assignment}
              auditId={auditId}
              finalUrl={finalUrl}
            />
            <Authorize canAccess={canAccessChat}>
              <ShareReportViaChat />
            </Authorize>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ShareReportDrawer;
