import React, { FC, forwardRef, useCallback, useMemo } from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import {
  components,
  MenuListProps,
  OptionProps,
  Select,
} from 'chakra-react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { getChakraSelectStyles } from '../../../../../atoms/Dropdown/dropdown.styles';
import { getImage, toArray } from '../../../../../utils';

import { SelectOption } from '../../../../../atoms';

interface IProps {
  locations: SelectOption[];
  value?: string[];
  onChange: (value: string[]) => void;
}

const Option: FC<OptionProps<SelectOption, true>> = (props) => {
  const { isSelected, data, children } = props;

  return (
    <components.Option {...props}>
      <Flex align='center' cursor='pointer'>
        <Image
          src={getImage(data.url, data.label)}
          borderRadius={'5px'}
          width={7}
          height={7}
        />
        <Text flex={1} noOfLines={1} px='8px'>
          {children}
        </Text>
        {/* <Checkbox isChecked={isSelected} onClick={(e) => e.preventDefault()} /> */}
        {isSelected && (
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            size='lg'
          />
        )}
      </Flex>
    </components.Option>
  );
};

const LocationSelect = forwardRef<never, IProps>(
  ({ locations, value = [], onChange }, ref) => {
    const records = useMemo(() => {
      return locations?.reduce<{ [key: string]: SelectOption }>(
        (previousValue, currentValue) => {
          if (!previousValue[currentValue.eid]) {
            previousValue[currentValue.eid] = currentValue;
          }
          return previousValue;
        },
        {}
      );
    }, [locations]);

    const selectedValue = useMemo(() => {
      return value?.map((it) => {
        if (typeof it === 'object') {
          return it;
        }
        return records[it];
      });
    }, [records, value]);

    const handleRemove = useCallback(
      (item: SelectOption) => onChange(value.filter((val) => val !== item.eid)),
      [value, onChange]
    );

    const MenuList = (props: MenuListProps<SelectOption, true>) => {
      return (
        <components.MenuList {...props}>
          <Box
            backgroundColor='white'
            border='1px solid #E2E8F0'
            borderRadius='8px'
          >
            {props.children}
          </Box>
        </components.MenuList>
      );
    };

    const handleChange = (newValue: SelectOption[]) => {
      onChange(toArray(newValue).map((s) => s.value));
    };

    return (
      <div>
        <Select<SelectOption, true>
          ref={ref}
          chakraStyles={getChakraSelectStyles({
            menuList: {
              padding: '12px',
            },
          })}
          options={locations}
          value={selectedValue}
          isMulti
          onChange={handleChange as never}
          styles={{
            option: (base, optionProps) => ({
              ...base,
              color: 'black',
              borderBottom: '1px solid #efefef',
              borderRadius:
                optionProps?.isSelected || optionProps?.isFocused ? 8 : 0,
              ':last-child': {
                borderBottom: 'none',
              },
              backgroundColor: optionProps?.isSelected
                ? '#2A85FF1F'
                : optionProps?.isFocused
                ? '#EEEEEE'
                : 'none',
            }),
          }}
          placeholder='Select locations'
          getOptionValue={(option) => option?.value}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
          components={{
            MenuList,
            Option,
          }}
        />
        {selectedValue?.length > 0 && (
          <Flex align='center' gap='10px' wrap='wrap' mt='1rem'>
            {selectedValue?.map((item) => {
              return (
                <Flex
                  key={item?.value}
                  align='center'
                  bg='#b1e5fc'
                  p='5px 6px'
                  borderRadius='5px'
                  gap='5px'
                >
                  <Image
                    width={7}
                    height={7}
                    borderRadius={'5px'}
                    src={getImage(item?.url, item?.label)}
                  />
                  <Text as='span' fontWeight='600' pr='5px' noOfLines={1}>
                    {item?.label}
                  </Text>
                  <span
                    onClick={() => handleRemove(item)}
                    style={{
                      cursor: 'pointer',
                      fontSize: '20px',
                      fontWeight: '500',
                      marginRight: '3px',
                    }}
                  >
                    &times;
                  </span>
                </Flex>
              );
            })}
          </Flex>
        )}
      </div>
    );
  }
);

LocationSelect.displayName = 'LocationSelect';

export default LocationSelect;
