import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faPen,
  faPlay,
  faRocketLaunch,
} from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import { Authorization, AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { UPDATE_LOCATION_LAUNCH } from 'pages/launcher/details/graphql/location-launch-details.graphql';
import { getUserTimezoneConvertedNow } from 'pages/launcher/details/helper';
import React, { FC, useEffect, useState } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import Loader from 'sub-components/Loader';
import { IInProgressCard } from 'sub-components/LocationLaunchDetail/types/types';
import { DatePickerComponent } from 'sub-components/tasks/create-task/Scheduler/CustomDatePicker/CustomDatePicker';
import CommonContainer from 'ui-components/CommonContainer';
import { SimpleDatePicker } from 'ui-components/DateFilter/components';
import { useGoLiveDate } from 'ui-components/GoLiveDateModal/useGoLiveDate';
import CommonLabel from '../CommonLabel';
import CommonWrapper from '../CommonWrapper';
import CurrentOn from '../Mid/CurrentOn';
import LastDone from '../Mid/LastDone';
import TotalTasksDone from '../TotalTasksDone';
import GoLiveRocket from './components/GoLiveRocket';
import ProjectStartSection from './components/ProjectStartSection';
import {
  commonDurationHandler,
  durationString,
  getRefactoredLiveDate,
} from './util/helper';
import useCombineStore from 'zustandStore/store';

const UPDATE_LIVE_DATE = gql`
  mutation UpdateLiveDate($locationId: String!, $days: Int!) {
    UpdateLiveDate(locationId: $locationId, days: $days) {
      succeed
    }
  }
`;

type IHorizontalProps = Pick<
  IInProgressCard,
  'foundLocationData' | 'topDetails' | 'midDetails' | 'callbackFn'
>;

const Horizontal: FC<IHorizontalProps> = (props) => {
  const {
    foundLocationData,
    topDetails: { completed, totalCount },
    midDetails: {
      currentStage,
      sinceWhen,
      lastDoneOn,
      lastDoneStep,
      currentPhase,
      currentPhaseSinceWhen,
      lastDonePhase,
      lastPhaseCompletedOn,
    },
    callbackFn,
  } = props;
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });
  const goLiveDateModal = useGoLiveDate();
  const [goLiveDate, setGoLiveDate] = useState(moment()?.toISOString());
  const [isHovered, setIsHovered] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const loggedInUserTimezone = useUserDataSelector((state) => state.timezone);
  const defaultTextProps = { fontWeight: 500, color: '#111315' };
  let val = 1;

  //get the task update date event from store
  const taskDueDateEvent = useCombineStore(
    (state) => state.events['EditLauncherTaskSideDrawer']['onUpdateDueTaskDate']
  );
  //clear event
  const clearEvent = useCombineStore((state) => state.clearEvent);

  useEffect(() => {
    setGoLiveDate(
      getRefactoredLiveDate(
        foundLocationData?.liveDate,
        foundLocationData?.tasks
      )
    );
  }, [foundLocationData]);

  //when task update event is triggered make the edit button visible
  useEffect(() => {
    if (taskDueDateEvent) {
      //scroll
      //calender and hover
      setIsHovered(true);
      // setIsDatePickerOpen(true);
    }
  }, [taskDueDateEvent, clearEvent]);

  const [updateGoLiveDate, { loading }] = useMutation(UPDATE_LOCATION_LAUNCH, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Go live date updated',
      });
      callbackFn?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Go live date could not be updated',
      });
    },
  });

  // const [updateGoLiveDate, { loading }] = useMutation(UPDATE_LIVE_DATE, {
  //   onCompleted: () => {
  //     toast({
  //       status: 'success',
  //       title: 'Go live date updated',
  //     });
  //     callbackFn?.();
  //   },
  //   onError: () => {
  //     toast({
  //       status: 'error',
  //       title: 'Go live date could not be updated',
  //     });
  //   },
  // });

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    if (!isDatePickerOpen) {
      setIsHovered(false);
    }
  };
  const onCalendarOpen = () => {
    setGoLiveDate(
      getRefactoredLiveDate(
        foundLocationData?.liveDate,
        foundLocationData?.tasks
      )
    );
    setIsDatePickerOpen(true);
  };
  const onCalendarClose = () => {
    setIsDatePickerOpen(false);
    setGoLiveDate(
      getRefactoredLiveDate(
        foundLocationData?.liveDate,
        foundLocationData?.tasks
      )
    );
    if (!isHovered) {
      setIsHovered(false);
    }
  };

  const updateGoLiveDateHandler = () => {
    setIsDatePickerOpen(false);
    updateGoLiveDate({
      variables: {
        input: {
          launchId: foundLocationData?.eid,
          liveDate: goLiveDate,
        },
      },
    });
  };

  const updateDateHandler = () => {
    if (isGoLiveDateBeforeTaskDueDate()) {
      goLiveDateModal({
        onProceed: () => {
          updateGoLiveDateHandler();
        },
      });
    } else {
      updateGoLiveDateHandler();
    }
  };

  // const getRefactoredLiveDate = () => {
  //   if (!foundLocationData?.liveDate) {
  //     if (
  //       Array.isArray(foundLocationData?.tasks) &&
  //       foundLocationData.tasks.length > 0
  //     ) {
  //       const validDates = foundLocationData.tasks
  //         .map((task) => task?.dueDate)
  //         .filter(Boolean)
  //         .map((date) => moment(date))
  //         .filter((dateMoment) => dateMoment.isValid());

  //       if (validDates.length > 0) {
  //         const largestDueDate = moment.max(validDates);
  //         return largestDueDate.toISOString();
  //       }
  //     }
  //     return moment().toISOString();
  //   }
  //   return foundLocationData.liveDate;
  // };

  const getTaskDuration = (refDate: moment.Moment) => {
    const now = getUserTimezoneConvertedNow(loggedInUserTimezone);
    const phaseDate = moment(sinceWhen);
    const isPastValue = phaseDate.isBefore(now);
    let result = commonDurationHandler(refDate, now);
    return durationString('isPast', result, undefined, isPastValue);
  };

  const maximumDueDateForTask = () => {
    if (!foundLocationData || foundLocationData?.tasks?.length === 0) return;
    let tasksDueDates = foundLocationData?.tasks?.map((task) => task?.dueDate);
    let tasksDueDatesInMoments = tasksDueDates?.map((date) => moment(date));
    let maxTaskDueDate = moment.max(tasksDueDatesInMoments);
    let maxTaskDueDateEndOfDay = maxTaskDueDate.startOf('day');
    return maxTaskDueDateEndOfDay;
  };

  const isGoLiveDateBeforeTaskDueDate = () => {
    let momentGoLiveDate = moment(goLiveDate);
    let goLiveDateEndOfDay = momentGoLiveDate?.startOf('day');
    let flag = goLiveDateEndOfDay.isBefore(maximumDueDateForTask());
    return flag;
  };

  return val === 1 ? (
    // @ts-ignore
    <Grid
      templateRows='repeat(2, 1fr)'
      templateColumns='repeat(2, 1fr)'
      gap='2px'
    >
      <GridItem
        bg='white'
        borderTopLeftRadius='12px'
        h='110px'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Flex p={4} boxSize='full'>
          <GoLiveRocket
            liveDate={foundLocationData?.liveDate}
            tasks={foundLocationData?.tasks}
            isGoLiveDateBeforeTaskDueDate={isGoLiveDateBeforeTaskDueDate}
          />
          {loading && <Loader size='md' thickness='3px' />}
          {(isHovered || isDatePickerOpen) && !loading ? (
            <Authorization
              permittedFor={['user']}
              permittedRoles={[AuthRole.SUPER_ADMIN]}
            >
              <SimpleDatePicker
                onChange={(value) => {
                  value && setGoLiveDate(value?.toISOString());
                  console.log('date value : ', value);
                }}
                selectedValue={moment(goLiveDate)?.toDate()}
                minDate={moment()?.startOf('day')?.toDate()}
                // minDate={moment(getRefactoredLiveDate())?.toDate()}
                placeholderText=''
                onCalendarOpen={onCalendarOpen}
                onCalendarClose={onCalendarClose}
                customInput={
                  // @ts-ignore
                  <Flex
                    h='fit-content'
                    alignItems='center'
                    gap='5px'
                    color={
                      isDatePickerOpen
                        ? 'rgba(42, 133, 255, 1)'
                        : 'rgba(111, 118, 126, 1)'
                    }
                    cursor='pointer'
                  >
                    <FontAwesomeIcon icon={faPen as IconProp} />
                    <Text id='goLiveEditBtn'>Edit</Text>
                  </Flex>
                }
              >
                <PrimaryButton
                  title='Update'
                  colorScheme='blue'
                  size='lg'
                  style={{ marginTop: '15px' }}
                  disabled={loading}
                  isLoading={loading}
                  onClick={updateDateHandler}
                />
              </SimpleDatePicker>
            </Authorization>
          ) : null}
        </Flex>
      </GridItem>
      <GridItem bg='white' borderTopRightRadius='12px' p={4} h='110px'>
        <TotalTasksDone
          tasksCompletedCount={completed}
          tasksTotalCount={totalCount}
        />
      </GridItem>
      <GridItem bg='white' borderBottomLeftRadius='12px' h='110px'>
        <ProjectStartSection startDate={foundLocationData?.startDate} />
      </GridItem>
      <GridItem
        display='grid'
        gridTemplateColumns='repeat(auto-fit, minmax(min(100%, 5em), 1fr))'
        gap='2px'
        h='110px'
      >
        <Flex bg='white' flexDir='column' p={4}>
          <Text color='rgba(111, 118, 126, 1)' fontSize='15px'>
            Last done task
          </Text>
          <Text as='span' isTruncated>
            {lastDoneStep || '-'}
          </Text>
          <Text fontSize='15px' color='rgba(166, 177, 188, 1)'>
            {lastDoneOn && moment(lastDoneOn)?.isValid()
              ? `on ${moment(lastDoneOn)?.format('Do MMM')}`
              : '-'}
          </Text>
        </Flex>
        <Flex borderBottomRightRadius='12px' bg='white' flexDir='column' p={4}>
          <Text color='rgba(111, 118, 126, 1)' fontSize='15px'>
            Current task
          </Text>
          <Text as='span' isTruncated>
            {currentStage || '-'}
          </Text>
          <Text fontSize='15px' color='rgba(166, 177, 188, 1)'>
            {/* {sinceWhen && moment(sinceWhen)?.isValid() ? `${isPast ? 'since' : 'in'} ${moment(sinceWhen)?.fromNow(true)}` : '-'} */}
            {getTaskDuration(moment(sinceWhen)) || '-'}
          </Text>
        </Flex>
      </GridItem>
    </Grid>
  ) : (
    <CommonContainer
      borderRadius='12px'
      p='20px'
      bg='white'
      display='flex'
      justifyContent='flex-start'
      w='full'
      gap={4}
    >
      <CommonContainer w='25%'>
        <TotalTasksDone
          tasksCompletedCount={completed}
          tasksTotalCount={totalCount}
        />
      </CommonContainer>
      <Divider orientation='vertical' />
      <CommonContainer w='15%'>
        <CurrentOn
          orientation='horizontal'
          currentStage={currentStage || ''}
          sinceWhen={sinceWhen}
          // sinceWhen={''}
        />
      </CommonContainer>
      <Divider orientation='vertical' />
      <CommonContainer w='15%'>
        <LastDone
          orientation='horizontal'
          lastDoneOn={lastDoneOn || ''}
          lastDoneStep={lastDoneStep}
        />
      </CommonContainer>
      <Divider orientation='vertical' />
      <CommonContainer w='15%'>
        <Flex flexDir='column'>
          <Text fontSize='15px' color='#6F767E'>
            Current Phase
          </Text>
          <Text
            fontSize='15px'
            fontWeight={500}
            color='#111315'
            isTruncated
            maxW='200px'
          >
            {currentPhase || '-'}
          </Text>
          {currentPhase && currentPhaseSinceWhen && (
            <Text color='#A6B1BC' fontSize='15px'>
              since {currentPhaseSinceWhen}
            </Text>
          )}
        </Flex>
        {/* <DatePickerComponent
          onChange={(date) => console.log(date)}
          placeholderText='Edit'
          selectedValue={null}
          dateFormat='d MMMM yyyy'
          isSelected={false}
        /> */}
      </CommonContainer>
      <Divider orientation='vertical' />
      <CommonContainer w='15%'>
        <Flex flexDir='column'>
          <Text fontSize='15px' color='#6F767E'>
            Last done Phase
          </Text>
          <Text
            fontSize='15px'
            fontWeight={500}
            color='#111315'
            isTruncated
            maxW='200px'
          >
            {lastDonePhase || '-'}
          </Text>
          {lastDonePhase && lastPhaseCompletedOn && (
            <Text color='#A6B1BC' fontSize='15px'>
              on {lastPhaseCompletedOn}
            </Text>
          )}
        </Flex>
        {/* <CommonWrapper
          firstLabel='Last done Phase'
          secondLabel={lastDonePhase}
          secondLabelProps={defaultTextProps}
          // thirdLabel={`on`}
          // thirdLabel={<Text color={HeaderColors.DarkBlue}>Edit</Text>}
        /> */}
        {/* <DatePickerComponent
          onChange={(date) => console.log(date)}
          placeholderText='Edit'
          selectedValue={null}
          dateFormat='d MMMM yyyy'
          isSelected={false}
        /> */}
      </CommonContainer>
    </CommonContainer>
  );
};

Horizontal.displayName =
  'LocationLaunchDashboard/InProgress/Singleton/Horizontal';
export default Horizontal;
