import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { EmptyState } from 'sub-components';
import { Column } from 'sub-components/audits/audit-tab/my-audits/components/CollapseTable/types';
import CollapseTable from 'sub-components/audits/audit-tab/my-audits/components/CollapseTable/CollapseTable';
import {
  FlaggedDataEntity,
  GetFlaggedResponsesEntity,
  SectionEntity,
} from '../types';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import QuestionTypes from '../../QuestionTypes/QuestionTypes';
import Notes from '../../Notes';
import EvidenceImages from '../../EvidenceImages/EvidenceImages';
import { getImage } from 'utils';

interface IProps {
  data: {
    section: SectionEntity;
    flaggedData: GetFlaggedResponsesEntity[];
    key: string;
  }[];
}

const FlaggedResponseTable: FC<IProps> = ({ data }) => {
  const entityObj = useReactiveVar(usersEntityObj);

  const renderHeader = (item: FlaggedDataEntity, isExpanded: boolean) => (
    <Flex align='center' p='24px' justify='space-between' w='full'>
      <Flex align='center' color='rgba(111, 118, 126, 1)'>
        {isExpanded ? (
          <ChevronDownIcon fontSize='20px' />
        ) : (
          <ChevronRightIcon fontSize='20px' />
        )}
        <Box textAlign='left' ml={2} fontWeight={600}>
          {item.section?.sectionName}
        </Box>
        <Flex
          fontWeight={500}
          fontSize='15px'
          bg='rgba(255, 188, 153, 1)'
          color='black'
          borderRadius='6px'
          p='2px 8px'
          align='center'
          gap='5px'
          ml={2}
        >
          <FontAwesomeIcon icon={faFlag as IconProp} />
          {item?.flaggedData?.length}{' '}
          {item?.flaggedData?.length === 1 ? 'flag' : 'flags'}
        </Flex>
      </Flex>
    </Flex>
  );

  const tableColumns: Column<FlaggedDataEntity>[] = [
    {
      header: 'Question',
      accessor: (item) => item?.flaggedData?.[0]?.question?.label,
      width: '24%',
    },
    {
      header: 'Flagged response',
      width: '33%',
      accessor: (item) => 'response',
    },
    {
      header: 'Notes/Attachments',
      width: '33%',
      accessor: (item) => 'attachment',
    },
    {
      header: 'Flagged by',
      width: '10%',
      accessor: (item) => 'flagged by',
    },
  ];

  const renderPanel = (item: FlaggedDataEntity) => (
    <Box
      display='grid'
      gridTemplateColumns='24% 33% 33% 10%'
      gap='0'
      width='100%'
      border='1px solid #f0f0f0'
      boxSizing='border-box'
    >
      {item.flaggedData.map((flagged, index) => (
        <Box key={index} display='contents'>
          <Box
            borderRight='1px solid #f0f0f0'
            borderBottom='1px solid #f0f0f0'
            minH='61px'
            display='flex'
            alignItems='baseline'
            px={2}
            py={4}
          >
            <Box fontWeight={500} color='rgba(111, 118, 126, 1)'>
              {flagged?.question?.label}
            </Box>
          </Box>

          <Box
            borderRight='1px solid #f0f0f0'
            borderBottom='1px solid #f0f0f0'
            minH='61px'
            display='flex'
            alignItems='baseline'
            px={2}
            py={4}
            whiteSpace='pre-wrap'
          >
            <QuestionTypes
              hideNotes
              hideEvidences
              rawQuestion={flagged?.question}
              rawResponse={flagged?.response}
            />
          </Box>

          <Box
            borderRight='1px solid #f0f0f0'
            borderBottom='1px solid #f0f0f0'
            minH='61px'
            display='flex'
            alignItems='baseline'
            p={2}
            py={4}
          >
            <Flex direction='column' gap={2} w='full'>
              {flagged?.response?.images && (
                <EvidenceImages images={flagged?.response?.images || []} />
              )}
              <Notes note={flagged?.response?.noteText || ''} />
            </Flex>
          </Box>

          <Box
            borderBottom='1px solid #f0f0f0'
            minH='61px'
            display='flex'
            alignItems='baseline'
            px={2}
            py={4}
          >
            {(() => {
              let foundUser = entityObj?.find(
                (entity) => entity?.eid === flagged?.response?.userId
              );
              if (foundUser) {
                return (
                  <Flex align='center' gap='5px'>
                    <Box
                      as='img'
                      src={getImage(foundUser?.profilePic, foundUser?.name)}
                      alt={foundUser?.name}
                      boxSize='20px'
                      borderRadius='50%'
                    />
                    <Box as='span' maxW='100px' isTruncated>
                      {foundUser?.name}
                    </Box>
                  </Flex>
                );
              }
              return <Box>-</Box>;
            })()}
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <CollapseTable
      data={data}
      columns={tableColumns}
      renderPanel={renderPanel}
      renderHeader={renderHeader}
    />
  );
};

export default FlaggedResponseTable;
