import React, { FC } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Box,
  Flex,
  Center,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Sort } from '../../../../../../../assets/images/sort.svg';
import { SelectOption } from 'atoms';
import { Loader } from 'sub-components';

interface IProps {
  customTrigger?: React.ReactNode;
  options: SelectOption[];
  value: SelectOption | undefined;
  loading?: boolean;
  handleOptionClick: (value: SelectOption) => void;
}

const FilterOptions: FC<IProps> = ({ options, value, handleOptionClick }) => {
  return (
    <Flex flexDir='column' gap={2} p={1} overflowY='scroll' maxH='20vh'>
      {options.map((option, index) => {
        const isSelected = value?.value === option?.value;
        return (
          <Flex
            key={index}
            p='8px 12px'
            justify='space-between'
            gap={2}
            align='center'
            cursor='pointer'
            bg={isSelected ? 'rgba(42, 133, 255, 0.1)' : 'none'}
            color={
              isSelected ? 'rgba(42, 133, 255, 1)' : 'rgba(111, 118, 126, 1)'
            }
            borderRadius={isSelected ? '12px' : 'none'}
            _hover={{
              color: isSelected ? 'none' : 'rgba(26, 29, 31, 1)',
              bg: isSelected
                ? 'rgba(42, 133, 255, 0.1)'
                : 'rgba(244, 244, 244, 1)',
              borderRadius: '12px',
            }}
            onClick={() => handleOptionClick(option)}
          >
            <Box fontWeight={600} fontSize='15px'>
              {option?.label}
            </Box>
            {isSelected && (
              <FontAwesomeIcon
                icon={faCheck as IconProp}
                style={{
                  color: 'rgba(42, 133, 255, 1)',
                }}
                size='lg'
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

const SectionFilter: FC<IProps> = ({
  customTrigger,
  options,
  value,
  loading,
  handleOptionClick,
}) => {
  return loading ? (
    <Box boxSize='20px'>
      <Loader />
    </Box>
  ) : (
    <Box>
      <Popover placement='bottom-end'>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              {!customTrigger ? (
                <Center
                  cursor='pointer'
                  bg={
                    isOpen || value?.value ? 'rgba(239, 239, 239, 0.6)' : 'none'
                  }
                  _hover={{
                    bg: 'rgba(239, 239, 239, 0.6)',
                    borderRadius: '2px',
                  }}
                  boxSize='20px'
                  borderRadius='2px'
                  border={
                    value?.value ? '1px solid rgba(42, 133, 255, 1)' : 'none'
                  }
                >
                  <Sort
                    style={{
                      color: value?.value ? 'rgba(42, 133, 255, 1)' : 'black',
                    }}
                  />
                </Center>
              ) : (
                customTrigger
              )}
            </PopoverTrigger>
            <PopoverContent
              borderRadius='16px'
              w='243px'
              style={{ boxShadow: '-2px 1px 4px 1px rgba(0, 0, 0, 0.05)' }}
            >
              <PopoverArrow />
              <PopoverBody>
                <FilterOptions
                  options={options}
                  value={value}
                  handleOptionClick={handleOptionClick}
                />
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

SectionFilter.displayName =
  'sub-components/audits/audit-tab/supervised/components/GridView/components/SectionFilter';

export default SectionFilter;
