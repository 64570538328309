import { Box, Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FC, useEffect, useState } from 'react';
import { useUserDataSelector } from 'hooks';
import { gql, useLazyQuery } from '@apollo/client';
import { GET_ENTITY_USERS } from 'shared/graphql/queries';
import { useParams } from 'react-router-dom';

export const LOCATION_DETAILS = gql`
  query UserById($eid: String!) {
    userById(eid: $eid) {
      entityId
    }
  }
`;

interface IProps {
  answer?: string[] | undefined;
  entityUserFromPublicReport?: any;
}

const Location: FC<IProps> = ({ answer, entityUserFromPublicReport }) => {
  const [entityUserData, setEntityUserData] = useState([]);
  const queryParams = new URLSearchParams(location?.search);
  const tokenId = queryParams.get('token');

  const { locationId } = useParams<{
    locationId: string;
  }>();

  let locations: any;

  if (tokenId) {
    locations = entityUserFromPublicReport;
  } else {
    locations = useUserDataSelector((state) => state?.entity?.locations);
  }

  const foundLocationDetails = answer?.map((locationId: string) => {
    const temp = locations?.find((item) => {
      if (item?.eid === locationId) {
        return item;
      }
    });
    return temp;
  });

  return (
    <Flex
      direction={'column'}
      alignItems={'flex-end'}
      justifyContent={'center'}
      gap={2}
    >
      {foundLocationDetails?.length > 0 &&
        foundLocationDetails?.map((item: any) => {
          return (
            <Box key={item?.eid}>
              <Flex
                width={'max-content'}
                padding={'6px 16px'}
                borderRadius={'8px'}
                backgroundColor={'#DCF6EA'}
                gap={2}
                alignItems={'center'}
                alignSelf={'flex-start'}
              >
                <FontAwesomeIcon
                  color='white'
                  style={{
                    backgroundColor: '#6FC666',
                    padding: '6px 8px',
                    borderRadius: '8px',
                  }}
                  icon={faLocationDot as IconProp}
                />
                <Text>{item?.name}</Text>
              </Flex>
            </Box>
          );
        })}
    </Flex>
  );
};

export default Location;
