import React, { forwardRef, useImperativeHandle } from 'react';
import ImageUploadProvider from './ImageUploadProvider';
import ImageList from './ImageList';
import UploadWrapper from './UploadWrapper';

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

const ImageUpload = forwardRef<never, IProps>(({ value, onChange }, ref) => {
  useImperativeHandle(ref, () => null, []);

  return (
    <ImageUploadProvider value={value} onChange={onChange}>
      <ImageList />
      <UploadWrapper />
    </ImageUploadProvider>
  );
});

ImageUpload.displayName = 'ImageUpload';

export default ImageUpload;
