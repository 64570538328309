import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toArray } from '../../../../utils';
import { SelectOption } from '../../../../atoms';
import { usersEntityObj } from '../../../Header';
import TitleHeader from '../../../CardEditor/TitleHeader';
import { ATFormInput } from './create-audit-task.types';
import LocationSelect from './components/LocationSelect';
import AssigneeHint from '../../../tasks/create-task/Assignees/AssigneeHint';

interface IProps {
  assignedLocations?: string[];
}

const TaskAssignee: FC<IProps> = ({ assignedLocations = [] }) => {
  const { t } = useTranslation(['task']);
  const { control } = useFormContext<ATFormInput>();

  const entityData = useReactiveVar(usersEntityObj);

  const flaggedLocations = useMemo(() => {
    return toArray(entityData).reduce<SelectOption[]>((acc, ent) => {
      if (assignedLocations?.includes(ent?.eid)) {
        acc.push({
          ...ent,
          label: ent?.name,
          value: ent?.eid,
        });
      }
      return acc;
    }, []);
  }, [assignedLocations]);

  return (
    <Box>
      <Controller
        control={control}
        name='assignedToLocation'
        rules={{
          required: t('task:validation.locationRequired'),
        }}
        render={({ field, fieldState }) => {
          return (
            <Flex gap={4} w='full' align='baseline'>
              <Box as='span' fontWeight={600}>
                Locations
              </Box>
              <FormControl isInvalid={fieldState.invalid}>
                <LocationSelect
                  locations={flaggedLocations}
                  onChange={field.onChange}
                  value={field.value}
                  ref={field.ref}
                />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          );
        }}
      />
      <Controller
        control={control}
        name='assignedLocationsType'
        // rules={{
        //   required: t('task:validation.supervisorRequired'),
        // }}
        rules={{
          required: 'Assigned to field is required',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={2} isInvalid={fieldState.invalid}>
              <TitleHeader title='Assign to' />
              <RadioGroup mt={2} {...field}>
                <Flex
                  flexDir='column'
                  alignItems='start'
                  gap={3}
                  sx={{
                    '.chakra-radio__label': {
                      color: '#33383F',
                      fontSize: 14,
                      fontWeight: 600,
                    },
                    '.chakra-radio__control': {
                      marginTop: '3px',
                      _checked: {
                        borderColor: 'blue.500',
                        background: 'white',
                        color: 'blue.500',
                        _hover: {
                          background: 'white',
                        },
                      },
                    },
                  }}
                >
                  <Radio size='md' value='locationOwner' alignItems='start'>
                    <Flex flexDir='column'>
                      <Box as='span'>Location Owners</Box>
                      <Box fontSize='11px' fontWeight={500} color='#676F6E'>
                        Task will be assigned to location owners, individually.
                      </Box>
                    </Flex>
                  </Radio>
                  <Radio size='md' value='locationUser' alignItems='start'>
                    <Flex flexDir='column'>
                      <Box as='span'>Locations</Box>
                      <Box fontSize='11px' fontWeight={600} color='#676F6E'>
                        Task will be assigned to any one person at the location,
                        any one from the location can do it
                      </Box>
                    </Flex>
                  </Radio>
                </Flex>
              </RadioGroup>
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default TaskAssignee;
