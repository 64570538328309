import React, { FC, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Authorize, AuthRole } from '../../../authorization';
import { Button } from '../../../atoms';
import { AppRoute, useRouteState } from '../../../routes';
import { ListingContainer } from '../../../sub-components/audits/listing';

const notSelectedTabStyle = {
  fontWeight: 600,
  color: '#6F767E',
  fontSize: '15px',
  padding: '8px 16px',
  borderRadius: '8px',
  cursor: 'pointer',
};

const selectedTabStyle = {
  backgroundColor: '#EFEFEF',
  fontWeight: 600,
  fontSize: '15px',
  padding: '8px 16px',
  borderRadius: '8px',
  cursor: 'pointer',
};

interface IProps {}

const TemplateContainer: FC<IProps> = () => {
  const history = useHistory();

  const { state, updateState } = useRouteState('template', {
    templateView: 'template',
  });

  useEffect(() => {
    const auditTab = sessionStorage.getItem('auditTemplateTab');
    if (auditTab) {
      updateState({
        templateView: auditTab,
      });
      sessionStorage.removeItem('auditTemplateTab');
    }
  }, []);

  function setTemplateView(name: string) {
    updateState({
      templateView: name,
    });
  }

  const templateView = state?.templateView;

  return (
    <Flex pos='relative' flexDir='column' gap={4}>
      <Authorize
        permittedFor={'user'}
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <Button
          position='absolute'
          top='-62px'
          right={0}
          variant='solid'
          colorScheme='blue'
          onClick={() => history.push(AppRoute.CREATE_AUDIT_TEMPLATE)}
        >
          Create your own
        </Button>
      </Authorize>
      <Flex justify='space-between'>
        <Flex w='80%' userSelect={'none'}>
          <Text
            style={
              templateView === 'template'
                ? selectedTabStyle
                : notSelectedTabStyle
            }
            onClick={() => setTemplateView('template')}
          >
            My Templates
          </Text>
          <Text
            style={
              templateView === 'library'
                ? selectedTabStyle
                : notSelectedTabStyle
            }
            onClick={() => setTemplateView('library')}
          >
            Template Library
          </Text>
          <Text
            style={
              templateView === 'archives'
                ? selectedTabStyle
                : notSelectedTabStyle
            }
            onClick={() => setTemplateView('archives')}
          >
            Archives
          </Text>
        </Flex>
      </Flex>
      <ListingContainer templateView={templateView} />
    </Flex>
  );
};

export default TemplateContainer;
