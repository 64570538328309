import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Center } from '@chakra-ui/react';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { useEffect, useState, FC } from 'react';
import { useParams } from 'react-router-dom';
import { usersEntityObj } from 'sub-components/Header';
import { formatDateTime } from '../listing/helper';
import { formatQuestionAnswerData, generateValidId } from './helper';
import { Overview, ReportPdfView, Navbar } from './index';
import {
  AUDIT_SESSION_DETAILS,
  LOCATION_DETAILS,
} from './query/audits-report.graphql';
import { Loader } from 'sub-components';
import { defaultCSTTimezone } from 'sub-components/tasks/create-task/validation/helper';

type ReportContentProps = {
  selectedViewType: any;
  setSelectedViewType: any;
};

const ReportContent: FC<ReportContentProps> = ({
  selectedViewType,
  setSelectedViewType,
}) => {
  const [selectedSection, setSelectedSection] = useState('overview');
  const [assignment, setAssignment] = useState([]);
  const [auditData, setAuditData] = useState({});
  const [additionalData, setAdditionalData] = useState({
    locationData: {},
    cardInfo: {},
    moreInfo: {},
    address: {},
    companyDetails: {},
    timeZone: '',
  });

  console.log('auditData --- ', auditData);
  console.log('assignment --- ', assignment);
  console.log('additionalData --- ', additionalData);

  const entityUsers = useReactiveVar(usersEntityObj);
  const locations = useUserDataSelector((state) => state?.entity?.locations);

  const { locationId } = useParams<{
    locationId: string;
  }>();

  let locationData = locations?.find((loc) => loc?.eid === locationId);

  const companyDetails = useUserEntity((state) => {
    return {
      name: state?.name,
      profilePic: state?.profilePic,
    };
  });

  const { sessionId } = useParams<{
    sessionId: string;
  }>();
  const [getSessionDetails, { data, loading }] = useLazyQuery(
    AUDIT_SESSION_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setAuditData(data?.AuditSessionById);

        const mappedAssignment = data?.AuditSessionById?.assignments?.filter(
          (item: any) => {
            if (item?.locationId === locationId) {
              return item;
            }
          }
        );

        let auditorDetails = entityUsers?.filter((item) => {
          if (item?.eid === mappedAssignment?.[0]?.completedBy) {
            return item;
          }
        });

        setAssignment(mappedAssignment?.[0]);

        //handling total flag items count
        const flagMemory: string[] = [];
        //getting flag items count from response
        let flaggedItems = mappedAssignment?.[0]?.response?.filter(
          (item: any) => {
            if (item?.isFlagged && !flagMemory?.includes(item?.qid)) {
              flagMemory?.push(item?.qid);
              return item;
            }
          }
        );

        //getting flag items count from question
        data?.AuditSessionById?.questions?.map((item: any) => {
          if (item?.hasFlaggedResponse && !flagMemory?.includes(item?.eid)) {
            flaggedItems?.push(item);
            flagMemory?.push(item?.eid);
          }
        });

        const totalObtainedScore = data?.AuditSessionById?.questions?.reduce(
          (acc: number, item: any) => {
            return acc + item?.score;
          },
          0
        );

        let cardInfo = {
          score: {
            title: 'Inspection score',
            totalScore: 0,
            obtainedScore: 0,
          },
          flaggedItemCount: {
            title: 'Flagged items',
            count: 0,
          },
          reportNumber: {
            title: 'Report Number',
            reportNumber: '---',
          },
        };

        let moreInfo = {
          location: {
            title: 'Location',
            address: '',
          },
          conductedOn: {
            title: 'Conducted on',
            date: '',
          },
          conductedBy: {
            title: 'Conducted by',
            userDetails: '',
          },
        };

        setAdditionalData((prevState: any) => {
          return {
            ...prevState,
            locationData: locationData,
            companyDetails: companyDetails,
            cardInfo: {
              ...cardInfo,
              flaggedItemCount: {
                ...cardInfo?.flaggedItemCount,
                count: flaggedItems?.length,
              },
              score: {
                ...cardInfo?.score,
                totalScore: mappedAssignment?.[0]?.maxScore,
                obtainedScore: mappedAssignment?.[0]?.totalScore,
              },
              reportNumber: {
                ...cardInfo?.reportNumber,
                reportNumber: mappedAssignment?.[0]?.assignmentId
                  ? mappedAssignment?.[0]?.assignmentId
                  : '--',
              },
            },
            moreInfo: {
              ...moreInfo,
              location: {
                ...moreInfo?.location,
                address: additionalData?.address,
              },
              conductedOn: {
                ...moreInfo?.conductedOn,
                date: mappedAssignment?.[0]?.completedAt,
              },
              conductedBy: {
                ...moreInfo?.conductedBy,
                userDetails: auditorDetails,
              },
            },
          };
        });
      },
    }
  );

  if (additionalData?.cardInfo?.score?.totalScore === 0) {
    delete additionalData?.cardInfo?.score;
  }

  const [getAddress] = useLazyQuery(LOCATION_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAdditionalData((prevState) => {
        return {
          ...prevState,
          timeZone: data?.userById?.timezone
            ? data?.userById?.timezone
            : defaultCSTTimezone,
          address: data?.userById?.address,
          entityId: data?.userById?.entityId,
          moreInfo: {
            ...prevState?.moreInfo,
            location: { title: 'Location', address: data?.userById?.address },
          },
          companyDetails: {
            name: data?.userById?.entity?.name,
            profilePic: data?.userById?.entity?.profilePic,
          },
        };
      });
    },
  });

  useEffect(() => {
    getAddress({
      variables: {
        eid: locationId,
      },
    });

    getSessionDetails({
      variables: {
        eid: sessionId,
      },
    });
  }, []);

  const mappedQuestionData = formatQuestionAnswerData(
    assignment?.questions,
    assignment?.response,
    assignment,
    auditData,
    additionalData
  );

  const flaggedCount = mappedQuestionData?.find(
    (item) => item?.title === 'Flagged Items'
  );

  useEffect(() => {
    setAdditionalData((prevState) => {
      return {
        ...prevState,
        cardInfo: {
          ...prevState?.cardInfo,
          flaggedItemCount: {
            title: 'Flagged items',
            count: flaggedCount?.responses?.length,
          },
        },
      };
    });
  }, [assignment]);

  if (loading) {
    return (
      <Center h='89vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <div>
      <Navbar
        selectedViewType={selectedViewType}
        setSelectedViewType={setSelectedViewType}
        setSelectedSection={setSelectedSection}
        selectedSection={selectedSection}
        mappedQuestionData={mappedQuestionData}
      />
      {selectedViewType === 'web' ? (
        <Overview
          auditData={auditData}
          additionalData={additionalData}
          mappedQuestionData={mappedQuestionData}
          assignment={assignment}
        />
      ) : (
        <ReportPdfView
          auditData={auditData}
          assignment={assignment}
          additionalData={additionalData}
          mappedQuestionData={mappedQuestionData}
        />
      )}
    </div>
  );
};

export default ReportContent;
