import React, { ReactNode, useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import { HeaderColors } from '../../../../configs';

interface IProps {
  title: string;
  headerColor?: string;
  content: ReactNode;
  width: string;
  buttonText: string;
  onConfirm?: () => void;
}

type AuditConfirmation = (props: IProps) => void;

export const useAuditConfirmation = (): AuditConfirmation => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            color={props.headerColor || HeaderColors.Purple}
            title={props.title}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: props.content,

      isCentered: true,
      contentProps: {
        maxW: props.width,
        paddingTop: '4px',
        paddingBottom: '12px',
      },
      okText: props.buttonText,
      okButtonProps: {
        flex: 1,
        size: 'lg',
        borderRadius: '10px',
      },
      cancelButtonProps: {
        flex: 1,
        size: 'lg',
        borderRadius: '10px',
        variant: 'outline',
      },
      onOK: props.onConfirm,
    });
  }, []);
};
