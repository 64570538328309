import React, { FC, FocusEvent } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Input } from 'atoms';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const ShortText: FC<IProps> = ({ value = '', onChange, onBlur }) => {
  return (
    <Flex flexDir='column' gap={2}>
      <Input
        size='lg'
        value={value}
        bg='rgba(244, 244, 244, 1)'
        onChange={(e) => onChange(e?.target?.value)}
        onBlur={onBlur}
      />
      <Box
        as='span'
        color='rgba(111, 118, 126, 1)'
        fontSize='12px'
        fontWeight={500}
        aria-invalid={value?.length > 100}
        _invalid={{
          color: 'red.500',
        }}
      >
        Character count: {value?.length}/100
      </Box>
    </Flex>
  );
};

export default ShortText;
