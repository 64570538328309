import { Stack, Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import TrainingPathAssignForm from './TrainingPathAssignForm';
import TrainingPathAssignText from './TrainingPathAssignText';

const TrainingPathAssign: FC = () => {
  return (
    <Stack direction={'row'} spacing={4}>
      <Box width={'60%'}>
        <TrainingPathAssignForm />
      </Box>
      <Box width={'40%'}>
        <TrainingPathAssignText />
      </Box>
    </Stack>
  );
};

TrainingPathAssign.displayName =
  'pages/Training/CreateTrainingPath/CreateTrainingPathContainer/TrainingPathAssign';

export default TrainingPathAssign;
