import React, { FC } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AddTaskInput } from '../../../tasks/create-task/task.graphql';
import { ResponsesByLocationEntity } from '../../../../pages/Audits/Detail/components/QuestionSection/types/audit-question.types';

import AuditTaskCreation from './AuditTaskCreation';

interface IProps {
  auditId?: string;
  auditDescription?: string;
  flaggedOptions: string[];
  qid?: string;
  sessionId?: string;

  auditTitle?: string;
  assignedLocations?: string[];
  questionTitle?: string;
  responses?: ResponsesByLocationEntity[];

  open: boolean;
  onClose: () => void;
  onTaskSuccess?: () => void;
  onTaskError?: () => void;
}

export const addTaskQuery = gql`
  mutation AddTask($input: AddTaskTypeInput) {
    AddTask(input: $input) {
      eid
    }
  }
`;

const TaskCrud: FC<IProps> = ({
  auditId,
  auditTitle,
  auditDescription,
  qid,
  sessionId,

  assignedLocations = [],
  questionTitle,
  responses,

  open,
  onClose,
  onTaskSuccess,
  onTaskError,
}) => {
  const { t } = useTranslation(['common', 'task', 'audit']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [submitTask] = useMutation(addTaskQuery, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('task:createSuccess'),
      });
      onTaskSuccess?.();
      onClose();
    },
    onError: (error) => {
      // TODO populate error to form field
      // eslint-disable-next-line no-console
      console.log(error);
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('common:something_wrong'),
      });
      onClose();
    },
  });

  const handleSubmit = async (dataToSubmit: AddTaskInput): Promise<void> => {
    try {
      let auditData = {
        ...dataToSubmit,
        audit: {
          auditId: auditId,
          description: auditDescription,
          flagged: [],
          qid: qid,
          sessionId: sessionId,
        },
      };
      const res = await submitTask({
        variables: {
          input: auditData,
        },
      });

      if (res.errors) {
        return Promise.reject('Task creation failed');
      }
      // deployEvent(AmplitudeEvent.TASK_PUBLISHED);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Drawer isOpen={open} placement='right' onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent minW='800px'>
        <DrawerHeader>
          <Box>Create task</Box>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody>
          <AuditTaskCreation
            auditTitle={auditTitle}
            questionTitle={questionTitle}
            responses={responses}
            assignedLocations={assignedLocations}
            onSubmit={handleSubmit}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TaskCrud;
