import { useLazyQuery } from '@apollo/client';
import {
  AUDIT_QUESTION_CYCLES,
  AUDIT_QUESTION_DETAILS,
  AUDIT_QUESTION_DETAILS_BY_SESSION_ID,
} from '../service/audit-question.graphql';
import {
  AuditCycleResponse,
  AuditCycleVariable,
  AuditQuestionResponse,
  AuditQuestionVariable,
} from '../types/audit-question.types';

export const useServiceLayer = () => {
  // const [getQuestionDetails, questionDetailsResponse] = useLazyQuery<
  //   AuditQuestionResponse,
  //   AuditQuestionVariable
  // >(AUDIT_QUESTION_DETAILS, {
  //   fetchPolicy: 'network-only',
  // });

  const [getQuestionDetails, questionDetailsResponse] = useLazyQuery<
    AuditQuestionResponse,
    AuditQuestionVariable
  >(AUDIT_QUESTION_DETAILS_BY_SESSION_ID, {
    fetchPolicy: 'network-only',
  });

  const [getAuditCycles, auditCyclesResponse] = useLazyQuery<
    AuditCycleResponse,
    AuditCycleVariable
  >(AUDIT_QUESTION_CYCLES, {
    fetchPolicy: 'network-only',
  });

  return {
    questionDetails: {
      getQuestionDetails,
      questionDetailsResponse,
    },
    auditCycle: {
      getAuditCycles,
      auditCyclesResponse,
    },
  };
};
