import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as CalendarIcon } from '../../../../../../../../assets/images/calendar.svg';
import React, { FC } from 'react';
import {
  AuditSessionEntity,
  AuditStatusFunctionEntity,
} from 'sub-components/audits/audit-tab/supervised/types';
import moment from 'moment';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';

interface IProps {
  auditData: AuditSessionEntity | undefined;
  auditStatus: AuditStatusFunctionEntity;
}

const DateDetail: FC<IProps> = ({ auditData, auditStatus }) => {
  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );

  const dateGenerate = () => {
    if (auditStatus === 'completed') {
      let selectedLocation = auditData?.assignments?.find(
        (assignment) => assignment?.locationId === selectedLocationFilter?.value
      );
      console.log('DATE GENERATE : ', selectedLocation);
      if (selectedLocation) {
        return moment(selectedLocation?.completedAt)?.isValid()
          ? moment.utc(selectedLocation?.completedAt)?.format('DD MMM, YYYY')
          : '-';
      } else {
        return moment(auditData?.completedAt)?.isValid()
          ? moment.utc(auditData?.completedAt)?.format('DD MMM, YYYY')
          : '-';
      }
    } else {
      return moment(auditData?.dueDate)?.isValid()
        ? moment?.utc(auditData?.dueDate)?.format('DD MMM, YYYY')
        : 'No Deadline';
    }
  };
  return (
    <Flex justify='space-between' color='#1A1D1F' mt={2}>
      <Box flex={1} fontWeight={600}>
        {auditStatus === 'completed' ? 'Completed date:' : 'Current due date:'}
      </Box>
      <Flex
        flex={1}
        gap={1}
        align='center'
        color='#6F767E'
        fontWeight={500}
        justify='flex-end'
      >
        <CalendarIcon width={20} height={20} />
        {dateGenerate()}
      </Flex>
    </Flex>
  );
};

export default DateDetail;
