import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../ui-components';
import EditDueDateDrawer from './EditDueDateDrawer';
import { ITaskItem } from 'pages/launcher/config-editor/editor-form/editor.types';
import SelectTaskPhase from 'pages/launcher/config-editor/post-setup/task-form/SelectTaskPhase';
import { useParams } from 'react-router-dom';

interface EditDueDateProps {
  initialValue?: ITaskItem;
  isFirstTask?: boolean;
  launchId?: string;
}

interface IProps extends EditDueDateProps {}

type EditDueDate = (props: IProps) => void;

export const useEditDueDateDrawer = (): EditDueDate => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const { locId } = useParams<{ locId: string }>();

  return useCallback(({ initialValue, isFirstTask, launchId }) => {
    console.log('Initial Value : ', initialValue);
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex align='center'>
          <Box flex={1}>
            <BoxHeader
              fontSize='18px'
              title={initialValue?.title?.trim() ? 'Update task' : 'Add task'}
              color='#CABDFF'
            />
          </Box>
          <Flex justify='flex-end' gap='8px'>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        </Flex>
      ),

      content: (
        <EditDueDateDrawer
          initialValue={initialValue}
          launchId={launchId}
          isFirstTask={isFirstTask}
          isEdit
          phaseComponent={SelectTaskPhase}
          layout='vertical'
          onClose={() => confirmRef.current?.destroy()}
          locId={locId}
        />
      ),

      size: 'full',
      contentProps: {
        padding: '12px',
        borderRadius: 0,
        bg: '#FFFFFF',
        containerProps: {
          maxW: '675px',
          left: 'unset',
          right: 0,
        },
      },
      footer: null,
    });
  }, []);
};
