import React, { FC, useState, useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  IconButton,
  useBreakpointValue,
  Tooltip,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FileEntity {
  mimetype?: string;
  name: string;
  type: string;
  url: string;
  fileSize: number;
}

interface AuditEvidenceEntity extends FileEntity {
  optionId?: string;
}

interface IProps {
  images: AuditEvidenceEntity[];
}

const ModalContent: FC<IProps> = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  const thumbnailRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handlePrev = () => {
    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setSelectedIndex((prev) => (prev < images.length - 1 ? prev + 1 : prev));
  };

  const handleThumbnailClick = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (thumbnailRefs.current[selectedIndex]) {
      thumbnailRefs.current[selectedIndex]?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  if (images.length === 0) {
    return <Box>No images available.</Box>;
  }

  const mainImageSize = useBreakpointValue({ base: '300px', md: '500px' });
  const thumbnailSize = useBreakpointValue({ base: '50px', md: '70px' });

  return (
    <Box display='flex' flexDirection='column' alignItems='center' width='100%'>
      <Box position='relative' width={mainImageSize} height={mainImageSize}>
        <Tooltip label='Previous Image' aria-label='Previous Image'>
          <IconButton
            icon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
            position='absolute'
            top='50%'
            left='-130px'
            transform='translateY(-50%)'
            onClick={handlePrev}
            isDisabled={selectedIndex === 0}
            aria-label='Previous Image'
            variant='ghost'
            size='lg'
          />
        </Tooltip>

        <Image
          src={images[selectedIndex].url}
          alt={images[selectedIndex].name}
          boxSize='100%'
          objectFit='cover'
          borderRadius='md'
          boxShadow='md'
        />

        <Tooltip label='Next Image' aria-label='Next Image'>
          <IconButton
            icon={<FontAwesomeIcon icon={faArrowRight as IconProp} />}
            position='absolute'
            top='50%'
            right='-130px'
            transform='translateY(-50%)'
            onClick={handleNext}
            isDisabled={selectedIndex === images.length - 1}
            aria-label='Next Image'
            variant='ghost'
            size='lg'
          />
        </Tooltip>
      </Box>

      <Flex
        mt={4}
        width={mainImageSize}
        overflowX='auto'
        px={2}
        ref={thumbnailContainerRef}
        css={{
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#a8a8a8',
          },
        }}
      >
        <Flex>
          {images.map((image, index) => (
            <Box
              key={index}
              ref={(el) => (thumbnailRefs.current[index] = el)}
              border={
                index === selectedIndex ? '2px solid' : '2px solid transparent'
              }
              borderColor={index === selectedIndex ? 'blue.500' : 'transparent'}
              borderRadius='md'
              overflow='hidden'
              cursor='pointer'
              onClick={() => handleThumbnailClick(index)}
              flexShrink={0}
              boxShadow='sm'
              _hover={{ borderColor: 'blue.300' }}
              mr={3}
            >
              <Image
                src={image.url}
                alt={image.name}
                boxSize={thumbnailSize}
                objectFit='cover'
                transition='transform 0.2s'
                _hover={{ transform: 'scale(1.05)' }}
                loading='lazy'
              />
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ModalContent;
