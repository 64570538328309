import React, { FC } from 'react';
import { Box, Flex, Switch } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuditHelper } from 'configs';
import { notLastStyle } from './BaseAuditQuesFooter';
import { IFormInput } from '../../form-types';
import { FlagResponse } from './flagged';
import { Evidence } from './evidence';

interface IProps {
  questionIndex: number;
}

const AuditFlagAndScoring: FC<IProps> = ({ questionIndex }) => {
  const { t } = useTranslation(['audit']);

  return (
    <Flex justify='flex-end' align='center' gap={8}>
      <Controller<IFormInput, `questions.${number}.hasFlaggedResponse`>
        name={`questions.${questionIndex}.hasFlaggedResponse`}
        render={({ field }) => {
          return (
            <Flex align='center' _notLast={notLastStyle}>
              <FlagResponse
                questionIndex={questionIndex}
                value={field.value}
                onChange={field.onChange}
                onBlur={AuditHelper.save(field.onBlur)}
              />
            </Flex>
          );
        }}
      />

      <Controller<IFormInput, `questions.${number}.hasScoring`>
        name={`questions.${questionIndex}.hasScoring`}
        render={({ field }) => {
          return (
            <Flex align='center' _notLast={notLastStyle}>
              <Box mr='8px' color='#33383f' fontWeight='600'>
                {t('audit:scoring')}
              </Box>
              <Switch
                size='md'
                isChecked={field.value}
                {...field}
                value={undefined}
                onBlur={AuditHelper.save(field.onBlur)}
                // isReadOnly={isReadOnly}
              />
            </Flex>
          );
        }}
      />

      <Controller<IFormInput, `questions.${number}.requiresEvidence`>
        name={`questions.${questionIndex}.requiresEvidence`}
        render={({ field }) => {
          return (
            <Flex align='center' _notLast={notLastStyle}>
              <Evidence
                questionIndex={questionIndex}
                value={field.value}
                onChange={field.onChange}
                onBlur={AuditHelper.save(field.onBlur)}
              />
            </Flex>
          );
        }}
      />
    </Flex>
  );
};

export default AuditFlagAndScoring;
