import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useQuestionsToSections } from './useQuestionsToSections';
import { useWatch } from 'react-hook-form';
import { IFormInput } from '../form-types';
import Section from '../../listing/template-preview/Section';
import MobileQuestionView from './MobileQuestionView';

interface IProps {}

const PreviewQuestionList: FC<IProps> = () => {
  const questions = useWatch<IFormInput, 'questions'>({
    name: 'questions',
  });

  const sections = useQuestionsToSections(questions);
  return (
    <Flex
      flexDir='column'
      boxSize='full'
      gap={1}
      overflow='auto'
      paddingBottom={6}
    >
      {sections.map((section, index) => (
        <div key={section.eid || `section-${index}`}>
          <Section
            sectionName={section.sectionName}
            noOfQuestions={section.questionCount}
          />

          <Flex flexDir='column' gap={3}>
            {section.questions?.map((question, index) => {
              if (question?.isDisabled) {
                return null;
              }
              return (
                <MobileQuestionView
                  key={question.eid || `question-${index}`}
                  questionIndex={index}
                  question={question}
                />
              );
            })}
          </Flex>
        </div>
      ))}
    </Flex>
  );
};

export default PreviewQuestionList;
