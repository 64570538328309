import React, { createContext, FC, useContext, useRef } from 'react';

import { createAuditTabStore, AuditTabAction } from './audit-tab.store';
import { useStore } from '../../../../zustandStore';

type AuditTabStore = ReturnType<typeof createAuditTabStore>;

const AuditTabContext = createContext<AuditTabStore | null>(null);

const AuditTabProvider: FC = ({ children }) => {
  const storeRef = useRef<AuditTabStore>();
  if (!storeRef.current) {
    storeRef.current = createAuditTabStore();
  }

  return (
    <AuditTabContext.Provider value={storeRef.current}>
      {children}
    </AuditTabContext.Provider>
  );
};

function useAuditTabContext<T>(selector: (state: AuditTabAction) => T): T {
  const store = useContext(AuditTabContext);
  if (!store) throw new Error('Missing AuditTabContext.Provider in the tree');
  return useStore(store, selector);
}

export { AuditTabProvider, useAuditTabContext };
