import React, { FC } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useApolloClient, useMutation } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import { AppRoute } from 'routes';

import {
  AccessRules,
  Frequency,
  TemplateSelect,
  TitleSection,
} from '../components';
import { IFormInput } from '../audit-schedule.types';
import { inputDataMapper } from '../data-mapper';
import { convertEditableData } from './convertEditableData';
import ScheduleEditorHeader from './ScheduleEditorHeader';
import { useAuditConfirmation } from './useAuditConfirmation';
import { getAuditAction } from './audit-setting.helpers';
import { AUDIT_QUERY, AUDIT_UPDATE } from './audit-setting.graphql';

const TEMPLATE_DATA = gql`
  query AuditTemplateById($eid: String!) {
    AuditTemplateById(eid: $eid) {
      eid
      title
    }
  }
`;

interface Params {
  auditId?: string;
}

interface IProps {}

const AuditSettings: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'audit']);
  const params = useParams<Params>();
  const client = useApolloClient();
  const history = useHistory();

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const methods = useForm<IFormInput>({
    defaultValues: async () => {
      const response = await client.query({
        query: AUDIT_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          eid: params.auditId,
        },
      });

      if (!response.data?.AuditById) {
        history.replace(AppRoute.AUDIT_LIST);
        toast({
          status: 'error',
          title: t('common:error'),
          description: 'The requested audit was not found.',
        });
        return Promise.reject('This audit is not found');
      }
      if (response.data?.AuditById?.status === 'AUDIT_DELETED') {
        history.goBack();
        toast({
          status: 'warning',
          title: t('common:warning'),
          description: 'The requested audit has been deleted.',
        });
        return Promise.reject('This audit is deleted');
      }

      const template = await client.query({
        query: TEMPLATE_DATA,
        fetchPolicy: 'network-only',
        variables: {
          eid: response.data?.AuditById?.templateId,
        },
      });

      const data = template?.data?.AuditTemplateById;

      return {
        ...convertEditableData(response.data?.AuditById),
        template: {
          value: data.eid,
          label: data.title,
        },
      };
    },
  });

  const [updateAudit] = useMutation(AUDIT_UPDATE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('audit:scheduleUpdateSuccess'),
      });
      history.goBack();
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('audit:scheduleUpdateFailed'),
      });
    },
  });

  const onSubmit = async (value: IFormInput) => {
    const input = inputDataMapper(value);

    // @ts-ignore
    delete input.templateId;
    // @ts-ignore
    delete input.auditType;

    // @ts-ignore
    input.eid = params.auditId;

    try {
      methods.control._disableForm(true);
      await updateAudit({
        variables: {
          input: input,
        },
      });
      await new Promise<void>((resolve) => setTimeout(resolve, 1000));
    } catch (err) {
      return Promise.reject(err);
    } finally {
      methods.control._disableForm(false);
    }
  };

  const auditConfirmation = useAuditConfirmation();

  const actionHandler = async (action: 'delete' | 'resume' | 'pause') => {
    const context = getAuditAction(action);

    auditConfirmation({
      ...context.modalProps,
      onConfirm: async () => {
        try {
          methods.control._disableForm(true);

          const response = await client.mutate({
            mutation: context.mutation,
            variables: {
              eid: params.auditId,
            },
          });

          if (response.errors) {
            return Promise.reject(response.errors);
          }

          methods.setValue('status', context.parseData(response.data).status);
          toast({
            status: 'success',
            title: t('common:success'),
            description: context.successMessage,
          });
          await new Promise<void>((resolve) => setTimeout(resolve));

          if (action === 'delete') {
            return history.replace(AppRoute.AUDIT_LIST);
          }
        } catch (err) {
          // @ts-ignore
          const message = err?.graphQLErrors?.[0]?.message;
          if (message?.includes('Audit')) {
            toast({
              status: 'error',
              title: t('common:error'),
              description: message,
            });
          } else {
            toast({
              status: 'error',
              title: t('common:error'),
              description: context.errorMessage,
            });
          }
          return Promise.reject(err);
        } finally {
          methods.control._disableForm(false);
        }
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <ScheduleEditorHeader
        actionHandler={actionHandler}
        onUpdate={methods.handleSubmit(onSubmit)}
      />
      <Flex justify='center' bg='white' padding='32px' mt='20px'>
        <Flex w='70%' flexDir='column' gap={4}>
          <TemplateSelect isEditing />
          <Frequency isEditing />
          <AccessRules />
          <TitleSection />
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default AuditSettings;
