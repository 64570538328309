import { Flex } from '@chakra-ui/react';
import { PerformanceAnalytics } from 'pages/Audits/Detail/components/PerformanceAnalytics';
import React, { FC, useEffect } from 'react';
import { HeaderColors } from 'shared/headerColors';
import { SessionDetailEntity } from 'sub-components/audits/take-audit/types';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { AuditorSection } from './AuditorSection';
import LocationReportSection from './LocationReportSection';
import SummarySection from './SummarySection/SummarySection';
import LocationPerformanceGraph from './LocationPerformanceGraph';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';

interface IProps {
  auditData: AuditSessionEntity | undefined;
  refetchHandler?: () => void;
}

const OneTimeAuditWithScore: FC<IProps> = ({ auditData, refetchHandler }) => {
  const updateShowLocationsFilter = useAuditDetailContext(
    (state) => state.updateShowLocationsFilter
  );

  useEffect(() => {
    updateShowLocationsFilter(true);
  }, []);

  return (
    <Flex gap={4} flexDir='column' w='full'>
      <Flex
        id='one-time-audit-with-score-container'
        justify='space-between'
        h='316px'
      >
        <Flex w='33%'>
          <SummarySection auditData={auditData} />
        </Flex>
        <Flex w='33%'>
          <PerformanceAnalytics auditData={auditData} />
        </Flex>
        <Flex w='33%'>
          <AuditorSection auditData={auditData} />
        </Flex>
      </Flex>
      <Flex>
        <LocationPerformanceGraph auditData={auditData} />
      </Flex>
      <Flex>
        <LocationReportSection
          auditData={auditData}
          hideFooter
          refetchHandler={refetchHandler}
        />
      </Flex>
    </Flex>
  );
};

OneTimeAuditWithScore.displayName =
  'sub-components/audits/detail/components/Overview/components/OneTimeAuditWithScore';

export default OneTimeAuditWithScore;
