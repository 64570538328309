import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toArray } from '../../../../../../../utils';
import { useLocationDrawer } from '../../../../../../../sub-components/audits/detail/create-task';
import { SessionDetailEntity } from '../../../../../../../sub-components/audits/take-audit/types';
import { usersEntityObj } from '../../../../../../../sub-components/Header';
import { QuestionResponseSectionEntity } from '../../types/audit-question.types';

export const useControlLayer = (
  auditData: SessionDetailEntity | undefined,
  refetchHandler?: () => void
) => {
  const entityObj = useReactiveVar(usersEntityObj);
  const sessionId = useParams<{ auditId: string; sessionId: string }>()
    ?.sessionId;

  const { openDrawer, LocationDrawerComponent } = useLocationDrawer();

  const createTaskHandler = (question: QuestionResponseSectionEntity) => {
    const responses = toArray(question?.responsesByLocation).filter(
      (res) => res?.isFlagged
    );

    openDrawer({
      auditId: auditData?.auditId!,
      auditName: auditData?.title!,
      qid: question?.questionId,
      sessionId: sessionId,

      questionTitle: question?.questionText,
      flaggedLocationIds: responses.map((res) => res?.locationId),

      responses: responses,

      onTaskSuccess: () => {
        refetchHandler?.();
      },
    });
  };
  return {
    entityObj,
    createTaskHandler,
    LocationDrawerComponent,
  };
};
