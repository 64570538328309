import { gql } from '@apollo/client';
import { AuditRepeatCycle, AuditRepeatEntity } from '../../../types';

interface Auditor {
  assignType: 'selected' | 'all';
  locations: string[];
  userId: string[];
}

export enum CustomRepeatType {
  'EVERY_N_DAYS' = 'EVERY_N_DAYS',
  'EVERY_N_WEEKS' = 'EVERY_N_WEEKS',
  'EVERY_N_MONTHS' = 'EVERY_N_MONTHS',
}

interface CustomRepeatEntity {
  type: CustomRepeatType;

  dayOfMonth?: number;
  months?: number;

  days?: number;
  // time: string;

  weekDay?: number;
  weeks?: number;
}

export interface AuditCreateInput {
  title: string;
  // description: string;
  // thumbnail: string;
  templateId: string;

  auditType: 'repeating' | 'oneTime' | 'forever';

  customRepeat?: CustomRepeatEntity;
  endCondition?: {
    endDate: string;
    endType: 'never' | 'repeat' | 'date';
  };

  hasCustomRepeat: boolean;
  repeatCycle?: AuditRepeatCycle;
  repeatDetails: AuditRepeatEntity<string>;
  repeatString: string;

  auditors: Auditor[];
  notifyLO: boolean;
}

export interface AuditCreateVariable {
  input: AuditCreateInput;
}

export const AUDIT_CREATE = gql`
  mutation CreateAudit($input: NewAuditInputInput) {
    CreateAudit(input: $input) {
      eid
    }
  }
`;
