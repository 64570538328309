import { useMutation } from '@apollo/client';
import {
  Box,
  Flex,
  Textarea,
  FormControl,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { Button } from 'atoms';
import { useUserDataSelector } from 'hooks';
import React, { FC } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SEND_REMINDER_TO_AUDITOR } from './send-reminder.graphql';
import {
  SendDirectMessageResponse,
  SendDirectMessageVariables,
} from './send-reminder.types';
import { AuditorDetails } from './useSendReminder';

interface IProps {
  auditorDetails: AuditorDetails | undefined;
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

interface IFormInput {
  message: string;
}

const ModalContent: FC<IProps> = ({
  auditorDetails,
  onClose,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation(['common']);
  const loggedInUserEid = useUserDataSelector((state) => state?.eid);
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const [remind, { data, loading, error }] = useMutation<
    SendDirectMessageResponse,
    SendDirectMessageVariables
  >(SEND_REMINDER_TO_AUDITOR, {
    onCompleted: (data) => {
      if (data?.sendDirectMessage?.succeed) {
        toast({
          status: 'success',
          title: t('success'),
          description: 'Auditor reminded successfully',
        });
        onSuccess?.();
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('error'),
        description: 'Auditor could not be reminded. Please try again later',
      });
      onError?.();
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (auditorDetails?.eid) {
      console.log('Message Sent:', data.message);
      remind({
        variables: {
          message: data?.message,
          senderId: loggedInUserEid,
          receiverIds: [auditorDetails?.eid],
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir='column' gap={4}>
        <Flex gap={4}>
          <Box as='span' fontWeight={600} fontSize='15px'>
            To:
          </Box>
          <Box
            bg='rgba(244, 244, 244, 1)'
            fontWeight={500}
            p='2px 8px'
            borderRadius='6px'
          >
            {auditorDetails?.name}
          </Box>
        </Flex>

        <FormControl isInvalid={!!errors.message}>
          <Controller
            name='message'
            control={control}
            rules={{ required: 'Message is required' }}
            render={({ field }) => (
              <Textarea
                {...field} // Spread the Controller field props
                placeholder='Add message'
                bg='rgba(244, 244, 244, 1)'
                resize='vertical'
              />
            )}
          />
          <FormErrorMessage>
            {errors.message && errors.message.message}
          </FormErrorMessage>
        </FormControl>

        <Flex gap={4}>
          <Button
            w='50%'
            size='lg'
            variant='outline'
            borderRadius='12px'
            type='button'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            w='50%'
            size='lg'
            variant='solid'
            colorScheme='blue'
            borderRadius='12px'
            type='submit'
            disabled={loading}
            isLoading={loading}
          >
            Send
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default ModalContent;
