import React, { FC } from 'react';
import { Box, Flex, keyframes, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useFormState } from 'react-hook-form';
import { Button } from 'atoms';
import { useHistory } from 'routes';
import { headerConfig } from '../common';
import { ActionButton } from '../../../ui-components';

import { IFormInput } from '../create-audit/form-types';

import { ReactComponent as BackIcon } from '../../../assets/images/chevronBackward.svg';
import { ReactComponent as SyncIcon } from '../../../assets/images/sync-sharp.svg';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

interface Params {
  templateId: string;
}

interface IProps {
  onUpdateClick?: () => void;
}

const EditQuestionHeader: FC<IProps> = ({ onUpdateClick }) => {
  const history = useHistory();
  const params = useParams<Params>();

  const { isSubmitting, isDirty } = useFormState<IFormInput>();

  return (
    <Flex flexDir='column' gap={2} {...headerConfig}>
      <Button
        fontSize='14px'
        fontWeight={500}
        width='max-content'
        variant='link'
        leftIcon={<BackIcon width='16' height='16' />}
        _hover={{
          textDecoration: 'none',
        }}
        onClick={history.goBack}
      >
        Back to Audit details
      </Button>

      <Flex align='center' justify='space-between'>
        <Flex align='center' gap={3}>
          <Text fontWeight={600} fontSize='28px'>
            Edit Audit questions
          </Text>
          <Flex
            hidden={!isSubmitting}
            align='center'
            gap='6px'
            fontSize='12px'
            color='#6F767E'
          >
            <Box animation={`${spin} 1s linear infinite`} boxSize={4}>
              <SyncIcon />
            </Box>
            Saving
          </Flex>
        </Flex>

        <ActionButton
          variant='solid'
          colorScheme='blue'
          actionFn={onUpdateClick}
          isDisabled={!isDirty}
        >
          Update Questions
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default EditQuestionHeader;
