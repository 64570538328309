import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface IProps {
  sectionName: string;
  noOfQuestions: number;
}

const Section: FC<IProps> = ({ sectionName, noOfQuestions }) => {
  return (
    <Flex
      position='sticky'
      top={0}
      zIndex='2'
      flexDir='column'
      padding='16px 24px'
      bg='#F3F3F3'
    >
      <Flex fontSize='18px' fontWeight='600'>
        {sectionName}
      </Flex>

      <Flex
        fontSize='12px'
        fontWeight={500}
        color='#6F767E'
        align='center'
        gap='8px'
      >
        <Box color='#1A1D1F' fontWeight={600}>
          0/{noOfQuestions}
        </Box>
        <Box>Question</Box>

        {/*<Box boxSize={1} borderRadius='full' bg='#6F767E' />*/}
        {/*<Box color='#1A1D1F' fontWeight={600}>*/}
        {/*  0/45*/}
        {/*</Box>*/}
        {/*<Box>Points</Box>*/}

        <Box boxSize={1} borderRadius='full' bg='#6F767E' />
        <Box color='#1A1D1F' fontWeight={600}>
          0%
        </Box>
        <Box>Score</Box>
      </Flex>
    </Flex>
  );
};

export default Section;
