import React, { FC } from 'react';
import {
  Flex,
  useToast,
  FormControl,
  FormErrorMessage,
  Box,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'atoms';
import { EMAIL_REGEX } from 'utils/constants';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import {
  SendInviteResponse,
  SendInviteVariable,
  SEND_INVITE_QUERY,
} from './invite.graphql';
import { IFormInput } from './invite.types';
import { ActionButton } from '../Confirm';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const PhoneEmailTab: FC = () => {
  const { control, getValues, setValue, trigger } =
    useFormContext<IFormInput>();
  const { t } = useTranslation(['common', 'invite', 'profile', 'location']);
  const toast = useToast();

  const smsSent = useWatch<IFormInput, 'linkSend.phone'>({
    name: 'linkSend.phone',
  });
  const emailSent = useWatch<IFormInput, 'linkSend.email'>({
    name: 'linkSend.email',
  });

  const [sendSmsInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('success'),
          description: t('invite:invite_send_to_success'),
        });
        setValue('linkSend.phone', true);
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('error'),
        });
      },
    }
  );

  const [sendEmailInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('success'),
          description: t('invite:invite_send_to_success'),
        });
        setValue('linkSend.email', true);
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('error'),
        });
      },
    }
  );

  const handleSMSClick = async () => {
    const isValid = await trigger('mobile');

    deployEvent(AmplitudeEventNames.MEMBER_SEND_EMAIL_SMS, {
      add_member_funnel_id: 5,
      resend_funnel_id: 7,
      member_id: getValues('invitedUserId'),
      sms_or_email: 'SMS',
    });

    if (isValid && !getValues('linkSend.phone')) {
      await sendSmsInvite({
        variables: {
          input: {
            eid: getValues('invitedUserId')!,
            // contact: `+1${getValues('mobile')}`,
            contact: getValues('mobile')?.includes('+')
              ? getValues('mobile')
              : `+1${getValues('mobile')}`,
            type: 'sms',
          },
        },
      });
    }
  };

  const handleEmailClick = async () => {
    const isValid = await trigger('email');

    deployEvent(AmplitudeEventNames.MEMBER_SEND_EMAIL_SMS, {
      add_member_funnel_id: 5,
      resend_funnel_id: 7,
      member_id: getValues('invitedUserId'),
      sms_or_email: 'EMAIL',
    });

    if (isValid && !getValues('linkSend.email')) {
      await sendEmailInvite({
        variables: {
          input: {
            eid: getValues('invitedUserId')!,
            contact: getValues('email'),
            type: 'email',
          },
        },
      });
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Controller
        name='mobile'
        control={control}
        rules={{
          required: t('profile:validation.phoneRequired'),
          pattern: {
            // value: /^[0-9]{10}$/,
            value: /^[0-9()+\- ]{10,20}$/,
            message: t('location:validation.phone_invalid'),
          },
        }}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <TitleHeader title={t('invite:inviteViaPhoneNumber')} />
              <Box h={1.5} />
              <FormInput
                size='lg'
                placeholder='556 456 3456'
                {...field}
                rightElementProps={{
                  width: 'auto',
                  pr: '6px',
                }}
                rightIcon={
                  <ActionButton
                    width='fit-content'
                    variant='solid'
                    colorScheme={smsSent ? 'green' : 'blue'}
                    borderRadius='10px'
                    height='36px'
                    lineHeight='unset'
                    size='sm'
                    actionFn={handleSMSClick}
                    isDisabled={!field.value}
                  >
                    {t(smsSent ? 'common:sent' : 'invite:sendSMS')}
                  </ActionButton>
                }
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Controller
        name='email'
        control={control}
        rules={{
          required: t('common:validation.email_required'),
          pattern: {
            value: EMAIL_REGEX,
            message: t('common:validation.email_invalid'),
          },
        }}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <TitleHeader title={t('invite:inviteViaEmail')} />
              <Box h={1.5} />
              <FormInput
                size='lg'
                placeholder='joey@gmail.com'
                {...field}
                rightElementProps={{
                  width: 'auto',
                  pr: '6px',
                }}
                rightIcon={
                  <ActionButton
                    width='fit-content'
                    variant='solid'
                    colorScheme={emailSent ? 'green' : 'blue'}
                    borderRadius='10px'
                    height='36px'
                    lineHeight='unset'
                    size='sm'
                    actionFn={handleEmailClick}
                    isDisabled={!field.value}
                  >
                    {t(emailSent ? 'common:sent' : 'invite:sendEmail')}
                  </ActionButton>
                }
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Box bg='#B1E5FC66' borderRadius='10px' p='12px 20px'>
        <Text>Invitation link will expire in 72 hours</Text>
      </Box>
    </Flex>
  );
};

export default PhoneEmailTab;
