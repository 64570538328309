import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Box, Flex, Image, Tooltip, useToast } from '@chakra-ui/react';
import { ColumnsType, Table, useChangeHandler } from 'atoms';
import { useSafeState } from 'hooks';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HeaderColors } from 'shared/headerColors';
import { SessionDetailEntity } from 'sub-components/audits/take-audit/types';
import EmptyState from 'sub-components/EmptyState';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { usersEntityObj } from 'sub-components/Header';
import { PageInfo } from 'types';
import { formatNumber, getImage } from 'utils';
import { LO_AUDIT_HISTORY } from './graphql/lo-audit-history.graphql';
import { ReactComponent as DownloadIcon } from '../../../../assets/images/audit-download-green.svg';
import { ReactComponent as AuditEye } from '../../../../assets/images/audit-solid-view.svg';
import RangeDatePicker from 'sub-components/audits/range-date-picker';
import { DateRange } from 'react-day-picker';
import DownloadReport from 'sub-components/audits/common/download-report';

interface LOHistoryResponse {
  AuditSessionPagination: {
    count: number;
    items: SessionDetailEntity[];
    pageInfo: PageInfo;
  };
}

interface LOHistoryResponseVariables {
  page: number;
  perPage: number;
  locationIds?: string[];
  filter: {
    auditIds: string[];
    locations?: string[];
    startDate?: string;
    endDate?: string;
  };
}

interface LOAuditData {
  count: number;
  pageInfo: PageInfo;
  items: LOAuditHistoryItem[];
}

interface LOAuditHistoryItem {
  eid: string;
  dueDate: string;
  auditors: {
    eid: string;
    name: string;
    profilePic: string;
  }[];
  scoreData: {
    achieved: number;
    total: number;
    completedPercent: string;
  };
  reportsUrl: string;
  isCompleted: boolean;
}

const LOAuditHistory: FC<{ isAuditScored: boolean }> = ({ isAuditScored }) => {
  const history = useHistory();
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    undefined
  );
  const entityData = useReactiveVar(usersEntityObj);
  const [currentPage, setCurrentPage] = useSafeState(1);
  const [getLOAuditHistory, { data, loading, refetch }] = useLazyQuery<
    LOHistoryResponse,
    LOHistoryResponseVariables
  >(LO_AUDIT_HISTORY, {
    fetchPolicy: 'network-only',
  });

  const { auditId, sessionId, locationId } = useParams<{
    auditId: string;
    sessionId: string;
    locationId: string;
  }>();

  useEffect(() => {
    if (locationId) {
      getLOAuditHistory({
        variables: {
          page: currentPage,
          perPage: 1000,
          locationIds: [locationId],
          filter: {
            auditIds: [auditId],
            locations: [locationId],
            startDate:
              moment(selectedRange?.from)?.isValid() && selectedRange?.from
                ? moment(selectedRange?.from)
                    .utcOffset(0, true)
                    .startOf('day')
                    .toISOString()
                : undefined,
            endDate:
              moment(selectedRange?.to)?.isValid() && selectedRange?.to
                ? moment(selectedRange?.to)
                    .utcOffset(0, true)
                    .endOf('day')
                    .toISOString()
                : undefined,
          },
        },
      });
    }
  }, [auditId, locationId, selectedRange]);

  const historyData = useMemo((): LOAuditData => {
    let obj: LOAuditData = {
      count: data?.AuditSessionPagination?.count || 0,
      pageInfo: data?.AuditSessionPagination?.pageInfo || ({} as PageInfo),
      items: [],
    };
    obj.items =
      data?.AuditSessionPagination?.items?.map((item) => {
        let maxScore = 0;
        let baggedScore = 0;
        let completedPercent = '0';
        let completedDate = '';
        item?.assignments?.map((assignment) => {
          maxScore += assignment?.maxScore;
          baggedScore += assignment?.totalScore;
          completedPercent = formatNumber(
            (assignment?.totalScore / assignment?.maxScore) * 100 || 0
          );
          completedDate = assignment?.completedAt;
        });
        // let foundAuditors = entityData
        //   ?.filter((entity) =>
        //     item?.auditors?.some((auditor) => auditor?.eid === entity?.eid)
        //   )
        //   ?.map((user) => {
        //     return {
        //       eid: user?.eid,
        //       name: user?.name,
        //       profilePic: user?.profilePic,
        //     };
        //   });
        let auditCompletedBy: string;
        item?.assignments?.map((assignment) => {
          if (assignment?.completedBy) {
            auditCompletedBy = assignment?.completedBy;
          }
          return;
        });
        let foundAuditors = entityData
          ?.filter((entity) => auditCompletedBy === entity?.eid)
          ?.map((user) => {
            return {
              eid: user?.eid,
              name: user?.name,
              profilePic: user?.profilePic,
            };
          });
        let reportsUrl = '';
        let foundLocation = item?.assignments?.find(
          (assignment) => assignment?.locationId === locationId
        );
        let isCompleted = false;
        if (foundLocation) reportsUrl = foundLocation?.reportsUrl;
        isCompleted = !!foundLocation?.isCompleted;

        return {
          eid: item?.eid,
          dueDate: moment(completedDate)?.isValid()
            ? moment.utc(completedDate)?.format('DD MMM, YYYY')
            : moment(item?.dueDate)?.isValid()
            ? moment.utc(item?.dueDate)?.format('DD MMM, YYYY')
            : '-',
          auditors: foundAuditors,
          scoreData: {
            achieved: baggedScore,
            total: maxScore,
            completedPercent: completedPercent,
          },
          reportsUrl,
          isCompleted,
        };
      }) || [];
    return obj;
  }, [data, entityData]);

  const columns: ColumnsType<LOAuditHistoryItem> = [
    {
      title: 'Date',
      dataIndex: 'dueDate',
    },
    {
      title: 'Auditor',
      dataIndex: 'auditor',
      key: 'auditor',
      render: (_, record) => {
        console.log('RECORD : ', record);
        // return <AuditAssignee assignees={record?.auditors} />;
        return record?.auditors?.[0]?.name ? (
          <Flex align='center' gap='5px'>
            <Image
              src={getImage(
                record?.auditors?.[0]?.profilePic,
                record?.auditors?.[0]?.name
              )}
              boxSize='30px'
              borderRadius='50%'
            />
            <Box>{record?.auditors?.[0]?.name}</Box>
          </Flex>
        ) : (
          <>-</>
        );
      },
    },
    ...(isAuditScored
      ? [
          {
            title: 'Score',
            dataIndex: 'scoreData',
            key: 'scoreData',
            render: (_: any, record: LOAuditHistoryItem) => (
              <Flex>
                <Box>
                  {record?.scoreData?.completedPercent}% -{' '}
                  {record?.scoreData?.achieved}/{record?.scoreData?.total}
                </Box>
              </Flex>
            ),
          },
        ]
      : []),
    {
      title: '',
      dataIndex: 'eid',
      key: 'eid',
      render: (_, record) =>
        record?.isCompleted ? (
          <Flex align='center' gap='30px' justify='flex-end' mr={4}>
            {/* <FontAwesomeIcon
            icon={faEye as IconProp}
            color='rgba(111, 118, 126, 1)'
          /> */}
            <Box
              cursor='pointer'
              onClick={() => {
                history.push(
                  `/audits/report/lI/${locationId}/sI/${record?.eid}`
                );
              }}
            >
              <Tooltip label='View Report' hasArrow placement='top'>
                <AuditEye />
              </Tooltip>
            </Box>
            <DownloadReport
              disabled={!record?.isCompleted}
              locationId={locationId}
              sessionId={record?.eid}
              callbackFn={refetch}
              reportsUrl={record?.reportsUrl}
              tooltipLabel={
                record?.isCompleted ? 'Download' : 'Audit not completed yet'
              }
            >
              <DownloadIcon />
            </DownloadReport>
          </Flex>
        ) : (
          <Flex align='center' justify='flex-end' mr='50px'>
            -
          </Flex>
        ),
    },
  ];

  const onChange = useChangeHandler<LOAuditHistoryItem>(
    (pagination, filters, _sorter, extra) => {
      switch (extra.action) {
        case 'paginate':
          setCurrentPage(pagination.current!);
          //   auditTemplates({
          //     variables: {
          //       ...inputVariables,
          //       page: pagination.current,
          //     },
          //   });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(pagination, filters, _sorter, extra);
      }
    }
  );

  const updateDateRange = (range: DateRange | undefined) => {
    setSelectedRange(range);
  };

  return (
    <Flex
      border='.5px solid rgba(199, 199, 199, 1)'
      borderRadius='10px'
      p={4}
      flexDir='column'
      w='full'
      gap={4}
    >
      <Flex align='center' justify='space-between'>
        <GenericColorHeader title='Audit History' color={HeaderColors.Purple} />
        <RangeDatePicker
          selectedRange={selectedRange}
          updateDateRange={() => {}}
          onPopoverClose={(range) => updateDateRange(range)}
        />
      </Flex>
      <Table
        isLoading={loading}
        columns={columns}
        dataSource={historyData?.items}
        onChange={onChange}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
          total: historyData?.count,
        }}
        emptyState={<EmptyState image='MyTask' title='No audits found' />}
        rowKey={(record) => record?.eid}
      />
    </Flex>
  );
};

LOAuditHistory.displayName =
  'sub-components/audits/location-owner/AuditHistory/LOAuditHistory';

export default LOAuditHistory;
