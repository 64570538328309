import React, { FC, MouseEvent, useState } from 'react';
import { useMountedRef } from 'hooks';
import { isThenable } from 'utils';
import { Button, ButtonProps } from 'atoms';
import { Any } from '../../types';

interface IProps extends Omit<ButtonProps, 'onClick'> {
  close?: Function;
  actionFn?: (...args: Any[]) => Any | PromiseLike<Any>;
}

const ActionButton: FC<IProps> = ({
  children,
  close,
  actionFn,
  isLoading: nativeLoad,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const mounted = useMountedRef();

  const internalClose = (...args: Any[]) => {
    close?.(...args);
  };

  const handlePromise = (returnValue: PromiseLike<Any>) => {
    if (!isThenable(returnValue)) {
      return;
    }
    setIsLoading(true);
    returnValue?.then(
      (...args: Any[]) => {
        mounted.current && setIsLoading(false);
        internalClose(...args);
      },
      (e: Error) => {
        mounted.current && setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClick = (_: MouseEvent<HTMLButtonElement>) => {
    if (!actionFn) {
      internalClose();
      return;
    }
    let returnValue;
    if (actionFn.length) {
      returnValue = actionFn(close);
    } else {
      returnValue = actionFn();
      if (!returnValue) {
        internalClose();
        return;
      }
    }
    handlePromise(returnValue);
  };

  const loading = isLoading || nativeLoad;

  return (
    <Button
      {...props}
      isLoading={loading}
      onClick={loading ? undefined : onClick}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
