import moment from 'moment';
import cronTime from 'cron-time-generator';
import { match } from 'ts-pattern';
import { IFormInput } from './audit-schedule.types';

const L = 'L' as unknown as number;

export const getScheduleRepeatString = (
  values: IFormInput['repeatDetails']
): string => {
  if (values.auditFrequency === 'oneTime') {
    return match(values?.auditDue!)
      .with('nextWeek', () => {
        let NextMon = moment()
          .startOf('isoWeek')
          .add(1, 'week')
          .day('saturday');

        return cronTime.everyYearIn(
          NextMon.get('month') + 1,
          NextMon.get('date'),
          23,
          59
        );
      })
      .with('endMonth', () => cronTime.everyMonthOn(L, 23, 59))
      .with('endYear', () => cronTime.everyYearIn(12, 31, 23, 59))
      .with('date', () => {
        const dateD = moment(values?.auditDueDate);

        return cronTime.everyYearIn(
          dateD.get('month') + 1,
          dateD.get('date'),
          23,
          59
        );
      })
      .with('anyTime', () => '')
      .otherwise(() => '');
  }

  if (values.auditFrequency === 'repeating') {
    return match(values?.repeatType!)
      .with('weekly', () =>
        cronTime.onSpecificDaysAt(values.weekly as string[], 23, 59)
      )
      .with('monthly', () => {
        const monthly = values.monthly;
        return cronTime.everyMonthOn(
          monthly === 'endMonth' ? L : monthly === 'middleMonth' ? 15 : 1,
          23,
          59
        );
      })
      .with('yearly', () =>
        cronTime.everyYearIn(
          moment(values.yearly).get('month') + 1,
          moment(values.yearly).get('date'),
          23,
          59
        )
      )
      .with('custom', () => '')
      .otherwise(() => '');
  }

  return '';
};
