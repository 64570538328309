import React, { FC, useRef, useState } from 'react';
import { Box, Flex, IconButton, Image, Spacer } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import _uniqueId from 'lodash/uniqueId';

import { Button } from '../../../../../atoms';
import {
  CancelTokenSource,
  useSafeState,
  useUploadImage,
} from '../../../../../hooks';
import { getFileIcon, toArray } from '../../../../../utils';

import { useFileName } from '../../../../forms/from-preview/component/file-name';
import { ReactComponent as FileIcon } from '../../../../../assets/images/profile-cloud-upload.svg';
import { AuditTake } from '../../tabke-audit.helpers';

interface IProps {
  onChange: (value: string[]) => void;
  value: string[];
}

const FileUpload: FC<IProps> = ({ value, onChange }) => {
  const [inputId] = useState(_uniqueId('file-upload-'));
  const cancelRef = useRef<CancelTokenSource>();

  const [isUploading, setIsUploading] = useSafeState(false);
  const [_fileName, setFileName] = useSafeState<string>();

  const uploadFile = useUploadImage();

  // @ts-ignore
  const fileName = useFileName(_fileName || value);

  const _onChange = async (fileList: FileList) => {
    if (fileList && fileList?.length) {
      cancelRef.current = useUploadImage.CancelToken();
      setFileName(fileList[0].name);
      setIsUploading(true);

      AuditTake.updateProgress(inputId, true);

      try {
        const fileUrl = await uploadFile(
          fileList[0],
          undefined,
          undefined,
          cancelRef.current
        );
        onChange(toArray(fileUrl));

        setFileName(fileUrl);
        setIsUploading(false);
      } catch (e) {
        setFileName(undefined);
        setIsUploading(false);
      } finally {
        AuditTake.updateProgress(inputId, false);
      }
    }
  };

  if (fileName) {
    return (
      <Flex
        align='center'
        height='40px'
        bg='#DCF5FF'
        px={3}
        borderRadius='8px'
        gap={2}
      >
        <Image
          src={getFileIcon(fileName.split('.').pop(), false)}
          boxSize={7}
          objectFit='cover'
        />
        <Box
          as='span'
          color='#272B30'
          fontWeight='500'
          textDecoration='underline'
          overflow='hidden'
        >
          <Flex>
            <span
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {fileName.slice(0, -20)}
            </span>
            <Box as='span' whiteSpace='nowrap'>
              {fileName.slice(-20)}
            </Box>
          </Flex>
        </Box>
        <Spacer />
        <IconButton
          ml={1}
          size='xs'
          variant='ghost'
          aria-label={'remove'}
          icon={<CloseIcon />}
          isLoading={isUploading}
          // onClick={() => onRemove?.()}
        />
        {isUploading && (
          <IconButton
            ml={1}
            size='xs'
            variant='ghost'
            aria-label={'remove'}
            icon={<CloseIcon />}
            onClick={() => cancelRef.current?.cancel()}
          />
        )}
      </Flex>
    );
  }

  return (
    <div>
      <label htmlFor={inputId}>
        <Button
          width='full'
          as='div'
          borderRadius='12px'
          h='48px'
          leftIcon={<FileIcon width='20' height='20' color='#33383F' />}
          fontWeight='400'
          cursor='pointer'
        >
          Add file
        </Button>
      </label>

      {!isUploading ? (
        <input
          disabled={isUploading}
          type='file'
          onChange={(event) => _onChange(event.target.files!)}
          id={inputId}
          style={{ display: 'none' }}
          accept='application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        />
      ) : null}
    </div>
  );
};

export default FileUpload;
