import { formatDateTime } from '../listing/helper';
import {
  AuditOptionsEntity,
  AuditQuestionsEntity,
  CompleteResponseImageType,
  FinalDataResponseType,
  ResponseType,
  SectionDetailsResponseType,
} from './types/report.types';

const initialNewSectionData = {
  title: '',
  answer: '',
  noteText: '',
  qType: undefined,
  media: [],
  url: '',
  isFlagged: false,
  responses: [],
  flaggedResponseCount: 0,
  obtainedScore: 0,
  totalAuditScore: 0,
  score: '',
};

export const formatHeadingAndDetails = (
  item: any,
  mappedData?: any,
  additionalData?: any
) => {
  if (item[0] === 'score') {
    return {
      heading: item[1]?.title,
      details: `${
        item?.[1]?.obtainedScore
          ? ((item?.[1]?.obtainedScore / item[1].totalScore) * 100).toFixed(2)
          : 0
      }% (${item?.[1]?.obtainedScore ? item?.[1]?.obtainedScore : 0}/${
        item[1].totalScore ? item[1].totalScore : 0
      })`,
    };
  }

  if (item[0] === 'flaggedItemCount') {
    return {
      heading: item[1]?.title,
      details: `${
        item?.[1]?.count
          ? item?.[1]?.count > 1
            ? item?.[1]?.count + ' flags'
            : item?.[1]?.count + ' flag'
          : '--'
      }`,
    };
  }

  if (item[0] === 'assignedTo') {
    return {
      heading: item[1]?.title,
      details: `${item[1]?.name ? item[1]?.name : '--'}/${
        item[1]?.location ? item[1]?.location : '--'
      }/${item[1]?.role ? item[1]?.role : '--'}`,
    };
  }

  if (item[0] === 'reportNumber') {
    return {
      heading: item[1]?.title,
      details: `${item[1]?.reportNumber ? item[1]?.reportNumber : '--'}`,
    };
  }

  if (item[0] === 'location') {
    let addressString = '';
    if (item?.[1]?.address) {
      const { city, state, zipCode } = item?.[1]?.address;
      addressString = `${city}, ${state}, ${zipCode}`;
    }

    if (addressString?.length > 0) {
      let temp = addressString?.replaceAll(',', '').replaceAll(' ', '');
      if (!temp) {
        addressString = '--';
      }
    }

    return {
      heading: item[1]?.title,
      details: addressString,
    };
  }

  if (item[0] === 'conductedOn') {
    return {
      heading: item[1]?.title,
      details: `${
        item[1]?.date
          ? formatDateTime(item[1]?.date, additionalData?.timeZone).dateString
          : '--'
      }`,
    };
  }

  if (item[0] === 'conductedBy') {
    return {
      heading: item[1]?.title,
      details: `${
        item?.[1]?.userDetails?.[0]?.name
          ? item?.[1]?.userDetails?.[0]?.name
          : '--'
      }`,
    };
  }

  return {
    heading: '--',
    details: '--',
  };
};

export const generateValidId = (item: string) => {
  item = item.replaceAll('?', '').replaceAll('.', '');

  return (
    item?.trim()?.length > 0 && item.trim().replaceAll(' ', '-').toLowerCase()
  );
};

export const mapResponse = (
  options: AuditQuestionsEntity[],
  responseIds: any
) => {
  const data = responseIds?.map((responseId: any) => {
    return options?.filter((option: any) => option?.eid === responseId);
  });
  return data;
};

export const mapQuestionAnswer = (
  question: AuditQuestionsEntity,
  responses: ResponseType[]
) => {
  let response = responses?.find(
    (item: ResponseType) => item?.qid === question?.eid
  );

  const tempObj: SectionDetailsResponseType = {
    qType: question?.qType,
    title: question?.label,
  };

  let selectedResponse = mapResponse(question?.options, response?.responseId);

  if (question?.qType === 'multiChoice') {
    tempObj['answer'] = selectedResponse?.[0]?.[0]?.label;
    tempObj['media'] = response?.images?.map((item: ResponseType) => item?.url);
    tempObj['noteText'] = response?.noteText;
    tempObj['isFlagged'] = response?.isFlagged;
    tempObj['responses'] = [];
  } else if (question?.qType === 'checkBox') {
    tempObj['answer'] = selectedResponse?.map((item: ResponseType) => {
      return { title: item?.[0]?.label, status: true };
    });
    tempObj['media'] = response?.images?.map((item: ResponseType) => item?.url);
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'shortText') {
    tempObj['answer'] = response?.response;
    tempObj['media'] = response?.images?.map((item: ResponseType) => item?.url);
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'longText') {
    tempObj['answer'] = response?.response;
    tempObj['media'] = response?.images?.map((item: ResponseType) => item?.url);
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'dropDown') {
    tempObj['answer'] = selectedResponse?.map((item: ResponseType) => {
      return { title: item?.[0]?.label };
    });
    tempObj['media'] = response?.images?.map((item: ResponseType) => item?.url);
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'fileUpload') {
    tempObj['answer'] = response?.images?.[0]?.name;
    tempObj['url'] = response?.responseUrl?.[0];
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'member') {
    tempObj['answer'] = response?.responseId;
    tempObj['url'] = response?.images?.[0]?.url;
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'location') {
    tempObj['answer'] = response?.responseId;
    tempObj['url'] = response?.images?.[0]?.url;
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'videoUpload') {
    tempObj['answer'] = response?.images?.[0]?.name;
    tempObj['url'] = response?.responseUrl?.[0];
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  } else if (question?.qType === 'imageUpload') {
    tempObj['media'] = response?.images?.map((item: ResponseType) => item?.url);
    tempObj['answer'] = response?.images?.[0]?.name;
    tempObj['url'] = response?.responseUrl?.map((item) => item);
    tempObj['noteText'] = response?.noteText;
    tempObj['responses'] = [];
  }

  return { response: tempObj, completeResponse: response };
};

//function to check if the specific question should be marked as flagged or not based on the responses
export const checkIfFlagged = (question: any, response: any) => {
  const responseId = response?.responseId || [];
  return question?.options?.some(
    (opt) => responseId?.includes(opt.eid) && opt.isFlagged
  );
};

export const formatQuestionAnswerData = (
  questions: any,
  responses: any,
  assignment: any,
  auditData: any,
  additionalData: any
) => {
  let data: any = [];
  let noSectionQuestions: any = {};
  let newSection: any = {};
  let flaggedItems: any = {};
  let mediaSummary: any = {};
  let qTypeMemory: string | undefined = '';
  let totalAuditScore = questions?.reduce(
    (acc: any, item: AuditQuestionsEntity) => {
      return (acc += item?.score);
    },
    0
  );

  questions?.map((question: AuditQuestionsEntity) => {
    let { response, completeResponse } = mapQuestionAnswer(question, responses);

    //if question doesn't have any section
    if (question?.qType !== 'section' && qTypeMemory === '') {
      if (noSectionQuestions.hasOwnProperty('title')) {
        noSectionQuestions['responses'] = [
          ...noSectionQuestions?.responses,
          response,
        ];
        noSectionQuestions['flaggedResponseCount'] +=
          completeResponse?.isFlagged
            ? 1
            : 0 || question?.hasFlaggedResponse
            ? 1
            : 0;
        noSectionQuestions['obtainedScore'] += completeResponse?.score
          ? completeResponse?.score
          : 0;
        noSectionQuestions['totalAuditScore'] = totalAuditScore;
      } else {
        noSectionQuestions['title'] = 'Audit Responses';
        noSectionQuestions['responses'] = [response];
        noSectionQuestions['flaggedResponseCount'] = completeResponse?.isFlagged
          ? 1
          : 0 || question?.hasFlaggedResponse
          ? 1
          : 0;
        noSectionQuestions['obtainedScore'] = completeResponse?.score
          ? completeResponse?.score
          : 0;
        noSectionQuestions['totalAuditScore'] = totalAuditScore;
      }
    } else if (question?.qType === 'section' && qTypeMemory === '') {
      //found the very first section
      newSection['title'] = question?.label;
      newSection['responses'] = [];
      newSection['flaggedResponseCount'] = 0;
      newSection['obtainedScore'] = 0;
      newSection['totalAuditScore'] = 0;
      newSection['score'] = `0/0 (0%)`;
      qTypeMemory = question?.label;
    } else if (question?.qType === 'section' && qTypeMemory !== '') {
      let completedPercentSectionWise =
        (newSection['obtainedScore'] / newSection['totalAuditScore']) * 100;
      newSection['score'] = `${newSection['obtainedScore']}/${
        newSection['totalAuditScore']
      } (${
        completedPercentSectionWise ? completedPercentSectionWise.toFixed(2) : 0
      }%)`;

      data.push(newSection);
      newSection = {
        title: question?.label,
        obtainedScore: 0,
        flaggedResponseCount: 0,
      };
    } else if (question?.qType !== 'section' && qTypeMemory !== '') {
      newSection['responses'] = [...(newSection['responses'] || []), response];
      newSection['flaggedResponseCount'] += completeResponse?.isFlagged
        ? 1
        : 0 ||
          (question?.hasFlaggedResponse &&
            checkIfFlagged(question, completeResponse))
        ? 1
        : 0;
      newSection['obtainedScore'] += completeResponse?.score
        ? completeResponse?.score
        : 0;
      newSection['totalAuditScore'] = totalAuditScore;
    }

    //populate data for flagged items --- response flagged items
    if (completeResponse?.isFlagged === true) {
      response = { ...response, qId: completeResponse?.qid };
      if (flaggedItems?.hasOwnProperty('title')) {
        flaggedItems['responses'] = [...flaggedItems['responses'], response];
        flaggedItems['flaggedResponseCount'] += 1;
      } else {
        flaggedItems['title'] = 'Flagged Items';
        flaggedItems['responses'] = [response];
        flaggedItems['flaggedResponseCount'] = 1;
      }
    }

    //populate data for flagged items --- question flagged items
    if (question?.hasFlaggedResponse === true) {
      response = { ...response, qId: question?.eid };
      if (flaggedItems?.hasOwnProperty('title')) {
        flaggedItems['responses'] = checkIfFlagged(question, completeResponse)
          ? [...flaggedItems['responses'], response]
          : flaggedItems?.responses;
        flaggedItems['flaggedResponseCount'] += checkIfFlagged(
          question,
          response
        )
          ? 1
          : 0;
      } else {
        flaggedItems['title'] = 'Flagged Items';
        flaggedItems['responses'] = checkIfFlagged(question, completeResponse)
          ? [response]
          : [];
        flaggedItems['flaggedResponseCount'] = checkIfFlagged(
          question,
          response
        )
          ? 1
          : 0;
      }
    }

    //handle unique flagged items from question and response both
    const filteredFlaggedResponses: string[] = [];
    flaggedItems = {
      ...flaggedItems,
      responses: flaggedItems?.responses?.filter((item: any) => {
        if (!filteredFlaggedResponses?.includes(item?.qId)) {
          filteredFlaggedResponses.push(item?.qId);
          return item;
        }
      }),
    };
    flaggedItems = {
      ...flaggedItems,
      flaggedResponseCount: flaggedItems?.responses?.length,
    };

    //populate data for media summary
    if (question?.qType !== 'section' && completeResponse?.images?.length > 0) {
      if (mediaSummary.hasOwnProperty('title')) {
        mediaSummary['responses'] = [
          ...mediaSummary['responses'],
          {
            qType: question?.qType,
            images: [
              {
                url: completeResponse?.images?.map(
                  (item: CompleteResponseImageType) => item?.url
                ),
                question: question?.label,
                section: qTypeMemory,
              },
            ],
          },
        ];
      } else {
        mediaSummary['title'] = 'Media Summary';
        mediaSummary['responses'] = [
          {
            qType: question?.qType,
            images: [
              {
                url: completeResponse?.images?.map(
                  (item: CompleteResponseImageType) => item?.url
                ),
                question: question?.label,
                section: qTypeMemory,
              },
            ],
          },
        ];
      }
    }

    //adding images from image type qType in the media summary
    if (
      question?.qType === 'imageUpload' &&
      mediaSummary?.hasOwnProperty('title')
    ) {
      mediaSummary['responses'] = [
        ...mediaSummary['responses'],
        {
          qType: question?.qType,
          images: [
            {
              url: completeResponse?.responseUrl?.map((item) => item),
              question: question?.label,
              section: qTypeMemory,
            },
          ],
        },
      ];
    }
  });

  if (newSection.hasOwnProperty('title')) {
    let completedPercentSectionWise =
      (newSection['obtainedScore'] / newSection['totalAuditScore']) * 100;
    newSection['score'] = `${newSection['obtainedScore']}/${
      newSection['totalAuditScore']
    } (${
      completedPercentSectionWise ? completedPercentSectionWise?.toFixed(2) : 0
    }%)`;
    data.push(newSection);
  }

  //initialize flagged items and handle empty response
  if (flaggedItems?.hasOwnProperty('title')) {
    data.push(flaggedItems);
  } else {
    data.push({
      title: 'Flagged Items',
      responses: [],
      flaggedResponseCount: 0,
    });
  }

  //populate data for Audit responses
  if (noSectionQuestions.hasOwnProperty('title')) {
    let completedPercentSectionWise =
      (noSectionQuestions['obtainedScore'] /
        noSectionQuestions['totalAuditScore']) *
      100;
    noSectionQuestions['score'] = `${noSectionQuestions['obtainedScore']}/${
      noSectionQuestions['totalAuditScore']
    } (${
      completedPercentSectionWise ? completedPercentSectionWise.toFixed(2) : 0
    }%)`;
    data.push(noSectionQuestions);
  }

  //populate data for Auditor's section
  data.push({
    title: 'Auditor Section',
    section: 'Auditor Section',
    responses: [
      {
        closingComment: assignment?.comments ? assignment?.comments : '--',
        auditorSign: {
          signUrl: assignment?.signature,
          name: additionalData?.moreInfo?.conductedBy?.userDetails?.[0]?.name,
          dateTime: assignment?.completedAt,
        },
      },
    ],
  });

  //initialize media summary and handle empty response
  if (mediaSummary?.hasOwnProperty('title')) {
    data.push(mediaSummary);
  } else {
    data.push({
      title: 'Media Summary',
      responses: [],
    });
  }

  //function to re-arrange array items
  function rearrangeArrayItems(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  let flaggedItemIndex = -1;
  let auditResponseIndex = -1;

  for (let i = 0; i < data?.length; i++) {
    if (flaggedItemIndex !== -1 && auditResponseIndex !== -1) {
      break;
    }

    if (data[i]?.title === 'Flagged Items') {
      flaggedItemIndex = i;
    }
    if (data[i]?.title === 'Audit Responses') {
      auditResponseIndex = i;
    }
  }

  flaggedItemIndex !== -1 && rearrangeArrayItems(data, flaggedItemIndex, 0);
  auditResponseIndex !== -1 && rearrangeArrayItems(data, auditResponseIndex, 1);

  const updatedMediaSummary = {
    title: 'Media Summary',
    responses: [],
  };

  mediaSummary?.responses?.map((item) => {
    if (item?.qType === 'imageUpload') {
      updatedMediaSummary?.responses?.push({
        qType: item?.qType,
        question: item?.images?.[0]?.question,
        section: item?.images?.[0]?.section,
        url: item?.images?.[0]?.url,
      });
    } else {
      updatedMediaSummary?.responses?.push({
        qType: item?.qType,
        question: item?.images?.[0]?.question,
        section: item?.images?.[0]?.section,
        url: item?.images?.[0]?.url,
      });
    }
  });

  data = data?.filter((item) => item?.title !== 'Media Summary');

  data.push(updatedMediaSummary);

  // console.log('new section --- ', newSection);
  // console.log('new data --- ', data);
  // console.log('no section question --- ', noSectionQuestions);
  // console.log('qTypeMemory --- ', qTypeMemory);
  // console.log('new section question --- ', newSection);
  console.log('final data --- ', data);

  return data;
};
