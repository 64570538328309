import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput, SelectOption, Table } from 'atoms';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  AuditSessionEntity,
  AuditStatusFunctionEntity,
} from 'sub-components/audits/audit-tab/supervised/types';
import EmptyState from 'sub-components/EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import { HeaderColors } from '../../../../../../shared/headerColors';
import GenericColorHeader from '../../../../../GenericColorHeader';
import { faEllipsis, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { LocationOwnersAuditsResponse } from '../../types';
import moment from 'moment';
import { formatNumber, getImage } from 'utils';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { AuditQuestionEntity } from 'types';
import { getAuditRepeatStatus } from 'sub-components/audits/common';
import { Filter } from 'sub-components/audits/detail/components';
import { useUserDataSelector } from 'hooks';
import { UserEntityData } from 'shared/graphql/shared-types';
import { Authorize } from 'authorization';

interface ProcessDataByLocation {
  auditName: string;
  auditor: {
    name: string;
    profilePic: string;
  };
  completedAt: string;
  location: string;
  score: number;
  scorePercent?: string;
  locationName: string;
  auditId: string;
  sessionId: string;
  assignmentId: string;
  auditStatus: AuditStatusFunctionEntity;
  repeatStatus: string;
  isScoredAudit: boolean;
}

interface IProps {
  auditData: LocationOwnersAuditsResponse | undefined;
}

/**
 * Determines the audit status based on completion, due date, and audit start.
 */
export const auditStatus = (
  isAllCompleted: boolean,
  auditDueDate: string,
  isAuditStarted: boolean
): AuditStatusFunctionEntity => {
  if (isAllCompleted) return 'completed';

  if (
    moment.utc(auditDueDate).isValid() &&
    moment.utc(auditDueDate).isBefore(moment())
  ) {
    return 'overdue';
  }

  if (isAuditStarted) {
    return 'ongoing';
  }

  return 'upcoming';
};

/**
 * Calculates the total score from audit questions.
 */
export const checkAuditScore = (questions: AuditQuestionEntity[]) => {
  return questions?.reduce((acc, question) => acc + (question?.score || 0), 0);
};

export const processCompletedAuditsByLocation = (
  data: AuditSessionEntity[],
  entityData: UserEntityData[]
): ProcessDataByLocation[] => {
  const processedDataArr: ProcessDataByLocation[] = [];

  data?.forEach((auditData) => {
    const auditName = auditData?.title;
    const auditId = auditData?.auditId;
    const sessionId = auditData?.eid;
    const repeatStatus = getAuditRepeatStatus(auditData?.repeatDetails);
    const isScoredAudit = !!checkAuditScore(auditData?.questions);

    auditData.assignments.forEach((assignment) => {
      const isAssignmentCompleted = assignment?.isCompleted;

      if (!isAssignmentCompleted) {
        return;
      }

      const locationId = assignment?.locationId;
      const locationName =
        entityData?.find((entity) => entity?.eid === locationId)?.name || '-';

      const status = auditStatus(
        isAssignmentCompleted,
        auditData?.dueDate,
        assignment?.startedBy ? true : false
      );

      let auditCompletedBy = entityData
        ?.filter((entity) => entity?.eid === assignment?.completedBy)
        ?.map((auditor) => ({
          name: auditor?.name,
          profilePic: auditor?.profilePic,
        }))?.[0];

      const entry: ProcessDataByLocation = {
        auditName,
        auditor: auditCompletedBy,
        completedAt: assignment?.completedAt || '',
        location: locationId,
        locationName,
        score: assignment?.totalScore || 0,
        auditId,
        sessionId,
        assignmentId: assignment?.locationId || locationId,
        auditStatus: status,
        repeatStatus,
        isScoredAudit,
      };

      // Include scorePercent if available
      // if (assignment?.completedPercent !== undefined) {
      //   entry.scorePercent = assignment.completedPercent;
      // }
      entry.scorePercent = assignment?.maxScore
        ? `${formatNumber(
            (assignment?.totalScore / assignment?.maxScore) * 100 || 0
          )}%`
        : 'N/A';

      processedDataArr.push(entry);
    });
  });

  return processedDataArr;
};

const AuditResultSection: FC<IProps> = ({ auditData }) => {
  const history = useHistory();
  const entityData = useReactiveVar(usersEntityObj);
  const loggedInUserLocations = useUserDataSelector(
    (state) => state?.locations
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState<
    SelectOption[]
  >([]);

  const processedAuditData: ProcessDataByLocation[] = useMemo(() => {
    let processedData = processCompletedAuditsByLocation(
      (auditData?.AuditSessionPagination?.items || []) as AuditSessionEntity[],
      entityData
    );

    if (selectedLocationsFilter?.length > 0) {
      const selectedLocationIds = selectedLocationsFilter.map(
        (sel) => sel.value
      );
      processedData = processedData.filter((process) =>
        selectedLocationIds.includes(process.location)
      );
    }

    if (searchQuery.trim() !== '') {
      const lowerSearch = searchQuery.toLowerCase();
      processedData = processedData.filter((process) =>
        process.locationName.toLowerCase().includes(lowerSearch)
      );
    }

    return processedData;
  }, [auditData, selectedLocationsFilter, searchQuery, entityData]);

  const handleSearchChange = (val: string) => {
    setSearchQuery(val);
  };

  const filterLocationsData = useMemo((): SelectOption[] => {
    if (!loggedInUserLocations || loggedInUserLocations?.length === 0) {
      return [];
    }
    return loggedInUserLocations?.map((loc) => ({
      label: loc?.name,
      value: loc?.eid,
    }));
  }, [loggedInUserLocations]);

  const getMenuData = useCallback(() => {
    const menuList: Array<any> = [
      {
        name: 'View',
        value: 'view',
        icon: <FontAwesomeIcon icon={faEye as IconProp} />,
      },
    ];

    return menuList;
  }, []);

  const clickHandler = (type: string, data: ProcessDataByLocation) => {
    if (type === 'view') {
      history.push(
        `/audits/details/${data?.auditId}/${data?.sessionId}/${data?.assignmentId}`
      );
    }
  };

  const handleFilterChange = (option: SelectOption) => {
    setSelectedLocationsFilter((prevFilters) => {
      const isSelected = prevFilters.some(
        (filter) => filter.value === option.value
      );
      if (isSelected) {
        return prevFilters.filter((filter) => filter.value !== option.value);
      } else {
        return [...prevFilters, option];
      }
    });
  };

  const findLocationName = (locationId: string) => {
    return (
      entityData
        ?.filter((entity) => entity?.eid === locationId)
        ?.map((loc) => loc?.name)?.[0] || '-'
    );
  };

  return (
    <Flex flexDir='column' w='full' gap={4}>
      <GenericColorHeader color={HeaderColors.Yellow} title='Audit results' />
      <Authorize permittedFor={'user'}>
        <Flex align='center' justify='space-between' gap={4}>
          <Flex flex='1'>
            <SearchInput
              placeholder='Search location'
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
              hideShortcuts
            />
          </Flex>
          <Filter
            options={filterLocationsData}
            value={selectedLocationsFilter}
            placeholder='Filter by Location(s)'
            onChange={handleFilterChange}
          />
        </Flex>
      </Authorize>
      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'auditName',
            key: 'auditName',
          },
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (location: string) => (
              <Flex flexDir='column' gap={1}>
                <Flex
                  w='fit-content'
                  gap='5px'
                  align='center'
                  p='2px 8px'
                  bg='rgba(181, 228, 202, 1)'
                  borderRadius='6px'
                >
                  <Center
                    bg='rgba(131, 191, 110, 1)'
                    borderRadius='4px'
                    boxSize='20px'
                    color='white'
                  >
                    <FontAwesomeIcon icon={faLocationDot as IconProp} />
                  </Center>
                  <Box as='span' isTruncated maxW='100px'>
                    {findLocationName(location)}
                  </Box>
                </Flex>
              </Flex>
            ),
          },
          {
            title: 'Auditor',
            dataIndex: 'auditor',
            key: 'auditor',
            render: (auditor: { name: string; profilePic: string }) =>
              auditor?.name ? (
                <Flex align='center' gap='5px'>
                  <Image
                    src={getImage(auditor?.profilePic, auditor?.name)}
                    boxSize='30px'
                    borderRadius='50%'
                  />
                  <Box as='span' maxW='100px' isTruncated>
                    {auditor?.name}
                  </Box>
                </Flex>
              ) : (
                '-'
              ),
          },
          {
            title: 'Completed On',
            dataIndex: 'completedAt',
            key: 'completedAt',
            render: (completedAt: string) => (
              <Box>
                {moment(completedAt)?.isValid()
                  ? moment.utc(completedAt).format('DD MMM, YYYY')
                  : '-'}
              </Box>
            ),
          },
          {
            title: 'Score',
            dataIndex: 'scorePercent',
            key: 'scorePercent',
            render: (scorePercent: string) => <Box>{scorePercent}</Box>,
          },
          {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (_, record: ProcessDataByLocation) => (
              <Menu>
                <MenuButton>
                  <FontAwesomeIcon icon={faEllipsis as IconProp} size='2x' />
                </MenuButton>
                <MenuList py={0} borderRadius='16px' p={4}>
                  {getMenuData()?.map((menu, index) => (
                    <MenuItem
                      key={index}
                      _hover={{ borderRadius: '12px' }}
                      p='12px 21px'
                      onClick={() => clickHandler(menu?.value, record)}
                    >
                      <Flex align='center' gap={4} justify='flex-start'>
                        {menu?.icon}
                        <Box as='span' fontSize='15px' fontWeight={600}>
                          {menu?.name}
                        </Box>
                      </Flex>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            ),
          },
        ]}
        dataSource={processedAuditData}
        rowKey={(record) => `${record.sessionId}-${record.assignmentId}`} // Unique key per row
        emptyState={
          <EmptyState
            image='MyTask'
            title={
              !searchQuery && selectedLocationsFilter?.length === 0
                ? 'No Completed Audit Data Available'
                : 'No completed audit found for searched location'
            }
            description='There are no completed audits to display at this time.'
          />
        }
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10, // Adjust page size as needed
        }}
      />
    </Flex>
  );
};

export default AuditResultSection;
