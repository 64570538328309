import { cloneDeep } from '@apollo/client/utilities';
import { match } from 'ts-pattern';
import moment from 'moment';
import { toArray, toNumber } from 'utils/utils';
import { AuditRepeatEntity } from '../../../types';
import { IFormInput } from './audit-schedule.types';
import { AuditCreateInput, CustomRepeatType } from './audit-create.graphql';
import { getScheduleRepeatString } from './schedule-repeatstring';

const getRepeatDetails = (
  repeatDetails: IFormInput['repeatDetails']
): AuditCreateInput['repeatDetails'] => {
  if (repeatDetails.auditFrequency === 'oneTime') {
    return {
      auditFrequency: repeatDetails.auditFrequency,
      auditDue: repeatDetails?.auditDue,
      auditDueDate: repeatDetails?.auditDueDate?.toString(),
    };
  }

  return match(repeatDetails)
    .with(
      {
        repeatType: 'weekly',
      },
      () => ({
        auditFrequency: repeatDetails.auditFrequency,
        repeatType: repeatDetails?.repeatType,
        weekly: repeatDetails?.weekly,
      })
    )
    .with(
      {
        repeatType: 'monthly',
      },
      () => ({
        auditFrequency: repeatDetails.auditFrequency,
        repeatType: repeatDetails?.repeatType,
        monthly: repeatDetails?.monthly,
      })
    )
    .with(
      {
        repeatType: 'yearly',
      },
      () => ({
        auditFrequency: repeatDetails.auditFrequency,
        repeatType: repeatDetails?.repeatType,
        yearly: repeatDetails?.yearly?.toString(),
      })
    )
    .with(
      {
        repeatType: 'custom',
        customRepeatType: 'days',
      },
      () => ({
        auditFrequency: repeatDetails.auditFrequency,
        repeatType: repeatDetails?.repeatType,
        customRepeatType: repeatDetails?.customRepeatType,
        customRepeatValue: repeatDetails?.customRepeatValue,
      })
    )
    .with(
      {
        repeatType: 'custom',
        customRepeatType: 'weeks',
      },
      () => ({
        auditFrequency: repeatDetails.auditFrequency,
        repeatType: repeatDetails?.repeatType,
        customRepeatType: repeatDetails?.customRepeatType,
        customRepeatValue: repeatDetails?.customRepeatValue,
        customRepeatWeek: repeatDetails.customRepeatWeek,
      })
    )
    .with(
      {
        repeatType: 'custom',
        customRepeatType: 'months',
      },
      () => ({
        auditFrequency: repeatDetails.auditFrequency,
        repeatType: repeatDetails?.repeatType,
        customRepeatType: repeatDetails?.customRepeatType,
        customRepeatValue: repeatDetails?.customRepeatValue,
        customRepeatMonth: repeatDetails?.customRepeatMonth?.toString(),
      })
    )
    .otherwise(() => ({
      auditFrequency: repeatDetails.auditFrequency,
      repeatType: repeatDetails?.repeatType,
      customRepeatType: repeatDetails?.customRepeatType,
      customRepeatValue: repeatDetails?.customRepeatValue,
    }));
};

const DATE_FORMAT = 'YYYY-MM-DD';

const getEndCondition = (
  values: IFormInput['repeatDetails']
): AuditCreateInput['endCondition'] => {
  if (values.auditFrequency === 'oneTime') {
    return match<typeof values.auditDue, AuditCreateInput['endCondition']>(
      values.auditDue
    )
      .with('nextWeek', () => ({
        endType: 'date',
        endDate: moment()
          .startOf('isoWeek')
          .add(1, 'week')
          .day('monday')
          .format(DATE_FORMAT),
      }))
      .with('endMonth', () => ({
        endType: 'date',
        endDate: moment().endOf('month').format(DATE_FORMAT),
      }))
      .with('endYear', () => ({
        endType: 'date',
        endDate: moment().endOf('year').format(DATE_FORMAT),
      }))
      .with('date', () => ({
        endType: 'date',
        endDate: moment(values.auditDueDate).format(DATE_FORMAT),
      }))
      .otherwise(() => undefined);
  }
  return undefined;
};

const getAuditType = (
  values: IFormInput['repeatDetails']
): AuditCreateInput['auditType'] => {
  switch (values.auditFrequency) {
    case 'oneTime':
      return values?.auditDue === 'anyTime' ? 'forever' : 'oneTime';
    default:
      return values.auditFrequency!;
  }
};

const isCustomRepeat = (values: IFormInput['repeatDetails']) => {
  return (
    values.auditFrequency === 'repeating' && values.repeatType === 'custom'
  );
};

const getCustomRepeat = (
  repeatDetails: AuditRepeatEntity<Date>
): AuditCreateInput['customRepeat'] => {
  if (isCustomRepeat(repeatDetails)) {
    switch (repeatDetails.customRepeatType) {
      case 'days':
        return {
          type: CustomRepeatType.EVERY_N_DAYS,
          days: toNumber(repeatDetails.customRepeatValue),
        };
      case 'weeks':
        return {
          type: CustomRepeatType.EVERY_N_WEEKS,
          weekDay: moment.weekdays().indexOf(repeatDetails.customRepeatWeek!),
          weeks: toNumber(repeatDetails.customRepeatValue),
        };
      case 'months':
        return {
          type: CustomRepeatType.EVERY_N_MONTHS,
          dayOfMonth: repeatDetails.customRepeatMonth?.getDate(),
          months: toNumber(repeatDetails.customRepeatValue),
        };
      default:
        return undefined;
    }
  }
  return undefined;
};

const getRepeatCycle = (values: IFormInput['repeatDetails']) => {
  if (values.auditFrequency === 'oneTime') {
    return undefined;
  }

  return match<IFormInput['repeatDetails'], AuditCreateInput['repeatCycle']>(
    values
  )
    .with(
      {
        repeatType: 'weekly',
      },
      () => 'weekly'
    )
    .with(
      {
        repeatType: 'monthly',
      },
      () => 'monthly'
    )
    .with(
      {
        repeatType: 'yearly',
      },
      () => 'yearly'
    )
    .with(
      {
        repeatType: 'custom',
        customRepeatType: 'days',
      },
      () => 'days'
    )
    .with(
      {
        repeatType: 'custom',
        customRepeatType: 'weeks',
      },
      () => 'weekly'
    )
    .with(
      {
        repeatType: 'custom',
        customRepeatType: 'months',
      },
      () => 'monthly'
    )
    .otherwise(() => undefined);
};

export const inputDataMapper = (_values: IFormInput): AuditCreateInput => {
  const values = cloneDeep(_values);

  return {
    title: values.scheduleTitle!,
    templateId: values.template?.value!,

    auditType: getAuditType(values.repeatDetails),
    hasCustomRepeat: isCustomRepeat(values.repeatDetails),
    repeatDetails: getRepeatDetails(values.repeatDetails),
    repeatString: getScheduleRepeatString(values.repeatDetails),
    endCondition: getEndCondition(values.repeatDetails),
    repeatCycle: getRepeatCycle(values.repeatDetails),
    customRepeat: getCustomRepeat(values.repeatDetails),
    notifyLO: values.notifyLO === true,
    auditors: toArray(values.auditors).map((auditor) => ({
      assignType: 'selected',
      locations: toArray(auditor.locations),
      userId: toArray(auditor.userId),
    })),
  };
};
