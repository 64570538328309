import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import { HeaderColors } from '../../../../configs';

interface IProps {
  onArchive?: (...args: any[]) => any | PromiseLike<any>;
}

type ArchiveConfirm = (props?: IProps) => void;

export const useArchiveConfirm = (): ArchiveConfirm => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ onArchive } = {}) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader title='Archive template' color={HeaderColors.Purple} />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <Box fontSize='15px' fontWeight={600} color='#6F767E'>
          Are you sure you want to move this template to the archives?
        </Box>
      ),
      isCentered: true,
      contentProps: {
        paddingTop: '8px',
        paddingBottom: '8px',
        minW: '600px',
      },
      okText: 'Archive',
      okButtonProps: {
        size: 'lg',
        flex: 1,
      },
      onOK: onArchive,
      cancelButtonProps: {
        size: 'lg',
        flex: 1,
        variant: 'outline',
      },
    });
  }, []);
};
