import { Flex, Link, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';
import { FILENAME_REGEX } from 'utils/constants';

interface IProps {
  answer?: string | undefined;
  url?: string;
}

const FileUpload: FC<IProps> = ({ answer, url }) => {
  if (url) {
    answer = url?.split(FILENAME_REGEX).pop();
  }
  return (
    <Flex direction={'column'} justifyContent={'center'} gap={2}>
      {url && (
        <Flex
          padding={'8px 16px'}
          borderRadius={'8px'}
          backgroundColor={'#DCF5FF'}
          gap={2}
          alignItems={'center'}
          alignSelf={'flex-start'}
          cursor={'pointer'}
          onClick={() =>
            url && window.open(url, '_blank', 'noopener noreferrer')
          }
        >
          <FontAwesomeIcon
            color='#DE4646'
            fontSize={'1.2rem'}
            icon={faFile as IconProp}
          />
          <Tooltip label={answer ? answer : url}>
            <Link href={url} isExternal textDecoration={'underline'}>
              {answer
                ? answer?.length > 30
                  ? answer?.substring(0, 30) + '...'
                  : answer
                : url?.length > 30
                ? url.substring(0, 30) + '...'
                : url}
            </Link>
          </Tooltip>
        </Flex>
      )}
    </Flex>
  );
};

export default FileUpload;
