import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { PreviewContent } from '../../../listing/template-preview';
import { useSelectedTemplate } from './useSelectedTemplate';

interface IProps {
  templateId: string;
}

const ScheduleTemplate: FC<IProps> = ({ templateId: _templateId }) => {
  const templateId = useSelectedTemplate(_templateId)?.value!;

  return (
    <Flex flexDir='column'>
      <Flex
        align='center'
        justify='center'
        fontSize='18px'
        fontWeight='600'
        py='28px'
      >
        PREVIEW
      </Flex>
      <PreviewContent templateId={templateId} />
    </Flex>
  );
};

export default ScheduleTemplate;
