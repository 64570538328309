import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { TemplateLibrary } from './components';
import { MyTemplates } from './my-templates';
import { ArchivedTemplates } from './archived-templates';

type ListingContainerProps = {
  templateView: string;
};

const ListingContainer: FC<ListingContainerProps> = ({ templateView }) => {
  return (
    <Box>
      {templateView === 'template' ? (
        <MyTemplates />
      ) : templateView === 'library' ? (
        <TemplateLibrary />
      ) : (
        <ArchivedTemplates />
      )}
    </Box>
  );
};

export default ListingContainer;
