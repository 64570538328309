import { Heading, AuditResponse } from './components';
import { generateValidId } from './helper';
import MediaSummary from './MediaSummary';
import { FC } from 'react';
import AuditorSignature from './AuditorSignature';
import { Flex, Text } from '@chakra-ui/react';
import {
  AssignmentType,
  SectionDetailsResponseType,
  SessionDetailEntity,
} from './types/report.types';
import { useTranslation } from 'react-i18next';

type IProps = {
  mappedQuestionData: any;
  auditData: SessionDetailEntity;
  assignment: AssignmentType;
  additionalData: any;
};

const RenderAllSections: FC<IProps> = ({
  auditData,
  assignment,
  additionalData,
  mappedQuestionData,
}) => {
  const { t } = useTranslation(['audit']);

  return (
    <div>
      {mappedQuestionData[0].hasOwnProperty('title') &&
        Object.values(mappedQuestionData).map((item: any, index) => {
          return item?.title === 'Media Summary' ? (
            <div
              style={{
                border:
                  item?.response?.length > 0 ? '1px solid #EFEFEF' : undefined,
                borderRadius: '12px',
                margin: '2rem 0px',
                scrollMargin: '8vh',
              }}
              key={index}
              id={`${generateValidId(item?.title)}`}
            >
              {item?.responses?.length > 0 && (
                <>
                  <Heading title={item?.title} />
                  <MediaSummary response={item?.responses} />
                </>
              )}
            </div>
          ) : item?.title === 'Auditor Section' ? (
            <div
              style={{
                border: item?.title ? '1px solid #EFEFEF' : undefined,
                borderRadius: '12px',
                margin: '2rem 0px',
                scrollMargin: '8vh',
              }}
              key={index}
              id={`${generateValidId(item?.title)}`}
            >
              <Heading
                title={item?.title}
                headingTextDesc={item?.headingTextDesc}
              />
              <AuditorSignature
                type='web'
                response={item}
                additionalData={additionalData}
                assignment={assignment}
              />
            </div>
          ) : (
            <div
              style={{
                border: item?.title ? '1px solid #EFEFEF' : undefined,
                borderRadius: '12px',
                margin: '38px 0px',
                scrollMargin: '8vh',
              }}
              key={index}
              id={`${generateValidId(item?.title)}`}
            >
              {item?.title && (
                <Heading
                  title={item?.title}
                  headingTextDesc={`${t('audit:flagCount', {
                    count: item?.flaggedResponseCount || 0,
                  })}${
                    item?.title !== 'Flagged Items' ? ', ' + item?.score : ''
                  }`}
                />
              )}

              {item?.title === 'Flagged Items' &&
              item?.responses?.length <= 0 ? (
                <Flex
                  justifyContent={'center'}
                  style={{
                    borderRadius: '12px',
                    backgroundColor: '#EFEFEF',
                    margin: '38px 1.5rem 10px 1.5rem',
                  }}
                >
                  <Text
                    lineHeight={'48px !important'}
                    fontWeight={500}
                    fontSize={'20px'}
                  >
                    No Flagged Responses
                  </Text>
                </Flex>
              ) : (
                item?.responses?.map(
                  (response: SectionDetailsResponseType, index: number) => {
                    return (
                      <AuditResponse
                        key={`${response?.qType}-${index}`}
                        section={item?.title}
                        index={index}
                        {...response}
                      />
                    );
                  }
                )
              )}
            </div>
          );
        })}
    </div>
  );
};

export default RenderAllSections;
