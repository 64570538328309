import { gql } from '@apollo/client';
import { AuditTemplateType } from '../../../../types';

export const GET_BUSINESS_AUDITS = gql`
  query AuditPagination($page: Int, $perPage: Int) {
    AuditPagination(page: $page, perPage: $perPage) {
      count
      items {
        eid
        title
        entityId
        templateId
        auditType
        updatedAt
        createdAt
      }
      pageInfo {
        perPage
        pageCount
      }
    }
  }
`;

export interface AuditTemplateItem {
  eid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  description?: string;
  thumbnail?: string;
  downloads?: number;
  category: {
    eid: string;
    name: string;
  };
}

export interface TemplateResponse {
  AuditTemplatePagination: {
    count: number;
    items: AuditTemplateItem[];
  };
}

export type TemplateSort =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'CREATEDAT_ASC'
  | 'CREATEDAT_DESC'
  | 'UPDATEDAT_ASC'
  | 'UPDATEDAT_DESC'
  | 'TITLE_ASC'
  | 'TITLE_DESC';

interface TemplateFilter {
  query?: string;
  status?: 'active' | 'inactive' | 'archived';
  templateType?: AuditTemplateType;
  entityId?: string;
}

export interface TemplateVariable {
  page?: number;
  perPage?: number;
  filter?: TemplateFilter;
  sort?: TemplateSort;
}

export const GET_AUDIT_TEMPLATES = gql`
  query AuditTemplatePagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyAuditTemplateInput
    $sort: SortFindManyAuditTemplateInput
  ) {
    AuditTemplatePagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        eid
        title
        description
        thumbnail
        downloads
        category {
          eid
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export interface TemplateOperation {
  templateIds: string[];
  operation: 'archive' | 'activate';
}

export const TEMPLATE_OPERATION = gql`
  mutation BulkAuditTemplateOperation(
    $templateIds: [String]
    $operation: AuditTemplateBulkOperationType
  ) {
    BulkAuditTemplateOperation(
      templateIds: $templateIds
      operation: $operation
    ) {
      succeed
    }
  }
`;

export interface DuplicateInput {
  eid: string;
}

export const CLONE_TEMPLATE = gql`
  mutation CloneAuditTemplate($eid: String) {
    CloneAuditTemplate(eid: $eid) {
      eid
    }
  }
`;
