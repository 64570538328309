import {
  Box,
  Flex,
  Stack,
  StackDivider,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faChevronLeft,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Prompt,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import TrainingPathContext, {
  IChapter,
  ITrainingHandbook,
} from '../TrainingPathStore/training-path-context';
import { TrainingPathSubContainerWrapper } from './TrainingPathSubContainer.styles';
import Steps, { Step } from 'ui-components/Steps';
import { Card } from 'antd';
import CardDetails from '../../CreateContainer/CardDetails';
import TrainingCardNameIcon from '../TrainingCardNameIcon';
import TrainingPathCountAction from '../TrainingPathCountAction';
import TrainingPathSelectedItems from '../TrainingPathSelectedItems';
import TrainingHandbookList from '../TrainingHandbookList';
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { ITrainingCategoryEntity } from '../../training-path-types';
import AssignPublish from '../../CreateContainer/AssignPublish';
import TrainingPathAssign from '../TrainingPathAssign';
import { UserDescendants } from 'sop-commons/Query/Sop';
import { getBranch } from 'sop-commons/Query/User';
import { WORKER } from 'utils/userRoles';
import {
  GET_HANDBOOK_QUERY,
  HandbookData,
  HandbookResponse,
  HandbookVariable,
} from 'pages/Handbook/handbook.graphql';
import PrimaryButton from 'atoms/PrimaryButton';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import PreviewHeader from 'sub-components/forms/component/PreviewHeader';
import VisibilityComponent from '../../VisibilityComponent';
import { useConfirm } from 'ui-components/Confirm/Confirm';
import ChangeLog from 'pages/TrainingDraft/ChangeLog';
import { UPDATE_TRAINING } from 'pages/EditTraining/edit-training.graphql';
import { TRAINING_BY_ID_COMPLETE } from 'sop-commons/Query/Training';
import { TrainingDraftMethods } from 'pages/TrainingDraft/training-draft-methods/TrainingDraftMethods';
import {
  ICreateChangeLog,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import { CREATE_CHANGE_LOG } from 'pages/TrainingDraft/training-draft-queries-mutations/training-draft-graphql';
import CardPreview from 'sub-components/CardPreview';
import CardPreviewCarousel from 'sub-components/CardPreviewCarousel';
import { IIconList } from 'sub-components/TrainingPathIconsModal';
import { ViewIcon } from '@chakra-ui/icons';
import TrainingPathModal from 'sub-components/AddNewPathModal/TrainingModal';
import CustomPathCarousel from 'sub-components/AddNewPathModal/CustomPathCarousel';
import {
  GET_TRAINING_HANDBOOK_QUERY,
  GET_ALL_MEMBER_LOCATION_DATA,
} from './training-path-sub-container.graphql';
import TrainingPathActionButtons from './TrainingPathActionButtons';
import { IPublishChangeDiffCheckTable } from 'pages/Training/PublishChangeDiffCheckModal/PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import PublishChangeDiffCheckModal from 'pages/Training/PublishChangeDiffCheckModal';
import { cloneDeep } from '@apollo/client/utilities';

import { HandbookModal } from 'modal';
import {
  ChapterResponse,
  ChapterVariable,
  GET_CHAPTERS_LIST_FOR_TRAINING,
} from '../TrainingHandbookList/trainining-handbook.graphql';
import { ChapterModal } from 'modal/chapter-modal';
import { useTrainingPreview } from 'pages/Training/CreateTrainingPath/Preview';
import { AuthRole } from 'authorization';
import moment from 'moment';
import { usersEntityObj } from 'sub-components/Header';

const CREATE_TRAINING_PATH = gql`
  mutation CreateTraining($input: AddTrainingInput) {
    CreateTraining(input: $input) {
      eid
      title
      status
    }
  }
`;

export const GET_ALL_USERS = gql`
  query TRAINING_PATH_SUB_CONTAINER_ENTITY_USER($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      name
      type
      role
      authRole
      status
      profilePic
      locations {
        eid
        name
      }
    }
  }
`;

const USER_DESCENDANTS = gql`
  query UserDescendants {
    userDescendants {
      eid
      name
      type
      role
      authRole
      status
      profilePic
      locations {
        eid
        name
      }
    }
  }
`;

interface LocationState {
  backToTitle?: string;
}

const TrainingPathSubContainer = () => {
  const { t } = useTranslation('training');
  const { t: tc } = useTranslation('common');
  const userObject = useReactiveVar(userObj);
  const toast = useToast({
    position: 'top-right',
  });
  const entityUserData = useReactiveVar(usersEntityObj);
  const history = useHistory();
  const [trainingData, setTrainingData] = useState<any>(null);
  const [initialTrainingData, setInitialTrainingData] = useState<any>(null);
  const [changeLogResponse, setChangeLogResponse] = useState<
    ICreateChangeLogChangesEntity[]
  >([]);
  const [changedData, setChangedData] = useState<
    IPublishChangeDiffCheckTable[]
  >([]);
  const [isPublishChangeDiffCheckModalOpen, setIsPublishChangeDiffCheckModal] =
    useState(false);
  const [updatedTrainingInput, setUpdatedTrainingInput] = useState<any>({});
  const [clickedPublishedButton, setClickedPublishedButton] = useState(false);
  const [shouldShowChangeLogModal, setShouldShowChangeLogModal] =
    useState(false);
  const trainingPathCtx = useContext(TrainingPathContext);
  const params = useRouteMatch<{ id?: string }>()?.params;
  const editDraftId = params?.id && params?.id !== 'draft' && params?.id;
  const confirm = useConfirm();
  const visibilityRef = useRef<'public' | 'private'>();
  const [visibility, setVisibility] = useState<'public' | 'private'>('private');
  const [trainingProgressModal, setTrainingProgressModal] = useState(false);
  const locState = useLocation<LocationState>().state;

  visibilityRef.current = visibility;

  const steps: Step[] = [
    {
      name: t('add_content_structure'),
      stringId: 'Add content & structure',
      active: true,
      icon: faPen,
      outline: 'none',
    },
    {
      name: t('assign_publish'),
      stringId: 'Assign and publish',
      active: false,
      icon: faCheck,
      outline: 'solid',
    },
  ];

  const [stepData, setStepData] = useState(steps);
  const trainingPreview = useTrainingPreview();

  useEffect(() => {
    if (params?.id === 'draft') {
      trainingPathCtx?.setTrainingPathTypeHandler('add');
      trainingPathCtx?.setIsCreateHandler(false);
    } else {
      if (params?.id && params?.id !== 'draft' && params?.id) {
        trainingPathCtx?.setTrainingPathTypeHandler('edit');
        getTrainingPathDetailsHandler(params?.id);
      }
      trainingPathCtx?.setIsCreateHandler(
        !(params?.id && params?.id !== 'draft' && params?.id)
      );
    }
  }, [params?.id]);

  useEffect(() => {
    if (
      trainingPathCtx?.trainingPathIconsList?.length > 0 &&
      trainingPathCtx?.trainingPathIcon?.url
    ) {
      let _icon: IIconList = {
        createdAt: '',
        backgroundColor: '',
        description: '',
        eid: '',
        label: '',
        tags: [],
        type: '',
        updatedAt: '',
        url: '',
      };
      trainingPathCtx?.trainingPathIconsList?.map((list) => {
        if (list?.url === trainingPathCtx?.trainingPathIcon?.url) {
          _icon = list;
        }
      });
      // trainingPathCtx?.setTrainingPathIconHandler(_icon);
    }
  }, [
    trainingPathCtx?.trainingPathIconsList,
    trainingPathCtx?.trainingPathIcon,
  ]);

  const [trainingByIdData] = useLazyQuery(TRAINING_BY_ID_COMPLETE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _data: any = JSON.parse(JSON.stringify(data));
      if (_data?.trainingById?.eid) {
        trainingPathCtx?.setAddedItemsHandler(
          _data?.trainingById?.contentDetails
        );
        trainingPathCtx?.setTrainingPathNameHandler(_data?.trainingById?.title);
        trainingPathCtx?.setTrainingPathIconHandler({
          backgroundColor: '',
          createdAt: '',
          description: '',
          eid: '',
          label: '',
          tags: [],
          type: '',
          updatedAt: '',
          url: _data?.trainingById?.thumbnail,
        });
        if (
          _data?.trainingById?.assignedLocations?.length > 0 ||
          _data?.trainingById?.assignedTo?.length > 0
        ) {
          trainingPathCtx?.setShouldShowHiddenItemsHandler(true);
        }
        _data?.trainingById?.assignedRoles?.map((assignedRole: any) => {
          userObject?.entity?.roles?.map((entityRole) => {
            if (assignedRole?.name === entityRole?.name) {
              assignedRole.eid = entityRole?.eid;
            }
          });
        });
        let rolesIds = _data?.trainingById?.assignedRoles?.map(
          (role: any) => role?.eid
        );
        let membersIds = _data?.trainingById?.assignedTo?.map(
          (assign: any) => assign?.eid
        );
        let locationsIds = _data?.trainingById?.assignedToLocations?.map(
          (location: any) => location?.eid
        );
        let supervisorsIds = _data?.trainingById?.supervisors;
        if (Array?.isArray(rolesIds) && rolesIds?.length > 0) {
          trainingPathCtx?.setSelectedRolesHandler(rolesIds);
        }
        if (Array?.isArray(membersIds) && membersIds?.length > 0) {
          trainingPathCtx?.setSelectedMembersHandler(membersIds);
        }
        if (Array?.isArray(locationsIds) && locationsIds?.length > 0) {
          trainingPathCtx?.setSelectedLocationsHandler(locationsIds);
        }
        if (Array?.isArray(supervisorsIds) && supervisorsIds?.length > 0) {
          trainingPathCtx?.setSelectedSupervisorsHandler(supervisorsIds);
        }
        // setSavedPublishData({
        //   supervisorData: JSON.parse(
        //     JSON.stringify(_data?.trainingById?.supervisors)
        //   ),
        //   assigneeData: JSON.parse(
        //     JSON.stringify(
        //       _data?.trainingById?.assignedTo?.map((assign: any) => assign?.eid)
        //     )
        //   ),
        // });
        setInitialTrainingData(_data?.trainingById);
        setTrainingData(_data?.trainingById);
      }
    },
  });

  const getTrainingPathDetailsHandler = (id: string) => {
    trainingByIdData({
      variables: {
        eid: id,
      },
    });
  };

  useEffect(() => {
    if (
      userObject?.authRole === AuthRole.SUPER_ADMIN ||
      userObject?.authRole === AuthRole.ADMIN
    ) {
      getAllUsersHandler();
      // getUsersData();
      // getLocations();
    } else {
      getLocationOwnerUsersHandler();
    }
  }, [userObject?.authRole]);

  useEffect(() => {
    getRolesData();
  }, [userObject?.entity?.roles]);

  useEffect(() => {
    let _category = JSON.parse(
      JSON.stringify(userObject?.entity?.category || [])
    );
    let _totalCategories: ITrainingCategoryEntity[] = [];
    if (_category && Array.isArray(_category) && _category?.length > 0) {
      _category?.forEach((cat: any) => (cat.isSelected = false));
    }
    _totalCategories = _category;
    trainingPathCtx?.setHandbookCategoryListHandler(_totalCategories);
  }, [userObject?.entity?.category]);

  const getTitleHandler = () => {
    if (
      trainingPathCtx?.trainingPathType === 'add' &&
      trainingPathCtx.isCreate
    ) {
      return t('create_training_path');
    } else if (
      trainingPathCtx?.trainingPathType === 'add' &&
      !trainingPathCtx.isCreate
    ) {
      return t('create_training_draft');
    } else {
      return t('update_training_draft');
    }
  };

  const getSubTitleHandler = () => {
    if (locState?.backToTitle) {
      return (
        <Text
          cursor='pointer'
          width={'fit-content'}
          marginLeft={'5px'}
          onClick={() => history.goBack()}
        >
          {locState?.backToTitle}
        </Text>
      );
    } else {
      if (trainingPathCtx?.isCreate) {
        return (
          <Text
            cursor='pointer'
            width={'fit-content'}
            marginLeft={'5px'}
            onClick={() => history.push('/training')}
          >
            {t('back_to_training')}
          </Text>
        );
      } else {
        return (
          <Text
            cursor='pointer'
            width={'fit-content'}
            marginLeft={'5px'}
            onClick={() => history.push('/drafts/list')}
          >
            {t('back_to_drafts')}
          </Text>
        );
      }
    }
  };

  const getRolesData = () => {
    let roleData: any = [];
    userObject?.entity?.roles
      ? userObject?.entity?.roles?.forEach(
          (role: { name: string; eid: string }) => {
            // roleData.push(key);
            roleData.push({
              id: role?.eid,
              label: role?.name,
              value: role?.name,
              selected: false,
              url: '',
            });
          }
        )
      : [];
    trainingPathCtx?.setRolesListHandler(roleData);
  };

  const [getUsersData, { loading }] = useLazyQuery(UserDescendants, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _userData = JSON.parse(JSON.stringify(data?.userDescendants));
      let _members: any[] = [];
      let _supervisors: any[] = [];
      getAllUsersHandler();
      // if (userObject?.authRole !== 'superadmin') {
      //   _userData?.map((user: any) => {
      //     if (userObject?.eid !== user?.eid) {
      //       _members?.push({
      //         id: user?.eid,
      //         label: user?.name,
      //         value: user?.name,
      //         selected: false,
      //         url: user?.profilePic,
      //       });
      //       _supervisors?.push({
      //         id: user?.eid,
      //         label: user?.name,
      //         value: user?.name,
      //         selected: false,
      //         url: user?.profilePic,
      //       });
      //       // if (user?.authRole !== WORKER) {
      //       // }
      //     }
      //   });
      //   trainingPathCtx?.setMembersListHandler(_members);
      //   trainingPathCtx?.setSupervisorsListHandler(_supervisors);
      // } else {
      //   // _userData?.map((user: any) => {
      //   //   if (userObject?.eid !== user?.eid) {
      //   //     if (user?.authRole !== WORKER) {
      //   //       _supervisors?.push({
      //   //         id: user?.eid,
      //   //         label: user?.name,
      //   //         value: user?.name,
      //   //         selected: false,
      //   //         url: user?.profilePic,
      //   //       });
      //   //     }
      //   //   }
      //   // });
      //   // trainingPathCtx?.setSupervisorsListHandler(_supervisors);
      //   getAllUsersHandler();
      // }
    },
  });

  const [getLocationOwnerUsers] = useLazyQuery(USER_DESCENDANTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let users: any[] =
        JSON.parse(JSON.stringify(data?.userDescendants)) || [];
      let sortedUsers = users?.sort((a, b) => a?.name.localeCompare(b?.name));
      let members: any[] = [];
      sortedUsers?.map((user) => {
        if (user?.eid !== userObject?.eid) {
          members?.push({
            id: user?.eid,
            label: user?.name,
            value: user?.eid,
            selected: false,
            url: user?.profilePic,
            role: user?.role,
            authRole: user?.authRole,
            locations: user?.locations,
          });
        }
      });
      trainingPathCtx?.setSupervisorsListHandler(members);
      trainingPathCtx?.setMembersListHandler(members);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [getAllUsers] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let users = data?.EntityUser?.filter(
        (entity: any) => entity?.type === 'user'
      );
      let locations = data?.EntityUser?.filter(
        (entity) => entity?.type === 'branch'
      );
      let _members: any[] = [];
      let _locations: any[] = [];
      if (Array.isArray(users) && users?.length > 0) {
        let sortedUsers = users?.sort((a, b) => a?.name.localeCompare(b?.name));
        sortedUsers?.map((user) => {
          if (userObject?.eid !== user?.eid) {
            _members?.push({
              id: user?.eid,
              label: user?.name,
              value: user?.eid,
              selected: false,
              url: user?.profilePic,
              role: user?.role,
              authRole: user?.authRole,
              locations: user?.locations,
            });
          }
        });
      }
      if (Array.isArray(locations) && locations?.length > 0) {
        let sortedLocations = locations?.sort((a, b) =>
          a?.name.localeCompare(b?.name)
        );
        sortedLocations?.map((location) => {
          if (userObject?.eid !== location?.eid) {
            _locations?.push({
              id: location?.eid,
              label: location?.name,
              value: location?.name,
              selected: false,
              url: '',
            });
          }
        });
      }
      trainingPathCtx?.setSupervisorsListHandler(_members);
      trainingPathCtx?.setMembersListHandler(_members);
      trainingPathCtx?.setLocationsListHandler(_locations);
    },
  });

  const getLocationOwnerUsersHandler = () => {
    getLocationOwnerUsers();
  };

  const getAllUsersHandler = () => {
    getAllUsers({
      variables: {
        entityId: userObject?.entityId,
      },
    });
  };

  const [getLocations] = useLazyQuery(getBranch, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _locations: any[] = [];
      data?.branch?.descendantBranches?.map((branch: any) => {
        _locations?.push({
          id: branch?.eid,
          label: branch?.name,
          value: branch?.name,
          selected: false,
          url: '',
        });
      });
      trainingPathCtx?.setLocationsListHandler(_locations);
    },
  });

  const getItemsHandler = (trainingItems: any[]) => {
    let _array: any[] = [];
    trainingItems?.map((item) => {
      if (item?.type === 'sop') {
        _array?.push(item?.data);
      } else {
        _array?.push(item);
      }
    });
    return _array;
  };

  const previewNewHandler = () => {
    deployEvent(AmplitudeEventNames.CHAPTER_PREVIEW_BUTTON);
    trainingPreview({
      trainingContents: getItemsHandler(trainingPathCtx?.addedItems),
    });
  };

  useEffect(() => {
    trainingPathCtx?.setCountsHandler();
  }, [trainingPathCtx?.addedItems]);

  useEffect(() => {
    // getHandbookData();
    getChapterData();
  }, []);

  useEffect(() => {
    // getHandbookDataHandler();
    getChapterDataHandler();
    // getHandbookDataHandler();
  }, [
    trainingPathCtx?.isCardCheckboxSelected,
    trainingPathCtx?.isDeckCheckboxSelected,
    trainingPathCtx?.searchedHandbookString,
    trainingPathCtx?.handbookCategoryList,
    trainingPathCtx?.selectedHandbookFilterOption,
  ]);

  const getChapterDataHandler = () => {
    let filter: {
      query?: string;
      category?: string;
      categoryId?: string;
      status?: string;
    } = {};
    filter.status = 'active';
    if (trainingPathCtx?.searchedHandbookString) {
      filter.query = trainingPathCtx?.searchedHandbookString;
    }
    if (
      // trainingPathCtx?.handbookCategoryList?.some((list) => list?.isSelected)
      trainingPathCtx?.selectedHandbookFilterOption
    ) {
      // filter.category = trainingPathCtx?.handbookCategoryList
      //   ?.filter((list) => list?.isSelected)
      //   ?.map((list) => list?.name)?.[0];
      filter.categoryId = trainingPathCtx?.selectedHandbookFilterOption;
    }
    trainingPathCtx?.setChapterItemsListHandler({
      SopPagination: {
        count: 0,
        items: [],
        pageInfo: {
          currentPage: 0,
          itemCount: 0,
          pageCount: 0,
          perPage: 0,
          hasNextPage: false,
          hasPreviousPage: false,
        },
      },
    });
    getChapterData({
      variables: {
        page: 1,
        ...((filter?.query || filter?.category || filter.status) && {
          filter: filter,
        }),
      },
    });
  };

  const getHandbookDataHandler = () => {
    let filter: {
      type?: 'card' | 'deck';
      query?: string;
      category?: string;
    } = {};
    if (trainingPathCtx?.isCardCheckboxSelected) {
      filter.type = 'card';
    }
    if (trainingPathCtx?.isDeckCheckboxSelected) {
      filter.type = 'deck';
    }
    if (trainingPathCtx?.searchedHandbookString) {
      filter.query = trainingPathCtx?.searchedHandbookString;
    }
    if (
      trainingPathCtx?.handbookCategoryList?.some((list) => list?.isSelected)
    ) {
      filter.category = trainingPathCtx?.handbookCategoryList
        ?.filter((list) => list?.isSelected)
        ?.map((list) => list?.name)?.[0];
    }
    trainingPathCtx?.setHandbookItemsListHandler({
      handbookPagination: {
        count: 0,
        items: [],
        pageInfo: {
          currentPage: 0,
          itemCount: 0,
          pageCount: 0,
          perPage: 0,
          hasNextPage: false,
          hasPreviousPage: false,
        },
      },
    });
    getHandbookData({
      variables: {
        page: 1,
        ...((filter?.type || filter?.query || filter?.category) && {
          filter: filter,
        }),
      },
    });
  };

  const [getHandbookData, { loading: handbookDataLoading }] = useLazyQuery<
    HandbookResponse,
    HandbookVariable
  >(GET_TRAINING_HANDBOOK_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _data = cloneDeep(data);
      if (trainingPathCtx?.addedItems?.length > 0) {
        let tempData: ITrainingHandbook = JSON.parse(JSON.stringify(_data));
        let filteredData: any[] = [];
        _data?.handbookPagination?.items?.map((item) => {
          let flag = trainingPathCtx?.addedItems?.some(
            (added) => added?.eid === item?.eid
          );
          if (!flag) {
            filteredData?.push(item);
          }
        });
        tempData!.handbookPagination!.items! = filteredData;
        // trainingPathCtx?.setHandbookItemsListHandler(tempData);
        trainingPathCtx?.setHandbookItemsListHandler({
          handbookPagination: {
            count: tempData?.handbookPagination?.count,
            items: new HandbookModal(filteredData).data,
            pageInfo: tempData?.handbookPagination?.pageInfo,
          },
        });
      } else {
        trainingPathCtx?.setHandbookItemsListHandler({
          handbookPagination: {
            count: _data?.handbookPagination?.count,
            items: new HandbookModal(_data?.handbookPagination?.items).data,
            pageInfo: _data?.handbookPagination?.pageInfo,
          },
        });
      }
    },
  });
  const [getChapterData, { loading: chapterDataLoading }] = useLazyQuery<
    ChapterResponse,
    ChapterVariable
  >(GET_CHAPTERS_LIST_FOR_TRAINING, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _data = cloneDeep(data);
      _data?.SopPagination?.items?.forEach((_item) => (_item.type = 'sop'));
      if (trainingPathCtx?.addedItems?.length > 0) {
        let tempData: IChapter = JSON.parse(JSON.stringify(_data));
        let filteredData: any[] = [];
        _data?.SopPagination?.items?.map((item) => {
          let flag = trainingPathCtx?.addedItems?.some(
            (added) => added?.eid === item?.eid
          );
          if (!flag) {
            filteredData?.push(item);
          }
        });
        tempData!.SopPagination!.items! = filteredData;
        // trainingPathCtx?.setHandbookItemsListHandler(tempData);
        trainingPathCtx?.setChapterItemsListHandler({
          SopPagination: {
            count: tempData?.SopPagination?.count,
            items: new ChapterModal(filteredData).data,
            pageInfo: tempData?.SopPagination?.pageInfo,
          },
        });
      } else {
        trainingPathCtx?.setChapterItemsListHandler({
          SopPagination: {
            count: _data?.SopPagination?.count,
            items: new ChapterModal(_data?.SopPagination?.items).data,
            pageInfo: _data?.SopPagination?.pageInfo,
          },
        });
      }
    },
  });

  useEffect(() => {
    trainingPathCtx?.setChapterListLoadingHandler(chapterDataLoading);
  }, [chapterDataLoading]);

  const switchOnStep = (val: Step, i: number) => {
    if (
      trainingPathCtx?.trainingPathName?.trim() &&
      trainingPathCtx?.addedItems?.length > 0
    ) {
      // if (trainingPathCtx?.stepCount !== i + 1) {
      trainingPathCtx?.setStepCountHandler((i + 1) as 1 | 2);
      let tempStepData: Step[] = [
        {
          name: t('add_content_structure'),
          stringId: 'Add content & structure',
          active: true,
          icon: faPen,
          outline: 'none',
        },
        {
          name: t('assign_publish'),
          stringId: 'Assign and publish',
          active: false,
          icon: faCheck,
          outline: 'solid',
        },
      ];
      if (val?.stringId === 'Add content & structure') {
        tempStepData[0].active = true;
      } else if (val?.stringId === 'Assign and publish') {
        if (!trainingPathCtx?.isCounterMovedFromFirstStep) {
          trainingPathCtx?.setIsCounterMovedFromFirstStepHandler(true);
        }
        tempStepData[0].active = true;
        tempStepData[1].active = true;
      }
      setStepData(tempStepData);
    }
  };

  const moveToNextStepHandler = () => {
    trainingPathCtx?.setStepCountHandler(2);
    let tempStepData: Step[] = [
      {
        name: t('add_content_structure'),
        stringId: 'Add content & structure',
        active: true,
        icon: faPen,
        outline: 'none',
      },
      {
        name: t('assign_publish'),
        stringId: 'Assign and publish',
        active: true,
        icon: faCheck,
        outline: 'solid',
      },
    ];
    deployEvent(AmplitudeEventNames.SAVE_CONTENT_AND_STRUCTURE, {
      training_path_name: trainingPathCtx?.trainingPathName,
      total_items_added: trainingPathCtx.addedItems?.length || 0,
      add_new_path_funnel_id: 4,
      edit_training_funnel_id: 4,
      total_chapters:
        trainingPathCtx?.addedItems?.filter((item) => item?.type === 'sop')
          ?.length || 0,
    });
    setStepData(tempStepData);
  };

  const [createDraft, { loading: creatingDraft }] = useMutation(
    CREATE_TRAINING_PATH,
    {
      onCompleted: (data) => {
        setClickedPublishedButton(true);
        toast({
          title: tc('success'),
          description: t('saved_draft_successfully_toast'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        history.push('/drafts/list');
      },
      onError: () => {
        setClickedPublishedButton(true);
        toast({
          title: t('save_draft_error_toast'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        history.push('/drafts/list');
      },
    }
  );

  const [saveAsDraft, { loading: savingAsDraft }] = useMutation(
    CREATE_TRAINING_PATH,
    {
      onCompleted: (data) => {
        trainingPathCtx?.setShouldMoveToNextStep(true);
        setClickedPublishedButton(true);
        toast({
          title: tc('success'),
          description: t('saved_draft_successfully_toast'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        history.push('/drafts/list');
      },
      onError: () => {
        setClickedPublishedButton(true);
        toast({
          title: t('save_draft_error_toast'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        history.push('/drafts/list');
      },
    }
  );

  const [saveDraft, { loading: savingDraft }] = useMutation(
    CREATE_TRAINING_PATH,
    {
      onCompleted: (data) => {
        setClickedPublishedButton(true);
        toast({
          title: tc('success'),
          description: t('saved_draft_successfully_toast'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        history.push('/drafts/list');
      },
      onError: () => {
        setClickedPublishedButton(true);
        toast({
          title: t('save_draft_error_toast'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        history.push('/drafts/list');
      },
    }
  );

  const captureEvent = () => {
    let roleNames: string[] = [];
    let locationNames: string[] = [];
    let memberNames: string[] = [];
    let supervisorNames: string[] = [];
    trainingPathCtx?.rolesList?.map((role) => {
      trainingPathCtx?.selectedRoles?.map((selectedRole) => {
        if (selectedRole === role?.id) {
          roleNames.push(role?.value);
        }
      });
    });

    locationNames =
      entityUserData
        ?.filter((entity) =>
          trainingPathCtx?.selectedLocations?.includes(entity?.eid)
        )
        ?.map((loc) => loc?.name) || [];
    memberNames =
      entityUserData
        ?.filter((entity) =>
          trainingPathCtx?.selectedMembers?.includes(entity?.eid)
        )
        ?.map((mem) => mem?.name) || [];
    supervisorNames =
      entityUserData
        ?.filter((entity) =>
          trainingPathCtx?.selectedSupervisors?.includes(entity?.eid)
        )
        ?.map((mem) => mem?.name) || [];

    deployEvent(AmplitudeEventNames.PUBLISH_TRAINING_PATH, {
      add_new_path_funnel_id: 4,
      visibility: visibility,
      training_path_id: '',
      training_path_name: trainingPathCtx?.trainingPathName?.trim() || '',
      total_items_added: trainingPathCtx?.addedItems?.length || 0,
      total_trainings_added: '',
      assign_to_roles: roleNames,
      assign_to_locations: locationNames,
      assign_to_members: memberNames,
      supervisor: supervisorNames,
      total_chapters: '',
    });
  };

  const [
    createTraining,
    { data: createTrainingData, loading: creatingTrainingPath },
  ] = useMutation(CREATE_TRAINING_PATH, {
    onCompleted: (data) => {
      captureEvent();
      setClickedPublishedButton(true);
      toast({
        title: tc('success'),
        description:
          data?.CreateTraining?.status === 'draft'
            ? t('draft_create_success_description_toast')
            : t('training_path_create_success_description_toast'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      if (data?.CreateTraining?.status === 'draft') {
        history.push('/drafts/list');
      } else {
        history.push('/training');
      }
    },
    onError: () => {
      setClickedPublishedButton(true);
      toast({
        title: t('create_training_path_error_toast'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      trainingPathCtx?.isCreate
        ? history.push('/drafts/list')
        : history.push('/training');
    },
  });

  const generateRandomIconHandler = () => {
    if (trainingPathCtx?.trainingPathIconsList?.length > 0) {
      let random =
        Math.floor(
          Math.random() * (trainingPathCtx?.trainingPathIconsList?.length - 0)
        ) + 0;
      return {
        url: trainingPathCtx?.trainingPathIconsList?.[random]?.url,
        backgroundColor:
          trainingPathCtx?.trainingPathIconsList?.[random]?.backgroundColor,
      };
    } else {
      return {
        url: '',
        backgroundColor: '',
      };
    }
  };

  const genericInputDataHandler = (status: 'active' | 'draft') => {
    let contentArr: any[] = [];

    trainingPathCtx?.addedItems.map((structure: any) => {
      if (structure.type === 'card' || structure.type === 'deck') {
        contentArr.push({
          type: structure?.type,
          eid: structure?.eid,
          title: structure?.title,
        });
      } else {
        contentArr.push({
          type: structure?.type,
          eid: structure?.eid ? structure?.eid : null,
          title: structure?.title,
          backgroundColor: structure?.backgroundColor,
        });
      }
    });

    let thumbnailData = generateRandomIconHandler();
    let thumbnailIcon = '';
    let thumbnailColor = '';
    if (!trainingPathCtx?.trainingPathIcon?.url) {
      thumbnailIcon = thumbnailData?.url;
      thumbnailColor = thumbnailData?.backgroundColor;
    } else {
      thumbnailIcon = trainingPathCtx?.trainingPathIcon?.url;
      thumbnailColor = trainingPathCtx?.trainingPathIcon?.backgroundColor;
    }
    let input: {
      title: string;
      thumbnail: string;
      thumbnailColor: string;
      description: string;
      supervisors: string[];
      assignedTo: string[];
      contents: any[];
      status: 'draft' | 'active';
      assignedToLocation?: string[];
      assignedToRole?: string[];
      eid?: string;
    } = {
      title: trainingPathCtx?.trainingPathName?.trim(),
      thumbnail: thumbnailIcon,
      thumbnailColor: thumbnailColor,
      description: '',
      supervisors: trainingPathCtx?.selectedSupervisors,
      assignedTo: trainingPathCtx?.selectedMembers,
      contents: contentArr,
      status: status,
    };
    if (trainingPathCtx?.selectedLocations?.length > 0) {
      input.assignedToLocation = trainingPathCtx?.selectedLocations;
    } else {
      input.assignedToLocation = [];
    }
    if (trainingPathCtx?.selectedRoles?.length > 0) {
      let roleNames: string[] = [];
      trainingPathCtx?.rolesList?.map((role) => {
        trainingPathCtx?.selectedRoles?.map((selectedRole) => {
          if (selectedRole === role?.id) {
            roleNames.push(role?.value);
          }
        });
      });
      input.assignedToRole = roleNames;
    } else {
      input.assignedToRole = [];
    }
    if (params?.id && params?.id !== 'draft') {
      input.eid = params?.id;
    }
    return input;
  };

  const createDraftHandler = () => {
    deployEvent(AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_SAVE_AS_DRAFT);
    createDraft({
      variables: {
        input: genericInputDataHandler('draft'),
        filter: {
          status: 'draft',
        },
      },
    });
  };

  const saveDraftHandler = () => {
    deployEvent(AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_SAVE_AS_DRAFT);
    saveDraft({
      variables: {
        input: genericInputDataHandler('draft'),
        filter: {
          status: 'draft',
        },
      },
    });
  };

  useEffect(() => {
    if (
      trainingPathCtx?.shouldMoveToNextStep &&
      trainingPathCtx?.addedItems?.length > 0 &&
      trainingPathCtx?.trainingPathName?.trim()
    ) {
      moveToNextStepHandler();
    }
  }, [trainingPathCtx?.shouldMoveToNextStep]);

  const saveAndNextSaveAsDraftHandler = () => {
    trainingPathCtx?.setShouldMoveToNextStep(false);
    saveAsDraftHandler();
  };

  const saveAsDraftHandler = () => {
    deployEvent(AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_SAVE_AS_DRAFT);

    if (editDraftId) {
      // input.eid = editDraftId;
      // let _trainingData = JSON.parse(JSON.stringify(trainingData));
      // console.log('training data assigned to : ', _trainingData);
      // if (_trainingData && _trainingData?.assignedTo) {
      //   _trainingData.assignedTo = _trainingData?.assignedTo?.map(
      //     (assign: any) => assign?.eid
      //   );
      // } else {
      //   _trainingData.assignedTo = [];
      // }
      // let changeLogResponse =
      //   TrainingDraftMethods.changeLogDataOperationHandler(
      //     _trainingData,
      //     input
      //   );
      // console.log('changeLogResponse : ', changeLogResponse);
      // let trainingChangeLogInput: ICreateChangeLog = {
      //   // changes: dataUpdation,
      //   changes: changeLogResponse,
      //   status: 'draft',
      //   trainingId: editDraftId,
      // };
      // let _trainingChangeLogInput: ICreateChangeLog = JSON.parse(
      //   JSON.stringify(trainingChangeLogInput)
      // );
      // _trainingChangeLogInput.changes.map((change) => {
      //   console.log('change : ', change);
      //   if (
      //     change.changeEvent === 'cardAdded' ||
      //     change.changeEvent === 'cardRemoved'
      //   ) {
      //     delete change.title;
      //     delete change.milestone;
      //     delete change.icon;
      //   }
      // });
      // console.log('final _trainingChangeLogInput : ', _trainingChangeLogInput);
      // setLeftUpdateDraftLoading(true);
      // createChangeLog({
      //   variables: {
      //     input: _trainingChangeLogInput,
      //   },
      // });
      // updateTraining({
      //   variables: {
      //     input: input,
      //   },
      // });
    } else {
      saveAsDraft({
        variables: {
          input: genericInputDataHandler('draft'),
          filter: {
            status: 'draft',
          },
        },
      });
    }
  };

  const confirmVisibility = (
    input: any,
    _isDraft: boolean,
    callback: (values: { variables: { input: any } }) => Promise<any>
  ) => {
    if (_isDraft) {
      deployEvent(AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_PUBLISH);
      callback({
        variables: {
          input: { ...input, visibility: 'private' },
        },
      });
      return;
    }
    confirm({
      title: (
        <PreviewHeader
          title={trainingPathCtx?.trainingPathName?.trim()}
          color='#cabdff'
        />
      ),
      content: (
        <VisibilityComponent
          defaultValue={visibility}
          onChange={(newValue) => setVisibility(newValue)}
        />
      ),
      okText: t('save_publish'),
      isCentered: true,
      contentProps: {
        paddingTop: '12px',
        paddingBottom: '12px',
        maxWidth: '700px',
        borderRadius: '16px',
      },
      onOK: () => {
        deployEvent(AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_PUBLISH);
        return callback({
          variables: {
            input: { ...input, visibility: visibilityRef.current },
          },
        });
      },
      onCancel: () => {
        // setRightCreateDraftLoading(false);
      },
    });
  };

  const publishHandler = () => {
    // deployEvent(AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_ADD_NEXT_BUTTON);
    let contentArr: any[] = [];
    console.log(trainingPathCtx?.addedItems);
    trainingPathCtx?.addedItems.map((structure) => {
      contentArr.push({
        type: structure?.type || 'sop',
        eid: structure.eid,
        title: structure?.title,
        isMandatory: structure?.isMandatory || false,
        ...(structure.type === 'milestone' && {
          thumbnail: structure?.content?.[0]?.url || '',
          addedBy: structure?.content?.[0]?.addedBy || '',
          dateAdded:
            structure?.content?.[0]?.dateAdded || moment()?.toISOString(),
          fileSize: structure?.content?.[0]?.fileSize || 0,
        }),
        ...(structure.type === 'milestone' && { backgroundColor: '#ffffff' }),
      });
    });
    console.log(contentArr);

    let thumbnailData = generateRandomIconHandler();
    let thumbnailIcon = '';
    let thumbnailColor = '';
    if (!trainingPathCtx?.trainingPathIcon?.url) {
      thumbnailIcon = thumbnailData?.url;
      thumbnailColor = thumbnailData?.backgroundColor;
    } else {
      thumbnailIcon = trainingPathCtx?.trainingPathIcon?.url;
      thumbnailColor = trainingPathCtx?.trainingPathIcon?.backgroundColor;
    }

    let input: {
      title: string;
      thumbnail: string;
      thumbnailColor: string;
      description: string;
      supervisors: string[];
      assignedTo: string[];
      contents: any;
      status: 'draft' | 'active';
      assignedToLocation?: string[];
      assignedToRole?: string[];
    } = {
      title: trainingPathCtx?.trainingPathName?.trim(),
      thumbnail: thumbnailIcon,
      thumbnailColor: thumbnailColor,
      description: '',
      supervisors: trainingPathCtx?.selectedSupervisors,
      assignedTo: trainingPathCtx?.selectedMembers,
      contents: contentArr,
      status: 'active',
    };
    if (trainingPathCtx?.selectedLocations?.length > 0) {
      input.assignedToLocation = trainingPathCtx?.selectedLocations;
    }
    if (trainingPathCtx?.selectedRoles?.length > 0) {
      let roleNames: string[] = [];
      trainingPathCtx?.rolesList?.map((role) => {
        trainingPathCtx?.selectedRoles?.map((selectedRole) => {
          if (selectedRole === role?.id) {
            roleNames.push(role?.value);
          }
        });
      });
      input.assignedToRole = roleNames;
    } else {
      input.assignedToRole = [];
    }
    let locationNames =
      entityUserData
        ?.filter((entity) =>
          input?.assignedToLocation?.some((loc) => loc === entity?.eid)
        )
        ?.map((loc) => loc?.name) || [];
    let memberNames =
      entityUserData
        ?.filter((entity) =>
          input?.assignedTo?.some((mem) => mem === entity?.eid)
        )
        ?.map((mem) => mem?.name) || [];
    let supervisorNames =
      entityUserData
        ?.filter((entity) =>
          input?.supervisors?.some((mem) => mem === entity?.eid)
        )
        ?.map((mem) => mem?.name) || [];
    deployEvent(AmplitudeEventNames.PUBLISH_TRAINING_CLICK, {
      assign_to_roles: input.assignedToRole || [],
      assign_to_locations: locationNames,
      assign_to_members: memberNames,
      supervisor: supervisorNames,
      training_path_id: '',
    });
    if (!editDraftId) {
      confirmVisibility(input, !trainingPathCtx?.isCreate, createTraining);
    } else {
      setShouldShowChangeLogModal(true);
    }

    // let input: {
    //   title: string;
    //   thumbnail: string;
    //   thumbnailColor: string;
    //   description: string;
    //   supervisors: string[];
    //   assignedTo: string[];
    //   contents: any;
    //   status: 'draft' | 'active';
    //   assignedToLocation?: string[];
    //   assignedToRole?: string[];
    // } = {
    //   title: trainingPathCtx?.trainingPathName?.trim(),
    //   thumbnail: trainingPathCtx?.trainingPathIcon?.url,
    //   thumbnailColor: trainingPathCtx?.trainingPathIcon?.backgroundColor,
    //   description: '',
    //   supervisors: trainingPathCtx?.selectedSupervisors,
    //   assignedTo: trainingPathCtx?.selectedMembers,
    //   contents: contentArr,
    //   status: 'active',
    // };
    // if (trainingPathCtx?.selectedLocations?.length > 0) {
    //   input.assignedToLocation = trainingPathCtx?.selectedLocations;
    // }
    // if (trainingPathCtx?.selectedRoles?.length > 0) {
    //   let roleNames: string[] = [];
    //   trainingPathCtx?.rolesList?.map((role) => {
    //     trainingPathCtx?.selectedRoles?.map((selectedRole) => {
    //       if (selectedRole === role?.id) {
    //         roleNames.push(role?.value);
    //       }
    //     });
    //   });
    //   input.assignedToRole = roleNames;
    // } else {
    //   input.assignedToRole = [];
    // }
    // if (!editDraftId) {
    //   confirmVisibility(input, !trainingPathCtx?.isCreate, createTraining);
    // } else {
    //   setShouldShowChangeLogModal(true);
    // }
  };

  const [createChangeLog, { loading: createChangeLogLoading }] =
    useMutation(CREATE_CHANGE_LOG);

  const publishTrainingDraftHandler = () => {
    // setShouldShowChangeLogModal(true);
    publishChangesHandler();
  };

  const saveUpdatedDraftHandler = () => {
    let _trainingData = JSON.parse(JSON.stringify(trainingData));
    if (_trainingData && _trainingData?.assignedTo) {
      _trainingData.assignedTo = _trainingData?.assignedTo?.map(
        (assign: any) => assign?.eid
      );
    } else {
      _trainingData.assignedTo = [];
    }
    let changeLogResponse = TrainingDraftMethods.changeLogDataOperationHandler(
      _trainingData,
      genericInputDataHandler('draft')
    );
    let trainingChangeLogInput: ICreateChangeLog = {
      changes: changeLogResponse,
      status: 'draft',
      trainingId: params?.id,
    };

    let _trainingChangeLogInput: ICreateChangeLog = JSON.parse(
      JSON.stringify(trainingChangeLogInput)
    );
    _trainingChangeLogInput.changes.map((change) => {
      if (
        change.changeEvent === 'cardAdded' ||
        change.changeEvent === 'cardRemoved'
      ) {
        delete change.title;
        delete change.milestone;
        delete change.icon;
      }
    });
    createChangeLog({
      variables: {
        input: _trainingChangeLogInput,
      },
    });
    updateTraining({
      variables: {
        input: genericInputDataHandler('draft'),
      },
    });
  };

  const [updateTraining, { loading: isPublishingChanges }] = useMutation(
    //
    UPDATE_TRAINING,
    {
      onCompleted: (data) => {
        setClickedPublishedButton(true);
        toast({
          title:
            data?.UpdateTraining?.status === 'draft'
              ? t('draft_update_success_toast')
              : t('training_path_update_success_toast'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        if (data?.UpdateTraining?.status === 'active') {
          history.push('/training');
        } else {
          history.push('/drafts/list');
        }
      },
      onError: () => {
        toast({
          title: t('training_path_update_error_toast'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const changeLogModalHandler = (type: string) => {
    setIsPublishChangeDiffCheckModal(false);
    if (type === 'publish' || type === 'draft') {
      let contentArr: any[] = [];
      trainingPathCtx?.addedItems.map((structure: any) => {
        if (structure.type === 'card' || structure.type === 'deck') {
          contentArr.push({
            type: structure?.type,
            eid: structure?.eid,
            title: structure?.title,
          });
        } else {
          contentArr.push({
            type: structure?.type,
            eid: structure?.eid ? structure?.eid : null,
            title: structure?.title,
            backgroundColor: structure?.backgroundColor,
          });
        }
      });
      confirmVisibility(
        genericInputDataHandler(type === 'draft' ? 'draft' : 'active'),
        type === 'draft',
        updateTraining
      );
    }
  };

  const checkIfChangesMadeHandler = () => {
    let flag = false;
    if (editDraftId && trainingData) {
      let input = genericInputDataHandler('active');
      input.eid = editDraftId;
      let _trainingData = JSON.parse(JSON.stringify(trainingData));
      if (_trainingData && _trainingData?.assignedTo) {
        _trainingData.assignedTo = _trainingData?.assignedTo?.map(
          (assign: any) => assign?.eid
        );
      } else {
        _trainingData.assignedTo = [];
      }
      let changeLogResponse =
        TrainingDraftMethods.changeLogDataOperationHandler(
          _trainingData,
          input
        );
      if (changeLogResponse?.length > 0) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      if (
        trainingPathCtx?.trainingPathName?.trim() ||
        trainingPathCtx?.trainingPathIcon?.url ||
        trainingPathCtx?.selectedLocations?.length > 0 ||
        trainingPathCtx?.selectedMembers?.length > 0 ||
        trainingPathCtx?.selectedRoles?.length > 0 ||
        trainingPathCtx?.selectedSupervisors?.length > 0 ||
        trainingPathCtx?.addedItems?.length > 0
      ) {
        flag = true;
      }
    }
    if (clickedPublishedButton) {
      flag = false;
    }
    return flag;
  };

  const getContentDetails = (addedItems: any[]) => {
    let _arr: any[] = [];
    addedItems?.map((item: any) => {
      if (item?.type === 'card' || item?.type === 'milestone') {
        _arr?.push(item);
      } else {
        let _cards = item?.cards;
        _arr?.push(..._cards);
      }
    });
    return _arr;
  };

  const publishChangesHandler = () => {
    let assignedToIDs = trainingData?.assignedToUsers.map(
      (assign: any) => assign.eid
    );
    let contentsArr: any[] = [];
    trainingPathCtx?.addedItems.map((content: any) => {
      if (content.type === 'milestone') {
        if (content.eid) {
          contentsArr.push({
            type: content.type,
            eid: content.eid,
            title: content.title,
            backgroundColor: content.backgroundColor || 'rgb(239, 239, 239)',
          });
        } else {
          contentsArr.push({
            type: content.type,
            title: content.title,
            backgroundColor: content.backgroundColor || 'rgb(239, 239, 239)',
          });
        }
      } else {
        contentsArr.push({
          type: content.type,
          eid: content.eid,
          title: content.title,
        });
      }
    });

    let thumbnailData = generateRandomIconHandler();
    let thumbnailIcon = '';
    let thumbnailColor = '';
    if (!trainingPathCtx?.trainingPathIcon?.url) {
      thumbnailIcon = thumbnailData?.url;
      thumbnailColor = thumbnailData?.backgroundColor;
    } else {
      thumbnailIcon = trainingPathCtx?.trainingPathIcon?.url;
      thumbnailColor = trainingPathCtx?.trainingPathIcon?.backgroundColor;
    }

    let input: {
      title: string;
      thumbnail: string;
      thumbnailColor: string;
      description: string;
      supervisors: string[];
      assignedTo: string[];
      contents: any[];
      status: string;
      assignedToLocation?: string[];
      assignedToRole?: string[];
      eid?: string;
      visibility: string;
    } = {
      eid: params?.id,
      title: trainingPathCtx?.trainingPathName?.trim(),
      status: trainingData?.status,
      thumbnail: thumbnailIcon,
      thumbnailColor: thumbnailColor,
      description: trainingData?.description,
      visibility: trainingData?.visibility,
      supervisors: trainingPathCtx?.selectedSupervisors,
      assignedTo: trainingPathCtx?.selectedMembers,
      contents: contentsArr,
    };
    if (trainingPathCtx?.selectedLocations?.length > 0) {
      input.assignedToLocation = trainingPathCtx?.selectedLocations;
    } else {
      input.assignedToLocation = [];
    }
    if (trainingPathCtx?.selectedRoles?.length > 0) {
      let roleNames: string[] = [];
      trainingPathCtx?.rolesList?.map((role) => {
        trainingPathCtx?.selectedRoles?.map((selectedRole) => {
          if (selectedRole === role?.id) {
            roleNames.push(role?.value);
          }
        });
      });
      input.assignedToRole = roleNames;
    } else {
      input.assignedToRole = [];
    }
    setIsPublishChangeDiffCheckModal(true);
    let _initialTrainingData = JSON.parse(JSON.stringify(initialTrainingData));
    if (
      _initialTrainingData &&
      _initialTrainingData?.assignedTo &&
      _initialTrainingData?.assignedTo?.length > 0
    ) {
      let foundIds = _initialTrainingData?.assignedTo?.map(
        (assign: any) => assign?.eid
      );
      _initialTrainingData.assignedTo = foundIds;
    }
    let _changeLogResponse = TrainingDraftMethods.changeLogDataOperationHandler(
      _initialTrainingData,
      input
    );
    setChangeLogResponse(_changeLogResponse);
    let ObjMap: any = {};
    _changeLogResponse.forEach((element: any) => {
      var makeKey = element.changeEvent;
      if (!ObjMap[makeKey]) {
        ObjMap[makeKey] = [];
      }
      ObjMap[makeKey].push(element);
    });
    let createdObject: IPublishChangeDiffCheckTable[] = [];
    Object.entries(ObjMap).map((val: any) => {
      createdObject.push({
        tableType: val[0],
        tableData: { changes: val[1] },
      });
    });
    setChangedData(createdObject);
    setUpdatedTrainingInput(input);
  };

  return (
    <TrainingPathSubContainerWrapper>
      <Flex justify={'space-between'} align={'center'} mt={4} width='full'>
        <Box width='50%'>
          <Text fontSize='28px' fontWeight='700' color='#272b30'>
            {getTitleHandler()}
          </Text>
          <Box className='create-training-breadcrumb'>
            <FontAwesomeIcon icon={faChevronLeft as IconProp} />
            {getSubTitleHandler()}
          </Box>
        </Box>
        <Flex width='50%' justifyContent='flex-end'>
          <TrainingPathActionButtons
            setTrainingProgressModal={setTrainingProgressModal}
            savingAsDraft={savingAsDraft}
            saveAsDraftHandler={saveAsDraftHandler}
            moveToNextStepHandler={moveToNextStepHandler}
            publishHandler={publishHandler}
            isPublishingChanges={isPublishingChanges}
            creatingDraft={creatingDraft}
            saveUpdatedDraftHandler={saveUpdatedDraftHandler}
            publishTrainingDraftHandler={publishTrainingDraftHandler}
            savingDraft={savingDraft}
            saveDraftHandler={saveDraftHandler}
            createDraftHandler={createDraftHandler}
            saveAndNextSaveAsDraftHandler={saveAndNextSaveAsDraftHandler}
            previewClickBtn={previewNewHandler}
            training_preview_button={t('preview_button')}
            training_save_as_draft_button={t('save_as_draft_button')}
            training_save_and_next_button={t('save_and_next_button')}
            training_publish_button={t('publish_button')}
            training_save_button={t('save_button')}
            training_preview_publish_button={t('preview_publish_button')}
            training_create_draft_button={t('create_draft_button')}
            training_prevent_publish_selection={t('preventPublishSelection')}
          />
        </Flex>
      </Flex>
      <Box
        id='create-path-step-div'
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        {checkIfChangesMadeHandler() && (
          <Prompt message='Any changes made will be lost. Are you sure you want to leave the page?' />
        )}
        <Steps
          stepData={{
            count: 2,
            steps: stepData,
          }}
          switchOnStep={switchOnStep}
        />
      </Box>
      {trainingPathCtx?.stepCount === 1 ? (
        <Card>
          <Stack
            divider={<StackDivider borderColor='gray.200' />}
            direction={'row'}
            spacing={4}
          >
            <Box width={'60%'} pr={2}>
              <TrainingCardNameIcon />
              <TrainingPathCountAction />
              <TrainingPathSelectedItems />
            </Box>
            <Box width={'40%'}>
              <TrainingHandbookList />
            </Box>
          </Stack>
        </Card>
      ) : (
        <TrainingPathAssign />
      )}
      {shouldShowChangeLogModal && (
        <ChangeLog
          isReadOnly={false}
          open={shouldShowChangeLogModal}
          onClose={(type: string) => changeLogModalHandler(type)}
          selectedDraftData={trainingData}
        />
      )}
      {/* {trainingPathCtx?.selectedCard && (
        <CardPreview
          cardSelected={trainingPathCtx?.selectedCard}
          onCloseClick={() => trainingPathCtx?.setSelectedCardHandler(null)}
          disableEdit
        />
      )}
      {trainingPathCtx?.selectedDeck && (
        <CardPreviewCarousel
          deckSelected={trainingPathCtx?.selectedDeck}
          onCloseClick={() => trainingPathCtx?.setSelectedDeckHandler(null)}
        />
      )} */}
      {trainingProgressModal && (
        <CustomPathCarousel
          enablePreview
          isOpen={trainingProgressModal}
          onClose={() => setTrainingProgressModal(false)}
          selectedTrainingData={{
            contentDetails: getContentDetails(trainingPathCtx?.addedItems),
          }}
        />
      )}
      {isPublishChangeDiffCheckModalOpen && (
        <PublishChangeDiffCheckModal
          title={t('change_log')}
          open={isPublishChangeDiffCheckModalOpen}
          onClose={(type: 'cancel' | 'publish' | 'continue' | 'draft') =>
            changeLogModalHandler(type)
          }
          changedData={changedData}
          trainingData={{
            title: trainingPathCtx?.trainingPathName,
            entityId: trainingData?.entityId,
            createdBy: trainingData?.createdBy,
            status: trainingData?.status,
            thumbnail: trainingPathCtx?.trainingPathIcon?.url,
            description: trainingData?.description,
            visibility: trainingData?.visibility,
            supervisors: trainingPathCtx?.selectedSupervisors,
            contentDetails: trainingPathCtx?.addedItems,
          }}
          initialTrainingData={initialTrainingData}
          thirdStepData={{
            roles: trainingPathCtx?.rolesList,
            supervisors: trainingPathCtx?.supervisorsList,
            locations: trainingPathCtx?.locationsList,
            members: trainingPathCtx?.membersList,
          }}
        />
      )}
    </TrainingPathSubContainerWrapper>
  );
};

export default TrainingPathSubContainer;
