import React, { FC } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { AuditHelper } from 'configs';
import { IFormInput } from '../form-types';

const AuditTemplateInfo: FC = () => {
  const getWidth = (value: number) => {
    return Math.min(Math.max(value + 8, 30), 58) + 'ch';
  };

  return (
    <Controller<IFormInput, 'title'>
      name='title'
      defaultValue=''
      render={({ field, fieldState }) => (
        <Flex>
          <FormControl
            width={getWidth(field.value?.length)}
            isInvalid={!!fieldState?.error}
          >
            <Input
              fontSize='20px'
              fontWeight='600'
              variant='unstyled'
              placeholder='Template name'
              id='audit-template-title'
              {...field}
              onBlur={AuditHelper.save(field.onBlur)}
              resize='horizontal'
            />

            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
          <Text as='span' color='rgba(255, 106, 85, 1)' pl={1}>
            *
          </Text>
          <Spacer />
        </Flex>
      )}
    />
  );
};

export default AuditTemplateInfo;
