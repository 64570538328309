import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { IFormInput } from '../../audit-schedule.types';
import LocationSelect from './LocationSelect';
import { useAuditorOptions } from './useAuditorOptions';
import AuditorUserSelect from './AuditorUserSelect';

interface IProps {
  fieldId: string;
  fieldIndex: number;
}

const AuditorInput: FC<IProps> = ({ fieldIndex }) => {
  const options = useAuditorOptions(fieldIndex);

  return (
    <Flex gap={5}>
      <Controller<IFormInput, `auditors.${number}.userId`>
        name={`auditors.${fieldIndex}.userId`}
        rules={{
          required: 'Please select an auditor',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl width='unset' flex={2} isInvalid={fieldState.invalid}>
              <AuditorUserSelect {...field} options={options.auditors} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Controller<IFormInput, `auditors.${number}.locations`>
        name={`auditors.${fieldIndex}.locations`}
        rules={{
          required: 'Please select a location',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              display='flex'
              flexDir='column'
              alignItems='flex-end'
              width='unset'
              flex={1}
              isInvalid={fieldState.invalid}
            >
              <LocationSelect
                options={options.locations}
                value={field.value}
                onChange={field.onChange}
                disabled={field.disabled}
              />

              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Flex>
  );
};

export default React.memo(AuditorInput);
