import { SorterResult } from 'atoms';
import { UserEntityData } from 'shared/graphql/shared-types';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';

export interface LocationOwnerEntity
  extends Pick<UserEntityData, 'eid' | 'name' | 'profilePic'> {}

export enum Punctuality {
  COMPLETED = 'Completed',
  ON_TIME = 'On time',
  MISSED = 'Missed',
}

export interface ProcessDataEntity {
  key: string;
  isDateRange: boolean;
  dateRange?: string;
  locationName?: string;
  locationId?: string;
  locationOwner?: LocationOwnerEntity;
  conductedOn?: string;
  punctuality?: Punctuality;
  auditScore?: string;
  totalFlags?: number;
  reportsUrl?: string;
  isCompleted?: boolean;
  item?: AuditSessionEntity;
  children?: ProcessDataEntity[];
}
