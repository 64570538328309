import React, { FC } from 'react';
import { FieldArray } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { QuestionImage } from '../../../../forms/create-form';
import { IFormInput, OptionWhiteList } from '../../form-types';
import { HelperTextFor, MediaComponent } from '../audit-questions';
import OptionsView from './OptionsView';

interface IProps {
  questionIndex: number;
  data: FieldArray<IFormInput, 'questions'>;
}

const QuestionTypeRender: FC<IProps> = ({ data, questionIndex }) => {
  const { t } = useTranslation('form', { keyPrefix: 'questionType' });

  if (HelperTextFor.includes(data.qType)) {
    return <MediaComponent questionType={data.qType} />;
  }

  if (OptionWhiteList.includes(data.qType)) {
    return (
      <OptionsView
        questionIndex={questionIndex}
        qType={data.qType}
        options={data.options}
      />
    );
  }

  const questionType = QuestionImage[data.qType];

  return (
    <Flex
      gap={2}
      align='center'
      bg='#F8F8F8'
      height='48px'
      borderRadius='12px'
      borderWidth={2}
      borderColor='#EFEFEF'
      paddingInline='16px'
    >
      <Box boxSize='16px'>
        <img alt={questionType?.title} src={questionType?.image} />
      </Box>
      <Box fontSize='12px' flex={1} fontWeight='600' isTruncated>
        {t(questionType?.title)}
      </Box>
    </Flex>
  );
};

export default QuestionTypeRender;
