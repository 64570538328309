import { Table, TableProps } from 'antd';
import styled from '@emotion/styled';
import { PropsWithChildren, ReactElement } from 'react';
import { Any } from 'types';
import { paginationStyles } from './pagination.styles';

const StyledTable = styled(Table)`
  width: 100%;
  table {
    width: 100%;
  }

  .ant-spin-blur {
    opacity: 1;
  }

  .ant-table-thead {
    > tr > th {
      background: unset;
      border-bottom: unset;
      padding: 16px 8px;

      //:first-child {
      //  padding-left: 0;
      //}
      //:last-child {
      //  padding-right: 0;
      //}
    }
    //.ant-table-column-sort {
    //  border-radius: 8px 8px 0 0 !important;
    //}
    .ant-table-column-has-sorters {
      .ant-table-column-title {
        flex: initial;
      }
      .ant-table-column-sorter {
        margin-right: auto;

        .ant-table-column-sorter-down,
        .ant-table-column-sorter-up {
          &.active {
            color: #000000;
          }
        }
      }
      //:hover {
      //  border-radius: 8px;
      //}
    }
    .ant-table-cell {
      font-size: 13px;
      font-weight: 600;
      color: #6f767e;
      background: #efefef;

      ::before {
        width: 0 !important;
      }
    }
  }
  .ant-table-tbody {
    > tr > td {
      border-bottom-color: #efefef;
      padding: 16px 8px;

      //:first-child {
      //  padding-left: 0;
      //}
      //:last-child {
      //  padding-right: 0;
      //}
    }
    .ant-table-cell {
      font-size: 14px;
      font-weight: 600;
      color: #1a1d1f;
    }

    .ant-table-placeholder {
      .ant-table-cell {
        border-bottom-color: transparent;
      }
    }
  }

  .ant-pagination {
    ${paginationStyles}
  }
`;

const AntdTable = StyledTable as <RecordType extends object = Any>(
  props: PropsWithChildren<TableProps<RecordType>>
) => ReactElement;

export { AntdTable };
