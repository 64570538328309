export const HeaderColors = {
  Blue: '#b1e5fc',
  DarkBlue: '#2A85FF',
  Green: '#B5E4CA',
  GreenDark: '#83BF6E',
  Orange: '#FFBC99',
  Purple: '#CABDFF',
  Red: {
    Light: '#FF1F0033',
    Mid: 'rgba(255, 106, 85, 1)',
    Dark: '#FF6A55',
  },
  Yellow: '#FFD88D',
  Black: '#111315',
} as const;

type ValueOf<T> = T[keyof T];
export type HeaderColorValues = ValueOf<typeof HeaderColors>;
