import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { HeaderColors } from 'shared/headerColors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { Filters } from './types';
import DueDateView from './current-view/DueDateView';
import LocationView from './current-view/LocationView';

interface CustomFilterProps {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  clearAllFilters: () => void;
  onPopoverClose: () => void;
}

const DropdownFilter: React.FC<CustomFilterProps> = ({
  filters,
  setFilters,
  clearAllFilters,
  onPopoverClose,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [currentView, setCurrentView] = useState<
    'initial' | 'dueDate' | 'locations'
  >('initial');

  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handlePopoverCloseInternal = () => {
    setIsPopoverOpen(false);
    onPopoverClose();
  };

  const noFilterSelected =
    !filters?.dueDate && filters?.locations?.length === 0;

  return (
    <Popover
      isOpen={isPopoverOpen}
      onOpen={togglePopover}
      onClose={handlePopoverCloseInternal}
      placement='bottom-end'
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Flex
          border='2px solid rgba(239, 239, 239, 1)'
          borderRadius='12px'
          p='8px 16px'
          color='rgba(111, 118, 126, 1)'
          align='center'
          gap='5px'
          fontWeight={600}
          cursor='pointer'
        >
          <Box w='max-content'>Select filters</Box>
          <ChevronDownIcon fontSize='20px' />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        width='388px'
        h={currentView === 'initial' ? '281px' : '688px'}
        borderRadius='12px'
      >
        <PopoverBody
          boxShadow='lg'
          p='31px'
          w='full'
          borderRadius='12px'
          h={currentView === 'initial' ? '281px' : '687px'}
        >
          <VStack align='flex-start' w='full' spacing={8}>
            <HStack justifyContent='space-between' alignItems='center' w='full'>
              <Flex>
                <GenericColorHeader
                  title='Filters'
                  color={HeaderColors.Purple}
                />
              </Flex>
              <Box
                as='span'
                color={
                  noFilterSelected
                    ? 'rgba(111, 118, 126, 1)'
                    : 'rgba(42, 133, 255, 1)'
                }
                onClick={() => {
                  if (noFilterSelected) {
                    return;
                  }
                  clearAllFilters();
                }}
                cursor={noFilterSelected ? 'no-drop' : 'pointer'}
              >
                Clear all
              </Box>
            </HStack>
            {currentView === 'initial' && (
              <VStack align='start' spacing={4} w='full'>
                <Box as='span' fontWeight={600}>
                  Filter by
                </Box>
                <Flex
                  w='full'
                  justify='space-between'
                  align='center'
                  onClick={() => setCurrentView('dueDate')}
                  color='rgba(51, 59, 79, 1)'
                  cursor='pointer'
                >
                  <Flex align='center' gap='10px'>
                    <FontAwesomeIcon icon={faCalendar as IconProp} />
                    <Box>Due Date</Box>
                  </Flex>
                  <ChevronRightIcon fontSize='20px' />
                </Flex>
                <Divider />
                <Flex
                  w='full'
                  justify='space-between'
                  align='center'
                  onClick={() => setCurrentView('locations')}
                  color='rgba(51, 59, 79, 1)'
                  cursor='pointer'
                >
                  <Flex align='center' gap='10px'>
                    <FontAwesomeIcon icon={faLocationDot as IconProp} />
                    <Box>Locations</Box>
                  </Flex>
                  <ChevronRightIcon fontSize='20px' />
                </Flex>
                <Divider />
              </VStack>
            )}

            {currentView === 'dueDate' && (
              <DueDateView
                filters={filters}
                setCurrentView={setCurrentView}
                setFilters={setFilters}
              />
            )}

            {currentView === 'locations' && (
              <LocationView
                filters={filters}
                setCurrentView={setCurrentView}
                setFilters={setFilters}
              />
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

DropdownFilter.displayName =
  'sub-components/audits/audit-tab/my-audits/components/DropdownFilter';

export default DropdownFilter;
