import React, { FC } from 'react';
import { Box, Flex, Image, AspectRatio } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';

import templateImage from '../../../resources/template-image.png';
import { AuditTemplateItem } from '../../query/audits-listing.graphql';

type SingleTemplateGridProps = {
  template: AuditTemplateItem;
  onPreview: () => void;
};

const SingleTemplateGrid: FC<SingleTemplateGridProps> = ({
  template,
  onPreview,
}) => {
  const { t } = useTranslation(['audit']);
  return (
    <Flex
      flexDir='column'
      padding='10px'
      borderRadius='12px'
      border='1px solid #EFEFEF'
      gap={2}
      cursor='pointer'
      _hover={{
        boxShadow: 'md',
      }}
      onClick={onPreview}
    >
      <AspectRatio ratio={5 / 3}>
        <Image src={template?.thumbnail || templateImage} borderRadius='12px' />
      </AspectRatio>

      <Flex flexDir='column' gap={1}>
        <Box fontWeight={700} fontSize='15px'>
          {template.title || 'Title not provided'}
        </Box>
        <Flex
          gap='7px'
          align='center'
          fontSize='12px'
          fontWeight={500}
          color='#6F767E'
        >
          <Box>{template?.category?.name || 'Not defined'}</Box>
          <Box borderRadius='full' boxSize='4px' bg='#6F767E' />
          <Flex gap='4px' align='center'>
            <FontAwesomeIcon
              fontSize='12px'
              icon={faArrowDownToBracket as IconProp}
            />
            <Box whiteSpace='nowrap'>
              <Trans
                t={t}
                i18nKey='audit:downloadCount'
                count={template.downloads}
                values={{
                  count: template.downloads,
                }}
              />
            </Box>
          </Flex>
        </Flex>
        <Box fontSize='12px' mt='6px' color='#6F767E' noOfLines={3}>
          {template?.description || 'Description not provided'}
        </Box>
      </Flex>
    </Flex>
  );
};

export default SingleTemplateGrid;
