import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { AuditTemplateItem } from '../../query/audits-listing.graphql';
import TemplateListViewShimmer from './TemplateListViewShimmer';
import SingleTemplateList from './SingleTemplateList';

interface TemplateListViewProps {
  isLoading?: boolean;
  allAuditTemplates?: AuditTemplateItem[];
  onPreview: (template: AuditTemplateItem) => void;
}

const TemplateListView: FC<TemplateListViewProps> = ({
  isLoading,
  allAuditTemplates,
  onPreview,
}) => {
  if (isLoading) {
    return (
      <Box>
        {Array.from({ length: 8 }).map((_, index) => {
          return <TemplateListViewShimmer key={index} />;
        })}
      </Box>
    );
  }

  return (
    <Box>
      {allAuditTemplates?.map((template) => {
        return (
          <SingleTemplateList
            key={template?.eid}
            template={template}
            onClick={() => onPreview(template)}
          />
        );
      })}
    </Box>
  );
};

export default TemplateListView;
