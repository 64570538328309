import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  bg: string;
  children: React.ReactNode;
}

const Outliner: FC<IProps> = ({ bg, children }) => {
  return (
    <Flex
      align='center'
      justify='space-between'
      bg={bg}
      h='60px'
      w='full'
      borderRadius='10px'
      p='22px'
    >
      {children}
    </Flex>
  );
};

Outliner.displayName =
  'sub-components/audits/audit-tab/supervised/components/GridView/components/Outliner';

export default Outliner;
