import React, { FC } from 'react';
import { AspectRatio, Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

const TemplateGridShimmerWrapper = styled.div`
  .skeleton {
    animation: load 2s ease-out 0s infinite normal;
    background-image: linear-gradient(to right, #f1f0f0, #c1c0c0, #f1f0f0);
    background-size: 200%;
    background-position: left;
    //background-color: #d9d9d9;
  }

  @keyframes load {
    0%,
    100% {
      background-position: right;
    }
    50% {
      background-position: left;
    }
  }
`;

const TemplateGridViewShimmer: FC = () => {
  return (
    <TemplateGridShimmerWrapper>
      <Flex
        gap={2}
        flexDir='column'
        padding='10px'
        borderRadius='12px'
        border='1px solid #EFEFEF'
      >
        <AspectRatio ratio={5 / 3}>
          <Box className='skeleton' borderRadius='12px' boxSize='full' />
        </AspectRatio>

        <Box
          className='skeleton'
          borderRadius='10px'
          my='6px'
          w='75%'
          h='11px'
        />
        <Box
          className='skeleton'
          borderRadius='10px'
          mt='1px'
          w='104px'
          h='7px'
        />
        <Box className='skeleton' borderRadius='10px' h='7px' />
        <Box className='skeleton' borderRadius='10px' h='7px' />
      </Flex>
    </TemplateGridShimmerWrapper>
  );
};

export default TemplateGridViewShimmer;
