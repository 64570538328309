import React, { FC } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { AuthRole, withAuthorization } from '../../../authorization';
import { useRouteHistory, AppRoute } from '../../../routes';
import { DashboardContainer } from 'sub-components';
import { IFormInput } from './audit-schedule.types';
import {
  AccessRules,
  Frequency,
  TemplateSelect,
  TitleSection,
} from './components';
import { useScheduleDefaultValues } from './useScheduleDefaultValues';
import { inputDataMapper } from './data-mapper';
import ScheduleHeader from './ScheduleHeader';
import { AUDIT_CREATE } from './audit-create.graphql';
import ScheduleNavigationWarning from './ScheduleNavigationWarning';

interface Params {
  templateId?: string;
}

const AuditSchedule: FC = () => {
  const { t } = useTranslation(['common', 'audit']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const params = useParams<Params>();
  const history = useHistory();

  const lastPage = useRouteHistory((state) => state.histories.at(-2));

  const defaultValues = useScheduleDefaultValues(params);

  const methods = useForm<IFormInput>({
    defaultValues: defaultValues,
  });

  const [creteAudit] = useMutation(AUDIT_CREATE, {
    onCompleted: async () => {
      methods.reset({}, { keepValues: true, keepDirty: false });
      await new Promise<void>((resolve) => setTimeout(resolve, 100));
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('audit:scheduleCreatedSuccess'),
      });
      if (lastPage?.pathname?.includes('/audits/template/')) {
        history.go(-2);
      } else {
        history.goBack();
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('audit:scheduleCreatedFailed'),
      });
    },
  });

  const onSubmit = async (value: IFormInput) => {
    try {
      methods.control._disableForm(true);
      await creteAudit({
        variables: {
          input: inputDataMapper(value),
        },
      });
      await new Promise<void>((resolve) => setTimeout(resolve, 1000));
    } catch (err) {
      return Promise.reject(err);
    } finally {
      methods.control._disableForm(false);
    }
  };

  return (
    <DashboardContainer>
      <FormProvider {...methods}>
        <ScheduleHeader onClick={methods.handleSubmit(onSubmit)} />
        <ScheduleNavigationWarning />
        <Flex justify='center' bg='white' padding='32px' mt='20px'>
          <Flex w='70%' flexDir='column' gap={4}>
            <TemplateSelect />
            <Frequency />
            <AccessRules />
            <TitleSection />
          </Flex>
        </Flex>
      </FormProvider>
    </DashboardContainer>
  );
};

export default withAuthorization({
  permittedFor: 'user',
  permittedRoles: [AuthRole.SUPER_ADMIN],
  redirectTo: AppRoute.AUDIT_LIST,
})(AuditSchedule);
