import React, { FC, useEffect } from 'react';
import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FlexCard, TabItem } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { useRouteState } from '../../../routes';

import AuditTabContainer, {
  LocationOwnerAuditContainer,
} from 'sub-components/audits/audit-tab';
import TemplateContainer from '../template/TemplateContainer';

const ListInternal: FC = () => {
  const { state, updateState, resetChildState } = useRouteState({
    tabIndex: 0,
  });

  useEffect(() => {
    const auditTab = sessionStorage.getItem('auditRootTab');
    if (auditTab && !isNaN(+auditTab)) {
      updateState({
        tabIndex: +auditTab,
      });
      sessionStorage.removeItem('auditRootTab');
    }
  }, []);

  const onChange = (index: number) => {
    resetChildState(index === 0 ? 'template' : 'audit');
    updateState({
      tabIndex: index,
    });
  };

  return (
    <Flex flex={1} direction='column' gap={5}>
      <Flex justify='space-between'>
        <Box fontSize='28px' fontWeight='700'>
          Audits
        </Box>
      </Flex>

      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        renderNoAccess={() => {
          return <LocationOwnerAuditContainer />;
        }}
      >
        <FlexCard flex={1} padding='24px 30px'>
          <Tabs w='full' isLazy index={state.tabIndex} onChange={onChange}>
            <TabList borderColor='#C7C7C7' borderBottomWidth='1px'>
              <TabItem>Audits</TabItem>
              <TabItem>Templates</TabItem>
            </TabList>
            <TabPanels>
              <TabPanel p={0} pt={5}>
                <AuditTabContainer />
              </TabPanel>
              <TabPanel p={0} pt={5}>
                <TemplateContainer />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </FlexCard>
      </Authorize>
    </Flex>
  );
};

ListInternal.displayName = 'pages/Audits/Listing/ListInternal';

export default ListInternal;
