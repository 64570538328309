import React, { FC, useMemo } from 'react';
import { Box, Flex, keyframes, Text, Tooltip } from '@chakra-ui/react';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../atoms';
import { formatNumber } from '../../../../utils';
import { headerConfig } from '../../common';

import { useMediaIsUploading } from './useMediaIsUploading';

import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';
import { ReactComponent as SyncIcon } from '../../../../assets/images/sync-sharp.svg';
import { SectionEntity } from '../types';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

interface IProps {
  title: string;
  onBackClick?: () => void;
  questionsCount?: number;
  sectionIndex: number;
}

const QuestionSectionHeader: FC<IProps> = ({
  title,
  onBackClick,
  questionsCount,
  sectionIndex,
}) => {
  const { t } = useTranslation('audit');
  const isSubmitting = useFormState().isSubmitting;

  const questions = useWatch<SectionEntity, 'data'>({
    name: 'data',
  });

  const completed = useMemo(() => {
    return questions?.filter((question) => question.isCompleted).length;
  }, [questions]);

  const flagCount = useMemo(() => {
    return questions?.filter((ques) => ques.isFlagged).length || 'NA';
  }, [questions]);

  const score = useMemo(() => {
    const _score = questions?.reduce(
      (acc, question) => {
        if (question.hasScoring) {
          acc.total += question.score!;
          acc.current += question.responseScore!;
        }

        return acc;
      },
      {
        total: 0,
        current: 0,
      }
    );
    if (!_score) {
      return 0;
    }

    return formatNumber((_score.current / _score.total) * 100 || 0);
  }, [questions]);

  const mediaUploading = useMediaIsUploading();

  return (
    <Flex flexDir='column' gap={2} {...headerConfig} shadow='unset' bg='unset'>
      <Tooltip
        placement='end'
        hasArrow
        label={t('uploadInProgressWarning')}
        offset={[0, 16]}
        isDisabled={!mediaUploading}
      >
        <Box alignSelf='start'>
          <Button
            fontSize='14px'
            fontWeight={500}
            width='max-content'
            variant='link'
            leftIcon={<BackIcon width='16' height='16' />}
            _hover={{
              textDecoration: 'none',
            }}
            onClick={onBackClick}
            isDisabled={mediaUploading}
          >
            Back to Audit Summary
          </Button>
        </Box>
      </Tooltip>

      <Flex align='center' justify='space-between' minH='40px'>
        <Flex align='end' gap={3}>
          <Text fontWeight={600} fontSize='28px'>
            {title}
          </Text>

          <Flex
            fontSize='12px'
            fontWeight={500}
            color='#6F767E'
            align='center'
            gap='8px'
          >
            <Box color='#1A1D1F' fontWeight={600}>
              {completed}/{questionsCount}
            </Box>
            <Box>Question</Box>

            {/*<Box boxSize={1} borderRadius='full' bg='#6F767E' />*/}
            {/*<Box color='#1A1D1F' fontWeight={600}>*/}
            {/*  0/45*/}
            {/*</Box>*/}
            {/*<Box>Points</Box>*/}

            <Box boxSize={1} borderRadius='full' bg='#6F767E' />
            <Box color='#1A1D1F' fontWeight={600}>
              {score}%
            </Box>
            <Box>Score</Box>

            <Box boxSize={1} borderRadius='full' bg='#6F767E' />
            <Box color='#1A1D1F' fontWeight={600}>
              {flagCount}
            </Box>
            <Box>Flag</Box>
          </Flex>
        </Flex>

        <Flex gap={3}>
          <Flex
            hidden={!mediaUploading || isSubmitting}
            align='center'
            gap='6px'
            fontSize='12px'
            color='#6F767E'
          >
            <Box animation={`${spin} 1s linear infinite`} boxSize={4}>
              <SyncIcon />
            </Box>
            Uploading assets
          </Flex>

          <Flex
            hidden={!isSubmitting}
            align='center'
            gap='6px'
            fontSize='12px'
            color='#6F767E'
          >
            <Box animation={`${spin} 1s linear infinite`} boxSize={4}>
              <SyncIcon />
            </Box>
            Syncing Changes
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(QuestionSectionHeader);
