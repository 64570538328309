// TableWrapper.tsx
import React, { FC } from 'react';
import { BaseTable } from '../../../../../../../atoms/Table';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { getImage } from '../../../../../../../utils';
import { QuestionResponseSectionEntity } from '../../types/audit-question.types';
import QuestionTypes from '../../../QuestionTypes/QuestionTypes';

// @ts-ignore
import { ReactComponent as FlagIcon } from '../../../../../../../assets/images/audits/FlagFilled.svg';

interface TableWrapperProps {
  tableData: QuestionResponseSectionEntity;
}

const TableWrapper: FC<TableWrapperProps> = ({ tableData }) => {
  return (
    <BaseTable
      bordered
      columns={[
        {
          title: 'Location',
          dataIndex: 'locationName',
          key: 'locationName',
          width: '25%',
          render: (value, record) => {
            return (
              <Flex
                w='fit-content'
                gap='5px'
                align='center'
                p='2px 8px'
                bg='rgba(181, 228, 202, 1)'
                borderRadius='6px'
                fontWeight='500'
              >
                <Center
                  bg='rgba(131, 191, 110, 1)'
                  borderRadius='4px'
                  boxSize='20px'
                  color='white'
                >
                  <FontAwesomeIcon icon={faLocationDot as IconProp} />
                </Center>
                <Box as='span'>{value}</Box>
                {record?.isFlagged && (
                  <FlagIcon width='16' height='16' color='#FF6A55' />
                )}
              </Flex>
            );
          },
        },
        {
          title: 'Location Owner',
          dataIndex: 'locationOwner',
          key: 'locationOwner',
          width: '25%',
          render: (value, record: any) =>
            record.isDateRange ? (
              { props: { colSpan: 0 } }
            ) : value?.eid ? (
              <Flex gap='5px'>
                <Image
                  src={getImage(value?.profilePic, value?.name)}
                  boxSize='20px'
                  borderRadius='50%'
                />
                <Box as='span'>{value?.name}</Box>
              </Flex>
            ) : (
              <Box as='span'>-</Box>
            ),
        },
        {
          title: 'Responses',
          dataIndex: 'response',
          key: 'response',
          width: '50%',
          render: (i, record) => {
            return (
              <QuestionTypes
                rawQuestion={record?.question}
                rawResponse={record?.rawResponse}
                responseStatus={record?.responseStatus}
              />
            );
          },
        },
      ]}
      rowKey='locationId'
      dataSource={tableData?.responsesByLocation}
      pagination={{
        pageSize: tableData?.responsesByLocation?.length || 10,
      }}
    />
  );
};

export default TableWrapper;
