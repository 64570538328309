import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useWatch } from 'react-hook-form';

import PreviewQuestionList from './PreviewQuestionList';
import { IFormInput } from '../form-types';

interface IProps {}

const AuditMobilePreview: FC<IProps> = () => {
  const title = useWatch<IFormInput, 'title'>({
    name: 'title',
  });
  return (
    <Flex
      width='300px'
      bg='gray.100'
      height='calc(100vh - 225px)'
      // height='calc(100vh - 291px)'
      borderRadius='16px'
      overflow='hidden'
      flexDir='column'
      boxShadow='0px 4px 20px 0px #0000001A'
    >
      <Flex
        align='center'
        gap={2}
        padding='16px 12px'
        bg='#FFFFFF'
        borderBottom='1px solid #EFEFEF'
      >
        <ArrowBackIcon color='#272B30' boxSize='22px' />
        <Flex as='span' fontSize='18px' fontWeight='600'>
          {title}
        </Flex>
      </Flex>
      <PreviewQuestionList />
    </Flex>
  );
};

export default AuditMobilePreview;
