import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';

import { SearchInput, SorterResult, Table, useChangeHandler } from 'atoms';
import { useSafeState, useUserDataSelector } from 'hooks';
import { AppRoute, useHistory } from 'routes';
import { toArray } from 'utils';

import {
  AuditTemplateItem,
  CLONE_TEMPLATE,
  DuplicateInput,
  GET_AUDIT_TEMPLATES,
  TEMPLATE_OPERATION,
  TemplateOperation,
  TemplateResponse,
  TemplateVariable,
} from '../query/audits-listing.graphql';
import { useMyTemplateColumns } from './useMyTemplateColumns';
import EmptyMyTemplates from './EmptyMyTemplates';
import { getTemplateSortBy } from '../common/sorting.helper';
import { useArchiveConfirm } from './useArchiveConfirm';
import BulkArchiveModal from './BulkArchiveModal';

interface IProps {}

const MyTemplates: FC<IProps> = () => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    duration: 3000,
  });
  const mounted = useRef(false);
  const entityId = useUserDataSelector((state) => state?.entityId);
  const [templateData, updateData] =
    useSafeState<TemplateResponse['AuditTemplatePagination']>();
  const [selectedRowKeys, setSelectedRowKeys] = useSafeState<React.Key[]>();
  const [sorter, setSorter] = useSafeState<SorterResult<AuditTemplateItem>>();
  const [currentPage, setCurrentPage] = useSafeState(1);
  const [searchQuery, setSearchQuery] = useSafeState('');

  const history = useHistory();

  const [auditTemplates, { loading }] = useLazyQuery<
    TemplateResponse,
    TemplateVariable
  >(GET_AUDIT_TEMPLATES, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => updateData(response.AuditTemplatePagination),
    onError: (error) => {
      console.log(error);
    },
  });

  const inputVariables = useMemo(() => {
    const inputs: TemplateVariable = {
      page: currentPage,
      perPage: 10,
      sort: getTemplateSortBy(sorter),
      filter: {
        status: 'active',
        entityId: entityId,
        templateType: 'business',
      },
    };

    if (searchQuery?.trim() && inputs['filter']) {
      inputs['filter']['query'] = searchQuery.trim();
    }

    return inputs;
  }, [searchQuery]);

  const [cloneTemplate] = useMutation<never, DuplicateInput>(CLONE_TEMPLATE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Template has been successfully cloned.',
      });
      auditTemplates({
        variables: inputVariables,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to clone template.',
      });
    },
  });

  const [archive] = useMutation<never, TemplateOperation>(TEMPLATE_OPERATION, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Template has been successfully archived.',
      });
      auditTemplates({
        variables: inputVariables,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Template Archiving Failed!',
      });
    },
  });

  const debouncedFetch = debounce(async (variables) => {
    auditTemplates({
      variables: variables,
    });
  }, 300);

  useEffect(() => {
    if (!mounted.current) {
      auditTemplates({
        variables: {
          page: 1,
          perPage: 10,
          sort: getTemplateSortBy(sorter),
          filter: {
            status: 'active',
            entityId: entityId,
            templateType: 'business',
          },
        },
      });
      setTimeout(() => {
        mounted.current = true;
      });
    }
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return undefined;
    }

    debouncedFetch(inputVariables);

    return () => debouncedFetch.cancel();
  }, [inputVariables]);

  const onChange = useChangeHandler<AuditTemplateItem>(
    (pagination, filters, _sorter, extra) => {
      switch (extra.action) {
        case 'paginate':
          setCurrentPage(pagination.current!);
          auditTemplates({
            variables: {
              ...inputVariables,
              page: pagination.current,
            },
          });
          break;
        case 'sort':
          setSorter(toArray(_sorter)[0]);
          auditTemplates({
            variables: {
              ...inputVariables,
              sort: getTemplateSortBy(_sorter),
            },
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(pagination, filters, _sorter, extra);
      }
    }
  );

  const onRowSection = (_selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(_selectedRowKeys);
  };

  const archiveConfirm = useArchiveConfirm();

  const actionHandler = (clickedItem: string, data: AuditTemplateItem) => {
    switch (clickedItem) {
      case 'edit':
        return history.push({
          pathname: AppRoute.EDIT_AUDIT_TEMPLATE,
          params: {
            templateId: data.eid,
          },
        });
      case 'schedule':
        return history.push({
          pathname: AppRoute.SCHEDULE_AUDIT,
          params: {
            templateId: data.eid,
          },
        });
      case 'duplicateTemplate':
        return cloneTemplate({
          variables: {
            eid: data.eid,
          },
        });
      case 'archiveTemplate':
        return archiveConfirm({
          onArchive: async () => {
            await archive({
              variables: {
                templateIds: toArray(data.eid),
                operation: 'archive',
              },
            });
          },
        });
      default:
        // eslint-disable-next-line no-console
        console.log(clickedItem, data);
    }
  };

  const columns = useMyTemplateColumns({
    actionHandler: actionHandler,
  });

  const onDeletePress = async () => {
    // Opening the archive template confirmation modal.
    return archiveConfirm({
      onArchive: async () => {
        await archive({
          variables: {
            templateIds: toArray(selectedRowKeys as string[]),
            operation: 'archive',
          },
        });
        // Resetting the selectedRowKeys after template is archived
        setTimeout(() => setSelectedRowKeys([]));
      },
    });
  };

  return (
    <Flex flexDir='column' gap={4}>
      <SearchInput
        placeholder='Search audit name'
        hideShortcuts
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Table
        rowKey='eid'
        isLoading={loading}
        columns={columns}
        dataSource={templateData?.items}
        onChange={onChange}
        searchActive={!!searchQuery}
        pagination={{
          pageSize: 10,
          total: templateData?.count,
        }}
        emptyState={<EmptyMyTemplates />}
        rowSelection={{
          selectedRowKeys,
          onChange: onRowSection,
        }}
      />

      <BulkArchiveModal
        selected={selectedRowKeys}
        onDeletePress={onDeletePress}
      />
    </Flex>
  );
};

export default MyTemplates;
