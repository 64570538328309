import React, { FC } from 'react';
import { Box, Center, Divider, Flex, Switch } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AlertBox } from 'atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'shared/headerColors';
import { Container } from '../shared';
import { IFormInput } from '../../audit-schedule.types';
import AuditorSelection from './AuditorSelection';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  title: string;
  caption: string;
}

export const AccessRuleSection: FC<SectionProps> = ({
  title,
  caption,
  children,
}) => {
  return (
    <Flex justify='space-between' align='center'>
      <Flex gap='10px'>
        <Center boxSize='32px'>
          <FontAwesomeIcon icon={faGlobe as IconProp} fontSize='22px' />
        </Center>
        <Flex flexDir='column'>
          <Box as='span' fontWeight={600} lineHeight='24px'>
            {title}
          </Box>
          <Box as='span' fontSize='12px' color='#6F767E'>
            {caption}
          </Box>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
};

const AccessRules: FC = () => {
  const { t } = useTranslation(['audit']);

  return (
    <Container>
      <BoxHeader
        title='Set access rules'
        fontSize='18px'
        boxWidth={3}
        color={HeaderColors.Purple}
      />

      <AccessRuleSection
        title={t('audit:generalAccess')}
        caption={t('audit:generalAccessDesc')}
      >
        <Box fontWeight={500} color='#6F767E' whiteSpace='nowrap'>
          View, Edit, Conduct
        </Box>
      </AccessRuleSection>

      <AccessRuleSection
        title={t('audit:accessAuditor')}
        caption={t('audit:accessAuditorDesc')}
      >
        <Box fontWeight={500} color='#6F767E' whiteSpace='nowrap'>
          View, Conduct
        </Box>
      </AccessRuleSection>

      <AuditorSelection />

      <Divider />

      <Controller<IFormInput, 'notifyLO'>
        name='notifyLO'
        render={({ field }) => (
          <>
            <AccessRuleSection
              title={t('audit:accessLocation')}
              caption={t('audit:accessLocationDesc')}
            >
              <Flex align='center' gap='5px'>
                <Box fontWeight={500} color='#111315' whiteSpace='nowrap'>
                  Notify LO
                </Box>
                <Switch isChecked={field.value} {...field} value={undefined} />
              </Flex>
            </AccessRuleSection>

            <AlertBox
              icon={faCircleExclamation}
              iconProps={{ color: '#000000' }}
            >
              {t(
                field.value
                  ? 'audit:accessRuleInfoActive'
                  : 'audit:accessRuleInfo'
              )}
            </AlertBox>
          </>
        )}
      />
    </Container>
  );
};

export default AccessRules;
