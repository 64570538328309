import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faRepeat } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Outliner from './Outliner';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import AuditAssignee from './AuditAssignee';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { ProcessData } from '../../../types';
import SectionFilter from './SectionFilter';
import { filterOptions } from './filter-options';
import { SelectOption } from 'atoms';
import AuditCard from './AuditCard';

interface IProps {
  data: ProcessData[];
  refetchHandler?: () => void;
}

const Upcoming: FC<IProps> = ({ data, refetchHandler }) => {
  const entityUsers = useReactiveVar(usersEntityObj);
  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | undefined
  >(undefined);

  const filteredUpcomingData = useMemo(() => {
    const upcomingData =
      data?.filter((upcoming) => upcoming?.auditStatus === 'upcoming') || [];
    if (!selectedFilter?.value) {
      return upcomingData;
    }
    let sortedData = [...upcomingData];
    switch (selectedFilter.value) {
      case 'dueDateFirst':
        sortedData.sort((a, b) => {
          const today = moment();

          const dueDateA = a.dueDate ? moment.utc(a.dueDate) : null;
          const dueDateB = b.dueDate ? moment.utc(b.dueDate) : null;

          const diffA = dueDateA ? Math.abs(dueDateA.diff(today)) : Infinity;
          const diffB = dueDateB ? Math.abs(dueDateB.diff(today)) : Infinity;

          return diffA - diffB;
        });
        break;
      case 'nameDesc':
        sortedData.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'nameAsc':
        sortedData.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'frequency':
        // Implement sorting by frequency if needed
        break;
      default:
        break;
    }

    return sortedData;
  }, [data, selectedFilter]);

  const handleFilterChange = (filter: SelectOption) => {
    setSelectedFilter((prevVal) =>
      prevVal?.value === filter.value ? undefined : filter
    );
  };

  const titleRender = (upcoming: ProcessData) => {
    return (
      <Tooltip label={upcoming?.title} hasArrow placement='top'>
        <Text fontWeight={600} isTruncated maxW='190px' cursor='pointer'>
          {upcoming?.title}
        </Text>
      </Tooltip>
    );
  };

  const scheduleRender = (upcoming: ProcessData) => {
    return (
      <Flex align='center' gap='3px' color='#4D4D4D' fontSize='10px'>
        <Flex gap='3px' align='flex-start'>
          <FontAwesomeIcon icon={faCalendar as IconProp} fontSize='14px' />
          <Box as='span'>
            {moment(upcoming?.dueDate)?.isValid()
              ? moment.utc(upcoming?.dueDate)?.format('MMM YY')
              : 'No Deadline'}
          </Box>
        </Flex>
        <Box>&bull;</Box>
        <FontAwesomeIcon icon={faRepeat as IconProp} />
        <Tooltip label={upcoming?.repeatStatus} hasArrow placement='top'>
          <Text textTransform='capitalize' isTruncated maxW='120px'>
            {upcoming?.repeatStatus || 'Does not repeat'}
          </Text>
        </Tooltip>
      </Flex>
    );
  };

  const footerRender = (upcoming: ProcessData) => {
    return (
      <Flex align='center' justify='space-between'>
        <Flex
          gap='5px'
          bg='rgba(202, 189, 255, 1)'
          borderRadius='6px'
          p='4px 8px'
        >
          <Center bg='rgba(142, 89, 255, 1)' boxSize='20px' borderRadius='4px'>
            <FontAwesomeIcon icon={faLocationDot as IconProp} color='white' />
          </Center>
          <Box as='span' fontWeight={500} fontSize='14px'>
            {upcoming?.assignments?.length}{' '}
            {upcoming?.assignments?.length === 1 ? 'location' : 'locations'}
          </Box>
        </Flex>
        <AuditAssignee
          assignees={entityUsers?.filter((user) =>
            upcoming?.auditors?.some((auditor) => auditor?.eid === user?.eid)
          )}
        />
      </Flex>
    );
  };

  return (
    <Flex flexDir='column' gap={4} minW='244px' maxW='244px'>
      <Outliner bg='rgba(202, 189, 255, 1)'>
        <Flex>
          <Box as='span' fontWeight={600} fontSize='14px'>
            Upcoming
          </Box>
          &nbsp;
          <Box as='span' fontWeight={600} fontSize='14px'>
            ({filteredUpcomingData?.length})
          </Box>
        </Flex>
        {filteredUpcomingData?.length > 0 && (
          <SectionFilter
            options={filterOptions}
            value={selectedFilter}
            handleOptionClick={handleFilterChange}
          />
        )}
      </Outliner>
      {filteredUpcomingData?.map((upcoming, index) => (
        <AuditCard
          key={upcoming?.eid}
          auditData={upcoming}
          auditTitle={titleRender(upcoming)}
          scheduleDetails={scheduleRender(upcoming)}
          footerDetails={footerRender(upcoming)}
          refetchHandler={refetchHandler}
        />
      ))}
    </Flex>
  );
};

export default Upcoming;
