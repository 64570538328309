import React, { FC, useEffect, useMemo } from 'react';
import { Center, CloseButton, useToast } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { NotAccessibleContainer } from 'ui-components';
import { Loader } from 'sub-components';
import { FormNotAccessible } from 'sub-components/forms';

import DashboardContainer from '../../../../../sub-components/DashboardContainer';
import { updateTaskProgress } from '../../services/MyTasks.service';
import FormRender from './sub-components/FormRender';
import ChapterRender from './sub-components/ChapterRender';
import ChapterNotAccessible from '../../../../Chapters/ChapterView/ChapterNotAccessible';
import { TaskItem } from '../../../../../sub-components/tasks/performance/details/task-details/task-details.graphql';
import { useTerminatedTaskModal } from 'pages/TasksDashboard/Components/sub-components/TerminatedTaskModal';
import {
  ICurrentUserProgressEntity,
  ITaskStatusEntity,
} from 'pages/TasksDashboard/tasks.types';
import {
  AmplitudeEventNames,
  CommonAmplitudeEvents,
  deployEvent,
} from 'shared/amplitudeEvents';

import { taskRepeat } from 'utils/taskRepeat';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';

interface TaskSessionData {
  eid: string;
  status: ITaskStatusEntity;
  taskId: string;
  taskItems: TaskItem[];
  lastActiveSession?: {
    currentUserProgress?: ICurrentUserProgressEntity;
  };
}

const TASK_PLAY_DATA = gql`
  query TaskPlayData($eid: String!) {
    TaskById(eid: $eid) {
      eid
      status
      taskItems
      lastActiveSession {
        currentUserProgress
      }
    }
  }
`;

interface Params {
  taskId: string;
  id: string;
  sessionId: string;
  type: string;
}

const MyTaskPlay: FC = () => {
  const history = useHistory();
  const toast = useToast();
  const terminatedTaskModal = useTerminatedTaskModal();

  const { taskId, id, sessionId, type } = useParams<Params>();

  const { data, loading } = useQuery<Record<'TaskById', TaskSessionData>>(
    TASK_PLAY_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: taskId,
      },
    }
  );

  const goBackHandler = () => {
    history.push('/tasks/my-tasks');
  };

  useEffect(() => {
    if (data?.TaskById?.status === 'TASK_TERMINATED') {
      terminatedTaskModal({
        btnTxt: 'Go back',
        callback: goBackHandler,
      });
    }
  }, [data]);

  const {
    execute: updateTaskProgressFn,
    loading: updatingProgress,
    data: updatingProgressData,
    error: updateProgressError,
  } = updateTaskProgress();

  const taskItem = useMemo(() => {
    if (Array.isArray(data?.TaskById?.taskItems)) {
      return data!.TaskById.taskItems.find((it) => it.eid === id);
    }
    return undefined;
  }, [data, id]);

  const updateProgressHandler = async () => {
    await updateTaskProgressFn({
      variables: {
        input: {
          contentId: id,
          sessionId: sessionId,
        },
      },
    });
  };

  useEffect(() => {
    if (
      updatingProgressData &&
      updatingProgressData?.UpdateUserTaskProgress &&
      updatingProgressData?.UpdateUserTaskProgress?.eid
    ) {
      if (
        updatingProgressData?.UpdateUserTaskProgress?.currentUserProgress
          ?.completedPercent === 100
      )
        deployEvent(AmplitudeEventNames.TASK_COMPLETE, {
          task_id: taskId,
          task_completion_funnel_id: 2,
          total_tasks:
            updatingProgressData?.UpdateUserTaskProgress?.taskItems?.length ||
            0,
          total_forms:
            updatingProgressData?.UpdateUserTaskProgress?.taskItems?.filter(
              (item) => item?.type === 'form'
            )?.length || 0,
          total_chapters:
            updatingProgressData?.UpdateUserTaskProgress?.taskItems?.filter(
              (item) => item?.type === 'sop'
            )?.length || 0,
          total_to_do_items:
            updatingProgressData?.UpdateUserTaskProgress?.taskItems?.filter(
              (item) => item?.type === 'todo'
            )?.length || 0,
          due_date: updatingProgressData?.UpdateUserTaskProgress?.dueDate || '',
          days_till_due_date: '',
          repeat: taskRepeat(
            updatingProgressData?.UpdateUserTaskProgress?.task
              ?.repeatDetails as RepeatDetails<Date>
          ),
          total_tasks_completed:
            updatingProgressData?.UpdateUserTaskProgress?.taskItems?.length ||
            0,
        });
      goBack();
    }
  }, [updatingProgressData]);

  const goBack = () => {
    !updatingProgress &&
      history.push(`/tasks/my-tasks/details/${taskId}/${sessionId}`);
  };

  const dataRenderHandler = () => {
    if (type === 'sop' && taskItem) {
      // if (!(taskItem.isMandatory || taskItem.isAccessible)) {
      //   return (
      //     <NotAccessibleContainer>
      //       <CloseButton ml='auto' onClick={history.goBack} />
      //       <ChapterNotAccessible type='UNAUTHORISED' />
      //     </NotAccessibleContainer>
      //   );
      // }

      return (
        <ChapterRender
          data={taskItem}
          taskId={taskId}
          sessionId={sessionId}
          updatingProgress={updatingProgress}
          goBack={goBack}
          updateProgressHandler={() => {
            deployEvent(AmplitudeEventNames.TASK_STEP_COMPLETE, {
              task_completion_funnel_id: 2,
              task_id: taskId,
              task_type: 'sop',
              total_steps: data?.TaskById?.taskItems?.length || 0,
              percent_completion: +(
                data?.TaskById?.lastActiveSession?.currentUserProgress
                  ?.completedPercent || 0
              )?.toFixed(0),
              steps_completed:
                (
                  data?.TaskById?.lastActiveSession?.currentUserProgress
                    ?.completedTasks || []
                )?.length || 0,
            });
            updateProgressHandler();
          }}
        />
      );
    } else if (type === 'form' && taskItem) {
      if (!(taskItem.isMandatory || taskItem.isAccessible)) {
        return (
          <NotAccessibleContainer>
            <CloseButton ml='auto' onClick={history.goBack} />
            <FormNotAccessible type='UNAUTHORISED' />
          </NotAccessibleContainer>
        );
      }

      return (
        <FormRender
          formData={taskItem}
          sessionId={sessionId}
          taskId={taskId}
          updateProgressHandler={() => {
            deployEvent(AmplitudeEventNames.TASK_STEP_COMPLETE, {
              task_completion_funnel_id: 2,
              task_id: taskId,
              task_type: 'form',
              total_steps: data?.TaskById?.taskItems?.length || 0,
              percent_completion: +(
                data?.TaskById?.lastActiveSession?.currentUserProgress
                  ?.completedPercent || 0
              )?.toFixed(0),
              steps_completed:
                (
                  data?.TaskById?.lastActiveSession?.currentUserProgress
                    ?.completedTasks || []
                )?.length || 0,
            });
            updateProgressHandler();
          }}
          event={CommonAmplitudeEvents.SUBMIT_FORM_RESPONSE}
        />
      );
    }
  };

  const showErrorMessage = () => {
    toast({
      status: 'error',
      title: updateProgressError?.message,
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  };

  return (
    <DashboardContainer>
      {updateProgressError?.message && showErrorMessage()}
      {!loading ? (
        dataRenderHandler()
      ) : (
        <Center h='80vh'>
          <Loader size='xl' />
        </Center>
      )}
    </DashboardContainer>
  );
};

MyTaskPlay.displayName = 'pages/TasksDashboard/MyTasks/component/MyTaskPlay';

export default MyTaskPlay;
