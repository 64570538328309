import { Flex, Text, Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import {
  AssignmentType,
  AuditorSectionResponseType,
} from './types/report.types';
import { FC } from 'react';
import { formatDateTime } from '../listing/helper';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Signature from './Signature';

type AuditorSignatureProps = {
  response: AuditorSectionResponseType;
  type?: string;
  additionalData: any;
  assignment: AssignmentType;
};

const AuditorSignature: FC<AuditorSignatureProps> = ({
  response,
  type = 'web',
  additionalData,
}) => {
  return (
    <Flex
      flexDir={'column'}
      fontWeight={500}
      padding={'0px 1.5rem'}
      marginTop={'10px'}
      paddingBottom={'1.5rem'}
    >
      <Box>
        <Text
          mt={'34px'}
          lineHeight={'32px !important'}
          fontWeight={600}
          fontSize={'20px'}
        >
          Closing comments
        </Text>
        <Flex
          padding={'12px'}
          // justifyContent={'center'}
          mt={'10px'}
          backgroundColor={
            response?.responses?.[0]?.closingComment !== '--' ? '#E9F8FF' : null
          }
        >
          <Text
            whiteSpace={'pre-line'}
            lineHeight={'32px !important'}
            fontWeight={500}
            fontSize={'18px'}
          >
            {response?.responses?.[0]?.closingComment}
          </Text>
        </Flex>
      </Box>
      {type === 'web' ? (
        <>
          <Text
            mt={'45px'}
            lineHeight={'48px !important'}
            fontWeight={600}
            fontSize={'20px'}
          >
            Auditor Signature
          </Text>
          {response?.responses?.[0]?.auditorSign?.signUrl?.text ? (
            <Signature
              view={'web'}
              value={response?.responses?.[0]?.auditorSign?.signUrl}
            />
          ) : (
            '--'
          )}

          <Text
            mt={'14px'}
            lineHeight={'24px !important'}
            fontWeight={500}
            fontSize={'15px'}
            color={'#808080'}
          >
            {response?.responses?.[0]?.auditorSign?.name
              ? response?.responses?.[0]?.auditorSign?.name
              : '--'}
          </Text>
          <Text
            mt={'14px'}
            lineHeight={'24px !important'}
            fontWeight={500}
            fontSize={'15px'}
            color={'#808080'}
          >
            {`${
              response?.responses?.[0]?.auditorSign?.dateTime
                ? formatDateTime(
                    response?.responses?.[0]?.auditorSign?.dateTime,
                    additionalData?.timeZone
                  ).dateString
                : '--'
            } ${
              response?.responses?.[0]?.auditorSign?.dateTime
                ? formatDateTime(
                    response?.responses?.[0]?.auditorSign?.dateTime,
                    additionalData?.timeZone
                  ).timeString
                : '--'
            }`}
          </Text>
        </>
      ) : (
        <>
          <Flex marginTop={'2rem'} gap={3} alignItems={'flex-start'}>
            <FontAwesomeIcon
              color='black'
              fontSize={'1.5rem'}
              icon={true ? (faSquareCheck as IconProp) : (faSquare as IconProp)}
            />
            <Text fontWeight={600} fontSize={'18px'}>
              {`I have completed this assessment to the best of my knowledge.  This audit is not intended to identify all safety and health hazards, but to identify all visible/known hazards as of the date printed on the completed assessment.`}
            </Text>
          </Flex>
          <Flex alignItems={'flex-end'} flexDir={'column'}>
            <Text
              mt={'45px'}
              lineHeight={'48px !important'}
              fontWeight={600}
              fontSize={'20px'}
            >
              Auditor Signature
            </Text>
            <Flex>
              {response?.responses?.[0]?.auditorSign?.signUrl?.text ? (
                <Signature
                  view={'pdf'}
                  value={response?.responses?.[0]?.auditorSign?.signUrl}
                />
              ) : (
                '--'
              )}
            </Flex>
            <Text
              mt={'14px'}
              lineHeight={'24px !important'}
              fontWeight={500}
              fontSize={'15px'}
              color={'#808080'}
            >
              {response?.responses?.[0]?.auditorSign?.name
                ? response?.responses?.[0]?.auditorSign?.name
                : '--'}
            </Text>
            <Text
              mt={'14px'}
              lineHeight={'24px !important'}
              fontWeight={500}
              fontSize={'15px'}
              color={'#808080'}
            >
              {`${
                response?.responses?.[0]?.auditorSign?.dateTime
                  ? formatDateTime(
                      response?.responses?.[0]?.auditorSign?.dateTime,
                      additionalData?.timeZone
                    ).dateString
                  : '--'
              } ${
                response?.responses?.[0]?.auditorSign?.dateTime
                  ? formatDateTime(
                      response?.responses?.[0]?.auditorSign?.dateTime,
                      additionalData?.timeZone
                    ).timeString
                  : '--'
              }`}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default AuditorSignature;
