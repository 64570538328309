import { Box, Center, Divider, Flex, VStack } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { normalTasksDataFormatter } from './helper';
import {
  CustomLauncherTask,
  LocationDetails,
  LocLaunchTaskList,
} from './types';
import GoLiveRocket from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Horizontal/components/GoLiveRocket';
import ProjectStartSection from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Horizontal/components/ProjectStartSection';
import TotalTasksDone from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/TotalTasksDone';
import { ListItem, LocationFilter } from './sub-components';
import Filters from './sub-components/Filters';
import { EmptyState } from 'sub-components';
import { LauncherLocationDetailTask } from 'pages/launcher/details/types/location-launch-details.types';
import { SelectOption } from 'atoms';
import { cloneDeep } from '@apollo/client/utilities';

interface IProps {
  _data: LocLaunchTaskList | undefined;
  locDetails: LocationDetails | undefined;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  setSelectedTaskData: (
    value: React.SetStateAction<CustomLauncherTask | undefined>
  ) => void;
}

const ListRender: FC<IProps> = ({
  _data,
  locDetails,
  setIsOpen,
  setSelectedTaskData,
}) => {
  const loggedInUserTimezone = useUserDataSelector((state) => state.timezone);

  const [selectedFilter, setSelectedFilter] = useState('active');
  const [locationFilterOptions, setLocationFilterOptions] = useState<
    SelectOption<string>[]
  >([
    {
      label: 'View all phases',
      value: 'viewPhase',
      selected: true,
    },
  ]);

  const data = useMemo(() => {
    return (
      _data?.LocationLaunchTasks?.filter((task) => task?.isPhaseSelected) || []
    );
  }, [_data]);

  const uniqueCategories: SelectOption<string>[] = useMemo(() => {
    if (!data) return [];

    const uniqueCategoryMap = new Map();

    data.forEach((item) => {
      if (!uniqueCategoryMap.has(item.categoryId)) {
        uniqueCategoryMap.set(item.categoryId, item);
      }
    });

    return Array.from(uniqueCategoryMap.values());
  }, [data]);

  useEffect(() => {
    const currentSelectedOption = locationFilterOptions.find(
      (option) => option.selected
    );

    let _options: SelectOption<string>[] = [
      {
        label: 'View all phases',
        value: 'viewPhase',
        selected: currentSelectedOption?.value === 'viewPhase',
      },
    ];

    uniqueCategories?.forEach((unique) => {
      _options.push({
        label: unique?.category,
        value: unique?.categoryId,
        selected: currentSelectedOption?.value === unique?.categoryId,
      });
    });
    setLocationFilterOptions(_options);
  }, [uniqueCategories]);

  const completedTasks = useMemo(() => {
    return data?.filter((task) => task?.completedAt)?.length || 0;
  }, [data]);

  const totalTasks = useMemo(() => {
    return data?.length || 0;
  }, [data]);

  let customData = useMemo(() => {
    if (selectedFilter === 'active') {
      let selectedLocationFilter = locationFilterOptions?.filter(
        (op) => op?.selected
      )?.[0];
      if (selectedLocationFilter?.value === 'viewPhase') {
        return normalTasksDataFormatter(
          data as CustomLauncherTask[],
          loggedInUserTimezone
        )?.filter((task) => !task?.completedAt);
      }
      return normalTasksDataFormatter(
        data as CustomLauncherTask[],
        loggedInUserTimezone
      )?.filter(
        (task) =>
          !task?.completedAt &&
          task?.categoryId === selectedLocationFilter?.value
      );
    } else {
      let selectedLocationFilter = locationFilterOptions?.filter(
        (op) => op?.selected
      )?.[0];
      if (selectedLocationFilter?.value === 'viewPhase') {
        return normalTasksDataFormatter(
          data as CustomLauncherTask[],
          loggedInUserTimezone
        )?.filter((task) => task?.completedAt);
      }
      return normalTasksDataFormatter(
        data as CustomLauncherTask[],
        loggedInUserTimezone
      )?.filter(
        (task) =>
          task?.completedAt &&
          task?.categoryId === selectedLocationFilter?.value
      );
    }
  }, [data, selectedFilter, locationFilterOptions]);

  const completedLabel = useMemo(() => {
    return `Completed tasks (${completedTasks})`;
  }, [completedTasks]);

  return (
    <Flex flexDir='column' gap={4} w='full'>
      <Flex align='center' justify='space-between' w='69%'>
        <Filters
          filterOptions={[
            { label: 'Active tasks', value: 'active' },
            { label: completedLabel, value: 'completed' },
          ]}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
        <LocationFilter
          options={locationFilterOptions}
          onChange={(option) => {
            console.log({ option });
            let _locationFilterOptions = cloneDeep(locationFilterOptions);
            _locationFilterOptions?.map((op) => {
              if (op?.value === option?.value) {
                op.selected = true;
              } else {
                op.selected = false;
              }
            });
            setLocationFilterOptions(_locationFilterOptions);
          }}
        />
      </Flex>
      <Flex w='full'>
        <Flex flexDir='column' gap={4} w='70%' mr={4}>
          {customData?.length > 0 ? (
            customData?.map((task) => {
              return (
                <ListItem
                  key={task?.eid}
                  task={task}
                  setIsOpen={setIsOpen}
                  setSelectedTaskData={setSelectedTaskData}
                />
              );
            })
          ) : (
            <Center bg='white' h='50vh' borderRadius='8px'>
              <EmptyState image='MyTask' title='No task found' />
            </Center>
          )}
        </Flex>
        {/* <Flex bg='white' flexDir='column' gap={2} h='fit-content' w='30%'>
          <Flex p={4}>
            <TotalTasksDone
              tasksCompletedCount={completedTasks}
              tasksTotalCount={totalTasks}
            />
          </Flex>
          <Divider />
          <Flex p={4}>
            <GoLiveRocket
              liveDate={locDetails?.liveDate!}
              tasks={
                locDetails?.tasks as unknown as LauncherLocationDetailTask[]
              }
            />
          </Flex>
          <Divider />
          <ProjectStartSection startDate={locDetails?.startDate} />
          <Divider />
        </Flex> */}
        <VStack
          bg='white'
          h='fit-content'
          w='30%'
          align='flex-start'
          divider={<Divider />}
        >
          <Flex p={4} w='full'>
            <TotalTasksDone
              tasksCompletedCount={completedTasks}
              tasksTotalCount={totalTasks}
            />
          </Flex>
          <Flex p={4} w='full'>
            <GoLiveRocket
              liveDate={locDetails?.liveDate!}
              tasks={
                locDetails?.tasks as unknown as LauncherLocationDetailTask[]
              }
            />
          </Flex>
          <ProjectStartSection startDate={locDetails?.startDate} />
        </VStack>
      </Flex>
    </Flex>
  );
};

export default ListRender;
