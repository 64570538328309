import React, { FC } from 'react';
import { Box, Flex, Divider } from '@chakra-ui/react';
import styled from '@emotion/styled';

const TemplateListShimmerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 49px;
  .skeleton {
    animation: load 2s ease-out 0s infinite normal;
    background-image: linear-gradient(to right, #f1f0f0, #c1c0c0, #f1f0f0);
    background-size: 200%;
    background-position: left;
    //background-color: #d9d9d9;
  }

  @keyframes load {
    0%,
    100% {
      background-position: right;
    }
    50% {
      background-position: left;
    }
  }
`;

const TemplateListViewShimmer: FC = () => {
  return (
    <React.Fragment>
      <TemplateListShimmerWrapper>
        <Flex align='center' gap='16px' py='1px'>
          <Box className='skeleton' borderRadius='10px' w='350px' h='14px' />
          <Box className='skeleton' borderRadius='4px' w='110px' h='24px' />
        </Flex>
        <Box
          className='skeleton'
          mt='8px'
          borderRadius='10px'
          w='180px'
          h='9px'
        />
      </TemplateListShimmerWrapper>
      <Divider marginY={4} />
    </React.Fragment>
  );
};

export default TemplateListViewShimmer;
