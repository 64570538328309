import { Box, Text, Flex } from '@chakra-ui/react';
import { AuditResponse, Heading, PdfOverview } from './components';
import { generateValidId } from './helper';
import MediaSummary from './MediaSummary';
import AuditorSignature from './AuditorSignature';
import { useUserEntity } from 'hooks';
import {
  AssignmentType,
  AuditSessionAssignments,
  SectionDetailsResponseType,
  SessionDetailEntity,
} from './types/report.types';
import { FC } from 'react';

interface IProps {
  auditData: SessionDetailEntity;
  assignment: AssignmentType;
  additionalData: any;
  mappedQuestionData: any;
  companyData?: any;
  entityUsers?: any;
  setCheckIfMembersLoaded?: any;
}

const ReportPdfView: FC<IProps> = ({
  auditData,
  assignment,
  additionalData,
  mappedQuestionData,
  companyData,
  entityUsers,
  setCheckIfMembersLoaded,
}) => {
  if (!companyData) {
    companyData = useUserEntity((state) => {
      return {
        name: state?.name,
        profilePic: state?.profilePic,
        address: state?.address,
      };
    });
  }

  let totalObtainedScore = 0;
  mappedQuestionData?.forEach((item: any) => {
    if (item?.obtainedScore) {
      totalObtainedScore += item?.obtainedScore;
    }
  });

  const pdfData = {
    profilePic: companyData?.profilePic,
    companyName: companyData?.name,
    companyAddress: companyData?.address,
    auditDate: assignment?.completedAt,
    address: {
      city: additionalData?.address?.city,
      state: additionalData?.address?.state,
      zipCode: additionalData?.address?.zipCode,
      country: additionalData?.address?.country,
    },
    reportTitle: auditData?.title,
    reportNumber: assignment?.assignmentId ? assignment?.assignmentId : '--',
    reportComment: '',
    flaggedItemCount: additionalData?.cardInfo?.flaggedItemCount?.count,
    auditorName: additionalData?.moreInfo?.conductedBy?.userDetails?.[0]?.name
      ? additionalData?.moreInfo?.conductedBy?.userDetails?.[0]?.name
      : '--',
    overAllScore: additionalData?.cardInfo?.score,
    totalObtainedScore: totalObtainedScore,
  };

  return (
    <Box mt={'20px'} id='report-pdf-view'>
      <PdfOverview
        pdfData={pdfData}
        assignment={assignment}
        additionalData={additionalData}
      />
      {/* Render all the other sections */}
      <Box paddingBottom={'1rem'}>
        {mappedQuestionData[0].hasOwnProperty('title') &&
          Object.values(mappedQuestionData)?.map((item: any, index) => {
            return item?.title === 'Media Summary' ? (
              <Box key={index}>
                {item?.responses?.length > 0 && (
                  <>
                    <Heading title={item?.title} type='pdf' />
                    <MediaSummary response={item?.responses} />
                  </>
                )}
              </Box>
            ) : item?.title === 'Auditor Section' ? (
              <Box key={index}>
                <Heading title={item?.title} type='pdf' />
                <AuditorSignature
                  response={item}
                  type='pdf'
                  additionalData={additionalData}
                />
              </Box>
            ) : (
              <Box key={index} id={`${generateValidId(item?.title)}`}>
                {item?.title && (
                  <Heading
                    title={item?.title}
                    type='pdf'
                    headingTextDesc={`${
                      item?.flaggedResponseCount
                        ? item?.flaggedResponseCount <= 1
                          ? item?.flaggedResponseCount + ' flag'
                          : item?.flaggedResponseCount + ' flags'
                        : 0 + ' flag'
                    } ${
                      item?.title !== 'Flagged Items' ? ', ' + item?.score : ''
                    }`}
                  />
                )}

                {item?.title === 'Flagged Items' &&
                item?.responses?.length <= 0 ? (
                  <Flex
                    justifyContent={'center'}
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    <Text
                      lineHeight={'48px !important'}
                      fontWeight={500}
                      fontSize={'20px'}
                    >
                      No Flagged Responses
                    </Text>
                  </Flex>
                ) : (
                  item?.responses?.map((response, index) => {
                    return (
                      <AuditResponse
                        key={index}
                        entityUsers={entityUsers}
                        setCheckIfMembersLoaded={setCheckIfMembersLoaded}
                        index={index}
                        section={item?.title}
                        {...response}
                      />
                    );
                  })
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default ReportPdfView;
