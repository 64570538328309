import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { Button } from 'atoms';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import './style.css';

const DateRangePicker: FC<{
  selectedRange: DateRange | undefined;
  updateDateRange: (range: DateRange | undefined) => void;
  onPopoverClose: (range: DateRange | undefined) => void;
}> = ({ selectedRange, updateDateRange, onPopoverClose }) => {
  const [internalRange, setInternalRange] = useState(selectedRange);
  const previousMonth = moment().subtract(1, 'month').toDate();

  const handleSelect = (range: DateRange | undefined) => {
    setInternalRange(range);
  };

  const handleClear = () => {
    setInternalRange({ from: undefined, to: undefined });
    updateDateRange({ from: undefined, to: undefined });
  };

  return (
    <Popover
      placement='bottom-start'
      onClose={() => onPopoverClose(internalRange)}
    >
      <PopoverTrigger>
        <Flex
          border='2px solid rgba(239, 239, 239, 1)'
          borderRadius='12px'
          p='8px 20px'
          align='center'
          gap='5px'
          justify='space-between'
          cursor='pointer'
          color='rgba(111, 118, 126, 1)'
          minW='190px'
        >
          <Box fontWeight={600}>Select date range</Box>
          <ChevronDownIcon fontSize='20px' />
        </Flex>
      </PopoverTrigger>
      <PopoverContent w='fit-content'>
        <PopoverBody boxShadow='lg'>
          <Box>
            <DayPicker
              mode='range'
              numberOfMonths={2}
              selected={internalRange}
              defaultMonth={previousMonth}
              // disabled={[
              //   { before: new Date() },
              //   { after: new Date(2024, 10, 25) },
              // ]}
              onSelect={handleSelect}
              footer={
                <Flex w='full' justify='flex-end'>
                  <Button variant='outline' onClick={handleClear}>
                    Clear
                  </Button>
                </Flex>
              }
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

DateRangePicker.displayName =
  'sub-components/audits/range-date-picker/RangeDatePicker';

export default DateRangePicker;
