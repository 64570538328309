import React, { FC, useMemo, useState } from 'react';
import { Box, HStack, Flex, Button, Text } from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';
import moment from 'moment/moment';

import GenericColorHeader from '../../../sub-components/GenericColorHeader';
import { HeaderColors } from '../../../shared/headerColors';
import CommonLabel from '../../../sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import LaunchGraph, {
  GraphData,
} from '../../../sub-components/LocationLaunchDashboard/LaunchGraph';
import CommonContainer from '../../../ui-components/CommonContainer';
import { LauncherLocation } from './dashboard.graphql';
import usePaginateData from '../../../hooks/usePaginateData';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';

interface IProps {
  locations?: LauncherLocation[];
  isLoading?: boolean;
}

const formatNumber = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
}).format;

const getPercentage = (total: number, value: number): string => {
  return formatNumber((value / total) * 100 || 0);
};

const getDueTasks = (tasks: LauncherLocation['tasks']) => {
  return tasks.filter((it) => {
    return !it.completedAt && moment(it.dueDate).isBefore(undefined, 'minute');
  });
};

const LocationLaunchGraph: FC<IProps> = ({ locations, isLoading }) => {
  const [pageIndex, setPageIndex] = useState(0);

  const graphData = useMemo(() => {
    return cloneDeep(locations || []).reduce<GraphData[]>((acc, loc) => {
      if (loc.tasks?.length) {
        const total = loc.tasks?.length;

        const dueTasks = getDueTasks(loc.tasks).length;
        const activeTasks = loc.tasks.filter((it) => !it.isCompleted).length;
        const comp = loc.tasks.filter((it) => it.isCompleted).length;

        acc.push({
          name: loc.location?.name,
          total: total,
          active: getPercentage(total, activeTasks - dueTasks),
          completed: getPercentage(total, comp),
          dueTask: getPercentage(total, dueTasks),
        });
      }

      return acc;
    }, []);
  }, [locations]);

  let paginatedGraphData = usePaginateData(graphData, 15);

  const nextPage = () => {
    setPageIndex((oldPage) => {
      let nextPage = oldPage + 1;
      if (nextPage > paginatedGraphData.length - 1) {
        nextPage = paginatedGraphData.length - 1;
      }
      return nextPage;
    });
  };

  const prevPage = () => {
    setPageIndex((oldPage) => {
      let prevPage = oldPage - 1;
      if (prevPage < 0) {
        prevPage = paginatedGraphData.length - 1;
      }
      return prevPage;
    });
  };

  return (
    <CommonContainer
      display='flex'
      flexDir='column'
      gap={4}
      bg='white'
      borderRadius='6px'
      p='20px'
    >
      <GenericColorHeader
        title={`${graphData.length || ''} ${
          graphData.length > 1 ? 'Locations' : 'Location'
        } launch progress`.trim()}
        color={HeaderColors.Green}
      />
      <HStack gap={4}>
        <HStack>
          <Box boxSize='7px' borderRadius='50%' bg='#83BF6E' />
          <CommonLabel
            label='Completed tasks'
            fontSize='14px'
            color='#2E2C34'
          />
        </HStack>
        <HStack>
          <Box boxSize='7px' borderRadius='50%' bg='#2A85FF' />
          <CommonLabel label='Active tasks' fontSize='14px' color='#2E2C34' />
        </HStack>
        <HStack>
          <Box boxSize='7px' borderRadius='50%' bg='#FF6A55' />
          <CommonLabel label='Overdue tasks' fontSize='14px' color='#2E2C34' />
        </HStack>
      </HStack>
      <LaunchGraph
        isLoading={isLoading}
        graphData={paginatedGraphData[pageIndex]}
        height={350}
      />
      {graphData.length > 15 && (
        <Box marginLeft='5.5rem' marginRight='3.5rem'>
          <Flex justifyContent='space-between' alignItems='center'>
            <Button disabled={pageIndex === 0} onClick={prevPage}>
              <ArrowBackIcon />
            </Button>
            <Text>Locations</Text>
            <Button
              disabled={pageIndex === paginatedGraphData.length - 1}
              onClick={nextPage}
            >
              <ArrowForwardIcon />
            </Button>
          </Flex>
        </Box>
      )}
    </CommonContainer>
  );
};

export default LocationLaunchGraph;
