import React, { FC } from 'react';
import { Authorize, AuthRole } from 'authorization';
import AuditHomeAdmin from './AuditHomeAdmin';
import AuditHomeLocation from './AuditHomeLocation';

const AuditsHome: FC = () => {
  return (
    <Authorize
      permittedFor='user'
      permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      renderNoAccess={() => <AuditHomeLocation />}
    >
      <AuditHomeAdmin />
    </Authorize>
  );
};

export default AuditsHome;
