import React, { FC } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';
import { useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useTranslation } from 'react-i18next';

import { LocationFieldEntity, userObj } from 'sop-commons/src/client';

import Dropdown from '../../../atoms/Dropdown';
import { ActionButton } from '../../../ui-components';

import {
  ILocationFieldEntity,
  mapToInput,
  optionsData,
  UPDATE_LOCATION_FIELD,
  UpdateFieldVariable,
} from './location-field.graphql';
import OtherFields from './OtherFields';
import { IFormInput } from './location-setting.types';
import { toArray } from '../../../utils/utils';

const formatData = (values: IFormInput): ILocationFieldEntity => {
  const data: ILocationFieldEntity = {
    fieldType: values.fieldType?.value!,
    fieldName: values.fieldName,
    options: toArray(values.options)
      .map((value) => value.name)
      .filter(Boolean),
    validations: values.validations as never,
    createdBy: values.eid ? values.createdBy : userObj().eid,
    visibleToLocations: values.visibleToLocations,
    oldLocationSupport: values.oldLocationSupport,
  };

  if (values.eid) {
    data.eid = values.eid;
  }

  return data;
};

interface IProps {
  locationField?: LocationFieldEntity;
  onFieldAdded?: () => void;
}

const defaultValue: Partial<IFormInput> = {
  fieldType: null as never,
  fieldName: '',
  options: [
    {
      name: '',
    },
  ],
  validations: {
    isRequired: false,
  },
  visibleToLocations: true,
  oldLocationSupport: false,
};

const formatDefaultValue = (data: LocationFieldEntity): IFormInput => {
  return {
    eid: data.eid,
    fieldType: optionsData.find((value) => value.value === data.fieldType)!,
    fieldName: data.fieldName,
    options: toArray(data.options).map((value) => ({
      name: value,
    })),
    validations: data.validations,
    createdBy: data.createdBy.eid,
    visibleToLocations: data.visibleToLocations,
    oldLocationSupport: data.oldLocationSupport,
  };
};

const AddLocationFieldForm: FC<IProps> = ({ locationField, onFieldAdded }) => {
  const { t } = useTranslation(['common', 'setting']);
  const { onClose } = useModalContext();

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const methods = useForm<IFormInput>({
    defaultValues: locationField
      ? formatDefaultValue(locationField)
      : defaultValue,
  });

  const fieldType = useWatch<IFormInput, 'fieldType.value'>({
    control: methods.control,
    name: 'fieldType.value',
  });

  const [updateLocationField] = useMutation<never, UpdateFieldVariable>(
    UPDATE_LOCATION_FIELD,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t(
            locationField
              ? 'setting:fieldUpdateSuccess'
              : 'setting:fieldAddSuccess'
          ),
        });
        onFieldAdded?.();
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t(
            locationField ? 'setting:fieldUpdateError' : 'setting:fieldAddError'
          ),
        });
      },
    }
  );

  const onFieldTypeChange = (value: IFormInput['fieldType']) => {
    if (!['dropDown', 'singleChoice'].includes(value?.value)) {
      methods.setValue('options', [
        {
          name: '',
        },
      ]);
    }
  };

  const submitForm = (values: IFormInput) => {
    const inputData = formatData(cloneDeep(values));

    const previous = toArray(userObj().entity.locationFields)
      .map(mapToInput)
      .filter((value) => {
        return !(locationField && locationField.eid === value.eid);
      });

    return updateLocationField({
      variables: {
        input: {
          eid: userObj().entityId,
          locationFields: [...previous, inputData],
        },
      },
    });
  };

  const onSaveAndAddMore = async (values: IFormInput) => {
    const res = await submitForm(values);

    if (res.errors) {
      return Promise.reject(res.errors);
    } else {
      setTimeout(methods.reset, 0, defaultValue);
    }
  };

  const onAddAndClose = async (values: IFormInput) => {
    const res = await submitForm(values);

    if (res.errors) {
      return Promise.reject(res.errors);
    } else {
      setTimeout(onClose);
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <Flex flexDir='column' gap={3}>
          <Controller
            control={methods.control}
            name='fieldType'
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isReadOnly={!!locationField}
                  isInvalid={!!fieldState.error}
                >
                  <Box
                    fontWeight='600'
                    fontSize='14px'
                    lineHeight='24px'
                    letterSpacing='-0.01em'
                    color={locationField ? '#999b9f' : '#33383F'}
                  >
                    {t('setting:selectFieldType')}
                  </Box>
                  <Box height={1} />
                  <Dropdown
                    isDisabled={!!locationField}
                    options={optionsData}
                    value={field.value}
                    onChange={callAll(field.onChange, onFieldTypeChange)}
                    placeholder={t('setting:placeholder.fieldType')}
                  />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />

          <OtherFields />
        </Flex>
        {!locationField && (
          <Controller
            name={'oldLocationSupport'}
            render={({ field }) => {
              return (
                <Flex mt={4}>
                  <Checkbox
                    size={'lg'}
                    spacing={'1rem'}
                    isChecked={field.value}
                    onChange={field.onChange}
                  >
                    <Text fontSize='14px' fontWeight='600' lineHeight='24px'>
                      {t('setting:locations_section.oldLocationsSelected')}
                    </Text>
                  </Checkbox>
                </Flex>
              );
            }}
          />
        )}

        <Flex justify='flex-end' gap={2} pt={4}>
          {!locationField && (
            <ActionButton
              variant='outline'
              px='20px'
              fontSize='15px'
              fontWeight='600'
              borderRadius='5px'
              actionFn={methods.handleSubmit(onSaveAndAddMore)}
              isDisabled={!fieldType}
            >
              {t('setting:saveAndAddMore')}
            </ActionButton>
          )}

          <ActionButton
            colorScheme='blue'
            px='20px'
            fontSize='15px'
            fontWeight='600'
            borderRadius='5px'
            actionFn={methods.handleSubmit(onAddAndClose)}
            isDisabled={!fieldType}
          >
            {t(locationField ? 'common:update' : 'setting:addAndClose')}
          </ActionButton>
        </Flex>
      </FormProvider>
    </div>
  );
};

export default AddLocationFieldForm;
