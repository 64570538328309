import { useLazyQuery } from '@apollo/client';
import { Box, Center, Flex, Tooltip } from '@chakra-ui/react';
import { AUDIT_LIST } from 'appRoutes';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteState } from 'routes';
import CollapseTable from 'sub-components/audits/audit-tab/my-audits/components/CollapseTable/CollapseTable';
import { Column } from 'sub-components/audits/audit-tab/my-audits/components/CollapseTable/types';
import { GET_MY_AUDITS } from 'sub-components/audits/audit-tab/my-audits/layers/useServiceLayer';
import { ProcessData } from 'sub-components/audits/audit-tab/my-audits/types';
import { useProcessData } from 'sub-components/audits/audit-tab/my-audits/utils/useProcessData';
import {
  AuditSessionPaginateResponse,
  AuditSessionPaginateVariables,
} from 'sub-components/audits/audit-tab/supervised/types';
import { ReactComponent as AuditView } from '../../../../../assets/images/audit-view-eye.svg';
import { ReactComponent as AuditDownload } from '../../../../../assets/images/audit-download.svg';
import Loader from 'sub-components/Loader';
import { Button } from 'atoms';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import EmptyState from 'sub-components/EmptyState';
import DownloadReport from 'sub-components/audits/common/download-report';

const MyAuditsHome: FC = () => {
  const loggedInUserEid = useUserDataSelector((state) => state.eid);
  const history = useHistory();
  const [processData, setProcessData] = useState<ProcessData[]>([]);
  let processedData = useProcessData();
  const { state } = useRouteState('audit', {
    selectedTab: 'supervisedByMe',
  });
  const [getAuditSessionList, { data, loading, refetch: refetchHandler }] =
    useLazyQuery<AuditSessionPaginateResponse, AuditSessionPaginateVariables>(
      GET_MY_AUDITS,
      {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          let res = processedData({
            data: data?.AuditSessionPagination?.items || [],
          });
          let _data: ProcessData[] = res.map((data) => ({
            ...data,
            key: data.auditSessionId,
          }));
          console.log('my audit res : ', _data);
          setProcessData(_data);
        },
      }
    );

  const getAuditsListFn = () => {
    getAuditSessionList({
      variables: {
        page: 1,
        perPage: 3,
        filter: {
          isAssigned: true,
        },
        sort: '_ID_DESC',
      },
    });
  };

  useEffect(() => {
    if (state?.selectedTab === 'myAudits') {
      getAuditsListFn();
    }
  }, [state?.selectedTab]);

  const columns: Column<ProcessData>[] = [
    {
      header: 'Location',
      accessor: 'auditTitle',
      width: '40%',
    },
    {
      header: 'Expected by',
      width: '20%',
      accessor: (item) =>
        item.assignedLocations?.[0]?.auditExpectedToFinish
          ? moment
              .utc(item.assignedLocations[0].auditExpectedToFinish)
              .format('DD MMM, YYYY')
          : 'No Deadline',
    },
    {
      header: 'Status',
      width: '15%',
      accessor: (item) => (
        <Box
          bg={
            item.assignedLocations?.[0]?.auditStatus === 'completed'
              ? 'rgba(181, 228, 202, 1)'
              : item.assignedLocations?.[0]?.auditStatus === 'ongoing'
              ? 'rgba(255, 216, 141, 1)'
              : item.assignedLocations?.[0]?.auditStatus === 'upcoming'
              ? 'rgba(202, 189, 255, 1)'
              : 'rgba(255, 188, 153, 1)'
          }
          borderRadius='6px'
          p='6px 8px'
          fontWeight={500}
          w='fit-content'
        >
          {(item?.assignedLocations?.[0]?.auditStatus || '')
            ?.charAt?.(0)
            .toUpperCase?.() +
            (item?.assignedLocations?.[0]?.auditStatus || '')?.slice?.(1)}
        </Box>
      ),
    },
    {
      header: 'Action',
      width: '25%',
      justify: 'center',
      accessor: (item) => <></>,
    },
  ];

  const renderPanel = (item: ProcessData) => (
    <Flex flexDir='column'>
      {item.assignedLocations.map((location) => {
        const hasAuditTakeAccess =
          location.auditStartedBy === loggedInUserEid ||
          !location.auditStartedBy;

        const handleAuditAction = () => {
          history.push(
            `${AUDIT_LIST}/take-audit/lI/${location.locationId}/sI/${location.auditSessionId}`
          );
        };

        const downloadKey = `${location.locationId}-${location.auditSessionId}`;

        return (
          <Flex
            key={location.locationId}
            p='20px'
            borderBottom='1px solid #f0f0f0'
            align='center'
          >
            <Box minW='40%' maxW='40%' fontWeight={500} isTruncated>
              {location.locationName}
            </Box>
            <Box minW='20%' maxW='20%' fontWeight={500}>
              {moment(location.auditExpectedToFinish).isValid()
                ? moment
                    .utc(location.auditExpectedToFinish)
                    .format('DD MMM, YYYY')
                : 'No Deadline'}
            </Box>
            <Box minW='15%' maxW='15%'>
              <Box
                w='fit-content'
                bg={
                  location.auditStatus === 'completed'
                    ? 'rgba(181, 228, 202, 1)'
                    : location.auditStatus === 'ongoing'
                    ? 'rgba(255, 216, 141, 1)'
                    : location.auditStatus === 'upcoming'
                    ? 'rgba(202, 189, 255, 1)'
                    : 'rgba(255, 188, 153, 1)'
                }
                borderRadius='6px'
                p='6px 8px'
                fontWeight={500}
              >
                {(location?.auditStatus || '')?.charAt(0).toUpperCase() +
                  (location?.auditStatus || '')?.slice(1)}
              </Box>
            </Box>
            <Box minW='25%' maxW='25%'>
              {location.auditStatus === 'completed' ? (
                <Flex align='center' gap='36px' justify='center'>
                  <Box
                    w='fit-content'
                    cursor='pointer'
                    onClick={() => {
                      history.push(
                        `/audits/report/lI/${location?.locationId}/sI/${location?.auditSessionId}`
                      );
                    }}
                  >
                    <Tooltip hasArrow placement='top' label='View Audit'>
                      <AuditView />
                    </Tooltip>
                  </Box>
                  <DownloadReport
                    locationId={location?.locationId}
                    sessionId={location?.auditSessionId}
                    callbackFn={refetchHandler}
                    reportsUrl={location?.reportsUrl}
                  >
                    <AuditDownload />
                  </DownloadReport>
                </Flex>
              ) : (
                <Tooltip
                  label={
                    !hasAuditTakeAccess ? 'Audit started by someone else' : ''
                  }
                  hasArrow
                  placement='top'
                >
                  <Flex w='full' justify='center'>
                    <Button
                      variant='outline'
                      disabled={!hasAuditTakeAccess}
                      onClick={handleAuditAction}
                    >
                      {location.auditStatus === 'ongoing'
                        ? 'Continue'
                        : 'Start audit'}
                    </Button>
                  </Flex>
                </Tooltip>
              )}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );

  const renderHeader = (item: ProcessData, isExpanded: boolean) => (
    <Flex align='center' p='24px' justify='space-between' w='full'>
      <Flex align='center' color='rgba(111, 118, 126, 1)' w='full'>
        {isExpanded ? (
          <ChevronDownIcon fontSize='20px' />
        ) : (
          <ChevronRightIcon fontSize='20px' />
        )}
        <Flex textAlign='left' ml={2} fontWeight={600}>
          {item.auditTitle}
        </Flex>
        <Flex
          textAlign='left'
          ml={2}
          fontWeight={500}
          flexShrink={0}
          whiteSpace='nowrap'
        >
          - {item.totalAssignments}{' '}
          {item.totalAssignments === 1 ? 'location' : 'locations'}
        </Flex>
      </Flex>
    </Flex>
  );

  if (loading) {
    return (
      <Center h='40vh'>
        <Loader />
      </Center>
    );
  }

  if (!loading && processData?.length === 0) {
    return <EmptyState image='Form' title='No Audit assigned to you' />;
  }

  return (
    <Flex>
      <CollapseTable
        data={processData}
        columns={columns}
        renderPanel={renderPanel}
        renderHeader={renderHeader}
      />
    </Flex>
  );
};

export default MyAuditsHome;
