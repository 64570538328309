import { useReactiveVar } from '@apollo/client';
import { Center } from '@chakra-ui/react';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import React, { FC, useEffect, useMemo } from 'react';
import { UserEntityData } from 'shared/graphql/shared-types';
import {
  AuditSessionAssignments,
  AuditSessionEntity,
} from 'sub-components/audits/audit-tab/supervised/types';
import { usersEntityObj } from 'sub-components/Header';
import Loader from 'sub-components/Loader';
import {
  OneTimeAuditWithoutScore,
  OneTimeAuditWithScore,
  RepeatAuditWithNoScore,
  RepeatAuditWithScore,
  SpecificLocation,
} from './components';
import { useControlLayer } from './control';

const Overview: FC = () => {
  const { loading, overviewData, refetch } = useControlLayer();
  const updateLocationFilterOptions = useAuditDetailContext(
    (state) => state.updateLocationFilterOptions
  );
  const updateSelectedLocationFilter = useAuditDetailContext(
    (state) => state.updateSelectedLocationFilter
  );
  const showSpecificLocationUI = useAuditDetailContext(
    (state) => state.showSpecificLocationUI
  );
  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );
  const entityLocations = useReactiveVar(usersEntityObj);

  const auditLocations = useMemo(() => {
    const locations = entityLocations?.filter((loc: UserEntityData) =>
      overviewData?.assignments?.some(
        (assignment: AuditSessionAssignments) =>
          loc?.eid === assignment?.locationId
      )
    );
    let locationsArr = locations?.map((loc) => {
      return {
        ...loc,
        label: loc?.name,
        value: loc?.eid,
      };
    });
    return [
      {
        label: 'All locations',
        value: 'allLocations',
      },
      ...locationsArr,
    ];
  }, [overviewData, entityLocations]);

  const auditHasScore = useMemo(() => {
    return overviewData?.questions?.some((q) => (q?.score || 0) > 0);
  }, [overviewData]);

  useEffect(() => {
    updateLocationFilterOptions(auditLocations);
    updateSelectedLocationFilter({
      label: 'All locations',
      value: 'allLocations',
    });
  }, [auditLocations]);

  const isRepeatingAudit = useMemo(() => {
    return overviewData?.auditType === 'repeating';
  }, [overviewData]);

  if (loading) {
    return (
      <Center h='50vh'>
        <Loader />
      </Center>
    );
  }

  if (auditHasScore) {
    if (isRepeatingAudit) {
      return (
        <RepeatAuditWithScore
          auditData={overviewData as unknown as AuditSessionEntity}
          refetchHandler={refetch}
        />
      );
    } else {
      return (
        <OneTimeAuditWithScore
          auditData={overviewData as unknown as AuditSessionEntity}
          refetchHandler={refetch}
        />
      );
    }
  } else {
    if (isRepeatingAudit) {
      return (
        <RepeatAuditWithNoScore
          auditData={overviewData as unknown as AuditSessionEntity}
          refetchHandler={refetch}
        />
      );
    } else {
      return (
        <OneTimeAuditWithoutScore
          auditData={overviewData as unknown as AuditSessionEntity}
          refetchHandler={refetch}
        />
      );
    }
  }
};

export default Overview;
