import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../ui-components';

import AuditSignatureContent from './AuditSignatureContent';

export const useAuditSignature = () => {
  const apolloClient = useApolloClient();

  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  return useCallback((onCreate) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: (wProps) => <ApolloProvider client={apolloClient} {...wProps} />,

      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            color='#CABDFF'
            title='Add a Signature'
            fontSize='18px'
            boxWidth={3}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <AuditSignatureContent onCreate={onCreate} />,

      isCentered: true,
      contentProps: {
        maxWidth: '620px',
        paddingBottom: '12px',
      },
      footer: null,
    });
  }, []);
};
