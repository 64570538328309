import { gql } from '@apollo/client';
import { AuditStatus } from '../../../types';
import { AuditQuestionsEntity } from '../create-audit/audit.types';

interface AuditEntity {
  eid: string;
  title: string;
  description: string;
  questions: AuditQuestionsEntity[];
  status?: AuditStatus;
}

export interface AuditQuestionResponse {
  AuditById: AuditEntity;
}

export const AUDIT_QUESTION = gql`
  query AuditQuestion($eid: String!) {
    AuditById(eid: $eid) {
      eid
      title
      description
      questions
      status
    }
  }
`;

export interface UpdateQuestionVariable {
  eid: string;
  title: string;
  description?: string;
  questions?: AuditQuestionsEntity[];
}

export const AUDIT_QUESTION_UPDATE = gql`
  mutation UpdateAuditQuestions($input: AuditUpdateInputInput) {
    UpdateAudit(input: $input) {
      auditType
    }
  }
`;
