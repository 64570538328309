import { gql } from '@apollo/client';

export const AUDIT_UPDATE = gql`
  mutation UpdateAudit($input: AuditUpdateInputInput) {
    UpdateAudit(input: $input) {
      eid
    }
  }
`;

export const AUDIT_QUERY = gql`
  query AuditById($eid: String!) {
    AuditById(eid: $eid) {
      eid
      status

      title
      templateId

      auditType
      hasCustomRepeat
      repeatDetails
      repeatString
      endCondition {
        endDate
        endType
      }

      notifyLO
      auditors {
        assignType
        locations
        userId
      }
    }
  }
`;
