import React, { FC, useRef } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { Button } from '../../../../atoms';

interface ItemProps {
  title: string;
  caption: string;
  value: string;
}

const FilterItem: FC<ItemProps> = ({ value, title, caption }) => {
  return (
    <Checkbox
      value={value}
      marginTop={1}
      size='lg'
      alignItems='start'
      borderRadius='12px'
      padding={3}
      sx={{
        '.chakra-checkbox__control': {
          borderRadius: '6px',
          marginTop: '4px',
        },
      }}
      _hover={{ bg: '#EFEFEF', color: '#33383F' }}
      _checked={{ bg: '#2A85FF1A', color: '#2A85FF' }}
      color='#6F767E'
    >
      <Flex flexDir='column'>
        <Box fontSize='15px' fontWeight='600'>
          {title}
        </Box>
        <Box fontSize='12px' fontWeight='500'>
          {caption}
        </Box>
      </Flex>
    </Checkbox>
  );
};

interface IProps {
  filter?: string[];
  onChange?: (value: string[]) => void;
}

const QuestionFilter: FC<IProps> = ({ filter, onChange }) => {
  const initialFocusRef = useRef<HTMLDivElement>(null);

  return (
    <Popover initialFocusRef={initialFocusRef} placement='bottom-end'>
      <PopoverTrigger>
        <Button
          height={12}
          variant='outline'
          borderRadius='12px'
          color='#6F767E'
          rightIcon={<ChevronDownIcon fontSize='20px' />}
        >
          Filter by status
        </Button>
      </PopoverTrigger>
      <PopoverContent ref={initialFocusRef} borderRadius='16px' boxShadow='md'>
        <PopoverBody paddingX={4} paddingY={3}>
          <CheckboxGroup value={filter} onChange={onChange}>
            <Flex flexDir='column' gap='2px'>
              <FilterItem
                value='isCompleted'
                title='Completed'
                caption='Questions that have been answered'
              />
              <FilterItem
                value='isNotFilled'
                title='Not filled'
                caption='Questions that are unanswered'
              />
              <FilterItem
                value='isRequired'
                title='Mandatory'
                caption='Questions that must be answered'
              />
              <FilterItem
                value='isFlagged'
                title='Flagged'
                caption='Questions with flagged responses'
              />
            </Flex>
          </CheckboxGroup>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default QuestionFilter;
