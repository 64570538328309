import { AuditSessionEntity } from '../audit-tab/supervised/types';
import { auditStatus } from '../audit-tab/supervised/utils';

export const getAuditStatus = (auditData: AuditSessionEntity | undefined) => {
  let isAllCompleted = auditData?.isCompleted;
  let hasAuditStarted = auditData?.assignments?.some(
    (assignment) => assignment.startedBy
  );
  let status = auditStatus(
    !!isAllCompleted,
    auditData?.dueDate || new Date()?.toISOString(),
    !!hasAuditStarted
  );
  return status;
};
