import CryptoJS from 'crypto-js';

const colors = ['#B1E5FC', '#B1FCDC', '#FCC7B1', '#C3EEC6'];

const cache: Record<string, string> = {};

function hashString(value: string) {
  return parseInt(
    CryptoJS.MD5(value).toString(CryptoJS.enc.Hex).slice(0, 8),
    16
  );
}

export const stringToColor = (value: string): string => {
  if (!cache[value]) {
    const hash = hashString(value);
    const colorIndex = hash % colors.length;
    cache[value] = colors[colorIndex];
  }

  return cache[value];
};
