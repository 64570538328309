import React, { FC, useMemo } from 'react';
import { Dropdown, SelectOption } from 'atoms';
import { toArray } from '../../../../../utils';
import { AuditOptionEntity } from '../../../../../types';

interface IProps {
  options: AuditOptionEntity[];
  value?: string[];
  onChange?: (value?: string[]) => void;
  isReadOnly?: boolean;
}

const DropdownType: FC<IProps> = ({
  options: _options,
  value = [],
  onChange,
  isReadOnly,
}) => {
  const options = useMemo(() => {
    return toArray(_options).reduce<SelectOption[]>((accOptions, option) => {
      if (option?.isDisabled || !option.label) {
        return accOptions;
      }

      accOptions.push({ label: option?.label, value: option?.eid });

      return accOptions;
    }, []);
  }, [_options]);

  const foundOption = useMemo(() => {
    return options.find((op) => value.includes(op?.value));
  }, [value, options]);

  return (
    <Dropdown
      size='lg'
      isReadOnly={isReadOnly}
      options={options}
      value={foundOption || null}
      onChange={(newValue) => {
        if (value.includes(newValue.value)) {
          return onChange?.(undefined);
        }
        return onChange?.(toArray(newValue?.value));
      }}
      selectStyles={{
        menu: {
          zIndex: 3,
        },
      }}
    />
  );
};

export default DropdownType;
