import isEmpty from 'lodash/isEmpty';
import { QuestionsState } from '../../types';
import { QuestionType } from '../../../../../types';

export const responseIdValidate = (
  value: string[],
  values: QuestionsState
): undefined | string => {
  if (isEmpty(value) && values?.isRequired) {
    return 'This field is required.';
  }

  // if (
  //   [
  //     QuestionType.MULTI_CHOICE,
  //     QuestionType.CHECKBOX,
  //     QuestionType.DROPDOWN,
  //     QuestionType.MEMBER,
  //     QuestionType.LOCATION,
  //   ].includes(values?.qType)
  // ) {
  //   if (isEmpty(value) && values?.isRequired) {
  //     return 'This field is required.';
  //   }
  //   return undefined;
  // }
  return undefined;
};

export const evidenceValidate = (
  value: QuestionsState['images'],
  values: QuestionsState
): undefined | string => {
  if (
    [
      QuestionType.MULTI_CHOICE,
      QuestionType.CHECKBOX,
      QuestionType.DROPDOWN,
    ].includes(values?.qType)
  ) {
    if (values?.requiresEvidence && !value?.length) {
      const res = values.options?.some(
        (op) => op.requiresEvidence && values?.responseId?.includes(op.eid)
      );
      if (res) {
        return 'Evidence required for this answer';
      }
    }
  }

  return undefined;
};
