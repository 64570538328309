import { TriggerType } from 'sub-components/audits/audit-tab/supervised/components/GridView/components/AuditCard';

export interface InitialEvents {
  LauncherDetailsPage: {
    fetchCompleteData: any;
  };
  EditLauncherTaskSideDrawer: {
    fetchCompleteData: any;
    onUpdateDueTaskDate: any;
  };
  LocationListPage: {
    preLaunchLocationLaunchClick: any;
    preLaunchLocationeEitClick: any;
    preLaunchLocationDeleteClick: any;
  };
  preLaunchLocationModal: {
    goToLauncher: any;
  };
  bulkUploadLocationsModal: {
    onBulkUploadSuccess: any;
    onBulkUploadError: any;
  };
  knowledgeBaseVisibilityModal: {
    onSetVisibilityModalClose: any;
  };
  auditSupervisedListing: {
    actionTrigger: { triggerType: TriggerType; auditId: string | undefined };
  };
}

export const InitialEventsJson: InitialEvents = {
  LauncherDetailsPage: {
    fetchCompleteData: undefined,
  },
  EditLauncherTaskSideDrawer: {
    fetchCompleteData: undefined,
    onUpdateDueTaskDate: undefined,
  },
  LocationListPage: {
    preLaunchLocationLaunchClick: undefined,
    preLaunchLocationeEitClick: undefined,
    preLaunchLocationDeleteClick: undefined,
  },
  preLaunchLocationModal: {
    goToLauncher: undefined,
  },
  bulkUploadLocationsModal: {
    onBulkUploadError: undefined,
    onBulkUploadSuccess: undefined,
  },
  knowledgeBaseVisibilityModal: {
    onSetVisibilityModalClose: undefined,
  },
  auditSupervisedListing: {
    actionTrigger: { triggerType: undefined, auditId: undefined },
  },
};
