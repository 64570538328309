import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

/**
 * @deprecated user Loader from "sub-components"
 * @example
 * import { Loader } from 'sub-components';
 */
const Loader = () => {
  return <Spin className='eventclass-loader' indicator={antIcon} />;
};

export default Loader;
