import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';
import { useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';
import { Dropdown, Input, SelectOption } from '../../../../../../../atoms';
import { AuditHelper } from '../../../../../../../configs';
import { IFormInput } from '../../../../audit-schedule.types';
import CustomRight from './CustomRight';

interface IOption {
  label: TFuncKey<'audit', undefined>;
  value: string;
}

const options: IOption[] = [
  { label: 'months', value: 'months' },
  { label: 'days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
];

function isNumber(value: string) {
  return value.match(/[0-9]+/);
}

const toValue = (value?: string): SelectOption | undefined =>
  value ? { value: value, label: value } : undefined;

const Custom: FC = () => {
  const { t } = useTranslation('audit');
  const { resetField } = useFormContext<IFormInput>();

  const resetFields = (
    value: IFormInput['repeatDetails']['customRepeatType']
  ) => {
    switch (value) {
      case 'days':
        resetField('repeatDetails.customRepeatWeek');
        resetField('repeatDetails.customRepeatMonth');
        break;
      case 'weeks':
        resetField('repeatDetails.customRepeatMonth');
        break;
      case 'months':
        resetField('repeatDetails.customRepeatWeek');
        break;
    }
  };

  return (
    <Flex align='start' gap={3}>
      <Flex flex={1} align='start' gap={3}>
        <Flex align='center' fontWeight={600} color='#33383F' h={12}>
          Every
        </Flex>

        <Controller<IFormInput, 'repeatDetails.customRepeatValue'>
          name='repeatDetails.customRepeatValue'
          rules={{
            required: 'Field is required',
          }}
          defaultValue={2}
          render={({ field, fieldState }) => {
            return (
              <FormControl width='unset' isInvalid={fieldState.invalid}>
                <Input
                  size='lg'
                  variant='auditOutline'
                  width='64px'
                  isInvalid={!!fieldState.error}
                  // isReadOnly={isReadOnly}
                  textAlign='center'
                  {...field}
                  onKeyPressCapture={(event) => {
                    if (!isNumber(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onBlur={AuditHelper.save(field.onBlur)}
                />
              </FormControl>
            );
          }}
        />

        <Controller<IFormInput, 'repeatDetails.customRepeatType'>
          name='repeatDetails.customRepeatType'
          rules={{
            required: 'Repeat cycle required',
          }}
          defaultValue='days'
          render={({ field, fieldState }) => {
            return (
              <FormControl width='unset' isInvalid={fieldState.invalid}>
                <Flex gap={4} align='center'>
                  <Dropdown
                    size='lg'
                    options={options}
                    isSearchable={false}
                    value={toValue(field.value)}
                    isDisabled={field.disabled}
                    onChange={(newValue) =>
                      callAll(field.onChange, resetFields)(newValue.value)
                    }
                    getOptionLabel={(option) => t(option.label)}
                    selectStyles={{
                      container: { minWidth: '150px' },
                      menuList: {
                        minWidth: '100%',
                      },
                      singleValue: {
                        fontSize: '14px',
                        fontWeight: '500',
                      },
                      placeholder: {
                        fontSize: '14px',
                      },
                    }}
                    inputStyle={{
                      borderWidth: '2px',
                      borderRadius: '12px',
                      outline: 'none',
                      backgroundColor: 'white',
                    }}
                  />
                </Flex>
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>
      <CustomRight />
    </Flex>
  );
};

export default Custom;
