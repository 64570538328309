import { TablePaginationConfig } from 'antd';
import { Any } from '../../types';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from './table.types';

type OnChange<RecordType> = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
  extra: TableCurrentDataSource<RecordType>
) => void;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChangeHandler<T extends Any>(callback: OnChange<T>) {
  return callback;
}
