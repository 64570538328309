import React, { FC } from 'react';
import { Center, Checkbox, Radio } from '@chakra-ui/react';
import { QuestionType } from '../../../../../types';

interface IProps {
  qType: QuestionType;
}

const OptionIcon: FC<IProps> = ({ qType }) => {
  return (
    <Center height={12}>
      {qType === QuestionType.CHECKBOX && <Checkbox isReadOnly />}
      {qType === QuestionType.MULTI_CHOICE && <Radio isReadOnly />}
    </Center>
  );
};

export default OptionIcon;
