import React, { FC, useMemo } from 'react';
import { FieldArrayWithId, useWatch } from 'react-hook-form';
import { searchRegExp } from '../../../../utils';
import { IFormInput, SectionEntity } from '../types';
import QuestionContainer from './QuestionContainer';

interface IProps {
  sectionIndex: number;
  searchQuery: string;
  filter?: string[];
}

const canRender = (
  ques: FieldArrayWithId<IFormInput, `sectionData.${number}.data`, 'eid'>,
  reg: RegExp,
  filter?: string[]
) => {
  const status = ques.label?.match(reg) || ques.qType?.match(reg);
  if (filter?.length) {
    // @ts-ignore
    // return filter.every((f) => !!ques?.[f]) && status; // TODO AND operation applied here
    // @ts-ignore
    return filter.some((f) => !!ques?.[f]) && status; // TODO OR operation applied here
  }
  return status;
};

const QuestionsList: FC<IProps> = ({ sectionIndex, searchQuery, filter }) => {
  const fields = useWatch<SectionEntity, `data`>({
    name: `data`,
    defaultValue: [],
  });

  const _fieldList = fields.slice().map((question, index) => ({
    question,
    questionIndex: index,
  }));

  const fieldList = useMemo(() => {
    if (searchQuery || filter?.length) {
      const reg = searchRegExp(searchQuery, 'gi');
      return _fieldList.filter((ques) => canRender(ques.question, reg, filter));
    }
    return _fieldList;
  }, [_fieldList, searchQuery, filter]);

  return (
    <>
      {fieldList?.map(({ question, questionIndex }, index) => (
        <QuestionContainer
          key={question.eid}
          index={index}
          question={question}
          questionIndex={questionIndex}
          sectionIndex={sectionIndex}
        />
      ))}
    </>
  );
};

export default QuestionsList;
