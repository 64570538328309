import { useReactiveVar } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { SelectOption } from 'atoms';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AuditSessionAssignments,
  AuditSessionEntity,
} from 'sub-components/audits/audit-tab/supervised/types';
import { SessionDetailEntity } from 'sub-components/audits/take-audit/types';
import { usersEntityObj } from 'sub-components/Header';
import { AuditQuestionEntity } from 'types';
import { useAuditDetailContext } from '../../../store/context';
import { DisplaySession } from '../../QuestionSection/types/audit-question.types';
import {
  ControlLayer,
  FlaggedDataEntity,
  FlaggedGraphData,
  GetFlaggedResponsesEntity,
  SectionEntity,
} from '../types';
import { useServiceLayer } from './useServiceLayer';

export const useControlLayer = (isAuditRepeating: boolean): ControlLayer => {
  const params = useParams<{ auditId: string; sessionId: string }>();
  const {
    auditCycles: { auditCyclesResponse, getAuditCycles },
    flaggedSession: {
      flaggedResponseSessionDetails,
      getFlaggedResponseSessionDetails,
    },
    // graphData: { getGraphData, graphDataDetails },
  } = useServiceLayer();
  const flaggedQueryData =
    flaggedResponseSessionDetails?.data?.AuditSessionById;
  const updateSessionsLoading = useAuditDetailContext(
    (state) => state.updateSessionLoading
  );
  const selectedTab = useAuditDetailContext((state) => state.selectedTab);
  const selectedAuditSessionId = useAuditDetailContext(
    (state) => state.selectedAuditSessionId
  );
  const updateSelectedSession = useAuditDetailContext(
    (state) => state.updateSelectedSession
  );
  const updateSelectedAuditSessionId = useAuditDetailContext(
    (state) => state.updateSelectedAuditSessionId
  );
  const updateDisplaySession = useAuditDetailContext(
    (state) => state.updateDisplaySessions
  );
  const entityObj = useReactiveVar(usersEntityObj);
  const [sessions, setSessions] = useState<SelectOption[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<
    SelectOption | undefined
  >(undefined);
  const [selectedSession, setSelectedSession] = useState<SelectOption>({
    label: '',
    value: '',
  });
  const [locationsList, setLocationsList] = useState<SelectOption[]>([]);

  const { loggedInUserAuthRole, loggedInUserEid } = useUserDataSelector(
    (state) => ({
      loggedInUserEid: state?.eid,
      loggedInUserAuthRole: state?.authRole,
    })
  );

  useEffect(() => {
    updateSessionsLoading(auditCyclesResponse?.loading);
  }, [auditCyclesResponse?.loading]);

  useEffect(() => {
    let foundSession = flaggedQueryData;
    if (foundSession) {
      let assignments: AuditSessionAssignments[] = [];
      if (loggedInUserAuthRole === AuthRole.ADMIN) {
        assignments =
          foundSession?.assignments?.filter((assignment) =>
            assignment?.auditors?.includes(loggedInUserEid)
          ) || [];
      } else {
        assignments = foundSession?.assignments;
      }
      let locationIds = assignments?.map(
        (assignment) => assignment?.locationId
      );
      let foundLocations = entityObj
        ?.filter((obj) => locationIds?.some((locId) => locId === obj?.eid))
        ?.map((loc) => {
          return {
            ...loc,
            label: loc?.name,
            value: loc?.eid,
          };
        });
      setLocationsList(foundLocations);
      setSelectedLocation(foundLocations?.[0]);
    }
  }, [flaggedQueryData, entityObj, loggedInUserAuthRole, loggedInUserEid]);

  useEffect(() => {
    updateSelectedAuditSessionId(params?.sessionId);
  }, [params]);

  useEffect(() => {
    let sessions = transformSessions(
      auditCyclesResponse?.data?.AuditSessionPagination?.items || []
    );
    let foundSession = sessions?.find(
      (session) => session?.eid === params?.sessionId
    );
    if (foundSession) {
      // setSelectedSession({
      //   label: foundSession?.label,
      //   value: foundSession?.eid,
      // });
      updateSelectedSession({
        label: foundSession?.label,
        value: foundSession?.eid,
      });
    }
    updateDisplaySession(sessions);
    // return sessions;
  }, [auditCyclesResponse?.data?.AuditSessionPagination?.items, params]);

  const transformSessions = (
    sessions: SessionDetailEntity[]
  ): DisplaySession[] => {
    if (!sessions || sessions.length === 0) return [];

    // const sorted = [...sessions].sort(
    //   (a, b) =>
    //     new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    // );

    const transformed: DisplaySession[] = sessions.map(
      (session, index, arr) => {
        if (index === 0)
          return { ...session, label: 'Current cycle', value: session?.eid };
        if (index === 1)
          return { ...session, label: 'Previous cycle', value: session?.eid };
        return {
          ...session,
          label: `${moment(session?.startDate)?.format('DD MMM YYYY')}`,
          value: session?.eid,
        };
        // return {
        //   ...session,
        //   label: `${moment(session?.startDate)?.format('DD MMM YY')} - ${moment(
        //     session?.dueDate
        //   )?.format('DD MMM YY')}`,
        //   value: session?.eid,
        // };
      }
    );

    return transformed;
  };

  useEffect(() => {
    if (params?.auditId && selectedTab === 'flaggedResponse') {
      getFlaggedResponseSessionDetails({
        variables: {
          eid: selectedAuditSessionId,
        },
      });
    }
  }, [selectedTab, selectedAuditSessionId]);

  useEffect(() => {
    if (isAuditRepeating && selectedTab === 'flaggedResponse') {
      getAuditCycles({
        variables: {
          page: 1,
          perPage: 1000,
          filter: {
            auditIds: [params?.auditId],
          },
          sort: 'DUEDATE_DESC',
        },
      });
    }
  }, [selectedTab, isAuditRepeating]);

  const displaySessions = useMemo(() => {
    let sessions = transformSessions(
      auditCyclesResponse?.data?.AuditSessionPagination?.items || []
    );
    let foundSession = sessions?.find(
      (session) => session?.eid === params?.sessionId
    );
    if (foundSession) {
      setSelectedSession({
        label: foundSession?.label,
        value: foundSession?.eid,
      });
    }
    return sessions;
  }, [auditCyclesResponse?.data?.AuditSessionPagination?.items, params]);

  const flaggedResponseData =
    flaggedResponseSessionDetails?.data?.AuditSessionById;

  const sectionWiseData = (filteredSession: AuditSessionEntity) => {
    const categorizedData: Array<SectionEntity> = [];

    let currentSection: SectionEntity = {
      eid: 'default',
      sectionName: 'Audit Questionnaire',
      data: [],
    };

    filteredSession?.questions?.forEach((question) => {
      if (question?.qType === 'section') {
        if (currentSection?.data?.length > 0) {
          categorizedData?.push(currentSection);
        }
        currentSection = {
          eid: question.eid,
          sectionName: question.label,
          data: [],
        };
      } else {
        currentSection.data.push(question);
      }
    });
    if (currentSection.data.length > 0) {
      categorizedData.push(currentSection);
    }
    return categorizedData;
  };

  const resForQuestion = (
    foundLocation: AuditSessionAssignments,
    question: AuditQuestionEntity
  ) => {
    return foundLocation.response.find(
      (response) => response.qid === question.eid
    );
  };

  const getFlaggedResponses = (
    foundLocation: AuditSessionAssignments,
    questions: AuditQuestionEntity[]
  ): GetFlaggedResponsesEntity[] => {
    let arr: GetFlaggedResponsesEntity[] = [];
    questions?.map((question) => {
      const responseForQuestion = resForQuestion(foundLocation, question);
      let isResponseFlagged = false;
      if (responseForQuestion) {
        isResponseFlagged = responseForQuestion.isFlagged;
        if (responseForQuestion.responseId.length > 0) {
          const selectedOptions = question.options.filter((option) =>
            responseForQuestion.responseId.includes(option.eid)
          );
          if (selectedOptions.some((option) => option.isFlagged)) {
            isResponseFlagged = true;
          }
        }
      }
      if (isResponseFlagged) {
        arr.push({
          question,
          response: responseForQuestion,
        });
      }
    });
    return arr;
  };

  const flaggedData = useMemo((): FlaggedDataEntity[] => {
    let arr: FlaggedDataEntity[] = [];
    if (flaggedResponseData) {
      let filteredSession = cloneDeep(flaggedResponseData);
      if (filteredSession) {
        if (loggedInUserAuthRole === AuthRole.ADMIN) {
          filteredSession.assignments =
            filteredSession?.assignments?.filter((assignment) =>
              assignment?.auditors?.includes(loggedInUserEid)
            ) || [];
        }
        const sectionData = sectionWiseData(filteredSession);
        let foundAssignment = filteredSession?.assignments?.find(
          (assignment) => assignment?.locationId === selectedLocation?.value
        );
        sectionData?.forEach((section) => {
          if (foundAssignment) {
            let processedArr = getFlaggedResponses(
              foundAssignment,
              section?.data
            );
            arr.push({
              section,
              flaggedData: processedArr,
            });
          }
        });
      }
    }
    return arr;
  }, [flaggedResponseData, params, selectedLocation]);

  const graphDataFlagged = useMemo(() => {
    let arr: FlaggedGraphData[] = [];
    if (flaggedResponseData) {
      let filteredSession = cloneDeep(flaggedResponseData);
      if (filteredSession) {
        if (loggedInUserAuthRole === AuthRole.ADMIN) {
          filteredSession.assignments =
            filteredSession?.assignments?.filter((assignment) =>
              assignment?.auditors?.includes(loggedInUserEid)
            ) || [];
        }
        filteredSession?.assignments?.forEach((assignment) => {
          let locDetails = entityObj?.find(
            (entity) => entity?.eid === assignment?.locationId
          );
          let processArr = getFlaggedResponses(
            assignment,
            filteredSession?.questions || []
          );
          arr.push({
            locName: locDetails?.name || '',
            flagCount: processArr?.length,
          });
        });
      }
    }
    return arr;
  }, [flaggedResponseData, loggedInUserAuthRole, loggedInUserEid, entityObj]);

  return {
    displaySessions,
    flaggedData,
    graphDataFlagged,
    loading: flaggedResponseSessionDetails?.loading,
    locationsList,
    selectedLocation,
    selectedSession,
    sessions,
    sessionsLoading: auditCyclesResponse?.loading,
    setLocationsList,
    setSelectedLocation,
    setSelectedSession,
    setSessions,
  };
};
