import React, { FC, forwardRef, Fragment, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuestionType } from '../../../../../types';

import { QuestionData } from './question-data';
import { IFormInput } from '../../form-types';

const CanAddDiv: QuestionType[] = [
  QuestionType.CHECKBOX,
  QuestionType.LONG_TEXT,
  QuestionType.DROPDOWN,
  QuestionType.MEMBER,
];

const AddDivider: FC<{ value: QuestionType }> = ({ value }) => {
  if (CanAddDiv.includes(value)) {
    return <MenuDivider my='1px' />;
  }
  return null;
};

interface IProps {
  value?: QuestionType;
  onChange?: (value: QuestionType) => void;
  disabled?: boolean;
  isViewOnly?: boolean;
}

const AuditQuestionTypeSelect = forwardRef<never, IProps>(
  ({ value: _value, onChange, disabled, isViewOnly }, ref) => {
    const { t } = useTranslation('form', { keyPrefix: 'questionType' });
    const { setValue } = useFormContext<IFormInput>();

    const { isOpen, onClose, onOpen } = useDisclosure({
      onOpen() {
        setValue('height', '300px');
      },
      onClose() {
        setValue('height', 0);
      },
    });

    const value = useMemo(() => {
      return QuestionData.find((val) => val.value === _value);
    }, [_value]);

    if (isViewOnly) {
      return (
        <Tooltip
          label='You cannot modify the question type of an audit question. To make changes, create a copy of the question and remove the original.'
          hasArrow
          padding='10px 16px'
          borderRadius='8px'
          placement='top'
          fontWeight='500'
          bg='blackAlpha.800'
        >
          <Button
            ref={ref}
            size='lg'
            borderRadius='12px'
            rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
            paddingInline='12px'
            variant='outline'
            bg='white'
            width='full'
          >
            <Flex flex={1} gap={2} align='center'>
              {value ? (
                <>
                  <Image src={value.image} boxSize='16px' />
                  <Box fontSize='14px' fontWeight='600' whiteSpace='nowrap'>
                    {t(value.title)}
                  </Box>
                </>
              ) : (
                <Box fontSize='14px' fontWeight='600' opacity='0.5'>
                  Select question type
                </Box>
              )}
            </Flex>
          </Button>
        </Tooltip>
      );
    }

    return (
      <Menu
        matchWidth
        closeOnSelect
        boundary='scrollParent'
        autoSelect={false}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <MenuButton
          ref={ref}
          as={Button}
          size='lg'
          borderRadius='12px'
          rightIcon={<ChevronDownIcon boxSize='28px' color='#6F767E' />}
          paddingInline='12px'
          variant='outline'
          bg='white'
          width='full'
          isDisabled={disabled}
        >
          <Flex gap={2} align='center'>
            {value ? (
              <>
                <Image src={value.image} boxSize='16px' />
                <Box fontSize='14px' fontWeight='600' whiteSpace='nowrap'>
                  {t(value.title)}
                </Box>
              </>
            ) : (
              <Box fontSize='14px' fontWeight='600' opacity='0.5'>
                Select question type
              </Box>
            )}
          </Flex>
        </MenuButton>
        <MenuList
          width='inherit'
          display='flex'
          flexDirection='column'
          padding='12px'
          borderRadius='12px'
          zIndex={100}
        >
          {QuestionData.map((item) => (
            <Fragment key={item.value}>
              <MenuItem
                my={1}
                gap={2}
                height='34px'
                alignItems='center'
                paddingInline='12px'
                borderRadius='5px'
                cursor='pointer'
                aria-selected={item.value === _value}
                _selected={{ bg: '#2A85FF1A', color: '#2A85FF' }}
                _hover={{ bg: '#F4F4F3' }}
                onClick={() => onChange?.(item.value)}
              >
                <Image boxSize='16px' alt={item.title} src={item.image} />
                <Box flex={1} fontSize='12px' fontWeight='600' isTruncated>
                  {t(item.title)}
                </Box>
              </MenuItem>
              <AddDivider value={item.value} />
            </Fragment>
          ))}
        </MenuList>
      </Menu>
    );
  }
);

AuditQuestionTypeSelect.displayName = 'QuestionTypeSelect';

export default AuditQuestionTypeSelect;
