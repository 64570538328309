import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useMemo } from 'react';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { getAuditRepeatStatus } from 'sub-components/audits/common';

interface IProps {
  auditData: AuditSessionEntity | undefined;
}

const FrequencyDetail: FC<IProps> = ({ auditData }) => {
  const repeatStr = useMemo(() => {
    return getAuditRepeatStatus(auditData?.repeatDetails);
  }, [auditData]);
  return (
    <Flex justify='space-between' color='#1A1D1F'>
      <Box flex={1} fontWeight={600}>
        Frequency:
      </Box>
      <Flex
        flex={1}
        color='#6F767E'
        fontWeight={500}
        justify='flex-end'
        textTransform='capitalize'
        align='center'
        gap='5px'
      >
        <FontAwesomeIcon icon={faRepeat as IconProp} />
        <Tooltip label={repeatStr} hasArrow>
          <Box isTruncated maxW='200px' cursor='pointer'>
            {repeatStr}
          </Box>
        </Tooltip>
        {/* {auditData?.auditType === 'oneTime'
            ? 'One time'
            : auditData?.repeatCycle || 'Repeating'} */}
      </Flex>
    </Flex>
  );
};

export default FrequencyDetail;
