import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FlexCard } from '../../../../atoms';
import LOAuditHistory from '../../../../sub-components/audits/location-owner/AuditHistory/LOAuditHistory';
import LOAuditDetailSection from '../../../../sub-components/audits/location-owner/LOAuditDetailSection/LOAuditDetail';
import HeadingSection from './HeadingSection';

interface IProps {
  auditTitle: string;
  isAuditScored: boolean;
  loading: boolean;
}

const LocationOwnerAuditDetail: FC<IProps> = ({
  auditTitle,
  isAuditScored,
  loading,
}) => {
  return (
    <Flex flexDir='column' gap={4} w='full'>
      <HeadingSection
        auditTitle={auditTitle}
        loading={loading}
        customNavigateText='Back'
      />
      <FlexCard w='full' gap={4} p='28px 39px'>
        <Flex w='70%'>
          <LOAuditHistory isAuditScored={isAuditScored} />
        </Flex>
        <Flex w='30%'>
          <LOAuditDetailSection isAuditScored={isAuditScored} />
        </Flex>
      </FlexCard>
    </Flex>
  );
};

export default LocationOwnerAuditDetail;
