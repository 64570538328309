import React, { FC, Fragment } from 'react';
import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

import { IActionMenuData } from './action-menu.types';
import ActionMenuItem from './ActionMenuItem';
import MenuItemWrapper from './MenuItemWrapper';
import { useMountedRef } from '../../hooks';

interface IProps {
  arrowSize?: number;
  closeOnBlur?: boolean;
  offset?: [number, number];
  menuData?: Array<IActionMenuData>;
  strategy?: 'absolute' | 'fixed';
  clickedItem?: (name: string, value: string) => void | Promise<void | null>;
}

const ActionMenu: FC<IProps> = ({
  menuData,
  children,
  arrowSize,
  closeOnBlur,
  offset,
  clickedItem,
  strategy,
}) => {
  const mountedRef = useMountedRef();
  const itemClickHandler = async (
    menu: IActionMenuData,
    callback: () => void
  ) => {
    await clickedItem?.(menu.name, menu.value);
    if (mountedRef.current) {
      callback();
    }
  };

  return (
    <Popover
      placement='bottom-start'
      arrowSize={arrowSize}
      closeOnBlur={closeOnBlur}
      offset={offset}
      strategy={strategy}
    >
      {({ onClose }) => (
        <>
          {/*// @ts-ignore*/}
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent borderRadius='8px' width='unset' minW='280px'>
            <PopoverArrow />
            <PopoverBody borderRadius='8px' boxShadow='lg' px='16px'>
              <Flex flexDir='column' py='8px' gap='1px'>
                {menuData?.map((menu, index) => (
                  <Fragment key={`${menu.value || menu.name}--${index}`}>
                    {menu.render ? (
                      menu.render({ menu })
                    ) : (
                      <MenuItemWrapper
                        menu={menu}
                        onClick={(e) => {
                          e.stopPropagation();
                          return itemClickHandler(menu, onClose);
                        }}
                      >
                        <ActionMenuItem menu={menu} />
                      </MenuItemWrapper>
                    )}
                  </Fragment>
                ))}
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default ActionMenu;
