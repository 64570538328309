import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, get } from 'react-hook-form';
import { Any } from '../../../../../types';
import { IFormInput, QuestionsState, SectionEntity } from '../../types';
import { Member } from '../question-types';
import { AuditTake } from '../../tabke-audit.helpers';
import { responseIdValidate } from './input.validator';

interface IProps {
  question: QuestionsState;
  sectionIndex: number;
  questionIndex: number;
}

const MemberSection: FC<IProps> = ({
  question,
  sectionIndex,
  questionIndex,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveHandler = (...args: Any) => {
    AuditTake.save(`data.${questionIndex}`, question.eid);
  };

  return (
    <Controller<SectionEntity, `data.${number}.responseId`>
      name={`data.${questionIndex}.responseId`}
      rules={{
        required: {
          value: question?.isRequired,
          message: 'This field is required',
        },
        validate: (value, values) => {
          return responseIdValidate(
            value,
            get(values, `data.${questionIndex}`)
          );
        },
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <Member
            value={field.value}
            onChange={field.onChange}
            onBlur={saveHandler}
          />
          <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default MemberSection;
