import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { toArray } from '../../../../utils';
import { useHistory } from '../../../../routes';
import {
  AUDIT_SESSION_DETAILS,
  SUBMIT_AUDIT_RESPONSE,
} from '../take-audit.graphql';
import {
  SubmitAuditResponse,
  AuditResponseInput,
  ServiceLayer,
  SessionDetailResponse,
  SessionDetailVariables,
} from '../types';
import { useAuditPausedWarning } from '../components/useAuditPausedWarning';

export const useServiceLayer = (): ServiceLayer => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    duration: 3000,
  });

  const showWarning = useAuditPausedWarning();

  const [getSessionDetails, sessionDetailResult] = useLazyQuery<
    SessionDetailResponse,
    SessionDetailVariables
  >(AUDIT_SESSION_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const auditData = response.AuditSessionById?.audit;
      if (auditData?.status === 'AUDIT_PAUSED') {
        const log = toArray(auditData.statusLogs)
          .filter((l) => l.status === 'AUDIT_PAUSED')
          .at(0);
        showWarning({
          name: log?.createdByUser?.name!,
          date: log?.statusDate!,
        });
      }
      if (auditData?.status === 'AUDIT_DELETED') {
        toast({
          status: 'warning',
          title: t('common:warning'),
          description: 'Audit has been deleted.',
        });
        history.goBack();
      }
    },
  });

  const [submitAuditResponse, auditQuestionResponseResult] = useMutation<
    SubmitAuditResponse,
    AuditResponseInput
  >(SUBMIT_AUDIT_RESPONSE, {
    onError: (error) => {
      // @ts-ignore
      const message = error?.graphQLErrors?.[0]?.message || '';
      if (message?.includes('User not assigned')) {
        toast({
          status: 'error',
          title: t('common:error'),
          description: message,
        });
        history.goBack();
      } else if (message?.includes('Audit is paused')) {
        toast({
          status: 'warning',
          title: t('common:warning'),
          description:
            'Audit is currently paused. The session is now inactive.',
        });
      } else if (message?.includes('Audit is deleted')) {
        toast({
          status: 'warning',
          title: t('common:warning'),
          description: 'Audit has been deleted and is no longer accessible',
        });
      } else {
        toast({
          status: 'error',
          title: 'Something went wrong',
        });
      }
    },
  });

  return {
    sessionDetails: { getSessionDetails, sessionDetailResult },
    questionSubmit: { submitAuditResponse, auditQuestionResponseResult },
  };
};
