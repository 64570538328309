import React, { FC } from 'react';
import { CreateTemplate } from 'sub-components/audits/template';
import DashboardContainer from '../../../sub-components/DashboardContainer';

interface IProps {}

const CreateTemplatePage: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <CreateTemplate />
    </DashboardContainer>
  );
};

export default CreateTemplatePage;
