import { Center, Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { HeaderColors } from 'shared/headerColors';
import { SessionDetailEntity } from 'sub-components/audits/take-audit/types';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { SearchInput } from 'atoms';
import { useUserDataSelector } from 'hooks';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import SummarySection from './SummarySection/SummarySection';
import { AuditorSection } from './AuditorSection';
import LocationReportSection from './LocationReportSection';
import { PerformanceAnalytics } from 'pages/Audits/Detail/components/PerformanceAnalytics';
import LocationLineGraph from './LocationLineGraph';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import SpecificLocation from './SpecificLocation';

interface IProps {
  auditData: AuditSessionEntity | undefined;
  refetchHandler?: () => void;
}

const RepeatAuditWithScore: FC<IProps> = ({ auditData, refetchHandler }) => {
  const updateShowSpecificLocationUI = useAuditDetailContext(
    (state) => state.updateShowSpecificLocationUI
  );
  const updateShowLocationsFilter = useAuditDetailContext(
    (state) => state?.updateShowLocationsFilter
  );

  const updateSelectedLocationFilter = useAuditDetailContext(
    (state) => state?.updateSelectedLocationFilter
  );

  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );

  useEffect(() => {
    updateShowSpecificLocationUI(true);
    updateShowLocationsFilter(true);
    updateSelectedLocationFilter({
      label: 'All locations',
      value: 'allLocations',
    });
  }, [auditData]);

  if (
    selectedLocationFilter &&
    selectedLocationFilter?.value !== 'allLocations'
  ) {
    return <SpecificLocation auditData={auditData} />;
  }

  return (
    <Flex gap={4} flexDir='column' w='full'>
      <Flex gap={4} justify='space-between' h='316px'>
        <Flex w='33%'>
          <SummarySection auditData={auditData} />
        </Flex>
        <Flex w='33%'>
          <PerformanceAnalytics auditData={auditData} />
        </Flex>
        <Flex w='33%'>
          <AuditorSection auditData={auditData} />
        </Flex>
      </Flex>
      <Flex w='full'>
        <LocationLineGraph auditData={auditData} />
      </Flex>
      <LocationReportSection
        auditData={auditData}
        refetchHandler={refetchHandler}
      />
    </Flex>
  );
};

RepeatAuditWithScore.displayName =
  'sub-components/audits/detail/components/Overview/components/RepeatAuditWithScore';

export default RepeatAuditWithScore;
