import React from 'react';
import {
  Button as ChakraButton,
  ButtonProps as NativeProps,
  forwardRef,
} from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { AmplitudeEvent, deployEvent } from '../shared';

export interface ButtonProps extends NativeProps {
  event?: AmplitudeEvent;
}

const Button = forwardRef<ButtonProps, 'button'>(({ event, ...props }, ref) => {
  const clickLog = () => {
    event && deployEvent(event);
  };

  return (
    <ChakraButton
      fontSize='15px'
      iconSpacing='8px'
      {...props}
      onClick={callAll(clickLog, props.onClick)}
      ref={ref}
    />
  );
});

Button.displayName = 'atoms/Button';

export default Button;
