import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  OpenStepHeader,
  SaveNextButton,
  useStepperIsOpen,
} from '../common';
import SchedulerStepper from './SchedulerStepper';
import SchedulerForm from './SchedulerForm';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from '../task.types';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { useParams } from 'react-router-dom';

interface IProps {
  isEdit?: boolean;
}

const Scheduler: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['task']);
  const { taskId } = useParams<{ taskId?: string }>();
  const isOpen = useStepperIsOpen('scheduler');
  const { handleSubmit, getValues, setValue } = useFormContext<IFormInput>();

  const saveAndNext = () => {
    setValue('completed.scheduler', true);
    setValue('filled.scheduler', true);
    setValue('currentStep', 'taskSteps');
    deployEvent(AmplitudeEventNames.SCHEDULE_SAVE, {
      task_id: taskId || '',
      frequency: getValues('taskRunFor'),
      task_due: getValues('taskDue'),
      task_due_date: getValues('taskDueDate'),
      task_due_time: getValues('dueTime'),
      publish_task: '',
      publish_date: '',
      publish_time: '',
      task_edit_funnel_id: 5,
      create_task_funnel_id: 4,
    });
  };

  if (!isOpen) {
    return <SchedulerStepper />;
  }

  return (
    <Container flexDir='column' p='20px'>
      <OpenStepHeader title={t('task:schedule')} />

      <SchedulerForm isEdit={isEdit} />

      <SaveNextButton onClick={handleSubmit(saveAndNext)} />
    </Container>
  );
};

export default Scheduler;
