import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouteState } from '../../../../../routes';
import { useAuditTabContext } from '../../store/context';
import { Filters } from '../components/DropdownFilter/types';
import { useProcessData } from '../utils/useProcessData';
import { useServiceLayer } from './useServiceLayer';

const startOfUTCDate = (date: Date): Date => {
  const d = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  );
  return d;
};

const endOfUTCDate = (date: Date): Date => {
  const d = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59,
      59,
      999
    )
  );
  return d;
};

const getNextUTCWeekMonday = (date: Date): Date => {
  const d = new Date(date);
  const day = d.getUTCDay();
  const diff = (8 - day) % 7;
  d.setUTCDate(d.getUTCDate() + diff);
  return startOfUTCDate(d);
};

const getNextUTCWeekSunday = (date: Date): Date => {
  const monday = getNextUTCWeekMonday(date);
  const sunday = new Date(monday);
  sunday.setUTCDate(monday.getUTCDate() + 6);
  return endOfUTCDate(sunday);
};

const isFiltersEqual = (a: Filters, b: Filters): boolean => {
  if (a.dueDate !== b.dueDate) return false;

  if (a.locations.length !== b.locations.length) return false;

  for (let i = 0; i < a.locations.length; i++) {
    if (a.locations[i] !== b.locations[i]) return false;
  }

  return true;
};

export const useControlLayer = () => {
  const service = useServiceLayer();
  const auditService = service?.auditsSessionList;
  const myAuditSearchQuery = useAuditTabContext(
    (state) => state?.myAuditSearchQuery
  );
  const { state } = useRouteState('audit', {
    selectedTab: 'supervisedByMe',
  });
  const myAuditData =
    auditService.getAuditSessionListResult.data?.AuditSessionPagination;
  const updateMyAuditData = useAuditTabContext(
    (state) => state.updateMyAuditData
  );

  const [filters, setFilters] = useState<Filters>({
    dueDate: null,
    locations: [],
  });
  const clearAllFilters = () => {
    setFilters({
      dueDate: null,
      locations: [],
    });
  };

  const previousFiltersRef = useRef<Filters>(filters);

  const getDateFilter = (
    dueDate: Filters['dueDate']
  ): { startDate?: string; endDate?: string } => {
    const today = new Date();

    switch (dueDate) {
      case 'dueToday': {
        const start = startOfUTCDate(today).toISOString();
        const end = endOfUTCDate(today).toISOString();
        return { startDate: start, endDate: end };
      }
      case 'dueTomorrow': {
        const tomorrow = new Date(
          Date.UTC(
            today.getUTCFullYear(),
            today.getUTCMonth(),
            today.getUTCDate() + 1
          )
        );
        const start = startOfUTCDate(tomorrow).toISOString();
        const end = endOfUTCDate(tomorrow).toISOString();
        return { startDate: start, endDate: end };
      }
      case 'dueNextWeek': {
        const nextMon = getNextUTCWeekMonday(today);
        const nextSun = getNextUTCWeekSunday(today);
        return {
          startDate: nextMon.toISOString(),
          endDate: nextSun.toISOString(),
        };
      }
      case 'noDueDate':
      case null:
      default:
        return { startDate: undefined, endDate: undefined };
    }
  };

  const getAuditsListFn = () => {
    auditService.getAuditSessionList({
      variables: {
        page: 1,
        perPage: 1000,
        filter: {
          isAssigned: true,
          ...(myAuditSearchQuery ? { query: myAuditSearchQuery } : {}),
          ...(filters?.locations?.length > 0
            ? { locations: filters?.locations }
            : {}),
          ...(filters?.dueDate ? getDateFilter(filters?.dueDate) : {}),
        },
        locationIds: filters?.locations,
      },
    });
  };

  const handleFiltersUpdateOnPopoverClose = useCallback(() => {
    const prevFilters = previousFiltersRef.current;
    const currentFilters = filters;

    const filtersChanged = !isFiltersEqual(prevFilters, currentFilters);

    if (filtersChanged) {
      getAuditsListFn();
      previousFiltersRef.current = { ...currentFilters };
    }
  }, [filters, getAuditsListFn]);

  let processedData = useProcessData();
  useEffect(() => {
    if (state?.selectedTab === 'myAudits') {
      getAuditsListFn();
    }
  }, [state?.selectedTab, myAuditSearchQuery]);

  useEffect(() => {
    if ((myAuditData?.items || [])?.length > 0) {
      let res = processedData({
        data: myAuditData?.items || [],
      });
      updateMyAuditData(res);
    } else {
      updateMyAuditData([]);
    }
  }, [auditService.getAuditSessionListResult]);

  const refetchHandler = () => {
    service?.auditsSessionList?.getAuditSessionListResult?.refetch?.();
  };

  return {
    filters,
    loading: service?.auditsSessionList?.getAuditSessionListResult?.loading,
    searchQuery: myAuditSearchQuery,
    clearAllFilters,
    handleFiltersUpdateOnPopoverClose,
    refetchHandler,
    setFilters,
  };
};
