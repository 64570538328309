import { Flex, Link, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { FC } from 'react';
import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FILENAME_REGEX } from 'utils/constants';

interface IProps {
  answer?: any;
  url?: any;
}

const ImageUpload: FC<IProps> = ({ answer, url }) => {
  return (
    <Flex
      direction={'column'}
      alignItems={'flex-end'}
      justifyContent={'flex-start'}
      gap={2}
    >
      {url?.map((item: any, index: number) => {
        if (item) {
          answer = item?.split(FILENAME_REGEX).pop();
        }

        return (
          <div key={index}>
            <Flex
              width={'max-content'}
              padding={'8px 16px'}
              borderRadius={'8px'}
              backgroundColor={'#DCF5FF'}
              gap={2}
              alignItems={'center'}
              alignSelf={'flex-end'}
              cursor={'pointer'}
              onClick={() =>
                item && window.open(item, '_blank', 'noopener noreferrer')
              }
            >
              <FontAwesomeIcon icon={faImage as IconProp} />
              <Tooltip label={answer ? answer : item}>
                <Link href={item} isExternal textDecoration={'underline'}>
                  {answer
                    ? answer?.length > 30
                      ? answer.substring(0, 30) + '...'
                      : answer
                    : item?.length > 30
                    ? item.substring(0, 30) + '...'
                    : item}
                </Link>
              </Tooltip>
            </Flex>
          </div>
        );
      })}
    </Flex>
  );
};

export default ImageUpload;
