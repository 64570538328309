import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import {
  ApolloProvider,
  useApolloClient,
  useReactiveVar,
} from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LocationState } from 'history';
import { useTranslation } from 'react-i18next';

import ModalContent from './ModalContent';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../../../../ui-components';
import DashboardDataProvider from '../../../../../../../DashboardContainer/DashboardStore/DashboardData/DashboardDataProvider';
import { HeaderColors } from 'shared/headerColors';

export interface AuditorDetails {
  name: string;
  eid: string;
}

interface IProps {
  auditorDetails?: AuditorDetails;
}

type ISendReminder = (props: IProps) => void;

export const useSendReminder = (): ISendReminder => {
  const { t } = useTranslation(['chapter']);
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ auditorDetails } = {}) => {
    const onCloseHandler = () => {
      confirmRef?.current?.destroy();
    };

    const onSuccessHandler = () => {
      confirmRef?.current?.destroy();
    };

    const onErrorHandler = () => {
      confirmRef?.current?.destroy();
    };

    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>
            <DashboardDataProvider>{children}</DashboardDataProvider>
          </ApolloProvider>
        );
      },
      size: '3xl',
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader
              title='Send reminder by chat'
              color={HeaderColors.Purple}
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ModalContent
          auditorDetails={auditorDetails}
          onClose={onCloseHandler}
          onSuccess={onSuccessHandler}
          onError={onErrorHandler}
        />
      ),
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      isCentered: false,
      footer: null,
    });
  }, []);
};
