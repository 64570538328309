import { IAmplitudeEventNamesEntity } from './amplitude-events-types';
import amplitude from 'amplitude-js';
import mixpanel from 'mixpanel-browser';
import { userObj } from 'sop-commons/src/client';
import { CommonEventType } from 'sop-commons/Events/CommonEvents';

const getEventInfo = (
  event: IAmplitudeEventNamesEntity | CommonEventType,
  params?: Record<string, unknown>
) => {
  if (typeof event === 'object') {
    return {
      eventName: event.value,
      eventProperties: { ...event.additional_info, ...params },
    };
  }
  return {
    eventName: event,
    eventProperties: params,
  };
};

export const deployEvent = (
  event: IAmplitudeEventNamesEntity | CommonEventType,
  properties?: Record<string, any>
): void => {
  let loggedInUserDetails = userObj();

  const { eventName, eventProperties } = getEventInfo(event, properties);

  if (
    process?.env?.REACT_APP_DEPLOYMENT_TYPE &&
    process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'production' &&
    loggedInUserDetails?.entity?.analytics
  ) {
    if (sessionStorage?.getItem('amplitudeInit') === '1') {
      amplitude.getInstance().logEvent(eventName, eventProperties);
    }
    if (sessionStorage?.getItem('mixPanelInit') === '1') {
      mixpanel?.track(eventName, eventProperties);
    }
  } else {
    console.log({
      dep_type: process?.env?.REACT_APP_DEPLOYMENT_TYPE,
      node_env: process?.env?.NODE_ENV,
      eventName,
      REACT_APP_DEPLOYMENT_TYPE: process?.env?.REACT_APP_DEPLOYMENT_TYPE,
      NODE_ENV: process?.env?.NODE_ENV,
    });
    if (process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'development') {
      console.log('deployEvent --> eventName = ', eventName, eventProperties);
    }
  }
};
