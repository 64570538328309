import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';

import { useSafeState } from '../../../../hooks';
import { Pagination } from '../../../../atoms';

import { TemplateLibraryContainer } from './template-library';
import {
  GET_AUDIT_TEMPLATES,
  TemplateResponse,
  TemplateVariable,
} from '../query/audits-listing.graphql';
import LibraryHeader from './LibraryHeader';

const pageSize = 8;

const TemplateLibrary: FC = () => {
  const mounted = useRef(false);
  const [selectedView, setSelectedViewType] = useState<'grid' | 'list'>('grid');

  const [allAuditTemplates, updateData] =
    useSafeState<TemplateResponse['AuditTemplatePagination']>();

  const [currentPage, setCurrentPage] = useSafeState(1);
  const [searchQuery, setSearchQuery] = useSafeState('');

  const [auditTemplates, { loading }] = useLazyQuery<
    TemplateResponse,
    TemplateVariable
  >(GET_AUDIT_TEMPLATES, {
    fetchPolicy: 'network-only',
    onCompleted: (templates) => updateData(templates?.AuditTemplatePagination),
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  const inputVariables = useMemo(() => {
    const inputs: TemplateVariable = {
      page: currentPage,
      perPage: pageSize,
      // sort: getTemplateSortBy(sorter),
      filter: {
        templateType: 'library',
      },
    };

    if (searchQuery?.trim() && inputs['filter']) {
      inputs.page = 1;
      inputs['filter']['query'] = searchQuery.trim();
    }

    return inputs;
  }, [searchQuery]);

  const debouncedFetch = debounce(async (variables) => {
    auditTemplates({
      variables: variables,
    });
  }, 300);

  useEffect(() => {
    if (!mounted.current) {
      auditTemplates({
        variables: {
          page: 1,
          perPage: pageSize,
          // sort: getTemplateSortBy(sorter),
          filter: {
            templateType: 'library',
          },
        },
      });
      setTimeout(() => {
        mounted.current = true;
      });
    }
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return undefined;
    }

    debouncedFetch(inputVariables);

    return () => debouncedFetch.cancel();
  }, [inputVariables]);

  const onChange = (page: number) => {
    setCurrentPage(page);
    auditTemplates({
      variables: {
        ...inputVariables,
        page: page,
      },
    });
  };

  return (
    <Box>
      <LibraryHeader
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        viewType={selectedView}
        onViewChange={setSelectedViewType}
      />
      <TemplateLibraryContainer
        allAuditTemplates={allAuditTemplates?.items}
        loading={loading}
        selectedViewType={selectedView}
        searchActive={!!searchQuery}
      />

      <Flex justify='flex-end' mt={4}>
        <Pagination
          pageSize={pageSize}
          current={currentPage}
          total={allAuditTemplates?.count}
          onChange={onChange}
        />
      </Flex>
    </Box>
  );
};

export default TemplateLibrary;
