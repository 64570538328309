import { Box, Flex, Progress, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClockThree, faRepeat } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Outliner from './Outliner';
import { ProcessData } from '../../../types';
import SectionFilter from './SectionFilter';
import { filterOptions } from './filter-options';
import { SelectOption } from 'atoms';
import AuditAssignee from './AuditAssignee';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import AuditCard from './AuditCard';

interface IProps {
  data: ProcessData[];
  refetchHandler?: () => void;
}

const OnGoing: FC<IProps> = ({ data, refetchHandler }) => {
  const entityUsers = useReactiveVar(usersEntityObj);

  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | undefined
  >(undefined);

  const filteredOngoingData = useMemo(() => {
    const ongoingData =
      data?.filter((ongoing) => ongoing?.auditStatus === 'ongoing') || [];
    if (!selectedFilter?.value) {
      return ongoingData;
    }
    let sortedData = [...ongoingData];
    switch (selectedFilter?.value) {
      case 'dueDateFirst':
        sortedData.sort((a, b) => {
          const today = moment();

          const dueDateA = a.dueDate ? moment.utc(a.dueDate) : null;
          const dueDateB = b.dueDate ? moment.utc(b.dueDate) : null;

          const diffA = dueDateA ? Math.abs(dueDateA.diff(today)) : Infinity;
          const diffB = dueDateB ? Math.abs(dueDateB.diff(today)) : Infinity;

          return diffA - diffB;
        });
        break;
      case 'nameDesc':
        sortedData.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'nameAsc':
        sortedData.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'frequency':
        // Implement sorting by frequency if needed
        break;
      default:
        break;
    }
    return sortedData;
  }, [data, selectedFilter]);

  const handleFilterChange = (filter: SelectOption) => {
    setSelectedFilter((prevVal) =>
      prevVal?.value === filter.value ? undefined : filter
    );
  };

  const titleRender = (ongoing: ProcessData) => {
    return (
      <Tooltip label={ongoing?.title} hasArrow placement='top'>
        <Text fontWeight={600} isTruncated maxW='190px'>
          {ongoing?.title}
        </Text>
      </Tooltip>
    );
  };

  const scheduleRender = (ongoing: ProcessData) => {
    return (
      <Flex mt='4px' align='center' gap='2px' color='#4D4D4D' fontSize='10px'>
        <FontAwesomeIcon icon={faClockThree as IconProp} />
        {moment(ongoing?.dueDate)?.isValid() ? (
          <Text>
            Expires on {moment.utc(ongoing?.dueDate)?.format('MMM YY')}
          </Text>
        ) : (
          <Text>No Deadline</Text>
        )}
        <>
          <Box>&bull;</Box>
          <FontAwesomeIcon icon={faRepeat as IconProp} />
          <Text textTransform='capitalize' isTruncated maxW='80px'>
            {ongoing?.repeatStatus || 'Does not repeat'}
          </Text>
        </>
      </Flex>
    );
  };

  const footerRender = (ongoing: ProcessData) => {
    return (
      <Flex w='full' align='center' gap='5px'>
        <Box fontWeight={600} fontSize='12px' minW='100px'>
          {
            ongoing?.assignments?.filter((assignment) => assignment?.startedBy)
              ?.length
          }
          /{ongoing?.assignments?.length} completed
        </Box>
        <Flex w='full' align='center' gap='10px'>
          <Progress
            colorScheme='yellow'
            size='sm'
            value={
              ongoing?.assignments?.filter(
                (assignment) => assignment?.startedBy
              )?.length || 0
            }
            max={ongoing?.assignments?.length}
            w='full'
            borderRadius='7px'
          />
          <AuditAssignee
            assignees={entityUsers?.filter((user) =>
              ongoing?.auditors?.some((auditor) => auditor?.eid === user?.eid)
            )}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex flexDir='column' gap={4} minW='244px' maxW='244px'>
      <Outliner bg='rgba(255, 216, 141, 1)'>
        <Flex align='center'>
          <Text fontWeight={600} fontSize='14px'>
            Ongoing
          </Text>
          &nbsp;
          <Text fontWeight={600} fontSize='14px'>
            ({filteredOngoingData?.length})
          </Text>
        </Flex>
        {filteredOngoingData?.length > 0 && (
          <SectionFilter
            options={filterOptions}
            value={selectedFilter}
            handleOptionClick={handleFilterChange}
          />
        )}
      </Outliner>
      {filteredOngoingData?.map((ongoing, index) => (
        <AuditCard
          key={ongoing?.eid}
          auditData={ongoing}
          auditTitle={titleRender(ongoing)}
          scheduleDetails={scheduleRender(ongoing)}
          footerDetails={footerRender(ongoing)}
          refetchHandler={refetchHandler}
        />
      ))}
    </Flex>
  );
};

export default OnGoing;
