import { gql } from '@apollo/client';

export const AUDIT_QUESTION_DETAILS = gql`
  query AuditQuestionsPaginated($filter: FilterFindManyAuditSessionInput) {
    AuditSessionPagination(filter: $filter) {
      items {
        audit {
          repeatDetails
        }
        name
        eid
        title
        task {
          title
          description
          eid
          audit {
            auditId
            sessionId
            qid
          }
        }
        dueDate
        startDate
        isCompleted
        createdAt
        entityId
        updatedAt
        description
        auditId
        questions
        assignments {
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          startedBy
          totalScore
          response
        }
      }
    }
  }
`;

export const AUDIT_QUESTION_DETAILS_BY_SESSION_ID = gql`
  query AuditQuestionBySessionId($eid: String!) {
    AuditSessionById(eid: $eid) {
      audit {
        repeatDetails
      }
      name
      eid
      title
      task {
        title
        description
        eid
        lastActiveSession {
          eid
          dueDate
          title
        }
        audit {
          auditId
          sessionId
          qid
        }
      }
      dueDate
      startDate
      isCompleted
      createdAt
      entityId
      updatedAt
      description
      auditId
      questions
      assignments {
        auditors
        completedAt
        completedBy
        completedPercent
        isCompleted
        locationId
        startedBy
        totalScore
        response
      }
    }
  }
`;

export const AUDIT_QUESTION_CYCLES = gql`
  query AuditCycles(
    $filter: FilterFindManyAuditSessionInput
    $sort: SortFindManyAuditSessionInput
    $page: Int
    $perPage: Int
  ) {
    AuditSessionPagination(
      filter: $filter
      sort: $sort
      page: $page
      perPage: $perPage
    ) {
      items {
        eid
        dueDate
        startDate
      }
    }
  }
`;
