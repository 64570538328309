import React, { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IconImage } from '../../../../ui-components';

type MoreDetailsProps = {
  heading: string;
  details: string;
};

const MoreDetails: FC<MoreDetailsProps> = ({ heading, details }) => {
  return (
    <Box
      borderTop='1px solid #EFEFEF'
      display='inline-block'
      padding='16px 20px'
      borderBottomRadius={heading === 'Conducted by' ? '12px' : undefined}
    >
      <Flex direction='column' justifyContent='center' gap={2}>
        <Text
          lineHeight='20px !important'
          fontWeight={500}
          color='#6F767E'
          fontSize='15px'
        >
          {heading}
        </Text>
        {heading === 'Conducted by' ? (
          <Flex alignItems={'center'} gap={2}>
            {details !== '--' && (
              <IconImage
                name={details}
                thumbnail={undefined}
                boxSize={24}
                borderRadius='50%'
              />
            )}
            <Box fontWeight={500} fontSize='20px' lineHeight='25px'>
              {details}
            </Box>
          </Flex>
        ) : (
          <Box fontWeight={500} fontSize='20px' lineHeight='25px'>
            {details}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default MoreDetails;
