import { Flex } from '@chakra-ui/react';
import DropdownFilter from './DropdownFilter/DropdownFilter';
import { SearchInput } from 'atoms';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { useAuditTabContext } from '../../store/context';
import { Filters } from './DropdownFilter/types';
import debounce from 'lodash.debounce';

interface SearchFilterProps {
  filters: Filters;
  clearAllFilters: () => void;
  onPopoverClose: () => void;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const SearchFilter: FC<SearchFilterProps> = ({
  filters,
  clearAllFilters,
  onPopoverClose,
  setFilters,
}) => {
  const searchQuery = useAuditTabContext((state) => state.myAuditSearchQuery);
  const updateMyAuditSearchQuery = useAuditTabContext(
    (state) => state.updateMyAuditSearchQuery
  );
  const updateChipFilters = useAuditTabContext(
    (state) => state.updateAuditChipsFilter
  );

  const [searchInput, setSearchInput] = useState<string>(searchQuery);

  const debouncedUpdateSearchQuery = useCallback(
    debounce((value: string) => {
      updateMyAuditSearchQuery(value);
    }, 1000),
    [updateMyAuditSearchQuery]
  );

  useEffect(() => {
    debouncedUpdateSearchQuery(searchInput);

    return () => {
      debouncedUpdateSearchQuery.cancel();
    };
  }, [searchInput, debouncedUpdateSearchQuery]);

  useEffect(() => {
    setSearchInput(searchQuery);
  }, [searchQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    updateChipFilters([]);
  };

  return (
    <Flex w='full' gap={4} justify='space-between'>
      <SearchInput
        hideShortcuts
        width='full'
        placeholder='Search audit name'
        value={searchInput}
        onChange={handleChange}
      />
      <DropdownFilter
        filters={filters}
        setFilters={setFilters}
        clearAllFilters={clearAllFilters}
        onPopoverClose={onPopoverClose}
      />
    </Flex>
  );
};

SearchFilter.displayName =
  'sub-components/audits/audit-tab/my-audits/components/SearchFilter';

export default SearchFilter;
