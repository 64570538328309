import React, { FC, useEffect } from 'react';
import { Center } from '@chakra-ui/react';

import { AppRoute, useHistory } from '../../../routes';
import Loader from '../../Loader';
import { useCreateTemplate } from './useCreateTemplate';

interface IProps {}

const CreateTemplate: FC<IProps> = () => {
  const history = useHistory();

  const [createTemplate] = useCreateTemplate({
    onCompleted: (data) => {
      history.replace({
        pathname: AppRoute.EDIT_AUDIT_TEMPLATE,
        params: {
          templateId: data.CreateAuditTemplate.eid,
        },
        state: {
          // @ts-ignore
          ...(history?.location?.state || {}),
          data: {
            eid: data.CreateAuditTemplate.eid,
          },
          isCreation: true,
        },
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    createTemplate({
      variables: {
        input: {
          title: '',
          status: 'inactive',
          categoryId: '2l954lxdx3qwi897ox92aqa4',
        },
      },
    });
  }, []);

  return (
    <Center boxSize='full'>
      <Loader size='lg' />
    </Center>
  );
};

export default CreateTemplate;
