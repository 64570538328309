import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { useUserData } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import TaskCompletionEmpty from './TaskCompletionEmpty';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  companyAvg: number;
  myLocations: number;
}

const SVGWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    vertical-align: middle;
    overflow: hidden;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the hover element */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;

const TaskCompletionRate: FC<IProps> = ({ companyAvg, myLocations }) => {
  const strokeWidth = 5;
  const userData = useUserData();
  const history = useHistory();
  const [companyAvgOffset, setCompanyAvgOffset] = useState(100);
  const [myLocationsOffset, setMyLocationsOffset] = useState(100);

  const getCircumference = (radius: number) => {
    return Math.PI * 2 * radius;
  };
  useEffect(() => {
    setCompanyAvgOffset(companyAvg);
    setMyLocationsOffset(myLocations);
  }, [companyAvg, myLocations]);

  const getStrokeDashOffset = (radius: number, percentage: number) => {
    const circumference = getCircumference(radius);
    const unfilled = ((100 - percentage) / 100) * circumference;
    return unfilled;
  };

  const getOuterLabel = () => {
    return 'Company Avg';
  };

  const getInnerLabel = () => {
    if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(userData?.authRole)) {
      return 'Best location';
    } else if (AuthRole.LOCATION_OWNER === userData?.authRole) {
      return 'My locations';
    } else {
      return 'My rate';
    }
  };

  const navigateHandler = () => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER]?.includes(
        userData?.authRole
      ) &&
      userData?.type === 'user' &&
      (companyAvg > 0 || myLocations > 0)
    ) {
      deployEvent(AmplitudeEventNames.TASKS_PAGE_VISIT, {
        from_where: 'Home Page',
      });
      history.push('/tasks/dashboard');
    }
  };

  return (
    <Flex
      id='task-completion-rate-flex-1'
      flexDir='column'
      gap={4}
      h='full'
      w='full'
      onClick={navigateHandler}
      cursor={companyAvg > 0 || myLocations > 0 ? 'pointer' : 'no-drop'}
    >
      <GenericColorHeader
        title='Tasks completion rate'
        color={HeaderColors.Purple}
      />
      {companyAvg > 0 || myLocations > 0 ? (
        <SVGWrapper id='svg-wrapper'>
          <div className='svg-container'>
            <svg
              width='100%'
              height='100%'
              viewBox='0 0 120 120'
              style={{ transition: 'stroke-dashoffset 1s ease' }}
            >
              {/* Outer circle (grey background) */}
              <g>
                <circle
                  cx='60'
                  cy='60'
                  r='56'
                  stroke='#e5e5e5'
                  strokeWidth={strokeWidth}
                  fill='none'
                  cursor='pointer'
                />
                <title>
                  {getOuterLabel()}:{' '}
                  <tspan fontWeight='bold'>{companyAvg}%</tspan>
                </title>
              </g>

              {/* Blue progress bar (Company avg) with tooltip */}
              <g className='tooltip'>
                <circle
                  cx='70'
                  cy='60'
                  r='56'
                  stroke='#2A85FF'
                  strokeWidth={strokeWidth}
                  fill='none'
                  strokeLinecap='round'
                  strokeDasharray={getCircumference(56)}
                  strokeDashoffset={getStrokeDashOffset(56, companyAvg)}
                  transform='rotate(-90 65 65)'
                  cursor='pointer'
                />
                <title>
                  {getOuterLabel()}:{' '}
                  <tspan fontWeight='bold'>{companyAvg}%</tspan>
                </title>
                {/* <text className='tooltiptext'>Company avg: 78%</text> */}
              </g>

              {/* Inner circle (grey background) */}
              <g>
                <circle
                  cx='60'
                  cy='60'
                  r='48'
                  stroke='#e5e5e5'
                  strokeWidth={strokeWidth}
                  fill='none'
                  cursor='pointer'
                />
                <title>
                  {getInnerLabel()}:{' '}
                  <tspan fontWeight='bold'>{myLocations}%</tspan>
                </title>
              </g>

              {/* Green progress bar (My locations) with tooltip */}
              <g className='tooltip'>
                <circle
                  cx='60'
                  cy='60'
                  r='48'
                  stroke='#83BF6E'
                  strokeWidth={strokeWidth}
                  fill='none'
                  strokeLinecap='round'
                  strokeDasharray={getCircumference(48)}
                  strokeDashoffset={getStrokeDashOffset(48, myLocations)}
                  transform='rotate(90 60 60)'
                  cursor='pointer'
                />
                <title>
                  {getInnerLabel()}:{' '}
                  <tspan fontWeight='bold'>{myLocations}%</tspan>
                </title>
                {/* <text className='tooltiptext'>My locations: 92%</text> */}
              </g>

              {/* Blue Key Circle */}
              <circle cx='25' cy='53' r='2' fill='#2A85FF' cursor='pointer' />
              <text
                x='30'
                y='60'
                fill='#000'
                fontSize='6'
                textAnchor='start'
                dy='-5'
              >
                {getOuterLabel()}:{' '}
                <tspan fontWeight='bold'>{companyAvg}%</tspan>
              </text>

              {/* Green Key Circle */}
              <circle cx='25' cy='63' r='2' fill='#83BF6E' cursor='pointer' />
              <text
                x='30'
                y='50'
                fill='#000'
                fontSize='6'
                textAnchor='start'
                dy='15'
              >
                {getInnerLabel()}:{' '}
                <tspan fontWeight='bold'>{myLocations}%</tspan>
              </text>
            </svg>
          </div>
        </SVGWrapper>
      ) : (
        <TaskCompletionEmpty />
      )}
    </Flex>
  );
};
export default TaskCompletionRate;
