import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
} from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import SearchInput from '../../../atoms/SearchInput';
import Dropdown, { SelectOption } from '../../../atoms/Dropdown';
import {
  FieldType,
  ICityStateList,
  ILocationStatusEntity,
  ISelectedFilterTab,
} from '../locations/LocationListContainer';
import { TeamTabType } from '../useActiveTeamTab';
import LocationStatusTabs from './LocationStatusTabs';
import LocationFilter from './LocationFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns3 } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import FieldItem from './FieldItem';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Loader } from 'sub-components';
import {
  GET_LOCATION_TABLE_FIELDS,
  UPDATE_LOCATION_TABLE_FIELD_VISIBILITY,
} from '../locations/locations.graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useUserDataSelector } from 'hooks';
import EmptyState from './EmptyState';

interface IProps {
  activeTab?: TeamTabType;
  cityStateList: ICityStateList;
  columnMetaLoading: boolean;
  fieldsData: FieldType[];
  filterByCategory: SelectOption<string>[];
  initialCityStateList: ICityStateList;
  locationStatus?: ILocationStatusEntity;
  searchQuery?: string;
  selectedFilterTab?: ISelectedFilterTab;
  showLocationFilter?: boolean;
  sortBy?: SelectOption | null;
  locationFilterChangeHandler: (option: SelectOption<string>[]) => void;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
  onSortChange?: (value: SelectOption | null) => void;
  setCityStateList: React.Dispatch<React.SetStateAction<ICityStateList>>;
  setFieldsData: React.Dispatch<React.SetStateAction<FieldType[]>>;
  setInitialCityStateList: React.Dispatch<React.SetStateAction<ICityStateList>>;
  setSelectedFilterTab?: React.Dispatch<
    React.SetStateAction<ISelectedFilterTab>
  >;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
  updateTableColumnsHandler: (columns: FieldType[]) => void;
}

type APIFieldType = {
  field: string;
  visibility: boolean;
};

const SearchFilterHeader: FC<IProps> = ({
  activeTab,
  cityStateList,
  columnMetaLoading,
  fieldsData,
  filterByCategory,
  initialCityStateList,
  locationStatus,
  searchQuery,
  selectedFilterTab,
  showLocationFilter = false,
  sortBy,
  locationFilterChangeHandler,
  onSearch,
  onSortChange,
  setCityStateList,
  setFieldsData,
  setInitialCityStateList,
  setSelectedFilterTab,
  setSelectedPage,
  updateTableColumnsHandler,
}) => {
  const { t } = useTranslation(['common', 'handbook']);
  const [fieldSearchQuery, setFieldSearchQuery] = useState<string>('');
  const [fieldsFromAPI, setFieldsFromAPI] = useState<APIFieldType[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  const filteredFieldData = useMemo(() => {
    return fieldsData?.filter((item) =>
      item?.field?.toLowerCase().includes(fieldSearchQuery?.toLowerCase())
    );
  }, [fieldsData, fieldSearchQuery]);

  console.log('filtered data --- ', filteredFieldData);

  const _onSortChange = useCallback(
    (option: SelectOption) => {
      if (option?.value === sortBy?.value) {
        onSortChange?.(null);
      } else {
        onSortChange?.(option);
      }
    },
    [sortBy, onSortChange]
  );

  const handleDragEnd = (e: any) => {
    const { active, over } = e;
    if (over && active?.id !== over?.id) {
      let sortedColumns: FieldType[] = [];
      setFieldsData((items) => {
        const oldIndex = items?.findIndex((item) => item?.id === active?.id);
        const newIndex = items?.findIndex((item) => item?.id === over?.id);
        sortedColumns = arrayMove(items, oldIndex, newIndex);
        updateTableColumnsHandler(sortedColumns);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  function rearrangeArrayItems(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const prepareDataToUpdate = (
    field: string,
    item: FieldType,
    visibility: boolean,
    isDisabled: boolean
  ) => {
    if (isDisabled) return;
    console.log('preparing data', field, item, visibility);
    let tempIndex = -1;

    fieldsData?.forEach((item, index) => {
      if (item?.field === field) {
        tempIndex = index;
        return item;
      }
    });

    let updatedFields = fieldsData?.filter((item) => item?.field !== field);
    updatedFields = [
      ...updatedFields,
      { id: item?.id, field, visibility, mandatory: item?.mandatory },
    ];
    console.log('updated fields : ', updatedFields);
    tempIndex !== -1 &&
      rearrangeArrayItems(updatedFields, updatedFields?.length - 1, tempIndex);
    setFieldsData(updatedFields);
    if (tempIndex !== -1) {
      updateTableColumnsHandler(updatedFields);
    }
  };

  return (
    <Flex
      justify={activeTab === 'locations' ? 'space-between' : 'flex-end'}
      gap='12px'
      mb='8px'
      flexDir='column'
    >
      {activeTab === 'locations' && (
        <LocationStatusTabs
          initialCityStateList={initialCityStateList}
          cityStateList={cityStateList}
          selectedFilterTab={selectedFilterTab}
          setSelectedFilterTab={setSelectedFilterTab}
          setInitialCityStateList={setInitialCityStateList}
          setCityStateList={setCityStateList}
          setSelectedPage={setSelectedPage}
          locationStatus={locationStatus}
        />
      )}
      <Flex align='center' gap='10px' justify='space-between'>
        <SearchInput
          disabled={true}
          size='md'
          width='400px'
          hideShortcuts={true}
          placeholder={t('common:search')}
          onChange={onSearch}
          value={searchQuery}
        />
        <Flex justifyContent={'space-evenly'} gap={4}>
          <LocationFilter
            filterByCategory={filterByCategory}
            showFilter={showLocationFilter}
            locationFilterChangeHandler={locationFilterChangeHandler}
          />
          <Flex gap='12px'>
            <Box width='210px' zIndex='100'>
              <Dropdown
                placeholder={t('handbook:sort')}
                value={sortBy}
                onChange={_onSortChange}
                options={[
                  // {
                  //   label: t('handbook:filter.last_updated'),
                  //   value: 'Last Updated',
                  // },
                  {
                    label: t('handbook:filter.last_created'),
                    value: 'Last Created',
                  },
                  {
                    label: t('handbook:filter.alphabetically'),
                    value: 'Alphabetically',
                  },
                  // { label: t('handbook:filter.active'), value: 'Active' },
                  // { label: t('handbook:filter.inactive'), value: 'Inactive' },
                ]}
                selectStyles={{
                  singleValue: {
                    color: '#6f767e',
                    fontWeight: '600',
                  },
                }}
                components={{
                  SingleValue: (valProps) => {
                    return (
                      <chakraComponents.SingleValue {...valProps}>
                        <Trans
                          t={t}
                          i18nKey='handbook:sort_by'
                          values={{
                            value: valProps.children,
                          }}
                        />
                      </chakraComponents.SingleValue>
                    );
                  },
                }}
              />
            </Box>

            {currentUrl?.includes('location') && (
              <Box mr={'1.5rem'} maxW='150px' zIndex='100'>
                <Popover
                  onOpen={() => setIsPopupOpen(true)}
                  onClose={() => setIsPopupOpen(false)}
                  placement='bottom-end'
                >
                  {/* @ts-ignore */}
                  <PopoverTrigger>
                    <Button
                      id='column-btn'
                      leftIcon={
                        <FontAwesomeIcon icon={faColumns3 as IconProp} />
                      }
                      rightIcon={<ChevronDownIcon fontSize='20px' />}
                      style={{
                        borderRadius: '12px',
                        gap: '0.5rem',
                        fontWeight: '200',
                      }}
                      color={isPopupOpen ? '#2A85FF' : 'gray'}
                      border={
                        isPopupOpen ? '2px solid #2A85FF' : '1px solid #EFEFEF'
                      }
                    >
                      Columns
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    width={'240px'}
                    boxShadow={'md'}
                    borderRadius={'20px'}
                    p={3}
                  >
                    <PopoverBody>
                      {columnMetaLoading && (
                        <Box
                          position='absolute'
                          top='0'
                          left='0'
                          width='100%'
                          height='100%'
                          bg='rgba(255, 255, 255, 0.8)'
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          borderRadius='20px'
                          zIndex='200'
                        >
                          <Loader size='lg' color='blue.500' />
                        </Box>
                      )}
                      <Flex gap={4} flexDir={'column'}>
                        <SearchInput
                          disabled={true}
                          size='md'
                          hideShortcuts={true}
                          placeholder={'Search fields'}
                          onChange={(e) => setFieldSearchQuery(e.target.value)}
                          value={fieldSearchQuery}
                        />
                        <Flex
                          height={'38vh'}
                          overflowY={'auto'}
                          flexDir={'column'}
                        >
                          {filteredFieldData?.length > 0 ? (
                            <DndContext
                              onDragEnd={handleDragEnd}
                              modifiers={[restrictToVerticalAxis]}
                            >
                              <SortableContext items={fieldsData}>
                                <Flex gap={2} flexDir={'column'}>
                                  {filteredFieldData?.map((item) => {
                                    return (
                                      <Flex gap={2} key={item?.id}>
                                        <Checkbox
                                          value={item?.field}
                                          isChecked={item?.visibility}
                                          onChange={() => {
                                            prepareDataToUpdate(
                                              item?.field,
                                              item,
                                              !item?.visibility,
                                              !!(
                                                item?.default || item?.mandatory
                                              )
                                            );
                                          }}
                                          disabled={
                                            item?.default || item?.mandatory
                                          }
                                        />
                                        <Box flex='1'>
                                          <FieldItem
                                            key={item?.id}
                                            data={item}
                                            onClickHandler={() => {
                                              prepareDataToUpdate(
                                                item?.field,
                                                item,
                                                !item?.visibility,
                                                !!(
                                                  item?.default ||
                                                  item?.mandatory
                                                )
                                              );
                                            }}
                                          />
                                        </Box>
                                      </Flex>
                                    );
                                  })}
                                </Flex>
                              </SortableContext>
                            </DndContext>
                          ) : (
                            <EmptyState title='column' />
                          )}
                        </Flex>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

SearchFilterHeader.displayName = 'pages/Teams/common/SearchFilterHeader';

export default SearchFilterHeader;
