import {
  useHistory as _useHistory,
  generatePath,
  ExtractRouteParams,
} from 'react-router';
import { Hash, LocationKey, Search, LocationState } from 'history';

interface PushProps<S extends string, State = LocationState> {
  pathname: S;
  params?: ExtractRouteParams<S>;
  search?: Search;
  state?: State;
  hash?: Hash;
  key?: LocationKey;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useHistory<HistoryLocationState = LocationState>() {
  const history = _useHistory<HistoryLocationState>();

  function push<S extends string>(
    path: PushProps<S, HistoryLocationState>,
    state?: HistoryLocationState
  ) {
    const { pathname, params, ...rest } = path;
    history.push(
      {
        pathname: generatePath(pathname, params),
        ...rest,
      },
      state
    );
  }

  function replace<S extends string>(
    path: PushProps<S, HistoryLocationState>,
    state?: HistoryLocationState
  ) {
    const { pathname, params, ...rest } = path;
    history.replace(
      {
        pathname: generatePath(pathname, params),
        ...rest,
      },
      state
    );
  }

  function createHref<S extends string>(
    path: PushProps<S, HistoryLocationState>
  ) {
    const { pathname, params, ...rest } = path;
    return history.createHref({
      pathname: generatePath(pathname, params),
      ...rest,
    });
  }

  return { ...history, push, replace, createHref };
}
