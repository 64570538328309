import React, { FC, useState } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFeatherPointed } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import TitleHeader from '../../../sub-components/CardEditor/TitleHeader';
import PrimaryButton from '../../../atoms/PrimaryButton';

import { FormInput } from '../../../atoms';
import { PushProps } from './types';
import { getQueryParamValue } from 'utils/queryParams';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps extends PushProps {
  category?: string;
}

const CreateFromScratch: FC<IProps> = ({ pushTo, category }) => {
  const { t } = useTranslation(['common', 'chapter']);
  const [chapterTitle, setChapterTitle] = useState<string>('');

  const nameValue = getQueryParamValue('name');
  const typeValue = getQueryParamValue('type');

  const pushToHandler = () => {
    deployEvent(AmplitudeEventNames.TAKE_ME_TO_THE_EDITOR, {
      add_chapter_funnel_id: 5,
      chapter_name: chapterTitle,
    });
    if (nameValue && typeValue) {
      pushTo?.(`/create-chapter?title=${chapterTitle}&status=RESTRICT`, {
        // @ts-ignore
        backToTitle: t('common:backToTitle', {
          value: category || t('common:chapters'),
        }),
      });
    } else {
      pushTo?.(`/create-chapter?title=${chapterTitle}`, {
        // @ts-ignore
        backToTitle: t('common:backToTitle', {
          value: category || t('common:chapters'),
        }),
      });
    }
  };

  return (
    <Flex flexDir='column' gap='12px'>
      <Flex flexDir='column'>
        <TitleHeader title={t('chapter:giveChapterTitle')} />
        <FormInput
          mt={1}
          size='lg'
          placeholder={t('chapter:enterChapterTitle')}
          value={chapterTitle}
          onChange={(e) => setChapterTitle(e?.target?.value)}
        />
      </Flex>
      <PrimaryButton
        title={t('chapter:takeMeToEditor')}
        colorScheme='blue'
        variant='solid'
        size='lg'
        disabled={!chapterTitle?.trim()}
        onClick={() => {
          pushToHandler();
        }}
      />

      <Center minH='200px'>
        <FontAwesomeIcon
          icon={faFeatherPointed as IconProp}
          color='#B1E5FC'
          fontSize='66px'
        />
      </Center>
    </Flex>
  );
};

export default CreateFromScratch;
