import React, { FC } from 'react';
import { Box, Divider, Flex, useBoolean } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-regular-svg-icons';
import { faFlag as faFlagSolid } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import isEmpty from 'lodash/isEmpty';
import { PiNote } from 'react-icons/pi';

import { Any, AuditEvidenceEntity, QuestionType } from '../../../../types';
import { QuestionsState, SectionEntity } from '../types';
import {
  AddEvidenceButton,
  EvidenceProvider,
  EvidenceUpload,
} from './EvidenceUpload';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import QuestionNotes from './QuestionNotes';
import HideComponent from './HideComponent';

interface IProps {
  question: QuestionsState;
  questionIndex: number;
  sectionIndex: number;
}

interface ISectionProps extends IProps {
  index: number;
  children: React.ReactNode;
  saveHandler: (...args: Any) => void;
}

const QuestionSection: FC<ISectionProps> = ({
  index,
  questionIndex,
  question,
  children,
  sectionIndex,
  saveHandler,
}) => {
  const { setValue } = useFormContext<SectionEntity>();

  const [editNote, editNoteAction] = useBoolean(false);

  const noteText = useWatch<SectionEntity, `data.${number}.noteText`>({
    name: `data.${questionIndex}.noteText`,
  });
  const responseId = useWatch<SectionEntity, `data.${number}.responseId`>({
    name: `data.${questionIndex}.responseId`,
  });
  const evidence = useWatch<SectionEntity, `data.${number}.images`>({
    name: `data.${questionIndex}.images`,
  });

  const disableMark =
    isEmpty(responseId) && question.qType === QuestionType.MULTI_CHOICE;

  const onEvidenceChange = (evidences: AuditEvidenceEntity[]) => {
    setValue(`data.${questionIndex}.images`, evidences, {
      shouldValidate: true,
    });
    setTimeout(saveHandler, 100);
  };

  return (
    <Flex
      flexDir='column'
      w='full'
      border='1px solid #EFEFEF'
      borderRadius='8px'
      p={4}
      gap={5}
      bg='white'
    >
      <Flex flexDir='column' gap={4}>
        <TitleHeader
          title={`${index + 1}. ${question?.label}`}
          isRequired={question.isRequired}
          titleStyle={{ fontSize: '16px' }}
        />
        {children}
      </Flex>

      <EvidenceProvider value={evidence} onChange={onEvidenceChange}>
        <Flex
          flexDir='column'
          gap={4}
          _empty={{
            display: 'none',
          }}
        >
          <EvidenceUpload
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />

          <Controller<SectionEntity, `data.${number}.noteText`>
            name={`data.${questionIndex}.noteText`}
            render={({ field }) => {
              return (
                <QuestionNotes
                  value={field.value}
                  onChange={callAll(field.onChange, saveHandler)}
                  closeNoteEdit={editNoteAction.off}
                  editNotes={editNoteAction.on}
                  editNote={editNote}
                />
              );
            }}
          />
        </Flex>

        <Flex
          gap={3}
          align='center'
          color='#33383F'
          justify='end'
          userSelect='none'
        >
          <HideComponent hidden={!(!noteText && !editNote)}>
            <Flex
              gap={1}
              align='center'
              cursor='pointer'
              fontWeight={500}
              onClick={editNoteAction.on}
            >
              <PiNote />
              Add note
            </Flex>

            <Divider
              orientation='vertical'
              height='20px'
              borderColor='#EFEFEF'
            />
          </HideComponent>

          <HideComponent
            hidden={[
              QuestionType.FILE_UPLOAD,
              QuestionType.IMAGE_UPLOAD,
              QuestionType.VIDEO_UPLOAD,
            ].includes(question.qType)}
          >
            <AddEvidenceButton />
          </HideComponent>

          <Controller<SectionEntity, `data.${number}.isFlagged`>
            name={`data.${questionIndex}.isFlagged`}
            defaultValue={false}
            render={({ field }) => {
              return (
                <Flex
                  gap={1}
                  align='center'
                  cursor='pointer'
                  fontWeight={500}
                  onClick={
                    disableMark
                      ? undefined
                      : () => callAll(field.onChange, saveHandler)(!field.value)
                  }
                  aria-disabled={disableMark}
                  _disabled={{
                    opacity: 0.4,
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  }}
                >
                  {!field?.value ? (
                    <>
                      <FontAwesomeIcon icon={faFlag as IconProp} />
                      <Box fontWeight={500}>Mark flag</Box>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faFlagSolid as IconProp} />
                      <Box>Unmark flag</Box>
                    </>
                  )}
                </Flex>
              );
            }}
          />
        </Flex>
      </EvidenceProvider>
    </Flex>
  );
};

export default QuestionSection;
