import { FC, useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';
import { ActionButton } from '../../../../ui-components';
import { Button } from '../../../../atoms';
import ShareReportDrawer from './ShareReportDrawer';
import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';
import {
  GET_PDF_URL,
  GENERATE_PDF_URL,
} from 'sub-components/audits/report/query/audits-report.graphql';

type ReportHeadingSectionProps = {
  locationName: string | undefined;
  selectedViewType: string;
};

const ReportHeadingSection: FC<ReportHeadingSectionProps> = ({
  locationName,
  selectedViewType,
}) => {
  const [showReportShareDrawer, setShowReportShareDrawer] = useState(false);
  const [assignment, setAssignment] = useState({});
  const [pdfUrl, setPdfUrl] = useState('');
  const [finalUrl, setFinalUrl] = useState('');
  const handlePrint = useReactToPrint({
    content: () => document.querySelector('#report-pdf-view'),
    documentTitle: `audit-report-${new Date()}`,
  });
  const history = useHistory();

  const { sessionId, locationId } = useParams<{
    sessionId: string;
    locationId: string;
  }>();

  const [getPdfUrl, { refetch }] = useLazyQuery(GET_PDF_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const foundAssignment = data?.AuditSessionById?.assignments?.find(
        (item) => item?.locationId === locationId
      );
      setAssignment(foundAssignment);
    },
  });

  const [generatePdfUrl, { loading }] = useMutation(GENERATE_PDF_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setPdfUrl(data?.AuditSessionReportsPdf);
      refetch?.();
      window.open(data?.AuditSessionReportsPdf, '_blank');
    },
  });

  const handleBackClick = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/audits');
    }
  };

  useEffect(() => {
    getPdfUrl({
      variables: {
        eid: sessionId,
      },
    });
  }, []);

  return (
    <Flex w='full' justify='space-between' align='center'>
      <Flex gap={2} flexDir='column'>
        <Button
          fontSize='14px'
          fontWeight={500}
          width='max-content'
          variant='link'
          leftIcon={<BackIcon width='16' height='16' />}
          _hover={{
            textDecoration: 'none',
          }}
          onClick={handleBackClick}
        >
          Back
        </Button>
        <Box fontWeight={600} fontSize='28px' minH={12}>
          {`Report ${locationName ? '- ' + locationName : ''}`}
        </Box>
      </Flex>
      <Flex gap={2}>
        <ActionButton
          bg='white'
          variant='outline'
          size='lg'
          leftIcon={<FontAwesomeIcon icon={faArrowUpFromBracket as IconProp} />}
          actionFn={() => {
            if (assignment?.reportsUrl || pdfUrl) {
              let url = assignment?.reportsUrl
                ? assignment?.reportsUrl
                : pdfUrl;

              setFinalUrl(url);
              window.open(url, '_blank');
            } else {
              return generatePdfUrl({
                variables: {
                  sessionId: sessionId,
                  locationId: locationId,
                },
              });
            }
          }}
        >
          Export PDF
        </ActionButton>

        <Button
          size='lg'
          colorScheme='blue'
          leftIcon={<FontAwesomeIcon icon={faShareNodes as IconProp} />}
          onClick={() => setShowReportShareDrawer(true)}
        >
          Share Report
        </Button>
        {showReportShareDrawer && (
          <ShareReportDrawer
            finalUrl={finalUrl}
            assignment={assignment}
            isOpen={showReportShareDrawer}
            onClose={() => setShowReportShareDrawer(false)}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ReportHeadingSection;
