import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useMountedRef } from './useMountedRef';

function useSafeState<S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>];

// eslint-disable-next-line no-redeclare
function useSafeState<S = undefined>(): [
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>
];

// eslint-disable-next-line no-redeclare, @typescript-eslint/explicit-module-boundary-types
function useSafeState<S>(initialState?: S | (() => S)) {
  const mountedRef = useMountedRef();
  const [state, setState] = useState(initialState);
  const setCurrentState = useCallback((currentState) => {
    /** if component is unmounted, stop update */
    if (!mountedRef.current) return;
    setState(currentState);
  }, []);

  return [state, setCurrentState] as const;
}

export default useSafeState;
