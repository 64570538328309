import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { match } from 'ts-pattern';
import { ActionType, Row, TableInstance } from 'react-table';

import { userObj } from 'sop-commons/src/client';
import { LocationType, TSortBy } from 'types';
import { SelectOption } from 'atoms/Dropdown';
import { AuthRole } from 'authorization';
import { toArray } from 'utils/utils';

import { SearchFilterHeader } from '../common';
import {
  GET_LOCATIONS,
  GET_LOCATION_TABLE_FIELDS,
  GET_STATE_CITY_DATA_FOR_LOCATIONS,
  LocationResponse,
  LocationSort,
  LocationVariable,
  UPDATE_LOCATION_TABLE_FIELD_VISIBILITY,
} from './locations.graphql';
import { ILocation } from 'sub-components/Locations/locationTypes/location-types';
import { useColumnHandler } from './location-helper-functions';
import EmptyState from '../../../sub-components/EmptyState';

import { createSheetsHandler } from '../../Settings/CreateReport/CreateReport';

import MemberExportButton from '../members/MemberExportButton';
import LocationModals from './LocationModals';
import { useHistory } from 'react-router-dom';
import ListPagination from 'atoms/ListPagination';
import LocationTable from './LocationTable';
import { useUserDataSelector } from 'hooks';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import useCombinedStore from 'zustandStore/store';
import { usePreLaunchLocation } from 'sub-components/PreLaunchLocation/usePreLaunchLocation';
import { useDeleteLocation } from 'sub-components/DeleteLocation/useDeleteLocation';
import { LAUNCHER_ADD_LOCATION } from 'appRoutes';
import LocationEmptyState from './LocationEmptyState';
import { useCustomColumnsHandler } from './useCustomColumnsHandler';

interface IProps {
  shouldHideHeader?: boolean;
  shouldHideEdit?: boolean;
  locationOwnerShare?: boolean;
  hiddenColumns?: ('status' | 'action')[];
}

export type ISelectedFilterTab = 'all' | 'open' | 'development' | 'preLaunch';

export type IDrawerType = 'add' | 'edit' | 'view';

export type ICloseType = 'close' | 'updated';

export type IDropdownType =
  | 'city'
  | 'state'
  | 'zipcode'
  | 'type'
  | 'locationStatus';

export type ICityStateListEntity = {
  name: string;
  selected: boolean;
};

export type IOtherFilterTypeEntity = {
  name: LocationType;
  selected: boolean;
};

export interface ILocationStatusEntity {
  open: number;
  development: number;
  preLaunch: number;
}

export interface ICityStateList {
  city: ICityStateListEntity[];
  state: ICityStateListEntity[];
  locationStatus: ICityStateListEntity[];
  zipCode: ICityStateListEntity[];
  type: IOtherFilterTypeEntity[];
}

export interface IEntityLocationMeta {
  EntityLocationMeta: {
    city: string[];
    state: string[];
    locationStatus: ILocationStatusEntity;
    zipCode: string[];
  };
}

export type FieldType = {
  id?: string;
  field: string;
  visibility: boolean;
  default?: boolean;
  mandatory?: boolean;
};

const Wrapper = styled.div<{ locationLoading: boolean }>`
  margin-top: 16px;
  overflow: auto;
  table {
    width: 100% !important;
    background: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(111, 118, 126, 0.25);
    border-radius: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 118, 126, 0.25);
    border-radius: 5px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(111, 118, 126, 0.25);
  }

  table > thead {
    background-color: #f4f4f4;
  }

  table thead th:last-child,
  table tbody td:last-child {
    position: sticky;
    right: 5px; /* Adjusted to match scrollbar width */
    z-index: 1;
    background-color: white;
  }

  table thead th:last-child::after,
  table tbody td:last-child::after {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    height: 100%;
    width: 5px;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  }
`;

const LocationListContainer: FC<IProps> = ({
  shouldHideHeader,
  shouldHideEdit,
  hiddenColumns,
  locationOwnerShare,
}) => {
  const userData = useReactiveVar(userObj);
  const loggedInUserEid = useUserDataSelector((state) => state?.eid);
  const preLaunchLocation = usePreLaunchLocation();
  const deleteLocation = useDeleteLocation();
  const {
    fetchLoading,
    fieldsData,
    processingLoading,
    updateLoading,
    setFieldsData,
    updateTableColumnsHandler,
  } = useCustomColumnsHandler();
  const launchViaLauncher = useCombinedStore(
    (state) => state.events['preLaunchLocationModal']['goToLauncher']
  );
  const bulkUploadSuccessEvent = useCombinedStore(
    (state) => state.events['bulkUploadLocationsModal']['onBulkUploadSuccess']
  );
  const bulkUploadErrorEvent = useCombinedStore(
    (state) => state.events['bulkUploadLocationsModal']['onBulkUploadError']
  );
  const clearEvent = useCombinedStore((state) => state.clearEvent);
  const loggedInUserLocations =
    useUserDataSelector((state) => state?.locations) || [];
  const history = useHistory();
  const [selectedFilterTab, setSelectedFilterTab] =
    useState<ISelectedFilterTab>('all');
  // const preLaunchLocationClick = useCombinedStore(
  //   (state) => state.events['LocationListPage']['preLaunchLocationLaunchClick']
  // );
  // const clearEvent = useCombinedStore((state) => state.clearEvent);
  const [filterBy, setFilterBy] = useState<SelectOption | null>();
  const [sortBy, setSortBy] = useState<LocationSort>();
  const [filterByCategory, setFilterByCategory] = useState<SelectOption[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [locationList, setLocationList] = useState<ILocation[]>([]);
  // const [initialLocationList, setInitialLocationList] = useState<ILocation[]>(
  //   []
  // );
  const [locationData, setLocationData] = useState<ILocation>();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedUserChatId, setSelectedUserChatId] = useState<string>();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [cityStateList, setCityStateList] = useState<ICityStateList>({
    city: [],
    state: [],
    locationStatus: [],
    zipCode: [],
    type: [],
  });
  const [initialCityStateList, setInitialCityStateList] =
    useState<ICityStateList>({
      city: [],
      state: [],
      locationStatus: [],
      zipCode: [],
      type: [],
    });

  const [selectedFlatRows, setSelectedFlatRows] = useState<
    Array<Row<ILocation>>
  >([]);

  useEffect(() => {
    if (launchViaLauncher) {
      clearEvent('preLaunchLocationModal', 'goToLauncher');
      history.push(LAUNCHER_ADD_LOCATION);
    }
    if (bulkUploadSuccessEvent) {
      clearEvent('bulkUploadLocationsModal', 'onBulkUploadSuccess');
      getStateCityDataHandler();
      getLocations({
        variables: getVariables(),
      });
    }
    if (bulkUploadErrorEvent) {
      clearEvent('bulkUploadLocationsModal', 'onBulkUploadError');
      getStateCityDataHandler();
      getLocations({
        variables: getVariables(),
      });
    }
  }, [launchViaLauncher, bulkUploadSuccessEvent, bulkUploadErrorEvent]);

  const [getLocations, { data, loading: locationLoading, refetch }] =
    useLazyQuery<LocationResponse, LocationVariable>(GET_LOCATIONS, {
      fetchPolicy: 'network-only',
    });

  const [
    getStateCityDataForLocationsHandler,
    { data: stateCityData, loading: locationMetaCountLoading },
  ] = useLazyQuery<IEntityLocationMeta, { entityId: string }>(
    GET_STATE_CITY_DATA_FOR_LOCATIONS,
    {
      fetchPolicy: 'network-only',
    }
  );

  // useEffect(() => {
  //   if (preLaunchLocationClick) {
  //     preLaunchLocation({});
  //     clearEvent('LocationListPage', 'preLaunchLocationLaunchClick');
  //   }
  // }, [preLaunchLocationClick, clearEvent]);

  useEffect(() => {
    if (!stateCityData) return;
    let _cityData: ICityStateListEntity[] = [];
    let _stateData: ICityStateListEntity[] = [];
    let _zipCodeData: ICityStateListEntity[] = [];
    stateCityData?.EntityLocationMeta?.city?.map((_city) => {
      _cityData?.push({
        name: _city,
        selected: false,
      });
    });
    stateCityData?.EntityLocationMeta?.state?.map((_state) => {
      _stateData?.push({
        name: _state,
        selected: false,
      });
    });
    stateCityData?.EntityLocationMeta?.zipCode?.map((_zipCode) => {
      _zipCodeData?.push({
        name: _zipCode,
        selected: false,
      });
    });
    let initialTypeData: IOtherFilterTypeEntity[] = [
      { name: 'corporate', selected: false },
      { name: 'franchise', selected: false },
    ];
    let initialStatusData: ICityStateListEntity[] = [
      { name: 'Open', selected: false },
      { name: 'Launching', selected: false },
      { name: 'Pre-launch', selected: false },
    ];
    setCityStateList({
      city: _cityData,
      state: _stateData,
      locationStatus: initialStatusData,
      zipCode: _zipCodeData,
      type: initialTypeData,
    });
    setInitialCityStateList({
      city: _cityData,
      state: _stateData,
      locationStatus: initialStatusData,
      zipCode: _zipCodeData,
      type: initialTypeData,
    });
  }, [stateCityData]);

  useEffect(() => {
    if (!userData?.entityId) return;
    getStateCityDataHandler();
  }, [userData?.entityId]);

  const getStateCityDataHandler = () => {
    getStateCityDataForLocationsHandler({
      variables: {
        entityId: userData?.entityId,
      },
    });
  };

  useEffect(() => {
    if (data && data?.Locations?.items?.length > 0) {
      setSelectedFlatRows([]);
      let _data: LocationResponse = JSON.parse(JSON.stringify(data));
      _data?.Locations?.items?.map((item) =>
        item?.members?.map((member) => {
          member.checked = false;
          member.location = item?.name;
          return member;
        })
      );
      // setInitialLocationList(_data?.Locations?.items);
      setLocationList(_data?.Locations?.items);
    } else {
      // setInitialLocationList([]);
      setLocationList([]);
      setSelectedFlatRows([]);
    }
    setSelectedFlatRows?.([]);
  }, [data]);

  const allLocationFilter = () => {
    return (
      userData?.type === 'user' &&
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(userData?.authRole)
    );
  };

  const getMyLocations = () => {
    if (filterByCategory?.find((cat) => cat.value === 'myLocations')) {
      return loggedInUserLocations?.map((loc) => loc?.eid);
    }
    return undefined;
  };

  const getVariables = useCallback((): LocationVariable => {
    /**
     * NEED TO ADD ZIP-CODE
     */
    let _filter: {
      locationStatus?: ISelectedFilterTab;
      locationType?: LocationType[];
      city?: string[];
      state?: string[];
      zipCode?: string[];
    } = {};
    if (
      selectedFilterTab === 'development' ||
      selectedFilterTab === 'open' ||
      selectedFilterTab === 'preLaunch'
    ) {
      _filter.locationStatus = selectedFilterTab;
    }

    let selectedLocationStatus = cityStateList?.locationStatus
      ?.filter((status) => status.selected)
      .map((status) => status.name);
    if (
      selectedLocationStatus?.includes('Launching') &&
      selectedLocationStatus?.includes('Open') &&
      selectedLocationStatus?.includes('Pre-launch')
    ) {
      _filter.locationStatus = undefined;
    } else if (
      selectedLocationStatus?.includes('Launching') ||
      selectedLocationStatus?.includes('Open') ||
      selectedLocationStatus?.includes('Pre-launch')
    ) {
      let locationStatus = selectedLocationStatus?.[0]?.toLowerCase();
      if (locationStatus === 'launching') {
        locationStatus = 'development';
      }
      if (locationStatus === 'pre-launch') {
        locationStatus = 'preLaunch';
      }
      _filter.locationStatus = locationStatus as ISelectedFilterTab;
    }

    let selectedCities = cityStateList?.city
      .filter((city) => city.selected)
      .map((city) => city.name);

    let selectedStates = cityStateList?.state
      .filter((state) => state.selected)
      .map((state) => state.name);

    let selectedZipCodes = cityStateList?.zipCode
      ?.filter((zip) => zip.selected)
      ?.map((zip) => zip.name);

    let selectedTypes = cityStateList?.type
      .filter((type) => type.selected)
      .map((type) => type.name);

    if (selectedCities && selectedCities.length > 0) {
      _filter.city = selectedCities;
    }

    if (selectedStates && selectedStates.length > 0) {
      _filter.state = selectedStates;
    }

    if (selectedZipCodes && selectedZipCodes.length > 0) {
      _filter.zipCode = selectedZipCodes;
    }

    if (selectedTypes && selectedTypes.length > 0) {
      _filter.locationType = selectedTypes;
    }

    console.log('filter by category : ', filterByCategory);

    if (filterByCategory?.find((cat) => cat.value === 'myStateLocations')) {
      let myStates = loggedInUserLocations
        ?.map((loc) => {
          return loc?.address?.state || '';
        })
        .filter(Boolean);
      if (myStates?.length > 0) {
        _filter.state = [...(_filter.state || []), ...myStates];
      }
      console.log('myStates : ', myStates);
    }

    if (filterByCategory?.find((cat) => cat.value === 'myCityLocations')) {
      let myCities = loggedInUserLocations
        ?.map((loc) => {
          return loc?.address?.city || '';
        })
        .filter(Boolean);
      if (myCities?.length > 0) {
        _filter.city = [...(_filter.city || []), ...myCities];
      }
      console.log('myCities : ', myCities);
    }

    // if (filterByCategory?.value === 'allFranchise') {
    //   // corporate' | 'franchise
    //   let isFranchiseSelected = cityStateList?.type?.find(t => t?.name === 'franchise' && t?.selected);
    //   console.log('is franchise selected : ', isFranchiseSelected);
    //   if (!isFranchiseSelected) {
    //     setCityStateList({...cityStateList, type: [...cityStateList.type, { name: 'franchise', selected: true }]});
    //   }
    // }

    return {
      page: selectedPage,
      query: searchQuery,
      sort:
        sortBy ||
        match<string, LocationSort>(filterBy?.value as string)
          .with('Alphabetically', () => 'NAME_ASC')
          .with('Last Created', () => '_ID_DESC')
          .otherwise(() => 'NAME_ASC'),
      ...(Object.keys(_filter).length > 0 ? { filter: _filter } : null),
      allLocations: allLocationFilter(),
      locationIds: getMyLocations(),
    };
  }, [
    sortBy,
    filterBy?.value,
    searchQuery,
    selectedPage,
    selectedFilterTab,
    cityStateList,
    filterByCategory,
  ]);
  // const getVariables = useCallback((): LocationVariable => {
  //   return getVariablesHandler();
  // }, [sortBy?.value, searchQuery, selectedPage, selectedFilterTab]);

  useEffect(() => {
    getLocations({
      variables: getVariables(),
    });
  }, [getVariables]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setSelectedPage(1);
  };

  const onLaunchLocationClick = useCallback((_location: ILocation) => {
    preLaunchLocation({
      locationDetails: _location,
      launchHandler: () =>
        history.push(`/launcher/pre-launch-to-launching/${_location?.eid}`),
      openHandler: () =>
        history.push(`/locations/review-add/${_location?.eid}`),
    });
  }, []);

  const onEditClick = useCallback((_location: ILocation) => {
    deployEvent(AmplitudeEventNames.LOCATION_EDIT_ACTION_BUTTON);
    history.push(`/locations/edit/${_location.eid}`);
  }, []);

  const onShareClick = useCallback(
    async (_location: ILocation) => {
      if (locationOwnerShare) {
        setLocationData({
          ..._location,
          members: _location.members.filter(
            (_user) => _user.authRole === AuthRole.LOCATION_OWNER
          ),
        });
      } else {
        setLocationData(_location);
      }

      setIsShareModalOpen(true);
    },
    [locationOwnerShare]
  );

  const onLocationClick = (locationData: ILocation) => {
    history.push(`/locations/${locationData?.eid}`);
  };

  const onCityStateClick = (
    type: IDropdownType,
    data: ICityStateListEntity
  ) => {
    console.log('On City State Click : ', { type, data });
    let _stateCityList: ICityStateList = JSON.parse(
      JSON.stringify(initialCityStateList)
    );
    if (type === 'city') {
      _stateCityList?.city?.forEach((_city) => {
        if (_city?.name === data?.name) {
          _city.selected = !data?.selected;
        }
      });
      setInitialCityStateList(_stateCityList);
    } else if (type === 'state') {
      _stateCityList?.state?.forEach((_state) => {
        if (_state?.name === data?.name) {
          _state.selected = !data?.selected;
        }
      });
      setInitialCityStateList(_stateCityList);
    } else if (type === 'zipcode') {
      _stateCityList?.zipCode?.forEach((_zipCode) => {
        if (_zipCode?.name === data?.name) {
          _zipCode.selected = !data?.selected;
        }
      });
      setInitialCityStateList(_stateCityList);
    } else if (type === 'type') {
      _stateCityList?.type?.forEach((_type) => {
        if (_type?.name === data?.name) {
          _type.selected = !data?.selected;
        }
      });
      setInitialCityStateList(_stateCityList);
    } else if (type === 'locationStatus') {
      _stateCityList?.locationStatus?.forEach((_type) => {
        if (_type?.name === data?.name) {
          _type.selected = !data?.selected;
        }
      });
      setInitialCityStateList(_stateCityList);
    }
  };

  const fetchData = () => {
    let _cityStateList: ICityStateList = JSON.parse(
      JSON.stringify(initialCityStateList)
    );
    let _filterByCategory: SelectOption<string>[] = [];
    let selectedTypes = _cityStateList?.type
      ?.filter((t) => t.selected)
      ?.map((t) => t.name);
    if (selectedTypes?.length > 0) {
      if (
        selectedTypes?.includes('corporate') &&
        selectedTypes?.includes('franchise')
      ) {
        _filterByCategory = [
          { label: 'All franchise', value: 'allFranchise' },
          { label: 'All corporates', value: 'allCorporates' },
        ];
      } else if (selectedTypes?.includes('corporate')) {
        _filterByCategory = [
          { label: 'All corporates', value: 'allCorporates' },
        ];
      } else if (selectedTypes?.includes('franchise')) {
        _filterByCategory = [{ label: 'All franchise', value: 'allFranchise' }];
      }
    } else {
      _filterByCategory = [];
    }
    console.log('Filter by category : ', _filterByCategory);
    console.log('Selected Types : ', selectedTypes);
    setFilterByCategory(_filterByCategory);
    setCityStateList(_cityStateList);
    setSelectedPage(1);
  };

  const onMessageClick = (_location: ILocation) => {
    setSelectedUserChatId(_location.eid);
  };

  const onDeleteLocationError = () => {
    getLocations({
      variables: getVariables(),
    });
    getStateCityDataHandler();
  };

  const onDeleteLocationSuccess = () => {
    getLocations({
      variables: getVariables(),
    });
    getStateCityDataHandler();
  };

  const onDeleteLocationClick = (_location: ILocation) => {
    deleteLocation({
      locationDetails: _location,
      onActionError: onDeleteLocationError,
      onActionSuccess: onDeleteLocationSuccess,
    });
  };

  const onEditPreLaunchClick = (_location: ILocation) => {
    console.log('On Edit Pre Launch Location : ', _location);
    history.push(`/locations/edit/${_location?.eid}`);
  };

  const columns = useColumnHandler(
    onEditClick,
    onEditPreLaunchClick,
    onShareClick,
    onMessageClick,
    onLocationClick,
    onLaunchLocationClick,
    onDeleteLocationClick,
    onCityStateClick,
    fetchData,
    initialCityStateList,
    cityStateList,
    selectedFilterTab,
    fieldsData,
    shouldHideEdit,
    locationOwnerShare
  );

  const onExportClick = useCallback(async () => {
    const exportableData = selectedFlatRows.map((it) => it.original);
    await createSheetsHandler(
      exportableData,
      { value: 'Locations' },
      locationData,
      exportableData,
      fieldsData
    );
  }, [selectedFlatRows, locationData, locationList, fieldsData]);

  const onPageChangeHandler = (page: number) => {
    setSelectedPage(page);
  };

  const pageInfo = data?.Locations;

  // useEffect(() => {
  //   if (selectedFilterTab === 'all') {
  //     setLocationList(initialLocationList);
  //   } else {
  //     if (selectedFilterTab === 'development') {
  //       let filteredLocations = initialLocationList?.filter(location => location?.locationStatus === 'development');
  //       setLocationList(filteredLocations);
  //     } else {
  //       let filteredLocations = initialLocationList?.filter(location => location?.locationStatus === 'open');
  //       setLocationList(filteredLocations);
  //     }
  //   }
  // }, [selectedFilterTab]);

  const userChatModalHandler = () => {
    setSelectedUserChatId(undefined);
  };

  const updateSortBy = (values: TSortBy) => {
    const newValue = match<TSortBy, LocationSort>(values)
      .with({ id: 'name' }, (sel) => (sel.desc ? 'NAME_ASC' : 'NAME_DESC'))
      .with({ id: 'locationType' }, (sel) =>
        sel.desc ? 'locationType_asc' : 'locationType_desc'
      )
      .otherwise(() => 'NAME_ASC');

    setSortBy(newValue);
    setFilterBy(null);
  };

  const toggleRowSelect = (
    selectedRows: Record<string, boolean> = {},
    rowsById: Record<string, Row<ILocation>> = {}
  ) => {
    setSelectedFlatRows(Object.keys(selectedRows).map((it) => rowsById[it]));
  };

  const stateReducer = <T extends unknown>(
    newState: T,
    action: ActionType,
    _: T,
    instance?: TableInstance<ILocation>
  ) => {
    switch (action?.type) {
      case 'toggleSortBy':
        // @ts-ignore
        updateSortBy(toArray<TSortBy>(newState?.sortBy)[0]);
        break;
      case 'toggleAllRowsSelected':
      case 'toggleRowSelected':
        toggleRowSelect(
          // @ts-ignore
          newState?.selectedRowIds || {},
          instance?.rowsById || {}
        );
        break;
    }

    return newState;
  };

  const locationFilterChangeHandler = (options: SelectOption<string>[]) => {
    setFilterByCategory(options);
    setSelectedPage(1);

    let _stateCityList: ICityStateList = JSON.parse(
      JSON.stringify(initialCityStateList)
    );
    _stateCityList?.locationStatus?.forEach((_status) => {
      _status.selected = false;
    });
    setInitialCityStateList(_stateCityList);

    let values = options?.map((o) => o.value);
    if (values?.includes('allFranchise') && values?.includes('allCorporates')) {
      setInitialCityStateList({
        ...initialCityStateList,
        type: [
          { name: 'corporate', selected: true },
          { name: 'franchise', selected: true },
        ],
      });
    } else if (values?.includes('allFranchise')) {
      setInitialCityStateList({
        ...initialCityStateList,
        type: [
          { name: 'corporate', selected: false },
          { name: 'franchise', selected: true },
        ],
      });
      setCityStateList({
        ...cityStateList,
        type: [
          { name: 'corporate', selected: false },
          { name: 'franchise', selected: true },
        ],
      });
    } else if (values?.includes('allCorporates')) {
      setInitialCityStateList({
        ...initialCityStateList,
        type: [
          { name: 'corporate', selected: true },
          { name: 'franchise', selected: false },
        ],
      });
      setCityStateList({
        ...cityStateList,
        type: [
          { name: 'corporate', selected: true },
          { name: 'franchise', selected: false },
        ],
      });
    } else if (
      !values?.includes('allCorporates') &&
      !values?.includes('allFranchise')
    ) {
      setInitialCityStateList({
        ...initialCityStateList,
        type: [
          { name: 'corporate', selected: false },
          { name: 'franchise', selected: false },
        ],
      });
      setCityStateList({
        ...cityStateList,
        type: [
          { name: 'corporate', selected: false },
          { name: 'franchise', selected: false },
        ],
      });
    }
  };

  const anyFilterSelected = () => {
    return (
      cityStateList?.city?.some((c) => c.selected) ||
      cityStateList?.locationStatus?.some((loc) => loc.selected) ||
      cityStateList?.state?.some((state) => state.selected) ||
      cityStateList?.type?.some((type) => type.selected) ||
      cityStateList?.zipCode?.some((zip) => zip.selected)
    );
  };

  const isLoading =
    updateLoading ||
    fetchLoading ||
    locationLoading ||
    locationMetaCountLoading ||
    processingLoading;

  if (
    !locationLoading &&
    !locationMetaCountLoading &&
    !fetchLoading &&
    !updateLoading &&
    !processingLoading &&
    locationList?.length === 0 &&
    !filterBy &&
    !sortBy &&
    filterByCategory?.length === 0 &&
    !searchQuery &&
    selectedFilterTab === 'all' &&
    !anyFilterSelected()
  ) {
    return <LocationEmptyState />;
  }

  if (
    !isLoading &&
    (!columns || columns?.length === 0 || fieldsData?.length === 0)
  ) {
    return (
      <EmptyState
        image='Search'
        title='Could not fetch columns data'
        description='Something went wrong, please try again'
      />
    );
  }

  return (
    <>
      <Box flex={1}>
        {!shouldHideHeader && (
          <SearchFilterHeader
            activeTab='locations'
            columnMetaLoading={fetchLoading || updateLoading}
            sortBy={filterBy}
            onSortChange={(value) => {
              setSortBy(undefined);
              setFilterBy(value);
            }}
            showLocationFilter
            searchQuery={searchQuery}
            filterByCategory={filterByCategory}
            onSearch={onSearchChange}
            initialCityStateList={initialCityStateList}
            setInitialCityStateList={setInitialCityStateList}
            cityStateList={cityStateList}
            setCityStateList={setCityStateList}
            selectedFilterTab={selectedFilterTab}
            setSelectedFilterTab={setSelectedFilterTab}
            setSelectedPage={setSelectedPage}
            locationStatus={stateCityData?.EntityLocationMeta?.locationStatus}
            locationFilterChangeHandler={locationFilterChangeHandler}
            updateTableColumnsHandler={updateTableColumnsHandler}
            setFieldsData={setFieldsData}
            fieldsData={fieldsData}
          />
        )}
        <Wrapper id='location-table-wrapper' locationLoading={isLoading}>
          <LocationTable
            colorScheme='blue'
            emptyData={{
              content: (
                <Flex justifyContent={'center'} width={'100vw'} height={'100%'}>
                  <EmptyState
                    title='No location found for applied search/filters'
                    image='Search'
                  />
                </Flex>
              ),
            }}
            page={selectedPage}
            onPageChange={onPageChangeHandler}
            columns={columns}
            data={locationList}
            isLoading={isLoading}
            totalRegisters={pageInfo?.count}
            stateReducer={stateReducer}
            isResponsive
            isHidePagination={true}
            // isHidePagination={
            //   !(selectedPage > 1 || (pageInfo?.count || 0) > 10)
            // }
            options={{
              autoResetSortBy: false,
              initialState: {
                hiddenColumns: toArray(hiddenColumns),
              },
            }}
          />
          {!isLoading && (
            <MemberExportButton
              selectedFlatRows={selectedFlatRows}
              onExportClick={onExportClick}
              transKey='setting:location_selected'
            />
          )}
        </Wrapper>
        {/* <Wrapper id='location-table-wrapper' locationLoading={locationLoading}>
          <SortingTable
            colorScheme='blue'
            emptyData={{
              content: (
                <EmptyState
                  image='Location'
                  title={t('location:noLocationPresent')}
                  description={t('location:noLocationPresentHelp')}
                  showButton
                  buttonProps={
                    <Authorization
                      permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
                      permittedFor='user'
                    >
                      <PrimaryButton
                        width='auto'
                        leftIcon={<AddIcon />}
                        title={t('location:add_location')}
                        style={{ marginTop: 20 }}
                        onClick={() => history.push(ADD_LOCATION)}
                        variant='solid'
                        colorScheme='blue'
                        size='lg'
                      />
                    </Authorization>
                  }
                />
              ),
            }}
            page={selectedPage}
            // onPageChange={onPageChangeHandler}
            columns={columns}
            data={locationList}
            isLoading={locationLoading}
            // totalRegisters={pageInfo?.count}
            stateReducer={stateReducer}
            isResponsive
            isHidePagination={true}
            // isHidePagination={
            //   !(selectedPage > 1 || (pageInfo?.count || 0) > 10)
            // }
            options={{
              autoResetSortBy: false,
              initialState: {
                hiddenColumns: toArray(hiddenColumns),
              },
            }}
          />

          <MemberExportButton
            selectedFlatRows={selectedFlatRows}
            onExportClick={onExportClick}
            transKey='setting:location_selected'
          />
        </Wrapper> */}
        {!isLoading &&
          ((pageInfo?.currentPage === 1 && pageInfo?.hasMore) ||
            (pageInfo?.currentPage || 1) > 1) && (
            <Box marginBottom={'4rem'}>
              <ListPagination
                onPageChange={onPageChangeHandler}
                data={locationList}
                totalRegisters={pageInfo?.count || 0}
                page={selectedPage}
                registersPerPage={10}
              />
            </Box>
          )}

        <LocationModals
          locationData={locationData}
          isShareModalOpen={isShareModalOpen}
          selectedUserChatId={selectedUserChatId}
          showInviteModal={showInviteModal}
          setIsShareModalOpen={setIsShareModalOpen}
          userChatModalHandler={userChatModalHandler}
          setShowInviteModal={setShowInviteModal}
          refetch={refetch}
          getVariables={getVariables}
        />
      </Box>
    </>
  );
};

LocationListContainer.displayName =
  'pages/Teams/locations/LocationListContainer';

export default LocationListContainer;
