import { match } from 'ts-pattern';
import { getFileType } from './utils';
import audio from '../assets/images/filetype-icons/audio.svg';
import gif from '../assets/images/filetype-icons/gif.svg';
import ppt from '../assets/images/filetype-icons/ppt.svg';
import pdf from '../assets/images/filetype-icons/pdf.svg';
import docs from '../assets/images/filetype-icons/doc.svg';
import excel from '../assets/images/filetype-icons/excel.svg';
import image from '../assets/images/filetype-icons/image.svg';
import video from '../assets/images/filetype-icons/video.svg';
import zip from '../assets/images/filetype-icons/zip.svg';
import fileGeneric from '../assets/images/filetype-icons/fIle-generic.svg';

export const getFileIcon = (
  mimeType?: string,
  isMimeType: boolean = true
): string | undefined => {
  if (!mimeType) {
    return undefined;
  }
  const fileType = isMimeType ? getFileType(mimeType) : mimeType;
  return match(fileType)
    .with('pdf', () => pdf)
    .with('docs', () => docs)
    .with('docx', () => docs)
    .with('xlsx', () => excel)
    .with('image', () => image)
    .with('video', () => video)
    .with('file', () => fileGeneric)
    .with('zip', () => zip)
    .with('audio', () => audio)
    .with('ppt', () => ppt)
    .with('gif', () => gif)
    .otherwise(() => undefined);
};
