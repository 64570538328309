import { SelectOption } from 'atoms';
import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import { DisplaySession } from '../components/QuestionSection/types/audit-question.types';
import { TabType } from './audit-detail.types';

export interface AuditDetailState {
  displaySession: DisplaySession[];
  selectedTab: TabType;
  selectedAuditSessionId: string;
  selectedLocationFilter: SelectOption<string> | undefined;
  selectedSession: SelectOption<string>;
  selectedLocation: SelectOption<string>;
  locationFilterOptions: SelectOption<string>[];
  sessionLoading: boolean;
  showLocationsFilter: boolean;
  showSpecificLocationUI: boolean;
  //   queryInfo: {
  //     page: number;
  //     query?: string;
  //     sortBy?: LocationSort | SelectOption | null;
  //     filter: LocationListVariable['filter'];
  //   };

  //   messageToUser?: string;
  //   locationShare?: LocationItem;
  //   data: LocationItem[];
}

export interface AuditDetailAction extends AuditDetailState {
  updateSelectedTab: (selecedTab: TabType) => void;
  updateSelectedLocationFilter: (option: SelectOption) => void;
  updateSelectedSession: (option: SelectOption) => void;
  updateSelectedAuditSessionId: (value: string) => void;
  updateLocationFilterOptions: (options: SelectOption[]) => void;
  updateDisplaySessions: (sessions: DisplaySession[]) => void;
  updateSessionLoading: (value: boolean) => void;
  updateShowLocationsFilter: (value: boolean) => void;
  updateShowSpecificLocationUI: (value: boolean) => void;
  //   setLocationData: (data: LocationItem[]) => void;
  //   changePage: (page: number) => void;
  //   onSearch: (query: string) => void;
  //   onSortChange: (sortBy: LocationState['queryInfo']['sortBy']) => void;
  //   updateFilter: (
  //     filterKey: keyof LocationState['queryInfo']['filter'],
  //     value: never
  //   ) => void;
  //   setMessageTo: (userId?: string) => void;
  //   setLocationShare: (location?: LocationItem) => void;
}

export const useAuditDetailStore = createStore<AuditDetailAction>();

interface DefaultProps {
  selectedTab: TabType;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createAuditDetailStore = (props?: DefaultProps) => {
  return createStore<AuditDetailAction>()(
    immer((set) => ({
      displaySession: [] as DisplaySession[],
      selectedTab: props?.selectedTab || ('overview' as TabType),
      selectedAuditSessionId: '',
      selectedLocationFilter: undefined as SelectOption | undefined,
      selectedSession: {
        label: '',
        value: '',
      },
      selectedLocation: {
        label: '',
        value: '',
      },
      locationFilterOptions: [] as SelectOption[],
      sessionLoading: false as boolean,
      showLocationsFilter: false as boolean,
      showSpecificLocationUI: false as boolean,
      updateSelectedTab: (selectedTab) => {
        set((state) => {
          state.selectedTab = selectedTab;
        });
      },
      updateSelectedLocationFilter: (option) => {
        set((state) => {
          state.selectedLocationFilter = option;
        });
      },
      updateSelectedSession: (option) => {
        set((state) => {
          state.selectedSession = option;
        });
      },
      updateSelectedAuditSessionId: (option) => {
        set((state) => {
          state.selectedAuditSessionId = option;
        });
      },
      updateLocationFilterOptions: (options) => {
        set((state) => {
          state.locationFilterOptions = options;
        });
      },
      updateDisplaySessions: (options) => {
        set((state) => {
          state.displaySession = options;
        });
      },
      updateSessionLoading: (value) => {
        set((state) => {
          state.sessionLoading = value;
        });
      },
      updateShowLocationsFilter: (value) => {
        set((state) => {
          state.showLocationsFilter = value;
        });
      },
      updateShowSpecificLocationUI: (value) => {
        set((state) => {
          state.showSpecificLocationUI = value;
        });
      },
      //   queryInfo: {
      //     page: 1,
      //     filter: {
      //       locationStatus: 'open' as LocationStatus,
      //     },
      //   },

      //   data: [] as LocationItem[],

      //   changePage: (newPage) => {
      //     set((state) => {
      //       state.queryInfo.page = newPage;
      //     });
      //   },
      //   onSearch: (query) => {
      //     set((state) => {
      //       if (state.queryInfo.page > 1) {
      //         state.queryInfo.page = 1;
      //       }
      //       // TODO: page cache here when page is > 1
      //       state.queryInfo.query = query;
      //     });
      //   },
      //   onSortChange: (sortBy) => {
      //     set((state) => {
      //       state.queryInfo.sortBy = sortBy;
      //     });
      //   },
      //   updateFilter: (filterKey, filterValue) => {
      //     set((state) => {
      //       state.queryInfo.filter[filterKey] = filterValue;
      //     });
      //   },

      //   setLocationData: (newData) => set({ data: newData || [] }),
      //   setMessageTo: (userId) => set({ messageToUser: userId }),
      //   setLocationShare: (location) => set({ locationShare: location }),
    }))
  );
};
