import React, { FC } from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faLocationDot,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { formatDateTime } from '../listing/helper';
import { getImage } from 'utils';
import { AssignmentType, SessionDetailEntity } from './types/report.types';
import { InfoCard, MoreDetails } from './components';
import { formatHeadingAndDetails } from './helper';
import RenderAllSections from './RenderAllSections';

type IProps = {
  auditData: SessionDetailEntity;
  additionalData: any;
  mappedQuestionData: any;
  assignment: AssignmentType;
};

const Overview: FC<IProps> = ({
  auditData,
  additionalData,
  mappedQuestionData,
  assignment,
}) => {
  return (
    <Box style={{ scrollMargin: '8vh' }} id='overview'>
      <Box>
        <Box border='1px solid #EFEFEF' borderRadius='12px'>
          <Flex
            gap={4}
            alignItems='center'
            padding='20px'
            borderTopRadius='12px'
          >
            <Image
              src={getImage(
                additionalData?.companyDetails?.profilePic,
                additionalData?.companyDetails?.name
              )}
              alt='company-profile'
              boxSize='82px'
              minW='82px'
              borderRadius='8px'
            />
            <Flex gap={1} direction='column'>
              <Text
                fontSize='28px'
                fontWeight={600}
                lineHeight='35px !important'
              >
                {auditData?.title}
              </Text>

              <Flex
                gap={2}
                fontWeight={500}
                fontSize='13px'
                alignItems='center'
                color='#33383F'
              >
                <Flex alignItems={'center'} gap={2}>
                  <FontAwesomeIcon
                    icon={faLocationDot as IconProp}
                    fontSize='12px'
                  />
                  <span>
                    {additionalData?.locationData?.name
                      ? additionalData?.locationData?.name
                      : '--'}
                  </span>
                </Flex>
                <Box boxSize='5px' borderRadius='full' bg='#33383F' />
                <Flex alignItems={'center'} gap={2}>
                  <FontAwesomeIcon
                    icon={faCalendar as IconProp}
                    fontSize='12px'
                  />

                  <span>
                    {assignment?.completedAt
                      ? formatDateTime(
                          assignment?.completedAt,
                          additionalData?.timeZone
                        ).dateString
                      : '--'}
                  </span>
                </Flex>
                <Box boxSize='5px' borderRadius='full' bg='#33383F' />
                <Flex alignItems={'center'} gap={2}>
                  <FontAwesomeIcon icon={faUser as IconProp} fontSize='12px' />
                  <span>
                    {auditData?.auditors?.[0]?.name
                      ? auditData?.auditors?.[0]?.name
                      : '--'}
                  </span>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex>
            {Object.entries(additionalData?.cardInfo).map((item) => {
              const data = formatHeadingAndDetails(
                item,
                mappedQuestionData,
                additionalData
              );
              return (
                <InfoCard
                  key={item[0]}
                  heading={data?.heading}
                  details={data?.details}
                />
              );
            })}
          </Flex>

          <Flex flexDir='column'>
            {Object.entries(additionalData?.moreInfo).map((item) => {
              const data = formatHeadingAndDetails(
                item,
                mappedQuestionData,
                additionalData
              );
              return (
                <MoreDetails
                  key={item[0]}
                  heading={data?.heading}
                  details={data?.details}
                />
              );
            })}
          </Flex>
        </Box>
        <Box>
          <RenderAllSections
            mappedQuestionData={mappedQuestionData}
            additionalData={additionalData}
            assignment={assignment}
            auditData={auditData}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
