import React, { useCallback, useEffect, useRef } from 'react';
import { AlertDialogCloseButton, Center } from '@chakra-ui/react';
import { useLatest } from '../../../../hooks';
import { AppRoute, useHistory } from '../../../../routes';
import { BoxHeader, useConfirm, UseConfirm } from '../../../../ui-components';
import PreviewContent from './PreviewContent';
import { useTemplateImport } from './useTemplateImport';

interface IProps {
  templateId: string;
}

export const useTemplatePreview = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const history = useHistory();

  const pushRef = useLatest(history.push);

  useEffect(() => {
    return () => {
      confirmRef.current?.destroy();
    };
  }, []);

  const templateImport = useTemplateImport();

  return useCallback((props: IProps) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Center justifyContent='space-between'>
          <BoxHeader
            boxWidth={3}
            title='Preview'
            color='#CABDFF'
            fontSize='18px'
          />
          <AlertDialogCloseButton
            size='sm'
            position='relative'
            colorScheme='blue'
            right='initial'
            top='initial'
          />
        </Center>
      ),
      content: (
        <PreviewContent
          templateId={props.templateId}
          onEditQuestion={async (audit) => {
            const newEid = await templateImport({ data: audit });
            history.push({
              pathname: AppRoute.EDIT_AUDIT_TEMPLATE,
              params: {
                templateId: newEid,
              },
            });
          }}
          onImportAndSchedule={async (audit) => {
            const newEid = await templateImport({ data: audit });
            pushRef.current({
              pathname: AppRoute.SCHEDULE_AUDIT,
              params: {
                templateId: newEid,
              },
            });
          }}
        />
      ),
      size: 'full',
      contentProps: {
        // padding: '12px',
        borderRadius: 0,
        bg: '#FFFFFF',
        containerProps: {
          maxW: '560px',
          left: 'unset',
          right: 0,
        },
      },
      bodyProps: {
        padding: 0,
        background: '#F3F3F3',
      },
      headerProps: {
        borderBottom: '0.5px solid #C7C7C7',
      },
      scrollBehavior: 'inside',
      footer: null,
    });

    return confirmRef.current;
  }, []);
};
