import { useQuery, useReactiveVar } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFlag } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderColors } from 'configs';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usersEntityObj } from 'sub-components/Header';
import { useGraphRangeVariable } from 'sub-components/tasks/performance/common/graph';
import { BoxHeader } from 'ui-components';
import {
  AuditLocationResponse,
  AuditLocationVariable,
  AUDIT_LOCATION_TREND,
  LocationTrend,
} from './LocationLineGraph';

const FlaggedResponsesAnalytics: FC = () => {
  const { auditId } = useParams<{ auditId: string; sessionId: string }>();
  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );
  const [variables, setVariable] = useGraphRangeVariable();

  const { data } = useQuery<AuditLocationResponse, AuditLocationVariable>(
    AUDIT_LOCATION_TREND,
    {
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        eid: auditId,
      },
    }
  );

  const getMonthKey = (
    date: Date,
    locationData: LocationTrend
  ): string | undefined => {
    const timestamp = date.toISOString();
    for (const [month, trend] of Object.entries(locationData)) {
      if (trend.start <= timestamp && timestamp <= trend.end) {
        return month;
      }
    }
    return undefined;
  };

  const getData = useMemo(() => {
    let currentMonthFlagged = 0;
    let previousMonthFlagged = 0;
    if (selectedLocationFilter?.value) {
      const locationData =
        data?.AuditLocationTrendAnalytics[selectedLocationFilter?.value];
      if (locationData) {
        const currentDate = new Date();
        const previousDate = new Date(currentDate);
        previousDate.setMonth(currentDate.getMonth() - 1);

        const currentMonthKey = getMonthKey(currentDate, locationData);
        const previousMonthKey = getMonthKey(previousDate, locationData);

        currentMonthFlagged = currentMonthKey
          ? locationData[currentMonthKey]?.totalFlagged || 0
          : 0;
        previousMonthFlagged = previousMonthKey
          ? locationData[previousMonthKey]?.totalFlagged || 0
          : 0;
      }
    }
    return { currentMonthFlagged, previousMonthFlagged };
  }, [data?.AuditLocationTrendAnalytics, selectedLocationFilter]);

  const trendDescRender = useMemo(() => {
    let icon = <></>;
    let color = '';
    if (getData?.currentMonthFlagged === getData?.previousMonthFlagged) {
      return <></>;
    } else if (getData?.currentMonthFlagged > getData?.previousMonthFlagged) {
      icon = (
        <FontAwesomeIcon
          icon={faCaretUp as IconProp}
          color={HeaderColors.Red.Mid}
        />
      );
      color = HeaderColors.Red.Mid;
    } else {
      icon = (
        <FontAwesomeIcon
          icon={faCaretDown as IconProp}
          color={HeaderColors.Green}
        />
      );
      color = HeaderColors.Green;
    }
    return (
      <Flex flexDir='column' align='flex-start' gap='2px'>
        <Flex align='center' gap='2px'>
          {icon}
          <Box color={color} as='span' fontSize='12px' fontWeight={500}>
            {getData?.currentMonthFlagged}
          </Box>
          <FontAwesomeIcon icon={faFlag as IconProp} color={color} />
        </Flex>
        <Flex color='#6D6D6D' fontWeight={500}>
          from last month
        </Flex>
      </Flex>
    );
  }, [getData]);

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='10px'
      p='16px 20px'
      gap={4}
      w='full'
      h='130px'
    >
      <BoxHeader
        boxWidth={3.5}
        fontSize='18px'
        title='Total flagged responses'
        color={HeaderColors.Green}
      />
      <Flex gap='10px'>
        <Box fontSize='32px' fontWeight={600}>
          {getData?.currentMonthFlagged}{' '}
          {getData?.currentMonthFlagged === 1 ? 'flag' : 'flags'}
        </Box>
        {trendDescRender}
      </Flex>
    </Flex>
  );
};

export default FlaggedResponsesAnalytics;
