import React, { FC } from 'react';
import {
  Flex,
  ModalBody,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { truncateString } from 'utils';

import { IFormInput } from './invite.types';
import PhoneEmailTab from './PhoneEmailTab';
import ScanQrCode from './ScanQRCode';
import ShareByLinkTab from './ShareByLinkTab';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const ParentTabWrapper: FC = ({ children }) => {
  return (
    <Tab
      fontWeight={600}
      fontSize='15px'
      color='#6F767E'
      _selected={{
        bg: '#EFEFEF',
        color: '#1A1D1F',
        fontWeight: 600,
        fontSize: '15px',
        borderRadius: '8px',
      }}
    >
      {children}
    </Tab>
  );
};

interface IProps {
  reInviteUser?: boolean;
}

const ShareLinkTabs: FC<IProps> = ({ reInviteUser }) => {
  const { t } = useTranslation(['invite']);
  const { getValues, setValue, reset } = useFormContext<IFormInput>();
  return (
    <>
      <ModalBody>
        <Tabs variant='unstyled'>
          <TabList>
            <ParentTabWrapper>{t('invite:phoneEmail')}</ParentTabWrapper>
            <ParentTabWrapper>{t('invite:scanQr')}</ParentTabWrapper>
            <ParentTabWrapper>{t('invite:viaLink')}</ParentTabWrapper>
          </TabList>
          <TabPanels display='flex' flexDir='column' minH='300px'>
            <TabPanel flex={1} p='16px 0'>
              <PhoneEmailTab />
            </TabPanel>
            <TabPanel display='flex' flexDir='column' flex={1} p='16px 0'>
              <ScanQrCode />
            </TabPanel>
            <TabPanel flex={1} p='16px 0'>
              <ShareByLinkTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalBody>

      <ModalFooter>
        <Flex width='full' gap='10px' justify='flex-end'>
          {!reInviteUser && (
            <PrimaryButton
              width='auto'
              title={t('invite:addAnotherMember')}
              variant='outline'
              size='lg'
              onClick={() => {
                deployEvent(AmplitudeEventNames.INVITE_ADD_ANOTHER_MEMBER);
                reset();
              }}
              style={{
                flex: 1,
                fontSize: '14px',
              }}
            />
          )}
          {!getValues('setupLater') && (
            <PrimaryButton
              width={reInviteUser ? 'full' : 'auto'}
              variant='solid'
              colorScheme='blue'
              size='lg'
              onClick={() => {
                deployEvent(AmplitudeEventNames.MEMBER_ADD_TO_TRAINING, {
                  add_member_funnel_id: 5,
                  resend_funnel_id: 7,
                });
                setValue('currentStep', 'trainingPath');
              }}
              style={{
                flex: 1,
                paddingInline: '24px',
                fontSize: '14px',
              }}
            >
              <Trans
                t={t}
                i18nKey='invite:addUserToTrainings'
                values={{
                  name: truncateString(getValues('name'), 15),
                }}
              />
            </PrimaryButton>
          )}
        </Flex>
      </ModalFooter>
    </>
  );
};

export default ShareLinkTabs;
