import { Center, Flex, List, ListItem } from '@chakra-ui/react';
import { FC, useContext, useEffect, useState } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import TaskDataContext from '../../../store/task-context';
import {
  IMyTask,
  INotRelevantUserDetails,
  ITaskListData,
  ITaskOverview,
} from '../../../tasks.types';
import MyTaskItem from '../../../Components/MyTaskItem';
import { getMyTasks, getTaskById } from '../../services/MyTasks.service';
import Loader from 'ui-components/Loader';
import { processTaskData } from '../../functions/processTaskData';
import { taskTypeCount } from '../../functions/taskTypeCount';
import { completedTasksHandler } from '../../functions/completedTasksHandler';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import moment from 'moment';
import debounce from 'lodash.debounce';
import Listing from './Listing';
import OverdueTasksComponent from './OverdueTasksComponent';
import TodayTasksComponent from './TodayTasksComponent';
import ThisWeekTasksComponent from './ThisWeekTasksComponent';
import UpcomingTasksComponent from './UpcomingTasksComponent';
import CompletedTasksComponent from './CompletedTasksComponent';
import { useLocation } from 'react-router-dom';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface LocationState {
  from_where?: string;
}

const MyTasks: FC = () => {
  const taskCtx = useContext(TaskDataContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const userData = useReactiveVar(userObj);
  const location = useLocation();
  const state = location.state as LocationState;
  const { execute, loading, error, data } = getMyTasks();
  const {
    execute: executeTaskById,
    loading: taskByIdLoading,
    error: taskByIdError,
    data: taskByIdData,
  } = getTaskById();

  useEffect(() => {
    execute({
      variables: getArgs(),
    });
  }, []);

  const getArgs = () => {
    // const lastYearDateISO = moment()?.subtract(1, 'years')?.toISOString();
    const sevenDaysAgoISO = moment().subtract(7, 'days').toISOString();
    return {
      endDate: sevenDaysAgoISO,
    };
  };

  useEffect(() => {
    let { taskDetails, taskOverview } = processTaskData(data, userData);
    deployEvent(AmplitudeEventNames.MY_TASKS_VISIT, {
      from_where: state?.from_where || '',
      overdue_tasks:
        taskDetails
          ?.filter((task) => task?.type === 'overdue')
          ?.map((task) => task?.data?.length)?.[0] || 0,
      due_today:
        taskDetails
          ?.filter((task) => task?.type === 'dueToday')
          ?.map((task) => task?.data?.length)?.[0] || 0,
      this_weeks_task:
        taskDetails
          ?.filter((task) => task?.type === 'thisWeek')
          ?.map((task) => task?.data?.length)?.[0] || 0,
      total_tasks:
        taskDetails
          ?.filter((task) => task?.type === 'totalTasks')
          ?.map((task) => task?.data?.length)?.[0] || 0,
    });
    taskCtx.myTaskDetailsHandler(taskDetails);
    taskCtx.taskOverviewDataHandler(taskOverview);
  }, [data]);

  useEffect(() => {
    if (taskCtx.taskNotRelevantSideDrawerDetails.open) {
      executeTaskById({
        variables: {
          eid: taskCtx.taskNotRelevantSideDrawerDetails.selectedTask.eid,
        },
      });
    }
  }, [taskCtx.taskNotRelevantSideDrawerDetails.open]);

  useEffect(() => {
    taskCtx.setTaskNotRelevantSideDrawerDetails({
      ...taskCtx.taskNotRelevantSideDrawerDetails,
      userDetails: taskByIdData?.TaskById?.supervisorsUsers,
    });
  }, [taskByIdData]);

  const taskOverviewSelectionHandler = (task: ITaskOverview) => {
    let _taskOverview: ITaskOverview[] = JSON.parse(
      JSON.stringify(taskCtx.myTasks.taskOverviewData)
    );
    _taskOverview?.map((_task) => {
      if (_task?.id === task?.id) {
        _task.selected = !_task.selected;
      } else {
        _task.selected = false;
      }
    });
    taskCtx.taskOverviewDataHandler(_taskOverview);
  };

  const taskComponents = {
    overdue: OverdueTasksComponent,
    dueToday: TodayTasksComponent,
    thisWeek: ThisWeekTasksComponent,
    upcoming: UpcomingTasksComponent,
    totalTasks: CompletedTasksComponent,
    completed: CompletedTasksComponent,
  };

  const getTaskOverviewToShow = () => {
    const selectedTaskOverview = taskCtx.myTasks.taskOverviewData.find(
      (task) =>
        task.selected && task.id !== 'completed' && task.id !== 'totalTasks'
    );
    if (selectedTaskOverview) {
      return [selectedTaskOverview];
    }
    return taskCtx.myTasks.taskOverviewData?.filter(
      (data) => data?.id !== 'upcoming'
    );
  };

  const getUsersList = () => {
    let _userDetails: INotRelevantUserDetails[] =
      taskCtx.taskNotRelevantSideDrawerDetails.userDetails;
    if (!searchQuery?.trim()) {
      return _userDetails;
    } else {
      return _userDetails?.filter((user) =>
        user?.name
          ?.toLowerCase()
          ?.trim()
          ?.includes(searchQuery?.toLowerCase()?.trim())
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = debounce(() => {
      getUsersList();
    }, 2000);
    delayDebounceFn();
    return () => delayDebounceFn.cancel();
  }, [searchQuery]);

  const onDrawerClose = () => {
    taskCtx.setTaskNotRelevantSideDrawerDetails({
      ...taskCtx.taskNotRelevantSideDrawerDetails,
      open: false,
    });
  };

  const openNotRelevantDrawer = () => {
    taskCtx.setTaskNotRelevantSideDrawerDetails({
      ...taskCtx.taskNotRelevantSideDrawerDetails,
      open: true,
    });
  };

  return loading ? (
    <Center w='full' h='80vh'>
      <Loader />
    </Center>
  ) : (
    <Flex flexDir='column' gap='20px'>
      <Listing
        data={data}
        overviewStepData={taskCtx.myTasks.taskOverviewData}
        searchQuery={searchQuery}
        selectedUserId={selectedUserId}
        taskByIdLoading={taskByIdLoading}
        isDrawerOpen={taskCtx.taskNotRelevantSideDrawerDetails.open}
        taskComponents={taskComponents}
        taskData={taskCtx.myTasks.myTaskDetails}
        getTaskOverviewToShow={getTaskOverviewToShow}
        getUsersList={getUsersList}
        taskOverviewSelectionHandler={taskOverviewSelectionHandler}
        onDrawerClose={onDrawerClose}
        setSearchQuery={setSearchQuery}
        setSelectedUserId={setSelectedUserId}
        openNotRelevantDrawer={openNotRelevantDrawer}
      />
    </Flex>
  );
};

export default MyTasks;
