function is<D extends unknown>(x: D, y: D) {
  if (x === y) {
    // @ts-ignore
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}

function internalEqual<D extends unknown>(
  objA: D,
  objB: D,
  compare: CallableFunction
): boolean {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  // @ts-ignore
  const keysA = Object.keys(objA);
  // @ts-ignore
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      // @ts-ignore
      !compare(objA[keysA[i]], objB[keysA[i]], compare)
    ) {
      return false;
    }
  }

  return true;
}

export function shallowEqual<D extends unknown>(objA: D, objB: D): boolean {
  return internalEqual(objA, objB, is);
}

export function deepEqual<D extends unknown>(objA: D, objB: D): boolean {
  return internalEqual(objA, objB, internalEqual);
}

export default shallowEqual;

// export default function shallowEqualOld<D extends unknown>(
//   objA: D,
//   objB: D
// ): boolean {
//   if (is(objA, objB)) return true;
//
//   if (
//     typeof objA !== 'object' ||
//     objA === null ||
//     typeof objB !== 'object' ||
//     objB === null
//   ) {
//     return false;
//   }
//
//   // @ts-ignore
//   const keysA = Object.keys(objA);
//   // @ts-ignore
//   const keysB = Object.keys(objB);
//
//   if (keysA.length !== keysB.length) return false;
//
//   for (let i = 0; i < keysA.length; i++) {
//     if (
//       !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
//       // @ts-ignore
//       !is(objA[keysA[i]], objB[keysA[i]])
//     ) {
//       return false;
//     }
//   }
//
//   return true;
// }
