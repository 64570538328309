import { Flex } from '@chakra-ui/react';
import { FormNotAccessible } from 'sub-components/forms';
import React, { FC, useContext } from 'react';
import { ActionType, QuizType } from 'sop-commons/src/card-components';
import Milestone from 'sop-commons/src/card-components/layouts/newLayouts/Milestone';
import CustomFormType from 'sub-components/forms/from-preview/CustomFormType';
import { CardType } from 'types';
import TaskPlayDataContext from '../../store/task-play-context';
import EditorPreview, { IEmojiStructure, JSONContent } from 'delightree-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const TaskPlayComponent: FC = () => {
  const taskPlayCtx = useContext(TaskPlayDataContext);

  const playComponentRender = () => {
    switch (
      taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]?.type
    ) {
      case 'action':
        return (
          <ActionType
            content={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.content
            }
            // @ts-ignore
            backgroundColor={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.backgroundColor
            }
            // @ts-ignore
            cardId={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.eid
            }
            // @ts-ignore
            subTitle={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.subTitle
            }
            isPreview
            shouldMoveToNextCard={() => {}}
          />
        );
      case 'quiz':
        return (
          <QuizType
            content={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.content
            }
            title={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.title
            }
            // @ts-ignore
            layout={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.layout
            }
            // @ts-ignore
            cardId={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.eid
            }
            isPreview
            shouldMoveToNextCard={() => {}}
          />
        );
      case 'milestone':
        return (
          <Milestone
            content={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.title
            }
          />
        );
      case CardType.CustomForm:
        if (
          !taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
            ?.isAccessible
        ) {
          return <FormNotAccessible type='UNAUTHORISED' />;
        }
        return (
          <CustomFormType
            content={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.content
            }
            title={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.title
            }
            isPreview
            shouldMoveToNextCard={() => {}}
          />
        );
      default:
        // @ts-ignore
        return (
          <EditorPreview
            readonly
            value={
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.content?.[0]?.tiptap ||
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
                ?.content ||
              taskPlayCtx?.taskPlayData?.taskItems?.[taskPlayCtx?.currentIndex]
            }
            chapterIcon={''}
            onItemClick={() => {}}
          />
        );
    }
  };
  return (
    <Flex justify='space-between' align='center'>
      <Flex w='10%' justify='center'>
        {taskPlayCtx?.taskPlayData?.taskItems?.length > 0 &&
          taskPlayCtx?.currentIndex > 0 && (
            <FontAwesomeIcon
              icon={faCircleArrowLeft as IconProp}
              cursor='pointer'
              fontSize='24px'
              onClick={() =>
                taskPlayCtx.currentIndexHandler(taskPlayCtx.currentIndex - 1)
              }
            />
          )}
      </Flex>
      <Flex
        bg='white'
        w='80%'
        borderRadius='8px'
        h='74vh'
        overflow='scroll'
        p={6}
      >
        {playComponentRender()}
      </Flex>
      <Flex w='10%' justify='center'>
        {taskPlayCtx?.taskPlayData?.taskItems?.length > 0 &&
          taskPlayCtx?.currentIndex + 1 <
            taskPlayCtx?.taskPlayData?.taskItems?.length && (
            <FontAwesomeIcon
              icon={faCircleArrowRight as IconProp}
              cursor='pointer'
              fontSize='24px'
              onClick={() =>
                taskPlayCtx.currentIndexHandler(taskPlayCtx.currentIndex + 1)
              }
            />
          )}
      </Flex>
    </Flex>
  );
};

TaskPlayComponent.displayName =
  'sub-components/tasks/task-play-components/TaskPlayComponent';

export default TaskPlayComponent;
