import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

const FlexContainer: FC<{ children: React.ReactNode; index: number }> = ({
  children,
  index,
}) => {
  return (
    <Flex
      key={index}
      w='fit-content'
      gap='5px'
      align='center'
      p='2px 8px'
      bg='rgba(177, 229, 252, 1)'
      borderRadius='6px'
    >
      {children}
    </Flex>
  );
};
export default FlexContainer;
