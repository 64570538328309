import { Center, Flex, useToast } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { useAuditTabContext } from 'sub-components/audits/audit-tab/store/context';
import EmptyState from 'sub-components/EmptyState';
import Loader from 'sub-components/Loader';
import useCombinedStore from 'zustandStore/store';
import { Completed, OnGoing, Overdue, Upcoming } from './components';
import { TriggerType } from './components/AuditCard';
import {
  AuditAction,
  getAuditAction,
} from 'sub-components/audits/schedule/audit-settings/audit-setting.helpers';
import { useAuditConfirmation } from 'sub-components/audits/schedule/audit-settings';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const GridView: FC<{
  loading: boolean;
  selectedRange: DateRange | undefined;
  refetchHandler?: () => void;
}> = ({ loading, selectedRange, refetchHandler }) => {
  const data = useAuditTabContext((state) => state.supervisedByMeData);

  const searchQuery = useAuditTabContext(
    (state) => state.supervisedSearchQuery
  );

  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return data;
    }
    return data?.filter((_data) =>
      _data?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
  }, [data, searchQuery]);

  if (loading) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  if (
    (searchQuery || (selectedRange?.from && selectedRange?.to)) &&
    filteredData?.length === 0
  ) {
    return (
      <EmptyState
        image='Search'
        title='Hmm, couldn’t find an audit with that name'
        description='Try using different search terms'
      />
    );
  }

  return (
    <Flex gap={4} overflow='scroll' w='full' minH='60vh'>
      <Overdue data={filteredData} refetchHandler={refetchHandler} />
      <OnGoing data={filteredData} refetchHandler={refetchHandler} />
      <Upcoming data={filteredData} refetchHandler={refetchHandler} />
      <Completed data={filteredData} refetchHandler={refetchHandler} />
    </Flex>
  );
};

GridView.displayName =
  'sub-components/audits/audit-tab/supervised/components/GridView';

export default GridView;
