import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import MyAudits from './my-audits/MyAuditsContainer';
import SupervisedTab from './supervised/SupervisedTab';
import ArchivedTab from './archived';
import { useRouteState } from '../../../routes';

const TabsContent: FC = () => {
  const { state } = useRouteState('audit', {
    selectedTab: 'supervisedByMe',
  });

  return (
    <Flex flexDir='column'>
      {state?.selectedTab === 'supervisedByMe' ? (
        <SupervisedTab />
      ) : state?.selectedTab === 'myAudits' ? (
        <MyAudits />
      ) : (
        <ArchivedTab />
      )}
    </Flex>
  );
};

TabsContent.displayName = 'sub-components/audits/audit-tab/TabsContent';

export default TabsContent;
