import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';
import { useTranslation } from 'react-i18next';
import { BoxHeader } from '../../../../ui-components';
import { HeaderColors } from '../../../../configs';

import TaskStepForm from '../../../tasks/create-task/TaskSteps/TaskStepForm';
import SchedulerForm from '../../../tasks/create-task/Scheduler/SchedulerForm';
import { dataTransformToSubmit } from '../../../tasks/create-task/dataTransform';
import { AddTaskInput } from '../../../tasks/create-task/task.graphql';
import {
  TaskCreateScheme,
  validateTaskData,
} from '../../../tasks/create-task/validation';

import TaskDetails from './TaskDetails';
import TaskAssignee from './TaskAssignee';
import TaskSupervisors from './TaskSupervisors';
import { ATFormInput } from './create-audit-task.types';
import { ResponsesByLocationEntity } from '../../../../pages/Audits/Detail/components/QuestionSection/types/audit-question.types';
import AssigneeHint from '../../../tasks/create-task/Assignees/AssigneeHint';

interface IProps {
  assignedLocations?: string[];

  auditTitle?: string;
  questionTitle?: string;
  responses?: ResponsesByLocationEntity[];

  onSubmit?: (dataToSubmit: AddTaskInput) => Promise<void>;
}

const AuditTaskCreation: FC<IProps> = ({
  auditTitle,
  questionTitle,
  responses,
  assignedLocations,

  onSubmit,
}) => {
  const { t } = useTranslation(['common', 'task', 'audit']);

  const methods = useForm<ATFormInput>({
    defaultValues: {
      assignToType: ['location'],
      taskRunFor: 'once',
      contents: [],
      assignedToLocation: assignedLocations,
    },
  });

  const handleSubmit = async (values: ATFormInput): Promise<void> => {
    try {
      await validateTaskData(
        TaskCreateScheme,
        cloneDeep(values),
        methods as never
      );

      const dataToSubmit = dataTransformToSubmit(cloneDeep(values));

      await onSubmit?.(dataToSubmit);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap='20px'>
        <TaskDetails
          auditTitle={auditTitle}
          questionTitle={questionTitle}
          responses={responses}
        />

        <Flex flexDir='column' gap={4}>
          <BoxHeader
            color={HeaderColors.Orange}
            title={t('task:assignees')}
            fontSize='18px'
          />
          <TaskAssignee assignedLocations={assignedLocations} />
          <AssigneeHint members={[]} />
        </Flex>

        <Box>
          <BoxHeader
            color={HeaderColors.Green}
            title={t('task:steps')}
            fontSize='18px'
          />
          <TaskStepForm />
        </Box>

        <Box>
          <BoxHeader
            color={HeaderColors.Purple}
            title={t('audit:frequency')}
            fontSize='18px'
          />
          <SchedulerForm />
        </Box>

        <TaskSupervisors onPublishClick={handleSubmit} />
      </Flex>
    </FormProvider>
  );
};

export default AuditTaskCreation;
