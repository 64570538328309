import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import Loader from '../../Loader';

const AuditFormLoader: FC = () => {
  const isLoading = useFormState().isLoading;

  if (!isLoading) return null;

  return (
    <Flex
      position='absolute'
      top='0'
      left={0}
      right={0}
      h='100%'
      borderRadius='8px'
      bg='blackAlpha.400'
    >
      <Loader size='lg' mx='auto' style={{ marginTop: '30%' }} />
    </Flex>
  );
};

export default AuditFormLoader;
