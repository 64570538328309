import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';

const NotSubmittedAudit: FC = () => {
  return (
    <Box fontWeight={500} color='rgba(111, 118, 126, 1)' fontStyle='italic'>
      Audit yet to be conducted
    </Box>
  );
};

export default NotSubmittedAudit;
