import { Modal } from 'antd';
import { Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons';

import './ImageModal.scss';

const ImageModal = ({ open, imageUrl, onClose }) => {
  const downloadImage = () => {
    // const a = document.createElement("a");
    // a.href = await toDataURL("http://serverpath/images/50.jpg");
    // a.download = "";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    var a = document.createElement('a');
    a.href = imageUrl;
    a.src = imageUrl;
    a.download = 'output.png';
    a.target = '__blank';
    document.body.appendChild(a);
    a.click();
    // document.body.removeChild(a);
  };
  return (
    <Modal
      className='image-viewer-modal'
      open={open}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <div className='image-download-container' onClick={downloadImage}>
        <FontAwesomeIcon
          icon={faCloudArrowDown}
          color={'#6f767e'}
          onClick={downloadImage}
          style={{ cursor: 'pointer' }}
        ></FontAwesomeIcon>
      </div>
      <Flex justify='center'>
        <img src={imageUrl} />
      </Flex>
    </Modal>
  );
};

export default ImageModal;
