import { SyntheticEvent } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { AnyObject, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { Any, QuestionType } from '../../../types';
import { toArray } from '../../../utils';
import { eventBus } from '../../../shared/eventEmit';
import { AuditResponseVariable, QuestionsState } from './types';

const AUDIT_SAVE_EVENT = '@@delightree-ongoing-audit-save';
const AUDIT_PROGRESS_EVENT = '@@delightree-upload-asset-audit';

type AuditSave = ((event?: SyntheticEvent) => void) | undefined;

type PathName = `data.${number}`;

interface Params {
  pathName: PathName;
  questionId: string;
}

interface ProgressParams {
  eid: string;
  status: boolean;
}

export class AuditTake {
  static onSave(handleSubmit: { (data: Params): Promise<void> }): () => void {
    return eventBus.on(AUDIT_SAVE_EVENT, handleSubmit);
  }

  static save(
    callbackOrEvent: Function | SyntheticEvent | PathName,
    pathName?: PathName | string,
    questionId?: string
  ): AuditSave {
    if (typeof callbackOrEvent === 'function') {
      return function (event?: SyntheticEvent) {
        callbackOrEvent(event);
        eventBus.emit(AUDIT_SAVE_EVENT, {
          pathName: pathName,
          questionId: questionId,
        });
      };
    }

    if (callbackOrEvent instanceof Event) {
      eventBus.emit(AUDIT_SAVE_EVENT, {
        pathName: pathName,
        questionId: questionId,
      });
    } else {
      const _pathName = callbackOrEvent as PathName;
      const _questionId = pathName as string;
      eventBus.emit(AUDIT_SAVE_EVENT, {
        pathName: _pathName,
        questionId: _questionId,
      });
    }
  }

  static onUploadProgress(callback: {
    (data: ProgressParams): void;
  }): () => void {
    return eventBus.on(AUDIT_PROGRESS_EVENT, callback);
  }

  static updateProgress(eid: string, status: boolean): void {
    return eventBus.emit(AUDIT_PROGRESS_EVENT, { eid, status });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateQuestion = async (
  schema: ObjectSchema<QuestionsState, AnyObject, Any, ''>,
  values: QuestionsState,
  serError: UseFormSetError<QuestionsState>
) => {
  const result = await yupResolver<QuestionsState>(schema)(
    values,
    {},
    {} as never
  );

  if (!isEmpty(result?.errors)) {
    Object.entries(result?.errors).forEach(([key, value]) => {
      serError(key as never, value as never);
    });
    return Promise.reject('Task validation failed');
  } else {
    return result.values;
  }
};

function calculateScore(values: QuestionsState) {
  if (
    [
      QuestionType.MULTI_CHOICE,
      QuestionType.CHECKBOX,
      QuestionType.DROPDOWN,
    ].includes(values?.qType) &&
    values.hasScoring
  ) {
    return toArray(values.options).reduce((acc, cur) => {
      if (values.responseId?.includes(cur.eid)) {
        if (values.qType === QuestionType.CHECKBOX) {
          return acc + (cur?.score || 0);
        }
        return Math.max(acc, cur?.score || 0);
      }
      return acc;
    }, 0);
  }
  return undefined;
}

export function isCompleted<T extends Any, Q extends Any>(
  response: T,
  question?: Q
): boolean {
  let qStatus = true;
  const _v = response as Any;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _Q = question as Any;

  // if (
  //   _Q &&
  //   [
  //     QuestionType.MULTI_CHOICE,
  //     QuestionType.CHECKBOX,
  //     QuestionType.DROPDOWN,
  //   ].includes(_Q?.qType)
  // ) {
  //   if (_Q?.requiresEvidence && !_v?.images?.length) {
  //     qStatus = !_Q?.options?.some(
  //       // @ts-ignore
  //       (op) => op.requiresEvidence && _v?.responseId?.includes(op.eid)
  //     );
  //   }
  // }

  return (
    !!(_v?.response || _v?.responseUrl?.length || _v?.responseId?.length) &&
    qStatus
  );
}

export const mapDataToInput = (
  values: QuestionsState
): AuditResponseVariable => {
  return {
    qid: values.eid,
    qType: values.qType,

    images: toArray(values.images).map((image) => {
      return {
        url: image.url,
        name: image.name,
        type: image.type,
        fileSize: image.fileSize,
        mimetype: image.mimetype,
        optionId: image.optionId,
      };
    }),

    isFlagged: values.isFlagged,
    noteText: values.noteText?.trim(),

    response: values.response?.trim(),
    responseId: values.responseId || [],
    responseUrl: values.responseUrl || [],
    score: calculateScore(values),
  };
};
