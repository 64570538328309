import * as Yup from 'yup';
import { Any } from '../../../types';
import { SectionEntity } from './types';

const auditSchema = Yup.object().shape({
  signature: Yup.object().test('signatureTest', async (value, context) => {
    const status = Yup.object()
      .shape({
        text: Yup.string().required(),
        font: Yup.string().required(),
      })
      .isValidSync(value);

    if (!status) {
      return context.createError({
        message: 'Auditor signature is required',
      });
    }

    return true;
  }),
  comments: Yup.string(),
  sectionData: Yup.mixed().test('sectionDataTest', (_value, context) => {
    const value: SectionEntity[] = _value as Any;

    if (
      value?.some((section) => {
        return section?.data?.some(
          (ques) => ques.isRequired && !ques.isCompleted
        );
      })
    ) {
      return context.createError({
        message: 'Questions unanswered',
      });
    }

    return true;
  }),
});

export { auditSchema };
