import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { Loader } from '../../../../../sub-components';
import SearchSection from './components/SearchSection/SearchSection';
import AuditHistoryTable from './components/AuditHistoryTable/AuditHistoryTable';
import { useControl } from './control';
import { Control } from './types';

const AuditHistorySection: FC = () => {
  const control: Control = useControl();

  if (control?.loading) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <Flex flexDir='column' gap={4}>
      <SearchSection
        locationFilterOptions={control?.locationFilterOptions}
        selectedDateRange={control?.selectedDateRange}
        setSelectedDateRangeHandler={control?.setSelectedDateRangeHandler}
        selectedLocationsFilter={control?.selectedLocationsFilter}
        handleFilterChange={control?.handleFilterChange}
      />
      <AuditHistoryTable
        data={control?.processedData}
        pageInfo={control?.pageInfo}
        refetchHandler={control?.refetchHandler}
        updatePageHandler={control?.updatePageHandler}
      />
    </Flex>
  );
};

AuditHistorySection.displayName =
  'pages/Audits/Detail/components/AuditHistory/AuditHistory';

export default AuditHistorySection;
