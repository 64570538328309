import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectOption } from 'atoms';
import React, { FC } from 'react';

interface IProps {
  options: SelectOption<string>[];
  value: SelectOption<string>[];
  handleOptionClick: (option: SelectOption<string>) => void;
}

const Options: FC<IProps> = ({ options, value, handleOptionClick }) => {
  return (
    <Flex
      position='absolute'
      flexDir='column'
      gap={2}
      maxH='30vh'
      overflowY='scroll'
      top='100%'
      left='0'
      right='0'
      mt='4px'
      bg='white'
      border='1px solid rgba(239, 239, 239, 1)'
      borderRadius='12px'
      boxShadow='md'
      zIndex={2}
      p={2}
    >
      {options.map((option, index) => {
        const isSelected = value?.some((val) => val?.value === option?.value);
        return (
          <Flex
            key={index}
            p='8px 12px'
            gap={2}
            align='center'
            cursor='pointer'
            bg={isSelected ? 'rgba(42, 133, 255, 0.1)' : 'none'}
            color={
              isSelected ? 'rgba(42, 133, 255, 1)' : 'rgba(111, 118, 126, 1)'
            }
            borderRadius={isSelected ? '12px' : 'none'}
            _hover={{
              color: isSelected ? 'none' : 'rgba(26, 29, 31, 1)',
              bg: isSelected
                ? 'rgba(42, 133, 255, 0.1)'
                : 'rgba(244, 244, 244, 1)',
              borderRadius: '12px',
            }}
            onClick={() => handleOptionClick(option)}
          >
            <FontAwesomeIcon
              icon={(isSelected ? faSquareCheck : faSquare) as IconProp}
              style={{
                color: isSelected
                  ? 'rgba(42, 133, 255, 1)'
                  : 'rgba(111, 118, 126, 0.4)',
              }}
              size='lg'
            />
            <Box fontWeight={600} fontSize='15px'>
              {option?.label}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default Options;
