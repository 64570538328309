import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import SectionFilter from '../../../../sub-components/audits/audit-tab/supervised/components/GridView/components/SectionFilter';
import { useAuditDetailContext } from '../store/context';

interface IBaseTabProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const BaseTab: FC<IBaseTabProps> = ({ label, selected, onClick }) => {
  return (
    <Center
      as='span'
      p='8px 16px'
      borderRadius='8px'
      fontWeight={600}
      fontSize='15px'
      color={selected ? 'black' : '#6F767E'}
      bg={selected ? '#EFEFEF' : '#FCFCFC'}
      cursor='pointer'
      onClick={() => onClick()}
    >
      {label}
    </Center>
  );
};

const FilterSection: FC<{ isAuditRepeating: boolean }> = ({
  isAuditRepeating,
}) => {
  const sessionsLoading = useAuditDetailContext(
    (state) => state.sessionLoading
  );
  const selectedTab = useAuditDetailContext((state) => state.selectedTab);
  const selectedSessionLabel = useAuditDetailContext(
    (state) => state.selectedSession?.label
  );
  const displaySessionOptions = useAuditDetailContext(
    (state) => state.displaySession
  );
  const selectedSession = useAuditDetailContext(
    (state) => state.selectedSession
  );
  const updateSelectedSession = useAuditDetailContext(
    (state) => state.updateSelectedSession
  );
  const updateSelectedAuditSessionId = useAuditDetailContext(
    (state) => state.updateSelectedAuditSessionId
  );
  const locationFilterOptions = useAuditDetailContext(
    (state) => state.locationFilterOptions
  );
  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );
  const showLocationsFilter = useAuditDetailContext(
    (state) => state.showLocationsFilter
  );
  const updateSelectedLocationFilter = useAuditDetailContext(
    (state) => state.updateSelectedLocationFilter
  );
  const udpateSelectedTab = useAuditDetailContext(
    (state) => state.updateSelectedTab
  );
  return (
    <Flex justify='space-between'>
      <Flex gap={2}>
        <BaseTab
          label='Overview'
          selected={selectedTab === 'overview'}
          onClick={() => udpateSelectedTab('overview')}
        />
        {isAuditRepeating && (
          <BaseTab
            label='Audit History'
            selected={selectedTab === 'auditHistory'}
            onClick={() => udpateSelectedTab('auditHistory')}
          />
        )}
        <BaseTab
          label='Question wise'
          selected={selectedTab === 'questionWise'}
          onClick={() => udpateSelectedTab('questionWise')}
        />
        <BaseTab
          label='Flagged responses'
          selected={selectedTab === 'flaggedResponse'}
          onClick={() => udpateSelectedTab('flaggedResponse')}
        />
      </Flex>
      {selectedTab === 'flaggedResponse' && isAuditRepeating && (
        <SectionFilter
          customTrigger={
            <Flex
              color='rgba(111, 118, 126, 1)'
              border='2px solid rgba(239, 239, 239, 1)'
              borderRadius='12px'
              p='8px 20px'
              fontWeight={600}
              cursor='pointer'
              align='center'
              justify='space-between'
            >
              <Box as='span' isTruncated maxW='100px'>
                {selectedSessionLabel}
              </Box>
              <ChevronDownIcon fontSize='20px' mt='2px' />
            </Flex>
          }
          loading={sessionsLoading}
          options={displaySessionOptions}
          value={selectedSession}
          handleOptionClick={(value) => {
            updateSelectedSession(value);
            updateSelectedAuditSessionId(value?.value);
          }}
        />
      )}
      {selectedTab === 'overview' && showLocationsFilter && (
        <SectionFilter
          customTrigger={
            <Flex
              color='rgba(111, 118, 126, 1)'
              border='2px solid rgba(239, 239, 239, 1)'
              borderRadius='12px'
              p='8px 20px'
              fontWeight={600}
              cursor='pointer'
              align='center'
              justify='space-between'
            >
              <Box as='span' isTruncated maxW='100px'>
                {selectedLocationFilter?.label}
              </Box>
              <ChevronDownIcon fontSize='20px' mt='2px' />
            </Flex>
          }
          options={locationFilterOptions}
          value={selectedLocationFilter}
          handleOptionClick={(value) => {
            updateSelectedLocationFilter(value);
          }}
        />
      )}
    </Flex>
  );
};

export default FilterSection;
