import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IFormItemsEntity, IFormListEntity } from 'pages/forms/forms-types';
import FormDataContext from 'pages/forms/FormStore/form-data-context';

import { AmplitudeEvent, deployEvent } from 'shared';
import { FORMS_PAGINATION } from './form-internal.graphql';
import { UPDATE_FORM_QUERY } from 'sub-components/forms/form.graphql';
import { SendForm } from '../SendForm';
import AllResponseFormTable from './AllResponseFormTable';
import AllFormResponseArchiveModal from './AllFormResponseArchiveModal';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { useFormDelete } from '../FormDelete';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

export type FormSortEntity =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'TITLE_ASC'
  | 'TITLE_DESC'
  | 'CATEGORY_ASC'
  | 'CATEGORY_DESC';

interface IProps {
  activeTabIndex: number;
  searchValue: string;
  categoryFilter: string[];
}

const AllResponseTabPanel: FC<IProps> = ({
  activeTabIndex,
  searchValue,
  categoryFilter,
}) => {
  const formCtx = useContext(FormDataContext);
  const dashboardCtx = useContext(DashboardDataContext);
  const selectedPage = dashboardCtx?.navigationPersistData?.forms?.selectedPage;
  const { t } = useTranslation(['form']);
  const { t: tc } = useTranslation('common');
  const history = useHistory();
  const [sendForm, setSendForm] = useState<IFormListEntity['items'][number]>();
  const [selectedItem, setSelectedItem] = useState<IFormItemsEntity>();
  const [sortBy, setSortBy] = useState<FormSortEntity>('_ID_DESC');
  const toast = useToast({
    position: 'top-right',
  });
  const [showShowArchiveModal, setShouldShowArchiveModal] = useState(false);

  const formDeleteMethod = useFormDelete();

  const getOptions = useCallback(() => {
    const filter: Record<string, unknown> = {
      status: 'active',
    };
    if (searchValue) {
      filter.query = searchValue;
    }

    if (categoryFilter?.length) {
      filter.categoryId = categoryFilter;
    }

    return {
      page: selectedPage,
      perPage: 10,
      filter: filter,
      sort: sortBy,
    };
  }, [selectedPage, sortBy, searchValue, activeTabIndex, categoryFilter]);

  const [getFormsListHandler, { loading }] = useLazyQuery(FORMS_PAGINATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      formCtx.updateFormsListDataHandler(data?.FormsPagination);
      // formCtx.updateFormsListDataHandler({ count: 0, items: [], pageInfo: { currentPage: 0, hasNextPage: false, hasPreviousPage: false, itemCount: 0, pageCount: 0, perPage: 0 } });
    },
  });

  const onPageChangeHandler = (newPage: number) => {
    let navigationPersistData = dashboardCtx?.navigationPersistData;
    let formData = navigationPersistData?.forms;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationPersistData,
      forms: { ...formData, selectedPage: newPage },
    });
  };

  const onSortByChange = useCallback((sortBy: FormSortEntity) => {
    setSortBy(sortBy);
  }, []);

  useEffect(() => {
    if (activeTabIndex === 0) {
      getFormsListHandler({
        variables: getOptions(),
      });
    }
  }, [getOptions]);

  const closeShowArchiveModal = () => {
    setShouldShowArchiveModal(false);
  };

  const archiveFormHandler = () => {
    setShouldShowArchiveModal(false);
    updateFormData({
      variables: {
        input: {
          eid: selectedItem?.eid,
          status: 'inactive',
        },
      },
    });
  };

  const deleteNavigateHandler = () => {
    let pageInfo = formCtx?.formsListData?.pageInfo;
    if (pageInfo.itemCount % 10 === 1 && pageInfo.itemCount > 10) {
      if (
        pageInfo.currentPage > 1 &&
        pageInfo.currentPage === pageInfo.pageCount
      ) {
        return pageInfo.currentPage - 1;
      }
    }
    return pageInfo.currentPage;
  };

  const refetchFormList = () => {
    let newPage = deleteNavigateHandler();
    let navigationPersistData = dashboardCtx?.navigationPersistData;
    let formData = navigationPersistData?.forms;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationPersistData,
      forms: { ...formData, selectedPage: newPage },
    });
    getFormsListHandler({
      variables: {
        page: newPage,
        perPage: 10,
        sort: '_ID_ASC',
        filter: {
          status: 'active',
        },
      },
    });
  };

  const [updateFormData, { loading: updating }] = useMutation(
    UPDATE_FORM_QUERY,
    {
      onCompleted: () => {
        toast({
          title: tc('success'),
          description: t('form:form_inactive'),
          status: 'success',
        });
        refetchFormList();
      },
      onError: (error) => {
        console.log(error);
        toast({
          title: tc('error'),
          description: tc('something_wrong'),
          status: 'error',
        });
      },
    }
  );

  const clickedItemsHandler = (
    clickedItem: string,
    form: IFormListEntity['items'][number]
  ) => {
    if (clickedItem === 'fill-form') {
      // deployEvent(AmplitudeEvent.FORMS_FILL_FROM_FORMS_LIST);
      deployEvent(AmplitudeEventNames?.FILL_FORM_CLICK, {
        form_id: form.eid,
        fill_form_funnel_id: 3,
        form_category: form?.category,
        form_title: form?.title,
      });
      return history.push(`/form/submit/${form.eid}`);
    }
    if (clickedItem === 'send-form') {
      deployEvent(AmplitudeEvent.FORMS_SEND);
      setSendForm(form);
    }
    if (clickedItem === 'view-form-response') {
      deployEvent(AmplitudeEvent.FORMS_EXISTING_FORM_SEE_RESPONSE_BUTTON_CLICK);
      history.push(`/forms/response/${form.eid}`);
    }
    if (clickedItem === 'edit-form') {
      deployEvent(AmplitudeEvent.FORMS_EXISTING_FORM_MODIFY_FORM_BUTTON);
      history.push(`/forms/edit/${form.eid}`);
    }
    if (clickedItem === 'mark-inactive') {
      deployEvent(AmplitudeEvent.FORMS_MARK_INACTIVE);
      setSelectedItem(form);
      setShouldShowArchiveModal(true);
    }
    if (clickedItem === 'appear-in') {
      deployEvent(AmplitudeEvent.FORMS_APPEAR_IN);
      setSelectedItem(form);
    }
    if (clickedItem === 'delete') {
      deployEvent(AmplitudeEvent.FORMS_DELETE_CLICK);
      formDeleteMethod({
        formId: form.eid,
        onFormDeleted: refetchFormList,
      });
    }
  };

  return (
    <Box h={formCtx?.formsListData?.items?.length > 0 ? '' : '100%'}>
      <AllResponseFormTable
        clickedItemsHandler={clickedItemsHandler}
        onPageChangeHandler={onPageChangeHandler}
        loading={loading}
        onSortByChange={onSortByChange}
      />

      <SendForm
        isOpen={!!sendForm?.eid}
        onClose={() => setSendForm(undefined)}
        formData={sendForm}
      />
      {showShowArchiveModal && (
        <AllFormResponseArchiveModal
          showShowArchiveModal={showShowArchiveModal}
          closeShowArchiveModal={closeShowArchiveModal}
          archiveFormHandler={archiveFormHandler}
        />
      )}
    </Box>
  );
};

export default AllResponseTabPanel;
