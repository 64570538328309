import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  usePopoverContext,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StringOrNumber } from 'types';
import { ActionButton } from 'ui-components';

import { ReactComponent as FlagIcon } from '../../../../../../assets/images/flagSvg.svg';
import { ReactComponent as SolidFlagIcon } from '../../../../../../assets/images/audits/FlagFilled.svg';
import { IFormInput } from '../../../form-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ContentProps {
  questionIndex: number;
  value?: boolean;
  onChange: (value: boolean) => void;
}

const Content: FC<ContentProps> = ({ questionIndex }) => {
  const { t } = useTranslation(['common', 'audit']);
  const { onClose } = usePopoverContext();
  const { getValues, setValue } = useFormContext<IFormInput>();

  const options = useMemo(() => {
    return (getValues(`questions.${questionIndex}.options`) || []).filter(
      (O) => O.label
    );
  }, [questionIndex]);

  const [values, setChecked] = useState<StringOrNumber[]>(
    options.filter((o) => o.isFlagged).map((o) => o.tempEid!)
  );

  const actionFunction = () => {
    const _options = getValues(`questions.${questionIndex}.options`) || [];

    const haveFlag = _options.reduce((acc, option, currentIndex) => {
      if (values.includes(option.tempEid!)) {
        _options[currentIndex].isFlagged = true;
        return acc || true;
      } else {
        _options[currentIndex].isFlagged = false;
      }
      return acc;
    }, false);

    setValue(`questions.${questionIndex}.options`, _options);
    setValue(`questions.${questionIndex}.hasFlaggedResponse`, haveFlag);
  };

  const haveNoOptions = useMemo(() => {
    return options.filter((O) => !O.isDisabled).length === 0;
  }, [options]);

  return (
    <>
      <PopoverHeader padding={3}>
        <Flex fontSize='16px' fontWeight='600'>
          {t('audit:flaggedResponses')}
        </Flex>
      </PopoverHeader>
      <PopoverBody paddingY={3} paddingX={0}>
        <Flex
          flexDir='column'
          gap={1}
          overflowY='auto'
          maxH='380px'
          sx={{
            '.chakra-checkbox__label': {
              color: '#6F767E',
              fontSize: 14,
              fontWeight: 600,
              _checked: {
                color: '#2A85FF',
              },
            },
            '.chakra-checkbox__control': {
              borderRadius: '6px',
            },
          }}
        >
          <CheckboxGroup value={values} onChange={setChecked}>
            {haveNoOptions ? (
              <Box
                fontSize='12px'
                fontWeight='500'
                color='#9A9FA5'
                fontStyle='italic'
                paddingX={3}
              >
                No options available for flagged
              </Box>
            ) : null}

            {options.map((option, index) => (
              <Checkbox
                size='lg'
                value={option.tempEid}
                key={index}
                padding={3}
                borderRadius='10px'
                isDisabled={!option.tempEid}
                hidden={option.isDisabled}
                _hover={{ bg: '#EFEFEF' }}
                _checked={{ bg: '#2A85FF1A' }}
              >
                {option.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Flex>
      </PopoverBody>
      <PopoverFooter
        paddingX={3}
        paddingBottom={6}
        paddingTop={0}
        borderTopWidth='0'
      >
        <ActionButton
          size='lg'
          width='full'
          colorScheme='blue'
          close={onClose}
          actionFn={actionFunction}
          isDisabled={!options.length}
        >
          {t('common:apply')}
        </ActionButton>
      </PopoverFooter>
    </>
  );
};

interface IProps {
  questionIndex: number;
  value?: boolean;
  onChange: (value: boolean) => void;
  onBlur?: () => void;
}

const FlagResponse: FC<IProps> = ({
  questionIndex,
  value,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation(['common', 'audit']);

  const props = useMemo(() => {
    if (value) {
      return {
        // bg: '#EFEFEF',
      };
    }
    return {};
  }, [value]);

  return (
    <Popover isLazy onClose={onBlur} placement='bottom-end'>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <Flex
          gap={2}
          color='#1A1D1F'
          fontSize='12px'
          fontWeight='500'
          cursor='pointer'
          borderRadius='5px'
          padding='4px 8px'
          alignItems='center'
          {...props}
          _hover={{ bg: '#EFEFEF' }}
          // _expanded={{ bg: '#EFEFEF' }}
        >
          {value ? (
            <SolidFlagIcon color='#000000' width='20' height='20' />
          ) : (
            <FlagIcon color='#1A1D1F' width='20' height='20' />
          )}
          <Box as='span' noOfLines={1}>
            {t('audit:flaggedResponses')}
          </Box>
          <FontAwesomeIcon
            width='20px'
            height='20'
            color='#6F767E'
            icon={faChevronDown as IconProp}
          />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        w='280px'
        borderRadius='16px'
        border='none'
        boxShadow='0px 32px 48px -8px #0000001A, 0px 0px 14px -4px #0000000D, 0px 40px 64px -12px #00000014'
        paddingX='12px'
        _focus={{
          outline: '2px solid transparent',
          outlineOffset: '2px',
        }}
      >
        <Content
          questionIndex={questionIndex}
          value={value}
          onChange={onChange}
        />
      </PopoverContent>
    </Popover>
  );
};

export default FlagResponse;
