import React, { FC } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFormInput } from '../types';
import { useMediaIsUploading } from './useMediaIsUploading';
import { Button } from '../../../../atoms';

interface IProps {
  selectedSection: number;
  getValues: UseFormGetValues<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
}

const NextSectionButton: FC<IProps> = ({
  selectedSection,
  getValues,
  setValue,
}) => {
  const { t } = useTranslation('audit');
  const maxSectionCount = getValues('maxSectionCount');

  const _nextSectionIndex = selectedSection + 1;

  const mediaUploading = useMediaIsUploading();

  if (_nextSectionIndex <= maxSectionCount - 1) {
    return (
      <Tooltip
        placement='top-end'
        hasArrow
        label={t('uploadInProgressWarning')}
        offset={[0, 16]}
        isDisabled={!mediaUploading}
        shouldWrapChildren
      >
        <Button
          colorScheme='blue'
          variant='link'
          rightIcon={<ArrowForwardIcon />}
          _hover={{
            textDecoration: 'none',
          }}
          isDisabled={mediaUploading}
          onClick={() => setValue('selectedSection', _nextSectionIndex)}
        >
          {getValues(`sectionData.${_nextSectionIndex}.sectionName`)}
        </Button>
      </Tooltip>
    );
  }
  return <div />;
};

export default NextSectionButton;
