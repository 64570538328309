/*
    The structure coming from backend is:

    chatConfig: {
        locationChat: boolean;
        restrictions: boolean;
        newChannel: {
            authRoles: string[];
            members: string[];
            restrictionType: role | authRole | anyone | creator;
            roles: string[];
        };
        startDM: {
            authRoles: string[];
            members: string[];
            restrictionType: role | authRole | anyone | creator;
            roles: string[];
        }
    }

*/

import { useCallback } from 'react';
import { checkChatAccess } from 'utils/checkChatAccess';
import { AuthRole } from '../authorization';
import { shallowEqual } from '../utils';
import { useUserDataSelector, useUserEntity } from './useUserDataQuery';

interface IUserDetails {
  eid: string;
  role: string;
  authRole: AuthRole;
  [k: string]: string;
}

type HasChatAccess = (userDetails: IUserDetails) => boolean;

export const useHasChatAccess = (): HasChatAccess => {
  const config = useUserEntity((entity) => entity?.chatConfig, shallowEqual);
  const loggedInUserDetails = useUserDataSelector((state) => state);
  const entityLocations = useUserEntity(
    (entity) => entity?.locations,
    shallowEqual
  );
  return useCallback(
    (userDetails: IUserDetails) => {
      return checkChatAccess(
        config,
        userDetails,
        loggedInUserDetails,
        entityLocations
      );
    },
    [config]
  );
};
