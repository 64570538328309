import { useState, useEffect } from 'react';
import { useLazyQuery, makeVar } from '@apollo/client';
import { GET_USERS } from '../pages/TasksDashboard/SupervisedTasks/services/supervised-tasks.graphql';
import { useUserDataSelector } from './useUserDataQuery';

/**
 * @deprecated Please import `usersEntityObj` from "sub-components/Header" instead.
 * This reactive variable is deprecated and will be removed in future releases.
 */
export const usersEntityObj = makeVar([]);

/**
 * @deprecated The `useUsersEntity` hook is deprecated and will be removed in future releases.
 * Please use the `useNewUsersEntity` hook from the `new-hooks` module instead.
 *
 * This hook fetches user entities based on the current user's `entityId`.
 *
 * @returns {Object} An object containing `usersEntity`, `refetchUsersEntity`, `loading`, and `error`.
 */
export const useUsersEntity = () => {
  // Optional: Runtime warning for deprecation
  useEffect(() => {
    console.warn(
      'Warning: `useUsersEntity` is deprecated and will be removed in future releases. Please use `useNewUsersEntity` from `new-hooks` instead.'
    );
  }, []);

  const entityId = useUserDataSelector((state) => state?.entityId);
  const [getUsersEntityHandler, { data, loading, error }] = useLazyQuery(
    GET_USERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        entityId: entityId,
      },
    }
  );

  const [usersEntity, setUsersEntity] = useState([]);

  useEffect(() => {
    if (data) {
      setUsersEntity(data.EntityUser);
      usersEntityObj(data.EntityUser); // Update the reactive variable
    }
  }, [data]);

  // Function to refetch data
  const refetchUsersEntity = () => {
    getUsersEntityHandler();
  };

  return { usersEntity, refetchUsersEntity, loading, error };
};
