import { FlexCard } from 'atoms';
import UpcomingAuditSection from './components/UpcomingAuditsSection/UpcomingAuditSection';
import AuditResultSection from './components/AuditResultsSection/AuditResultsSection';
import React, { FC } from 'react';
import { useControlLayer } from './layers';
import { Center, Flex } from '@chakra-ui/react';
import Loader from 'sub-components/Loader';
import LocationLineGraph from 'sub-components/audits/detail/components/Overview/components/LocationLineGraph';
import GraphSection from './components/GraphSection';
import BaseLayoutWrapper from 'sub-components/BaseLayoutWrapper';

interface IProps {
  homePageRender?: boolean;
}

const LocationOwnerAuditContainer: FC<IProps> = ({ homePageRender }) => {
  const { completedData, upcomingData } = useControlLayer();
  if (homePageRender) {
    return (
      <Flex>
        <UpcomingAuditSection
          auditData={upcomingData?.auditData}
          homePageRender
        />
      </Flex>
    );
  }
  return (
    <FlexCard flex={1} padding='24px 30px' w='full'>
      {completedData?.loading || upcomingData?.loading ? (
        <Center h='100%' w='full'>
          <Loader />
        </Center>
      ) : (
        <Flex flexDir='column' gap={4} w='full'>
          {/* <LocationLineGraph auditData={auditData} /> */}
          <GraphSection />
          <Flex
            border='.5px solid rgba(199, 199, 199, 1)'
            borderRadius='10px'
            p='20px'
          >
            <UpcomingAuditSection auditData={upcomingData?.auditData} />
          </Flex>
          <Flex
            border='.5px solid rgba(199, 199, 199, 1)'
            borderRadius='10px'
            p='20px'
          >
            <AuditResultSection auditData={completedData?.auditData} />
          </Flex>
        </Flex>
      )}
    </FlexCard>
  );
};

export default LocationOwnerAuditContainer;
