import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

interface Progress {
  progress: Record<string, number>;
}

export const useMediaIsUploading = (): boolean => {
  const progress = useWatch<Progress, 'progress'>({
    name: 'progress',
    defaultValue: {},
  });

  return useMemo(() => {
    return Object.values(progress).some((a) => a);
  }, [progress]);
};
