import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import TemplateGridView from './TemplateGridView';
import TemplateListView from './TemplateListView';
import { useTemplatePreview } from '../../template-preview';
import { AuditTemplateItem } from '../../query/audits-listing.graphql';
import EmptyState from '../../../../EmptyState';

type TemplateLibraryContainerProps = {
  selectedViewType: 'grid' | 'list';
  allAuditTemplates?: AuditTemplateItem[];
  loading: boolean;
  searchActive?: boolean;
};

const TemplateLibraryContainer: FC<TemplateLibraryContainerProps> = ({
  selectedViewType,
  allAuditTemplates = [],
  loading,
  searchActive,
}) => {
  const templatePreview = useTemplatePreview();

  const onPreview = (template: AuditTemplateItem) => {
    templatePreview({
      templateId: template.eid,
    });
  };

  if (searchActive && !allAuditTemplates?.length) {
    return (
      <Box paddingTop='80px'>
        <EmptyState
          image='Search'
          imageSize='160px'
          title='Hmm, couldn’t find a template with that name'
          description='Try again with different search terms or filters'
        />
      </Box>
    );
  }

  return (
    <Box marginTop={5} borderRadius='10px'>
      {selectedViewType === 'grid' ? (
        <TemplateGridView
          isLoading={loading}
          allAuditTemplates={allAuditTemplates}
          onPreview={onPreview}
        />
      ) : (
        <TemplateListView
          isLoading={loading}
          allAuditTemplates={allAuditTemplates}
          onPreview={onPreview}
        />
      )}
    </Box>
  );
};

export default TemplateLibraryContainer;
