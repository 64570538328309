import { useCallback } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import GoLiveDateContent from './GoLiveDateContent';

import { BoxHeader, useConfirm } from '../../../../../ui-components';

interface IProps {
  updateTaskDate?: (...args: any[]) => any | PromiseLike<any>;
  updateLiveDate?: (...args: any[]) => any | PromiseLike<any>;
  textContent?: string;
}

type GoLiveDate = (props: IProps) => void;

export const useGoLiveDateConfirmation = (): GoLiveDate => {
  const confirm = useConfirm();

  return useCallback(
    ({ updateTaskDate, updateLiveDate, textContent }) => {
      confirm({
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader
                title='Update the go-live date or adjust the task due dates'
                color='rgba(255, 188, 153, 1)'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: <GoLiveDateContent textContent={textContent} />,
        isCentered: true,
        footerProps: {
          marginTop: '20px',
          display: 'flex',
          flexDir: 'column-reverse',
          gap: '10px',
        },
        contentProps: {
          py: '16px',
          px: '24px',
          minW: '700px',
          borderRadius: '16px',
        },
        cancelButtonProps: {
          //   backgroundColor: 'rgba(243, 165, 13, 1)',
          //   color: 'white',
          size: 'lg',
          width: '100%',
          fontSize: '14px',
          fontWeight: '700',
          marginLeft: '10px',
          //   border: '1px solid black',
          //   sx: {
          //     _hover: {
          //       //   backgroundColor: 'rgba(243, 165, 13, 1)',
          //     },
          //   },
        },
        okButtonProps: {
          colorScheme: 'blue',
          size: 'lg',
          fontSize: '14px',
          fontWeight: '700',
          width: '100%',
        },
        okText: 'Update Go-live date',
        cancelText: 'Update later',
        onCancel: updateTaskDate,
        onOK: updateLiveDate,
      });
    },
    [confirm]
  );
};
