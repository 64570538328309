import React, { FC, useMemo } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';
import { useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';
import { Dropdown, SelectOption } from 'atoms';
import { IFormInput } from '../../audit-schedule.types';

const toValue = (value?: string): SelectOption | undefined =>
  value ? { value: value, label: value } : undefined;

interface Option {
  label: TFuncKey<'audit', 'auditType'>;
  value: string;
}

interface IProps {
  isEditing?: boolean;
}

const AuditFrequency: FC<IProps> = ({ isEditing }) => {
  const { t } = useTranslation('audit', { keyPrefix: 'auditType' });
  const { resetField } = useFormContext<IFormInput>();

  const options = useMemo((): Option[] => {
    return [
      {
        value: 'oneTime',
        label: 'oneTime',
      },
      {
        value: 'repeating',
        label: 'repeating',
      },
      // {
      //   value: 'forever',
      //   label: 'forever',
      // },
    ];
  }, []);

  const resetFields = (
    value: IFormInput['repeatDetails']['auditFrequency']
  ) => {
    if (value === 'oneTime') {
      resetField('repeatDetails.repeatType');
      resetField('repeatDetails.weekly', { defaultValue: [] });
      resetField('repeatDetails.monthly');
      resetField('repeatDetails.yearly');
    } else if (value === 'repeating') {
      resetField('repeatDetails.auditDue');
      resetField('repeatDetails.auditDueDate');
    }
  };

  return (
    <Controller<IFormInput, 'repeatDetails.auditFrequency'>
      name='repeatDetails.auditFrequency'
      rules={{
        required: 'Frequency required',
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={fieldState.invalid}>
            <Flex gap={4} align='center'>
              <Box as='span' fontWeight={600} color='#33383F'>
                How often do they have to do the audit?
              </Box>
              <Dropdown
                size='lg'
                options={options}
                isSearchable={false}
                selectStyles={{
                  container: { minWidth: '240px' },
                  singleValue: {
                    fontSize: '14px',
                    fontWeight: '500',
                  },
                  placeholder: {
                    fontSize: '14px',
                  },
                }}
                placeholder='Select frequency type'
                value={toValue(field.value)}
                disabled={field.disabled || isEditing}
                onChange={(newValue) =>
                  callAll(field.onChange, resetFields)(newValue.value)
                }
                getOptionLabel={(option: Option) => t(option.label)}
                inputStyle={{
                  borderWidth: '2px',
                  borderRadius: '12px',
                  outline: 'none',
                  backgroundColor: 'white',
                }}
              />
            </Flex>
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default AuditFrequency;
