import React, { FC, useRef } from 'react';
import { cloneDeep } from '@apollo/client/utilities';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AmplitudeEvent, deployEvent } from '../../../shared';

import { IFormInput } from './task.types';
import { dataTransformToSubmit } from './dataTransform';

import { AddTaskInput, addTaskQuery, AddTaskVariable } from './task.graphql';
import { TaskCreateScheme, validateTaskData } from './validation';
import TaskCreationBase, { TaskCreationRef } from './TaskCreationBase';
import { usersEntityObj } from 'sub-components/Header';

interface IProps {}

const TaskCreation: FC<IProps> = () => {
  const history = useHistory();
  const toast = useToast({
    position: 'top-right',
  });
  const userEntityData = useReactiveVar(usersEntityObj);
  const { t } = useTranslation(['common', 'task']);

  const taskFormRef = useRef<TaskCreationRef>(null);

  const [submitTask] = useMutation<never, AddTaskVariable>(addTaskQuery, {
    onCompleted: () => {
      toast({
        title: t('common:success'),
        description: t('task:createSuccess'),
        status: 'success',
      });
      history.goBack();
    },
    onError: (error) => {
      // TODO populate error to form field
      console.log(error);
      toast({
        title: t('common:error'),
        description: t('common:something_wrong'),
        status: 'error',
      });
    },
  });

  const captureEvent = (dataToSubmit: AddTaskInput, taskId: string) => {
    let selectedLocations = dataToSubmit?.assignedToLocation || [];
    let selectedRoles = dataToSubmit?.assignedToRole || [];
    let selectedMembers = dataToSubmit?.assignedToUsers || [];
    let selectedSupervisors = dataToSubmit?.supervisors || [];
    let totalAssignees =
      [...selectedLocations, ...selectedRoles, ...selectedMembers]?.length || 0;
    let supervisorNames =
      userEntityData
        ?.filter((entity) =>
          selectedSupervisors?.some((sup) => entity?.eid?.includes(sup))
        )
        ?.map((supervisor) => supervisor?.name) || [];
    let memberNames =
      userEntityData
        ?.filter((entity) =>
          selectedMembers?.some((mem) => entity?.eid?.includes(mem))
        )
        ?.map((member) => member?.name) || [];
    let locationNames =
      userEntityData
        ?.filter((entity) =>
          selectedLocations?.some((loc) => entity?.eid?.includes(loc))
        )
        ?.map((location) => location?.name) || [];

    deployEvent(AmplitudeEvent.PUBLISH_TASK, {
      task_id: taskId,
      total_tasks_published: '',
      task_edit_funnel_id: 5,
      create_task_funnel_id: 4,
      edited_sections: [],
      task_title: dataToSubmit?.title,
      description: dataToSubmit?.description,
      frequency: dataToSubmit?.repeatDetails?.taskRunFor,
      task_due: dataToSubmit?.repeatDetails?.taskDue,
      task_due_date: dataToSubmit?.repeatDetails?.taskDueDate,
      task_due_time: dataToSubmit?.repeatDetails?.dueTime,
      total_steps_added: dataToSubmit?.contents?.length || 0,
      total_forms_added:
        dataToSubmit?.contents?.filter((content) => content?.type === 'form')
          ?.length || 0,
      total_chapters_added:
        dataToSubmit?.contents?.filter((content) => content?.type === 'sop')
          ?.length || 0,
      total_to_do_items_added:
        dataToSubmit?.contents?.filter((content) => content?.type === 'todo')
          ?.length || 0,
      location_assignee_type: dataToSubmit?.assignedLocationsType,
      total_assignees: totalAssignees,
      total_supervisors: selectedSupervisors?.length || 0,
      supervisors_name: supervisorNames,
      members: memberNames,
      location: locationNames,
      jobs: selectedRoles,
    });
  };

  const handleSubmit = async (values: IFormInput) => {
    try {
      await validateTaskData(
        TaskCreateScheme,
        cloneDeep(values),
        taskFormRef.current!
      );

      const dataToSubmit = dataTransformToSubmit(cloneDeep(values));
      const res = await submitTask({
        variables: {
          input: dataToSubmit,
        },
      });

      if (res.errors) {
        return Promise.reject('Task creation failed');
      }
      deployEvent(AmplitudeEvent.TASK_PUBLISHED);
      captureEvent(dataToSubmit, res?.data?.AddTask?.eid);
    } catch (e) {
      console.log(e);
    }
  };

  return <TaskCreationBase ref={taskFormRef} handleSubmit={handleSubmit} />;
};

export default TaskCreation;
