import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller } from 'react-hook-form';

import TitleHeader from '../../../../CardEditor/TitleHeader';
import { IFormInput } from '../../audit-schedule.types';
import { OptionButton } from '../shared';
import AuditDueDate from './AuditDueDate';

const SingleTime: FC = () => {
  const resetField = () => null;

  return (
    <Controller<IFormInput, 'repeatDetails.auditDue'>
      name='repeatDetails.auditDue'
      rules={{
        required: 'Please select when the audit is due',
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <TitleHeader title='When is the audit due?' />
          <Flex w='full' gap={4} mt={2} wrap='wrap'>
            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'nextWeek'}
              onClick={() => callAll(field.onChange, resetField)('nextWeek')}
            >
              Next week
            </OptionButton>

            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'endMonth'}
              onClick={() => callAll(field.onChange, resetField)('endMonth')}
            >
              End of month
            </OptionButton>

            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'endYear'}
              onClick={() => callAll(field.onChange, resetField)('endYear')}
            >
              End of the year
            </OptionButton>

            <AuditDueDate
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'date'}
              onChange={() => callAll(field.onChange, resetField)('date')}
            />

            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'anyTime'}
              onClick={() => callAll(field.onChange, resetField)('anyTime')}
            >
              No deadline
            </OptionButton>
          </Flex>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default SingleTime;
