import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  usePopoverContext,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'atoms';
import { StringOrNumber } from 'types';
import { ActionButton } from 'ui-components';

import { IFormInput } from '../../../form-types';

interface ContentProps {
  questionIndex: number;
  value?: boolean;
  onChange: (value: boolean) => void;
}

const Content: FC<ContentProps> = ({ questionIndex }) => {
  const { t } = useTranslation(['common', 'audit']);
  const { onClose } = usePopoverContext();
  const { getValues, setValue } = useFormContext<IFormInput>();

  const options = useMemo(() => {
    return (getValues(`questions.${questionIndex}.options`) || []).filter(
      (O) => O.label
    );
  }, [questionIndex]);

  const [values, setChecked] = useState<StringOrNumber[]>(
    options
      .filter((o) => o.requiresEvidence || o.isFlagged)
      .map((o) => o.tempEid!)
  );

  const actionFunction = () => {
    const _options = getValues(`questions.${questionIndex}.options`) || [];

    const requireEvd = _options.reduce((acc, option, currentIndex) => {
      if (values.includes(option.tempEid!)) {
        _options[currentIndex].requiresEvidence = true;
        return acc || true;
      } else {
        _options[currentIndex].requiresEvidence = false;
      }
      return acc;
    }, false);

    setValue(`questions.${questionIndex}.options`, _options);
    setValue(`questions.${questionIndex}.requiresEvidence`, requireEvd);
  };

  const haveNoOptions = useMemo(() => {
    return options.filter((O) => !O.isDisabled).length === 0;
  }, [options]);

  return (
    <>
      <PopoverBody paddingY={3} paddingX={2}>
        <Flex flexDir='column' px={3} pb={2}>
          <Box fontWeight='600'>{t('audit:addEvidence')}</Box>
          <Box mt={2}>
            Select an option to trigger adding evidence ie: media
          </Box>
          <Box fontSize='12px' color='#6D6D6D'>
            (Flagged options are selected by default.)
          </Box>
        </Flex>
        <Flex
          flexDir='column'
          gap={1}
          overflowY='auto'
          maxH='380px'
          sx={{
            '.chakra-checkbox__label': {
              color: '#6F767E',
              fontSize: 14,
              fontWeight: 600,
              _checked: {
                color: '#2A85FF',
              },
            },
            '.chakra-checkbox__control': {
              borderRadius: '6px',
            },
          }}
        >
          <CheckboxGroup value={values} onChange={setChecked}>
            {haveNoOptions ? (
              <Box
                fontSize='12px'
                fontWeight='500'
                color='#9A9FA5'
                fontStyle='italic'
                paddingX={3}
                paddingY={3}
              >
                No options available for evidence
              </Box>
            ) : null}

            {options.map((option, index) => (
              <Checkbox
                size='lg'
                value={option.tempEid}
                key={index}
                padding={3}
                borderRadius='10px'
                isDisabled={option.isFlagged}
                hidden={option.isDisabled}
                _hover={{ bg: '#EFEFEF' }}
                _checked={{ bg: '#2A85FF1A' }}
              >
                {option.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Flex>
      </PopoverBody>
      <PopoverFooter
        paddingX={3}
        paddingBottom={4}
        paddingTop={3}
        // borderTopWidth='0'
        textAlign='right'
      >
        <ActionButton
          size='lg'
          // width='full'
          colorScheme='blue'
          close={onClose}
          actionFn={actionFunction}
          isDisabled={!options.length}
        >
          {t('common:apply')}
        </ActionButton>
      </PopoverFooter>
    </>
  );
};

interface IProps {
  questionIndex: number;
  value?: boolean;
  onChange: (value: boolean) => void;
  onBlur?: () => void;
}

const Evidence: FC<IProps> = ({ questionIndex, value, onChange, onBlur }) => {
  const { t } = useTranslation(['common', 'audit']);

  return (
    <Popover isLazy onClose={onBlur} placement='bottom-end'>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <Button variant='link' colorScheme='blue' textDecoration='underline'>
          {t('audit:addEvidence')}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w='240px'
        borderRadius='16px'
        border='none'
        boxShadow='0px 32px 48px -8px #0000001A, 0px 0px 14px -4px #0000000D, 0px 40px 64px -12px #00000014'
        // paddingX='12px'
        _focus={{
          outline: '2px solid transparent',
          outlineOffset: '2px',
        }}
      >
        <Content
          questionIndex={questionIndex}
          value={value}
          onChange={onChange}
        />
      </PopoverContent>
    </Popover>
  );
};

export default Evidence;
