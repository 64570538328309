import React, { FC, useMemo } from 'react';
import { Flex, useControllableState } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useReactiveVar } from '@apollo/client';
import { chakraComponents } from 'chakra-react-select';
import { Dropdown, SelectOption } from 'atoms';
import { toArray } from '../../../../../utils';
import { IconImage } from '../../../../../ui-components';
import { usersEntityObj } from 'sub-components/Header';
import OptionItem from './OptionItem';

const otherProps = {
  isMulti: true,
  hideSelectedOptions: false,
  controlShouldRenderValue: false,
  isClearable: false,
  backspaceRemovesValue: false,
  closeMenuOnSelect: false,
};

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
  isReadOnly?: boolean;
}

const Member: FC<IProps> = ({ value, onChange, onBlur, isReadOnly }) => {
  const entityUsers = useReactiveVar(usersEntityObj);

  const [internalValue, updateValue] = useControllableState<string[]>({
    value: value,
    onChange: onChange,
  });

  const options = useMemo(() => {
    return toArray(entityUsers)
      .filter(
        (user) =>
          user?.type === 'user' && ['active', 'inactive'].includes(user?.status)
      )
      .map((user) => {
        return {
          label: user?.name,
          value: user?.eid,
          profilePic: user?.profilePic,
        };
      });
  }, [entityUsers]);

  const foundOption = useMemo(() => {
    return options?.filter((op) => internalValue?.includes(op?.value));
  }, [internalValue, options]);

  const remove = (op: SelectOption) => {
    updateValue?.(internalValue?.filter((val) => val !== op.value));
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Dropdown
        size='lg'
        {...otherProps}
        options={options}
        value={foundOption as never}
        onChange={(newValue) =>
          updateValue?.(toArray(newValue).map((v) => v.value))
        }
        onMenuClose={onBlur}
        isReadOnly={isReadOnly}
        placeholder='Select member'
        inputStyle={{
          borderWidth: '2px',
          borderRadius: '12px',
          outline: 'none',
          backgroundColor: 'white',
        }}
        selectStyles={{
          option: {
            borderRadius: '8px',
            padding: 'unset',
          },
          menu: {
            zIndex: 3,
          },
          menuList: {
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
          },
        }}
        components={{
          Option: (optionProps) => {
            return (
              <chakraComponents.Option {...optionProps} isSelected={false}>
                {/*// @ts-ignore */}
                <OptionItem
                  {...optionProps.data}
                  isSelected={optionProps.isSelected}
                />
              </chakraComponents.Option>
            );
          },
        }}
      />
      {!!foundOption?.length && (
        <Flex gap={2} flexWrap='wrap'>
          {foundOption?.map((op) => (
            <Flex
              key={op.value}
              bg='#DCF5FF'
              padding='4px 8px'
              borderRadius='4px'
              align='center'
              gap='6px'
              fontWeight='500'
              onClick={() => remove(op)}
            >
              <IconImage
                name={op.label}
                thumbnail={op.profilePic}
                boxSize={20}
                borderRadius='4px'
              />
              {op.label}
              <CloseIcon boxSize='10px' cursor='pointer' color='#6F767E' />
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default Member;
