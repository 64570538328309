import { FieldType } from './LocationListContainer';

export const initialData: FieldType[] = [
  {
    id: 'name_ac414408-cf21-4c42-840f-8d7b49371163',
    field: 'name',
    visibility: true,
    default: true,
  },
  {
    id: 'type_1bc6d3c7-c775-43b8-bf41-ebf6bf626b84',
    field: 'type',
    visibility: true,
    default: true,
  },
  {
    id: 'owner_9887af5a-5a93-49a3-858f-d74b159e5043',
    field: 'owner',
    visibility: true,
  },
  {
    id: 'status_88acac9a-36de-40fd-8b03-d43ebc474ded',
    field: 'status',
    visibility: true,
  },
  {
    id: 'email_9967c0fd-c14f-4b6c-a83d-bcbde1e5dd88',
    field: 'email',
    visibility: true,
  },
  {
    id: 'phone_e9ff2acb-2050-4d69-b9ce-aca3fe059439',
    field: 'phone',
    visibility: true,
  },
  {
    id: 'city_6cef1622-0da1-4bcb-b01c-6f33cda6bd08',
    field: 'city',
    visibility: true,
  },
  {
    id: 'zipCode_72346833-37da-4681-8b9c-800106687f42',
    field: 'zipCode',
    visibility: true,
  },
  {
    id: 'state_93fecf7f-5ae8-4324-ba02-515c3eb3c981',
    field: 'state',
    visibility: true,
  },
];

export const staticColumnIds = [
  'name_ac414408-cf21-4c42-840f-8d7b49371163',
  'type_1bc6d3c7-c775-43b8-bf41-ebf6bf626b84',
  'owner_9887af5a-5a93-49a3-858f-d74b159e5043',
  'status_88acac9a-36de-40fd-8b03-d43ebc474ded',
  'email_9967c0fd-c14f-4b6c-a83d-bcbde1e5dd88',
  'phone_e9ff2acb-2050-4d69-b9ce-aca3fe059439',
  'city_6cef1622-0da1-4bcb-b01c-6f33cda6bd08',
  'zipCode_72346833-37da-4681-8b9c-800106687f42',
  'state_93fecf7f-5ae8-4324-ba02-515c3eb3c981',
];
