import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { AuditHelper } from 'configs';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { IFormInput } from '../form-types';
import AuditTemplateSelect from './AuditTemplateSelect';

const AuditTemplateType: FC = () => {
  return (
    <Flex flexDir='column'>
      <Box>
        <TitleHeader
          title={'Template type'}
          desc={
            'This will help determine the kind of questions that will be suggested to you'
          }
          isRequired
        />
      </Box>

      <Controller<IFormInput, 'category'>
        name='category'
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState.error}>
              <AuditTemplateSelect
                {...field}
                value={field.value as never}
                onBlur={AuditHelper.save(field.onBlur)}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Flex>
  );
};

export default AuditTemplateType;
