import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useController } from 'react-hook-form';

interface IProps {}

const NullComp: FC<IProps> = () => {
  const { field } = useController({
    name: 'height',
    defaultValue: 0,
  });

  return <Box height={field.value} animation='ease-in-out' />;
};

export default NullComp;
