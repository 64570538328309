import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Button, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FormInput } from 'atoms';
import { toArray } from 'utils/utils';
import { FileEntity } from 'types';

import { useUploadDocument } from './useUploadDocument';

interface IProps {
  isDisabled?: boolean;
  value?: any;
  onChange?: (files: FileEntity[]) => void;
}

const UploadDocumentInput = forwardRef<never, IProps>(
  ({ value, onChange, isDisabled }, ref) => {
    const { t } = useTranslation(['location']);

    useImperativeHandle(ref, () => null, []);

    const uploadDocument = useUploadDocument();

    const onClick = () => {
      uploadDocument({
        onFileChange: (file) => onChange?.(toArray(file)),
      });
    };

    const innerValue = useMemo((): string | undefined => {
      if (Array.isArray(value)) {
        return typeof value[0] === 'object' ? value[0]?.name : value[0];
      }
      return value?.name || value;
    }, [value]);

    if (innerValue) {
      return (
        <FormInput
          isReadOnly
          size='lg'
          value={innerValue}
          rightIcon={
            <IconButton
              isDisabled={isDisabled}
              borderRadius='full'
              size='xs'
              aria-label='remove'
              px='0'
              fontSize='unset'
              icon={
                <FontAwesomeIcon
                  icon={faCircleXmark as IconProp}
                  color='#6F767E'
                />
              }
              onClick={() => onChange?.([])}
            />
          }
        />
      );
    }

    return (
      <Button
        width='full'
        size='lg'
        variant='outline'
        borderRadius='12px'
        fontSize='14px'
        px={4}
        rightIcon={
          <FontAwesomeIcon icon={faCirclePlus as IconProp} color='#6F767E' />
        }
        justifyContent='space-between'
        onClick={onClick}
        isDisabled={isDisabled}
      >
        {t('location:addADocument')}
      </Button>
    );
  }
);

UploadDocumentInput.displayName = 'Upload Document Input';

export default UploadDocumentInput;
