import React, { FC } from 'react';
import { Center, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  ICityStateList,
  ILocationStatusEntity,
  ISelectedFilterTab,
} from '../locations/LocationListContainer';

interface ItemProps {
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
  boxBackground: string;
  value?: number;
}

const StatusItem: FC<ItemProps> = ({
  title,
  isSelected,
  onClick,
  boxBackground,
  value = 0,
}) => {
  return (
    <Flex
      gap='7px'
      align='center'
      bg={isSelected ? '#F1F1F1' : 'none'}
      p='8px 16px'
      borderRadius='8px'
      cursor='pointer'
      onClick={onClick}
    >
      <Text m={0} color={isSelected ? '#1A1D1F' : 'inherit'}>
        {title}
      </Text>
      <Center
        fontSize='12px'
        fontWeight={600}
        color='#272B30'
        bg={boxBackground}
        borderRadius='5px'
        minW='28px'
        p='5px'
      >
        {value}
      </Center>
    </Flex>
  );
};

interface IProps {
  initialCityStateList: ICityStateList;
  cityStateList: ICityStateList;
  selectedFilterTab?: ISelectedFilterTab;
  setSelectedFilterTab?: React.Dispatch<
    React.SetStateAction<ISelectedFilterTab>
  >;
  setInitialCityStateList: React.Dispatch<React.SetStateAction<ICityStateList>>;
  setCityStateList: React.Dispatch<React.SetStateAction<ICityStateList>>;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
  locationStatus: ILocationStatusEntity | undefined;
}

const LocationStatusTabs: FC<IProps> = ({
  initialCityStateList,
  cityStateList,
  locationStatus,
  setSelectedFilterTab,
  setInitialCityStateList,
  setCityStateList,
  setSelectedPage,
  selectedFilterTab,
}) => {
  const { t } = useTranslation(['common', 'location']);

  const resetLocationStatus = () => {
    let _stateCityList: ICityStateList = JSON.parse(
      JSON.stringify(initialCityStateList)
    );
    _stateCityList?.locationStatus?.forEach((status) => {
      status.selected = false;
    });
    setInitialCityStateList(_stateCityList);
    setCityStateList(_stateCityList);
  };

  return (
    <Flex
      gap='8px'
      fontWeight={600}
      fontSize='15px'
      color='#6F767E'
      align='center'
    >
      <StatusItem
        title={t('common:all')}
        boxBackground='white'
        isSelected={selectedFilterTab === 'all'}
        onClick={() => {
          resetLocationStatus();
          setSelectedFilterTab?.('all');
          setSelectedPage?.(1);
        }}
        value={
          (locationStatus?.open || 0) +
          (locationStatus?.development || 0) +
          (locationStatus?.preLaunch || 0)
        }
      />

      <StatusItem
        title={t('common:open')}
        boxBackground='#B5E4CA66'
        isSelected={selectedFilterTab === 'open'}
        onClick={() => {
          resetLocationStatus();
          setSelectedFilterTab?.('open');
          setSelectedPage?.(1);
        }}
        value={locationStatus?.open || 0}
      />

      <StatusItem
        title='Launching'
        boxBackground='#FFD88D66'
        isSelected={selectedFilterTab === 'development'}
        onClick={() => {
          resetLocationStatus();
          setSelectedFilterTab?.('development');
          setSelectedPage?.(1);
        }}
        value={locationStatus?.development || 0}
      />

      <StatusItem
        title='Pre-launch'
        boxBackground='rgba(239, 239, 239, 1)'
        isSelected={selectedFilterTab === 'preLaunch'}
        onClick={() => {
          resetLocationStatus();
          setSelectedFilterTab?.('preLaunch');
          setSelectedPage?.(1);
        }}
        value={locationStatus?.preLaunch || 0}
      />
    </Flex>
  );
};

export default LocationStatusTabs;
