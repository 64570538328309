import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera, faFile, faVideo } from '@fortawesome/pro-light-svg-icons';

import { QuestionType } from 'types';
import { IFormInput } from '../../form-types';

export const HelperTextFor: QuestionType[] = [
  QuestionType.FILE_UPLOAD,
  QuestionType.IMAGE_UPLOAD,
  QuestionType.VIDEO_UPLOAD,
];

export const MediaComponent: FC<{ questionType: QuestionType }> = ({
  questionType,
}) => {
  const { t } = useTranslation('form');

  const [icon, transKey] = useMemo(() => {
    return match(questionType)
      .with(
        QuestionType.IMAGE_UPLOAD,
        () => [faCamera, 'questionImageHelp'] as const
      )
      .with(
        QuestionType.VIDEO_UPLOAD,
        () => [faVideo, 'questionVideoHelp'] as const
      )
      .with(
        QuestionType.FILE_UPLOAD,
        () => [faFile, 'questionFileHelp'] as const
      )
      .otherwise(() => [null, null]);
  }, [questionType]);

  if (!transKey) {
    return null;
  }

  return (
    <Flex
      minH='48px'
      borderRadius='12px'
      border='1px dashed #9B9B9B'
      align='center'
      px='16px'
      gap='12px'
    >
      <FontAwesomeIcon icon={icon as IconProp} color='#BD7AFF' />
      <Box fontSize='12px' color='#BD7AFF'>
        {t(transKey)}
      </Box>
    </Flex>
  );
};

interface IProps {
  questionIndex: number;
}

const AuditMediaHelper: FC<IProps> = ({ questionIndex }) => {
  const questionType = useWatch<IFormInput, `questions.${number}.qType`>({
    name: `questions.${questionIndex}.qType`,
  });

  if (!HelperTextFor.includes(questionType)) {
    return null;
  }

  return <MediaComponent questionType={questionType} />;
};

export default AuditMediaHelper;
