import React, { FC, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { useRect } from '../../../../hooks';

interface ModalProps {
  left?: number;
  width?: number;
}

const InternalModal: FC<ModalProps> = ({ left, width, children }) => {
  return (
    <Flex
      height='64px'
      justify='space-between'
      align='center'
      bg='#FFFFFF'
      position='fixed'
      bottom='-4px'
      left={left && `${left}px`}
      width={width && `${width}px`}
      paddingX='30px'
      zIndex={10}
      _empty={{
        display: 'none',
      }}
      boxShadow='0px -4px 16px 0px #AABED833'
    >
      {children}
    </Flex>
  );
};

interface IProps {
  sectionCount: number;
}

const QuestionSectionFooter: FC<IProps> = ({ children, sectionCount }) => {
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);

  if (!sectionCount || sectionCount === 1) {
    return null;
  }

  return (
    <Flex ref={ref} height='20px'>
      <InternalModal left={rect?.left} width={rect?.width}>
        {children}
      </InternalModal>
    </Flex>
  );
};

export default QuestionSectionFooter;
