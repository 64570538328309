import { Flex } from '@chakra-ui/react';
import { SelectOption } from 'atoms';
import React, { FC, useState } from 'react';
import { DateRange } from 'react-day-picker';
import DateRangePicker from 'sub-components/audits/range-date-picker';
import { Filter } from '../../../../../../../sub-components/audits/detail/components';
import { BoxHeader } from '../../../../../../../ui-components';

interface IProps {
  locationFilterOptions: SelectOption<string>[];
  selectedDateRange: DateRange | undefined;
  selectedLocationsFilter: SelectOption<string>[];
  handleFilterChange: (options: SelectOption[]) => void;
  setSelectedDateRangeHandler: (range: DateRange | undefined) => void;
}

const SearchSection: FC<IProps> = ({
  locationFilterOptions,
  selectedDateRange,
  selectedLocationsFilter,
  handleFilterChange,
  setSelectedDateRangeHandler,
}) => {
  const [internalSelectedDateRange, setInternalSelectedDateRange] = useState<
    DateRange | undefined
  >(selectedDateRange);

  const [internalSelectedLocations, setInternalSelectedLocations] = useState<
    SelectOption[]
  >(selectedLocationsFilter);

  const handleInternalFilterChange = (option: SelectOption) => {
    setInternalSelectedLocations((prevFilters) => {
      const isSelected = prevFilters.some(
        (filter) => filter.value === option.value
      );
      if (isSelected) {
        return prevFilters.filter((filter) => filter.value !== option.value);
      } else {
        return [...prevFilters, option];
      }
    });
  };

  const updateDateRange = (range: DateRange | undefined) => {
    setSelectedDateRangeHandler(range);
  };

  const onLocationFilterClose = () => {
    handleFilterChange(internalSelectedLocations);
  };

  return (
    <Flex justify='space-between' align='center'>
      <BoxHeader
        boxWidth={3}
        fontSize='18px'
        title='Audit History'
        color='#CABDFF'
      />
      <Flex align='center' gap={2}>
        <Filter
          placeholder='Select location(s)'
          value={internalSelectedLocations}
          options={locationFilterOptions}
          onChange={handleInternalFilterChange}
          onCloseCallback={onLocationFilterClose}
        />
        <DateRangePicker
          selectedRange={internalSelectedDateRange}
          updateDateRange={updateDateRange}
          onPopoverClose={updateDateRange}
        />
      </Flex>
    </Flex>
  );
};

SearchSection.displayName =
  'pages/Audits/Detail/components/AuditHistory/components/SearchSection';

export default SearchSection;
