import React, { FC, useMemo } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useWatch } from 'react-hook-form';

import { Input } from 'atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'shared/headerColors';
import { IFormInput } from '../audit-schedule.types';
import { Container } from './shared';

const TitleSection: FC = () => {
  const _data = useWatch<IFormInput, ['template.label', 'repeatDetails']>({
    name: ['template.label', 'repeatDetails'],
  });

  const _value = useMemo(() => {
    const [template, _] = _data;

    return template;
  }, [_data]);

  return (
    <Container>
      <BoxHeader
        title='Title'
        fontSize='18px'
        boxWidth={3}
        color={HeaderColors.Purple}
      />

      <Controller<IFormInput, 'scheduleTitle'>
        name='scheduleTitle'
        rules={{
          required: {
            // value: !_value,
            value: true,
            message: 'Please enter a valid title',
          },
        }}
        defaultValue=''
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid}>
            <Input size='lg' variant='auditOutline' {...field} />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Container>
  );
};

export default TitleSection;
