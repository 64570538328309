import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSquareCheck,
  faFile,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import {
  Any,
  AuditEvidenceEntity,
  AuditQuestionEntity,
  AuditResponseEntity,
  QuestionType,
} from 'types';
import { FILENAME_REGEX } from 'utils/constants';
import { getImage } from '../../../../../utils';
import EvidenceImages from '../EvidenceImages/EvidenceImages';
import Notes from '../Notes';
import { ResponseStatus } from '../QuestionSection/types/audit-question.types';
import {
  BaseContainer,
  FlexContainer,
  MissedAudit,
  NotSubmittedAudit,
} from './components';
import { useResponse } from './utils/useResponse';

interface FormattedResponseBase {
  questionId: string;
  questionType: QuestionType;
  userId: string;
  comment?: string;
  images?: AuditEvidenceEntity[];
  respondedAt: string;
  isCorrect: boolean;
  isFlagged: boolean;
  noteText?: string;
  score: number;
  userName?: string;
}

interface FormattedResponseText extends FormattedResponseBase {
  response: string;
}

interface FormattedResponseOptions extends FormattedResponseBase {
  response: { eid: string; label: string; [k: string]: Any }[];
}

interface FormattedResponseUploads extends FormattedResponseBase {
  response: string[];
}

interface IProps {
  rawQuestion: AuditQuestionEntity;
  note?: string;
  hideNotes?: boolean;
  hideEvidences?: boolean;
  evidenceImages?: string[];
  responseStatus?: ResponseStatus;
  rawResponse?: AuditResponseEntity | AuditResponseEntity[];
}

const QuestionTypes: FC<IProps> = ({
  rawQuestion,
  rawResponse,
  responseStatus,
  hideEvidences,
  hideNotes,
}) => {
  const responses = useResponse(rawResponse, rawQuestion);

  if (responseStatus) {
    if (responseStatus === 'missed') {
      return <MissedAudit />;
    } else if (responseStatus === 'notSubmitted') {
      return <NotSubmittedAudit />;
    } else if (responseStatus === 'noResponseFound') {
      return <Box as='span'>No Response available.</Box>;
    }
  }

  if (!responses || responses.length === 0) {
    return <Box>No response available.</Box>;
  }

  return (
    <>
      {responses.map((response, responseIndex) => {
        const renderResponse = () => {
          switch (response.questionType) {
            case QuestionType.SHORT_TEXT:
            case QuestionType.LONG_TEXT: {
              const textResponse = response as FormattedResponseText;
              return (
                <Flex
                  flexDir='column'
                  gap={2}
                  key={`${response.questionId}-${responseIndex}`}
                >
                  <BaseContainer
                    key={`${response.questionId}-${responseIndex}`}
                  >
                    <Box
                      as='span'
                      fontWeight={500}
                      fontSize='12px'
                      color='rgba(128, 128, 128, 1)'
                    >
                      {textResponse.response}
                    </Box>
                  </BaseContainer>
                  <EvidenceImages
                    images={!hideEvidences ? textResponse.images || [] : []}
                  />
                  <Notes note={!hideNotes ? textResponse.noteText || '' : ''} />
                </Flex>
              );
            }

            case QuestionType.MULTI_CHOICE:
            case QuestionType.CHECKBOX:
            case QuestionType.DROPDOWN:
            case QuestionType.LOCATION:
            case QuestionType.MEMBER: {
              const optionsResponse = response as FormattedResponseOptions;
              const isMultiChoice =
                response.questionType === QuestionType.MULTI_CHOICE;
              const isCheckbox =
                response.questionType === QuestionType.CHECKBOX;
              const isDropdown =
                response.questionType === QuestionType.DROPDOWN;
              const isLocation =
                response.questionType === QuestionType.LOCATION;
              const isMember = response.questionType === QuestionType.MEMBER;
              return (
                <Flex
                  flexDir='column'
                  gap={2}
                  key={`${response.questionId}-${responseIndex}`}
                >
                  {optionsResponse.response.map((found, index) => (
                    <BaseContainer key={`${found.eid}-${index}`}>
                      {isCheckbox ? (
                        <FlexContainer index={index}>
                          <FontAwesomeIcon
                            icon={faSquareCheck as IconProp}
                            color='rgba(42, 133, 255, 1)'
                          />
                          <Box as='span'>{found.label}</Box>
                        </FlexContainer>
                      ) : isLocation ? (
                        <Flex
                          w='fit-content'
                          gap='5px'
                          align='center'
                          p='2px 8px'
                          bg='rgba(181, 228, 202, 1)'
                          borderRadius='6px'
                        >
                          <Center
                            bg='rgba(131, 191, 110, 1)'
                            borderRadius='4px'
                            boxSize='20px'
                            color='white'
                          >
                            <FontAwesomeIcon icon={faLocationDot as IconProp} />
                          </Center>
                          <Box as='span'>{found.label}</Box>
                        </Flex>
                      ) : isMember ? (
                        <FlexContainer index={index}>
                          <Image
                            boxSize='20px'
                            borderRadius='50%'
                            src={getImage(found?.profilePic, found.label)}
                            alt={found.label}
                          />
                          <Box as='span'>{found.label}</Box>
                        </FlexContainer>
                      ) : isMultiChoice || isDropdown ? (
                        <Box
                          as='span'
                          w='fit-content'
                          p='2px 8px'
                          bg={
                            response.isFlagged
                              ? 'rgba(255, 106, 85, 1)'
                              : 'rgba(131, 191, 110, 1)'
                          }
                          borderRadius='6px'
                          color='white'
                        >
                          {found.label}
                        </Box>
                      ) : null}
                    </BaseContainer>
                  ))}
                  <EvidenceImages
                    images={!hideEvidences ? optionsResponse.images || [] : []}
                  />
                  <Notes
                    note={!hideNotes ? optionsResponse.noteText || '' : ''}
                  />
                </Flex>
              );
            }

            case QuestionType.IMAGE_UPLOAD:
            case QuestionType.VIDEO_UPLOAD:
            case QuestionType.FILE_UPLOAD: {
              const uploadsResponse = response as FormattedResponseUploads;

              const isVideoUpload =
                response.questionType === QuestionType.VIDEO_UPLOAD;
              const isImageUpload =
                response.questionType === QuestionType.IMAGE_UPLOAD;

              return (
                <Flex
                  flexDir='column'
                  gap={2}
                  key={`${response.questionId}-${responseIndex}`}
                >
                  {uploadsResponse.response.map((url, index) => (
                    <BaseContainer key={`${url}-${index}`}>
                      <Flex
                        bg='rgba(220, 245, 255, 1)'
                        p='8px 12px'
                        borderRadius='8px'
                        align='center'
                        gap='5px'
                        w='fit-content'
                        cursor='pointer'
                        onClick={() => window.open(url, '_blank')}
                      >
                        {isVideoUpload ? (
                          <FontAwesomeIcon icon={faVideo as IconProp} />
                        ) : isImageUpload ? (
                          <Image src={url} boxSize='24px' borderRadius='4px' />
                        ) : (
                          <FontAwesomeIcon icon={faFile as IconProp} />
                        )}
                        <Box isTruncated maxW='200px'>
                          {url?.split(FILENAME_REGEX).pop()}
                        </Box>
                      </Flex>
                    </BaseContainer>
                  ))}
                  <EvidenceImages
                    images={!hideEvidences ? uploadsResponse.images || [] : []}
                  />
                  <Notes
                    note={!hideNotes ? uploadsResponse.noteText || '' : ''}
                  />
                </Flex>
              );
            }

            default:
              return null;
          }
        };

        return renderResponse();
      })}
    </>
  );
};

export default QuestionTypes;
