import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import QuestionsSection from './QuestionsSection';
import { IFormInput } from '../types';

interface IProps {}

const QuestionSectionContainer: FC<IProps> = () => {
  const selectedSection = useWatch<IFormInput, 'selectedSection'>({
    name: 'selectedSection',
  });

  if (typeof selectedSection !== 'number') {
    return null;
  }

  return <QuestionsSection selectedSection={selectedSection} />;
};

export default QuestionSectionContainer;
