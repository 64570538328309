import { Box, Center } from '@chakra-ui/react';
import React, { FC } from 'react';
import { HeaderColors } from 'shared/headerColors';
import GenericColorHeader from 'sub-components/GenericColorHeader';

const NoData: FC = () => {
  return (
    <>
      <GenericColorHeader
        title='Performance Analytics'
        color={HeaderColors.Green}
      />
      <Center flexDir='column' h='full' gap={4}>
        <Box as='span' fontWeight={600} fontSize='16px'>
          Yet to be generated
        </Box>
        <Box
          as='span'
          fontWeight={500}
          color='rgba(111, 118, 126, 1)'
          textAlign='center'
        >
          Analytics will be updated once the audit has been started
        </Box>
      </Center>
    </>
  );
};

export default NoData;
