import React, { FC, useEffect, useMemo, useState } from 'react';
import { Divider, Flex } from '@chakra-ui/react';

import AnimateItemContainer from './AnimateItemContainer';

import ItemHeader from './ItemHeader';
import { LauncherLocation } from '../dashboard.graphql';
import ItemContent from './ItemContent';
import ItemHelpQuery from './ItemHelpQuery';
import { getCompletedTask } from '../launcher.helpers';
import { LocLauncherStatus } from '../launcher.types';
import { useUserDataSelector } from 'hooks';
import { LauncherLocationDetail } from 'pages/launcher/details/types/location-launch-details.types';
import { groupLocationDetails } from 'pages/launcher/details/helper/getGroupedData';
import { getCurrentTask, getLastTask } from 'pages/launcher/details/helper';
import moment from 'moment';
import { getRefactoredLiveDate } from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Horizontal/util/helper';

interface IProps {
  data: LauncherLocationDetail;
  onClick?: () => void;
}

const LocationProgressItem: FC<IProps> = ({ data, onClick }) => {
  const [goLiveDate, setGoLiveDate] = useState(moment()?.toISOString());
  const loggedInUserTimezone = useUserDataSelector((state) => state.timezone);
  const grouped = groupLocationDetails(data);
  const currentTask = getCurrentTask(grouped, loggedInUserTimezone);
  const lastTask = getLastTask(data?.tasks || []);
  const { completed } = useMemo(() => {
    return {
      completed: getCompletedTask(data.tasks),
    };
  }, [data.tasks]);

  useEffect(() => {
    setGoLiveDate(getRefactoredLiveDate(data?.liveDate, data?.tasks));
  }, [data.tasks, data]);

  const maximumDueDateForTask = () => {
    if (!data || data?.tasks?.length === 0) return;
    let tasksDueDates = data?.tasks?.map((task) => task?.dueDate);
    let tasksDueDatesInMoments = tasksDueDates?.map((date) => moment(date));
    let maxTaskDueDate = moment.max(tasksDueDatesInMoments);
    let maxTaskDueDateEndOfDay = maxTaskDueDate.startOf('day');
    return maxTaskDueDateEndOfDay;
  };

  const isGoLiveDateBeforeTaskDueDate = () => {
    let momentGoLiveDate = moment(goLiveDate);
    let goLiveDateEndOfDay = momentGoLiveDate?.startOf('day');
    let flag = goLiveDateEndOfDay.isBefore(maximumDueDateForTask());
    return flag;
  };

  // const currently = useMemo(() => {
  //   return getCurrentTask(data.tasks);
  // }, [data.tasks]);

  // const _currentTaskData = useMemo(() => {
  //   return currentTaskData(data, loggedInUserTimezone);
  // }, [data]);

  // const _lastTaskData = useMemo(() => {
  //   return lastTaskData(data, loggedInUserTimezone);
  // }, [data]);

  return (
    <AnimateItemContainer>
      <Flex
        h='full'
        flexDir='column'
        borderRadius='12px'
        bg='white'
        padding={5}
        gap={2}
        cursor={onClick ? 'pointer' : undefined}
        onClick={onClick}
        _hover={{
          boxShadow: 'lg',
        }}
      >
        <ItemHeader
          isGoLiveDateBeforeTaskDueDate={isGoLiveDateBeforeTaskDueDate}
          status={
            data.isLauncherDue
              ? LocLauncherStatus.LAGGING
              : LocLauncherStatus.ON_TRACK
          }
          name={data.location?.name}
          completed={completed.length}
          totalTasks={data.tasks.length}
          // startDate={data.startDate}
          startDate={completed.at(0)?.completedAt}
          overdueTasks={
            data?.tasks?.filter((task) => task?.taskStatus === 'overdue')
              ?.length || 0
          }
        />
        <Divider />
        <ItemContent
          currently={currentTask?.currentlyOn}
          lastDone={lastTask?.lastTask}
        />
        {/* <Divider /> */}
        {/* <ItemHelpQuery /> */}
      </Flex>
    </AnimateItemContainer>
  );
};

export default LocationProgressItem;
