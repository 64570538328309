import React, { FC, forwardRef, useCallback, useMemo } from 'react';
import { CSSObject, Flex, useStyles } from '@chakra-ui/react';
import {
  chakraComponents,
  MenuListProps,
  OptionProps,
  Size,
  SizeProps,
} from 'chakra-react-select';
import { gql, useQuery } from '@apollo/client';

import { Dropdown, DropDownProps, SelectOption } from 'atoms';
import { FormCategory } from 'types';
import { toArray } from 'utils';

const AUDIT_CATEGORIES = gql`
  query AuditCategories {
    AuditCategories {
      eid
      name
    }
  }
`;

type IProps = DropDownProps<SelectOption<never>> & {};
interface CustomOptionProps
  extends Omit<OptionProps<FormCategory, false>, 'children'> {
  onEditClick?: (data: FormCategory) => void;
}

const CustomOption: FC<CustomOptionProps> = (props) => {
  const {
    children,
    isFocused,
    isDisabled,
    isSelected,
    selectProps: { size, selectedOptionStyle, chakraStyles },
  } = props;

  const itemStyles = useStyles().item as Record<string, CSSObject>;

  const paddings: SizeProps = {
    // sm: '0.3rem 0.6rem',
    // md: '0.4rem 0.8rem',
    // lg: '0.5rem 1rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
  };

  const paddingBlock: SizeProps = {
    sm: '0.3rem',
    md: '0.4rem',
    lg: '0.5rem',
  };

  const shouldHighlight: boolean =
    selectedOptionStyle === 'color' && isSelected;

  const selectedBg = '#B1E5FC47';

  const initialStyles: CSSObject = {
    ...itemStyles,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textAlign: 'start',
    fontSize: size,
    paddingInline: paddings[size as Size],
    paddingBlock: 0,
    color: '#1A1D1F',
    bg: isFocused ? itemStyles._focus?.bg : 'transparent',
    ...(shouldHighlight && {
      bg: selectedBg,
      _active: { bg: selectedBg },
    }),
    ...(isDisabled && itemStyles._disabled),
    ...(isDisabled && { _active: {} }),
    _hover: {
      button: {
        visibility: 'visible',
      },
    },
  };

  const sx: CSSObject = chakraStyles?.option
    ? // @ts-ignore
      chakraStyles.option(initialStyles, props)
    : initialStyles;

  return (
    <chakraComponents.Option
      {...props}
      // @ts-ignore
      innerProps={{ ...props.innerProps, sx }}
    >
      <Flex
        flex={1}
        align='center'
        justify='space-between'
        borderTop='1px solid #EAEBED'
        paddingBlock={paddingBlock[size as Size]}
        gap={4}
      >
        {children}
      </Flex>
    </chakraComponents.Option>
  );
};

const AuditTemplateSelect = forwardRef<never, IProps>(
  ({ inputStyle, onChange, ...rest }, ref) => {
    const { data, loading } = useQuery<
      Record<'AuditCategories', FormCategory[]>
    >(AUDIT_CATEGORIES, {
      fetchPolicy: 'network-only',
    });

    const MenuList = useCallback((menuProps: MenuListProps) => {
      return (
        <chakraComponents.MenuList {...menuProps}>
          {menuProps.children}
        </chakraComponents.MenuList>
      );
    }, []);

    const categoryList = useMemo(() => {
      return toArray(data?.AuditCategories);
    }, [data?.AuditCategories]);

    return (
      <Dropdown
        ref={ref}
        options={categoryList as never}
        getOptionValue={(option) => option?.eid}
        getOptionLabel={(option) => (option?.eid ? option?.name : undefined)}
        size='lg'
        className='dropdown'
        placeholder='Select template type'
        inputStyle={{
          borderWidth: '2px',
          borderRadius: '12px',
          fontSize: '15px',
          outline: 'none',
          backgroundColor: 'white',
          ...inputStyle,
        }}
        components={{
          MenuList: MenuList as never,
          // Option: CustomOption as never,
          Option: (props) => (
            // @ts-ignore
            <CustomOption {...props} />
          ),
        }}
        // @ts-ignore
        onChange={onChange}
        {...rest}
        isLoading={loading || rest.isLoading}
      />
    );
  }
);

AuditTemplateSelect.displayName = 'AuditTemplateSelect';

export default AuditTemplateSelect;
