import { useLazyQuery } from '@apollo/client';
import {
  AuditSessionEntity,
  AuditSessionPaginateResponse,
  AuditSessionPaginateVariables,
} from 'sub-components/audits/audit-tab/supervised/types';
import {
  AUDIT_HISTORY,
  AUDIT_HISTORY_SESSION_LOCATIONS,
} from '../service/audit-history.graphql';

export const useService = () => {
  const [getAuditHistory, auditHistoryResult] = useLazyQuery<
    AuditSessionPaginateResponse,
    AuditSessionPaginateVariables
  >(AUDIT_HISTORY, {
    fetchPolicy: 'network-only',
  });

  const [getAuditHistorySessionLocations, auditHistorySessionLocations] =
    useLazyQuery<{ AuditSessionById: AuditSessionEntity }, { eid: string }>(
      AUDIT_HISTORY_SESSION_LOCATIONS,
      {
        fetchPolicy: 'network-only',
      }
    );

  return {
    auditHistory: {
      getAuditHistory,
      auditHistoryResult,
    },
    sessionLocations: {
      getAuditHistorySessionLocations,
      auditHistorySessionLocations,
    },
  };
};
