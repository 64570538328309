import moment from 'moment';
import { AuditRepeatEntity, AuditStatus } from '../../../../types';
import { IFormInput } from '../audit-schedule.types';
import { AuditCreateInput } from '../audit-create.graphql';

const parseRepeatDetails = (
  repeatDetails: AuditRepeatEntity<string>
): AuditRepeatEntity<Date> => {
  if (repeatDetails.auditFrequency === 'oneTime') {
    return {
      auditFrequency: repeatDetails.auditFrequency,
      auditDue: repeatDetails?.auditDue,
      auditDueDate: moment(repeatDetails?.auditDueDate).toDate(),
    };
  }

  return {
    auditFrequency: repeatDetails.auditFrequency,
    repeatType: repeatDetails?.repeatType,
    weekly: repeatDetails?.weekly,
    monthly: repeatDetails?.monthly,
    yearly: repeatDetails?.yearly
      ? moment(repeatDetails?.yearly).toDate()
      : undefined,

    customRepeatType: repeatDetails.customRepeatType,
    customRepeatValue: repeatDetails.customRepeatValue,
    customRepeatWeek: repeatDetails.customRepeatWeek,
    customRepeatMonth: repeatDetails?.customRepeatMonth
      ? moment(repeatDetails?.customRepeatMonth).toDate()
      : undefined,
  };
};

interface AuditData extends AuditCreateInput {
  status: AuditStatus;
}

export const convertEditableData = (value: AuditData): IFormInput => {
  return {
    template: {
      value: value.templateId,
      label: '',
    },

    repeatDetails: parseRepeatDetails(value.repeatDetails),

    auditors: value.auditors,

    notifyLO: value.notifyLO,
    scheduleTitle: value.title,

    originalValue: value,
    status: value.status,
  };
};
