import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../atoms';
import { BoxHeader } from '../../../ui-components';
import { HeaderColors } from '../../../configs';

import BaseLayoutWrapper from '../../BaseLayoutWrapper';
import LocationOwnerHome from './components/location-owner-home';

interface IProps {}

const AuditHomeLocation: FC<IProps> = () => {
  const history = useHistory();

  return (
    <BaseLayoutWrapper p={5} flexDir='column' gap={4} w='full'>
      <Flex align='center' justify='space-between' w='full'>
        <BoxHeader title='Audits' fontSize='18px' color={HeaderColors.Green} />
        <Button variant='outline' onClick={() => history.push('/audits')}>
          View all
        </Button>
      </Flex>
      <Flex flexDir='column'>
        <LocationOwnerHome />
      </Flex>
    </BaseLayoutWrapper>
  );
};

export default AuditHomeLocation;
