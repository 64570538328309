import React, { FC, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { Steps } from 'antd';

import { usePhaseDelete } from '../common';

import { EditorWrapper } from './editor-wrapper';
import EditorHeader from './EditorHeader';
import { IFormInput } from './editor.types';

import EditorSection from './section/EditorSection';
import { EditorEvent, useEditorContext } from './context';
import { EditorSectionLoader, EditorStepLoader } from './EditorLoading';
import { selected } from './helper';
import StepFocus from './StepFocus';
import { useStepItems } from './editor-steps';

interface IProps {}

const LauncherEditorContainer: FC<IProps> = () => {
  const { emit, initialising } = useEditorContext();
  const phaseDelete = usePhaseDelete();

  const { control, getValues } = useFormContext<IFormInput>();

  const { fields, insert, move, update, remove } = useFieldArray<
    IFormInput,
    'contents'
  >({
    control: control,
    name: 'contents',
  });

  const activeStep = useWatch<IFormInput, 'activeStep'>({
    control: control,
    name: 'activeStep',
  });

  const onMoveClick = async (currentIndex: number, nextIndex: number) => {
    move(currentIndex, nextIndex);
    await emit(EditorEvent.MOVE_PHASE, { currentIndex, nextIndex });
  };

  const onDeleteClick = (index: number) => {
    phaseDelete({
      category: getValues(`contents.${index}.category`),
      onDeletePress: async () => {
        const res = await emit(EditorEvent.DELETE_PHASE, index);
        if (res) {
          remove(index);
        }
      },
    });
  };

  const onTitleUpdate = async (index: number, newTitle: string) => {
    await emit(EditorEvent.UPDATE_PHASE, {
      index: index,
      title: newTitle,
    });

    update(index, {
      ...getValues(`contents.${index}`),
      category: newTitle,
    });
  };

  const maxIndex = useMemo(() => {
    return fields?.filter(selected).length || 0;
  }, [fields]);

  const stepItems = useStepItems(fields, {
    activeStep,
    insert,
    onMoveClick,
    onDeleteClick,
    onTitleUpdate,
    maxIndex,
  });

  return (
    <EditorWrapper>
      <Flex flexDir='column' gap={4} pb={4} className='launcher-editor-header'>
        <EditorHeader />

        <EditorStepLoader isLoaded={!initialising}>
          <StepFocus maxIndex={maxIndex} />
          <Steps labelPlacement='vertical' items={stepItems} />
        </EditorStepLoader>
      </Flex>

      <EditorSectionLoader isLoaded={!initialising}>
        {!!fields.length && (
          <EditorSection
            key={`section-${activeStep}`}
            activeStep={activeStep}
            isLastSection={maxIndex === activeStep + 1}
          />
        )}
      </EditorSectionLoader>
    </EditorWrapper>
  );
};

export default LauncherEditorContainer;
