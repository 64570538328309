import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { AuditMobilePreview } from './mobile-preview';
import { IFormInput } from './form-types';

interface IProps {}

const PreviewContainer: FC<IProps> = () => {
  const previewHidden = useWatch<IFormInput, 'previewHidden'>({
    name: 'previewHidden',
  });

  if (previewHidden) {
    return null;
  }

  return (
    <Flex
      position='sticky'
      top='80px'
      marginBottom='-10px'
      flexDir='column'
      gap={6}
      width='348px'
      bg='#FCFCFC'
      borderRadius='8px'
      paddingX='24px'
      paddingBottom='20px'
      paddingTop='12px'
    >
      <AuditMobilePreview />
    </Flex>
  );
};

export default PreviewContainer;
