import xlsxPopulate, { Workbook, Sheet } from 'xlsx-populate';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { FieldType } from './LocationListContainer';
import { ILocation } from 'sub-components/Locations/locationTypes/location-types';

const generateColumnLetter = (number: number): string => {
  let column = '';
  while (number > 0) {
    const remainder = (number - 1) % 26;
    column = String?.fromCharCode?.(65 + remainder) + column;
    number = Math?.floor?.((number - 1) / 26);
  }
  return column;
};

const capitalizeFirstLetter = (s: string): string => {
  if (!s) return s;
  return s?.charAt?.(0)?.toUpperCase?.() + s?.slice?.(1)?.toLowerCase?.();
};

const addHeaders = (sheet: Sheet, fieldTypes: FieldType[]): Sheet => {
  fieldTypes?.forEach((field, index) => {
    const columnLetter = generateColumnLetter?.(index + 1);
    const cellAddress = `${columnLetter}1`;
    const cell = sheet?.cell?.(cellAddress);
    cell?.value?.(capitalizeFirstLetter?.(field?.field || ''));
    cell?.style?.({ bold: true, fill: 'E0E0E0' });
    sheet?.column?.(index + 1)?.width?.(20);
  });
  return sheet;
};

const addDataRows = (
  sheet: Sheet,
  locations: ILocation[],
  fieldTypes: FieldType[]
): Sheet => {
  locations?.forEach?.((location, rowIndex) => {
    fieldTypes?.forEach?.((field, colIndex) => {
      const columnLetter = generateColumnLetter?.(colIndex + 1);
      const rowNumber = rowIndex + 2;
      const cellAddress = `${columnLetter}${rowNumber}`;
      const cell = sheet?.cell(cellAddress);

      const [fieldName, uuid] = field.id!?.split?.('_');

      let cellValue: any = '';

      switch (fieldName) {
        case 'name':
          cellValue = location?.name;
          break;
        case 'type':
          cellValue = location?.locationType;
          break;
        case 'owner':
          cellValue = location?.members
            ?.map?.((member) => member?.name)
            ?.join?.(', ');
          break;
        case 'status':
          cellValue = location?.locationStatus;
          break;
        case 'email':
          cellValue = location?.locationEmail?.join?.(', ');
          break;
        case 'phone':
          cellValue = location?.locationPhone?.join?.(', ');
          break;
        case 'city':
          cellValue = location?.address?.city;
          break;
        case 'zipCode':
          cellValue = location?.address?.zipCode;
          break;
        case 'state':
          cellValue = location?.address?.state;
          break;
        default:
          const dynamicField = location?.otherFields?.find?.(
            (of) => of?.fieldId === field?.id
          );
          if (dynamicField) {
            if (dynamicField?.files && dynamicField?.files?.length > 0) {
              const fileNames = dynamicField?.files
                ?.map?.((file) => file?.name)
                ?.join?.(', ');
              cellValue = `${dynamicField?.value}${
                dynamicField?.value ? ', ' : ''
              }${fileNames}`;
            } else {
              cellValue = dynamicField?.options?.[0] || dynamicField?.value;
            }
          }
          break;
      }

      cell?.value?.(cellValue);
    });
  });

  return sheet;
};

const setDynamicColumnWidths = (
  sheet: Sheet,
  fieldTypes: FieldType[],
  locations: ILocation[]
): void => {
  fieldTypes?.forEach?.((field, colIndex) => {
    let maxLength = field?.field?.length;

    locations?.forEach?.((location) => {
      const [fieldName, uuid] = field?.id!?.split?.('_');
      let value = '';

      switch (fieldName) {
        case 'name':
          value = location?.name;
          break;
        case 'type':
          value = location?.locationType;
          break;
        case 'owner':
          value = location?.members
            ?.map?.((member) => member?.name)
            ?.join?.(', ');
          break;
        case 'status':
          value = location?.locationStatus;
          break;
        case 'email':
          value = location?.locationEmail?.join?.(', ');
          break;
        case 'phone':
          value = location?.locationPhone?.join?.(', ');
          break;
        case 'city':
          value = location?.address?.city;
          break;
        case 'zipCode':
          value = location?.address?.zipCode;
          break;
        case 'state':
          value = location?.address?.state;
          break;
        default:
          const dynamicField = location?.otherFields?.find?.(
            (of) => of?.fieldId === field?.id
          );
          if (dynamicField) {
            if (dynamicField?.files && dynamicField?.files?.length > 0) {
              const fileNames = dynamicField?.files
                ?.map((file) => file?.name)
                ?.join(', ');
              value = `${dynamicField?.value}${
                dynamicField?.value ? ', ' : ''
              }${fileNames}`;
            } else {
              value = dynamicField?.value;
            }
          }
          break;
      }

      if (value?.length > maxLength) {
        maxLength = value?.length;
      }
    });

    sheet?.column?.(colIndex + 1)?.width?.(Math?.min?.(maxLength * 1.2, 50));
  });
};

export const generateLocationsDetailSheet = async (
  fieldTypes: FieldType[],
  locations: ILocation[]
): Promise<Workbook> => {
  const visibleFieldTypes = fieldTypes?.filter?.((field) => field?.visibility);

  const workbook: Workbook = await xlsxPopulate.fromBlankAsync();

  const sheet: Sheet = workbook.sheet(0);
  sheet.name('Locations Detail');

  addHeaders(sheet, visibleFieldTypes);

  addDataRows(sheet, locations, visibleFieldTypes);

  setDynamicColumnWidths(sheet, visibleFieldTypes, locations);

  return workbook;
};

export const exportLocationsDetail = async (
  fieldTypes: FieldType[],
  locations: ILocation[],
  fileName: string = `Locations_Detail_${moment().format('YYYY_MM_DD')}.xlsx`
): Promise<void> => {
  try {
    const workbook = await generateLocationsDetailSheet(fieldTypes, locations);

    const blob = (await workbook.outputAsync({ type: 'blob' })) as Blob;

    saveAs(blob, fileName);
  } catch (error) {
    console.error('Error exporting Locations Detail sheet:', error);
  }
};
