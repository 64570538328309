import React, { FC } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PiNote } from 'react-icons/pi';
import { QuestionType } from '../../../../types';
import TitleHeader from '../../../CardEditor/TitleHeader';
import InputFieldView from '../../listing/template-preview/InputFieldView';
import { HideComponent } from '../../take-audit/components';
import { IFormInput } from '../form-types';

interface IProps {
  question: IFormInput['questions'][number];
  questionIndex: number;
}

const MobileQuestionView: FC<IProps> = ({ question, questionIndex }) => {
  return (
    <Flex
      flexDir='column'
      padding='20px 16px'
      borderTop='1px solid #EFEFEF'
      borderBottom='1px solid #EFEFEF'
      bg='#FFFFFF'
      gap={4}
      userSelect='none'
    >
      <TitleHeader
        title={`${questionIndex + 1}. ${question?.label}`}
        isRequired={question.isRequired}
        titleStyle={{ fontSize: '15px' }}
      />

      <InputFieldView
        qType={question.qType}
        // @ts-ignore
        options={question.options!}
      />

      <Flex
        gap={3}
        align='center'
        color='#33383F'
        justify='end'
        userSelect='none'
        fontSize='10px'
        whiteSpace='nowrap'
      >
        <Flex gap={1} align='center' cursor='pointer' fontWeight={500}>
          <PiNote />
          Add note
        </Flex>

        <Divider orientation='vertical' height='16px' borderColor='#EFEFEF' />

        <HideComponent
          hidden={[
            QuestionType.FILE_UPLOAD,
            QuestionType.IMAGE_UPLOAD,
            QuestionType.VIDEO_UPLOAD,
          ].includes(question.qType)}
        >
          <Flex gap={1} align='center' cursor='pointer' fontWeight={500}>
            <FontAwesomeIcon icon={faPaperclip as IconProp} />
            Add evidence
          </Flex>

          <Divider orientation='vertical' height='16px' borderColor='#EFEFEF' />
        </HideComponent>

        <Flex
          gap={1}
          align='center'
          cursor='pointer'
          fontWeight={500}
          _disabled={{
            opacity: 0.4,
            pointerEvents: 'none',
            cursor: 'not-allowed',
          }}
        >
          <FontAwesomeIcon icon={faFlag as IconProp} />
          <Box fontWeight={500}>Mark flag</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MobileQuestionView;
