import React, { FC, useMemo } from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxGroup,
  Flex,
} from '@chakra-ui/react';
import { QuestionsState } from '../../types';

interface IProps {
  options: QuestionsState['options'];
  value: string[];
  onChange: (value: string[]) => void;
}

const Checkbox: FC<IProps> = ({ options, value, onChange }) => {
  const _options = useMemo(() => {
    return options?.filter((opt) => !opt.isDisabled);
  }, [options]);

  return (
    <Flex
      flexDir='column'
      gap={3}
      sx={{
        '.chakra-checkbox__label': {
          color: '#6F767E',
          fontSize: 14,
          fontWeight: 500,
        },
        '.chakra-checkbox__control': {
          borderRadius: '6px',
        },
      }}
    >
      <CheckboxGroup
        value={value}
        onChange={(selectedValues: string[]) => onChange(selectedValues)}
      >
        {_options?.map((option) => (
          <ChakraCheckbox key={option?.eid} value={option?.eid} size='lg'>
            {option?.label}
          </ChakraCheckbox>
        ))}
      </CheckboxGroup>
    </Flex>
  );
};

export default Checkbox;
