import { gql } from '@apollo/client';

export const retrieveData = gql`
  query AuditTemplateById($eid: String!) {
    AuditTemplateById(eid: $eid) {
      eid
      title
      status
      categoryId
      description
      questions
    }
  }
`;
