import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from '@apollo/client/utilities';
import { toArray } from '../../../../utils';
import { AuditResponseEntity } from '../../../../types';
import { ControlLayer, ControlLayerArgs, SectionEntity } from '../types';
import { isCompleted } from '../tabke-audit.helpers';

interface Params {
  locationId: string;
  sessionId: string;
}

export const useControlLayer = ({
  service,
}: ControlLayerArgs): ControlLayer => {
  const { locationId, sessionId } = useParams<Params>();

  useEffect(() => {
    getSessionDetailsFn();
  }, []);

  const getSessionDetailsFn = () => {
    if (sessionId) {
      service.sessionDetails.getSessionDetails({
        variables: {
          eid: sessionId,
        },
      });
    }
  };

  const sessionData = useMemo(() => {
    if (service.sessionDetails?.sessionDetailResult?.data) {
      const cloned = cloneDeep(
        service.sessionDetails.sessionDetailResult.data?.AuditSessionById
      );

      return {
        ...cloned,
        questions: cloned.questions.filter((question) => !question?.isDisabled),
      };
    }

    return undefined;
  }, [service.sessionDetails.sessionDetailResult.data]);

  const categorizeQuestions = useMemo(() => {
    const categorizedData: Array<SectionEntity> = [];

    let currentSection: SectionEntity = {
      eid: 'default',
      sectionName: 'Audit Questionnaire',
      data: [],
      selected: false,
      questionCount: 0,
    };

    if (sessionData) {
      const foundLocationDetails = sessionData?.assignments?.find(
        (assignment) => assignment?.locationId === locationId
      );

      const responseObj = toArray(foundLocationDetails?.response).reduce<
        Record<string, AuditResponseEntity>
      >((acc, value) => {
        if (!acc[value.qid]) {
          acc[value.qid] = value;
        }

        return acc;
      }, {});

      sessionData.questions.forEach((question) => {
        // If the question is a section, create a new section object
        if (question.qType === 'section') {
          if (currentSection.data.length > 0) {
            currentSection.questionCount = currentSection.data.length;
            categorizedData.push(currentSection);
          }
          // Set the new current section
          currentSection = {
            eid: question.eid, // Assuming question.id can serve as a unique identifier
            sectionName: question.label,
            data: [],
            selected: false,
            questionCount: 0,
          };
        } else {
          // If it's not a section, add the question to the current section's data

          // Integrate the logic for setting the question's response data

          let foundResponse = responseObj[question?.eid];

          // Setting the response data to the question object
          const updatedQuestion = {
            ...question,
            response: foundResponse?.response,
            responseUrl: foundResponse?.responseUrl || [],
            responseId: foundResponse?.responseId || [],
            noteText: foundResponse?.noteText || '',
            images: foundResponse?.images,
            isFlagged: foundResponse?.isFlagged || false,
            responseScore: foundResponse?.score || 0,
            isCompleted: isCompleted(foundResponse, question),
            isNotFilled: !foundResponse,
          };

          // Add the updated question to the current section
          currentSection.data.push(updatedQuestion);
        }
      });

      // Push the last section to the categorizedData if it has any questions
      if (currentSection.data.length > 0) {
        currentSection.questionCount = currentSection.data.length;
        categorizedData.push(currentSection);
      }
    }

    return categorizedData;
  }, [sessionData, locationId]);

  return { categorizeQuestions, sessionData };
};
