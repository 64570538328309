import { toArray } from '../../../utils';
import { IFormInput } from '../create-audit/form-types';
import { AuditInput } from '../create-audit/audit.types';
import { calculateScore, optionHandler } from '../create-audit/data-helper';

const parseEid = (eid?: string) => (eid?.includes('-') ? undefined : eid);

export const editedQuestionData = (
  questions: IFormInput['questions']
): AuditInput['questions'] => {
  return toArray(questions).map((_question) => {
    const optionsMap = optionHandler(
      _question.options,
      _question.hasScoring,
      _question.hasFlaggedResponse,
      _question.requiresEvidence
    );

    const question: AuditInput['questions'][number] = {
      eid: parseEid(_question.eid),
      label: _question.label,
      qType: _question.qType,
      isRequired: !!_question.isRequired,
      isDisabled: !!_question.isDisabled,
      hasScoring: !!_question.hasScoring,
      hasFlaggedResponse: !!_question.hasFlaggedResponse,
      requiresEvidence: !!optionsMap.requiresEvidence,
      options: optionsMap.options,
      score: calculateScore(_question.options!, _question.qType),
    };

    return question;
  });
};
