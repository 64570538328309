import React, { FC, useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from 'atoms';
import { useUserDataSelector } from 'hooks';
import { shallowEqual } from 'utils';

import { ITasksForToday } from 'pages/Home/Home';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { AuthRole } from 'sop-commons/src/client';
import BaseLayoutWrapper from 'sub-components/BaseLayoutWrapper';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import MyTasksTab from './components/MyTasksTab';
import SupervisedTab from './components/SupervisedTab';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

type IButtonValue = 'supervised' | 'myTasks';

interface HProps {
  value: IButtonValue;
  onClick: (newValue: IButtonValue) => void;
  event?: any;
}

const styles = {
  variant: 'ghost',
  color: '#6F7E7C',
  _hover: {
    bg: 'gray.50',
  },
};

const Header: FC<HProps> = ({ value, onClick, event }) => {
  const authRole = useUserDataSelector((state) => state.authRole);
  return (
    <Flex
      align='center'
      gap='12px'
      paddingBlock='8px'
      fontSize='15px'
      fontWeight={500}
      color='#6F767E'
    >
      <Button
        {...(value === 'supervised' ? {} : styles)}
        onClick={() => {
          onClick('supervised');
          event && deployEvent(event.SUPERVISED_BY_ME_TAB_HOME);
        }}
      >
        {authRole === AuthRole.LOCATION_OWNER
          ? 'My locations’ tasks'
          : 'Supervised by me'}
      </Button>
      <Button
        {...(value === 'myTasks' ? {} : styles)}
        onClick={() => onClick('myTasks')}
      >
        <Flex
          align='center'
          justify='center'
          gap='5px'
          onClick={() => event && deployEvent(event.MY_TASKS_TAB_HOME)}
        >
          <Text>My tasks</Text>
          {/* <Center
            boxSize='20px'
            bg='#FF6A55'
            borderRadius='50%'
            color='white'
            fontSize='12px'
          >
            2
          </Center> */}
        </Flex>
      </Button>
      <Box flex={1} />
    </Flex>
  );
};

interface IProps {
  tasksForTodayData: ITasksForToday;
  setTasksForTodayData: React.Dispatch<React.SetStateAction<ITasksForToday>>;
  event?: any;
}

const TasksBoard: FC<IProps> = ({
  tasksForTodayData,
  setTasksForTodayData,
  event,
}) => {
  const history = useHistory();
  const { type, authRole } = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      type: state.type,
    }),
    shallowEqual
  );
  const [value, setValue] = useState<IButtonValue>('supervised');
  const [hideViewBtn, setHideViewBtn] = useState(false);

  const onClick = (newValue: IButtonValue) => {
    setValue(newValue);
  };

  const status = authRole !== AuthRole.WORKER && type !== 'branch';

  return (
    <BaseLayoutWrapper bg='initial'>
      <Flex
        w='full'
        _hover={status ? { '.show-on-hover': { opacity: 1 } } : {}}
        flexDir='column'
        gap={1}
      >
        <Flex
          flexDir='column'
          gap={4}
          bg='white'
          padding='24px 24px 16px'
          borderTopRadius='8px'
        >
          <Flex align='center' justify='space-between' w='full'>
            <GenericColorHeader
              title={status ? 'Tasks' : 'My tasks'}
              color={HeaderColors.Purple}
            />
            {!hideViewBtn && (
              <Box
                opacity={status ? 0 : 1}
                className='show-on-hover'
                transition='opacity 0.3s ease'
              >
                <PrimaryButton
                  title={status ? 'Task dashboard' : 'View all'}
                  variant='outline'
                  style={{ color: '#6F767E' }}
                  onClick={() => {
                    if (status) {
                      history.push('tasks/dashboard');
                    } else {
                      history.push('tasks/my-tasks', {
                        from_where: 'Home Page',
                      });
                    }
                  }}
                />
              </Box>
            )}
          </Flex>

          {status && <Header value={value} onClick={onClick} event={event} />}
        </Flex>

        {status ? (
          value === 'supervised' ? (
            <SupervisedTab
              tasksForTodayData={tasksForTodayData}
              setTasksForTodayData={setTasksForTodayData}
              event={CommonAmplitudeEvents.VIEW_SUPERVISED_BY_ME_TASK_HOME}
            />
          ) : (
            <MyTasksTab
              hideViewBtn={hideViewBtn}
              setHideViewBtn={setHideViewBtn}
              tasksForTodayData={tasksForTodayData}
              setTasksForTodayData={setTasksForTodayData}
              event={CommonAmplitudeEvents.VIEW_MY_TASK_HOME}
            />
          )
        ) : (
          <MyTasksTab
            hideViewBtn={hideViewBtn}
            setHideViewBtn={setHideViewBtn}
            tasksForTodayData={tasksForTodayData}
            setTasksForTodayData={setTasksForTodayData}
            event={CommonAmplitudeEvents.VIEW_MY_TASK_HOME}
          />
        )}
      </Flex>
    </BaseLayoutWrapper>
  );
};

export default TasksBoard;
