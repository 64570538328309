import React, { FC, useEffect, useMemo } from 'react';
import { Flex, IconButton, usePrevious } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { useQuery } from '@apollo/client';
import { client } from 'sop-commons/src/client';
import { AuditQuestionEntity } from '../../../../types';
import { useSafeState } from '../../../../hooks';
import { Button } from '../../../../atoms';
import { HideComponent } from '../../take-audit/components';
import { retrieveData } from './preview.types';
import Section from './Section';
import QuestionView from './QuestionView';
import PreviewHeader from './PreviewHeader';
import PreviewSkeleton from './PreviewSkeleton';

interface PreviseSection {
  eid: string;
  sectionName: string;
  selected: boolean;
  questions: AuditQuestionEntity[];
  questionCount: number;
}

type AuditInput = {
  eid: string;
  title: string;
  status: 'active' | 'inactive' | 'archived';
  categoryId: string;
  description?: string;
  questions: AuditQuestionEntity[];
};

interface IProps {
  templateId: string;
  onEditQuestion?: (input: AuditInput) => Promise<void>;
  onImportAndSchedule?: (input: AuditInput) => Promise<void>;
}

const PreviewContent: FC<IProps> = ({
  templateId,
  onEditQuestion,
  onImportAndSchedule,
}) => {
  const [selectedSection, setSection] = useSafeState(0);
  const prevId = usePrevious(templateId);
  const { data, loading } = useQuery<Record<'AuditTemplateById', AuditInput>>(
    retrieveData,
    {
      client: client,
      variables: {
        eid: templateId,
      },
    }
  );

  useEffect(() => {
    if (prevId !== templateId) {
      setSection(0);
    }
  }, [templateId]);

  const audit = data?.AuditTemplateById;

  const sections = useMemo(() => {
    const categorizedData: Array<PreviseSection> = [];

    let currentSection: PreviseSection = {
      eid: 'default',
      sectionName: 'Audit Questionnaire',
      questions: [],
      selected: false,
      questionCount: 0,
    };

    if (audit?.questions) {
      audit.questions.forEach((question) => {
        if (question.isDisabled) {
          return true;
        }
        // If the question is a section, create a new section object
        if (question.qType === 'section') {
          if (currentSection.questions.length > 0) {
            currentSection.questionCount = currentSection.questions.length;
            categorizedData.push(currentSection);
          }
          // Set the new current section
          currentSection = {
            eid: question.eid,
            sectionName: question.label,
            questions: [],
            selected: false,
            questionCount: 0,
          };
        } else {
          currentSection.questions.push(question);
        }
      });

      // Push the last section to the categorizedData if it has any questions
      if (currentSection.questions.length > 0) {
        currentSection.questionCount = currentSection.questions.length;
        categorizedData.push(currentSection);
      }
    }

    return categorizedData;
  }, [audit?.questions]);

  if (loading) {
    return <PreviewSkeleton />;
  }

  const auditData = sections[selectedSection];

  if (!audit || !auditData) return null;

  return (
    <Flex position='relative' flexDir='column' gap={1}>
      <PreviewHeader
        title={audit?.title}
        description={audit?.description}
        status={audit?.status}
        onEditQuestion={
          onEditQuestion ? () => onEditQuestion?.(audit) : undefined
        }
        onImportAndSchedule={
          onImportAndSchedule ? () => onImportAndSchedule?.(audit) : undefined
        }
      />

      <Section
        sectionName={auditData.sectionName}
        noOfQuestions={auditData.questionCount}
      />

      <Flex flexDir='column' gap={3} paddingX={6} paddingBottom={6}>
        {auditData.questions?.map((question, index) => {
          return (
            <QuestionView
              key={question.eid}
              questionIndex={index}
              question={question}
            />
          );
        })}
      </Flex>

      <HideComponent hidden={sections.length <= 1}>
        <Flex
          position='sticky'
          bottom={0}
          padding='9px 13px'
          bg='#FFFFFF'
          boxShadow='10px 0px 20px -6px #00000040'
          gap={3}
        >
          <IconButton
            size='lg'
            aria-label='back'
            colorScheme='blue'
            isDisabled={selectedSection === 0}
            onClick={() => {
              setSection((prevState) => {
                if (sections[prevState - 1]) {
                  return prevState - 1;
                }
                return prevState;
              });
            }}
          >
            <ArrowBackIcon />
          </IconButton>

          <Button
            width='full'
            colorScheme='blue'
            size='lg'
            isDisabled={sections?.length === selectedSection + 1}
            rightIcon={<ArrowForwardIcon />}
            onClick={() => {
              setSection((prevState) => {
                if (sections[prevState + 1]) {
                  return prevState + 1;
                }
                return prevState;
              });
            }}
          >
            Next
          </Button>
        </Flex>
      </HideComponent>
    </Flex>
  );
};

export default PreviewContent;
