import React, { FC } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '../../../../atoms';
import { ATFormInput } from './create-audit-task.types';
import { ResponsesByLocationEntity } from '../../../../pages/Audits/Detail/components/QuestionSection/types/audit-question.types';
import FlagDetails from './FlagDetails';

interface IProps {
  auditTitle?: string;
  questionTitle?: string;
  responses?: ResponsesByLocationEntity[];
}

const TaskDetails: FC<IProps> = ({ auditTitle, questionTitle, responses }) => {
  const { control } = useFormContext<ATFormInput>();

  return (
    <Flex flexDir='column' gap={4}>
      <Controller
        control={control}
        name='title'
        // rules={{
        //   required: t('task:validation.supervisorRequired'),
        // }}
        rules={{
          required: 'Task Title is required',
        }}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={2} isInvalid={fieldState.invalid}>
              <Input placeholder='Title' border='none' size='lg' {...field} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name='description'
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl>
              <Input
                placeholder='Add description (optional)'
                border='none'
                {...field}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Flex
        border='2px solid #EFEFEF'
        bg='#F4F4F4'
        borderRadius='12px'
        p='12px 16px'
        gap='5px'
      >
        <Box as='span' fontWeight='500' color='#6F767E'>
          Audit:
        </Box>
        <Box as='span' fontWeight='600' color='#1A1D1F'>
          {auditTitle}
        </Box>
      </Flex>

      <Accordion allowToggle>
        <AccordionItem bg='#EDFAFF' borderRadius='8px' border='none'>
          <AccordionButton _hover={{ bg: '#EDFAFF', borderRadius: '8px' }}>
            <Box
              as='span'
              flex='1'
              textAlign='left'
              fontSize='14px'
              fontWeight={600}
              paddingY='6px'
            >
              Related flags
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <FlagDetails questionTitle={questionTitle} responses={responses} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default TaskDetails;
