export const getFormattedDateTime = (timestamp: string) => {
  let date = new Date(timestamp).toString();
  let dateArray = date.split(' ');
  let day = dateArray[2];
  let month = dateArray[1];
  let year = dateArray[3];
  let time = new Date(timestamp).toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });
  let sortOrder = new Date(timestamp).getTime();

  return {
    dateString: `${day} ${month} ${year}`,
    timeString: time,
    order: sortOrder,
  };
};
