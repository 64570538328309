import React, { FC } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';

import CheckButton from '../CheckButton';
import { IFormInput, Weekdays } from '../../task.types';

export const WeekDaysData: Array<{
  label: TFuncKey<'task'[], undefined>;
  value: typeof Weekdays[number];
}> = [
  {
    label: 'task:weekDays.monday',
    value: 'Monday',
  },
  {
    label: 'task:weekDays.tuesday',
    value: 'Tuesday',
  },
  {
    label: 'task:weekDays.wednesday',
    value: 'Wednesday',
  },
  {
    label: 'task:weekDays.thursday',
    value: 'Thursday',
  },
  {
    label: 'task:weekDays.friday',
    value: 'Friday',
  },
  {
    label: 'task:weekDays.saturday',
    value: 'Saturday',
  },
  {
    label: 'task:weekDays.sunday',
    value: 'Sunday',
  },
];

interface IProps {
  isEdit?: boolean;
}

const WeekDaysSelect: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['task']);
  const { control } = useFormContext<IFormInput>();

  const repeatCycle = useWatch<IFormInput, 'repeatCycle'>({
    name: 'repeatCycle',
  });

  if (repeatCycle !== 'weekly') {
    return null;
  }

  const onChange = (
    field: ControllerRenderProps<IFormInput, 'weekDays'>,
    opt: typeof WeekDaysData[number]
  ) => {
    const newValues = [...(field.value || [])];

    if (newValues.includes(opt.value)) {
      return field.onChange(newValues.filter((val) => val !== opt.value));
    }

    field.onChange(newValues.concat(opt.value));
  };

  return (
    <Box pb={3}>
      <Controller
        control={control}
        name='weekDays'
        rules={{
          required: t('task:validation.weekRequired'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <Flex gap={2}>
                {WeekDaysData.map((opt) => {
                  return (
                    <CheckButton
                      isReadOnly={isEdit}
                      key={opt.value}
                      isSelected={field.value?.includes(opt.value)}
                      clickDisabled={false}
                      onClick={() => onChange(field, opt)}
                    >
                      <Trans t={t} i18nKey={opt.label} />
                    </CheckButton>
                  );
                })}
              </Flex>

              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default WeekDaysSelect;
