import { Box, BoxProps, Flex, forwardRef } from '@chakra-ui/react';

export const BoxCard = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Box ref={ref} bg='white' borderRadius='12px' p={6} {...props} />;
});

export const FlexCard = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Flex ref={ref} bg='white' borderRadius='12px' p={6} {...props} />;
});

export default BoxCard;
