import React, { FC } from 'react';
import { useFieldArray, useFormState } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';

import { Button } from 'atoms';

import { IFormInput } from '../../audit-schedule.types';
import AuditorInput from './AuditorInput';

interface IProps {}

const AuditorSelection: FC<IProps> = () => {
  const { fields, append } = useFieldArray<IFormInput, 'auditors'>({
    name: 'auditors',
  });

  const isDisabled = useFormState<IFormInput>().disabled;

  const onAddClick = () => {
    append({
      userId: [],
      locations: [],
    });
  };

  return (
    <Flex flexDir='column' gap={4}>
      {fields.map((field, index) => (
        <AuditorInput key={field.id} fieldId={field.id} fieldIndex={index} />
      ))}

      <Button
        w='max-content'
        leftIcon={<AddIcon />}
        variant='ghost'
        colorScheme='blue'
        onClick={onAddClick}
        isDisabled={isDisabled}
      >
        Add Auditors
      </Button>
    </Flex>
  );
};

export default AuditorSelection;
