import React, { useMemo } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsis,
  faFolderArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRotateLeft,
  faArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

import { ColumnsType, SorterResult } from 'atoms';
import { ActionMenu } from 'ui-components';

import { AuditTemplateItem } from '../query/audits-listing.graphql';
import { stringToColor } from '../common/helpers';
import { Any } from '../../../../types';

interface IProps<T> {
  actionHandler?: (action: string, value: T) => void | Promise<void | Any>;
  sorter?: SorterResult<T>;
}

export const useArchivedColumns = (
  props?: IProps<AuditTemplateItem>
): ColumnsType<AuditTemplateItem> => {
  const { actionHandler } = props || {};
  return useMemo((): ColumnsType<AuditTemplateItem> => {
    return [
      {
        title: 'Template Name',
        dataIndex: 'title',
        width: '35%',
        render: (title) => {
          return (
            <Flex gap={2} alignItems='center'>
              <Box color='#1A1D1F' fontWeight={700} noOfLines={1}>
                {title}
              </Box>
              <FontAwesomeIcon
                style={{
                  backgroundColor: '#F4F4F4',
                  padding: '7px 11px',
                  borderRadius: '3px',
                  cursor: 'pointer',
                }}
                color='#6F767E'
                icon={faFolderArrowDown as IconProp}
              />
            </Flex>
          );
        },
      },
      {
        title: 'Template Type',
        dataIndex: 'category',
        key: 'category',
        sorter: true,
        render: (category) => (
          <Center
            borderRadius='6px'
            fontSize='14px'
            fontWeight='500'
            maxW='max-content'
            padding='4px 10px'
            bg={stringToColor(category?.eid)}
          >
            {category?.name || 'Not Defined'}
          </Center>
        ),
      },
      {
        title: 'Created On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (createdAt) => {
          const _moment = moment(createdAt);
          return (
            <Flex flexDir='column' fontWeight='400'>
              <Box>{_moment.format('DD MMM YYYY')}</Box>
              <Box fontSize='12px' color='#8D949C'>
                {_moment.format('hh:mm A')}
              </Box>
            </Flex>
          );
        },
      },
      {
        title: 'Last Activity',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: true,
        render: (updatedAt) => {
          const _moment = moment(updatedAt);
          return (
            <Flex flexDir='column' fontWeight='400'>
              <Box>{_moment.format('DD MMM YYYY')}</Box>
              <Box fontSize='12px' color='#8D949C'>
                {_moment.format('hh:mm A')}
              </Box>
            </Flex>
          );
        },
      },
      {
        dataIndex: 'action',
        align: 'center',
        render: (__, data) => (
          <Center>
            <ActionMenu
              menuData={[
                {
                  name: 'View Template',
                  value: 'viewTemplate',
                  icon: faArrowUpRight,
                  normalIcon: 'fontAwesome',
                  iconColor: '#6F767E',
                },
                {
                  name: 'Restore Template',
                  value: 'restoreTemplate',
                  icon: faArrowRotateLeft,
                  normalIcon: 'fontAwesome',
                  iconColor: '#6F767E',
                },
              ]}
              arrowSize={10}
              closeOnBlur={true}
              offset={[24, 8]}
              clickedItem={(_, clickedItem) => {
                return actionHandler?.(clickedItem, data);
              }}
              strategy={'fixed'}
            >
              <Box w='min-content' px={1}>
                <FontAwesomeIcon
                  cursor={'pointer'}
                  fontSize={'1.2rem'}
                  icon={faEllipsis as IconProp}
                />
              </Box>
            </ActionMenu>
          </Center>
        ),
      },
    ];
  }, [actionHandler]);
};
