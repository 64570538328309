import React, { FC } from 'react';
import { Flex, Skeleton, SkeletonText } from '@chakra-ui/react';
import { getRandomNumber } from '../../../../utils';

interface IProps {}

const PreviewSkeleton: FC<IProps> = () => {
  return (
    <Flex flexDir='column'>
      <Flex flexDir='column' padding={4} bg='#FFFFFF'>
        <Skeleton borderRadius='7px' h='20px' width='230px' my='5px' />

        <Skeleton borderRadius='7px' h='12px' width='300px' my='3px' />

        <Flex gap={5} mt={5}>
          <Skeleton flex={1} borderRadius='7px' h={12} />
          <Skeleton flex={1} borderRadius='7px' h={12} />
        </Flex>
      </Flex>

      {/*<Skeleton borderRadius='7px' h='12px' width='300px' my='3px' />*/}

      <Flex flexDir='column' gap={3} padding={6}>
        {Array.from({ length: getRandomNumber(2, 4) }).map((_, i) => (
          <Flex
            key={i}
            flexDir='column'
            padding='20px 16px'
            border='1px solid #C7C7C7'
            borderRadius='8px'
            bg='#FFFFFF'
            gap={4}
          >
            <Skeleton h='16px' width='300px' my='4px' />

            <SkeletonText skeletonHeight='12px' />

            <Flex gap={3} align='center' justify='end' py='3px'>
              <Skeleton h='14px' width='90px' />
              <Skeleton h='14px' width='80px' />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default React.memo(PreviewSkeleton);
