import React from 'react';
import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

const Container = forwardRef<FlexProps, 'div'>((props, ref) => {
  return (
    <Flex
      ref={ref}
      flexDir='column'
      gap={4}
      w='full'
      border='.5px solid #C7C7C7'
      borderRadius='8px'
      p='24px 27px'
      {...props}
    />
  );
});

export default Container;
