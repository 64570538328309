import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useWatch } from 'react-hook-form';
import { IFormInput } from '../../../../audit-schedule.types';
import { SimpleDatePicker } from '../../../../../../../ui-components/DateFilter/components';

interface IProps {}

const CustomRepeatDate: FC<IProps> = () => {
  const type = useWatch<IFormInput, 'repeatDetails.customRepeatType'>({
    name: 'repeatDetails.customRepeatType',
  });

  if (type !== 'months') {
    return null;
  }

  return (
    <Controller<IFormInput, 'repeatDetails.customRepeatMonth'>
      name='repeatDetails.customRepeatMonth'
      rules={{
        required: 'Please select custom repeat date',
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl display='flex' gap={4} isInvalid={!!fieldState.error}>
            <SimpleDatePicker
              disabled={field.disabled}
              isSelected={!!field.value}
              onChange={field.onChange}
              selectedValue={field.value || null}
              placeholderText=''
              minDate={new Date('2023/05/01')}
              maxDate={new Date('2023/05/31')}
              dateFormat='do'
              renderCustomHeader={() => null}
              hideDays
              shouldCloseOnSelect
            />
            <FormErrorMessage mt='0'>
              {fieldState.error?.message}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default CustomRepeatDate;
