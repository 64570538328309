import React, { FC } from 'react';
import { Checkbox, Flex } from '@chakra-ui/react';

interface IProps {
  label: string;
  isSelected: boolean;
}

const OptionItem: FC<IProps> = ({ label, isSelected }) => {
  return (
    <Flex
      gap={2}
      width='full'
      fontSize='14px'
      fontWeight='600'
      padding='8px 10px'
      borderRadius='8px'
      color='#6F767E'
      aria-selected={isSelected}
      _hover={{
        color: '#1A1D1F',
      }}
      _selected={{
        bg: '#2A85FF1A',
        color: '#2A85FF',
      }}
    >
      <Checkbox isChecked={isSelected} isReadOnly />
      {label}
    </Flex>
  );
};

export default OptionItem;
