const useSetCurrentURLToLocalStorage = (url: string): void => {
  localStorage.setItem('currentUrl', url);

  //Code to remove launcher selected option when moving to any other page
  if (!localStorage.getItem('currentUrl')?.includes('launcher')) {
    localStorage.removeItem('launcherSelectedOption');
  }
};

export default useSetCurrentURLToLocalStorage;
