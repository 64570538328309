import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { get, useController } from 'react-hook-form';
import { SectionEntity } from '../../types';
import { evidenceValidate } from '../question-sections/input.validator';
import EvidenceList from './EvidenceList';
import EvidenceWrapper from './EvidenceWrapper';
import { useUploadContext } from './EvidenceProvider';

const EvidenceUpload: FC<{
  sectionIndex: number;
  questionIndex: number;
}> = ({ sectionIndex, questionIndex }) => {
  const notHaveImage = useUploadContext(
    (state) => !(state.value?.length + state.uploadQue?.length)
  );

  const { fieldState } = useController<SectionEntity, `data.${number}.images`>({
    name: `data.${questionIndex}.images`,
    defaultValue: [],
    rules: {
      validate: (value, values) => {
        return evidenceValidate(value, get(values, `data.${questionIndex}`));
      },
    },
  });

  if (notHaveImage) {
    return fieldState.invalid ? (
      <Flex align='center' color='red.500'>
        {fieldState?.error?.message}
      </Flex>
    ) : null;
  }

  return (
    <Flex gap={3} flexWrap='wrap'>
      <EvidenceList />
      <EvidenceWrapper />
    </Flex>
  );
};

export default EvidenceUpload;
