import { Box, useFormControlContext } from '@chakra-ui/react';
import React, { FC } from 'react';
import OptionViewInput from './OptionViewInput';

interface IProps {
  value: string;
}

const QuestionViewInput: FC<IProps> = ({ value }) => {
  const field = useFormControlContext();

  if (field.isInvalid) {
    return <OptionViewInput value={value} />;
  }

  return (
    <Box fontWeight='600' maxW='max-content'>
      {value}
      {field.isRequired ? (
        <Box as='span' color='red' pl={1}>
          *
        </Box>
      ) : null}
    </Box>
  );
};

export default QuestionViewInput;
