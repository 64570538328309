import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Flex,
  Image,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  IFilesEntity,
  ILocationDetails,
  IOtherFieldsEntity,
} from 'pages/LocationDetails/utils/location-details.types';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldType, userObj } from 'sop-commons/src/client';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { AuthRole } from 'authorization';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FILENAME_REGEX } from 'utils/constants';
import { useUserDataSelector } from 'hooks';
import { OtherFieldInput } from 'pages/Locations/AddLocation/add-location.types';

interface IProps {
  details: ILocationDetails | undefined;
}

interface IOtherFields extends IOtherFieldsEntity {
  oldLocationsSupport?: boolean;
  visible?: boolean;
  fieldType: FieldType | undefined;
  fieldName: string | null;
}

const ImageViewModal: FC<{
  name: string;
  url: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ name, url, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>
          <GenericColorHeader color='#CABDFF' title={name} />
        </ModalHeader>
        <ModalCloseButton mt={6} mr={4} />
        <ModalBody>
          <Flex>
            <Image src={url} w='full' h='auto' />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const OtherDetails: FC<IProps> = ({ details }) => {
  const { t } = useTranslation(['location']);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImageDetails, setSelectedImageDetails] = useState<{
    name: string;
    url: string;
  }>({ name: '', url: '' });

  const { locationFields, loggedInUserAuthRole } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      locationFields: state?.entity?.locationFields,
    })
  );
  console.log(
    'here',
    'details',
    details?.otherFields,
    'currentEntFields',
    locationFields
  );
  const otherDetailsList = useCallback(() => {
    if (locationFields?.length > 0) {
      const mergedArray =
        details?.otherFields.map((field) => {
          const locationField = locationFields.find(
            (location) => location.eid === field.fieldId
          );
          return {
            ...field,
            fieldType: locationField?.fieldType,
            fieldName: locationField ? locationField.fieldName : null,
            visible: locationField ? locationField.visibleToLocations : true,
            oldLocationsSupport: locationField
              ? locationField.oldLocationSupport
              : false,
          };
        }) || [];

      const existingFieldIds =
        details?.otherFields.map((field) => field?.fieldId) || [];
      const missingFields = locationFields?.filter(
        (locField) =>
          locField?.visibleToLocations &&
          !existingFieldIds?.includes(locField?.eid)
      );

      const missingFieldsMapped: IOtherFields[] = missingFields?.map(
        (locField) => ({
          fieldType: locField?.fieldType,
          fieldName: locField?.fieldName,
          createdAt: '',
          fieldId: locField?.eid,
          visible: locField?.visibleToLocations,
          oldLocationsSupport: locField?.oldLocationSupport,
          files: [],
          options: locField?.options || [],
          updatedAt: '',
          value: '-',
        })
      );

      const combinedArray = [...mergedArray, ...missingFieldsMapped];

      if (loggedInUserAuthRole === AuthRole.LOCATION_OWNER) {
        const loFieldsData = combinedArray?.filter((field) => {
          return field?.visible && field?.oldLocationsSupport;
        });
        return loFieldsData;
      }
      return combinedArray.filter((locFields) => locFields.fieldName !== null); //nofieldName=> feild been deleted by SA/A
    }
  }, [locationFields, details, loggedInUserAuthRole]);

  const valueRenderer = (customField: IOtherFields) => {
    switch (customField?.fieldType) {
      case 'text':
        return <Text>{customField?.value}</Text>;
      case 'date':
        return (
          <Text>
            {moment(customField?.value)?.isValid()
              ? moment.utc(customField?.value)?.format('LLL')
              : '-'}
          </Text>
        );
      case 'document':
        return (
          <Tooltip
            hasArrow
            label={
              customField?.files?.[0]?.url ? customField?.files?.[0]?.url : ''
            }
          >
            <Link
              href={customField?.files?.[0]?.url}
              isExternal
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <Flex
                align='center'
                columnGap='5px'
                bg='#e0f5fe'
                p='5px 10px'
                borderRadius='7px'
                width='fit-content'
                cursor='pointer'
              >
                {customField?.files?.[0]?.type === 'image' ? (
                  <Image
                    src={customField?.files?.[0]?.url}
                    minW='20px'
                    maxW='20px'
                    minH='20px'
                    maxH='20px'
                  />
                ) : (
                  <FontAwesomeIcon icon={faFile as IconProp} fontSize='18px' />
                )}
                <Text isTruncated maxW='150px'>
                  {customField?.files?.[0]?.url
                    ? customField?.files?.[0]?.url?.split(FILENAME_REGEX).pop()
                    : '-'}
                </Text>
              </Flex>
            </Link>
          </Tooltip>
        );
      case 'dropDown':
        return (
          <Flex flexDir='column' gap='2px'>
            {customField?.options?.map((option: string, index: number) => (
              <Flex
                key={index}
                align='center'
                columnGap='5px'
                bg='#e0f5fe'
                p='5px 10px'
                borderRadius='7px'
                width='fit-content'
              >
                <Box as='span'>{option}</Box>
              </Flex>
            ))}
          </Flex>
        );
      case 'singleChoice':
        return (
          <Flex
            align='center'
            columnGap='5px'
            bg='#e0f5fe'
            p='5px 10px'
            borderRadius='7px'
            width='fit-content'
          >
            <Box as='span'>{customField?.value}</Box>
          </Flex>
        );
      default:
        return <Text>-</Text>;
    }
  };

  return (
    <Flex flexDir='column' gap='20px'>
      <GenericColorHeader
        title={t('location:otherDetails')}
        color='#FF6A5566'
      />
      <Flex flexDir='column' gap='20px'>
        <List spacing={4}>
          {otherDetailsList()?.map((detail) => (
            <ListItem key={detail?.fieldId}>
              <Flex flexDir='column'>
                <Text m={0} p={0} fontWeight={600} fontSize='14px'>
                  {detail?.fieldName}
                </Text>
                {valueRenderer(detail)}
              </Flex>
            </ListItem>
          ))}
        </List>
      </Flex>
      {openImageModal && (
        <ImageViewModal
          name={selectedImageDetails?.name}
          url={selectedImageDetails?.url}
          isOpen={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
            setSelectedImageDetails({ name: '', url: '' });
          }}
        />
      )}
    </Flex>
  );
};

export default OtherDetails;
