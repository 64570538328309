import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IFormInput } from '../../form-types';
import QuestionTypeRender from './QuestionTypeRender';
import QuestionViewInput from './QuestionViewInput';

interface IProps {
  questionIndex: number;
  fieldId: string;
}

const AuditQuestionView: FC<IProps> = ({ questionIndex, fieldId }) => {
  const { setValue } = useFormContext<IFormInput>();
  const data = useWatch<IFormInput, `questions.${number}`>({
    name: `questions.${questionIndex}`,
  });

  const { setNodeRef, transform, transition } = useSortable({
    id: fieldId,
    data: { questionIndex },
  });

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='8px'
      bg='#FCFCFC'
      padding='18px 35px'
      gap={5}
      _hover={{ shadow: 'md' }}
      onClick={() => setValue('selectedQuestion', data.tempEid)}
      marginLeft={7}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
    >
      <Controller<IFormInput, `questions.${number}.label`>
        name={`questions.${questionIndex}.label`}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              isRequired={data.isRequired}
              isInvalid={fieldState.invalid}
            >
              <QuestionViewInput value={field.value} />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <QuestionTypeRender questionIndex={questionIndex} data={data} />
    </Flex>
  );
};

export default AuditQuestionView;
