import React, { FC, useEffect, useState } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthRole } from '../../../../authorization';
import { HeaderColors } from '../../../../configs';
import { useUserDataSelector } from '../../../../hooks';
import { ActionButton, BoxHeader } from '../../../../ui-components';

import { SelectOption } from '../../../tasks/create-task/common';
import {
  SupervisorHint,
  useMembersData,
} from '../../../tasks/create-task/Supervisors';

import TitleHeader from '../../../CardEditor/TitleHeader';
import { CustomSelectComponent } from '../../../CustomDropdowns';
import { AccessRuleSection } from '../../schedule';

import { ATFormInput } from './create-audit-task.types';

interface IProps {
  onPublishClick: (values: ATFormInput) => Promise<void>;
}

const TaskSupervisors: FC<IProps> = ({ onPublishClick }) => {
  const { t } = useTranslation(['common', 'task', 'audit']);
  const { control, handleSubmit } = useFormContext<ATFormInput>();

  const entityId = useUserDataSelector((state) => state.entityId);

  const [members, setMembers] = useState<SelectOption[]>([]);

  const { getUsersData, loading } = useMembersData(setMembers);

  useEffect(() => {
    getUsersData({
      variables: {
        entityId: entityId,
        status: ['active', 'inactive'],
        type: ['user'],
        authRole: [AuthRole.SUPER_ADMIN, AuthRole.ADMIN],
      },
    });
  }, [entityId]);

  return (
    <Flex flexDir='column' gap={5}>
      <BoxHeader
        color={HeaderColors.Purple}
        title={t('task:whoWillSupervise')}
        fontSize='18px'
      />

      <AccessRuleSection
        title={t('audit:generalAccess')}
        caption={t('audit:generalAccessTaskDesc')}
      />

      <Box>
        <TitleHeader
          title={t('task:selectSupervisors')}
          desc={t('task:supervisorTips')}
        />

        <Controller
          control={control}
          name='supervisors'
          // rules={{
          //   required: t('task:validation.supervisorRequired'),
          // }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={2} isInvalid={!!fieldState?.error}>
                <CustomSelectComponent
                  loading={loading}
                  options={members}
                  value={field.value}
                  placeholder='Select supervisor/s'
                  showHeader
                  headerTitle='Select supervisor/s'
                  headerBarColor={HeaderColors.Purple}
                  showHeaderCloseIcon
                  showSearchField
                  showDivider
                  bottomOutsideBtn={{
                    show: true,
                    title: 'Add selected supervisors',
                  }}
                  showFilter
                  showSelectAll
                  authRoleFilterOptions={[
                    {
                      label: 'Super Admins',
                      value: AuthRole.SUPER_ADMIN,
                    },
                    {
                      label: 'Admins',
                      value: AuthRole.ADMIN,
                    },
                  ]}
                  onChange={field.onChange}
                />

                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
                {/*{supervisors && supervisors?.length > 0 && (*/}
                {/*  <InfoText icon={faUserTie as IconDefinition}>*/}
                {/*    <Trans t={t} i18nKey='task:superAdminSupervisor' />*/}
                {/*  </InfoText>*/}
                {/*)}*/}
                <SupervisorHint members={members} />
              </FormControl>
            );
          }}
        />
      </Box>

      <Flex justify='flex-end' mt='16px'>
        <ActionButton
          size='lg'
          colorScheme='blue'
          // disabled={disabled}
          actionFn={handleSubmit(onPublishClick)}
        >
          {t('common:create')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default TaskSupervisors;
