import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

// import scss
import Header from '../Header';
import Sidebar from '../Sidebar';

import './DashboardContainer.scss';
import GenericModal from 'ui-components/GenericModal';
import DashboardDataContext from './DashboardStore/DashboardData/dashboard-data-context';
import { useReactiveVar } from '@apollo/client/react/hooks/useReactiveVar';
import { userObj } from 'sop-commons/src/client/clientFactory';
import amplitude from 'amplitude-js';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { ILanguageSetting } from 'ui-components/LanguageSetting/language-setting.types';
import { GET_SUPPORTED_LOCALES } from 'ui-components/LanguageSetting/language-setting.graphql';
import { Box } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

interface IProps {
  refetchUsers?: () => void;
  refetchLocations?: () => void;
  className?: string;
}

const DashboardContainer: FC<IProps> = ({
  refetchUsers,
  children,
  refetchLocations,
  className = '',
}: PropsWithChildren<IProps>) => {
  const location = useLocation();
  const userObject = useReactiveVar(userObj);
  const analyticsEnabled = useUserDataSelector(
    (state) => state?.entity?.analytics
  );
  const dashboardCtx = useContext(DashboardDataContext);

  const [isAmplitudeInitialized, setIsAmplitudeInitialized] = useState(false);
  const [isMixpanelInitialized, setIsMixpanelInitialized] = useState(false);

  const amplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY;
  const mixpanelKey = process.env.REACT_APP_MIXPANEL_KEY;

  useEffect(() => {
    Sentry.setUser({
      email: userObject?.email,
      fullName: userObject?.name,
      username: userObject?.name,
      id: userObject?.eid,
    });
  }, [userObject]);

  const chekBotFlag = () => {
    if (
      location?.pathname === '/create-sop' ||
      location?.pathname === '/search-sop'
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      process?.env?.REACT_APP_DEPLOYMENT_TYPE &&
      process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'production' &&
      analyticsEnabled &&
      mixpanelKey &&
      amplitudeKey &&
      !chekBotFlag()
    ) {
      if (userObject?.eid) {
        // Amplitude initialization
        amplitude.getInstance().init(amplitudeKey, userObject?.eid);
        setIsAmplitudeInitialized(true);
        sessionStorage.setItem('amplitudeInit', '1');

        // Mix Panel Initialization
        mixpanel.init(mixpanelKey, {
          debug: false,
          track_pageview: true,
          persistence: 'localStorage',
        });
        mixpanel.identify(userObject?.eid);
        setIsMixpanelInitialized(true);
        sessionStorage.setItem('mixPanelInit', '1');
      } else {
        amplitude.getInstance().init(amplitudeKey);
        setIsAmplitudeInitialized(true);
        sessionStorage.setItem('amplitudeInit', '1');
        console.warn(
          '[Mixpanel] User ID is missing. Mixpanel will not be initialized.'
        );
      }
      const identify = new amplitude.Identify();
      let properties = {
        name: userObject?.name,
        email: userObject?.email,
        role: userObject?.role,
        authRole: userObject?.authRole,
        companyName: userObject?.entity?.name,
        isTestUser: !analyticsEnabled,
        userType: userObject?.type,
        locations:
          userObject?.locations?.map((loc) => {
            return { eid: loc?.eid, name: loc?.name };
          }) || [],
        ...(userObject?.entity?.isDemo ? { AUTODEMO: true } : {}),
      };
      Object.entries(properties).forEach(([key, value]) => {
        if (value !== null) {
          identify.set(key, value);
        }
      });
      mixpanel?.people?.set({
        $name: userObject?.name,
        $email: userObject?.email,
        $role: userObject?.role,
        $authRole: userObject?.authRole,
        $companyName: userObject?.entity?.name,
        $isTestUser: !analyticsEnabled,
        $userType: userObject?.type,
        $locations:
          userObject?.locations?.map((loc) => {
            return { eid: loc?.eid, name: loc?.name };
          }) || [],
        ...(userObject?.entity?.isDemo ? { $AUTODEMO: true } : {}),
      });
      amplitude.getInstance().identify(identify);
    } else {
      if (isMixpanelInitialized) {
        mixpanel.reset();
      }
      setIsMixpanelInitialized(false);
      setIsAmplitudeInitialized(false);
      sessionStorage.setItem('amplitudeInit', '0');
      sessionStorage.setItem('mixPanelInit', '0');
    }

    getLanguagesHandler();
  }, [userObject, amplitudeKey, mixpanelKey]);

  const getLanguagesHandler = () => {
    getLocales();
  };

  const [getLocales, { loading }] = useLazyQuery<ILanguageSetting>(
    GET_SUPPORTED_LOCALES,
    {
      // fetchPolicy: 'network-only',
      onCompleted: (data) => {
        dashboardCtx?.preferredLanguagesHandler(data?.SupportedLocales);
      },
    }
  );

  useEffect(() => {
    if (
      process?.env?.REACT_APP_DEPLOYMENT_TYPE &&
      process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'production' &&
      isAmplitudeInitialized &&
      isMixpanelInitialized &&
      !chekBotFlag()
    ) {
      if (isAmplitudeInitialized) {
        amplitude
          .getInstance()
          .logEvent('Route Changed', { url: location?.pathname });
      }
      if (isMixpanelInitialized) {
        mixpanel.track('Route Changed', { url: location?.pathname });
      }
    }
  }, [location, isAmplitudeInitialized, isMixpanelInitialized]);

  return (
    <>
      <div className={'dashboard-container ' + className}>
        <Sidebar />

        <div className='desktop-content-with-header-container'>
          {!chekBotFlag() ? (
            <Header
              refetchUsers={refetchUsers}
              refetchLocations={refetchLocations}
            />
          ) : (
            <Box width='100%' height='96px' bg='#fcfcfc' display='flex' />
          )}
          <div id='dashboard-content' className='desktop-content-container'>
            {children}
          </div>
        </div>
      </div>
      {dashboardCtx.showErrorModal && !chekBotFlag() && (
        <GenericModal
          isOpen={dashboardCtx.showErrorModal}
          onClose={dashboardCtx.hideErrorModalHandler}
          title={dashboardCtx.modalTitle}
          closeIcon={dashboardCtx.CloseIcon}
          lineColor={dashboardCtx.lineColor}
          isCentered={true}
          buttonsObject={[
            {
              mt: 6,
              type: 'button',
              size: 'lg',
              isLoading: false,
              disabled: false,
              title: 'OK',
              leftIcon: null,
              rightIcon: null,
              onClick: dashboardCtx.hideErrorModalHandler,
              style: { width: '160px' },
              variant: 'solid',
              colorScheme: 'blue',
              className: null,
              value: null,
            },
          ]}
          buttonsPosition='right'
          paddingLeft={30}
          paddingRight={30}
          paddingTop={30}
          paddingBottom={20}
          modalSizes={{
            sm: '600px',
            md: '600px',
            base: '600px',
            xl: '600px',
            ['2xl']: '600px',
          }}
          modalMinSizes={{
            sm: '600px',
            md: '600px',
            base: '600px',
            xl: '600px',
            ['2xl']: '600px',
          }}
        >
          <div
            style={{
              marginTop: '13px',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '1.63',
              letterSpacing: '-0.16px',
              marginLeft: '20px',
              width: '93%',
            }}
          >
            {dashboardCtx.errorMessage}
            {/* Looks like you don&apos;t have visibility access to this deck / card. Please contact your manager to get access! */}
          </div>
        </GenericModal>
      )}
    </>
  );
};

export default DashboardContainer;
