// useResponse.ts
import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import {
  AuditQuestionEntity,
  AuditResponseEntity,
  QuestionType,
  AuditEvidenceEntity,
} from 'types';
import { toArray } from 'utils';

interface FormattedResponseBase {
  questionId: string;
  questionType: QuestionType;
  userId: string;
  comment?: string;
  images?: AuditEvidenceEntity[];
  respondedAt: string;
  isCorrect: boolean;
  isFlagged: boolean;
  noteText?: string;
  score: number;
  userName?: string;
}

interface FormattedResponseText extends FormattedResponseBase {
  response: string;
}

interface FormattedResponseOptions extends FormattedResponseBase {
  response: { eid: string; label: string }[];
}

interface FormattedResponseUploads extends FormattedResponseBase {
  response: string[];
}

type FormattedResponse =
  | FormattedResponseText
  | FormattedResponseOptions
  | FormattedResponseUploads;

export const useResponse = (
  rawResponse: AuditResponseEntity | AuditResponseEntity[] | undefined,
  rawQuestion: AuditQuestionEntity
): FormattedResponse[] | undefined => {
  const userEntityData = useReactiveVar(usersEntityObj) || [];

  const formattedResponses = useMemo<FormattedResponse[] | undefined>(() => {
    if (!rawResponse) {
      return undefined;
    }
    const responsesArray = toArray(rawResponse);

    const filteredResponses = responsesArray.filter(
      (resp) => resp.qid === rawQuestion.eid
    );

    if (filteredResponses.length === 0) {
      return undefined;
    }

    const mapOptions = (ids: string[]): { eid: string; label: string }[] => {
      if (!rawQuestion?.options || !Array.isArray(rawQuestion.options)) {
        return [];
      }

      return ids
        .map((id) => {
          const option = rawQuestion.options.find((opt) => opt.eid === id);
          return option ? { eid: option.eid, label: option.label } : undefined;
        })
        .filter(
          (option): option is { eid: string; label: string } =>
            option !== undefined
        );
    };

    const mapLocations = (ids: string[]): { eid: string; label: string }[] => {
      if (!Array.isArray(userEntityData)) {
        return [];
      }

      const locationsMap: { [key: string]: string } = {};
      userEntityData.forEach((user) => {
        if (user.eid && user.name && user?.type === 'branch') {
          locationsMap[user.eid] = user.name;
        }
      });

      return ids
        .map((id) => ({
          eid: id,
          label: locationsMap[id] || undefined,
        }))
        .filter(
          (loc): loc is { eid: string; label: string } =>
            loc.label !== undefined
        );
    };

    const mapMembers = (
      ids: string[]
    ): { eid: string; label: string; profilePic?: string }[] => {
      if (!Array.isArray(userEntityData)) {
        return [];
      }

      return ids
        .map((id) => {
          const member = userEntityData.find((user) => user.eid === id);
          return member && member.name
            ? {
                eid: member.eid,
                label: member.name,
                profilePic: member?.profilePic,
              }
            : undefined;
        })
        .filter(
          (
            member
          ): member is { eid: string; label: string; profilePic: string } =>
            member !== undefined
        );
    };

    const processSingleResponse = (
      responseEntity: AuditResponseEntity
    ): FormattedResponse | null => {
      try {
        const {
          qid = '',
          userId = '',
          respondedAt = '',
          isCorrect = false,
          isFlagged = false,
          score = 0,
          response = '',
          responseId = [],
          responseUrl = [],
          comment,
          images,
          noteText,
        } = responseEntity;

        let isResponseFlagged = isFlagged;

        if (responseId.length > 0 && rawQuestion.options) {
          const selectedOptions = rawQuestion.options.filter((option) =>
            responseId.includes(option.eid)
          );
          if (selectedOptions.some((option) => option.isFlagged)) {
            isResponseFlagged = true;
          }
        }

        const baseResponse: FormattedResponseBase = {
          questionId: qid,
          questionType: rawQuestion?.qType || QuestionType.SHORT_TEXT,
          userId,
          respondedAt,
          isCorrect,
          isFlagged: isResponseFlagged,
          score,
          noteText,
        };

        const currentUser = userEntityData.find((user) => user.eid === userId);
        if (currentUser && currentUser.name) {
          baseResponse.userName = currentUser.name;
        }

        let formatted: FormattedResponse | null = null;

        switch (rawQuestion?.qType) {
          case QuestionType.SHORT_TEXT:
          case QuestionType.LONG_TEXT: {
            formatted = {
              ...baseResponse,
              response: typeof response === 'string' ? response : '',
              ...(noteText ? { noteText } : {}),
              ...(images && images.length > 0 ? { images } : {}),
            } as FormattedResponseText;
            break;
          }

          case QuestionType.MULTI_CHOICE:
          case QuestionType.CHECKBOX:
          case QuestionType.DROPDOWN: {
            const mappedOptions = Array.isArray(responseId)
              ? mapOptions(responseId)
              : [];
            formatted = {
              ...baseResponse,
              response: mappedOptions,
              ...(noteText ? { noteText } : {}),
              ...(images && images.length > 0 ? { images } : {}),
            } as FormattedResponseOptions;
            break;
          }

          case QuestionType.LOCATION: {
            const mappedLocations = Array.isArray(responseId)
              ? mapLocations(responseId)
              : [];
            formatted = {
              ...baseResponse,
              response: mappedLocations,
              ...(noteText ? { noteText } : {}),
              ...(images && images.length > 0 ? { images } : {}),
            } as FormattedResponseOptions;
            break;
          }

          case QuestionType.MEMBER: {
            const mappedMembers = Array.isArray(responseId)
              ? mapMembers(responseId)
              : [];
            formatted = {
              ...baseResponse,
              response: mappedMembers,
              ...(noteText ? { noteText } : {}),
              ...(images && images.length > 0 ? { images } : {}),
            } as FormattedResponseOptions;
            break;
          }

          case QuestionType.IMAGE_UPLOAD:
          case QuestionType.VIDEO_UPLOAD:
          case QuestionType.FILE_UPLOAD: {
            const uploads = Array.isArray(responseUrl) ? responseUrl : [];
            formatted = {
              ...baseResponse,
              response: uploads,
              ...(noteText ? { noteText } : {}),
              ...(images && images.length > 0 ? { images } : {}),
            } as FormattedResponseUploads;
            break;
          }

          default: {
            formatted = {
              ...baseResponse,
              response: typeof response === 'string' ? response : '',
              ...(noteText ? { noteText } : {}),
              ...(images && images.length > 0 ? { images } : {}),
            } as FormattedResponseText;
            break;
          }
        }

        return formatted;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error formatting response:', error);
        return null;
      }
    };

    const formattedArray: FormattedResponse[] = filteredResponses
      .map(processSingleResponse)
      .filter((resp): resp is FormattedResponse => resp !== null);

    return formattedArray.length > 0 ? formattedArray : undefined;
  }, [rawResponse, rawQuestion, userEntityData]);

  return formattedResponses;
};
