import { useCallback } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'ui-components';
import { AuditEvidenceEntity } from '../../../../../../types';
import React from 'react';
import ModalContent from './ModalContent';

interface IProps {
  images: AuditEvidenceEntity[];
}

type EvidenceImages = (props: IProps) => void;

export const useEvidenceImages = (): EvidenceImages => {
  const confirm = useConfirm();

  return useCallback(
    ({ images }) => {
      confirm({
        title: (
          <Flex gap='12px' justify='flex-end'>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: <ModalContent images={images} />,
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '24px',
          minW: '1000px',
        },
        okText: null,
        cancelText: null,
      });
    },
    [confirm]
  );
};
