import { Box, Flex, Image } from '@chakra-ui/react';
import { ReactComponent as StepOne } from '../../../../assets/images/prepare-for-audit/step-one.svg';
import { ReactComponent as StepTwo } from '../../../../assets/images/prepare-for-audit/step-two.svg';
import { ReactComponent as StepThree } from '../../../../assets/images/prepare-for-audit/step-three.svg';
import { ReactComponent as StepFour } from '../../../../assets/images/prepare-for-audit/step-four.svg';
import React, { FC } from 'react';

const stepsArr = [
  {
    stepIcon: <StepOne />,
    stepLabel: 'STEP 01',
    stepHeading: 'Organize Documentation',
    stepHeadingWidth: '180px',
    stepDesc: 'Gather all necessary files and keep them easily accessible.',
  },
  {
    stepIcon: <StepTwo />,
    stepLabel: 'STEP 02',
    stepHeading: 'Conduct Pre-Audit Inspections',
    stepDesc: 'Gather all necessary files and keep them easily accessible.',
  },
  {
    stepIcon: <StepThree />,
    stepLabel: 'STEP 03',
    stepHeading: 'Train your staff on behaviour and procedures',
    stepDesc: 'Gather all necessary files and keep them easily accessible.',
  },
  {
    stepIcon: <StepFour />,
    stepLabel: 'STEP 04',
    stepHeading: 'Keep a cool head and make note of the feedback',
    stepDesc: 'Gather all necessary files and keep them easily accessible.',
  },
];

const ModalContent: FC = () => {
  return (
    <Flex flexDir='column' gap={4}>
      <Box
        as='span'
        fontWeight={600}
        fontSize='15px'
        color='rgba(109, 109, 109, 0.7)'
      >
        Here are a few pointers to help ensure a smooth audit
      </Box>
      <Flex justify='space-between'>
        {stepsArr?.map((step) => (
          <Flex
            border='1px solid rgba(239, 239, 239, 1)'
            borderRadius='8px'
            p='16px'
            flexDir='column'
            gap={2}
            h='223px'
            w='230px'
          >
            {step?.stepIcon}
            <Box
              fontWeight={600}
              fontSize='12px'
              color='rgba(111, 118, 126, 0.5)'
            >
              {step?.stepLabel}
            </Box>
            <Box
              fontWeight={600}
              fontSize='15px'
              w={step?.stepHeadingWidth ? step?.stepHeadingWidth : 'full'}
            >
              {step?.stepHeading}
            </Box>
            <Box
              fontWeight={400}
              fontSize='13px'
              color='rgba(111, 118, 126, 1)'
            >
              {step?.stepDesc}
            </Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ModalContent;
