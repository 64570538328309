import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface IProps {
  answer?: string | undefined;
}

const ShortAnswerQuestions: FC<IProps> = ({ answer }) => {
  return (
    <Flex direction={'column'} justifyContent={'center'} gap={2}>
      {answer && (
        <Text
          lineHeight={'24px !important'}
          fontSize={'15px'}
          color={'#808080'}
          fontWeight={500}
        >
          {answer}
        </Text>
      )}
    </Flex>
  );
};

export default ShortAnswerQuestions;
