import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EmptyState } from 'sub-components';

const LocationEmptyState: FC = () => {
  const { t } = useTranslation(['common', 'location', 'setting']);
  return (
    <EmptyState
      image='Location'
      title={t('location:noLocationPresent')}
      customDescription={
        <Box textAlign='center' w='470px'>
          <Trans
            t={t}
            i18nKey='location:noLocationPresentHelp'
            components={[
              <Box
                key='noLocationPresentTypeDefault'
                fontWeight={500}
                fontSize='11px'
                as='span'
                color='rgba(158, 158, 158, 1)'
              />,
              <Box
                key='noLocationPresentType'
                as='span'
                fontWeight={500}
                fontSize='11px'
                color='black'
              />,
            ]}
          />
        </Box>
      }
    />
  );
};

LocationEmptyState.displayName = 'pages/Teams/locations/LocationEmptyState';

export default LocationEmptyState;
