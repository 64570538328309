import { FlexProps } from '@chakra-ui/react';

export const headerConfig: FlexProps = {
  pos: 'sticky',
  zIndex: 2,
  top: {
    base: '-14px',
    md: '-19px',
    xl: '-17px',
  },
  marginX: {
    base: '-19px',
    md: '-21px',
    xl: '-38px',
  },
  paddingX: {
    base: '19px',
    md: '21px',
    xl: '38px',
  },
  paddingY: 4,
  mt: {
    base: '-14px',
    md: '-19px',
    xl: '-17px',
  },
  bg: 'white',
  shadow: 'sm',
};
