import { Divider, Flex, useToast, VStack } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import TaskDrawerUpload from '../TaskDrawerUpload';
import ButtonFooter from './ButtonFooter';
import DateDetails from './DateDetails';
import StepsList from './StepsList';
import TaskDescription from './TaskDescription';
import TaskDurationData from './TaskDurationData';
import { FileWithEid } from 'hooks/useUploadMultipleFilesWithCancel';
import { getFileType } from 'utils/utils';
import { useUserDataSelector } from 'hooks';
import { v4 as uuidv4 } from 'uuid';
import { eventBus } from 'shared/eventEmit';
import {
  COMPLETE_LAUNCHER_TASK,
  MARK_LAUNCHER_TASK_UNDONE,
} from '../task-detail-drawer.graphql';
import {
  CompleteLauncherTaskResponse,
  CompleteLauncherTaskVariables,
  FileInputEntity,
  IFilesProgressData,
  IRefetchType,
  ISelectedStep,
  MarkTaskUndoneResponse,
  MarkTaskUndoneVariables,
} from '../task-detail-drawer.types';
import useCombinedStore from 'zustandStore/store';
import { AuthRole } from 'sop-commons/src/client';
import { AmplitudeEvent, deployEvent } from '../../../../../../shared';

interface SubmitModeProps {
  onClose: () => void;
  refetchHandler: (type: IRefetchType) => void;
  selectedTaskData: CustomLauncherTask;
}

const SubmitMode: FC<SubmitModeProps> = ({
  onClose,
  refetchHandler,
  selectedTaskData,
}) => {
  const {
    taskDetails: {
      updateIsLoading,
      updateIsUploading,
      updateSelectedFiles,
      updateSelectedStep,
    },
  } = useCombinedStore();
  const loggedInUserDataEid = useUserDataSelector((state) => state.eid);
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const [filesProgressData, setFilesProgressData] = useState<
    IFilesProgressData[]
  >([]);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [markAsUndone, { loading }] = useMutation<
    MarkTaskUndoneResponse,
    MarkTaskUndoneVariables
  >(MARK_LAUNCHER_TASK_UNDONE);

  useEffect(() => {
    const stepRefetchCompleteHandler = () => {
      updateSelectedStep({ loading: false, index: -1 });
      updateIsLoading(false);
      toast({
        status: 'success',
        title: 'Step updated successfully',
      });
    };
    const stepRefetchErrorHandler = () => {
      updateSelectedStep({ loading: false, index: -1 });
      updateIsLoading(false);
      toast({
        status: 'error',
        title: 'Something went wrong',
      });
    };
    eventBus.on('stepRefetchComplete', stepRefetchCompleteHandler);
    eventBus.on('stepRefetchError', stepRefetchErrorHandler);
    return () => {
      eventBus.off('stepRefetchComplete', stepRefetchCompleteHandler);
      eventBus.off('stepRefetchError', stepRefetchErrorHandler);
    };
  }, []);

  useEffect(() => {
    if (selectedTaskData?.files?.length > 0) {
      let _files: FileWithEid[] = [];
      let _filesProgressData: IFilesProgressData[] = [];
      selectedTaskData?.files?.forEach((file) => {
        let fileEid = uuidv4();
        _files?.push({
          eid: fileEid,
          file: {
            name: file?.name,
            size: file?.fileSize,
            type: file?.mimetype,
          } as File,
          url: file?.url,
        });
        _filesProgressData?.push({
          fileEid: fileEid,
          progressNum: 100,
          uploadStatus: 'uploaded',
        });
      });
      console.log('selected files : ', _files);
      updateSelectedFiles(_files);
      setFilesProgressData(_filesProgressData);
    }
  }, [selectedTaskData]);

  const [markAsComplete] = useMutation<
    CompleteLauncherTaskResponse,
    CompleteLauncherTaskVariables
  >(COMPLETE_LAUNCHER_TASK);

  useEffect(() => {
    const markAsCompleteHandler = async () => {
      updateIsLoading(true);
      try {
        let res = await markAsComplete({
          variables: {
            input: {
              launchId: selectedTaskData?.launchId,
              taskId: selectedTaskData?.eid,
            },
          },
        });

        if (res?.data?.CompleteLauncherTask?.succeed) {
          deployEvent(AmplitudeEvent.LAUNCHER_MARK_TASK_COMPLETE, {
            location_id: selectedTaskData?.locationId,
            task_name: selectedTaskData?.title,
            task_type: selectedTaskData?.categoryName,
            schedule_start: moment(selectedTaskData?.startDate).format(
              'M/DD/YYYY'
            ),
            schedule_completion: moment().format('M/DD/YYYY'),
            days_left: moment(selectedTaskData?.dueDate).diff(moment(), 'days'),
            task_id: selectedTaskData?.eid,
          });
          toast({
            status: 'success',
            title: 'Task completed successfully',
          });
          refetchHandler('task');
          onClose();
        }
      } catch (err) {
        toast({
          status: 'error',
          title: 'Task could not be updated',
        });
      } finally {
        updateSelectedStep({ loading: false, index: -1 });
        updateIsLoading(false);
      }
    };

    // Properly subscribe to the event
    eventBus.on('markTaskAsComplete', markAsCompleteHandler);

    // Return a cleanup function to unsubscribe from the event
    return () => {
      eventBus.off('markTaskAsComplete', markAsCompleteHandler);
    };
  }, [
    markAsComplete,
    selectedTaskData,
    toast,
    updateIsLoading,
    updateSelectedStep,
    refetchHandler,
    onClose,
  ]);

  /** Mark Step as Complete */
  const markStepAsCompleteHandler = async (
    stepId: string,
    formResponseId?: string
  ) => {
    try {
      let res = await markAsComplete({
        variables: {
          input: {
            launchId: selectedTaskData?.launchId,
            taskId: selectedTaskData?.eid,
            stepId: stepId,
            formResponseId: formResponseId,
          },
        },
      });
      if (res?.data?.CompleteLauncherTask?.succeed) {
        refetchHandler('step');
      }
    } catch (err) {
      toast({
        status: 'error',
        title: 'Step could not be completed',
      });
      updateSelectedStep({ loading: false, index: -1 });
      updateIsLoading(false);
    }
  };

  const markStepAsIncompleteHandler = async (stepId: string) => {
    try {
      let res = await markAsUndone({
        variables: {
          launchId: selectedTaskData?.launchId,
          taskId: selectedTaskData?.eid,
          stepId: stepId,
        },
      });
      if (res?.data?.MarkLauncherTaskUndone?.succeed) {
        refetchHandler('step');
      }
    } catch (err) {
      refetchHandler('step');
    }
  };

  const compRender = () => {
    if (loggedInUserAuthRole === AuthRole.LOCATION_OWNER) {
      return (
        <VStack gap='12px'>
          <TaskDurationData selectedTaskData={selectedTaskData} />
          <TaskDescription selectedTaskData={selectedTaskData} />
          <StepsList
            mode='submit'
            selectedTaskData={selectedTaskData}
            markStepAsCompleteHandler={markStepAsCompleteHandler}
            markStepAsIncompleteHandler={(stepId: string) =>
              markStepAsIncompleteHandler(stepId)
            }
          />
          <DateDetails selectedTaskData={selectedTaskData} />
          <TaskDrawerUpload
            mode='submit'
            selectedTaskData={selectedTaskData}
            filesProgressData={filesProgressData}
            setFilesProgressData={setFilesProgressData}
            refetchHandler={refetchHandler}
          />
          <ButtonFooter selectedTaskData={selectedTaskData} />
        </VStack>
      );
    } else {
      return (
        <Flex w='full' gap='20px'>
          <Flex flexDir='column' w='60%' gap={4}>
            <TaskDurationData selectedTaskData={selectedTaskData} />
            <TaskDescription selectedTaskData={selectedTaskData} />
            <StepsList
              mode='submit'
              selectedTaskData={selectedTaskData}
              markStepAsCompleteHandler={markStepAsCompleteHandler}
              markStepAsIncompleteHandler={(stepId: string) =>
                markStepAsIncompleteHandler(stepId)
              }
            />
          </Flex>
          <Flex>
            <Divider orientation='vertical' />
          </Flex>
          <Flex flexDir='column' w='40%' gap={4}>
            <DateDetails selectedTaskData={selectedTaskData} />
            <TaskDrawerUpload
              mode='submit'
              selectedTaskData={selectedTaskData}
              filesProgressData={filesProgressData}
              setFilesProgressData={setFilesProgressData}
              refetchHandler={refetchHandler}
            />
          </Flex>
        </Flex>
      );
    }
  };
  return compRender();
};

export default SubmitMode;
