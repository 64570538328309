import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Flex, Switch } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AuditHelper } from 'configs';
import { IFormInput } from '../../form-types';
import BaseAuditQuesFooter, { notLastStyle } from './BaseAuditQuesFooter';
import { IFooterProps } from './audit-options';

interface IProps extends IFooterProps {}

const AuditQuestionFooter: FC<IProps> = ({
  questionIndex,
  handleRemove,
  handleDuplicate,
  isReadOnly,
}) => {
  const { t } = useTranslation(['common', 'form']);

  return (
    <BaseAuditQuesFooter
      handleRemove={handleRemove}
      handleDuplicate={handleDuplicate}
      questionIndex={questionIndex}
      isReadOnly={isReadOnly}
    >
      <Controller<IFormInput, `questions.${number}.isRequired`>
        name={`questions.${questionIndex}.isRequired`}
        render={({ field }) => {
          return (
            <Flex align='center' _notLast={notLastStyle}>
              <Box mr='8px' color='#33383f' fontWeight='600'>
                {t('required')}
              </Box>
              <Switch
                size='md'
                isChecked={field.value}
                {...field}
                value={undefined}
                onBlur={AuditHelper.save(field.onBlur)}
                // isReadOnly={isReadOnly}
              />
            </Flex>
          );
        }}
      />
    </BaseAuditQuesFooter>
  );
};

export default AuditQuestionFooter;
