import { cloneDeep } from '@apollo/client/utilities';
import { toArray, toNumber } from '../../../utils';
import { QuestionType } from '../../../types';
import { IFormInput, OptionWhiteList } from './form-types';
import { AuditInput, AuditOptionsEntity } from './audit.types';

const parseEid = (eid?: string) => (eid?.includes('-') ? undefined : eid);

interface OptionsMap {
  options: AuditOptionsEntity[];
  requiresEvidence?: boolean;
}

/**
 * **option?.isDisabled** attribute is used to persist deleted option state.
 *
 * which is `score, isFlagged, requiresEvidence`
 */
export const optionHandler = (
  options?: AuditOptionsEntity[],
  hasScoring?: boolean,
  hasFlagged?: boolean,
  requiresEvidence?: boolean
): OptionsMap => {
  return toArray(options).reduce<OptionsMap>(
    (acc, option) => {
      const isFlagged =
        hasFlagged || option?.isDisabled ? option.isFlagged : undefined;
      const evidence =
        requiresEvidence || option?.isDisabled
          ? option.requiresEvidence
          : undefined;

      acc.options.push({
        eid: parseEid(option.eid),
        label: option.label,
        score: hasScoring || option?.isDisabled ? toNumber(option.score, 0) : 0,
        isFlagged: isFlagged,
        isDisabled: !!option.isDisabled,
        requiresEvidence: evidence || isFlagged,
      });

      acc.requiresEvidence = evidence || isFlagged || acc.requiresEvidence;

      return acc;
    },
    {
      options: [] as AuditOptionsEntity[],
      requiresEvidence: requiresEvidence,
    }
  );
};

export const calculateScore = (
  options: AuditOptionsEntity[] = [],
  qType: QuestionType
): number => {
  return options.reduce((acc, opt) => {
    if (opt?.isDisabled) {
      return acc;
    }
    const score = toNumber(opt.score, 0);
    if (qType === QuestionType.CHECKBOX) {
      return acc + score;
    }

    return Math.max(acc, score);
  }, 0);
};

export const createAuditData = (_values: IFormInput): AuditInput => {
  const values = cloneDeep(_values);

  return {
    eid: values.eid,
    title: values.title,
    status: 'active',
    description: values.description,
    categoryId: values.category?.eid!,
    questions: toArray(values.questions).map((_question) => {
      const question: AuditInput['questions'][number] = {
        eid: parseEid(_question.eid),
        label: _question.label,
        qType: _question.qType,
        isRequired: !!_question.isRequired,
        isDisabled: !!_question.isDisabled,
        hasScoring: false,
        hasFlaggedResponse: false,
        requiresEvidence: false,
        options: [],
        score: 0,
      };

      if (OptionWhiteList.includes(_question.qType)) {
        const optionMap = optionHandler(
          _question.options,
          _question.hasScoring,
          _question.hasFlaggedResponse,
          _question.requiresEvidence
        );
        question.hasScoring = _question.hasScoring;
        question.hasFlaggedResponse = _question.hasFlaggedResponse;
        question.requiresEvidence = optionMap.requiresEvidence;
        question.options = optionMap.options;
      }

      question.score = calculateScore(question.options!, _question.qType);

      return question;
    }),
  };
};
