import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../atoms';
import { ActionButton } from '../../../../ui-components';

import { headerConfig } from '../../common';

// @ts-ignore
import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';
// @ts-ignore
import { ReactComponent as TrashIcon } from '../../../../assets/images/trash-red.svg';
import PauseResumeAction from './PauseResumeAction';

interface IProps {
  onUpdate?: () => void;
  actionHandler?: (action: 'delete' | 'resume' | 'pause') => void;
}

const ScheduleEditorHeader: FC<IProps> = ({ onUpdate, actionHandler }) => {
  const { t } = useTranslation(['common']);

  const { isDirty, isLoading } = useFormState();

  const history = useHistory();

  return (
    <Flex flexDir='column' gap={2} {...headerConfig}>
      <Button
        fontSize='14px'
        fontWeight={500}
        width='max-content'
        variant='link'
        leftIcon={<BackIcon width='16' height='16' />}
        _hover={{
          textDecoration: 'none',
        }}
        onClick={history.goBack}
      >
        Back
      </Button>
      <Flex align='center' justify='space-between'>
        <Text fontWeight={600} fontSize='28px'>
          Audit Settings
        </Text>
        <Flex gap={3}>
          <PauseResumeAction
            onResumeClick={() => actionHandler?.('resume')}
            onPauseClick={() => actionHandler?.('pause')}
          />
          <ActionButton
            variant='ghost'
            colorScheme='red'
            actionFn={() => actionHandler?.('delete')}
            color='#FF6A55'
            leftIcon={<TrashIcon width='20' height='20' />}
            isDisabled={isLoading}
          >
            {t('delete')}
          </ActionButton>
          <ActionButton
            isDisabled={!isDirty || isLoading}
            variant='solid'
            colorScheme='blue'
            actionFn={onUpdate}
          >
            {t('update')}
          </ActionButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ScheduleEditorHeader;
