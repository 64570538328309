import { useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { IFormInput } from './audit-schedule.types';

const TEMPLATE_DATA = gql`
  query AuditTemplateById($eid: String!) {
    AuditTemplateById(eid: $eid) {
      eid
      title
      status
    }
  }
`;

export const useScheduleDefaultValues = (params?: Record<string, any>) => {
  const client = useApolloClient();

  return useCallback(async (): Promise<IFormInput> => {
    if (params?.templateId) {
      const response = await client.query({
        query: TEMPLATE_DATA,
        fetchPolicy: 'network-only',
        variables: {
          eid: params.templateId,
        },
      });

      const data = response?.data?.AuditTemplateById;

      if (data?.status === 'active') {
        return Promise.resolve({
          template: {
            value: data.eid,
            label: data.title,
          },
          auditors: [
            {
              userId: [] as string[],
              locations: [] as string[],
            },
          ],
          repeatDetails: {},
          scheduleTitle: data.title,
        } as IFormInput);
      }
    }

    return Promise.resolve({
      auditors: [
        {
          userId: [] as string[],
          locations: [] as string[],
        },
      ],
      repeatDetails: {},
      scheduleTitle: '',
    } as IFormInput);
  }, [params]);
};
