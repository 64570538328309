import { AuthRole } from '../authorization';

/**
 * @deprecated
 * @example replace with **AuthRole** enum
 * import { AuthRole } from "authorization"
 */
export const SUPER_ADMIN = AuthRole.SUPER_ADMIN;

/**
 * @deprecated
 * @example replace with **AuthRole** enum
 * import { AuthRole } from "authorization"
 */
export const ADMIN = AuthRole.ADMIN;

/**
 * @deprecated
 * @example replace with **AuthRole** enum
 * import { AuthRole } from "authorization"
 */
export const MANAGER = AuthRole.LOCATION_OWNER;

/**
 * @deprecated
 * @example replace with **AuthRole** enum
 * import { AuthRole } from "authorization"
 */
export const WORKER = AuthRole.WORKER;

/**
 * @deprecated
 * @example replace with **AuthRole** enum
 * import { AuthRole } from "authorization"
 */
export const LOCATIONOWNER = AuthRole.LOCATION_OWNER;
