import React, { FC, useRef, useState } from 'react';
import { Box, Flex, IconButton, Spacer } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import _uniqueId from 'lodash/uniqueId';

import { Button } from '../../../../../atoms';
import { useSafeState, useUploadToAws } from '../../../../../hooks';
import { toArray } from '../../../../../utils';

import { AuditTake } from '../../tabke-audit.helpers';

import { useFileName } from '../../../../forms/from-preview/component/file-name';
import { ReactComponent as VideoIcon } from '../../../../../assets/images/videoPreview.svg';

interface IProps {
  onChange: (value: string[]) => void;
  value: string[];
}

const VideoUpload: FC<IProps> = ({ value, onChange }) => {
  const [inputId] = useState(_uniqueId('video-upload-'));
  const cancelRef = useRef<AbortController>();

  const [isUploading, setIsUploading] = useSafeState(false);
  const [_fileName, setFileName] = useSafeState<string>();

  const uploadToAws = useUploadToAws();

  // @ts-ignore
  const fileName = useFileName(_fileName || value);

  const _onChange = async (fileList: FileList) => {
    if (fileList && fileList?.length) {
      cancelRef.current = new AbortController();

      setFileName(fileList[0].name);
      setIsUploading(true);

      AuditTake.updateProgress(inputId, true);

      try {
        const fileUrl = await uploadToAws(
          fileList[0],
          undefined,
          cancelRef.current
        );

        onChange(toArray(fileUrl));

        setFileName(fileUrl);
        setIsUploading(false);
      } catch (e) {
        setFileName(undefined);
        setIsUploading(false);
      } finally {
        AuditTake.updateProgress(inputId, false);
      }
    }
  };

  if (fileName) {
    return (
      <Flex
        align='center'
        height='40px'
        bg='#DCF5FF'
        px={3}
        borderRadius='8px'
        gap={2}
      >
        <VideoIcon width='20' height='20' color='#33383F' />
        <Box
          as='span'
          color='#272B30'
          fontWeight='500'
          textDecoration='underline'
          overflow='hidden'
        >
          <Flex>
            <span
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {fileName.slice(0, -20)}
            </span>
            <Box as='span' whiteSpace='nowrap'>
              {fileName.slice(-20)}
            </Box>
          </Flex>
        </Box>
        <Spacer />
        <IconButton
          ml={1}
          size='xs'
          variant='ghost'
          aria-label={'remove'}
          icon={<CloseIcon />}
          isLoading={isUploading}
          onClick={() => onChange?.([])}
        />
        {isUploading && (
          <IconButton
            ml={1}
            size='xs'
            variant='ghost'
            aria-label={'remove'}
            icon={<CloseIcon />}
            onClick={() => cancelRef.current?.abort()}
          />
        )}
      </Flex>
    );
  }

  return (
    <div>
      <label htmlFor={inputId}>
        <Button
          width='full'
          as='div'
          borderRadius='12px'
          h='48px'
          leftIcon={<VideoIcon width='20' height='20' color='#33383F' />}
          fontWeight='400'
          cursor='pointer'
        >
          Upload video
        </Button>
      </label>

      <input
        type='file'
        onChange={(event) => _onChange(event.target.files!)}
        id={inputId}
        style={{ display: 'none' }}
        accept={'video/mp4,video/x-m4v,video/*'}
      />
    </div>
  );
};

export default VideoUpload;
