import React, { FC } from 'react';
import { List } from '@chakra-ui/react';
import ShareMemItem from 'ui-components/ShareToMember/ShareMemItem';
import ShareMemberSkeleton from 'ui-components/ShareToMember/ShareMemberSkeleton';
import { ShareMemberEntity } from '../../../../ui-components/ShareToMember';
import NoReportShareMembers from './NoReportShareMembers';

interface IProps {
  search?: string;
  loading?: boolean;
  members: ShareMemberEntity[];
  onSendClick?: (value: ShareMemberEntity) => PromiseLike<void>;
}

const ReportShareMemberList: FC<IProps> = ({
  members,
  loading,
  onSendClick,
  search,
}) => {
  return (
    <ShareMemberSkeleton isLoaded={!loading}>
      {!members.length ? (
        <NoReportShareMembers search={search} />
      ) : (
        <List overflow='auto' spacing={4} pl={0}>
          {members.map((members) => {
            return (
              <ShareMemItem
                key={members.eid}
                data={members}
                onSendClick={onSendClick}
              />
            );
          })}
        </List>
      )}
    </ShareMemberSkeleton>
  );
};

export default ReportShareMemberList;
