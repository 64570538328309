import { gql } from '@apollo/client';
import { Address, FileEntity, LocationStatus, LocationType } from 'types';
import { IEntityTypes } from './static-data';

export interface OtherFieldsEntity {
  fieldId: string;
  value: string;
  options: string[];
  files: FileEntity[];
}

export interface LocationInputs {
  // TODO Basic Details
  locationType: LocationType;
  name: string;
  locationStatus: LocationStatus;

  locationEmail: string[];
  locationPhone: string[];
  locationOwners: string[];

  // TODO Login Details
  username: string;
  password: string;

  // TODO Address
  timezone: string;
  address: Address;

  // TODO Entity details
  entityName: string;
  entityType: IEntityTypes;
  countryOfFormation: string;
  stateOfFormation: string;
  taxPayerId: string;

  // TODO Other details
  otherFields: OtherFieldsEntity[];
}

export interface AddLocationVariable {
  input: LocationInputs;
}

export interface AddLocationResponse {
  addLocation: {
    eid: string;
    name: string;
  };
}
export interface AddPreLaunchLocationResponse {
  AddPrelaunchLocation: {
    name: string;
    eid: string;
  };
}

export const ADD_LOCATION_QUERY = gql`
  mutation AddLocation($input: NewLocationInput) {
    addLocation(input: $input) {
      eid
      name
    }
  }
`;

export const ADD_PRE_LAUNCH_LOCATION_QUERY = gql`
  mutation AddPrelaunchLocation($input: NewPrelaunchLocationInput) {
    AddPrelaunchLocation(input: $input) {
      eid
    }
  }
`;
