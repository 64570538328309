import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Center } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import _uniqueId from 'lodash/uniqueId';
import { v4 as uuidv4 } from 'uuid';
import { useUploadContext } from './EvidenceProvider';

interface IProps {}

const EvidenceUploadInput: FC<IProps> = () => {
  const [inputId] = useState(_uniqueId('addFile-'));
  const inputRef = useRef<HTMLInputElement>(null);

  const addEvidence = useUploadContext((state) => state.addEvidence);

  const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const fileList = event.target.files;

    if (fileList && fileList.length) {
      addEvidence({ eid: uuidv4(), file: fileList.item(0)! }).catch(() => null);
    }

    inputRef.current!.files = null;
    inputRef.current!.value = '';
  };

  return (
    <Center
      as='label'
      boxSize='48px'
      bg='#EFEFEF'
      borderRadius='4px'
      cursor='pointer'
      htmlFor={inputId}
      _hover={{
        boxShadow: 'sm',
      }}
    >
      <AddIcon color='#818181' />

      <input
        ref={inputRef}
        type='file'
        onChange={onImageChange}
        id={inputId}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </Center>
  );
};

export default EvidenceUploadInput;
