import React, { FC, useMemo } from 'react';
import { Steps } from 'antd';
import { Flex } from '@chakra-ui/react';
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { LauncherTask, TaskVariable } from '../../common';

import { useChangePhase, useTaskDelete } from '../change-phase';
import { EditorEvent, useEditorContext } from '../context';
import { IFormInput, ITaskItem } from '../editor.types';
import { selected } from '../helper';

import EditorItemContainer from './EditorItemContainer';
import { VerticalStepIconRender } from './StepIconRender';
import EmptySection from './EmptySection';
import EditorBottomAction from './EditorBottomAction';

interface IProps {
  activeStep: number;
  isLastSection?: boolean;
  categoryId: string;
}

const EditorSection: FC<IProps> = ({
  activeStep,
  isLastSection,
  categoryId,
}) => {
  const { emit } = useEditorContext();

  const { getValues, setValue } = useFormContext<IFormInput>();

  const { fields, insert, update, move, remove } = useFieldArray<
    IFormInput,
    `contents.${number}.tasks`
  >({
    name: `contents.${activeStep}.tasks`,
  });

  const changePhase = useChangePhase();

  const deleteTask = useTaskDelete();

  const editId = useWatch<IFormInput, 'taskEditable'>({
    name: 'taskEditable',
  });

  const actionHandler = (
    action: string,
    taskData: FieldArrayWithId<IFormInput, `contents.${number}.tasks`, 'id'>,
    taskIndex: number
  ) => {
    switch (action) {
      case 'delete':
        return deleteTask(async () => {
          if (!taskData?.isNew) {
            await emit(EditorEvent.DELETE_TASK, {
              taskIndex: taskIndex,
              categoryId: categoryId,
              task: taskData,
            });
          }

          remove(taskIndex);
        });
      case 'change-phase':
        return changePhase({
          initialValue: categoryId,
          onUpdate: async (newVal) => {
            await emit(EditorEvent.CHANGE_TASK_PHASE, {
              categoryId: categoryId,
              newCategoryId: newVal,
              taskIndex: taskIndex,
              task: taskData,
            });

            remove(taskIndex);
          },
        });
    }
  };

  const onTaskMove = async (currentIndex: number, nextIndex: number) => {
    move(currentIndex, nextIndex);
    await emit(EditorEvent.MOVE_TASK, {
      currentIndex,
      nextIndex,
      sectionIndex: activeStep,
    });
  };

  const addNewTask = async <T extends ITaskItem>(newTaskData: T | T[]) => {
    insert(0, newTaskData);
  };

  const onUpdateClick = async (index: number, values: TaskVariable) => {
    const res = await emit<
      { categoryId: string; index: number; task: TaskVariable },
      LauncherTask
    >(EditorEvent.UPDATE_TASK, {
      categoryId: categoryId,
      index: index,
      task: values,
    });

    if (res) {
      update(index, { ...res, tempEid: res.eid, updateStatus: 'finish' });

      const nextTask = getValues(`contents.${activeStep}.tasks.${index + 1}`);

      if (nextTask && !nextTask?.completed) {
        setValue('taskEditable', nextTask.tempEid);
      } else {
        setValue('taskEditable', undefined);
      }
    }
  };

  const maxIndex = useMemo(() => {
    return fields?.filter(selected).length || 0;
  }, [fields]);

  if (!maxIndex) {
    return (
      <Flex flexDir='column' gap={2} mt={1}>
        <Steps direction='vertical' status='wait'>
          <Steps.Step
            status='wait'
            title={
              <EmptySection categoryId={categoryId} addNewTask={addNewTask} />
            }
            icon={<VerticalStepIconRender index={0} />}
          />
        </Steps>
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap={2} mt={1}>
      <Steps direction='vertical' status='wait'>
        {fields.map((value, index) => {
          const isEditable = editId === value.tempEid;

          if (!value.selected) {
            return null;
          }

          return (
            <Steps.Step
              status={isEditable ? 'process' : value.updateStatus}
              key={value.id}
              title={
                <EditorItemContainer
                  key={value.id}
                  index={index}
                  data={value}
                  update={update}
                  updateTask={onUpdateClick}
                  isLast={maxIndex === index + 1}
                  moveTask={onTaskMove}
                  addNewTask={insert}
                  categoryId={categoryId}
                  actionHandler={(_, action) =>
                    actionHandler(action, value, index)
                  }
                />
              }
              icon={<VerticalStepIconRender index={index} />}
            />
          );
        })}
      </Steps>

      <EditorBottomAction isLastSection={isLastSection} />
    </Flex>
  );
};

const SectionWrapper: FC<Omit<IProps, 'categoryId'>> = (props) => {
  const categoryId = useWatch<IFormInput, `contents.${number}.eid`>({
    name: `contents.${props.activeStep}.eid`,
  });

  if (categoryId && props.activeStep >= 0) {
    return <EditorSection {...props} categoryId={categoryId} />;
  }

  return null;
};

export default SectionWrapper;
