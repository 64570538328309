import { Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { FC, useMemo } from 'react';
import { ReactComponent as DragIcon } from 'assets/images/dragIcon.svg';
import { capitalize } from 'lodash';
import { FieldType } from '../locations/LocationListContainer';
import { staticColumnIds } from '../locations/static-data';

interface IProps {
  data: FieldType;
  onClickHandler: () => void;
}

const FieldItem: FC<IProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props?.data?.id! });

  const isDisabled = !!(props?.data?.default || props?.data?.mandatory);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const label = useMemo(() => {
    return staticColumnIds?.includes(props?.data?.id!)
      ? capitalize(props?.data?.field)
      : props?.data?.field;
  }, [props?.data?.field]);

  return (
    <Flex
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
      style={style}
      ref={setNodeRef}
    >
      <Flex
        gap={2}
        w='full'
        cursor={isDisabled ? 'no-drop' : 'pointer'}
        onClick={props?.onClickHandler}
      >
        {/* <Checkbox /> */}
        <Tooltip hasArrow label={label}>
          <Text
            isTruncated
            maxW={'130px'}
            w='fit-content'
            fontWeight={400}
            fontSize={'14px'}
          >
            {label}
          </Text>
        </Tooltip>
      </Flex>
      <Flex
        as='button'
        {...attributes}
        {...listeners}
        aria-label='Drag Handle'
        cursor='grab'
        onClick={(e) => e?.stopPropagation()}
      >
        <DragIcon />
      </Flex>
    </Flex>
  );
};

export default FieldItem;
