import React, { FC } from 'react';

interface IProps {
  hidden?: boolean;
}

const HideComponent: FC<IProps> = ({ children, hidden }) => {
  if (hidden) {
    return null;
  }

  return <>{children}</>;
};

export default HideComponent;
