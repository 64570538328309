import { AuthRole, AuthUser } from '../authorization';

export const canAccessAudit = (authUser: AuthUser): boolean => {
  return (
    authUser?.entity?.features?.audit && authUser?.authRole !== AuthRole.WORKER
  );
};

export const canAccessChat = (authUser: AuthUser): boolean => {
  return authUser?.entity?.features?.chat;
};
