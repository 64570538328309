import React, { FC } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import MemberEmpty from '../../../../assets/images/empty-state/member-empty-state.svg';
import SearchEmpty from '../../../../assets/images/Illustrations.svg';

interface IProps {
  search?: string;
}

const NoReportShareMembers: FC<IProps> = ({ search }) => {
  const { t } = useTranslation(['common', 'form', 'header', 'location']);

  return (
    <Center flexDir='column' height='100%'>
      <Center
        flexDir='column'
        maxW={search ? '280px' : '360px'}
        gap='8px'
        textAlign='center'
      >
        <Image
          src={search ? SearchEmpty : MemberEmpty}
          boxSize={search ? '150px' : '200px'}
        />

        <Box fontSize='15px' fontWeight='700' color='#303030'>
          {t(
            search ? 'form:noMemberLocationFound' : 'form:noMemberLocationFound'
          )}
        </Box>
        <Box fontSize='12px' fontWeight='500' color='#9E9E9E'>
          {t(search ? 'common:searchResultNotFound' : 'header:not_found_desc')}
        </Box>
      </Center>
    </Center>
  );
};

export default NoReportShareMembers;
