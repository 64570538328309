import { FC } from 'react';
import { Box, Flex, Text, Img, Divider } from '@chakra-ui/react';
import {
  MultipleChoice,
  CheckboxQuestions,
  DropdownQuestions,
  ShortAnswerQuestions,
  ParagraphQuestions,
  RecordVideo,
  FileUpload,
  Members,
  Location,
  ImageUpload,
} from './audit-response-type';
import { QuestionType } from 'types';
import MediaSummary from '../MediaSummary';
import { generateValidId } from '../helper';

interface IProps {
  title: string;
  qType?: QuestionType;
  section?: string;
  index?: number;
  entityUsers?: any;
  setCheckIfMembersLoaded?: any;
}

const AuditResponse: FC<IProps> = ({
  children,
  title,
  index,
  section,
  entityUsers,
  setCheckIfMembersLoaded,
  qType,
  ...rest
}) => {
  const render = () => {
    switch (qType) {
      case QuestionType.MULTI_CHOICE:
        return <MultipleChoice {...rest} />;
      case QuestionType.CHECKBOX:
        return <CheckboxQuestions {...rest} />;
      case QuestionType.DROPDOWN:
        return <DropdownQuestions {...rest} />;
      case QuestionType.SHORT_TEXT:
        return <ShortAnswerQuestions {...rest} />;
      case QuestionType.LONG_TEXT:
        return <ParagraphQuestions {...rest} />;
      case QuestionType.VIDEO_UPLOAD:
        return <RecordVideo {...rest} />;
      case QuestionType.FILE_UPLOAD:
        return <FileUpload {...rest} />;
      case QuestionType.MEMBER:
        return <Members {...rest} entityUserFromPublicReport={entityUsers} />;
      case QuestionType.LOCATION:
        return <Location {...rest} entityUserFromPublicReport={entityUsers} />;
      case QuestionType.IMAGE_UPLOAD:
        return <ImageUpload {...rest} />;

      default:
        return <MediaSummary />;
    }
  };

  return (
    <Box>
      <Flex
        alignItems={'center'}
        fontWeight={500}
        padding={'0px 1.5rem'}
        marginTop={'10px'}
        paddingBottom={'10px'}
        scrollMargin={'8vh'}
        id={generateValidId(section + '-' + title)}
      >
        <Flex flexDir={'column'} width={'50%'}>
          <Text
            lineHeight={'32px !important'}
            fontWeight={500}
            fontSize={'20px'}
          >
            {section !== 'Flagged Items' && index + 1 + '.'} {title}
          </Text>
          <Flex gap={'17px'} wrap={'wrap'}>
            {rest?.media?.length > 0 &&
              rest?.media?.map((item: string, index: number) => {
                return (
                  <Flex cursor={'pointer'} flexDir={'column'} key={index}>
                    <Img
                      margin={'5px'}
                      borderRadius={'12px'}
                      h={'120px'}
                      src={item}
                      alt={'media image'}
                    />
                    {/* <Text textDecoration={'underline'}>Photo {index + 1}</Text> */}
                  </Flex>
                );
              })}
          </Flex>
        </Flex>

        <Flex
          width={'50%'}
          direction={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-end'}
          gap={2}
          fontSize={'15px'}
        >
          {render()}
        </Flex>
      </Flex>

      {rest?.hasOwnProperty('answer') && (
        <Flex>
          {rest?.noteText && (
            <Flex
              width={'100%'}
              mx={'1.5rem'}
              mb={'10px'}
              alignItems={'center'}
              px={'15px'}
              py={'5px'}
              borderRadius={'3px'}
              backgroundColor={'#FFD88D'}
              minHeight={'30px'}
            >
              {rest?.noteText && (
                <Text fontWeight={500} fontSize={'16px'}>
                  {rest?.noteText}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      )}
      <Divider style={{ width: '98%', margin: 'auto' }} />
    </Box>
  );
};

export default AuditResponse;
