import React, { FC, useState } from 'react';
import { Box, Button, Flex, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { userObj } from 'sop-commons/src/client';

import { FormVisibility } from '../Visibility/visibility.types';
import ShareToMember from './ShareToMember';

import {
  FormToUserResponse,
  FormToUserVariable,
  SEND_FORM_QUERY,
} from '../../pages/forms/modules/SendForm/send-form.graphql';
import { ShareMemberEntity } from './share-member.graphql';
import { SharedProps } from './share-member.types';
import VisibilityView from './VisibilityView';
import { useVisibilityMemberData } from './useVisibilityMemberData';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

interface IProps extends Omit<SharedProps, 'onSendClick'> {
  visibility: FormVisibility;
  formId: string;
  formName: string;
}

const ShareContent: FC<IProps> = ({
  visibility,
  formId,
  onClickHandler,
  formCreatorId,
  formName,
}) => {
  const { t } = useTranslation(['form']);

  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });

  const { members, loading } = useVisibilityMemberData(visibility);

  const [showVisibility, setShowVisibility] = useState(false);

  const [submitFormData] = useMutation<FormToUserResponse, FormToUserVariable>(
    SEND_FORM_QUERY
  );

  const onSendClick = async (value: ShareMemberEntity) => {
    deployEvent(AmplitudeEventNames?.SEND_MESSAGE_CLICK, {
      from_where: 'Forms & Checklists > Responses',
      member_type: value?.authRole,
      member_name: value?.name,
      member_id: value?.eid,
      member_location: value?.locations?.[0]?.name,
      member_job: value?.role,
    });

    await submitFormData({
      variables: {
        senderId: userObj().eid,
        receiverIds: [value.eid],
        message: `${process.env.REACT_APP_FRONTEND_URL}/form/submit/${formId}`,
      },
    }).then((response) => {
      if (response.data?.sendDirectMessage?.succeed) {
        toast({
          title: t('success'),
          status: 'success',
          description: t('form:formSuccessSentUser', {
            name: value.name,
          }),
        });
      }
    });
  };

  return (
    <Flex flex={1} flexDir='column' gap={5} overflow='hidden'>
      <Flex
        p='12px 16px'
        borderRadius='10px'
        bg='rgba(202, 189, 255, 0.4)'
        gap={2}
        align='center'
      >
        <FontAwesomeIcon icon={faInfoCircle as IconProp} color='#6F767E' />

        <Box flex={1} fontSize='12px' fontWeight='semibold' color='#33383F'>
          {t('form:formShareVisibilityHelp')}
        </Box>

        <Button
          variant='link'
          size='sm'
          colorScheme='purple'
          fontSize='12px'
          textDecoration='underline'
          disabled={loading}
          onClick={() => setShowVisibility(true)}
        >
          VISIBILITY
        </Button>
      </Flex>
      {showVisibility && (
        <VisibilityView
          title={formName}
          showVisibility={showVisibility}
          visibility={visibility}
          members={members}
          onClose={() => setShowVisibility(false)}
        />
      )}
      <ShareToMember
        members={members}
        loading={loading}
        visibility={visibility}
        onSendClick={onSendClick}
        onClickHandler={onClickHandler}
        formCreatorId={formCreatorId}
      />
    </Flex>
  );
};

export default ShareContent;
