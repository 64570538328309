import { TFuncKey } from 'i18next';

import { QuestionType } from '../../../../../types';
import { QuestionImage } from '../../../../forms/create-form';

export interface IQuestionData {
  title: TFuncKey<'form', 'questionType'>;
  image: string;
  imageLight: string;
  value: QuestionType;
  description?: string;
}

const OrderedData = [
  QuestionType.MULTI_CHOICE,
  QuestionType.CHECKBOX,
  QuestionType.SHORT_TEXT,
  QuestionType.LONG_TEXT,
  QuestionType.DROPDOWN,
  QuestionType.LOCATION,
  QuestionType.MEMBER,
  QuestionType.FILE_UPLOAD,
  QuestionType.IMAGE_UPLOAD,
  QuestionType.VIDEO_UPLOAD,
] as const;

export const QuestionData = OrderedData.map((i) => ({
  ...QuestionImage[i],
}));
