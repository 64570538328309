import SearchFilter from './components/SearchFilter';
import ChipsBadge from './components/ChipsBadge';
import TableRender from './components/TableRender';
import TableRenderNew from './components/TableRenderNew';
import React, { FC, useEffect } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { useControlLayer } from './layers';
import { EmptyState, Loader } from 'sub-components';
import { useAuditTabContext } from '../store/context';

const MyAudits: FC = () => {
  const {
    filters,
    loading,
    searchQuery,
    clearAllFilters,
    handleFiltersUpdateOnPopoverClose,
    refetchHandler,
    setFilters,
  } = useControlLayer();
  const myAuditData = useAuditTabContext((state) => state.myAuditData);
  if (loading && !searchQuery) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  if (
    myAuditData?.length === 0 &&
    !searchQuery &&
    !filters?.dueDate &&
    filters?.locations?.length === 0
  ) {
    return (
      <Center h='60vh'>
        <EmptyState image='Form' title='No Audit assigned to you' />
      </Center>
    );
  }

  return (
    <Flex flexDir='column' gap={4}>
      <SearchFilter
        filters={filters}
        clearAllFilters={clearAllFilters}
        onPopoverClose={handleFiltersUpdateOnPopoverClose}
        setFilters={setFilters}
      />
      {!loading &&
      (searchQuery || filters?.dueDate || filters?.locations?.length > 0) &&
      myAuditData?.length === 0 ? (
        <Center h='60vh'>
          <EmptyState
            image='Search'
            title='Hmm, couldn’t find an audit with that name'
            description='Try using different search terms'
          />
        </Center>
      ) : (
        <>
          {!searchQuery && <ChipsBadge />}
          <TableRenderNew
            loading={!!(loading && searchQuery)}
            refetchHandler={refetchHandler}
          />
          {/* <TableRender loading={!!(loading && searchQuery)} /> */}
        </>
      )}
    </Flex>
  );
};

MyAudits.displayName =
  'sub-components/audits/audit-tab/my-audits/MyAuditsContainer';

export default MyAudits;
