import React, { FC } from 'react';
import { Box, Flex, useControllableState } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Button, Textarea } from '../../../../atoms';

interface EditNoteProps {
  defaultValue?: string;
  onCancel?: () => void;
  onSaveChange?: (value: string) => void;
}

const EditNotes: FC<EditNoteProps> = ({
  defaultValue,
  onCancel,
  onSaveChange,
}) => {
  const [value, updateValue] = useControllableState({
    defaultValue: defaultValue,
  });

  return (
    <Flex flexDir='column' gap={2}>
      <Textarea
        fontSize='13px'
        value={value}
        placeholder='Write your notes here'
        onChange={(e) => updateValue(e?.target?.value)}
      />
      <Flex gap={2} justify='end'>
        <Button size='sm' variant='solid' onClick={onCancel}>
          Cancel
        </Button>

        <Button
          size='sm'
          variant='solid'
          colorScheme='blue'
          onClick={() => onSaveChange?.(value)}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

interface IProps {
  editNote?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  closeNoteEdit?: () => void;
  editNotes?: () => void;
}

const QuestionNotes: FC<IProps> = ({
  value,
  onChange,
  editNote,
  editNotes,
  closeNoteEdit,
}) => {
  if (editNote) {
    return (
      <EditNotes
        defaultValue={value}
        onCancel={closeNoteEdit}
        onSaveChange={callAll(onChange, closeNoteEdit)}
      />
    );
  }

  if (value) {
    return (
      <Box
        bg='rgba(244, 244, 244, 1)'
        borderRadius='8px'
        p='16px'
        cursor='pointer'
        onClick={editNotes}
      >
        {value}
      </Box>
    );
  }

  return null;
};

export default QuestionNotes;
