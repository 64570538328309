import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Button, Dropdown, SelectOption } from '../../../../atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'shared/headerColors';

import { IFormInput } from '../audit-schedule.types';
import { Container } from './shared';

import { useSchedulePreview } from './preview';
import { useTemplatesData } from './useTemplatesData';

interface IProps {
  templateId?: string;
  isEditing?: boolean;
}

const TemplateSelect: FC<IProps> = ({ templateId, isEditing }) => {
  const { getValues, setValue } = useFormContext<IFormInput>();
  const { loading, options } = useTemplatesData({
    skip: !!templateId,
    fetchPolicy: 'network-only',
  });

  const schedulePreview = useSchedulePreview();

  const onChange = (value: SelectOption) => {
    setValue('scheduleTitle', value.label);
  };

  return (
    <Container>
      <Flex justify='space-between'>
        <BoxHeader
          title='Template'
          fontSize='18px'
          boxWidth={3}
          color={HeaderColors.Purple}
        />

        <Button
          variant='outline'
          disabled={loading || !options.length}
          leftIcon={
            <FontAwesomeIcon
              icon={faMobile as IconProp}
              flip='vertical'
              color='#6F767E'
            />
          }
          onClick={() => {
            return schedulePreview({
              templateId: getValues('template.value'),
            });
          }}
        >
          Preview
        </Button>
      </Flex>

      <Controller<IFormInput, 'template'>
        name='template'
        rules={{
          required: 'Template required',
        }}
        // disabled={!!templateId}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid}>
              <Dropdown
                size='lg'
                {...field}
                onChange={callAll(field.onChange, onChange)}
                disabled={field.disabled || isEditing}
                isLoading={loading}
                options={options}
                getOptionLabel={(option) => {
                  if (!option?.label && option?.value) {
                    return options.find((o) => o.value === option?.value)
                      ?.label;
                  }
                  return option?.label;
                }}
                inputStyle={{
                  borderWidth: '2px',
                  borderRadius: '12px',
                  outline: 'none',
                  backgroundColor: 'white',
                }}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Container>
  );
};

export default React.memo(TemplateSelect);
