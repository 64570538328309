import { Flex } from '@chakra-ui/react';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import React, { FC, useEffect, useMemo } from 'react';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { AuditorSection } from './AuditorSection';
import LocationReportSection from './LocationReportSection';
import SummarySection from './SummarySection/SummarySection';

interface IProps {
  auditData: AuditSessionEntity | undefined;
  refetchHandler?: () => void;
}

const OneTimeAuditWithoutScore: FC<IProps> = ({
  auditData,
  refetchHandler,
}) => {
  const { updateShowLocationsFilter, selectedLocationFilter } =
    useAuditDetailContext((state) => ({
      updateShowLocationsFilter: state.updateShowLocationsFilter,
      selectedLocationFilter: state.selectedLocationFilter,
    }));

  useEffect(() => {
    updateShowLocationsFilter(true);
  }, []);

  const processedAuditData = useMemo<AuditSessionEntity | undefined>(() => {
    if (!auditData) return undefined;

    const { assignments } = auditData;

    if (
      !selectedLocationFilter ||
      selectedLocationFilter.value === 'allLocations'
    ) {
      return auditData;
    }

    const filteredAssignments = assignments?.filter(
      (assignment) => assignment?.locationId === selectedLocationFilter.value
    );

    return { ...auditData, assignments: filteredAssignments };
  }, [auditData, selectedLocationFilter]);

  const { auditors = [] } = auditData || {};

  return (
    <Flex w='full' gap={4}>
      <Flex w='70%'>
        <LocationReportSection
          auditData={processedAuditData}
          scored={false}
          hideFooter
          refetchHandler={refetchHandler}
        />
      </Flex>
      <Flex w='30%' flexDir='column' gap={4}>
        <SummarySection auditData={auditData} />
        <AuditorSection auditData={auditData} />
      </Flex>
    </Flex>
  );
};

OneTimeAuditWithoutScore.displayName =
  'sub-components/audits/detail/components/Overview/components/OneTimeAuditWithoutScore';

export default OneTimeAuditWithoutScore;
