import React, { FC } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import ListInternal from './ListInternal';

const AuditList: FC = () => {
  return (
    <DashboardContainer>
      <ListInternal />
    </DashboardContainer>
  );
};

AuditList.displayName = 'pages/Audits/Listing/AuditList';

export default AuditList;
