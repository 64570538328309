import React, { FC, forwardRef, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CSSObject,
  Flex,
  HStack,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useStyles,
} from '@chakra-ui/react';
import {
  chakraComponents,
  MenuListProps,
  OptionProps,
  SizeProps,
  Size,
} from 'chakra-react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import Dropdown, { DropDownProps, SelectOption } from 'atoms/Dropdown';
import { FormInput } from 'atoms';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';

interface IProps {
  options: SelectOption<string>[];
  onChange: (option: SelectOption<string>) => void;
}

const LocationFilter: FC<IProps> = ({ options, onChange }) => {
  console.log('options : ', options);
  const selectedOption = options?.filter((op) => op.selected)?.[0];
  console.log({ options, selectedOption });
  return (
    <Popover placement='bottom-end'>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <HStack
          cursor='pointer'
          bg='rgba(255, 255, 255, 1)'
          borderRadius='8px'
          p='8px 16px'
        >
          <Box
            as='span'
            fontWeight={600}
            fontSize='15px'
            color='rgba(111, 118, 126, 1)'
          >
            {selectedOption?.label}
          </Box>
          <ChevronDownIcon fontSize='20px' color='rgba(111, 118, 126, 1)' />
        </HStack>
      </PopoverTrigger>
      <PopoverContent w='fit-content' borderRadius='16px'>
        <PopoverBody boxShadow='lg' borderRadius='16px'>
          <List spacing={4} maxH='30vh' overflowY='scroll'>
            {options?.map((option, index) => (
              <Flex
                key={index}
                gap={8}
                cursor='pointer'
                align='center'
                justify='space-between'
                onClick={() => onChange(option)}
              >
                <ListItem fontSize='15px'>{option?.label}</ListItem>
                {option?.selected && (
                  <CheckIcon color='rgba(131, 191, 110, 1)' />
                )}
              </Flex>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default LocationFilter;
