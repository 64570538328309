import { Flex } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useAuditDetailContext } from '../store/context';
import AuditHistorySection from './AuditHistory/AuditHistorySection';
import AuditQuestionSection from './QuestionSection/AuditQuestionSection';
import FilterSection from './FilterSection';
import FlaggedResponseSection from './FlaggedResponseSection/FlaggedResponse';
import OverviewSection from './OverviewSection';
import { SessionDetailEntity } from '../../../../sub-components/audits/take-audit/types';

const Container: FC<{ isAuditRepeating: boolean }> = ({ isAuditRepeating }) => {
  const selectedTab = useAuditDetailContext((state) => state.selectedTab);

  const tabRender = useMemo(() => {
    switch (selectedTab) {
      case 'overview':
        return <OverviewSection />;
      case 'auditHistory':
        return isAuditRepeating ? <AuditHistorySection /> : null;
      case 'questionWise':
        return <AuditQuestionSection isAuditRepeating={isAuditRepeating} />;
      case 'flaggedResponse':
        return <FlaggedResponseSection isAuditRepeating={isAuditRepeating} />;
      default:
        return;
    }
  }, [selectedTab]);

  return (
    <Flex bg='white' flexDir='column' gap={4} py='36px' px='35px'>
      <FilterSection isAuditRepeating={isAuditRepeating} />
      {tabRender}
    </Flex>
  );
};

Container.displayName = 'pages/Audits/Detail/components/Container';

export default Container;
