import { gql } from '@apollo/client';

export const AUDIT_HISTORY = gql`
  query AuditHistory(
    $filter: FilterFindManyAuditSessionInput
    $page: Int
    $perPage: Int
    $locationIds: [String]
  ) {
    AuditSessionPagination(filter: $filter, page: $page, perPage: $perPage) {
      count
      items {
        name
        eid
        title
        dueDate
        startDate
        isCompleted
        createdAt
        entityId
        updatedAt
        description
        auditId
        questions
        assignments(locationIds: $locationIds) {
          auditors
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          response
          startedBy
          totalScore
          maxScore
          reportsUrl
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const AUDIT_HISTORY_SESSION_LOCATIONS = gql`
  query AuditHistorySessionLocations($eid: String!) {
    AuditSessionById(eid: $eid) {
      name
      eid
      title
      assignments {
        locationId
        auditors
      }
    }
  }
`;
