import { Flex, Box, Center } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import {
  AnalyticsData,
  LocationAnalytics,
  MonthAnalytics,
} from './GraphSection';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { HeaderColors } from 'configs';
import Gauge from './SemiCircularGauge';
import { BoxHeader } from 'ui-components';

interface TotalFlagsProps {
  data: AnalyticsData | undefined;
}

const TotalFlags: FC<TotalFlagsProps> = ({ data }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const currentYear = currentDate.getFullYear();
  const previousYear = previousMonth === 12 ? currentYear - 1 : currentYear;

  const { currentFlag, previousFlag } = useMemo(() => {
    let current = 0;
    let previous = 0;

    if (data && data.AuditLocationsAnalytics) {
      Object.values(data.AuditLocationsAnalytics).forEach(
        (locationTrend: LocationAnalytics) => {
          const currentMonthKey = currentMonth;
          const previousMonthKey = previousMonth;

          const currentMonthData: MonthAnalytics | undefined =
            locationTrend[currentMonthKey];
          const previousMonthData: MonthAnalytics | undefined =
            locationTrend[previousMonthKey];
          console.log({
            currentMonthKey,
            previousMonthKey,
            currentMonthData,
            previousMonthData,
          });
          if (currentMonthData) {
            current += currentMonthData.totalFlagged;
          }

          if (previousMonthData) {
            previous += previousMonthData.totalFlagged;
          }
        }
      );
    }

    return { currentFlag: current, previousFlag: previous };
  }, [data, currentMonth, previousMonth, currentYear, previousYear]);

  if (!data || (!currentFlag && !previousFlag)) {
    return (
      <Flex flexDir='column'>
        <GenericColorHeader title='Total Flags' color={HeaderColors.Orange} />
        <Center flexDir='column' h='full' w='full'>
          <Box as='span' fontWeight={600} fontSize='16px'>
            Great Job!
          </Box>
          <Box
            as='span'
            fontWeight={500}
            color='rgba(111, 118, 126, 1)'
            textAlign='center'
          >
            You're all clear—no flags at the moment.
          </Box>
        </Center>
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' w='full' overflow='hidden'>
      <BoxHeader title='Total Flags' color={HeaderColors.Green} />
      <Box w='full' h='230px'>
        <Gauge
          currentMonthFlag={currentFlag}
          previousMonthFlag={previousFlag}
        />
      </Box>
    </Flex>
  );
};

export default TotalFlags;
