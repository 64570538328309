import React, { FC, useEffect } from 'react';
import { Any, QuestionType } from '../../../../types';
import { QuestionsState } from '../types';
import {
  CheckboxSection,
  DropdownSection,
  FileUploadSection,
  ImageUploadSection,
  LocationSection,
  LongTextSection,
  MemberSection,
  MultiChoiceSection,
  ShortTextSection,
  VideoUploadSection,
} from './question-sections';
import QuestionSection from './QuestionSection';
import { AuditTake } from '../tabke-audit.helpers';

interface IProps {
  question: QuestionsState;
  questionIndex: number;
  sectionIndex: number;
  index: number;
}

const QuestionContainer: FC<IProps> = ({
  index,
  questionIndex,
  question,
  sectionIndex,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveHandler = (...args: Any) => {
    AuditTake.save(`data.${questionIndex}`, question.eid);
  };

  const renderQuestionInput = () => {
    let renderer: React.ReactNode = <></>;
    switch (question?.qType) {
      case QuestionType.MULTI_CHOICE:
        renderer = (
          <MultiChoiceSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.CHECKBOX:
        renderer = (
          <CheckboxSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.SHORT_TEXT:
        renderer = (
          <ShortTextSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.LONG_TEXT:
        renderer = (
          <LongTextSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.DROPDOWN:
        renderer = (
          <DropdownSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.LOCATION:
        renderer = (
          <LocationSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.MEMBER:
        renderer = (
          <MemberSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.FILE_UPLOAD:
        renderer = (
          <FileUploadSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.IMAGE_UPLOAD:
        renderer = (
          <ImageUploadSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      case QuestionType.VIDEO_UPLOAD:
        renderer = (
          <VideoUploadSection
            question={question}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
          />
        );
        break;
      default:
        break;
    }
    return renderer;
  };

  return (
    <QuestionSection
      index={index}
      questionIndex={questionIndex}
      sectionIndex={sectionIndex}
      question={question}
      saveHandler={saveHandler}
    >
      {renderQuestionInput()}
    </QuestionSection>
  );
};

export default QuestionContainer;
