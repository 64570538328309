import React, { FC, useCallback } from 'react';
import { Button, useFormControlContext } from '@chakra-ui/react';
import { useButtonProps } from '../../../../tasks/create-task/Scheduler/useButtonProps';

interface IProps {
  isReadOnly?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  clickDisabled?: boolean;
  isDisabled?: boolean;
}

const OptionButton: FC<IProps> = ({
  isReadOnly,
  isSelected,
  onClick,
  children,
  clickDisabled = true,
  isDisabled,
}) => {
  const field = useFormControlContext();
  const otherProps = useButtonProps({
    isSelected: isSelected,
    isReadOnly: isReadOnly,
  });

  const _onClick = useCallback(() => {
    if (isReadOnly) {
      return undefined;
    }

    return isSelected ? (clickDisabled ? undefined : onClick?.()) : onClick?.();
  }, [isReadOnly, isSelected, clickDisabled, onClick]);

  return (
    <Button
      size='lg'
      fontSize='14px'
      fontWeight='500'
      borderRadius='8px'
      borderWidth='2px'
      {...otherProps}
      aria-invalid={field?.isInvalid}
      isDisabled={isDisabled}
      // _invalid={{
      //   borderColor: 'red.500',
      // }}
      onClick={_onClick}
    >
      {children}
    </Button>
  );
};

export default OptionButton;
