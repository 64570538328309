import React, { FC, useMemo, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { DateRange } from 'react-day-picker';
import { useAuditTabContext } from '../../store/context';
import { SearchInput } from 'atoms';
import DateRangePicker from 'sub-components/audits/range-date-picker';
import debounce from 'lodash.debounce';

interface SearchFilterProps {
  loading: boolean;
  selectedRange: DateRange | undefined;
  setSelectedRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
}

const SearchFilter: FC<SearchFilterProps> = ({
  loading,
  selectedRange,
  setSelectedRange,
}) => {
  const searchQuery = useAuditTabContext(
    (state) => state.supervisedSearchQuery
  );
  const updateSearchQuery = useAuditTabContext(
    (state) => state.updateSupervisedSearchQuery
  );

  const [localQuery, setLocalQuery] = useState<string>(searchQuery || '');

  const debouncedUpdateSearchQuery = useMemo(
    () =>
      debounce((value: string) => {
        updateSearchQuery(value);
      }, 1000),
    [updateSearchQuery]
  );

  useEffect(() => {
    debouncedUpdateSearchQuery(localQuery);
  }, [localQuery, debouncedUpdateSearchQuery]);

  useEffect(() => {
    return () => {
      debouncedUpdateSearchQuery.cancel();
    };
  }, [debouncedUpdateSearchQuery]);

  useEffect(() => {
    setLocalQuery(searchQuery);
  }, [searchQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalQuery(e.target.value);
  };

  const onDatePickerClose = (range: DateRange | undefined) => {
    setSelectedRange(range);
  };

  const updateDateRange = (range: DateRange | undefined) => {
    setSelectedRange(range);
  };

  return (
    <Flex w='full' gap={4} justify='space-between' align='center'>
      <Flex w='85%'>
        <SearchInput
          placeholder='Search'
          hideShortcuts
          value={localQuery}
          onChange={handleChange}
        />
      </Flex>
      <DateRangePicker
        selectedRange={selectedRange}
        updateDateRange={updateDateRange}
        onPopoverClose={onDatePickerClose}
      />
    </Flex>
  );
};

SearchFilter.displayName =
  'sub-components/audits/audit-tab/supervised/components/SearchFilter';

export default SearchFilter;
