import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { UserSignature } from '../../../types';
import { signatureConfigs } from '../../../configs';
interface VProps {
  value: UserSignature;
  view: string;
}
const Signature: FC<VProps> = ({ value, view }) => {
  const config = useMemo(() => {
    return signatureConfigs.find((it) => it.fontFamily === value?.font);
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <Flex
      justifyContent={view === 'web' ? 'flex-start' : 'flex-end'}
      alignItems={'center'}
      minHeight={'80px'}
      minWidth={'250px'}
    >
      <Flex
        whiteSpace='nowrap'
        color='#1A1D1F'
        {...config}
        userSelect='none'
        borderRadius='4px'
      >
        <Text fontSize={'2rem'}>{value?.text ? value?.text : '--'}</Text>
      </Flex>
    </Flex>
  );
};

export default Signature;
