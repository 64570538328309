import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';

const MissedAudit: FC = () => {
  return (
    <Flex color='rgba(255, 106, 85, 1)' align='center' gap='5px'>
      <FontAwesomeIcon icon={faCircleExclamation as IconProp} />
      <Box>Audit missed in this cycle</Box>
    </Flex>
  );
};

export default MissedAudit;
