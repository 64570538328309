import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'; // Adjust icons as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { ProcessData, AuditSessionEntity } from '../../../types';
import { ReactComponent as PauseAudit } from 'assets/images/pause-audit.svg';
import { ReactComponent as ResumeAudit } from 'assets/images/resume-audit.svg';
import { ReactComponent as DeleteAudit } from 'assets/images/delete-audit.svg';
import { UserEntityData } from 'shared/graphql/shared-types';
import { AUDIT_LIST } from 'appRoutes';
import useCombinedStore from 'zustandStore/store';
import { useApolloClient } from '@apollo/client';
import { useAuditConfirmation } from 'sub-components/audits/schedule/audit-settings';
import {
  AuditAction,
  getAuditAction,
} from 'sub-components/audits/schedule/audit-settings/audit-setting.helpers';
import { useTranslation } from 'react-i18next';
import { faCirclePause } from '@fortawesome/pro-solid-svg-icons';
import { Authorize, AuthRole } from 'authorization';

interface AuditCardProps {
  auditData: ProcessData;
  auditTitle: React.ReactNode;
  scheduleDetails: React.ReactNode;
  footerDetails: React.ReactNode;
  refetchHandler?: () => void;
}

export type TriggerType = 'pause' | 'resume' | 'delete' | undefined;

const AuditCard: FC<AuditCardProps> = ({
  auditData,
  auditTitle,
  scheduleDetails,
  footerDetails,
  refetchHandler,
}) => {
  const history = useHistory();
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  const { t } = useTranslation(['common']);
  const client = useApolloClient();
  const auditConfirmation = useAuditConfirmation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auditId = auditData?.auditId;
  const sessionId = auditData?.eid;

  const actionHandler = async (action: AuditAction) => {
    if (!auditId) return;
    const context = getAuditAction(action);

    auditConfirmation({
      ...context.modalProps,
      onConfirm: async () => {
        try {
          const response = await client.mutate({
            mutation: context.mutation,
            variables: {
              eid: auditId,
            },
          });

          if (response.errors) {
            return Promise.reject(response.errors);
          }
          toast({
            status: 'success',
            title: t('common:success'),
            description: context.successMessage,
          });
          await new Promise<void>((resolve) => setTimeout(resolve));
          refetchHandler?.();
        } catch (err) {
          // @ts-ignore
          const message = err?.graphQLErrors?.[0]?.message;
          if (message?.includes('Audit')) {
            toast({
              status: 'error',
              title: t('common:error'),
              description: message,
            });
          } else {
            toast({
              status: 'error',
              title: t('common:error'),
              description: context.errorMessage,
            });
          }
          return Promise.reject(err);
        }
      },
    });
  };

  const hidePauseResume = () => {
    return (
      auditData?.auditStatus === 'completed' &&
      auditData?.audit?.repeatDetails?.auditFrequency === 'oneTime'
    );
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement='bottom'
    >
      {({ onClose }) => (
        <Flex
          flexDir='column'
          w='full'
          h='125px'
          justify='space-around'
          border='.5px solid rgba(239, 239, 239, 1)'
          p='8px 11px'
          borderRadius='10px'
          cursor='pointer'
          role='group'
          onClick={() =>
            history.push(`${AUDIT_LIST}/details/${auditId}/${sessionId}`)
          }
          pos='relative'
          boxShadow='6px 6px 24px -12px rgba(0, 0, 0, 0.4)'
        >
          {auditData?.audit?.status === 'AUDIT_PAUSED' && (
            <Flex
              borderRadius='15px'
              border='1px solid rgba(239, 239, 239, 1)'
              w='fit-content'
              align='center'
              gap='2px'
              px='5px'
            >
              <FontAwesomeIcon
                icon={faCirclePause as IconProp}
                color='#33383F'
              />
              <Box as='span' color='#33383F' fontSize='12px' fontWeight={600}>
                Paused
              </Box>
            </Flex>
          )}
          <Flex flexDir='column'>
            {auditTitle}
            <Authorize
              permittedRoles={[AuthRole.SUPER_ADMIN]}
              permittedFor='user'
            >
              <PopoverTrigger>
                <Box
                  pos='absolute'
                  top='10px'
                  right='20px'
                  opacity={isOpen ? 1 : 0}
                  _groupHover={{ opacity: 1 }}
                  transition='opacity 0.2s'
                  onClick={(e) => e.stopPropagation()}
                >
                  <FontAwesomeIcon
                    icon={faEllipsis as IconProp}
                    fontSize='20px'
                  />
                </Box>
              </PopoverTrigger>
            </Authorize>
            <PopoverContent
              onClick={(e) => e.stopPropagation()}
              borderRadius='16px'
              w='232px'
            >
              <PopoverArrow />
              <PopoverBody p={0}>
                <Flex flexDir='column' p='5px'>
                  {!hidePauseResume() &&
                    auditData?.audit?.status === 'AUDIT_LIVE' && (
                      <Flex
                        _hover={{
                          bg: 'rgba(244, 244, 244, 1)',
                          borderRadius: '12px',
                        }}
                        onClick={() => actionHandler('pause')}
                      >
                        <Flex p='12px' gap='10px'>
                          <PauseAudit />
                          <Box as='span' fontWeight={600} fontSize='15px'>
                            Pause Audit
                          </Box>
                        </Flex>
                      </Flex>
                    )}
                  {!hidePauseResume() &&
                    auditData?.audit?.status === 'AUDIT_PAUSED' && (
                      <Flex
                        _hover={{
                          bg: 'rgba(244, 244, 244, 1)',
                          borderRadius: '12px',
                        }}
                        onClick={() => actionHandler('resume')}
                      >
                        <Flex p='12px' gap='10px'>
                          <ResumeAudit />
                          <Box as='span' fontWeight={600} fontSize='15px'>
                            Resume Audit
                          </Box>
                        </Flex>
                      </Flex>
                    )}
                  <Flex
                    _hover={{
                      bg: 'rgba(244, 244, 244, 1)',
                      borderRadius: '12px',
                    }}
                    onClick={() => actionHandler('delete')}
                  >
                    <Flex p='12px' gap='10px'>
                      <DeleteAudit />
                      <Box
                        as='span'
                        fontWeight={600}
                        fontSize='15px'
                        color='rgba(255, 106, 85, 1)'
                      >
                        Delete Audit
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </PopoverBody>
            </PopoverContent>
            {scheduleDetails}
          </Flex>
          <Divider />
          {footerDetails}
        </Flex>
      )}
    </Popover>
  );
};

export default AuditCard;
