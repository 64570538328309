import { makeVar, useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  chaptersListVar,
  foldersListVar,
} from 'pages/Chapters/ChapterContainer/ChapterContainer';
import {
  ChapterListResponse,
  ChapterListVariable,
  GET_CHAPTERS,
  GET_FOLDERS_LIST,
} from 'pages/Chapters/chapters.graphql';
import { GET_USER } from 'pages/Login/login.graphql';
import { CategoryEntity, userObj } from 'sop-commons/src/client';
import { usersEntityObj } from 'sub-components/Header';
import { roleObj } from 'ui-components/DashboardMenu';
import { UserDataResponse } from '../../hooks/useUserDataQuery';
import { GET_ENTITY_USERS, GET_FOLDER_BY_ID } from './queries';
import { UserEntityData } from './shared-types';

export const getLoggedInUserDataHandler = (
  onSuccess?: (data: UserDataResponse) => void,
  onErrorFn?: () => void
) => {
  const [execute, { loading, error, data, refetch }] =
    useLazyQuery<UserDataResponse>(GET_USER, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (loggedInUserData) => {
        userObj(loggedInUserData?.user);
        const roles = data?.user?.entity?.roles;
        if (roles && roles.length) {
          let roleWithColor: any = {};
          roles.forEach((role: any) => {
            roleWithColor[role.name] = role.color;
          });

          roleObj(roleWithColor);
        }
        onSuccess?.(loggedInUserData);
      },
      onError: () => {
        onErrorFn?.();
      },
    });
  return { execute, loading, error, data, refetch };
};

//use this to fetch user entity data for complete project
export const getUsersEntityHandler = (
  onSuccess?: (data: UserEntityData[]) => void
) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<{
    EntityUser: UserEntityData[];
  }>(GET_ENTITY_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      usersEntityObj(data?.EntityUser);
      onSuccess?.(data?.EntityUser);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getFoldersList = (
  onSuccess?: (data: CategoryEntity[]) => void
) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    { EntityCategories: CategoryEntity[] },
    never
  >(GET_FOLDERS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // updateFoldersList(data?.EntityCategories || []);
      foldersListVar(data?.EntityCategories || []);
      onSuccess?.(data?.EntityCategories || []);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getChaptersList = (
  onSuccess?: (data: ChapterListResponse) => void
) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    ChapterListResponse,
    ChapterListVariable
  >(GET_CHAPTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      chaptersListVar(data);
      onSuccess?.(data);
    },
  });
  return { execute, loading, error, data, refetch };
};

export const getFolderById = (onSuccess?: (data: CategoryEntity) => void) => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    { EntityCategoryById: CategoryEntity },
    { eid: string }
  >(GET_FOLDER_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSuccess?.(data?.EntityCategoryById);
    },
  });
  return { execute, loading, error, data, refetch };
};
