export enum QuestionType {
  MULTI_CHOICE = 'multiChoice',
  CHECKBOX = 'checkBox',
  SHORT_TEXT = 'shortText',
  LONG_TEXT = 'longText',
  DROPDOWN = 'dropDown',
  FILE_UPLOAD = 'fileUpload',
  IMAGE_UPLOAD = 'imageUpload',
  VIDEO_UPLOAD = 'videoUpload',
  SECTION = 'section',
  LOCATION = 'location',
  MEMBER = 'member',
  TEXT_SECTION = 'disclaimer',
  ACKNOWLEDGMENT = 'acknowledgment',
}

export enum QuestionTypeText {
  multiChoice = 'Multiple choice',
  checkBox = 'Checkbox',
  shortText = 'Short answer',
  longText = 'Long answer',
  dropDown = 'Dropdown',
  fileUpload = 'File upload',
  imageUpload = 'Image upload',
  videoUpload = 'Video upload',
  section = 'Section',
  location = 'Location',
  member = 'Member',
  disclaimer = 'Disclaimer',
  acknowledgment = 'Acknowledgment',
}

export interface NoteImageEntity {
  name: string;
  url: string;
  type: string;
  mimetype: string;
  fileSize: number;
  optionId?: string;
  eid: string;
}

export interface ResponseEntity {
  qid: string;
  responseId?: string[];
  response: string;
  respondedAt: string;
  responseUrl?: string[];
  noteText: string;
  noteImage?: NoteImageEntity[];
  qType: QuestionType;
  isCorrect: boolean;
  otherResponse?: string;
  font?: string;
}

export interface QuestionEntity {
  label: string;
  subTitle?: string;
  qType: QuestionType;
  options?: OptionsEntity[];
  isRequired: boolean;
  hasImageNote: boolean;
  isMultiSelect: boolean;
  isDisabled: boolean;
  hasAnalytics: boolean;
  isArchieved: boolean;
  hasOtherOption: boolean;
  // translations: Record<string, any>;
  eid: string;
}

export interface OptionsEntity {
  label: string;
  isCorrect: boolean;
  isDisabled: boolean;
  isArchieved: boolean;
  isOther: boolean;
  // translations: Record<string, any>;
  eid: string;
}

export interface UserListEntity {
  name: string;
  eid: string;
  type: string;
  role: string;
  authRole: string;
  email?: string;
  createdAt: string;
  status: string;
  isExternal: boolean;
  isRoot: boolean;
  profilePic?: string;
}

export interface FormCategory {
  eid: string;
  name: string;
  categoryType?: 'default' | 'custom';
  createdBy?: string;
  entityId?: string;
}
