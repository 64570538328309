import { useLazyQuery } from '@apollo/client';
import { Box, Center, Flex } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import LocationLineGraph from 'sub-components/audits/detail/components/Overview/components/LocationLineGraph';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import Loader from 'sub-components/Loader';
import { LOCATION_OWNER_ANALYTICS } from '../../graphql/location-owner-audit.graphql';
import AverageAuditScore from './AverageAuditScore';
import LocationOwnerLineGraph from './LocationOwnerLineGraph';
import TotalFlags from './TotalFlags';

export interface GetAuditLocationsAnalyticsVariables {
  locationIds: string[];
  startDate?: string;
  endDate?: string;
}

export interface MonthAnalytics {
  start: string;
  end: string;
  totalScoreSum: number;
  maxScoreSum: number;
  totalFlagged: number;
}

export interface TotalFlagsResult {
  totalFlagged: number;
  maxFlagCount: number;
  flaggedPercentage: number;
}

export interface LocationAnalytics {
  [month: string]: MonthAnalytics;
}

export interface AuditLocationsAnalytics {
  [locationId: string]: LocationAnalytics;
}

export interface AnalyticsData {
  AuditLocationsAnalytics: AuditLocationsAnalytics;
}

const GraphSection: FC = () => {
  const { loggedInUserLocations, loggedInUserEid, loggedInUserType } =
    useUserDataSelector((state) => ({
      loggedInUserLocations: state?.locations,
      loggedInUserType: state?.type,
      loggedInUserEid: state?.eid,
    }));

  const [getAuditLocationsAnalytics, { data, loading, error }] = useLazyQuery<
    AnalyticsData,
    GetAuditLocationsAnalyticsVariables
  >(LOCATION_OWNER_ANALYTICS, {
    fetchPolicy: 'network-only',
  });

  const loggedInUserLocationsIds = useMemo(() => {
    return loggedInUserType === 'branch'
      ? [loggedInUserEid]
      : loggedInUserLocations?.map((loc) => loc?.eid);
  }, [loggedInUserLocations]);

  useEffect(() => {
    getAuditLocationsAnalytics({
      variables: {
        locationIds: loggedInUserLocationsIds || [],
        startDate: moment().startOf('year').toISOString(),
        endDate: moment().endOf('year').toISOString(),
      },
    });
  }, [loggedInUserLocationsIds]);

  return (
    <Flex w='full' direction='column' gap={4}>
      {/* Graphs Section */}
      {loading ? (
        <Center h='260px' w='full'>
          <Loader />
        </Center>
      ) : (
        <Box overflowX='auto' w='full'>
          <Flex
            w='full'
            gap={4}
            px={2} // Optional: Add some horizontal padding
          >
            {/* Main Line Graph */}
            <Flex
              flex='1' // Makes the main graph container flexible
              //   w={{ base: '300px', md: '600px', lg: '600px' }}
              width={['100%', '75%', '50%', '25%']}
              border='0.5px solid rgba(199, 199, 199, 1)'
              borderRadius='10px'
              p={4}
              flexDir='column'
            >
              <LocationOwnerLineGraph />
            </Flex>

            {/* Secondary Graphs */}
            <Flex
              flexShrink={0} // Prevents shrinking below the fixed width
              w='280px' // Fixed width
              flexDir='column'
              gap={4}
            >
              {/* Average Audit Score */}
              <Flex
                border='0.5px solid rgba(199, 199, 199, 1)'
                borderRadius='12px'
                p='20px'
                h='240px'
              >
                <AverageAuditScore data={data} />
              </Flex>

              {/* Total Flags */}
              <Flex
                border='0.5px solid rgba(199, 199, 199, 1)'
                borderRadius='12px'
                p='20px'
                h='240px'
              >
                <TotalFlags data={data} />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}

      {/* Optional: Additional Sections */}
      {/* <UpcomingAuditSection auditData={auditData} />
      <AuditResultSection auditData={auditData} /> */}
    </Flex>
  );
};

export default GraphSection;
