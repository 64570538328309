import {
  AuditSessionEntity,
  AuditStatusFunctionEntity,
  ProcessData,
} from '../types';
import moment from 'moment-timezone';
import { AuditQuestionEntity } from 'types';
import { getAuditRepeatStatus } from 'sub-components/audits/common';

export const auditStatus = (
  /** `isAllCompleted`: Has all the assigned locations completed the audit? */
  isAllCompleted: boolean,
  /** `auditDueDate`: Due date for the audit */
  auditDueDate: string | undefined,
  /** `isAuditStarted`: Has audit started for some location? */
  isAuditStarted: boolean
): AuditStatusFunctionEntity => {
  let status: AuditStatusFunctionEntity = undefined;
  undefined;
  if (isAllCompleted) {
    status = 'completed';
    return status;
  }

  // No Deadline
  if (!auditDueDate) {
    if (isAuditStarted) {
      status = 'ongoing';
      return status;
    } else {
      return 'upcoming';
    }
  }

  // Overdue
  if (
    auditDueDate &&
    moment(auditDueDate)?.isValid() &&
    moment.utc(auditDueDate)?.isBefore(moment())
  ) {
    status = 'overdue';
    return status;
  }

  // Ongoing
  if (isAuditStarted) {
    status = 'ongoing';
    return status;
  }

  status = 'upcoming';
  return status;
};

export const checkAuditScore = (questions: AuditQuestionEntity[]) => {
  return questions?.reduce((acc, assignment) => {
    return acc + (assignment?.score || 0);
  }, 0);
};

export const processData = (data: AuditSessionEntity[]): ProcessData[] => {
  let processedDataArr: ProcessData[] = [];

  data
    ?.filter((data) => data?.audit?.status !== 'AUDIT_DELETED')
    ?.map((auditData) => {
      let isAllCompleted = auditData?.isCompleted;
      let hasAuditStarted = auditData?.assignments?.some(
        (assignment) => assignment.startedBy
      );
      let status = auditStatus(
        isAllCompleted,
        auditData?.dueDate,
        hasAuditStarted
      );
      let isScoredAudit = checkAuditScore(auditData?.questions);

      if (status === 'completed') {
        let maxScore = auditData?.assignments?.reduce((acc, assignment) => {
          return acc + (assignment?.maxScore || 0);
        }, 0);
        let baggedScore = auditData?.assignments?.reduce((acc, assignment) => {
          return acc + (assignment?.totalScore || 0);
        }, 0);
        let percent = ((baggedScore || 0) / (maxScore || 0)) * 100 || 0;
        return processedDataArr?.push({
          ...auditData,
          auditStatus: 'completed',
          repeatStatus: getAuditRepeatStatus(auditData?.repeatDetails),
          isScoredAudit: !!isScoredAudit,
          totalScore: baggedScore,
          scorePercent: percent,
        });
      }

      // Overdue
      if (status === 'overdue') {
        return processedDataArr?.push({
          ...auditData,
          auditStatus: 'overdue',
          isScoredAudit: !!isScoredAudit,
          repeatStatus: getAuditRepeatStatus(auditData?.repeatDetails),
        });
      }

      // Ongoing
      if (status === 'ongoing') {
        return processedDataArr?.push({
          ...auditData,
          auditStatus: 'ongoing',
          isScoredAudit: !!isScoredAudit,
          repeatStatus: getAuditRepeatStatus(auditData?.repeatDetails),
        });
      }

      return processedDataArr?.push({
        ...auditData,
        auditStatus: 'upcoming',
        isScoredAudit: !!isScoredAudit,
        repeatStatus: getAuditRepeatStatus(auditData?.repeatDetails),
      });
    });

  return processedDataArr;
};
