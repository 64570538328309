import React, { FC, useEffect } from 'react';
import { Box, Flex, Tooltip, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  errors: any;
}

const LabelContent: FC<IProps> = ({ errors }) => {
  return (
    <Flex flexDir='column' fontWeight='500' color='#272B30' gap={1}>
      <Box color='#FFA43D'>Missing requirements</Box>
      {Object.values(errors).map((error, i) => (
        <Flex key={i} align='center' gap='6px'>
          <Box as='span' boxSize='4px' borderRadius='full' bg='#272B30' />
          {/*// @ts-ignore */}
          <span>{error?.message}</span>
        </Flex>
      ))}
    </Flex>
  );
};

const ErrorContent: FC<IProps> = ({ errors }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isEmpty(errors)) {
      onOpen();
      setTimeout(onClose, 3000);
    }
  }, [errors]);

  if (isEmpty(errors)) {
    return null;
  }
  return (
    <Tooltip
      hasArrow
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      label={<LabelContent errors={errors} />}
      placement='bottom-start'
      colorScheme='red'
      backgroundColor='#FCF1E5'
      borderRadius='4px'
      padding='16px'
    >
      <Box>
        <FontAwesomeIcon
          icon={faCircleExclamation as IconProp}
          color='#FFA43D'
          fontSize='22px'
        />
      </Box>
    </Tooltip>
  );
};

export default ErrorContent;
