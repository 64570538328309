import { gql } from '@apollo/client';
import { Box, Center, Image } from '@chakra-ui/react';
import { AuditStatus } from '../../../../types';
// @ts-ignore
import trashImage from '../../../../assets/images/trash-red.svg';

const PAUSE_AUDIT = gql`
  mutation PauseAudit($eid: String!) {
    PauseAudit(eid: $eid) {
      eid
      status
    }
  }
`;
const RESUME_AUDIT = gql`
  mutation RestartAudit($eid: String!) {
    RestartAudit(eid: $eid) {
      eid
      status
    }
  }
`;

const DELETE_AUDIT = gql`
  mutation DeleteAudit($eid: String!) {
    DeleteAudit(eid: $eid) {
      eid
      status
    }
  }
`;

export type AuditAction = 'delete' | 'resume' | 'pause';

interface ActionResponse {
  eid: string;
  status: AuditStatus;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAuditAction = <T extends ActionResponse>(
  action: AuditAction
) => {
  switch (action) {
    case 'pause':
      return {
        mutation: PAUSE_AUDIT,
        parseData: (data: Record<string, T>): T => data.PauseAudit,
        successMessage: 'The audit has been successfully paused.',
        errorMessage: 'The audit could not be paused. Please try again later.',
        modalProps: {
          title: 'Pause audit?',
          content: (
            <Box fontSize='15px' fontWeight='600' color='#6F767E'>
              Pausing the audit will temporarily make this audit inactive until
              it is resumed manually. Are you sure you want to pause audit?
            </Box>
          ),
          width: '620px',
          buttonText: 'Pause',
        },
      };
    case 'resume':
      return {
        mutation: RESUME_AUDIT,
        parseData: (data: Record<string, T>): T => data.RestartAudit,
        successMessage: 'The audit has been successfully resumed.',
        errorMessage: 'The audit could not be resumed. Please try again later.',
        modalProps: {
          title: 'Resume audit?',
          content: (
            <Box fontSize='15px' fontWeight='600' color='#6F767E'>
              Are you sure you want to resume this audit?
            </Box>
          ),
          width: '620px',
          buttonText: 'Resume',
        },
      };
    case 'delete':
      return {
        mutation: DELETE_AUDIT,
        parseData: (data: Record<string, T>): T => data.DeleteAudit,
        successMessage: 'The audit has been successfully deleted.',
        errorMessage: 'The audit could not be deleted. Please try again later.',
        modalProps: {
          title: 'Delete audit?',
          content: (
            <Center flexDir='column' gap={4} pb={2}>
              <Image
                src={trashImage}
                boxSize='56px'
                p='16px'
                bg='#FF6A5533'
                borderRadius='full'
              />
              <Box color='#33383F' textAlign='center' paddingX={3}>
                Deleting an audit will <strong>permanently stop</strong> the
                audit and cannot be restarted. The audit history can still be
                viewed in the <strong>archives</strong>. <br /> Are you sure you
                want to delete audit?
              </Box>
            </Center>
          ),
          width: '540px',
          buttonText: 'Delete',
        },
      };
    default:
      throw new Error('Unsupported action');
  }
};
