import React from 'react';
import { Flex, forwardRef, Text, TextProps } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ICommonLabel = TextProps & {
  label: string;
};

/** Custom Text wrapper based on Chakra UI Text Props
 * Default values are
 * - fontWeight: 400
 * - fontSize: 15px
 * - color: #6F767E
 */
const CommonLabel = forwardRef<ICommonLabel, 'p'>((props, ref) => {
  const {
    label,
    fontWeight = 400,
    fontSize = '15px',
    color = '#6F767E',
    ...rest
  } = props;
  return (
    <Flex alignItems={'center'} gap={2}>
      {props?.['aria-label'] && (
        <FontAwesomeIcon icon={faCircleCheck as IconProp} />
      )}
      <Text
        ref={ref}
        fontWeight={fontWeight}
        fontSize={fontSize}
        color={color}
        {...rest}
      >
        {label}
      </Text>
    </Flex>
  );
});

export default CommonLabel;
