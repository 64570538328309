import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Center, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import TableActionMenu from 'ui-components/TableActionMenu';
import FormTemplate from '../../../../sub-components/forms/FormTemplate';

import QuizIcon from '../../../../assets/images/quizSvg.svg';

import { Authorize, AuthRole } from 'authorization';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';

const AddFormButton = () => {
  const { t } = useTranslation('form');
  const history = useHistory();

  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  const clickedItemsHandler = (clickedItem: string) => {
    if (clickedItem === 'scratch') {
      // deployEvent(
      //   AmplitudeEventNames.FORMS_ADD_NEW_FORM_BUTTON_FROM_SCRATCH_CLICK
      // );
      deployEvent(AmplitudeEventNames?.CREATE_FROM_SCRATCH_FORM, {
        form_id: '',
        create_form_funnel_id: 5,
      });
      history.push('/forms/create');
    } else {
      // deployEvent(AmplitudeEventNames.FORMS_ADD_NEW_FORM_BUTTON_TEMPLATE_CLICK);
      deployEvent(AmplitudeEventNames?.USE_EXISTING_TEMPLATE_FORM, {
        form_id: '',
        from_where: 'Create from Scratch form page',
        create_form_funnel_id: 5,
        edit_form_funnel_id: 3,
      });
      setOpenTemplateModal(true);
    }
  };

  return (
    <>
      <TableActionMenu
        menuData={[
          {
            name: t('create_scratch'),
            value: 'scratch',
            icon: <AddIcon w={5} h={5} color='#6F767E' marginRight='14px' />,
            normalIcon: 'chakraUIIcon',
            textColor: '#6F767E',
          },
          {
            name: t('create_template'),
            value: 'template',
            icon: QuizIcon,
            textColor: '#6F767E',
          },
        ]}
        arrowSize={10}
        closeOnBlur={true}
        offset={[0, 10]}
        clickedItem={(_, clickedItem) => clickedItemsHandler(clickedItem)}
      >
        <Center
          style={{
            width: '11.438rem',
            height: '2.625rem',
            backgroundColor: '#2a85ff',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '7px',
            fontWeight: 'bold',
            fontSize: '15px',
          }}
        >
          <FontAwesomeIcon
            icon={faPlus as IconProp}
            style={{ fontSize: 14, marginRight: 10 }}
            color={'white'}
          />
          {t('delightree_forms')}
        </Center>
      </TableActionMenu>
      {openTemplateModal && (
        <FormTemplate
          isOpen={openTemplateModal}
          onClose={() => setOpenTemplateModal(false)}
        />
      )}
    </>
  );
};

const FormHeader: FC = () => {
  const { t } = useTranslation('form');
  return (
    <Flex width='full' justify='space-between'>
      <div style={{ width: '50%', fontSize: '28px', fontWeight: '700' }}>
        {t('forms')}
      </div>
      <Authorize
        permittedFor={'user'}
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
      >
        <AddFormButton />
      </Authorize>
    </Flex>
  );
};

export default FormHeader;
