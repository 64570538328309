import moment from 'moment-timezone';
import { defaultCSTTimezone } from 'sub-components/tasks/create-task/validation/helper';

export const formatDateTime = (timestamp: string, timeZone?: any) => {
  let selectedTimeZone = timeZone || defaultCSTTimezone;

  const date = moment(timestamp).tz(selectedTimeZone).format('DD MMM YYYY');
  const time = moment(timestamp).tz(selectedTimeZone).format('hh:mm A');

  return {
    dateString: date,
    timeString: time,
  };
};
