import React, { FC, useMemo } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, useControllableState } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';

import { Dropdown, SelectOption } from 'atoms';
import { useUserEntity } from 'hooks';
import { toArray } from '../../../../../utils';
import OptionItem from './OptionItem';

const otherProps = {
  isMulti: true,
  hideSelectedOptions: false,
  controlShouldRenderValue: false,
  isClearable: false,
  backspaceRemovesValue: false,
  closeMenuOnSelect: false,
};

interface IProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
  isReadOnly?: boolean;
}

const Location: FC<IProps> = ({ value, onChange, onBlur, isReadOnly }) => {
  const locations = useUserEntity((entity) => entity?.locations || []);

  const [internalValue, updateValue] = useControllableState<string[]>({
    value: value,
    onChange: onChange,
  });

  const options = useMemo((): SelectOption[] => {
    return locations
      .filter((loc) => loc.locationStatus !== 'preLaunch')
      .map((option) => {
        return {
          label: option?.name,
          value: option?.eid,
        };
      });
  }, [locations]);

  const foundOption = useMemo(() => {
    return options?.filter((op) => internalValue?.includes(op?.value));
  }, [internalValue, options]);

  const remove = (op: SelectOption) => {
    updateValue?.(internalValue?.filter((val) => val !== op.value));
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Dropdown
        size='lg'
        {...otherProps}
        options={options}
        value={foundOption as never}
        onChange={(newValue) =>
          updateValue?.(toArray(newValue).map((v) => v.value))
        }
        onMenuClose={onBlur}
        isReadOnly={isReadOnly}
        placeholder='Select location'
        inputStyle={{
          borderWidth: '2px',
          borderRadius: '12px',
          outline: 'none',
          backgroundColor: 'white',
        }}
        selectStyles={{
          option: {
            borderRadius: '8px',
            padding: 'unset',
          },
          menu: {
            zIndex: 3,
          },
          menuList: {
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
          },
        }}
        components={{
          Option: (optionProps) => {
            return (
              <chakraComponents.Option {...optionProps} isSelected={false}>
                {/*// @ts-ignore */}
                <OptionItem
                  {...optionProps.data}
                  isSelected={optionProps.isSelected}
                />
              </chakraComponents.Option>
            );
          },
        }}
      />

      {!!foundOption?.length && (
        <Flex gap={2} flexWrap='wrap'>
          {foundOption?.map((op) => (
            <Flex
              key={op.value}
              bg='#B5E4CA'
              padding='4px 8px'
              borderRadius='4px'
              align='center'
              gap='6px'
              fontWeight='500'
              onClick={() => remove(op)}
            >
              <Flex
                bg='#83BF6E'
                align='center'
                justify='center'
                boxSize='20px'
                borderRadius='4px'
              >
                <FontAwesomeIcon
                  icon={faLocationDot as IconProp}
                  color='white'
                />
              </Flex>
              {op.label}
              <CloseIcon boxSize='10px' cursor='pointer' color='#6F767E' />
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default Location;
