import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { AuthUser, Permission } from './authorization.types';
import { checkPermission } from './checkPermission';

const DEFAULT_VAL = '===AUTHORIZE===' as never;

export type AuthFunction = <T>(
  permission: Permission,
  permittedValue: T,
  restrictedValue: T
) => T extends boolean ? boolean : T;

const useAuthorization = (rootPermission: Permission): AuthFunction => {
  const authUser = useReactiveVar(userObj) as AuthUser;

  const returnValue = <T>(value: T) => {
    if (typeof value === 'function') {
      return value();
    }
    return value;
  };

  return useCallback<AuthFunction>(
    (
      permission,
      permittedValue = DEFAULT_VAL,
      restrictedValue = DEFAULT_VAL
    ) => {
      const {
        permittedRoles,
        restrictedRoles,
        canAccess,
        restrictedFor,
        permittedFor,
      } = { ...rootPermission, ...permission };

      if (
        !permittedRoles &&
        !restrictedRoles &&
        !canAccess &&
        !permittedFor &&
        !restrictedFor
      ) {
        if (permittedValue !== DEFAULT_VAL) {
          return returnValue(permittedValue);
        }
        return true as boolean;
      }

      const permitted = checkPermission(authUser, {
        permittedRoles: permittedRoles || [],
        restrictedRoles: restrictedRoles || [],
        permittedFor: permittedFor,
        restrictedFor: restrictedFor,
      });

      if (permitted && permittedValue !== DEFAULT_VAL) {
        return permittedValue !== DEFAULT_VAL
          ? returnValue(permittedValue)
          : true;
      } else {
        if (canAccess && canAccess(authUser)) {
          return permittedValue !== DEFAULT_VAL
            ? returnValue(permittedValue)
            : true;
        }
        return restrictedValue !== DEFAULT_VAL
          ? returnValue(restrictedValue)
          : false;
      }
    },
    [rootPermission, authUser]
  );
};

export { useAuthorization };
