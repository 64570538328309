import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Any } from '../types';

export const useSearchParam = <
  Params extends { [k in keyof Params]?: string } = Any
>(): Params => {
  const location = useLocation();
  return queryString.parse(location.search) as Params;
};
