import { Box, Flex, useToast } from '@chakra-ui/react';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import DashboardContainer from 'sub-components/DashboardContainer';
import {
  UserDetailsComponent,
  UserDetailsRef,
} from 'sub-components/UserLocationDetails';
import Loader from 'ui-components/Loader';
import CategoryModal, { CategoryRef } from './CategoryModal/CategoryModal';
import ChapterEditorContainer from './ChapterEditorContainer';
import DiscardPrompt from './DiscardPrompt';
import Heading from './Heading';
import PreviewModal from './PreviewModal';
import TrainingPathFlowModal from './TrainingPathFlowModal/TrainingPathFlowModal';
import { useChapterEditorLogic } from './hooks/useChapterEditorLogic';
import VisibilityOperationModal from './VisibilityOperationModal';
import { useSubmitForm } from 'sub-components/forms';
import { IChapterInterface } from './chapter.types';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  IBaseInput,
  ISaveChapterData,
  IShowState,
  LocationState,
} from './hooks/types';
import { IFormInput } from './chaptor.types';
import { defaultContent } from './defaultData';
import {
  anyChange,
  fetchData,
  initialChapterData,
  removeTrailingEmptyParagraphs,
} from './helper';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { useUserData, useUserDataSelector } from 'hooks';
import {
  FetchResult,
  useLazyQuery,
  useMutation,
  useReactiveVar,
} from '@apollo/client';
import { loginUserBranchRootObj } from 'sub-components/Header';
import {
  locationUserRoleHelper,
  rolesListSetHandler,
  selectedDataSetHandler,
} from 'pages/Chapters/EditChapterModal/helper';
import { IEmojiStructure, useReadOnlyEditor } from 'delightree-editor';
// import { useCategoryList } from './CategoryModal/useCategoryList';
import {
  IConditionType,
  IType,
  IVisibilityEntity,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { UpdateChapterVisibility } from './chapter-editor.graphql';
import {
  GET_CHAPTER_BY_ID,
  GET_FOLDERS_LIST,
} from 'pages/Chapters/chapters.graphql';
import {
  AddChapterResponse,
  AddChapterVariable,
  ADD_CHAPTER,
  SmartCardVariable,
  SMART_CARD_UPDATE,
  UpdateChapterResponse,
  UpdateChapterVariable,
  UPDATE_CHAPTER,
} from './ChapterEditorContainer/chapter-editor.graphql';
import { dataConverter, smartCardGenerate } from './SmartPages';
import { CategoryEntity } from 'sop-commons/src/client';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import useCombinedStore from 'zustandStore/store';
import { getQueryParamValue } from 'utils/queryParams';
import { SelectOption } from 'atoms';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { AuthRole } from 'authorization';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ChapterEditor: FC = () => {
  // const _p = useChapterEditorLogic();
  // const { allowNavigation } = useBlockNavigation();
  const statusValue = getQueryParamValue('status');
  const {
    hierarchyData,
    visibilitySelection,
    updateHierarchyData,
    updateVisibilitySelection,
  } = useCombinedStore();

  console.log({ hierarchyData, statusValue });

  const useDetailRef = useRef<UserDetailsRef>(null);
  const submitForm = useSubmitForm();
  const {
    selectedData,
    updateSelectedData,
    updateVisibilityScope,
    updateOperationType,
  } = useCombinedStore();
  const loggedInUserData = useUserDataSelector((state) => ({
    eid: state?.eid,
    authRole: state?.authRole,
    locactions: state?.locations,
  }));
  const [initialLoad, setInitialLoad] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allowNavigation, setAllowNavigation] = useState(false);
  const [showShareDrawer, setShowShareDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [smartPageEnabled, setSmartPageEnabled] = useState<boolean>(false);
  // `notifyUsers` is used to store value of boolean value to notify users or not when updating the chapter. It has nothing to do when creating the chapter
  const [notifyUsers, setNotifyUsers] = useState(false);
  const [editorData, setEditorData] = useState<IChapterInterface>({
    SopById: undefined,
  });
  const [returnedChapterId, setReturnedChapterId] = useState('');
  const [showTrainingPathFlowModal, setShowTrainingPathFlowModal] =
    useState(false);
  const locationState = useLocation<LocationState>().state;
  const [chapterSaveData, setChapterSaveData] = useState<ISaveChapterData>({
    savedOnce: false,
    changeMade: false,
    returnedChapterId: '',
  });
  const methods = useForm<IFormInput>({
    defaultValues: {
      title: '',
      content: defaultContent,
      smartPage: false,
      formIds: [],
      thumbnail: '',
    },
  });
  const contentWatch = useWatch<IFormInput, 'content'>({
    control: methods.control,
    name: 'content',
  });
  const titleWatch = useWatch<IFormInput, 'title'>({
    control: methods.control,
    name: 'title',
  });
  const categoryWatch = useWatch<IFormInput, 'category'>({
    control: methods.control,
    name: 'category',
  });
  const subFolderWatch = useWatch<IFormInput, 'subFolder'>({
    control: methods.control,
    name: 'subFolder',
  });
  const dashboardCtx = useContext(DashboardDataContext);
  const userData = useUserData();
  const foldersListData = useReactiveVar(foldersListVar);
  const toast = useToast({ position: 'top-right', duration: 3000 });
  const history = useHistory();
  const [saveChapterTemp, setSaveChapterTemp] = useState(false);
  const [showState, setShowState] = useState<IShowState>({
    showFolder: false,
    showVisibility: false,
    showTrainingPathFlowModal: false,
  });
  const [membersList, setMembersList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [rolesList, setRolesList] = useState<any[]>([]);
  const userBranchRoot = useReactiveVar(loginUserBranchRootObj);

  const useCategoryList = (): SelectOption[] => {
    const categories = foldersListData;
    return useMemo(() => {
      return categories.map((category) => {
        return {
          eid: category.eid,
          value: category.name,
          label: category.name,
          visibility: {
            visibleTo: category.visibleTo,
            visibility: category.visibility,
          },
        };
      });
    }, [foldersListData]);
  };

  const [getFoldersList] = useLazyQuery<
    { EntityCategories: CategoryEntity[] },
    never
  >(GET_FOLDERS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      foldersListVar(data?.EntityCategories || []);
    },
  });

  useEffect(() => {
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    if (userBranchRoot) {
      let _data = JSON.parse(JSON.stringify({ branch: userBranchRoot }));
      locationUserRoleHelper(
        _data,
        userData,
        setMembersList,
        setLocationsList,
        selectedData,
        updateSelectedData
      );
    }
  }, [userBranchRoot]);

  useEffect(() => {
    rolesListSetHandler(userData, setRolesList);
    getFoldersList();
  }, [userData]);

  useEffect(() => {
    selectedDataSetHandler(
      membersList,
      locationsList,
      visibilitySelection!,
      rolesList,
      updateSelectedData
    );
  }, [locationsList, membersList, visibilitySelection]);
  const [selectedEmoji, setSelectedEmoji] = useState<IEmojiStructure>({
    emoticons: [],
    id: '',
    keywords: [],
    name: '',
    native: '',
    shortcodes: '',
    unified: '',
  });
  const { id } = useParams<{ id?: string }>();
  const isEditMode = !!id;
  const readOnlyEditor = useReadOnlyEditor();
  const categoryList = useCategoryList();
  const getJSONWithoutTrainingEmptyParagraphs = () => {
    return removeTrailingEmptyParagraphs(methods?.getValues('content'));
  };
  const extractFormIds = (node: any) => {
    let ids: string[] = [];
    if (node?.type === 'form') {
      ids.push(node?.attrs?.eid);
    }
    if (node?.content) {
      node?.content?.forEach((child: any) => {
        ids = ids.concat(extractFormIds(child));
      });
    }
    return ids;
  };
  const getBaseInput = (): IBaseInput => {
    const jsonWithoutTrailingEmptyParagraphs =
      getJSONWithoutTrainingEmptyParagraphs();
    const formIds = extractFormIds(jsonWithoutTrailingEmptyParagraphs);
    if (Array.isArray(formIds) && formIds?.length > 0) {
      methods!.setValue('formIds', formIds);
    }
    const editor = readOnlyEditor({
      content: jsonWithoutTrailingEmptyParagraphs,
    });
    let input: IBaseInput = {
      attachedForms: formIds,
      categoryId:
        methods?.getValues('subFolder.eid') ||
        methods?.getValues('category.eid')!,
      content: [
        {
          attrs: {},
          text: methods?.getValues('title')!,
          type: 'tiptap',
          tiptap: jsonWithoutTrailingEmptyParagraphs,
        },
      ],
      icon: selectedEmoji,
      isMultiPage: editor.getHTML().includes('<hr>'),
      raw_content: editor.getText(),
      smartPageEnabled: methods?.getValues('smartPage') || false,
      thumbnail: '',
      title: methods?.getValues('title')!,
    };
    console.log({ baseInput: input });
    return input;
  };
  useEffect(() => {
    if (isEditMode) {
      getChapterDetails();
    }
  }, [isEditMode]);

  const getChapterDetails = () => {
    getChapterDetailsHandler({
      variables: {
        eid: id,
      },
    });
  };
  const publishHandler = () => {
    deployEvent(AmplitudeEventNames.PUBLISH_CLICK, {
      method: getOperationMethod(),
      chapter_Id: id || '',
      add_chapter_funnel_id: 5,
      edit_chapter_funnel_id: 4,
    });
    setSaveChapterTemp(false);
    if (
      (isEditMode && editorData?.SopById?.status === 'active') ||
      statusValue === 'RESTRICT'
    ) {
      setShowState({
        showFolder: false,
        showVisibility: true,
        showTrainingPathFlowModal: false,
      });
    } else {
      setShowState({
        showFolder: true,
        showVisibility: false,
        showTrainingPathFlowModal: false,
      });
    }
    // setShowModal(true);
  };
  const saveTemporaryFn = () => {
    deployEvent(AmplitudeEventNames.SAVE_CLICK, {
      add_chapter_funnel_id: 5,
      method: getOperationMethod(),
      edit_chapter_funnel_id: 4,
      chapter_Id: id || '',
    });
    setSaveChapterTemp(true);
    if (statusValue === 'RESTRICT') {
      saveTemporaryHandler();
    } else {
      setShowState({
        showFolder: true,
        showTrainingPathFlowModal: false,
        showVisibility: false,
      });
    }
  };
  const saveVisibilityHandler = () => {
    saveChapterVisibility({
      variables: {
        input: {
          condition: visibilitySelection?.condition,
          locations: visibilitySelection?.locations,
          roles: visibilitySelection?.roles,
          sopId: returnedChapterId,
          users: visibilitySelection?.users,
          visibility: visibilitySelection?.visibility,
        },
      },
    });
  };
  useEffect(() => {
    if (isEditMode || statusValue === 'RESTRICT') return;
    let foundParentFolder = foldersListData?.find(
      (folder) => folder?.eid === categoryWatch?.eid
    );
    let foundChildFolder = foundParentFolder?.children?.find(
      (child) => child?.eid === subFolderWatch?.eid
    );
    console.log({ CHAPTER_EDITOR: { foundParentFolder, foundChildFolder } });
    if (foundParentFolder && foundChildFolder) {
      updateHierarchyData({
        ...hierarchyData,
        parentFolder: {
          details: { ...foundParentFolder, type: 'folder' },
          visibilityData: {
            locations: foundParentFolder?.visibleTo?.locations,
            roles: foundParentFolder?.visibleTo?.roles,
            users: foundParentFolder?.visibleTo?.users,
            condition: foundParentFolder?.visibleTo
              ?.condition as IConditionType,
            visibility: foundParentFolder?.visibility,
          },
        },
        subFolder: {
          details: { ...foundChildFolder, type: 'subFolder' },
          visibilityData: {
            locations: foundChildFolder?.visibleTo?.locations,
            roles: foundChildFolder?.visibleTo?.roles,
            users: foundChildFolder?.visibleTo?.users,
            condition: foundChildFolder?.visibleTo?.condition as IConditionType,
            visibility: foundChildFolder?.visibility,
          },
        },
        ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
          ? {
              chapter: {
                details: {} as any,
                visibilityData: {
                  locations:
                    loggedInUserData?.locactions?.map((loc) => loc?.eid) || [],
                  roles: [],
                  users: [],
                  condition: 'and',
                  visibility: 'private',
                },
              },
            }
          : {}),
      });
    } else if (foundParentFolder) {
      updateHierarchyData({
        ...hierarchyData,
        parentFolder: {
          details: { ...foundParentFolder, type: 'folder' },
          visibilityData: {
            locations: foundParentFolder?.visibleTo?.locations,
            roles: foundParentFolder?.visibleTo?.roles,
            users: foundParentFolder?.visibleTo?.users,
            condition: foundParentFolder?.visibleTo
              ?.condition as IConditionType,
            visibility: foundParentFolder?.visibility,
          },
        },
        ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
          ? {
              chapter: {
                details: {} as any,
                visibilityData: {
                  locations:
                    loggedInUserData?.locactions?.map((loc) => loc?.eid) || [],
                  roles: [],
                  users: [],
                  condition: 'and',
                  visibility: 'private',
                },
              },
            }
          : {}),
      });
    }
    if (subFolderWatch?.eid) {
      // let _categoryList = userData?.entity?.category || [];
      let _categoryList = foldersListData;
      let allSubFolders = _categoryList
        ? _categoryList.flatMap((entity) => entity.children || [])
        : [];
      let foundCategory = allSubFolders?.filter(
        (cat) => cat?.eid === subFolderWatch?.eid
      )?.[0];
      if (!foundCategory) return;
      let foundObj = {
        locations: foundCategory?.visibleTo?.locations,
        roles: foundCategory?.visibleTo?.roles,
        users: foundCategory?.visibleTo?.users,
        condition: foundCategory?.visibleTo?.condition as IConditionType,
        visibility: foundCategory?.visibility,
      };
      if (loggedInUserData?.authRole !== AuthRole.LOCATION_OWNER) {
        updateVisibilitySelection(foundObj);
      } else {
        updateVisibilitySelection({
          locations: loggedInUserData?.locactions?.map((loc) => loc?.eid) || [],
          roles: [],
          users: [],
          condition: 'and',
          visibility: 'private',
        });
      }
    } else if (categoryWatch?.eid) {
      let foundCategory = categoryList?.filter(
        (cat) => cat?.eid === categoryWatch?.eid
      )?.[0];
      if (!foundCategory) return;
      let foundObj = {
        locations: foundCategory?.visibility?.visibleTo?.locations,
        roles: foundCategory?.visibility?.visibleTo?.roles,
        users: foundCategory?.visibility?.visibleTo?.users,
        condition: foundCategory?.visibility?.visibleTo?.condition,
        visibility: foundCategory?.visibility?.visibility,
      };
      if (loggedInUserData?.authRole !== AuthRole.LOCATION_OWNER) {
        updateVisibilitySelection(foundObj);
      } else {
        updateVisibilitySelection({
          locations: loggedInUserData?.locactions?.map((loc) => loc?.eid) || [],
          roles: [],
          users: [],
          condition: 'and',
          visibility: 'private',
        });
      }
    }
  }, [categoryList, categoryWatch, subFolderWatch, statusValue, showState]);

  useEffect(() => {
    if (subFolderWatch?.value) {
      if (saveChapterTemp) {
        deployEvent(AmplitudeEventNames.SAVE_SUBFOLDER_SELECT, {
          folder_name: subFolderWatch?.label,
          method: getOperationMethod(),
          chapter_Id: id || '',
          add_chapter_funnel_id: 5,
          edit_chapter_funnel_id: 4,
        });
      } else {
        deployEvent(AmplitudeEventNames.PUBLISH_SUBFOLDER_SELECT, {
          folder_name: subFolderWatch?.label,
          method: getOperationMethod(),
          chapter_Id: id || '',
          add_chapter_funnel_id: 5,
        });
      }
    }
  }, [subFolderWatch, saveChapterTemp]);

  useEffect(() => {
    if (statusValue === 'RESTRICT') {
      if (
        hierarchyData?.parentFolder?.details?.eid &&
        hierarchyData?.subFolder?.details?.eid
      ) {
        let foundObj = {
          locations: hierarchyData?.subFolder?.details?.visibleTo?.locations,
          roles: hierarchyData?.subFolder?.details?.visibleTo?.roles,
          users: hierarchyData?.subFolder?.details?.visibleTo?.users,
          condition: hierarchyData?.subFolder?.details?.visibleTo
            ?.condition as IConditionType,
          visibility: hierarchyData?.subFolder?.details?.visibility,
        };
        updateVisibilitySelection(foundObj);
        let _foundCategory = {
          label: hierarchyData?.subFolder?.details?.name,
          value: hierarchyData?.subFolder?.details?.eid,
          ...hierarchyData?.subFolder?.details,
        };
        methods.setValue('category', _foundCategory);
      } else if (hierarchyData?.parentFolder?.details?.eid) {
        let foundObj = {
          locations: hierarchyData?.parentFolder?.details?.visibleTo?.locations,
          roles: hierarchyData?.parentFolder?.details?.visibleTo?.roles,
          users: hierarchyData?.parentFolder?.details?.visibleTo?.users,
          condition: hierarchyData?.parentFolder?.details?.visibleTo
            ?.condition as IConditionType,
          visibility: hierarchyData?.parentFolder?.details?.visibility,
        };
        let _foundCategory = {
          label: hierarchyData?.parentFolder?.details?.name,
          value: hierarchyData?.parentFolder?.details?.eid,
          ...hierarchyData?.parentFolder?.details,
        };
        methods.setValue('category', _foundCategory);
        updateVisibilitySelection(foundObj);
      }
    }
  }, [statusValue]);

  const resetNavigationPersistHandler = () => {
    let navigationData = dashboardCtx?.navigationPersistData;
    let knowledgeBaseData = dashboardCtx?.navigationPersistData?.knowledgeBase;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      knowledgeBase: { ...knowledgeBaseData, selectedPage: 1 },
    });
  };
  const [saveChapterVisibility] = useMutation(UpdateChapterVisibility, {
    onCompleted: (data) => {
      setAllowNavigation(true);
      resetNavigationPersistHandler();
      history.push('/folders');
    },
  });

  const [getChapterDetailsHandler, { loading, refetch }] =
    useLazyQuery<IChapterInterface>(GET_CHAPTER_BY_ID, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        loggedInUserData?.authRole === AuthRole.LOCATION_OWNER &&
          updateOperationType('edit');
        setSelectedEmoji(
          data?.SopById?.icon || {
            emoticons: [],
            id: '',
            keywords: [],
            name: '',
            native: '',
            shortcodes: '',
            unified: '',
          }
        );
        updateVisibilitySelection({
          locations: data?.SopById?.visibleTo?.locations || [],
          roles: data?.SopById?.visibleTo?.roles || [],
          users: data?.SopById?.visibleTo?.users || [],
          condition: data?.SopById?.visibleTo?.condition || '',
          visibility: (data?.SopById?.visibility || 'public') as IType,
        });
        if (statusValue === 'RESTRICT') {
          if (
            hierarchyData?.parentFolder?.details?.eid &&
            hierarchyData?.subFolder?.details?.eid
          ) {
            let _foundCategory = {
              label: hierarchyData?.subFolder?.details?.name,
              value: hierarchyData?.subFolder?.details?.eid,
              ...hierarchyData?.subFolder?.details,
            };
            methods.setValue('category', _foundCategory);
          } else if (hierarchyData?.parentFolder?.details?.eid) {
            let _foundCategory = {
              label: hierarchyData?.parentFolder?.details?.name,
              value: hierarchyData?.parentFolder?.details?.eid,
              ...hierarchyData?.parentFolder?.details,
            };
            methods.setValue('category', _foundCategory);
          }
        } else {
          let allFolders = userData?.entity?.category || [];
          let allSubFolders = userData?.entity?.category
            ? userData?.entity?.category.flatMap(
                (entity) => entity.children || []
              )
            : [];
          let foundCategory = [...allFolders, ...allSubFolders]?.filter(
            (f) => f?.eid === data?.SopById?.categoryId
          )?.[0];
          let _foundCategory = {
            label: foundCategory?.name,
            value: foundCategory?.eid,
            ...foundCategory,
          };
          methods.setValue('category', _foundCategory);
          if (foundCategory?.parent) {
            let parentFound = allFolders?.filter(
              (folder) => folder?.eid === foundCategory?.parent
            )?.[0];
            if (parentFound) {
              updateHierarchyData({
                ...hierarchyData,
                parentFolder: {
                  details: { ...parentFound, type: 'folder' },
                  visibilityData: {
                    locations: parentFound?.visibleTo?.locations,
                    roles: parentFound?.visibleTo?.roles,
                    users: parentFound?.visibleTo?.users,
                    condition: parentFound?.visibleTo
                      ?.condition as IConditionType,
                    visibility: parentFound?.visibility,
                  },
                },
                subFolder: {
                  details: { ..._foundCategory, type: 'subFolder' },
                  visibilityData: {
                    locations: _foundCategory?.visibleTo?.locations,
                    roles: _foundCategory?.visibleTo?.roles,
                    users: _foundCategory?.visibleTo?.users,
                    condition: _foundCategory?.visibleTo
                      ?.condition as IConditionType,
                    visibility: _foundCategory?.visibility,
                  },
                },
                ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
                  ? {
                      chapter: {
                        details: {} as any,
                        visibilityData: {
                          locations: data?.SopById?.visibleTo?.locations || [],
                          roles: data?.SopById?.visibleTo?.roles || [],
                          users: data?.SopById?.visibleTo?.users || [],
                          condition: 'and',
                          visibility: 'private',
                        },
                      },
                    }
                  : {}),
              });
            }
          } else {
            updateHierarchyData({
              ...hierarchyData,
              parentFolder: {
                details: { ..._foundCategory, type: 'folder' },
                visibilityData: {
                  locations: _foundCategory?.visibleTo?.locations,
                  roles: _foundCategory?.visibleTo?.roles,
                  users: _foundCategory?.visibleTo?.users,
                  condition: _foundCategory?.visibleTo
                    ?.condition as IConditionType,
                  visibility: _foundCategory?.visibility,
                },
              },
              ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
                ? {
                    chapter: {
                      details: {} as any,
                      visibilityData: {
                        locations:
                          loggedInUserData?.locactions?.map(
                            (loc) => loc?.eid
                          ) || [],
                        roles: [],
                        users: [],
                        condition: 'and',
                        visibility: 'private',
                      },
                    },
                  }
                : {}),
            });
          }
        }
        setNotifyUsers(data?.SopById?.notifyUsers || false);
        setEditorData(data);
      },
    });

  const refetchChapterDetailsHandler = () => {
    refetch?.();
  };

  const getOperationMethod = () => {
    return _query.get('training-path-flow')
      ? 'Training Path'
      : _query.get('fileUrl')
      ? 'Extract From PDF'
      : 'Create from Scratch';
  };

  const [publishChapter] = useMutation<AddChapterResponse, AddChapterVariable>(
    ADD_CHAPTER,
    {
      onCompleted: async (response) => {
        const sopData = response.addSop;
        toast({
          title: 'Chapter created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        deployEvent(AmplitudeEventNames.PUBLISH_CHAPTER, {
          notify_everyone: sopData?.notifyUsers,
          method: getOperationMethod(),
          folder_name: categoryWatch?.label || '',
          subfolder_name: subFolderWatch?.label || '',
          visibility: sopData?.visibility,
          chapter_Id: sopData?.eid,
          chapter_name: sopData?.title,
          add_chapter_funnel_id: 5,
        });
        setIsLoading(false);
        setReturnedChapterId(sopData?.eid);
        if (!(sopData.smartPageEnabled || sopData.isMultiPage)) {
          setAllowNavigation(true);
          setReturnedChapterId(sopData?.eid);
          if (!showTrainingPathFlowModal) {
            if (locationState?.backToTitle) {
              resetNavigationPersistHandler();
              history.goBack();
            } else {
              resetNavigationPersistHandler();
              history.push('/folders');
            }
          } else {
            deployEvent(AmplitudeEventNames.KNOWLEDGE_BASE_ADD, {
              chapter_Id: sopData?.eid,
              chapter_name: sopData?.title,
              training_path_id: '',
              add_new_path_funnel_id: 4,
              edit_training_funnel_id: 4,
            });
            setTimeout(() => {
              window.close();
            }, 5000);
          }
        }
      },
      onError: (error) => {
        setIsLoading(false);
        toast({
          title: 'Chapter could not be created',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  const [updateChapter] = useMutation<
    UpdateChapterResponse,
    UpdateChapterVariable
  >(UPDATE_CHAPTER, {
    onCompleted: (response) => {
      setIsLoading(false);
      const sopData = response.updateSop;
      toast({
        title: 'Chapter updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      deployEvent(AmplitudeEventNames.UPDATE_CHAPTER, {
        method: getOperationMethod(),
        folder_name: categoryWatch?.label,
        visibility: sopData?.visibility,
        chapter_Id: sopData?.eid,
        chapter_name: sopData?.title,
        edit_chapter_funnel_id: 4,
      });

      if (!(sopData.smartPageEnabled || sopData.isMultiPage)) {
        setAllowNavigation(true);
        if (locationState?.backToTitle) {
          resetNavigationPersistHandler();
          history.goBack();
        } else {
          resetNavigationPersistHandler();
          history.push('/folders');
        }
      }
    },
    onError: (error) => {
      setIsLoading(false);
      toast({
        title: 'Chapter could not be updated',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const [updateChapterTemp, { loading: chapterUpdateLoading }] = useMutation<
    UpdateChapterResponse,
    UpdateChapterVariable
  >(UPDATE_CHAPTER, {
    onCompleted: (response) => {
      setIsLoading(false);
      setChapterSaveData({
        ...chapterSaveData,
        savedOnce: true,
        returnedChapterId: response?.updateSop?.eid,
      });
      setEditorData({ SopById: response?.updateSop });
      toast({
        title: 'Chapter saved successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (error) => {
      setIsLoading(false);
      toast({
        title: 'Chapter could not be saved',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const [publishChapterTemp, { loading: chapterSaveLoading }] = useMutation<
    AddChapterResponse,
    AddChapterVariable
  >(ADD_CHAPTER, {
    onCompleted: (response) => {
      setChapterSaveData({
        ...chapterSaveData,
        savedOnce: true,
        returnedChapterId: response?.addSop?.eid,
      });
      setEditorData({ SopById: response?.addSop });
      toast({
        title: 'Chapter saved successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
      toast({
        title: 'Chapter could not be saved',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const saveTemporaryHandler = () => {
    //
    let baseInput = getBaseInput();
    let input: AddChapterVariable['input'] = {
      ...baseInput,
      notifyUsers: false,
      status: 'draft',
      visibleTo: {
        condition: undefined,
        locations: [],
        roles: [],
        users: [userData?.eid],
      },
      visibility: 'private',
    };
    console.log('input', input);
    deployEvent(AmplitudeEventNames.SAVE_SUBFOLDER_SELECT, {
      folder_name: subFolderWatch?.label,
      method: getOperationMethod(),
      chapter_Id: id || '',
      add_chapter_funnel_id: 5,
      edit_chapter_funnel_id: 4,
    });
    if (!isEditMode && !chapterSaveData?.returnedChapterId) {
      deployEvent(AmplitudeEventNames.SAVE_PRIVATE_CHAPTER_FROM_EDITOR);
      publishChapterTemp({
        variables: {
          input,
        },
      });
    } else {
      deployEvent(AmplitudeEventNames.UPDATE_PRIVATE_CHAPTER_FROM_EDITOR);
      updateChapterTemp({
        variables: {
          input: {
            ...input,
            eid: id || chapterSaveData?.returnedChapterId,
            languageCode: 'en',
          },
        },
      });
    }
  };
  const [updateSmartCard] = useMutation<never, SmartCardVariable>(
    SMART_CARD_UPDATE
  );
  const onPublishHandler = async (
    _notifyUsers: boolean,
    visibilityObject?: IVisibilityEntity
  ) => {
    let baseInput = getBaseInput();
    let input: AddChapterVariable['input'] = {
      ...baseInput,
      notifyUsers: _notifyUsers,
      status: 'active',
      visibleTo: {
        ...(visibilityObject?.condition &&
        visibilityObject?.locations?.length > 0 &&
        visibilityObject?.roles?.length > 0
          ? { condition: visibilityObject?.condition as never }
          : {}),
        locations: visibilityObject?.locations || [],
        roles: visibilityObject?.roles || [],
        users: visibilityObject?.users || [],
      },
      visibility: visibilityObject?.visibility || 'public',
    };

    let response: FetchResult<any> = {};
    setIsLoading(true);
    if (!editorData?.SopById?.category) {
      if (showTrainingPathFlowModal) {
        setShowState({
          ...showState,
          showFolder: false,
          showVisibility: false,
          showTrainingPathFlowModal: true,
        });
      }
      deployEvent(AmplitudeEventNames.CREATE_CHAPTER_FROM_EDITOR);
      response = await publishChapter({
        variables: {
          input,
        },
      });
    } else {
      deployEvent(AmplitudeEventNames.UPDATE_CHAPTER_FROM_EDITOR);
      response = await updateChapter({
        variables: {
          input: {
            ...input,
            eid: chapterSaveData?.returnedChapterId
              ? chapterSaveData?.returnedChapterId
              : id!,
            languageCode: 'en',
          },
        },
      });
    }
    const chapterId =
      response?.data?.addSop?.eid || response?.data?.updateSop?.eid;
    // if (getValues('smartPage') && chapterId) {
    setReturnedChapterId(chapterId);
    if ((input.smartPageEnabled || input.isMultiPage) && chapterId) {
      setIsLoading(true);
      const cardData = dataConverter(
        methods?.getValues('content') || {},
        input.smartPageEnabled
      );
      const smartCards = smartCardGenerate(cardData, readOnlyEditor);
      await updateSmartCard({
        variables: {
          input: {
            cards: smartCards,
            sop: chapterId,
            language: 'en',
          },
        },
      }).catch((err) => {
        console.log(err);
      });
      setIsLoading(false);
      setReturnedChapterId(chapterId);
      if (locationState?.backToTitle) {
        setAllowNavigation(true);
        resetNavigationPersistHandler();
        history.goBack();
      } else {
        resetNavigationPersistHandler();
        history.push('/folders');
      }
    } else {
      setReturnedChapterId(chapterId);
    }
  };
  const [fetchedResult, setFetchedResult] = useState('');
  const [query, setQuery] = useState('');
  const [getThumbnail, setGetThumbnail] = useState(false);
  const categoryModalRef = useRef<CategoryRef>(null);
  const [isAnyChangeMade, setIsAnyChangeMade] = useState(false);
  const _query = useQuery();
  const route = useRouteMatch();

  useEffect(() => {
    let title = _query.get('title');
    let path = route?.path;
    if (title && path === '/create-chapter') {
      setEditorData({
        SopById: initialChapterData(title),
      });
    }
  }, [_query.get('title'), route?.path]);

  useEffect(() => {
    if (_query.get('training-path-flow') !== null) {
      setShowTrainingPathFlowModal(true);
    }
  }, [_query.get('training-path-flow')]);

  useEffect(() => {
    let isChange = anyChange(
      selectedEmoji,
      editorData,
      contentWatch,
      titleWatch || ''
    );
    setIsAnyChangeMade(isChange);
  }, [selectedEmoji, contentWatch, titleWatch]);

  const searchQueryHandler = () => {
    fetchData(query, setFetchedResult);
  };

  const getThumbnailHandler = (image: any) => {
    // console.log('IMAGE', image);
  };

  const onItemClick = useCallback((event: MouseEvent, node: any) => {
    switch (node?.type?.name) {
      case 'member':
        useDetailRef.current?.openDetail('user', node.attrs);
        break;
      case 'location':
        useDetailRef.current?.openDetail('branch', node.attrs);
        break;
      case 'form':
        submitForm({
          formId: node.attrs?.eid,
          title: node.attrs?.label,
        });
        break;
      case 'chapter':
        window.open(`/chapters/view/${node.attrs?.eid}`, '_blank');
        break;
    }
  }, []);

  // useEffect(() => {
  //   if (returnedChapterId) {
  //     saveVisibilityHandler();
  //   }
  // }, [returnedChapterId]);

  const previewHandler = () => {
    setShowPreview(true);
  };

  const allowNavigationHandler = () => {
    let flag = false;
    if (
      allowNavigation ||
      (chapterSaveData?.savedOnce && !isAnyChangeMade) ||
      !isAnyChangeMade
    ) {
      flag = true;
    }
    return flag;
  };

  const onAddCategoryModalClose = () => {
    updateOperationType(isEditMode ? 'edit' : 'add');
    updateVisibilityScope('chapter');
  };

  return (
    <DashboardContainer>
      {!loading && (
        <FormProvider {...methods}>
          <DiscardPrompt allowNavigation={allowNavigationHandler()} />
          <Box bg='#f4f4f4'>
            <Heading
              chapterId={id}
              showShareDrawer={showShareDrawer}
              editorData={editorData}
              setShowShareDrawer={setShowShareDrawer}
              chapterStatus={editorData?.SopById?.status}
              publishHandler={publishHandler}
              previewHandler={previewHandler}
              saveTemporaryHandler={saveTemporaryFn}
              refetchChapterDetailsHandler={refetchChapterDetailsHandler}
              chapterSaveData={chapterSaveData}
              isEditMode={isEditMode}
              chapterSaveLoading={chapterSaveLoading}
              chapterUpdateLoading={chapterUpdateLoading}
              showTrainingPathFlowModal={showTrainingPathFlowModal}
              isAnyChangeMade={isAnyChangeMade}
              initialLoad={initialLoad}
            />
            <Flex w='full' gap={4} mt={4} justifyContent='center'>
              <Box
                bg='white'
                borderRadius='7px'
                w='full'
                h='calc(100vh - 200px)'
              >
                <ChapterEditorContainer
                  editorData={editorData['SopById']}
                  getThumbnailFn={getThumbnailHandler}
                  getThumbnail={getThumbnail}
                  onItemClick={onItemClick}
                  selectedEmoji={selectedEmoji}
                  setSelectedEmoji={setSelectedEmoji}
                />
              </Box>
              {/* <Box flex={2} bg='white' borderRadius='1rem' p='16px'>
              <SmartPages />
            </Box> */}
            </Flex>
            {/* {showScrollToTop && (
          )} */}
            {showState?.showFolder && (
              <CategoryModal
                ref={categoryModalRef}
                isSaveChapterTemp={saveChapterTemp}
                isOpen={showState?.showFolder}
                showStateData={showState}
                onClose={() =>
                  setShowState({
                    showFolder: false,
                    showVisibility: false,
                    showTrainingPathFlowModal: false,
                  })
                }
                categoryId={editorData?.SopById?.categoryId}
                setReturnedChapterId={setReturnedChapterId}
                setShowModal={setShowModal}
                setIsLoading={setIsLoading}
                setShowState={setShowState}
                saveTemporaryHandler={saveTemporaryHandler}
                onAddCategoryModalClose={onAddCategoryModalClose}
              />
            )}
            {showState?.showVisibility && (
              <VisibilityOperationModal
                showState={showState}
                setShowState={setShowState}
                isEditMode={isEditMode}
                locationsList={locationsList}
                membersList={membersList}
                notifyUsers={notifyUsers}
                rolesList={rolesList}
                onPublishHandler={onPublishHandler}
                setNotifyUsers={setNotifyUsers}
                parentFolderData={
                  subFolderWatch?.eid ? subFolderWatch : categoryWatch
                }
                operationMethod={getOperationMethod()}
                name={methods.getValues('title')}
                chapterId={id}
              />
              // <VisibilityModalComponent
              //   isEditMode={isEditMode}
              //   isLoading={isLoading}
              //   // newSetVisibility={_visibilityData}
              //   newSetVisibility={newSetVisibility}
              //   notifyUsers={notifyUsers}
              //   showState={showState}
              //   modalType='read'
              //   onPublishHandler={onPublishHandler}
              //   setNewSetVisibility={setNewSetVisibility}
              //   setNotifyUsers={setNotifyUsers}
              //   setShowState={setShowState}
              //   showVisibilityInfo
              // />
            )}
          </Box>
          <PreviewModal
            open={showPreview}
            onClose={() => setShowPreview(false)}
          />
        </FormProvider>
      )}
      {loading && (
        <Box
          display='flex'
          justifyContent={'center'}
          alignItems={'center'}
          h='60vh'
          w='full'
        >
          <Loader />
        </Box>
      )}
      <UserDetailsComponent ref={useDetailRef} />
      {/* <ConfirmNavigation
        title='Discard Chapter?'
        desc='This chapter has not been saved, all your content will be lost if you close!'
      /> */}
      <TrainingPathFlowModal
        isOpen={showState?.showTrainingPathFlowModal}
        onClose={() => {}}
      />
    </DashboardContainer>
  );
};

export default ChapterEditor;
