import { useLazyQuery } from '@apollo/client';
import {
  LOCATION_OWNER_UPCOMING_AUDITS,
  LOCATION_OWNER_COMPLETED_AUDITS,
} from '../graphql/location-owner-audit.graphql';
import React from 'react';
import {
  LocationOwnerAuditsVariable,
  LocationOwnersAuditsResponse,
} from '../types';

export const useServiceLayer = () => {
  const [getLocationOwnerCompletedAudits, locationOwnerCompletedAuditsResult] =
    useLazyQuery<LocationOwnersAuditsResponse, LocationOwnerAuditsVariable>(
      LOCATION_OWNER_COMPLETED_AUDITS,
      {
        fetchPolicy: 'network-only',
      }
    );

  const [getLocationOwnerUpcomingAudits, locationOwnerUpcomingAuditsResult] =
    useLazyQuery<LocationOwnersAuditsResponse, LocationOwnerAuditsVariable>(
      LOCATION_OWNER_UPCOMING_AUDITS,
      {
        fetchPolicy: 'network-only',
      }
    );

  return {
    locationOwnerCompletedAudits: {
      getLocationOwnerCompletedAudits,
      locationOwnerCompletedAuditsResult,
    },
    locationOwnerUpcomingAudits: {
      getLocationOwnerUpcomingAudits,
      locationOwnerUpcomingAuditsResult,
    },
  };
};
