import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SectionEntity } from '../types';

interface IProps {
  section: SectionEntity;
  onClick: () => void;
}

const SectionCard: FC<IProps> = ({
  section: { sectionName, data },
  onClick,
}) => {
  const totalQuestions = useMemo(() => {
    return data?.length || 0;
  }, [data]);

  const completedQuestions = useMemo(() => {
    return data?.filter((question) => question.isCompleted).length;
  }, [data]);

  const areAllQuestionsDone = useMemo(() => {
    return !data?.some((ques) => ques.isRequired && !ques.isCompleted);
  }, [data]);

  const isNoQuestionCompleted = useMemo(() => {
    return data?.every((ques) => !ques.isCompleted);
  }, [data]);

  const isAnyQuestionCompleted = useMemo(() => {
    return data?.some(
      (ques) =>
        ques.isCompleted || ques?.noteText?.trim() || ques?.images?.length
    );
  }, [data]);

  const flaggedResponsesCount = useMemo(() => {
    return data?.filter((ques) => ques.isFlagged).length;
  }, [data]);

  const showStatusAction = () => {
    if (isNoQuestionCompleted) {
      return (
        <Box
          as='span'
          color='#2A85FF'
          fontWeight={600}
          fontSize='15px'
          padding='8px 16px'
        >
          Begin Now
        </Box>
      );
    }

    if (areAllQuestionsDone) {
      return (
        <Flex align='center' gap={2}>
          <Flex
            align='center'
            gap={1}
            bg='#B5E4CA'
            color='#1A1D1F'
            borderRadius='6px'
            p='2px 8px'
            minH='28px'
          >
            <CheckIcon />
            <Box as='span' fontWeight='500'>
              Completed
            </Box>
          </Flex>
          <ChevronRightIcon boxSize='24px' />
        </Flex>
      );
    }
    if (isAnyQuestionCompleted) {
      return (
        <Flex align='center' gap={2}>
          <Flex
            align='center'
            gap={1}
            bg='#FFD88D'
            color='#1A1D1F'
            borderRadius='6px'
            p='2px 8px'
            minH='28px'
          >
            <FontAwesomeIcon icon={faCircleExclamation as IconProp} />
            <Box as='span' fontWeight='500'>
              Pending
            </Box>
          </Flex>
          <ChevronRightIcon fontSize='24px' />
        </Flex>
      );
    }
    return (
      <Box
        as='span'
        color='#2A85FF'
        fontWeight={600}
        fontSize='15px'
        padding='8px 16px'
      >
        Begin Now
      </Box>
    );
  };

  return (
    <Flex
      boxShadow='2px 2px 12px 0px #292D321F'
      padding='16px 24px'
      borderRadius='10px'
      bg='#FFFFFF'
      gap={3}
      align='center'
      cursor='pointer'
      onClick={onClick}
    >
      <Flex flexDir='column' flex={1} gap={2}>
        <Box fontSize='15px' fontWeight={700} color='#1A1D1F'>
          {sectionName}
        </Box>
        <Flex
          fontSize='12px'
          fontWeight={500}
          color='#6F767E'
          align='center'
          gap='8px'
        >
          <Box color='#1A1D1F' fontWeight={600}>
            {completedQuestions}/{totalQuestions}
          </Box>
          <Box>Completed</Box>

          {/*<Box boxSize={1} borderRadius='full' bg='#6F767E' />*/}
          {/*<Box color='#1A1D1F' fontWeight={600}>*/}
          {/*  {points ?? 'NA'}*/}
          {/*</Box>*/}
          {/*<Box>Points</Box>*/}

          {/*<Box boxSize={1} borderRadius='full' bg='#6F767E' />*/}
          {/*<Box color='#1A1D1F' fontWeight={600}>*/}
          {/*  {score ?? 'NA'}*/}
          {/*</Box>*/}
          {/*<Box>Score</Box>*/}

          <Box boxSize={1} borderRadius='full' bg='#6F767E' />
          <Box color='#1A1D1F' fontWeight={600}>
            {flaggedResponsesCount ?? 'NA'}
          </Box>
          <Box>{flaggedResponsesCount === 1 ? 'Flag' : 'Flags'}</Box>
        </Flex>
      </Flex>
      {showStatusAction()}
    </Flex>
  );
};

export default SectionCard;
