import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckListDetails, ProjectStartAndSupervisor } from '../Components';
import { ActionButton } from '../../../ui-components';

import { IFormInput } from './add-location.types';
import { useUserDataSelector } from 'hooks';
import {
  getDateFromDuration,
  getPhaseTasksDuration,
} from 'utils/phaseTaskDuration';
import { useGoLiveDate } from 'ui-components/GoLiveDateModal/useGoLiveDate';
import moment from 'moment';
import { deployProjectDetails } from './add-location.events';

interface IProps {}

const ProjectDetails: FC<IProps> = () => {
  const { control, handleSubmit, setValue } = useFormContext<IFormInput>();

  const phaseContents = useUserDataSelector(
    (state) => state?.entity?.launcher?.contents
  );

  const { t } = useTranslation(['common', 'home']);

  const loading = useWatch<IFormInput, 'loading'>({
    control: control,
    name: 'loading',
  });

  const tasksTotalDuration = useMemo(() => {
    return getPhaseTasksDuration(phaseContents);
  }, [phaseContents]);

  let nextDayTasksTotalDuration = tasksTotalDuration + 1;

  const endDate = useMemo(() => {
    return getDateFromDuration(nextDayTasksTotalDuration); // adding extra 2 to consider the current day making it "TOTAL DAYS OF TASKS + CURRENT DAY" and making it live by next day of total count
  }, [nextDayTasksTotalDuration]);

  const goLiveDate = useGoLiveDate();

  const startDateWatch = useWatch<IFormInput, 'startDate'>({
    control: control,
    name: 'startDate',
  });

  const isGoLiveDateBeforeTaskDueDate = useCallback(() => {
    let startDateStartOfDay = moment.utc(startDateWatch)?.startOf('day');
    let flag = startDateStartOfDay.isBefore(endDate.utc()?.startOf('day'));
    return flag;
  }, [startDateWatch, endDate]);

  useEffect(() => {
    let startDateValue = startDateWatch;
    if (!startDateValue) {
      setValue('startDate', endDate?.toDate());
    }
  }, [endDate, startDateWatch]);

  const updateTab = () => {
    handleSubmit((values) => {
      deployProjectDetails(values);
      return setValue('currentTab', 3);
    })();
  };

  const nextClickHandler = () => {
    if (isGoLiveDateBeforeTaskDueDate()) {
      goLiveDate({
        onProceed: updateTab,
        onCancel: () => setValue('startDate', endDate?.toDate()),
      });
    } else {
      updateTab();
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Box fontSize='32px' fontWeight='600' color='#111315' textAlign='center'>
        Project Details
      </Box>

      <CheckListDetails />

      <ProjectStartAndSupervisor endDate={endDate} />

      <ActionButton
        size='lg'
        type='submit'
        colorScheme='blue'
        width='fit-content'
        minW='170px'
        alignSelf='flex-end'
        fontSize='15px'
        fontWeight='600'
        // actionFn={handleSubmit}
        // actionFn={handleSubmit(() => setValue('currentTab', 3))}
        actionFn={nextClickHandler}
        isLoading={loading}
        disabled={loading}
      >
        {/* {t('home:addCard.location')} */}
        Next
      </ActionButton>
    </Flex>
  );
};

export default ProjectDetails;
