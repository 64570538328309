const usePaginateData = <T extends unknown>(
  data: T[],
  itemPerPage: number
): T[][] => {
  const pages = Math.ceil(data.length / itemPerPage);
  return Array.from({ length: pages }, (_, index) => {
    const start = index * itemPerPage;
    const end = start + itemPerPage;
    return data.slice(start, end);
  });
};

export default usePaginateData;
