import React, { FC } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PiNote } from 'react-icons/pi';

import { AuditQuestionEntity, QuestionType } from '../../../../types';
import TitleHeader from '../../../CardEditor/TitleHeader';
import { HideComponent } from '../../take-audit/components';
import InputFieldView from './InputFieldView';

interface IProps {
  question: AuditQuestionEntity;
  questionIndex: number;
}

const QuestionView: FC<IProps> = ({ question, questionIndex }) => {
  return (
    <Flex
      flexDir='column'
      padding='20px 16px'
      border='1px solid #C7C7C7'
      borderRadius='8px'
      bg='#FFFFFF'
      gap={4}
      userSelect='none'
    >
      <TitleHeader
        title={`${questionIndex + 1}. ${question?.label}`}
        isRequired={question.isRequired}
        titleStyle={{ fontSize: '16px' }}
      />

      <InputFieldView qType={question.qType} options={question.options} />

      <Flex
        gap={3}
        align='center'
        color='#33383F'
        justify='end'
        userSelect='none'
      >
        <Flex gap={1} align='center' cursor='pointer' fontWeight={500}>
          <PiNote />
          Add note
        </Flex>

        <Divider orientation='vertical' height='20px' borderColor='#EFEFEF' />

        <HideComponent
          hidden={[
            QuestionType.FILE_UPLOAD,
            QuestionType.IMAGE_UPLOAD,
            QuestionType.VIDEO_UPLOAD,
          ].includes(question.qType)}
        >
          <Flex gap={1} align='center' cursor='pointer' fontWeight={500}>
            <FontAwesomeIcon icon={faPaperclip as IconProp} />
            Add evidence
          </Flex>

          <Divider orientation='vertical' height='20px' borderColor='#EFEFEF' />
        </HideComponent>

        <Flex
          gap={1}
          align='center'
          cursor='pointer'
          fontWeight={500}
          _disabled={{
            opacity: 0.4,
            pointerEvents: 'none',
            cursor: 'not-allowed',
          }}
        >
          <FontAwesomeIcon icon={faFlag as IconProp} />
          <Box fontWeight={500}>Mark flag</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default QuestionView;
