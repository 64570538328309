import React, { FC, useMemo } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { SimpleDatePicker } from 'ui-components/DateFilter/components';
import { IFormInput } from '../../../audit-schedule.types';

const Yearly: FC = () => {
  const { minDate, maxDate } = useMemo(() => {
    return {
      minDate: moment().startOf('year').toDate(),
      maxDate: moment().endOf('year').toDate(),
    };
  }, []);

  return (
    <Controller<IFormInput, 'repeatDetails.yearly'>
      name='repeatDetails.yearly'
      rules={{
        required: 'Please select yearly repeat period',
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl display='flex' gap={4} isInvalid={!!fieldState.error}>
            <SimpleDatePicker
              disabled={field.disabled}
              isSelected={!!field.value}
              onChange={field.onChange}
              selectedValue={field.value || null}
              placeholderText=''
              minDate={minDate}
              maxDate={maxDate}
              dateFormat='d MMMM'
            />
            <FormErrorMessage mt='0'>
              {fieldState.error?.message}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default Yearly;
