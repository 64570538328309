import React, { FC, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UserSignature } from '../../../../../types';
import { signatureConfigs } from '../../../../../configs';
import { useAuditSignature } from './useAuditSignature';

interface VProps {
  value: UserSignature;
  onClick?: () => void;
}

const SignatureValue: FC<VProps> = ({ value, onClick }) => {
  const config = useMemo(() => {
    return signatureConfigs.find((it) => it.fontFamily === value?.font);
  }, [value]);

  if (!value) {
    return null;
  }

  return (
    <Center
      position='relative'
      whiteSpace='nowrap'
      color='#1A1D1F'
      {...config}
      // @ts-ignore
      fontSize={config?.fontSize * 1.5}
      userSelect='none'
      minHeight='80px'
      backgroundImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='2' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`}
      borderRadius='4px'
      cursor='pointer'
      _hover={{
        ' > div': {
          visibility: 'visible',
          opacity: 1,
        },
      }}
    >
      {value?.text}

      <Flex
        visibility='hidden'
        opacity={0}
        position='absolute'
        cursor='pointer'
        fontSize='26px'
        top='-13px'
        right='-13px'
        alignSelf='center'
        borderRadius='full'
        _hover={{
          boxShadow: 'sm',
        }}
        onClick={onClick}
      >
        <FontAwesomeIcon color='#FF6A55' icon={faCircleXmark as IconProp} />
      </Flex>
    </Center>
  );
};

interface IProps {
  value?: UserSignature;
  onChange?: (sign?: UserSignature) => void;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
}

const AuditSignature = forwardRef<never, IProps>(
  ({ value, onChange, isReadOnly, isInvalid }, ref) => {
    const auditSignature = useAuditSignature();

    useImperativeHandle(ref, () => null, []);

    const addSignature = () => {
      auditSignature(onChange);
    };

    if (value || isReadOnly) {
      return (
        <SignatureValue value={value!} onClick={() => onChange?.(undefined)} />
      );
    }

    return (
      <Center
        color='#6F767E'
        bg='#EFEFEF'
        h='80px'
        flexDir='column'
        cursor='pointer'
        gap={1}
        onClick={addSignature}
        borderRadius='4px'
        border='1px solid transparent'
        aria-invalid={isInvalid}
        _invalid={{
          borderColor: 'red.500',
        }}
      >
        <FontAwesomeIcon icon={faCirclePlus as IconProp} />
        <Box as='span' fontWeight={500}>
          Add signature
        </Box>
      </Center>
    );
  }
);

AuditSignature.displayName = 'AuditSignature';

export default AuditSignature;
