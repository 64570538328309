import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, get, useWatch } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';
import { Any } from '../../../../../types';
import { QuestionsState, SectionEntity } from '../../types';
import { MultiChoice } from '../question-types';
import { AuditTake } from '../../tabke-audit.helpers';
import { responseIdValidate } from './input.validator';

interface IProps {
  question: QuestionsState;
  sectionIndex: number;
  questionIndex: number;
}

const MultiChoiceSection: FC<IProps> = ({
  question,
  sectionIndex,
  questionIndex,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveHandler = (...args: Any) => {
    AuditTake.save(`data.${questionIndex}`, question.eid);
  };

  const isFlagged = useWatch<SectionEntity, `data.${number}.isFlagged`>({
    name: `data.${questionIndex}.isFlagged`,
  });

  return (
    <Controller<SectionEntity, `data.${number}.responseId`>
      name={`data.${questionIndex}.responseId`}
      rules={{
        required: {
          value: question?.isRequired,
          message: 'This field is required',
        },
        validate: (value, values) => {
          return responseIdValidate(
            value,
            get(values, `data.${questionIndex}`)
          );
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={fieldState.invalid} userSelect='none'>
            <MultiChoice
              options={question?.options}
              value={field.value}
              onChange={callAll(field.onChange, saveHandler)}
              isFlagged={isFlagged}
            />

            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default MultiChoiceSection;
