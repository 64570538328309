import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { IFormInput } from '../../audit-schedule.types';
import SingleTime from './SingleTime';
import { Repeating } from './repeating';

const AuditDue: FC = () => {
  const auditType = useWatch<IFormInput, 'repeatDetails.auditFrequency'>({
    name: 'repeatDetails.auditFrequency',
  });

  if (auditType === 'repeating') {
    return <Repeating />;
  }

  if (auditType === 'oneTime') {
    return <SingleTime />;
  }

  return null;
};

export default AuditDue;
