import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { SectionAction, SectionTitleEditor } from '../../common';

interface IProps {
  category: string;
  index: number;
  maxIndex: number;
  onTitleUpdate: (index: number, newTitle: string) => void;
  onMoveClick: (currentIndex: number, nextIndex: number) => void;
  onDeleteClick: (index: number) => void;
}

const StepTitle: FC<IProps> = ({
  category,
  index,
  maxIndex,
  onTitleUpdate,
  onMoveClick,
  onDeleteClick,
}) => {
  return (
    <Flex gap={4} mb={3} align='center'>
      <SectionTitleEditor
        title={category}
        index={index}
        onUpdate={onTitleUpdate}
      >
        <Box fontSize='16px' fontWeight='600' color='#1A1D1F' isTruncated>
          {category}
        </Box>
      </SectionTitleEditor>
      <SectionAction
        index={index}
        isLastSection={maxIndex === index + 1}
        moveSectionTo={onMoveClick}
        onDeleteClick={onDeleteClick}
      />
    </Flex>
  );
};

export default StepTitle;
