import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  Container,
  OpenStepHeader,
  SaveNextButton,
  useStepperIsOpen,
} from '../common';
import TaskStepStepper from './TaskStepStepper';

import TaskStepForm from './TaskStepForm';
import { IFormInput } from '../task.types';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

interface IProps {}

const TaskSteps: FC<IProps> = () => {
  const { t } = useTranslation(['task']);
  const isOpen = useStepperIsOpen('taskSteps');
  const { handleSubmit, getValues, setValue } = useFormContext<IFormInput>();

  const captureEvent = () => {
    deployEvent(AmplitudeEventNames.STEPS_SAVE, {
      task_id: '',
      total_steps_added: getValues('contents')?.length || 0,
      total_forms_added:
        getValues('contents')?.filter((content) => content?.type === 'form')
          ?.length || 0,
      total_chapters_added:
        getValues('contents')?.filter((content) => content?.type === 'sop')
          ?.length || 0,
      total_to_do_items_added:
        getValues('contents')?.filter((content) => content?.type === 'todo')
          ?.length || 0,
      task_edit_funnel_id: 5,
      create_task_funnel_id: 4,
    });
  };

  const saveAndNext = () => {
    setValue('completed.taskSteps', true);
    setValue('filled.taskSteps', true);
    setValue('currentStep', 'assignees');
    captureEvent();
  };

  if (!isOpen) {
    return <TaskStepStepper />;
  }

  return (
    <Container flexDir='column' p='20px'>
      <OpenStepHeader title={t('task:steps')} />

      <TaskStepForm />

      <SaveNextButton onClick={handleSubmit(saveAndNext)} />
    </Container>
  );
};

export default TaskSteps;
