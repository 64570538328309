import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { TFuncKey } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { toArray } from '../../../../../utils';
import { AuditRepeatEntity, IWeekDays, Weekdays } from '../../../../../types';
import { IFormInput } from '../../audit-schedule.types';

const sortWeekdays = <T extends IWeekDays>(a: T, b: T) => {
  return Weekdays.indexOf(a) - Weekdays.indexOf(b);
};

interface TransObj {
  i18nKey: TFuncKey<'audit', undefined> | undefined;
  values: object;
  context: string;
}

export const useFrequencyInfo = (): TransObj | undefined => {
  const details = useWatch<IFormInput, 'repeatDetails'>({
    name: 'repeatDetails',
  });

  return useMemo(() => {
    if (details?.auditFrequency === 'repeating') {
      const repeatCycle = details?.repeatType;
      return match<AuditRepeatEntity<Date>['repeatType'], TransObj | undefined>(
        repeatCycle
      )
        .with('weekly', () => {
          if (!isEmpty(details?.weekly)) {
            return {
              i18nKey: 'frequencyInfo.repeat',
              values: {
                repeatDate: toArray(details?.weekly)
                  .sort(sortWeekdays)
                  .map((i) => i.slice(0, 3)),
              },
              context: repeatCycle!,
            };
          }
          return undefined;
        })
        .with('monthly', () => {
          if (details?.monthly) {
            return {
              i18nKey: 'frequencyInfo.repeat',
              values: {},
              context: repeatCycle!.concat('_', details.monthly),
            };
          }
          return undefined;
        })
        .with('yearly', () => {
          if (details?.yearly) {
            return {
              i18nKey: 'frequencyInfo.repeat',
              values: {
                repeatDate: moment(details.yearly).format('MMMM Do'),
              },
              context: repeatCycle!,
            };
          }
          return undefined;
        })
        .with('custom', () => {
          const customRepeat = details?.customRepeatType;
          return match<
            AuditRepeatEntity<Date>['customRepeatType'],
            TransObj | undefined
          >(customRepeat)
            .with('days', () => {
              if (details?.customRepeatValue) {
                return {
                  i18nKey: 'frequencyInfo.repeat',
                  values: {
                    repeatValue: details?.customRepeatValue,
                  },
                  context: repeatCycle!.concat('_', customRepeat!),
                };
              }
              return undefined;
            })
            .with('weeks', () => {
              if (details?.customRepeatWeek && details?.customRepeatValue) {
                return {
                  i18nKey: 'frequencyInfo.repeat',
                  values: {
                    repeatValue: details?.customRepeatValue,
                    repeatDate: details?.customRepeatWeek,
                    dueDate: Weekdays.at(
                      Weekdays.indexOf(details?.customRepeatWeek) - 1
                    ),
                  },
                  context: repeatCycle!.concat('_', customRepeat!),
                };
              }
              return undefined;
            })
            .with('months', () => {
              if (details?.customRepeatMonth && details?.customRepeatValue) {
                return {
                  i18nKey: 'frequencyInfo.repeat',
                  values: {
                    repeatValue: details?.customRepeatValue,
                    dueDate: moment(details?.customRepeatMonth).format('Do'),
                  },
                  context: repeatCycle!.concat('_', customRepeat!),
                };
              }
              return undefined;
            })
            .otherwise(() => undefined);
        })
        .otherwise(() => undefined);
    }
    const END_DATE_FORMAT = 'MMMM Do [at] hh:mm A';
    return match<AuditRepeatEntity<Date>, TransObj | undefined>(details)
      .with({ auditDue: 'nextWeek' }, () => {
        return {
          i18nKey: 'frequencyInfo.once',
          values: {
            endDate: moment()
              .startOf('isoWeek')
              .add(1, 'week')
              .day('saturday')
              .hours(23)
              .minutes(59)
              .format(END_DATE_FORMAT),
          },
          context: details.auditDue!,
        };
      })
      .with({ auditDue: 'endMonth' }, () => {
        return {
          i18nKey: 'frequencyInfo.once',
          values: {
            endDate: moment().endOf('month').format(END_DATE_FORMAT),
          },
          context: details.auditDue!,
        };
      })
      .with({ auditDue: 'endYear' }, () => {
        return {
          i18nKey: 'frequencyInfo.once',
          values: {
            endDate: moment().endOf('year').format(END_DATE_FORMAT),
          },
          context: details.auditDue!,
        };
      })
      .with({ auditDue: 'date' }, () => {
        if (details?.auditDueDate) {
          return {
            i18nKey: 'frequencyInfo.once',
            values: {
              endDate: moment(details?.auditDueDate)
                .hours(23)
                .minutes(59)
                .format(END_DATE_FORMAT),
            },
            context: details.auditDue!,
          };
        }

        return undefined;
      })
      .with({ auditDue: 'anyTime' }, () => {
        return {
          i18nKey: 'frequencyInfo.once',
          values: {
            endDate: '',
          },
          context: details.auditDue!,
        };
      })
      .otherwise(() => undefined);
  }, [details]);
};
