import { gql } from '@apollo/client';

export const LO_AUDIT_HISTORY = gql`
  query LOAuditHistory(
    $filter: FilterFindManyAuditSessionInput
    $page: Int
    $perPage: Int
    $locationIds: [String]
  ) {
    AuditSessionPagination(filter: $filter, page: $page, perPage: $perPage) {
      items {
        name
        eid
        title
        auditors {
          eid
        }
        dueDate
        startDate
        isCompleted
        createdAt
        entityId
        updatedAt
        description
        auditId
        questions
        assignments(locationIds: $locationIds) {
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          response
          startedBy
          reportsUrl
          totalScore
          maxScore
        }
      }
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
