import { useMemo } from 'react';
import { IFormInput } from '../form-types';

interface PreviseSection {
  eid: string;
  sectionName: string;
  selected: boolean;
  questions: IFormInput['questions'];
  questionCount: number;
}

export const useQuestionsToSections = (
  questions: IFormInput['questions']
): PreviseSection[] => {
  return useMemo(() => {
    const categorizedData: Array<PreviseSection> = [];

    let currentSection: PreviseSection = {
      eid: 'default',
      sectionName: 'Audit Questionnaire',
      questions: [],
      selected: false,
      questionCount: 0,
    };

    if (questions) {
      questions.forEach((question) => {
        if (question.isDisabled) {
          return true;
        }
        // If the question is a section, create a new section object
        if (question.qType === 'section') {
          if (currentSection.questions.length > 0) {
            currentSection.questionCount = currentSection.questions.length;
            categorizedData.push(currentSection);
          }
          // Set the new current section
          currentSection = {
            eid: question.eid || Math.random().toString(18).slice(2, 10),
            sectionName: question.label,
            questions: [],
            selected: false,
            questionCount: 0,
          };
        } else {
          currentSection.questions.push(question);
        }
      });

      // Push the last section to the categorizedData if it has any questions
      if (currentSection.questions.length > 0) {
        currentSection.questionCount = currentSection.questions.length;
        categorizedData.push(currentSection);
      }
    }

    return categorizedData;
  }, [questions]);
};
