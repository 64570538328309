import React, { useMemo } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Image,
  PopoverTrigger as OrigPopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { NullShorting } from 'utils/sorting';
import { shallowEqual } from 'utils';

import { Column } from '../../../sub-components/ChakraTable/SortingTable';
import { IndeterminateCheckbox } from '../common';
import {
  LocationName,
  LocationMembers,
  LocationActions,
  LocationStatus,
  LocationType,
  LocationCityHeader,
  LocationState,
  LocationZipCodeHeader,
  LocationTypeHeader,
  LocationEmailPhone,
  LocationStatusHeader,
} from './Components';
import { ILocation } from '../../../sub-components/Locations/locationTypes/location-types';
import {
  FieldType,
  ICityStateList,
  ICityStateListEntity,
  IDropdownType,
  ISelectedFilterTab,
} from './LocationListContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFile, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { IOtherFieldsEntity } from 'pages/LocationDetails/utils/location-details.types';
import moment from 'moment';
import { FILENAME_REGEX } from 'utils/constants';

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

export const useColumnHandler = (
  onEditClick: (location: ILocation) => void,
  onEditPreLaunchClick: (location: ILocation) => void,
  onShareClick: (location: ILocation) => void,
  onMessageClick: (location: ILocation) => void,
  onLocationClick: (location: ILocation) => void,
  onLaunchLocationClick: (location: ILocation) => void,
  onDeleteLocationClick: (location: ILocation) => void,
  onCityStateClick: (type: IDropdownType, data: ICityStateListEntity) => void,
  fetchData: () => void,
  cityStateList: ICityStateList,
  finalCityStateList: ICityStateList,
  selectedFilterTab: ISelectedFilterTab,
  filteredFieldData: FieldType[],
  shouldHideEdit?: boolean,
  locationOwnerShare?: boolean
): Column<ILocation>[] => {
  const { t, i18n } = useTranslation(['location', 'setting']);
  const userData = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      type: state.type,
      locationFields: state?.entity?.locationFields,
    }),
    shallowEqual
  );

  const listRender = (
    data: ICityStateListEntity,
    index: number,
    type: IDropdownType
  ) => {
    return (
      <Flex
        align='center'
        cursor='pointer'
        justify='space-between'
        w='full'
        gap={4}
        key={index}
        onClick={() => onCityStateClick(type, data)}
      >
        <Text as='b' textTransform='capitalize'>
          {data?.name}
        </Text>
        {/* <Checkbox isReadOnly isChecked={data?.selected} /> */}
        {data?.selected ? (
          <FontAwesomeIcon
            icon={faSquareCheck as IconProp}
            color='#2a85ff'
            fontSize='18px'
          />
        ) : (
          <FontAwesomeIcon
            icon={faSquare as IconProp}
            color='#E2E8F0'
            fontSize='18px'
          />
        )}
      </Flex>
    );
  };

  const customFieldValue = (customField: IOtherFieldsEntity) => {
    let fieldData = userData?.locationFields?.filter((filter) =>
      filter?.eid?.includes(customField?.fieldId)
    )?.[0];
    switch (fieldData?.fieldType) {
      case 'text':
        return <Text>{customField?.value}</Text>;
      case 'date':
        return (
          <Text>
            {moment(customField?.value)?.isValid()
              ? moment.utc(customField?.value)?.format('LLL')
              : '-'}
          </Text>
        );
      case 'document':
        return (
          <Flex
            align='center'
            columnGap='5px'
            bg='#e0f5fe'
            p='5px 10px'
            borderRadius='7px'
            width='fit-content'
            cursor='pointer'
          >
            <Tooltip
              hasArrow
              label={
                customField?.files?.[0]?.url ? customField?.files?.[0]?.url : ''
              }
            >
              <>
                {customField?.files?.[0]?.type === 'image' ? (
                  <Image
                    src={customField?.files?.[0]?.url}
                    minW='20px'
                    maxW='20px'
                    minH='20px'
                    maxH='20px'
                  />
                ) : (
                  <FontAwesomeIcon icon={faFile as IconProp} fontSize='18px' />
                )}
                <Text isTruncated maxW='150px'>
                  {customField?.files?.[0]?.url
                    ? customField?.files?.[0]?.url?.split(FILENAME_REGEX).pop()
                    : '-'}
                </Text>
              </>
            </Tooltip>
          </Flex>
        );
      case 'dropDown':
        return (
          <Flex flexDir='column' gap='2px'>
            {customField?.options?.map((option: string, index: number) => (
              <Flex
                key={index}
                align='center'
                columnGap='5px'
                bg='#e0f5fe'
                p='5px 10px'
                borderRadius='7px'
                width='fit-content'
              >
                <Box as='span'>{option}</Box>
              </Flex>
            ))}
          </Flex>
        );
      case 'singleChoice':
        return (
          <Flex
            align='center'
            columnGap='5px'
            bg='#e0f5fe'
            p='5px 10px'
            borderRadius='7px'
            width='fit-content'
          >
            <Box as='span'>{customField?.value}</Box>
          </Flex>
        );
      default:
        return <Text>-</Text>;
    }
  };

  return useMemo((): Column<ILocation>[] => {
    const actionColumn =
      (userData?.authRole === AuthRole.SUPER_ADMIN ||
        userData?.authRole === AuthRole.ADMIN ||
        userData?.authRole === AuthRole.LOCATION_OWNER) &&
      userData?.type === 'user'
        ? [
            {
              Header: '',
              accessor: 'eid' as any,
              sticky: 'right',
              width: 90,
              id: 'action',
              // @ts-ignore
              Cell: ({ cell: { row, value } }) => {
                return (
                  <LocationActions
                    locationId={value}
                    shouldHideEdit={shouldHideEdit}
                    locationDetails={row?.original}
                    locationOwnerShare={locationOwnerShare}
                    onEditClick={() => onEditClick(row.original)}
                    onEditPreLaunchClick={() =>
                      onEditPreLaunchClick(row.original)
                    }
                    onShareClick={() => onShareClick(row.original)}
                    onMessageClick={() => onMessageClick?.(row.original)}
                    onDeleteLocationClick={() =>
                      onDeleteLocationClick?.(row?.original)
                    }
                    preLaunch={row?.original?.locationStatus === 'preLaunch'}
                    onLaunchLocationClick={() =>
                      onLaunchLocationClick?.(row.original)
                    }
                  />
                );
              },
              disableSortBy: true,
            },
          ]
        : [];
    let columnsArray: any[] = [];
    filteredFieldData
      ?.filter((filter) => filter?.visibility)
      ?.map((filter) => {
        if (filter?.field) {
          if (filter?.id === 'name_ac414408-cf21-4c42-840f-8d7b49371163') {
            columnsArray?.push({
              Header: t('setting:locations_section.name_table_heading'),
              accessor: 'name',
              width: 300,
              Cell: ({ cell: { value, row } }) => {
                return (
                  <Box>
                    <LocationName
                      name={value}
                      username={row.original.username}
                      thumbnail={row.original.thumbnail}
                      onClick={() => onLocationClick(row?.original)}
                    />
                  </Box>
                );
              },
              sortType: NullShorting,
            });
          } else if (
            filter?.id === 'type_1bc6d3c7-c775-43b8-bf41-ebf6bf626b84'
          ) {
            columnsArray?.push({
              Header: (
                <LocationTypeHeader
                  cityStateList={cityStateList}
                  finalCityStateList={finalCityStateList}
                  fetchData={fetchData}
                  listRender={listRender}
                />
              ),
              accessor: 'locationType',
              width: 250,
              disableSortBy: true,
              Cell: ({ cell: { value } }) => {
                return <LocationType value={value} />;
              },
              sortType: NullShorting,
            });
          } else if (
            filter?.id === 'owner_9887af5a-5a93-49a3-858f-d74b159e5043'
          ) {
            columnsArray?.push({
              Header: t('setting:locations_section.owner'),
              accessor: 'owner',
              width: 250,
              Cell: ({ cell: { value, row } }) => {
                let membersData = row?.original?.members?.filter(
                  (member) => member?.authRole === AuthRole.LOCATION_OWNER
                );
                return membersData?.length > 0 ? (
                  <LocationMembers
                    members={membersData}
                    locationName={row.original.name}
                  />
                ) : (
                  <Text>-</Text>
                );
              },
              disableSortBy: true,
            });
          } else if (
            filter?.id === 'status_88acac9a-36de-40fd-8b03-d43ebc474ded'
          ) {
            columnsArray?.push({
              Header: (
                <LocationStatusHeader
                  cityStateList={cityStateList}
                  finalCityStateList={finalCityStateList}
                  selectedFilterTab={selectedFilterTab}
                  fetchData={fetchData}
                  listRender={listRender}
                />
              ),
              accessor: 'locationStatus',
              disableSortBy: true,
              Cell: ({ cell: { value } }) => {
                return <LocationStatus status={value} />;
              },
            });
          } else if (
            filter?.id === 'email_9967c0fd-c14f-4b6c-a83d-bcbde1e5dd88'
          ) {
            columnsArray?.push({
              Header: 'Email',
              accessor: 'locationEmail',
              disableSortBy: true,
              width: 300,
              Cell: ({ cell: { value } }) => {
                return (
                  <LocationEmailPhone
                    values={value}
                    message='Email copied to clipboard'
                  />
                );
              },
            });
          } else if (
            filter?.id === 'phone_e9ff2acb-2050-4d69-b9ce-aca3fe059439'
          ) {
            columnsArray?.push({
              Header: 'Phone',
              accessor: 'locationPhone',
              disableSortBy: true,
              width: 300,
              Cell: ({ cell: { value } }) => {
                return (
                  <LocationEmailPhone
                    values={value}
                    message='Phone number copied to clipboard'
                  />
                );
              },
            });
          } else if (
            filter?.id === 'city_6cef1622-0da1-4bcb-b01c-6f33cda6bd08'
          ) {
            columnsArray?.push({
              Header: (
                <LocationCityHeader
                  cityStateList={cityStateList}
                  finalCityStateList={finalCityStateList}
                  fetchData={fetchData}
                  listRender={listRender}
                />
              ),
              accessor: 'address',
              id: 'city',
              width: 250,
              Cell: ({ cell: { value } }) => {
                return <Text>{value?.city || '-'}</Text>;
              },
              disableSortBy: true,
            });
          } else if (
            filter?.id === 'zipCode_72346833-37da-4681-8b9c-800106687f42'
          ) {
            columnsArray?.push({
              Header: (
                <LocationZipCodeHeader
                  cityStateList={cityStateList}
                  finalCityStateList={finalCityStateList}
                  fetchData={fetchData}
                  listRender={listRender}
                />
              ),
              accessor: 'address',
              disableSortBy: true,
              width: 250,
              Cell: ({ cell: { value } }) => {
                return <Text>{value.zipCode || '-'}</Text>;
              },
            });
          } else if (
            filter?.id === 'state_93fecf7f-5ae8-4324-ba02-515c3eb3c981'
          ) {
            columnsArray?.push({
              Header: (
                <LocationState
                  cityStateList={cityStateList}
                  finalCityStateList={finalCityStateList}
                  fetchData={fetchData}
                  listRender={listRender}
                />
              ),
              accessor: 'address',
              id: 'state',
              width: 250,
              Cell: ({ cell: { value } }) => {
                return <Text>{value?.state || '-'}</Text>;
              },
              disableSortBy: true,
            });
          } else {
            columnsArray?.push({
              accessor: filter?.field,
              id: filter?.id,
              width: 250,
              disableSortBy: true,
              Header: filter?.field,
              Cell: ({ cell: { value, row } }) => {
                let foundCellField: IOtherFieldsEntity =
                  row?.original?.otherFields?.filter((other: any) =>
                    other?.fieldId?.includes(filter?.id)
                  )?.[0];
                if (foundCellField) {
                  return customFieldValue(foundCellField);
                }
                return <Text>-</Text>;
              },
            });
          }
        }
      });
    return [
      {
        accessor: 'eid',
        id: 'checkbox',
        width: 50,
        // @ts-ignore
        Header: ({ getToggleAllRowsSelectedProps }) => {
          return (
            <IndeterminateCheckbox
              borderRadius='5px'
              ml='12px'
              {...getToggleAllRowsSelectedProps?.()}
            />
          );
        },
        Cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              borderRadius='5px'
              ml='12px'
              // @ts-ignore
              {...row.getToggleRowSelectedProps?.()}
            />
          );
        },
        disableSortBy: true,
      },
      ...columnsArray,
      ...actionColumn,
    ];
  }, [
    t,
    i18n.language,
    onEditClick,
    onShareClick,
    onMessageClick,
    shouldHideEdit,
    locationOwnerShare,
    cityStateList,
  ]);
};
