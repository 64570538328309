import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SessionDetailEntity } from 'sub-components/audits/take-audit/types';
import { useServiceLayer } from './useServiceLayer';

export const useControlLayer = () => {
  const { auditId, sessionId } = useParams<{
    auditId: string;
    sessionId: string;
  }>();
  const { loggedInUserAuthRole, loggedInUserEid } = useUserDataSelector(
    (state) => ({
      loggedInUserEid: state?.eid,
      loggedInUserAuthRole: state?.authRole,
    })
  );
  const service = useServiceLayer();

  useEffect(() => {
    service?.overviewData?.getOverviewData({
      variables: {
        eid: sessionId,
      },
    });
  }, []);

  const filteredOverviewData = useMemo((): SessionDetailEntity | undefined => {
    if (service?.overviewData?.overviewDataResponse?.data?.AuditSessionById) {
      if (loggedInUserAuthRole === AuthRole.ADMIN) {
        let session =
          service?.overviewData?.overviewDataResponse?.data?.AuditSessionById;
        const filteredAssignments = session?.assignments?.filter((assignment) =>
          assignment?.auditors?.includes(loggedInUserEid)
        );
        return {
          ...session,
          assignments: filteredAssignments,
        };
      }
      return service?.overviewData?.overviewDataResponse?.data
        ?.AuditSessionById;
    }
    return undefined;
  }, [service?.overviewData?.overviewDataResponse?.data?.AuditSessionById]);

  return {
    loading: service?.overviewData?.overviewDataResponse?.loading,
    overviewData: filteredOverviewData,
    refetch: service?.overviewData?.overviewDataResponse?.refetch,
  };
};
