import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import { CancelTokenSource, useUploadImage } from '../../../../../hooks';
import { AuditEvidenceEntity } from '../../../../../types';
import {
  convertToWebp,
  fileToDtaUrl,
  getWebpFilename,
} from '../ImageUpload/image.helper';
import { AuditTake } from '../../tabke-audit.helpers';

interface EvidenceInput {
  eid: string;
  file: File;
  image?: AuditEvidenceEntity;
}

export interface QueImage extends EvidenceInput {
  cancelToken?: CancelTokenSource;
  thumbnail?: string;
  imageUrl?: string;
  isUploaded?: boolean;
  isUploading?: boolean;
  uploadFailed?: boolean;
}

export interface EvidenceAction {
  value: AuditEvidenceEntity[];
  uploadQue: QueImage[];
  addEvidence: (file: EvidenceInput) => Promise<void>;
  removeImage: (index: number) => void;
  removeFromQue: (eid: string) => void;
}

type UseUploadImage = ReturnType<typeof useUploadImage>;

interface IProps {
  uploadAction: UseUploadImage;
  onChange?: (value: AuditEvidenceEntity[]) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createEvidenceStore = (props: IProps) => {
  return createStore<EvidenceAction>()(
    immer((set, getState) => ({
      value: [] as AuditEvidenceEntity[],
      uploadQue: [] as QueImage[],

      addEvidence: async (data) => {
        const index = getState().uploadQue?.length;
        const cancelToken = useUploadImage.CancelToken();

        try {
          const dataUrl = await fileToDtaUrl(data.file);

          AuditTake.updateProgress(data.eid, true);

          set((state) => {
            state.uploadQue[index] = {
              isUploading: true,
              uploadFailed: false,
              thumbnail: dataUrl,
              ...data,
              cancelToken,
            };
          });

          const blobData = await convertToWebp(dataUrl);

          const imageUrl = await props.uploadAction(
            blobData,
            getWebpFilename(data?.file?.name),
            undefined,
            cancelToken
          );

          set((state) => {
            if (state.uploadQue[index]) {
              state.uploadQue[index].isUploading = false;
              state.uploadQue[index].isUploaded = true;
              state.uploadQue[index].imageUrl = imageUrl;

              state.uploadQue[index].image = {
                url: imageUrl,
                mimetype: blobData.type,
                fileSize: blobData.size,
                name: getWebpFilename(data?.file?.name),
                type: blobData.type,
              };
            }
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          set((state) => {
            if (state.uploadQue[index]) {
              state.uploadQue[index].uploadFailed = true;
              state.uploadQue[index].isUploading = false;
            }
          });
        } finally {
          AuditTake.updateProgress(data.eid, false);
          if (props?.onChange) {
            const uploadQue = getState().uploadQue;
            if (!uploadQue.some((a) => a.isUploading)) {
              props.onChange([
                ...getState().value,
                ...uploadQue
                  .filter((a) => a.isUploaded && a.image)
                  .map((a) => a.image!),
              ]);

              set((state) => {
                state.uploadQue = state.uploadQue.filter(
                  (a) => a.isUploading || a.uploadFailed
                );
              });
            }
          }
        }
      },

      removeImage: (index) => {
        props.onChange?.(getState().value.filter((_, i) => i !== index));
      },

      removeFromQue: (eid) => {
        set((state) => {
          state.uploadQue = state.uploadQue.filter((q) => q.eid !== eid);
        });
      },
    }))
  );
};
