export const Weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export type IWeekDays = typeof Weekdays[number];

export type TaskStatus =
  | 'TASK_CREATED'
  | 'TASK_STARTED'
  | 'TASK_REPEAT'
  | 'TASK_COMPLETED'
  | 'TASK_TERMINATED'
  | 'TASK_DELETED';

export interface CompletedTasksEntity {
  contentId: string;
  isCompleted: boolean;
  completedAt: string;
}

export interface UserTaskProgress {
  userId: string;
  locations?: string[];
  role: string;
  authRole: string;
  completedTasks?: CompletedTasksEntity[];
  isCompleted: boolean;
  completedPercent: number;
  completedAt?: string;
}
