import './App.scss';
import React, { FC, Suspense, useCallback, useState } from 'react';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { authTokenVar, UserData, userObj } from 'sop-commons/src/client';
import { IntercomProps, useIntercom } from 'react-use-intercom';
import { AuthRole, useAuthorization } from './authorization';
import { useChatInitialize, useLogout } from './hooks';
import SplashScreen from './SplashScreen';
import { UserEntityData } from './shared/graphql/shared-types';
import { usersEntityObj } from './sub-components/Header';
import RouteComponentsRender from './RouteComponentsRender';
import { roleObj } from './ui-components/DashboardMenu';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { GET_ENTITY_USERS } from './shared/graphql/queries';
import { GET_USER } from './pages/Login/login.graphql';
import useCombinedStore from './zustandStore/store';

/**
 * This hook is used to delay the loading status so that the userObject gets updated in the next event loop.
 */
const useLoadingDelay = (loading?: boolean): boolean => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const x = setTimeout(setIsLoading, 200, loading);

    return () => clearTimeout(x);
  }, [loading]);

  return isLoading;
};

const App: FC = () => {
  // useUserFlowInit();
  useChatInitialize();
  const { updateUsersEntityLoading, updateUserLoading } = useCombinedStore();
  const userObject = useReactiveVar(userObj);
  const history = useHistory();
  const authToken = useReactiveVar<any>(authTokenVar);
  const entityObjData = useReactiveVar(usersEntityObj);
  const logoutCurrentUser = useLogout();

  const authorization = useAuthorization({
    permittedFor: 'user',
    permittedRoles: [AuthRole.SUPER_ADMIN],
  });
  const { boot } = useIntercom();
  const bootWithProps = useCallback(
    (props: IntercomProps) => boot(props),
    [boot]
  );

  // const onUsersEntitySuccess = (data: UserEntityData[]) => {
  //   usersEntityObj(data);
  // };

  // const onGetUserSuccess = async (data: UserDataResponse) => {
  //   if (data?.user?.status !== 'active') {
  //     await logoutCurrentUser();
  //     history.replace('/');
  //     window.location.reload();
  //     return;
  //   }

  //   const roles = data?.user?.entity?.roles;
  //   if (roles && roles.length) {
  //     let roleWithColor: any = {};
  //     roles.forEach((role: any) => {
  //       roleWithColor[role.name] = role.color;
  //     });

  //     roleObj(roleWithColor);
  //   }
  //   executeGetEntityUsersData({
  //     variables: {
  //       entityId: data?.user?.entityId,
  //     },
  //   });
  // };

  const { loading: entityUsersLoading } = useQuery<{
    EntityUser: UserEntityData[];
  }>(GET_ENTITY_USERS, {
    fetchPolicy: 'network-only',
    skip: entityObjData?.length > 0 || !userObject?.entityId,
    variables: {
      entityId: userObject?.entityId,
    },
    onCompleted: (data) => {
      usersEntityObj(data?.EntityUser);
    },
  });

  const logoutUser = async () => {
    await logoutCurrentUser();
    history.replace('/');
    window.location.reload();
    return;
  };

  const { loading: getUserLoading } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    skip: !authToken || Object.keys(userObject).length > 0,
    onCompleted: (data: { user: UserData }) => {
      if (data?.user?.status !== 'active' || !data?.user?.entity?.isActive) {
        return logoutUser();
      }
      userObj(data?.user);
      const roles = data?.user?.entity?.roles;
      if (roles && roles.length) {
        let roleWithColor: Record<string, string> = {};
        roles.forEach((role) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error?.message);
    },
  });

  const appInitialising = useLoadingDelay(getUserLoading);

  useEffect(() => {
    updateUserLoading(getUserLoading);
    updateUsersEntityLoading(entityUsersLoading);
  }, [entityUsersLoading, getUserLoading]);

  // const { execute: executeGetUserData } =
  //   getLoggedInUserDataHandler(onGetUserSuccess);
  // const { execute: executeGetEntityUsersData } =
  //   getUsersEntityHandler(onUsersEntitySuccess);
  // const { execute: executeGetFoldersList } = getFoldersList();

  // useEffect(() => {
  //   // @ts-ignore
  //   if (localStorage.getItem('authToken') !== null && !userObject?.eid) {
  //     executeGetUserData();
  //   }
  // }, [localStorage.getItem('authToken'), userObject?.eid]);

  useEffect(() => {
    sessionStorage?.setItem('amplitudeInit', '0');
    sessionStorage?.setItem('mixPanelInit', '0');
  }, []);

  useEffect(() => {
    if (userObject?.eid) {
      let isValidated = authorization(
        { permittedRoles: [AuthRole.SUPER_ADMIN] },
        'validated',
        null
      );
      if (isValidated) {
        bootWithProps({
          email: userObject?.email,
          userId: userObject?.eid,
          customAttributes: {
            businessName: userObject?.entity?.name,
            businessId: userObject?.entity?.eid,
            authRole: userObject?.authRole,
          },
          hideDefaultLauncher: true,
          alignment: 'left',
          horizontalPadding: 50,
          verticalPadding: 50,
        });
      }
      // eslint-disable-next-line no-console
      console.log('Initialize call again');
      if (typeof window !== undefined) {
        // @ts-ignore
        window.heap.identify(userObject?.eid);
        // @ts-ignore
        window.heap.addUserProperties({
          userId: userObject?.eid,
          name: userObject?.name,
          role: userObject?.role,
          authRole: userObject?.authRole,
          businessName: userObject?.entity?.name,
          businessId: userObject?.entity?.eid,
          email: userObject?.email,
          username: userObject?.username,
        });
      }
    }
  }, [userObject?.eid]);

  if (appInitialising) {
    return null;
  }

  return (
    <Suspense fallback={<SplashScreen />}>
      <RouteComponentsRender />
    </Suspense>
  );
};

export default Sentry.withProfiler(App);
