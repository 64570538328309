import { gql } from '@apollo/client';

export const ARCHIVED_AUDITS = gql`
  query ArchivedAudits(
    $filter: FilterFindManyAuditInput
    $page: Int
    $perPage: Int
    $sort: SortFindManyAuditInput
    $sessionSort: SortFindManyAuditSessionInput
    $sessionLimit: Int
  ) {
    AuditPagination(
      filter: $filter
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        eid
        title
        lastUpdatedOn
        auditors {
          userId
          locations
          assignType
        }
        statusLogs {
          status
          createdBy
          createdByUser {
            eid
            name
            profilePic
          }
          comment
          statusDate
        }
        status
        sessions(sort: $sessionSort, limit: $sessionLimit) {
          eid
        }
      }
    }
  }
`;
