import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import moment from 'moment';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { SessionDetailEntity } from 'sub-components/audits/take-audit/types';
import { usersEntityObj } from 'sub-components/Header';
import GraphLocationSelection, {
  LocationCellRender,
} from 'sub-components/tasks/performance/overview/location/GraphLocationSelection';
import { toArray } from 'utils';
import { SelectOption } from '../../../../../../atoms';
import { HeaderColors } from '../../../../../../shared/headerColors';
import { getIndexColor } from '../../../../../../utils/randomColor';
import GenericColorHeader from '../../../../../GenericColorHeader';
import {
  AxisPercentageDomain,
  toPercent,
  TrendAnalytic,
  getGraphLabel,
  GraphMode,
} from '../../../../../tasks/performance/common';
import {
  GraphFooter,
  GraphLoader,
  GraphMonthWeekSelect,
  getToolTipConfig,
  IGraphRange,
  useGraphRangeVariable,
} from '../../../../../tasks/performance/common/graph';

interface AuditLocationTrendAnalytics {
  [locationId: string]: LocationTrend;
}

export interface LocationTrend {
  [month: string]: TrendData;
}

interface TrendData {
  start: string;
  end: string;
  totalScoreSum: number;
  maxScoreSum: number;
  totalFlagged: number;
  scoreAvg: number;
}

export interface AuditLocationResponse {
  AuditLocationTrendAnalytics: AuditLocationTrendAnalytics;
}

export interface AuditLocationVariable extends IGraphRange {
  eid: string;
}

export const AUDIT_LOCATION_TREND = gql`
  query Query(
    $eid: String!
    $type: AuditAnalyticsTypeEnum!
    $startDate: Date
    $endDate: Date
  ) {
    AuditLocationTrendAnalytics(
      eid: $eid
      type: $type
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

const LocationLineGraph: FC<{
  auditData: AuditSessionEntity | undefined;
}> = ({ auditData }) => {
  const selectedLocationFilter = useAuditDetailContext(
    (state) => state.selectedLocationFilter
  );
  const { auditId } = useParams<{ auditId: string; sessionId: string }>();
  const entityData = useReactiveVar(usersEntityObj);
  const [selectedLocation, setSelectedLocation] = useState<string[]>();
  const [variables, setVariable] = useGraphRangeVariable();

  const { data, loading } = useQuery<
    AuditLocationResponse,
    AuditLocationVariable
  >(AUDIT_LOCATION_TREND, {
    fetchPolicy: 'network-only',
    variables: {
      ...variables,
      eid: auditId,
    },
  });

  const getGraphTooltipLabel = (value: TrendData, type: GraphMode) => {
    if (type === 'weekly') {
      return moment(value.end).format('wo').concat(' Weeks');
    } else {
      return moment(value.start).format('MMMM');
    }
  };

  const [graphData, locationNames] = useMemo(() => {
    const resData = data?.AuditLocationTrendAnalytics || {};
    let filteredLocations =
      entityData?.filter((loc) =>
        auditData?.assignments?.some(
          (assignment) => assignment?.locationId === loc?.eid
        )
      ) || [];
    const locRecord = toArray(filteredLocations).reduce<Record<string, string>>(
      (acc, value) => {
        acc[value.eid] = value.name;
        return acc;
      },
      {}
    );
    const _locationNames = Object.values(locRecord);
    const newData = Object.entries(resData).reduce((acc, [locId, records]) => {
      Object.entries(records).forEach(([weekMonth, value]) => {
        const prev = acc[weekMonth];

        let locationName = locRecord[locId];
        if (locationName) {
          if (prev) {
            acc[weekMonth] = {
              ...prev,
              [locationName]: (value.scoreAvg * 100)?.toFixed(2) || 0,
            };
          } else {
            acc[weekMonth] = {
              tooltipLabel: getGraphTooltipLabel(value, variables.type),
              month: getGraphLabel(
                value as unknown as TrendAnalytic,
                variables.type
              ),
              [locationName]: (value.scoreAvg * 100)?.toFixed(2) || 0,
            };
          }
        }
      });

      return acc;
    }, {} as Record<string, object>);

    return [Object.values(newData), _locationNames];
  }, [data, entityData, auditData]);

  useEffect(() => {
    if (selectedLocationFilter?.value === 'allLocations') {
      return;
    }
    if (
      selectedLocationFilter?.value !== 'allLocations' &&
      selectedLocationFilter?.value
    ) {
      setSelectedLocation([selectedLocationFilter.label]);
    }
  }, [selectedLocationFilter]);

  const onLocationClick = (value: string) => {
    console.log('value : ', value);
    setSelectedLocation((prevState = []) => {
      if (prevState.includes(value)) {
        return [...prevState.filter((it) => it !== value)];
      }
      return [...prevState, value];
    });
  };

  const filteredLocations = useMemo(() => {
    if (selectedLocation?.length) {
      return selectedLocation;
    }
    return locationNames;
  }, [locationNames, selectedLocation]);

  const onGraphTypeChange = (newVariables: IGraphRange) => {
    setVariable((prevState) => {
      return {
        ...prevState,
        ...newVariables,
      };
    });
  };

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='10px'
      p='16px 20px'
      gap={4}
      w='full'
      h='fit-content'
    >
      <Flex flexDir='column' gap='12px'>
        <Flex justify='space-between' align='center'>
          <GenericColorHeader
            title='Location wise performance'
            color={HeaderColors.Purple}
          />

          {(!selectedLocationFilter ||
            selectedLocationFilter?.value === 'allLocations') && (
            <GraphLocationSelection
              locations={locationNames}
              selected={selectedLocation}
              onChange={onLocationClick}
            />
          )}
        </Flex>

        {(!selectedLocationFilter ||
          selectedLocationFilter?.value === 'allLocations') && (
          <Flex justify='space-between'>
            <LocationCellRender
              selected={selectedLocation}
              onChange={onLocationClick}
            />

            <GraphMonthWeekSelect
              data={variables}
              onChange={onGraphTypeChange}
            />
          </Flex>
        )}
      </Flex>

      <GraphLoader isLoading={loading} minHeight={260}>
        <ResponsiveContainer width='100%' height={260}>
          <LineChart data={graphData}>
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis axisLine={false} dataKey='month' />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={toPercent}
              domain={AxisPercentageDomain}
            />

            <Tooltip
              labelFormatter={(label, payload) => {
                return (payload?.[0]?.payload?.tooltipLabel || label).concat(
                  ' (Completion score)'
                );
              }}
              {...getToolTipConfig()}
              // @ts-ignore
              itemSorter={(item) => 0 - item.value}
            />

            {filteredLocations.map((locName, index) => {
              return (
                <Line
                  key={locName}
                  type='linear'
                  dataKey={locName}
                  stroke={getIndexColor(index)}
                  dot={false}
                  unit='%'
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </GraphLoader>

      <GraphFooter data={variables} onChange={onGraphTypeChange} />
    </Flex>
  );
};

export default LocationLineGraph;
