import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import { Box, Flex, Text } from '@chakra-ui/react';

interface GaugeProps {
  currentMonthFlag: number;
  previousMonthFlag: number;
}

const Gauge: React.FC<GaugeProps> = ({
  currentMonthFlag,
  previousMonthFlag,
}) => {
  const difference = currentMonthFlag - previousMonthFlag;
  const absDifference = Math.abs(difference);

  const maxFlag = Math.max(currentMonthFlag, previousMonthFlag, 1);

  const ranges = [
    {
      name: 'Normal',
      color: '#B5E4CA',
      startPercent: 0,
      endPercent: 33,
    },
    {
      name: 'Needs Attention',
      color: '#FFD88D',
      startPercent: 34,
      endPercent: 66,
    },
    {
      name: 'Problematic',
      color: '#FF6A55',
      startPercent: 67,
      endPercent: 100,
    },
  ];

  const currentFlagPercent = (currentMonthFlag / maxFlag) * 100;

  const currentRange = ranges.find(
    (range) =>
      currentFlagPercent >= range.startPercent &&
      currentFlagPercent <= range.endPercent
  );

  const data = ranges.map((range) => ({
    name: range.name,
    value: range.endPercent - range.startPercent,
    color: range.color,
    isCurrent: currentRange?.name === range.name,
    startPercent: range.startPercent,
    endPercent: range.endPercent,
  }));

  const flagColor = currentRange ? currentRange.color : '#000';

  return (
    <Box position='relative' width='250px' height='230px'>
      <ResponsiveContainer width='100%' height='100%'>
        <PieChart>
          <Pie
            data={data}
            startAngle={180}
            endAngle={0}
            innerRadius={75}
            outerRadius={100}
            paddingAngle={2}
            dataKey='value'
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                stroke={entry.isCurrent ? entry.color : '#fff'}
                strokeWidth={entry.isCurrent ? 2 : 1}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      <Flex
        position='absolute'
        top='50%'
        left='50%'
        transform='translate(-50%, -50%)'
        flexDir='column'
        align='center'
        justify='center'
        pointerEvents='none'
      >
        <Flex align='center' justify='center'>
          <Text fontSize='2rem' fontWeight='bold' color='#000'>
            {currentMonthFlag}
          </Text>
          <FontAwesomeIcon
            icon={faFlag as IconProp}
            fontSize='1.125rem'
            style={{
              marginLeft: '0.5rem',
              color: flagColor,
            }}
          />
        </Flex>

        <Text fontSize='1rem' color='#000'>
          {currentMonthFlag === 1 ? 'Flag' : 'Flags'}
        </Text>

        {difference > 0 && (
          <Flex align='center' justify='center' mt={1} w='max-content'>
            <Text ml={1} fontSize='12px' color='#6F767E'>
              Flag increased by {difference} from last month
            </Text>
          </Flex>
        )}
        {difference < 0 && (
          <Flex align='center' justify='center' mt={1} w='max-content'>
            <Text ml={1} fontSize='12px' color='#6F767E'>
              Flag decreased by {absDifference} from last month
            </Text>
          </Flex>
        )}
        {difference === 0 && (
          <Flex align='center' justify='center' mt={1} w='max-content'>
            <Text mt={1} fontSize='12px' color='#6F767E'>
              No change in flags from last month
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Gauge;
