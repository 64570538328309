import { useReactiveVar } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectOption } from 'atoms';
import React, { FC, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';
import { HeaderColors } from 'shared/headerColors';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { SessionDetailEntity } from 'sub-components/audits/take-audit/types';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { usersEntityObj } from 'sub-components/Header';
import {
  AxisPercentageDomain,
  toPercent,
} from 'sub-components/tasks/performance';
import { getToolTipConfig } from 'sub-components/tasks/performance/common/graph';
import Filter from '../../Filter/Filter';

interface GraphData {
  name: string;
  completeRate: string;
}

interface IProps {
  auditData: AuditSessionEntity | undefined;
}

const CustomBar = (props: any) => {
  const value = props.value;

  return <Rectangle {...props} fill='rgba(181, 228, 202, 1)' />;
};

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor='end'
        fill='#2E2C34'
        transform='rotate(-90)'
      >
        {payload.value.length > 9
          ? `${payload.value.substring(0, 8)}...`
          : payload.value}
      </text>
    </g>
  );
};

const LocationPerformanceGraph: FC<IProps> = ({ auditData }) => {
  const entityData = useReactiveVar(usersEntityObj);
  const [selectedLocations, setSelectedLocations] = useState<SelectOption[]>(
    []
  );
  const graphData = useMemo(() => {
    let arr: GraphData[] = [];
    const selectedValues = selectedLocations.map((loc) => loc.value);

    auditData?.assignments?.forEach((assignment) => {
      if (
        selectedValues.length === 0 ||
        selectedValues.includes(assignment.locationId)
      ) {
        let foundLocation = entityData?.find(
          (entity) => entity?.eid === assignment?.locationId
        );
        if (foundLocation) {
          let maxScore = assignment?.maxScore;
          let baggedScore = assignment?.totalScore;

          const calculatedScore = (baggedScore / maxScore) * 100;
          arr.push({
            name: foundLocation?.name,
            completeRate: calculatedScore?.toFixed(2),
          });
        }
      }
    });
    return arr;
  }, [auditData, entityData, selectedLocations]);

  const locationOptions = useMemo(() => {
    const assignments = auditData?.assignments ?? [];
    const entities = entityData ?? [];
    const entityMap = new Map(entities.map((entity) => [entity.eid, entity]));

    const options = assignments.map((assignment) => {
      const foundLocation = entityMap.get(assignment.locationId);
      if (foundLocation) {
        return {
          label: foundLocation.name,
          value: foundLocation.eid,
        };
      }
      return null;
    });

    return options.filter((option): option is SelectOption => option !== null);
  }, [auditData, entityData]);

  const handleFilterChange = (option: SelectOption) => {
    setSelectedLocations((prevFilters) => {
      const isSelected = prevFilters.some(
        (filter) => filter.value === option.value
      );
      if (isSelected) {
        return prevFilters.filter((filter) => filter.value !== option.value);
      } else {
        return [...prevFilters, option];
      }
    });
  };

  const removeSelectedLocationHandler = (location: SelectOption) => {
    setSelectedLocations((prevFilters) =>
      prevFilters.filter((filter) => filter.value !== location.value)
    );
  };

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='10px'
      p='16px 20px'
      gap={4}
      w='full'
    >
      <Flex align='center' justify='space-between'>
        <GenericColorHeader
          title='Location wise performance'
          color={HeaderColors.Purple}
        />
        <Filter
          placeholder='Compare location(s)'
          options={locationOptions}
          value={selectedLocations}
          onChange={handleFilterChange}
        />
      </Flex>
      <Flex align='baseline'>
        <Flex flexWrap='wrap' gap='5px' w='90%'>
          {selectedLocations?.map((location) => (
            <Flex
              key={location?.value}
              border='1px solid rgba(227, 225, 229, 1)'
              borderRadius='19px'
              p='2px 8px'
              bg='rgba(182, 180, 186, 0.1)'
              align='center'
              gap='5px'
              color='rgba(132, 129, 138, 1)'
              cursor='pointer'
              onClick={() => removeSelectedLocationHandler(location)}
            >
              {location?.label}
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </Flex>
          ))}
        </Flex>
        <Flex gap='5px' align='center' minW='fit-content' justify='flex-end'>
          <Box boxSize='16px' borderRadius='4px' bg='rgba(181, 228, 202, 1)' />
          <Box as='span'>Audit Score</Box>
        </Flex>
      </Flex>
      <ResponsiveContainer width='100%' height={400}>
        <BarChart
          data={graphData}
          barSize={24}
          barCategoryGap={16}
          margin={{
            bottom: 50,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray='3 3' />
          <XAxis
            axisLine={false}
            dataKey='name'
            height={40}
            interval={0}
            tick={<CustomizedAxisTick />}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={toPercent}
            domain={AxisPercentageDomain}
          />
          <Tooltip
            cursor={false}
            {...getToolTipConfig()}
            formatter={(value) => {
              return [`${value}%`, [] as any];
            }}
          />
          <Bar
            dataKey='completeRate'
            radius={4}
            fill='#5542F6'
            shape={CustomBar}
          />
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default LocationPerformanceGraph;
