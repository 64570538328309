import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { QuestionType } from '../../../../../types';
import { IFormInput } from '../../form-types';
import { AuditQuestionView } from '../audit-question-view';
import AuditQuestion from './AuditQuestion';
import { AuditSection } from './section';
import { IFooterProps } from './audit-options';

interface IProps extends IFooterProps {
  fieldId: string;
}

const AuditQuestionContainer: FC<IProps> = ({
  questionIndex,
  handleRemove,
  handleDuplicate,
  fieldId,
}) => {
  const [selectedQuestion, questionId, qType, isDisabled] = useWatch<
    IFormInput,
    [
      'selectedQuestion',
      `questions.${number}.tempEid`,
      `questions.${number}.qType`,
      `questions.${number}.isDisabled`
    ]
  >({
    name: [
      'selectedQuestion',
      `questions.${questionIndex}.tempEid`,
      `questions.${questionIndex}.qType`,
      `questions.${questionIndex}.isDisabled`,
    ],
  });

  if (isDisabled) {
    return null;
  }

  if (qType === QuestionType.SECTION) {
    return (
      <AuditSection
        fieldId={fieldId}
        questionIndex={questionIndex}
        handleRemove={handleRemove}
        // isReadOnly={isReadOnly}
      />
    );
  }

  if (selectedQuestion === questionId) {
    return (
      <AuditQuestion
        fieldId={fieldId}
        questionIndex={questionIndex}
        handleRemove={handleRemove}
        handleDuplicate={handleDuplicate}
      />
    );
  }

  return <AuditQuestionView fieldId={fieldId} questionIndex={questionIndex} />;
};

export default AuditQuestionContainer;
