import React, { FC } from 'react';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'shared/headerColors/header-colors';
import AuditFrequency from './AuditFrequency';
import AuditDue from './AuditDue';
import { Container } from '../shared';
import FrequencyInfoText from './FrequencyInfoText';

interface IProps {
  isEditing?: boolean;
}

const Frequency: FC<IProps> = ({ isEditing }) => {
  return (
    <Container>
      <BoxHeader
        title='Frequency'
        fontSize='18px'
        boxWidth={3}
        color={HeaderColors.Purple}
      />

      <AuditFrequency isEditing={isEditing} />
      <AuditDue />
      <FrequencyInfoText />
    </Container>
  );
};

export default Frequency;
