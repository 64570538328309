import React, { FC } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { Dropdown } from '../../../../../atoms';
import { BoxHeader } from '../../../../../ui-components';
import { eventBus } from '../../../../../shared/eventEmit';
import { useTemplatesData } from '../useTemplatesData';
import { TemplateEvent, useSelectedTemplate } from './useSelectedTemplate';

interface IProps {
  templateId: string;
}

const SchedulePreviewHeader: FC<IProps> = ({ templateId: _templateId }) => {
  const { loading, options } = useTemplatesData();

  const template = useSelectedTemplate(_templateId);

  return (
    <Flex flexDir='column' gap={5}>
      <Flex align='center' justify='space-between'>
        <BoxHeader
          boxWidth={3}
          title='Template'
          color='#CABDFF'
          fontSize='18px'
        />
        <AlertDialogCloseButton
          size='sm'
          position='relative'
          colorScheme='blue'
          right='initial'
          top='initial'
        />
      </Flex>

      <Dropdown
        size='lg'
        value={template}
        onChange={(newValue) => eventBus.emit(TemplateEvent, newValue)}
        isLoading={loading}
        options={options}
        getOptionLabel={(option) => {
          if (!option?.label && option?.value) {
            return options.find((o) => o.value === option?.value)?.label;
          }
          return option?.label;
        }}
        selectStyles={{
          menu: {
            zIndex: 11,
          },
        }}
        inputStyle={{
          borderWidth: '2px',
          borderRadius: '12px',
          outline: 'none',
          backgroundColor: 'white',
        }}
      />
    </Flex>
  );
};

export default SchedulePreviewHeader;
