import { useReactiveVar } from '@apollo/client';
import {
  Center,
  Checkbox,
  HStack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from 'atoms';
import React, { FC, useMemo, useState } from 'react';
import EmptyState from 'sub-components/EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import { Filters } from '../types';

const LocationView: FC<{
  filters: Filters;
  setCurrentView: React.Dispatch<
    React.SetStateAction<'initial' | 'dueDate' | 'locations'>
  >;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}> = ({ filters, setCurrentView, setFilters }) => {
  const allLocations = useReactiveVar(usersEntityObj);
  const [locationSearch, setLocationSearch] = useState('');

  const filteredLocations = useMemo(() => {
    return (
      allLocations?.filter(
        (loc) =>
          loc?.type === 'branch' &&
          loc?.name?.toLowerCase()?.includes(locationSearch?.toLowerCase())
      ) || []
    );
  }, [allLocations, locationSearch]);

  return (
    <VStack align='start' spacing={4} w='full'>
      <HStack w='full'>
        <FontAwesomeIcon
          icon={faArrowLeft as IconProp}
          color='rgba(111, 118, 126, 1)'
          cursor='pointer'
          fontSize='20px'
          onClick={() => setCurrentView('initial')}
        />
        <Text fontWeight='bold'>
          Assigned to Locations{' '}
          {filters.locations.length > 0 && `(${filters.locations.length})`}
        </Text>
      </HStack>
      {filteredLocations?.length === 0 && !locationSearch ? (
        <Center w='full' h='470px'>
          <EmptyState image='Location' title='No locations found' />
        </Center>
      ) : (
        <>
          <SearchInput
            placeholder='Search locations'
            hideShortcuts
            value={locationSearch}
            onChange={(e) => setLocationSearch(e.target.value)}
          />
          <Stack maxHeight='470px' overflowY='auto' width='100%'>
            {filteredLocations.length > 0 ? (
              filteredLocations.map((location) => (
                <Checkbox
                  key={location.eid}
                  isChecked={filters.locations.includes(location.eid)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilters((prev) => ({
                        ...prev,
                        locations: [...prev.locations, location.eid],
                      }));
                    } else {
                      setFilters((prev) => ({
                        ...prev,
                        locations: prev.locations.filter(
                          (id) => id !== location.eid
                        ),
                      }));
                    }
                  }}
                >
                  {location.name}
                </Checkbox>
              ))
            ) : (
              <Center h='400px'>
                <EmptyState
                  image='Search'
                  title='Searched Location not found'
                />
              </Center>
            )}
          </Stack>
        </>
      )}
    </VStack>
  );
};

export default LocationView;
