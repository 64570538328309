import { Flex, Box, Text } from '@chakra-ui/react';
import { QuestionImage } from 'sub-components/forms/create-form';
import { useTranslation } from 'react-i18next';
import { QuestionsEntity } from 'sub-components/audits/report/types/report.types';
import { FC } from 'react';

interface IProps {
  field: any;
  suggestedQuestions: QuestionsEntity[];
  setInputSelectedQuestion: (item: any) => void;
}

const AuditQuestionSearchSuggestion: FC<IProps> = ({
  field,
  suggestedQuestions,
  setInputSelectedQuestion,
}) => {
  const { t } = useTranslation('form', { keyPrefix: 'questionType' });

  return (
    <>
      <Box
        style={{
          backgroundColor: '#FFFFFF',
          boxShadow: '3px 3px 5px 5px #EFEFEF',
          padding: '20px',
          marginTop: '10px',
          borderRadius: '12px',
          zIndex: '999',
          position: 'absolute',
          width: '100%',
        }}
        className='custom-popup'
      >
        {suggestedQuestions?.length > 0 && field?.value?.length > 0 && (
          <Box
            className='custom-popup'
            maxHeight={'400px'}
            style={{ overflowY: 'auto' }}
          >
            {suggestedQuestions?.map((question) => {
              const questionType = QuestionImage[question.qType];

              return (
                <Box
                  className='custom-popup'
                  onClick={() => {
                    setInputSelectedQuestion(question);
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                  my={'10px'}
                  key={question?.eid}
                >
                  <Flex
                    className='custom-popup'
                    _hover={{
                      backgroundColor: '#EFEFEF',
                      transition: '0.1s ease',
                    }}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      width={'36vw'}
                      className='custom-popup'
                      lineHeight={'24px !important'}
                      fontWeight={600}
                      fontSize={'14px'}
                    >
                      {question?.label}
                    </Text>
                    <Flex
                      className='custom-popup'
                      px={'5px'}
                      backgroundColor={'#EFEFEF'}
                      borderRadius={'4px'}
                      width={'128px'}
                      height={'36px'}
                      gap={2}
                      alignItems={'center'}
                    >
                      <Box className='custom-popup' boxSize='16px'>
                        <img
                          className='custom-popup'
                          alt={questionType?.title}
                          src={questionType?.image}
                        />
                      </Box>
                      <Box
                        className='custom-popup'
                        fontSize='12px'
                        flex={1}
                        fontWeight='600'
                        isTruncated
                      >
                        {t(questionType?.title)}
                      </Box>
                    </Flex>
                  </Flex>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export default AuditQuestionSearchSuggestion;
