import React, { CSSProperties, FC } from 'react';
import { ITaskStatus } from './types';
import { Image } from '@chakra-ui/react';

import inProgressTaskIcon from 'assets/images/inProgressTask.svg';
import completedTaskIcon from 'assets/images/tasks/completed.svg';
import overdueTaskIcon from 'assets/images/tasks/overdue.svg';
import partiallyCompletedTaskIcon from 'assets/images/tasks/partiallyCompleted.svg';
import scheduledTaskIcon from 'assets/images/tasks/scheduled.svg';
import terminatedTaskIcon from 'assets/images/tasks/terminated.svg';

interface IProps {
  status: ITaskStatus | undefined;
  imgSize?: string;
  iconStyles?: CSSProperties;
}

const statusIconMap: { [key in ITaskStatus]: string } = {
  completed: completedTaskIcon,
  inProgress: inProgressTaskIcon,
  overdue: overdueTaskIcon,
  partiallyCompleted: partiallyCompletedTaskIcon,
  scheduled: scheduledTaskIcon,
  terminated: terminatedTaskIcon,
};

const TaskStatusIcon: FC<IProps> = ({
  status,
  imgSize = '15px',
  iconStyles,
}) => {
  if (!status) return null;
  const IconSrc = statusIconMap[status];
  if (!IconSrc) return null;

  return (
    <Image src={IconSrc} boxSize={imgSize} style={iconStyles} minW={imgSize} />
  );
};

export default TaskStatusIcon;
