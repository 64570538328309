import React, { FC, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useRect } from '../../../../hooks';
import { ActionButton } from '../../../../ui-components';
import { Any } from '../../../../types';

interface ModalProps {
  left?: number;
  width?: number;
  selected?: React.Key[];
  onDeletePress?: () => Promise<void | Any>;
}

const InternalModal: FC<ModalProps> = ({
  left,
  width,
  selected,
  onDeletePress,
}) => {
  const { t } = useTranslation(['audit']);

  if (!selected?.length) {
    return null;
  }

  return (
    <Flex
      height='80px'
      justify='space-between'
      align='center'
      bg='#2A85FF'
      position='fixed'
      bottom='-4px'
      left={left && `${left - 30}px`}
      width={width && `${width + 60}px`}
      paddingX='30px'
      zIndex={10}
    >
      <Box fontSize='15px' fontWeight={600} color='#FCFCFC'>
        <Trans
          t={t}
          i18nKey='audit:templateSelect'
          count={selected?.length}
          values={{ value: selected?.length }}
        />
      </Box>
      <ActionButton actionFn={onDeletePress}>Delete</ActionButton>
    </Flex>
  );
};

interface IProps extends Pick<ModalProps, 'onDeletePress'> {
  selected?: React.Key[];
}

const BulkArchiveModal: FC<IProps> = ({ selected, onDeletePress }) => {
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);

  return (
    <Flex ref={ref} height={selected?.length ? '60px' : undefined}>
      <InternalModal
        left={rect?.left}
        width={rect?.width}
        selected={selected}
        onDeletePress={onDeletePress}
      />
    </Flex>
  );
};

export default React.memo(BulkArchiveModal);
