import React, { FC } from 'react';
import { getCharFromNumber } from 'utils';

interface IProps {
  index: number;
}
export const StepIconRender: FC<IProps> = (props) => {
  return (
    <div className='ant-steps-custom-icon'>
      {getCharFromNumber(props.index + 1)}
    </div>
  );
};

export const VerticalStepIconRender: FC<IProps> = (props) => {
  return <div className='ant-steps-custom-icon'>{props.index + 1}</div>;
};
