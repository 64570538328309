import React, { FC } from 'react';
import { Box, Center, Checkbox, Flex } from '@chakra-ui/react';
import { Input, Textarea } from '../../../../atoms';
import { AuditQuestionEntity, QuestionType } from '../../../../types';
import {
  DropdownType,
  Location,
  Member,
} from '../../take-audit/components/question-types';

import { ReactComponent as FileIcon } from '../../../../assets/images/profile-cloud-upload.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/images/image.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/images/videoPreview.svg';

interface IProps {
  qType: QuestionType;
  options: AuditQuestionEntity['options'];
}

const InputFieldView: FC<IProps> = ({ qType, options }) => {
  if (qType === QuestionType.CHECKBOX) {
    return (
      <Flex
        flexDir='column'
        gap={3}
        sx={{
          '.chakra-checkbox__label': {
            color: '#6F767E',
            fontSize: 14,
            fontWeight: 500,
          },
          '.chakra-checkbox__control': {
            borderRadius: '6px',
          },
        }}
      >
        {options?.map((option, index) => {
          if (option?.isDisabled) {
            return null;
          }
          return (
            <Checkbox
              key={option?.eid || `option-${index}`}
              value={option?.eid}
              size='lg'
              isReadOnly
            >
              {option?.label || '- -'}
            </Checkbox>
          );
        })}
      </Flex>
    );
  }

  if (qType === QuestionType.MULTI_CHOICE) {
    return (
      <Flex gap={3} flexDir='column'>
        {options?.map((option, index) => {
          if (option?.isDisabled) {
            return null;
          }
          return (
            <Flex
              key={option?.eid || `option-${index}`}
              cursor='pointer'
              border='2px solid #EFEFEF'
              borderRadius='12px'
              p='12px 20px'
              fontWeight={500}
              color='#6F767E'
            >
              {option?.label || '- -'}
            </Flex>
          );
        })}
      </Flex>
    );
  }

  if (qType === QuestionType.DROPDOWN) {
    return <DropdownType options={options} isReadOnly />;
  }
  if (qType === QuestionType.LOCATION) {
    return <Location isReadOnly />;
  }
  if (qType === QuestionType.MEMBER) {
    return <Member isReadOnly />;
  }
  if (qType === QuestionType.FILE_UPLOAD) {
    return (
      <Center
        h='48px'
        bg='gray.100'
        borderRadius='12px'
        gap={2}
        fontSize='15px'
        fontWeight='400'
        cursor='pointer'
      >
        <FileIcon width='20' height='20' color='#33383F' />
        Add file
      </Center>
    );
  }
  if (qType === QuestionType.IMAGE_UPLOAD) {
    return (
      <Center
        h='48px'
        bg='gray.100'
        borderRadius='12px'
        gap={2}
        fontSize='15px'
        fontWeight='400'
        cursor='pointer'
      >
        <ImageIcon width='20' height='20' color='#33383F' />
        Upload image
      </Center>
    );
  }
  if (qType === QuestionType.VIDEO_UPLOAD) {
    return (
      <Center
        h='48px'
        bg='gray.100'
        borderRadius='12px'
        gap={2}
        fontSize='15px'
        fontWeight='400'
        cursor='pointer'
      >
        <VideoIcon width='20' height='20' color='#33383F' />
        Upload video
      </Center>
    );
  }

  if (qType === QuestionType.LONG_TEXT) {
    return (
      <Flex flexDir='column' gap={2}>
        <Textarea
          bg='rgba(244, 244, 244, 1)'
          isReadOnly
          _focus={{
            background: 'rgba(244, 244, 244, 1)',
          }}
        />
        <Box
          as='span'
          color='rgba(111, 118, 126, 1)'
          fontSize='12px'
          fontWeight={500}
        >
          Character count: {0}/500
        </Box>
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap={2}>
      <Input size='lg' bg='rgba(244, 244, 244, 1)' isReadOnly />
      <Box
        as='span'
        color='rgba(111, 118, 126, 1)'
        fontSize='12px'
        fontWeight={500}
      >
        Character count: {0}/100
      </Box>
    </Flex>
  );
};

export default InputFieldView;
