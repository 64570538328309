import React, { FC } from 'react';
import {
  Box,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { Steps } from 'antd';
import { AddIcon } from '@chakra-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';

import { TitleForm } from '../../common';

import { IFormInput } from '../editor.types';

interface IProps {
  emptySection?: boolean;
  onSubmit?: (value: string) => void | Promise<void>;
}

const AddSectionTitle: FC<IProps> = ({ onSubmit, emptySection }) => {
  const { setValue } = useFormContext<IFormInput>();

  const sectionTitle = useWatch<IFormInput, 'sectionTitle'>({
    name: 'sectionTitle',
  });

  if (sectionTitle === -2 || emptySection) {
    return (
      <Popover
        isOpen
        isLazy
        placement='bottom-start'
        onClose={() => setValue('sectionTitle', undefined)}
      >
        {({ onClose }) => (
          <>
            {/*// @ts-ignore */}
            <PopoverTrigger>
              <Box alignSelf='start' cursor='pointer'>
                <Steps.Step
                  prefixCls='ant-steps'
                  className='add-section'
                  // @ts-ignore
                  id='add-launcher-section-btn' // id is being used to focus the element
                  icon={
                    <Center boxSize='full'>
                      <AddIcon boxSize='14px' color='#2A85FF' />
                    </Center>
                  }
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius='12px' width='440px' boxShadow='md'>
              <PopoverArrow />
              <PopoverBody px={4}>
                <TitleForm
                  addTitle='common:add'
                  formTitle='launcher:addNewPhase'
                  onSubmit={onSubmit}
                  onClose={emptySection ? undefined : onClose}
                />
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    );
  }

  return (
    <Box alignSelf='start' cursor='pointer'>
      <Steps.Step
        prefixCls='ant-steps'
        className='add-section'
        // @ts-ignore
        id='add-launcher-section-btn' // id is being used to focus the element
        icon={
          <Center boxSize='full'>
            <AddIcon boxSize='14px' color='#2A85FF' />
          </Center>
        }
        onClick={() => setValue('sectionTitle', -2)}
      />
    </Box>
  );
};

export default AddSectionTitle;
