import React, { FC } from 'react';
import { Box, Flex, Progress } from '@chakra-ui/react';
import { IconImage } from '../../../../../../../ui-components';
import { HeaderColors } from 'shared/headerColors';
import { useSendReminder } from './SendReminder/useSendReminder';
import { useUserDataSelector } from 'hooks';
import { AuditStatusFunctionEntity } from 'sub-components/audits/audit-tab/supervised/types';

interface IProps {
  eid: string;
  name: string;
  profilePic?: string;
  auditStatus: AuditStatusFunctionEntity;
  // totalLocation: number;
  // completed: number;
}

const AuditorItem: FC<IProps> = ({
  eid,
  name,
  profilePic,
  auditStatus,
  // totalLocation,
  // completed,
}) => {
  const loggedInUserEid = useUserDataSelector((state) => state?.eid);
  const sendReminder = useSendReminder();

  const sendRemindHandler = () => {
    sendReminder({
      auditorDetails: {
        eid,
        name,
      },
    });
  };

  return (
    <Flex
      flexDir='column'
      border='1px solid #EFEFEF'
      borderRadius='8px'
      gap={4}
      p='12px'
    >
      <Flex align='center' justify='space-between'>
        <Flex align='center' gap='8px'>
          <IconImage
            name={name}
            thumbnail={profilePic}
            boxSize={24}
            borderRadius='50%'
            objectFit='cover'
          />
          <Box fontWeight={600}>{name}</Box>
        </Flex>
        {eid !== loggedInUserEid && auditStatus !== 'completed' && (
          <Box
            as='span'
            cursor='pointer'
            textDecor='underline'
            color='rgba(42, 133, 255, 1)'
            fontSize='12px'
            fontWeight={600}
            onClick={sendRemindHandler}
          >
            Send Reminder
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default AuditorItem;
