import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ActionButton } from '../../../../ui-components';
import { IFormInput } from '../audit-schedule.types';

interface IProps {
  onResumeClick: () => void;
  onPauseClick: () => void;
}

const PauseResumeAction: FC<IProps> = ({ onPauseClick, onResumeClick }) => {
  const { t } = useTranslation(['common']);

  const [status, auditFrequency] = useWatch<
    IFormInput,
    ['status', 'repeatDetails.auditFrequency']
  >({
    name: ['status', 'repeatDetails.auditFrequency'],
  });

  if (status === 'AUDIT_COMPLETED' && auditFrequency === 'oneTime') {
    return null;
  }

  if (!status || status === 'AUDIT_TERMINATED' || status === 'AUDIT_DELETED') {
    return null;
  }

  if (status === 'AUDIT_PAUSED') {
    return (
      <ActionButton
        variant='ghost'
        colorScheme='blackAlpha'
        actionFn={onResumeClick}
        color='#1A1D1F'
        leftIcon={<FontAwesomeIcon icon={faPlay as IconProp} />}
      >
        {t('resume')}
      </ActionButton>
    );
  }

  return (
    <ActionButton
      variant='ghost'
      colorScheme='blackAlpha'
      actionFn={onPauseClick}
      color='#1A1D1F'
      leftIcon={<FontAwesomeIcon icon={faPause as IconProp} />}
    >
      {t('pause')}
    </ActionButton>
  );
};

export default PauseResumeAction;
