import React, { FC } from 'react';
import EmptyState from '../../../EmptyState';

interface IProps {
  searchActive?: boolean;
}

const EmptyArchivedTemplates: FC<IProps> = ({ searchActive }) => {
  if (searchActive) {
    return (
      <EmptyState
        image='Search'
        imageSize='160px'
        title='Hmm, couldn’t find a template with that name'
        description='Try searching for it in the template library'
      />
    );
  }

  return (
    <EmptyState
      image='Form'
      imageSize='200px'
      title='There are currently no archived templates'
    />
  );
};

export default EmptyArchivedTemplates;
