import { SelectOption } from 'atoms';
import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import { ProcessData } from '../supervised/types';
import { ProcessData as MyAuditProcessData } from '../my-audits/types';
import { SupervisedViewType, TabType } from './audit-tab.types';

export interface AuditTabState {
  // selectedTab: TabType;
  supervisedViewType: SupervisedViewType;
  supervisedSearchQuery: string;
  auditChipsFilter: SelectOption[];
  supervisedByMeData: ProcessData[];
  myAuditData: MyAuditProcessData[];
  myAuditSearchQuery: string;
}

export interface AuditTabAction extends AuditTabState {
  // updateSelectedTab: (selecedTab: TabType) => void;
  updateSupervisedViewType: (viewType: SupervisedViewType) => void;
  updateSupervisedSearchQuery: (value: string) => void;
  updateAuditChipsFilter: (value: SelectOption[]) => void;
  updateSupervisedByMeData: (data: ProcessData[]) => void;
  updateMyAuditData: (data: MyAuditProcessData[]) => void;
  updateMyAuditSearchQuery: (query: string) => void;
}

export const useAuditTabStore = createStore<AuditTabAction>();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createAuditTabStore = () => {
  return createStore<AuditTabAction>()(
    immer((set) => ({
      // selectedTab: 'supervisedByMe' as TabType,
      supervisedViewType: 'grid' as SupervisedViewType,
      supervisedSearchQuery: '',
      auditChipsFilter: [] as SelectOption[],
      supervisedByMeData: [] as ProcessData[],
      myAuditData: [] as MyAuditProcessData[],
      myAuditSearchQuery: '',
      // updateSelectedTab: (selectedTab) => {
      //   set((state) => {
      //     state.selectedTab = selectedTab;
      //   });
      // },
      updateSupervisedViewType: (viewType: SupervisedViewType) => {
        set((state) => {
          state.supervisedViewType = viewType;
        });
      },
      updateSupervisedSearchQuery: (value: string) => {
        set((state) => {
          state.supervisedSearchQuery = value;
        });
      },
      updateAuditChipsFilter: (value: SelectOption[]) => {
        set((state) => {
          state.auditChipsFilter = value;
        });
      },
      updateSupervisedByMeData: (data: ProcessData[]) => {
        set((state) => {
          state.supervisedByMeData = data;
        });
      },
      updateMyAuditData: (data: MyAuditProcessData[]) => {
        set((state) => {
          state.myAuditData = data;
        });
      },
      updateMyAuditSearchQuery: (query: string) => {
        set((state) => {
          state.myAuditSearchQuery = query;
        });
      },
    }))
  );
};
