const Button = {
  baseStyle: {
    borderRadius: '7px',
  },
  variants: {
    outline: {
      border: '2px solid',
    },
  },
};

export default Button;
