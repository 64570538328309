import moment from 'moment';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';

export const taskRepeat = (repeatDetails: RepeatDetails<Date>): string => {
  if (repeatDetails?.taskRunFor === 'repeat') {
    let baseStr = 'Monthly';
    switch (repeatDetails?.repeatCycle) {
      case 'daily':
        return 'Daily';
      case 'weekly':
        return (
          'Weekly - ' +
          repeatDetails?.weekDays
            ?.map((day) => moment().day(day).format('ddd'))
            ?.join(', ')
        );
      case 'monthly':
        if (repeatDetails?.repeatMonthly === 'lastDay') {
          return baseStr + ' - Last Day';
        }
        if (moment(repeatDetails?.repeatMonthly)?.isValid()) {
          return (
            baseStr + ' - ' + moment(repeatDetails?.repeatMonthly)?.format('Do')
          );
        }
        return baseStr;
      case 'yearly':
        return 'Yearly - ' + moment(repeatDetails?.repeatYearly)?.format('Do');
      case 'days':
        return `After ${repeatDetails?.noOfDays} days`;
    }
  }
  return 'No repeat';
};
