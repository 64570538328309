import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';
import { useHistory } from '../../../../routes';
import { Button } from '../../../../atoms';
import { ActionButton } from '../../../../ui-components';
import { headerConfig } from '../../common';

import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';
import ErrorContent from './ErrorContent';

interface IProps {
  title: string;
  onFinishClick?: () => void;
}

const SummaryHeader: FC<IProps> = ({ title, onFinishClick }) => {
  const history = useHistory();

  const errors = useFormState().errors;

  return (
    <Flex flexDir='column' gap={2} {...headerConfig}>
      <Button
        fontSize='14px'
        fontWeight={500}
        width='max-content'
        variant='link'
        leftIcon={<BackIcon width='16' height='16' />}
        _hover={{
          textDecoration: 'none',
        }}
        onClick={history.goBack}
      >
        Back to My Audits
      </Button>

      <Flex align='center' justify='space-between'>
        <Text fontWeight={600} fontSize='28px'>
          {title}
        </Text>
        <Flex flex={1} paddingX={4}>
          <ErrorContent errors={errors} />
        </Flex>
        <Flex align='center' gap={3}>
          <ActionButton
            variant='solid'
            colorScheme='blue'
            actionFn={onFinishClick}
          >
            Finish Audit
          </ActionButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SummaryHeader;
