import React, { FC, Fragment, useContext, useState } from 'react';
import { ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import GenericColorHeader from '../../GenericColorHeader';
import { FormTemplateWrapper } from './FormTemplate.styles';
import TemplateCatSkeleton from './TemplateCatSkeleton';
import FormTemplateList from './FormTemplateList';
import FormDataContext from '../../../pages/forms/FormStore/form-data-context';
import { TemplateItem } from './form-template.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  categories?: string[];
  loadingCategory: boolean;
  onTemplateClick: (template: TemplateItem) => void;
  selectedTemplate?: TemplateItem;
}

const TemplateSelection: FC<IProps> = ({
  categories = [],
  loadingCategory,
  onTemplateClick,
  selectedTemplate,
}) => {
  const { t } = useTranslation('form');
  const formCtx = useContext(FormDataContext);

  const getClassHandler = (template: string) => {
    if (template === formCtx?.selectedTemplateTab) {
      return 'templates-list-div active';
    }
    return 'templates-list-div';
  };

  const changeTabHandler = (template: string) => {
    formCtx.changeTemplateTab(template);
  };

  if (selectedTemplate?.eid) {
    return null;
  }

  return (
    <Fragment>
      <ModalHeader>
        <GenericColorHeader title={t('form_template')} color='#cabdff' />
      </ModalHeader>
      <ModalCloseButton m={'10'} />
      <ModalBody marginTop={'4'} overflow='hidden' display='flex'>
        <FormTemplateWrapper>
          <div className='template-tabs-container'>
            <TemplateCatSkeleton isLoading={loadingCategory}>
              {categories?.map((template, index) => {
                return (
                  <div
                    key={index}
                    className={getClassHandler(template)}
                    onClick={() => {
                      changeTabHandler(template);
                      deployEvent(AmplitudeEventNames?.FORM_TEMPLATE_SELECT, {
                        form_id: '',
                        create_form_funnel_id: 5,
                        category: template,
                        name: '',
                        edit_form_funnel_id: 3,
                      });
                    }}
                  >
                    {template}
                  </div>
                );
              })}
            </TemplateCatSkeleton>
          </div>
          <FormTemplateList
            category={formCtx?.selectedTemplateTab}
            loadingCategory={loadingCategory}
            onClick={(template) => {
              onTemplateClick(template);
              deployEvent(AmplitudeEventNames?.FORM_TEMPLATE_SELECT, {
                form_id: '',
                create_form_funnel_id: 5,
                category: '',
                name: template?.title,
                edit_form_funnel_id: 3,
              });
            }}
          />
        </FormTemplateWrapper>
      </ModalBody>
    </Fragment>
  );
};

export default TemplateSelection;
