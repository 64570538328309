import { useUserDataSelector } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { useServiceLayer } from './useServiceLayer';

export const useControlLayer = () => {
  const {
    locationOwnerCompletedAudits: {
      getLocationOwnerCompletedAudits,
      locationOwnerCompletedAuditsResult: {
        loading: fetchingCompletedAudits,
        data: completedAudits,
      },
    },
    locationOwnerUpcomingAudits: {
      getLocationOwnerUpcomingAudits,
      locationOwnerUpcomingAuditsResult: {
        loading: fetchingUpcomingAudits,
        data: upcomingAudits,
      },
    },
  } = useServiceLayer();

  const { loggedInUserLocations, loggedInUserType, loggedInUserEid } =
    useUserDataSelector((state) => ({
      loggedInUserLocations: state?.locations,
      loggedInUserType: state?.type,
      loggedInUserEid: state?.eid,
    }));

  const loCompletedAuditsHandler = (locIds: string[]) => {
    getLocationOwnerCompletedAudits({
      variables: {
        page: 1,
        perPage: 1000,
        filter: {
          locations: locIds,
        },
        sort: '_ID_DESC',
        locationIds: locIds,
      },
    });
  };

  const loUpcomingAuditsHandler = (locIds: string[]) => {
    getLocationOwnerUpcomingAudits({
      variables: {
        page: 1,
        perPage: 1000,
        filter: {
          locations: locIds,
          notifyLO: true,
        },
        sort: '_ID_DESC',
        locationIds: locIds,
      },
    });
  };

  useEffect(() => {
    let locationEids =
      loggedInUserType === 'user'
        ? (loggedInUserLocations || [])?.map((loc) => loc?.eid)
        : [loggedInUserEid];
    if (locationEids?.length > 0) {
      loCompletedAuditsHandler(locationEids);
      loUpcomingAuditsHandler(locationEids);
    }
  }, [loggedInUserLocations]);

  return {
    completedData: {
      loading: fetchingCompletedAudits,
      auditData: completedAudits,
    },
    upcomingData: {
      loading: fetchingUpcomingAudits,
      auditData: upcomingAudits,
    },
  };
};
