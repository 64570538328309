import { FC } from 'react';
import { useFormState } from 'react-hook-form';
import { useBlockPrompt } from '../../../routes';

interface IProps {}

const ScheduleNavigationWarning: FC<IProps> = () => {
  const isDirty = useFormState().isDirty;

  useBlockPrompt({
    canShowPrompt: isDirty,
    blockConfig: () => {
      return {
        alertType: 'AuditScheduleAlert',
      };
    },
  });

  return null;
};

export default ScheduleNavigationWarning;
