import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Text,
  Tooltip,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useMutation } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { userObj } from 'sop-commons/src/client';

import { PrimaryButton, SearchInput } from 'atoms';
import { searchRegExp } from 'utils';

import { SopDetailData } from './chapter.graphql';
import BoxHeader from '../../../ui-components/BoxHeader';
import {
  ShareMemberEntity,
  useVisibilityMemberData,
} from '../../../ui-components/ShareToMember';
import ShareMemberList from '../../../ui-components/ShareToMember/ShareMemberList';
import {
  FormToUserResponse,
  FormToUserVariable,
  SEND_FORM_QUERY,
} from '../../forms/modules/SendForm/send-form.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  chapterId: string;
  isOpen: boolean;
  onClose: () => void;
  chapterData?: SopDetailData;
}

const ShareChapterDrawer: FC<IProps> = ({
  chapterId,
  isOpen,
  chapterData,
  onClose,
}) => {
  const { t } = useTranslation(['chapter', 'invite', 'location']);

  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });

  const { onCopy } = useClipboard(
    `${process.env.REACT_APP_FRONTEND_URL}/chapters/view/${chapterId}`
  );

  const copyLinkHandler = () => {
    onCopy();
    toast({
      isClosable: true,
      title: t('invite:link_copied'),
      status: 'success',
    });
  };

  const visibility = {
    visibility: chapterData?.visibility!,
    visibleTo: chapterData?.visibleTo!,
  };

  const [searchQuery, setSearchQuery] = useState('');

  const { members, loading } = useVisibilityMemberData(visibility);

  const filteredMembers = useMemo(() => {
    if (!searchQuery) {
      return members;
    }

    const reg = searchRegExp(searchQuery, 'gi');

    return members.filter((value) => {
      return (
        value.name?.match(reg) ||
        value.authRole?.match(reg) ||
        value.role?.match(reg) ||
        value?.locations?.[0]?.name?.match(reg)
      );
    });
  }, [members, searchQuery]);

  const [submitFormData] = useMutation<FormToUserResponse, FormToUserVariable>(
    SEND_FORM_QUERY
  );

  const onSendClick = async (value: ShareMemberEntity) => {
    deployEvent(AmplitudeEventNames?.SEND_MESSAGE_CLICK, {
      from_where: 'Knowledge Base > Chapters',
      member_type: value?.authRole,
      member_name: value?.name,
      member_id: value?.eid,
      member_location: value?.locations?.[0]?.name,
      member_job: value?.role,
    });

    await submitFormData({
      variables: {
        senderId: userObj().eid,
        receiverIds: [value.eid],
        message: `Please use the link to view the chapter: ${process.env.REACT_APP_FRONTEND_URL}/chapters/view/${chapterId}`,
      },
    }).then((response) => {
      if (response.data?.sendDirectMessage?.succeed) {
        toast({
          title: t('success'),
          status: 'success',
          description: t('chapter:chapterSuccessSentUser', {
            name: value.name,
          }),
        });
      }
    });
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size='lg'>
      <DrawerContent padding='12px' maxW='560px'>
        <DrawerHeader>
          <Flex align='center' justify='space-between'>
            <BoxHeader color='#B5E4CA' title={t('chapter:shareChapter')} />
            <Flex justify='flex-end' gap='8px'>
              <DrawerCloseButton
                pos='relative'
                borderRadius='full'
                top='0'
                right='0'
              />
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody display='flex' flexDir='column' overflow='hidden'>
          <Flex
            align='center'
            justify='space-between'
            w='full'
            bg='#CABDFF66'
            borderRadius='10px'
            p='12px 20px'
            mb={4}
          >
            <Flex align='center' gap='10px'>
              <FontAwesomeIcon
                icon={faCircleInfo as IconProp}
                color='#6F767E'
              />
              <Text whiteSpace='pre-wrap' maxW='400px'>
                <Trans
                  t={t}
                  i18nKey='chapter:publicShareLinkFor'
                  values={{
                    name: chapterData?.title,
                  }}
                />
              </Text>
            </Flex>
            <Box bg='white' borderRadius='7px'>
              <PrimaryButton
                size='sm'
                onClick={copyLinkHandler}
                // disabled={chapterData?.visibility === 'private'}
                leftIcon={<CopyIcon />}
                colorScheme='purple'
                variant='outline'
                title={t('location:copy_link')}
                style={{
                  borderColor: 'transparent',
                  cursor: 'pointer',
                  // cursor:
                  //   chapterData?.visibility === 'private'
                  //     ? 'no-drop'
                  //     : 'pointer',
                }}
              />
            </Box>
            {/* <Tooltip
              hasArrow
              placement='bottom-start'
              isDisabled={chapterData?.visibility === 'public'}
              label={t('chapter:onlyPublicChapterWillShared')}
            >
            </Tooltip> */}
          </Flex>
          <Box mb={4}>
            <SearchInput
              value={searchQuery}
              hideShortcuts
              placeholder={t('chapter:searchMemberRoleJob')}
              onChange={(e) => setSearchQuery(e?.target?.value)}
            />
          </Box>

          <ShareMemberList
            search={searchQuery}
            members={filteredMembers}
            loading={loading}
            onSendClick={onSendClick}
            i18NotFoundKey='form:noMemberLocationAreFound'
            i18NotFoundDescKey='form:checkVisibilitySetting'
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ShareChapterDrawer;
