import React, { FC, isValidElement, ReactElement, useMemo } from 'react';
import { TableProps as NativeProps } from 'antd';
import { TableLocale } from 'antd/lib/table/interface';
import { Center, Skeleton } from '@chakra-ui/react';

import { Any } from '../../types';
import Loader from '../../sub-components/Loader';

import BaseTable from './BaseTable';

interface EmptyStateProps {
  searchActive?: boolean;
}

interface EmptyProps extends EmptyStateProps {
  loading?: boolean;
}

const TableEmpty: FC<EmptyProps> = ({ loading, searchActive, children }) => {
  const childNode = children && React.Children.only(children);

  if (loading) {
    return (
      <Center justifyContent='start' flexDir='column' gap={4} minH='400px'>
        <Skeleton w='full' height='20px' />
        <Skeleton w='full' height='20px' />
        <Skeleton w='full' height='20px' />
        <Skeleton w='full' height='20px' />
      </Center>
    );
  }

  if (childNode && isValidElement(childNode)) {
    return (
      <Center minH='400px'>
        {React.cloneElement(childNode, {
          searchActive: searchActive,
        })}
      </Center>
    );
  }

  return null;
};

interface TableProps<RecordType = Any>
  extends Omit<NativeProps<RecordType>, 'loading' | 'locale'> {
  searchActive?: boolean;
  isLoading?: boolean;
  locale?: Omit<TableLocale, 'emptyText'>;
  emptyState?: React.ReactElement<EmptyStateProps>;
}

function Table<RecordType extends object>(
  props: TableProps<RecordType>
): ReactElement {
  const {
    pagination,
    searchActive = false,
    locale,
    isLoading = false,
    emptyState,
    ...rest
  } = props;

  const haveData = !!rest.dataSource?.length;

  const loading = useMemo((): NativeProps['loading'] => {
    if (!emptyState) {
      return isLoading;
    }
    return {
      spinning: isLoading,
      size: 'large',
      indicator: haveData ? <Loader /> : <></>,
    };
  }, [emptyState, isLoading, haveData]);

  const paginationConfig = useMemo(() => {
    if (pagination === false) {
      return pagination;
    }
    return {
      showSizeChanger: false,
      hideOnSinglePage: false,
      ...pagination,
    };
  }, [pagination]);

  return (
    <BaseTable<RecordType>
      showHeader={haveData || isLoading || searchActive}
      showSorterTooltip={false}
      tableLayout='auto'
      {...rest}
      loading={loading}
      locale={{
        emptyText: (
          <TableEmpty loading={isLoading} searchActive={searchActive}>
            {emptyState}
          </TableEmpty>
        ),
        ...locale,
      }}
      pagination={paginationConfig}
    />
  );
}

export type { TableProps };

export default Table;
