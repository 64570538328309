import { AuditInput, AuditQuestionsEntity } from './audit.types';
import { QuestionType } from '../../../types';

interface AuditQuestion extends AuditQuestionsEntity {
  tempEid: string;
}

export interface IFormInput extends AuditInput {
  // status?: FormStatus;
  // isReadOnly?: boolean;

  questions: AuditQuestion[];

  selectedQuestion?: string;

  /**
   * This attribute is used to identify the audit question editing vs template editing
   *
   * when **isAuditQuestion** is set to true, then it will be editing `audit questions`
   * where question and option deletion will be marked as __isDisabled__
   */
  isAuditQuestion?: boolean;
  previewHidden?: boolean;
  height?: string | number;
}

export const OptionWhiteList: QuestionType[] = [
  QuestionType.CHECKBOX,
  QuestionType.MULTI_CHOICE,
  QuestionType.DROPDOWN,
];
