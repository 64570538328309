import { gql } from '@apollo/client';

export const LOCATION_OWNER_UPCOMING_AUDITS = gql`
  query LocationOwnerUpcomingAudits(
    $filter: FilterFindManyAuditSessionInput
    $locationIds: [String]
    $page: Int
    $perPage: Int
    $sort: SortFindManyAuditSessionInput
  ) {
    AuditSessionPagination(
      filter: $filter
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      items {
        name
        eid
        title
        task {
          title
          description
          eid
          audit {
            auditId
            sessionId
            qid
          }
        }
        dueDate
        startDate
        isCompleted
        createdAt
        entityId
        updatedAt
        description
        auditId
        questions
        auditors {
          name
          profilePic
          eid
        }
        assignments(locationIds: $locationIds) {
          auditors
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          startedBy
          maxScore
          totalScore
          response
        }
      }
    }
  }
`;

export const LOCATION_OWNER_COMPLETED_AUDITS = gql`
  query LocationOwnerCompletedAudits(
    $filter: FilterFindManyAuditSessionInput
    $locationIds: [String]
    $page: Int
    $perPage: Int
    $sort: SortFindManyAuditSessionInput
  ) {
    AuditSessionPagination(
      filter: $filter
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      items {
        name
        eid
        title
        task {
          title
          description
          eid
          audit {
            auditId
            sessionId
            qid
          }
        }
        dueDate
        startDate
        isCompleted
        createdAt
        entityId
        updatedAt
        description
        auditId
        questions
        auditors {
          name
          profilePic
          eid
        }
        assignments(locationIds: $locationIds) {
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          startedBy
          maxScore
          totalScore
          response
        }
      }
    }
  }
`;

export const LOCATION_OWNER_ANALYTICS = gql`
  query Query($locationIds: [String!]!, $startDate: Date, $endDate: Date) {
    AuditLocationsAnalytics(
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
