import { useLazyQuery } from '@apollo/client';
import { Center, Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import { useUserDataSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'sub-components';
import { ReportContent } from 'sub-components/audits/report';
import { AUDIT_SESSION_DETAILS } from 'sub-components/audits/take-audit/take-audit.graphql';
import {
  SessionDetailResponse,
  SessionDetailVariables,
} from 'sub-components/audits/take-audit/types';
import DashboardContainer from 'sub-components/DashboardContainer';
import ReportHeadingSection from './components/ReportHeadingSection';

const AuditReport: FC = () => {
  const [selectedViewType, setSelectedViewType] = useState('web');
  const { locationId, sessionId } = useParams<{
    locationId: string;
    sessionId: string;
  }>();

  const locations = useUserDataSelector((state) => state?.entity?.locations);
  let locationData = locations?.find((loc) => loc?.eid === locationId);

  const [getSessionDetails, { data, loading }] = useLazyQuery<
    SessionDetailResponse,
    SessionDetailVariables
  >(AUDIT_SESSION_DETAILS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getSessionDetails({
      variables: {
        eid: sessionId,
      },
    });
  }, []);

  if (loading) {
    return (
      <Center h='89vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <DashboardContainer>
      <ReportHeadingSection
        selectedViewType={selectedViewType}
        locationName={locationData?.name}
      />
      <Flex direction='column'>
        <Flex mt='20px' columnGap='1rem'>
          <div
            style={{
              width: '100%',
            }}
          >
            <Card
              style={{
                position: 'relative',
                borderRadius: '12px',
              }}
            >
              <ReportContent
                setSelectedViewType={setSelectedViewType}
                selectedViewType={selectedViewType}
              />
            </Card>
          </div>
        </Flex>
      </Flex>
    </DashboardContainer>
  );
};

export default AuditReport;
