// components/DownloadReport.tsx
import React, { FC, useCallback, useEffect } from 'react';
import { Center, Tooltip, useToast } from '@chakra-ui/react';
import Loader from 'sub-components/Loader';
import { useDownloadReport } from './useDownloadReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface DownloadReportProps {
  locationId: string;
  sessionId: string;
  reportsUrl?: string;
  children?: React.ReactNode;
  tooltipLabel?: string;
  callbackFn?: () => void;
  loadingComp?: React.ReactNode;
  disabled?: boolean;
}

const DownloadReport: FC<DownloadReportProps> = ({
  locationId,
  sessionId,
  reportsUrl,
  children,
  tooltipLabel = 'Download',
  loadingComp,
  disabled,
  callbackFn,
}) => {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const handleCallback = useCallback(() => {
    callbackFn?.();
  }, [callbackFn]);

  const { handleDownload, loading, error } = useDownloadReport({
    locationId,
    sessionId,
    reportsUrl,
    callback: handleCallback,
  });

  useEffect(() => {
    if (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Download Failed',
      });
    }
  }, [error, toast]);

  const onClickFn = useCallback(
    async (e) => {
      e.stopPropagation();
      try {
        if (disabled) return;
        handleDownload();
      } catch (err) {
        // Error is already handled in the hook via toast
      }
    },
    [handleDownload, toast]
  );

  return (
    <Tooltip
      label={loading ? 'Downloading...' : tooltipLabel}
      hasArrow
      placement='top'
    >
      <Center
        as='button'
        cursor={loading || disabled ? 'not-allowed' : 'pointer'}
        w='fit-content'
        onClick={onClickFn}
        disabled={loading}
        aria-label={tooltipLabel}
        tabIndex={0}
      >
        {loading
          ? loadingComp || <Loader size='md' />
          : children || (
              <FontAwesomeIcon icon={faArrowDownToLine as IconProp} />
            )}
      </Center>
    </Tooltip>
  );
};

export default DownloadReport;
