import React, { useCallback, useEffect, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useConfirm, UseConfirm } from '../../../../../ui-components';
import ScheduleTemplate from './ScheduleTemplate';
import SchedulePreviewHeader from './SchedulePreviewHeader';

interface IProps {
  templateId: string;
}

export const useSchedulePreview = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  useEffect(() => {
    return () => {
      confirmRef.current?.destroy();
    };
  }, []);

  return useCallback((props: IProps) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: (wProps) => <ApolloProvider client={apolloClient} {...wProps} />,
      title: <SchedulePreviewHeader templateId={props.templateId} />,
      content: <ScheduleTemplate templateId={props.templateId} />,
      size: 'full',
      contentProps: {
        // padding: '12px',
        borderRadius: 0,
        bg: '#FFFFFF',
        containerProps: {
          maxW: '560px',
          left: 'unset',
          right: 0,
        },
      },
      bodyProps: {
        padding: 0,
        background: '#F3F3F3',
      },
      headerProps: {
        borderBottom: '0.5px solid #C7C7C7',
      },
      scrollBehavior: 'inside',
      footer: null,
    });

    return confirmRef.current;
  }, []);
};
