import React, { FC } from 'react';
import { CSSObject, Flex } from '@chakra-ui/react';
import { useFormState } from 'react-hook-form';

import { ReactComponent as DeleteIcon } from '../../../../../assets/images/trash.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/images/copy.svg';
import { IFormInput } from '../../form-types';
import { IFooterProps } from './audit-options';

export const notLastStyle: CSSObject = {
  position: 'relative',
  ':after': {
    content: "''",
    position: 'absolute',
    right: '-16px',
    top: '50%',
    width: '1.5px',
    height: '20px',
    background: '#BEBEBE',
    transform: 'translateY(-10px)',
  },
};

interface IProps extends IFooterProps {}

const BaseAuditQuesFooter: FC<IProps> = ({
  questionIndex,
  isReadOnly,
  handleDuplicate,
  handleRemove,
  children,
}) => {
  const isDisabled = useFormState<IFormInput>({
    name: 'title',
  }).disabled;

  const isInteractive = isDisabled || isReadOnly;

  return (
    <Flex justify='flex-end' align='center' gap={8}>
      <Flex gap='16px' _notLast={notLastStyle}>
        <CopyIcon
          cursor={!isInteractive ? 'pointer' : 'no-drop'}
          opacity={isDisabled ? 0.5 : 1}
          onClick={(event) =>
            !isInteractive && handleDuplicate(questionIndex, event)
          }
        />
        <DeleteIcon
          cursor={!isInteractive ? 'pointer' : 'no-drop'}
          opacity={isDisabled ? 0.5 : 1}
          onClick={(event) =>
            !isInteractive && handleRemove(questionIndex, event)
          }
        />
      </Flex>

      {children}
    </Flex>
  );
};

export default BaseAuditQuesFooter;
