enum AuditFrequency {
  OneTime = 'oneTime',
  Repeating = 'repeating',
}

enum CustomRepeatType {
  Weeks = 'weeks',
  Days = 'days',
  Months = 'months',
}

enum AuditDue {
  NextWeek = 'nextWeek',
  EndMonth = 'endMonth',
  EndYear = 'endYear',
  Date = 'date',
  AnyTime = 'anyTime',
}

enum RepeatType {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Custom = 'custom',
}

interface BaseAudit {
  auditFrequency: AuditFrequency;
}

interface OneTimeAudit extends BaseAudit {
  auditFrequency: AuditFrequency.OneTime;
  auditDue: AuditDue;
  auditDueDate?: string;
}

interface RepeatingAudit extends BaseAudit {
  auditFrequency: AuditFrequency.Repeating;
  repeatType: RepeatType;
}

interface WeeklyRepeatingAudit extends RepeatingAudit {
  repeatType: RepeatType.Weekly;
  weekly: FullWeekdayName[];
}

const WEEKDAY_ABBREVIATIONS: { [key: string]: string } = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

type FullWeekdayName =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

interface MonthlyRepeatingAudit extends RepeatingAudit {
  repeatType: RepeatType.Monthly;
  monthly: 'startMonth' | 'middleMonth' | 'endMonth';
}

interface YearlyRepeatingAudit extends RepeatingAudit {
  repeatType: RepeatType.Yearly;
  yearly: string;
}

interface CustomRepeatingAudit extends RepeatingAudit {
  repeatType: RepeatType.Custom;
  customRepeatType?: CustomRepeatType;
  customRepeatValue: number | string;
  customRepeatMonth?: string;
}

type Audit =
  | OneTimeAudit
  | WeeklyRepeatingAudit
  | MonthlyRepeatingAudit
  | YearlyRepeatingAudit
  | CustomRepeatingAudit;

export function getAuditRepeatStatus(audit: Audit): string {
  if (audit?.auditFrequency === AuditFrequency.OneTime) {
    return 'Does not repeat';
  }

  if (audit?.auditFrequency === AuditFrequency.Repeating) {
    switch (audit?.repeatType) {
      case RepeatType.Weekly:
        const weeklyAudit = audit as WeeklyRepeatingAudit;
        if (weeklyAudit.weekly && weeklyAudit.weekly.length > 0) {
          const formattedDays = weeklyAudit.weekly
            .map((day) => WEEKDAY_ABBREVIATIONS[day] || day)
            .join(', ');
          return `Weekly - ${formattedDays}`;
        }
        return 'Weekly';

      case RepeatType.Monthly:
        const monthly = (audit as MonthlyRepeatingAudit)?.monthly;
        switch (monthly) {
          case 'startMonth':
            return 'Monthly start';
          case 'middleMonth':
            return 'Monthly mid';
          case 'endMonth':
            return 'Monthly end';
          default:
            return 'Monthly';
        }

      case RepeatType.Yearly:
        return 'Yearly';

      case RepeatType.Custom:
        const custom = audit as CustomRepeatingAudit;

        if (
          !custom.customRepeatType ||
          custom.customRepeatValue === undefined ||
          custom.customRepeatValue === null
        ) {
          return 'Custom repeat';
        }

        let repeatValue: number | string = custom.customRepeatValue;

        if (typeof repeatValue === 'string') {
          const parsedValue = parseInt(repeatValue, 10);
          if (!isNaN(parsedValue)) {
            repeatValue = parsedValue;
          }
        }

        switch (custom.customRepeatType) {
          case CustomRepeatType.Days:
            if (typeof repeatValue === 'number') {
              return repeatValue === 1 ? 'Daily' : `Every ${repeatValue} days`;
            }
            return `Every ${repeatValue} days`;

          case CustomRepeatType.Weeks:
            if (typeof repeatValue === 'number') {
              return repeatValue === 1
                ? 'Weekly'
                : `Every ${repeatValue} weeks`;
            }
            return `Every ${repeatValue} weeks`;

          case CustomRepeatType.Months:
            if (typeof repeatValue === 'number') {
              return repeatValue === 1
                ? 'Monthly'
                : `Every ${repeatValue} months`;
            }
            return `Every ${repeatValue} months`;

          default:
            return 'Custom repeat';
        }

      default:
        return 'Repeating';
    }
  }

  return 'Unknown';
}
