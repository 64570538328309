import { useEffect } from 'react';
import { useSafeState } from '../../../../../hooks';
import { SelectOption } from '../../../../../atoms';
import { eventBus } from '../../../../../shared/eventEmit';

export const TemplateEvent = '@@delightree-template-preview-change';

export const useSelectedTemplate = (
  _templateId?: string
): SelectOption | null => {
  const [template, setTemplate] = useSafeState<SelectOption | null>(() => {
    if (_templateId) {
      return {
        value: _templateId,
        label: '',
      };
    }
    return null;
  });

  useEffect(() => {
    const removeListener = eventBus.on(TemplateEvent, (value: SelectOption) => {
      setTemplate(value);
    });

    return () => {
      removeListener();
    };
  }, []);

  return template;
};
