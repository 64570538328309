import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SimpleDatePicker } from 'ui-components/DateFilter/components';
import { IFormInput } from '../../audit-schedule.types';

interface IProps {
  isSelected?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  onChange?: (date: Date | null) => void;
}

const AuditDueDate: FC<IProps> = ({
  onChange,
  isSelected,
  isDisabled,
  isReadOnly,
}) => {
  const { t } = useTranslation(['task']);

  return (
    <Controller<IFormInput, 'repeatDetails.auditDueDate'>
      name='repeatDetails.auditDueDate'
      render={({ field }) => {
        return (
          <SimpleDatePicker
            readOnly={isReadOnly}
            disabled={isDisabled}
            isSelected={isSelected}
            onChange={field.onChange}
            onSelect={(date) => onChange?.(date)}
            selectedValue={field.value || null}
            placeholderText={t('task:customChooseCal')}
            transKey='task:customDateValue'
            minDate={new Date()}
          />
        );
      }}
    />
  );
};

export default AuditDueDate;
