import React, { FC, useEffect, useState, useCallback } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { gql, useLazyQuery } from '@apollo/client';
import { callAll } from '@chakra-ui/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { v4 as generateEid } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AuditHelper } from 'configs';
import { Input } from 'atoms';
import { toArray } from '../../../../../utils';
import TitleHeader from '../../../../CardEditor/TitleHeader';

import { IFormInput } from '../../form-types';
import AuditQuestionFooter from './AuditQuestionFooter';
import AuditQuestionTypeSelect from './AuditQuestionTypeSelect';
import { AuditOptionList, IFooterProps } from './audit-options';
import AuditMediaHelper from './AuditMediaHelper';
import AuditQuestionSearchSuggestion from './AuditQuestionSearchSuggestion';

interface IProps extends IFooterProps {
  fieldId: string;
}

const GET_QUESTION_SUGGESTIONS = gql`
  query Query($query: String!) {
    AuditTemplateSuggestion(query: $query)
  }
`;

const AuditQuestion: FC<IProps> = ({
  questionIndex,
  handleRemove,
  handleDuplicate,
  isReadOnly,
  fieldId,
}) => {
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [questionText, setQuestionText] = useState('');
  const [blurToggle, setBlurToggle] = useState(false);
  const { setValue } = useFormContext<IFormInput>();
  const [isAuditQuestion, questionId] = useWatch<
    IFormInput,
    ['isAuditQuestion', `questions.${number}.eid`]
  >({
    name: ['isAuditQuestion', `questions.${questionIndex}.eid`],
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: fieldId, data: { questionIndex } });

  const [getQuestionSuggestions] = useLazyQuery(GET_QUESTION_SUGGESTIONS, {
    onCompleted: (questions) => {
      setSuggestedQuestions(questions?.AuditTemplateSuggestion);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      getQuestionSuggestions({
        variables: {
          query: questionText,
        },
      });
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [questionText]);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!e.target.classList.contains('custom-popup')) {
        // Check if suggestedQuestions is already empty
        if (suggestedQuestions?.length > 0) {
          setSuggestedQuestions([]);
        }
      }
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [blurToggle]);

  const setInputSelectedQuestion = (question: any) => {
    setValue(`questions.${questionIndex}.qType`, question?.qType);
    setValue(`questions.${questionIndex}.label`, question?.label);
    setValue(
      `questions.${questionIndex}.options`,
      toArray(question?.options).map((opt) => ({
        ...opt,
        eid: undefined,
        tempEid: generateEid(),
      }))
    );
    setSuggestedQuestions([]);
  };

  return (
    <Flex
      gap={1}
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      transition={transition}
    >
      <Flex
        alignSelf='center'
        align='center'
        justify='center'
        ref={setActivatorNodeRef}
        {...attributes}
        {...listeners}
        boxSize={6}
      >
        <FontAwesomeIcon
          icon={faGripDotsVertical as IconProp}
          fontSize='20px'
          color='#6F767E'
        />
      </Flex>

      <Flex
        flex={1}
        border='2px solid #EFEFEF'
        borderRadius='8px'
        bg='#FCFCFC'
        padding='1px'
      >
        <Flex width='16px' bg='#2A85FF' borderLeftRadius='5px' />
        <Flex flex={1} flexDir='column' gap={4} padding='23px 23px 23px 16px'>
          <Flex flexDir='column'>
            <TitleHeader title='Question' isRequired />

            <Controller<IFormInput, `questions.${number}.label`>
              name={`questions.${questionIndex}.label`}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={2} isInvalid={!!fieldState.error}>
                    <Input
                      onKeyUp={(e) => setQuestionText(e?.target?.value)}
                      size='lg'
                      variant='auditOutline'
                      placeholder='Type your own question or choose from list'
                      {...field}
                      onBlur={() => {
                        AuditHelper.save(field.onBlur);
                        setBlurToggle(!blurToggle);
                      }}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                    {suggestedQuestions?.length > 0 &&
                      questionText.length > 0 && (
                        <AuditQuestionSearchSuggestion
                          field={field}
                          suggestedQuestions={suggestedQuestions}
                          setInputSelectedQuestion={setInputSelectedQuestion}
                        />
                      )}
                  </FormControl>
                );
              }}
            />
          </Flex>

          <Flex flexDir='column'>
            <TitleHeader title='Type of response' />

            <Controller<IFormInput, `questions.${number}.qType`>
              name={`questions.${questionIndex}.qType`}
              render={({ field, fieldState }) => (
                <FormControl mt={2} isInvalid={!!fieldState.error}>
                  <AuditQuestionTypeSelect
                    {...field}
                    isViewOnly={!!(isAuditQuestion && questionId)}
                    onChange={callAll(field.onChange, AuditHelper.save)}
                  />

                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          </Flex>

          <AuditMediaHelper questionIndex={questionIndex} />
          <AuditOptionList questionIndex={questionIndex} />

          <AuditQuestionFooter
            questionIndex={questionIndex}
            handleRemove={handleRemove}
            handleDuplicate={handleDuplicate}
            isReadOnly={isReadOnly}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AuditQuestion;
