import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AuditEvidenceEntity } from 'types';
import EvidenceImages from '../../EvidenceImages/EvidenceImages';
import Notes from '../../Notes';

const BaseContainer: FC<{
  children: React.ReactNode;
  note?: string;
  evidenceImages?: AuditEvidenceEntity[];
}> = ({ children, evidenceImages = [], note }) => {
  return (
    <Flex flexDir='column' gap={2}>
      {children}
      {note && <Notes note={note} />}
      {evidenceImages?.length > 0 && <EvidenceImages images={evidenceImages} />}
    </Flex>
  );
};

export default BaseContainer;
