import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller } from 'react-hook-form';
import { Any } from '../../../../../types';
import { QuestionsState, SectionEntity } from '../../types';
import { FileUpload } from '../question-types';
import { AuditTake } from '../../tabke-audit.helpers';

interface IProps {
  question: QuestionsState;
  sectionIndex: number;
  questionIndex: number;
}

const FileUploadSection: FC<IProps> = ({
  question,
  sectionIndex,
  questionIndex,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveHandler = (...args: Any) => {
    AuditTake.save(`data.${questionIndex}`, question.eid);
  };

  return (
    <Controller<SectionEntity, `data.${number}.responseUrl`>
      name={`data.${questionIndex}.responseUrl`}
      rules={{
        required: {
          value: question?.isRequired,
          message: 'This field is required',
        },
        validate: {
          isFileSelected: (value) =>
            (value && value.length > 0) || 'Please upload a file.',
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={fieldState.invalid}>
            <FileUpload
              value={field.value}
              onChange={callAll(field.onChange, saveHandler)}
            />
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default FileUploadSection;
