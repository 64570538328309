import React, { FC } from 'react';
import { Grid } from '@chakra-ui/react';
import SingleTemplateGrid from './SingleTemplateGrid';
import TemplateGridViewShimmer from './TemplateGridViewShimmer';
import { AuditTemplateItem } from '../../query/audits-listing.graphql';

interface TemplateGridViewProps {
  isLoading?: boolean;
  allAuditTemplates?: AuditTemplateItem[];
  onPreview: (template: AuditTemplateItem) => void;
}

const TemplateGridView: FC<TemplateGridViewProps> = ({
  isLoading,
  allAuditTemplates,
  onPreview,
}) => {
  if (isLoading) {
    return (
      <Grid
        gap={4}
        templateColumns={{
          base: 'repeat(4, 1fr)',
          '2xl': 'repeat(5, 1fr)',
        }}
      >
        {Array.from({ length: 8 }).map((_, index) => {
          return <TemplateGridViewShimmer key={index} />;
        })}
      </Grid>
    );
  }

  return (
    <Grid
      gap={4}
      templateColumns={{
        base: 'repeat(4, 1fr)',
        '2xl': 'repeat(5, 1fr)',
      }}
    >
      {allAuditTemplates?.map((template) => {
        return (
          <SingleTemplateGrid
            key={template?.eid}
            template={template}
            onPreview={() => onPreview(template)}
          />
        );
      })}
    </Grid>
  );
};

export default TemplateGridView;
