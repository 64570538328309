import { useCallback, useRef } from 'react';
import { useToast } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { isCompleted, mapDataToInput } from './tabke-audit.helpers';
import { AuditResponseVariable, IAuditFormInput, SectionEntity } from './types';
import { useServiceLayer } from './layers';

interface Params {
  locationId: string;
  sessionId: string;
}

type UpdateCallback = (
  pathName: `data.${number}`,
  inputResponse: IAuditFormInput
) => void;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuditSubmitHandler = (
  methods: UseFormReturn<SectionEntity>,
  updateCallback: UpdateCallback
) => {
  const service = useServiceLayer();
  const queRef = useRef(new Map<string, AbortController>());
  const params = useParams<Params>();

  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    duration: 3000,
  });

  return useCallback(
    async (pathName: `data.${number}`, questionId: string) => {
      methods.control._subjects.state.next({
        isSubmitting: true,
      });

      if (queRef.current.has(pathName)) {
        queRef.current.get(pathName)?.abort();
        queRef.current.delete(pathName);
        await new Promise((resolve) => setTimeout(resolve, 1));
      }

      const controller = new AbortController();
      queRef.current.set(pathName, controller);

      await methods.trigger(pathName);

      const originalValues = methods.getValues(pathName);

      const inputResponse = mapDataToInput(originalValues);

      if (inputResponse.qid !== questionId) {
        methods.control._subjects.state.next({
          isSubmitting: false,
        });
        console.log('Selected question not found');
        return;
      }

      try {
        const response = await service.questionSubmit.submitAuditResponse({
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
          variables: {
            input: {
              locationId: params.locationId,
              sessionId: params.sessionId,
              response: inputResponse,
            },
          },
        });

        if (response.data) {
          methods.setValue(`${pathName}.responseScore`, inputResponse.score!);
          const completed = isCompleted(inputResponse, originalValues);
          methods.setValue(`${pathName}.isCompleted`, completed);
          // @ts-ignore
          methods.setValue(`${pathName}.isNotFilled`, !completed);
          await new Promise((resolve) => setTimeout(resolve, 10));
          methods.control._subjects.state.next({
            isSubmitting: false,
          });
          if (updateCallback) {
            updateCallback(pathName, {
              images: inputResponse.images,

              isFlagged: inputResponse.isFlagged!,
              noteText: inputResponse.noteText,

              response: inputResponse.response!,
              responseId: inputResponse.responseId,
              responseUrl: inputResponse.responseUrl,
              responseScore: inputResponse.score!,
            });
          }
        }

        if (response.errors) {
          await Promise.reject(response.errors);
        }
      } catch (error) {
        methods.control._subjects.state.next({
          isSubmitting: false,
        });

        // @ts-ignore
        if (!error?.graphQLErrors) {
          toast({
            status: 'error',
            title: 'Something went wrong',
          });
        }
      }
    },
    [params, updateCallback]
  );
};
