import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import { Avatar, Box, Center, Flex, Image, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HeaderColors } from 'shared/headerColors';
import AuditAssignee from 'sub-components/audits/audit-tab/supervised/components/GridView/components/AuditAssignee';
import EmptyState from 'sub-components/EmptyState';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { usersEntityObj } from 'sub-components/Header';
import Loader from 'sub-components/Loader';
import { AuditQuestionEntity } from 'types';
import { formatNumber, getImage } from 'utils';

interface AuditSessionByIdVariable {
  eid: string;
  filter?: {
    locations?: string[];
  };
  locationIds?: string[];
}

interface AuditSessionByIdEntity {
  task: {
    eid: string;
    lastActiveSession: {
      eid: string;
      dueDate: string;
      title: string;
    };
  }[];
  auditors: {
    eid: string;
    name: string;
  }[];
  completedAt: string;
  assignments: {
    completedAt: string;
    locationId: string;
    totalScore: number;
    maxScore: number;
    completedPercent: number;
    completedBy: string;
  }[];
  questions: AuditQuestionEntity[];
}

interface AuditSessionByIdResponse {
  AuditSessionById: AuditSessionByIdEntity;
}

const LO_AUDIT_DETAIL = gql`
  query LocationOwnerAuditDetails($eid: String!, $locationIds: [String]) {
    AuditSessionById(eid: $eid) {
      task {
        eid
        lastActiveSession {
          eid
          dueDate
          title
        }
      }
      auditors {
        eid
        name
      }
      completedAt
      assignments(locationIds: $locationIds) {
        completedAt
        completedBy
        completedPercent
        locationId
        totalScore
        maxScore
      }
    }
  }
`;

const Detail: FC<{
  data: AuditSessionByIdEntity | undefined;
  isAuditScored: boolean;
}> = ({ data, isAuditScored }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const entityData = useReactiveVar(usersEntityObj);

  const assignment = useMemo(
    () => data?.assignments?.find((a) => a?.locationId === locationId),
    [data, locationId]
  );

  const {
    completedBy: auditCompletedBy = '',
    maxScore = 0,
    totalScore: baggedScore = 0,
    completedPercent = 0,
    completedAt: auditCompletedOn = '-',
  } = assignment || {};

  const foundAuditors = useMemo(() => {
    const auditor = entityData?.find(
      (entity) => entity?.eid === auditCompletedBy
    );
    return auditor
      ? {
          eid: auditor.eid,
          name: auditor.name,
          profilePic: auditor.profilePic,
        }
      : null;
  }, [auditCompletedBy, entityData]);

  const foundLocation = useMemo(() => {
    const location = entityData?.find((entity) => entity?.eid === locationId);
    return location?.name || 'No location';
  }, [locationId, entityData]);

  const formattedAuditDate = useMemo(() => {
    return moment(auditCompletedOn).isValid()
      ? moment.utc(auditCompletedOn).format('DD MMM, YYYY')
      : '-';
  }, [auditCompletedOn]);

  const locationDisplay = useMemo(
    () => (
      <Flex align='center' cursor='pointer' color='rgba(51, 56, 63, 1)'>
        <FontAwesomeIcon icon={faLocationDot as IconProp} />
        <Tooltip hasArrow label={foundLocation}>
          <Box ml={2} isTruncated maxW='150px'>
            {foundLocation}
          </Box>
        </Tooltip>
      </Flex>
    ),
    [foundLocation]
  );

  return (
    <Flex
      border='0.5px solid rgba(199, 199, 199, 1)'
      borderRadius='10px'
      py={4}
      pl={4}
      pr={8}
      flexDir='column'
      gap={6}
    >
      <GenericColorHeader title='Audit details' color={HeaderColors.Purple} />

      <Flex align='center' justify='space-between'>
        <Box as='span' fontWeight={600} fontSize='16px'>
          Auditor:
        </Box>
        {foundAuditors ? (
          <Flex align='center' gap='5px'>
            <Image
              src={getImage(foundAuditors.profilePic, foundAuditors.name)}
              boxSize='20px'
              borderRadius='50%'
              alt={foundAuditors.name}
            />
            <Box as='span'>{foundAuditors.name}</Box>
          </Flex>
        ) : (
          <Box as='span'>N/A</Box>
        )}
      </Flex>

      <Flex align='center' justify='space-between'>
        <Box as='span' fontWeight={600} fontSize='16px'>
          Location:
        </Box>
        {locationDisplay}
      </Flex>

      <Flex align='center' justify='space-between'>
        <Box as='span' fontWeight={600} fontSize='16px'>
          Score:
        </Box>
        {isAuditScored ? (
          <Box>
            {formatNumber((baggedScore / maxScore) * 100 || 0)}% - {baggedScore}
            /{maxScore}
          </Box>
        ) : (
          <Box>No Scoring</Box>
        )}
      </Flex>

      <Flex align='center' justify='space-between'>
        <Box as='span' fontWeight={600} fontSize='16px'>
          Audit date:
        </Box>
        <Flex gap='5px' align='center'>
          <FontAwesomeIcon icon={faCalendarCheck as IconProp} />
          {formattedAuditDate}
        </Flex>
      </Flex>
    </Flex>
  );
};

const TaskSection: FC<{
  data: AuditSessionByIdEntity | undefined;
}> = ({ data }) => {
  const history = useHistory();
  const tasks = useMemo(() => {
    return data?.task;
  }, [data?.task]);
  return (
    <Flex
      border='.5px solid rgba(199, 199, 199, 1)'
      borderRadius='10px'
      p={4}
      flexDir='column'
      gap={4}
    >
      <GenericColorHeader title='Audit tasks' color={HeaderColors.Purple} />
      {tasks?.length === 0 ? (
        <EmptyState image='MyTask' title='No task added for audit' />
      ) : (
        <Flex flexDir='column' gap={4}>
          {tasks?.map((task) => (
            <Flex
              borderRadius='3px'
              border='.2px solid rgba(199, 199, 199, 1)'
              p='12px'
              align='center'
              cursor='pointer'
              justify='space-between'
              onClick={() =>
                history.push(
                  `/tasks/my-tasks/details/${task?.eid}/${task?.lastActiveSession?.eid}`,
                  { backToTitle: 'Back to Audit Detail' }
                )
              }
            >
              <Flex flexDir='column' gap={2} w='90%'>
                <Tooltip hasArrow label={task?.lastActiveSession?.title}>
                  <Box isTruncated maxW='300px'>
                    {task?.lastActiveSession?.title}
                  </Box>
                </Tooltip>
                <Flex align='center' gap='5px'>
                  <FontAwesomeIcon icon={faCalendarCheck as IconProp} />{' '}
                  {moment
                    .utc(task?.lastActiveSession?.dueDate)
                    ?.format('DD MMM, YYYY')}
                </Flex>
              </Flex>
              <Flex justify='flex-end'>
                <FontAwesomeIcon icon={faArrowRight as IconProp} />
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const LOAuditDetail: FC<{ isAuditScored: boolean }> = ({ isAuditScored }) => {
  const { auditId, sessionId, locationId } = useParams<{
    auditId: string;
    sessionId: string;
    locationId: string;
  }>();

  const [getSessionDetails, { data, loading }] = useLazyQuery<
    AuditSessionByIdResponse,
    AuditSessionByIdVariable
  >(LO_AUDIT_DETAIL, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getSessionDetails({
      variables: {
        eid: sessionId,
        locationIds: [locationId],
      },
    });
  }, []);

  const detailData = useMemo(() => {
    return data?.AuditSessionById;
  }, [data]);

  if (loading) {
    return (
      <Center h='70vh' w='full'>
        <Loader />
      </Center>
    );
  }

  return (
    <Flex gap={4} flexDir='column' w='full'>
      <Detail data={detailData} isAuditScored={isAuditScored} />
      <TaskSection data={detailData} />
    </Flex>
  );
};

export default LOAuditDetail;
