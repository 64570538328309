import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  note: string;
}

const Notes: FC<IProps> = ({ note }) => {
  if (!note) {
    return null;
  }
  return (
    <Flex
      align='baseline'
      gap='5px'
      bg='rgba(255, 216, 141, 1)'
      p='8px'
      maxW='100%'
      borderRadius='6px'
      wordBreak='break-word'
      fontWeight={600}
    >
      <FontAwesomeIcon icon={faPenToSquare as IconProp} />
      <Text>{note}</Text>
    </Flex>
  );
};

export default Notes;
