import { AnalyticsData, TotalFlagsResult } from './GraphSection';
export const getMonthName = (monthNumber: number): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[monthNumber - 1] || `Month ${monthNumber}`;
};
export const getPreviousMonthAndYear = (
  date: Date
): { previousMonthNumber: number; previousYear: number } => {
  let previousMonthNumber = date.getMonth();
  let previousYear = date.getFullYear();

  if (previousMonthNumber === 0) {
    previousMonthNumber = 12;
    previousYear -= 1;
  }

  return {
    previousMonthNumber,
    previousYear,
  };
};

export const calculateAverageAuditScoreAndDifference = (
  currentMonthDate: Date,
  data?: AnalyticsData
): {
  currentMonthPercentage: number;
  differenceFromPreviousMonth: number | null;
} => {
  if (!data) {
    return {
      currentMonthPercentage: 0,
      differenceFromPreviousMonth: 0,
    };
  }
  const currentMonthNumber = currentMonthDate.getMonth() + 1;
  const currentYear = currentMonthDate.getFullYear();
  const { previousMonthNumber, previousYear } =
    getPreviousMonthAndYear(currentMonthDate);
  const aggregateScores = (
    month: number,
    year: number
  ): { totalScoreSum: number; totalMaxScoreSum: number } => {
    let totalScoreSum = 0;
    let totalMaxScoreSum = 0;

    Object.values(data.AuditLocationsAnalytics).forEach((location) => {
      Object.entries(location).forEach(([monthKey, metrics]) => {
        const metricStartDate = new Date(metrics.start);
        const metricMonth = metricStartDate.getMonth() + 1;
        const metricYear = metricStartDate.getFullYear();

        if (metricMonth === month && metricYear === year) {
          totalScoreSum += metrics.totalScoreSum;
          totalMaxScoreSum += metrics.maxScoreSum;
        }
      });
    });

    return { totalScoreSum, totalMaxScoreSum };
  };

  const {
    totalScoreSum: currentTotalScoreSum,
    totalMaxScoreSum: currentTotalMaxScoreSum,
  } = aggregateScores(currentMonthNumber, currentYear);

  const currentMonthPercentage =
    currentTotalMaxScoreSum > 0
      ? (currentTotalScoreSum / currentTotalMaxScoreSum) * 100
      : 0;

  const {
    totalScoreSum: previousTotalScoreSum,
    totalMaxScoreSum: previousTotalMaxScoreSum,
  } = aggregateScores(previousMonthNumber, previousYear);

  const previousMonthPercentage =
    previousTotalMaxScoreSum > 0
      ? (previousTotalScoreSum / previousTotalMaxScoreSum) * 100
      : 0;

  let differenceFromPreviousMonth: number | null = null;

  if (previousTotalMaxScoreSum > 0 || previousTotalScoreSum > 0) {
    differenceFromPreviousMonth =
      currentMonthPercentage - previousMonthPercentage;
  }

  const roundedCurrentMonthPercentage =
    Math.round(currentMonthPercentage * 100) / 100;
  const roundedDifference =
    differenceFromPreviousMonth !== null
      ? Math.round(differenceFromPreviousMonth * 100) / 100
      : null;

  return {
    currentMonthPercentage: roundedCurrentMonthPercentage,
    differenceFromPreviousMonth: roundedDifference,
  };
};

export const calculateTotalFlags = (
  data: AnalyticsData,
  currentMonth: number
): TotalFlagsResult => {
  let totalFlagged = 0;
  let maxFlagCount = 0;

  Object.values(data.AuditLocationsAnalytics).forEach((location) => {
    const monthKey = currentMonth.toString();
    const metrics = location[monthKey];
    if (metrics) {
      totalFlagged += metrics.totalFlagged;
      if (metrics.totalFlagged > maxFlagCount) {
        maxFlagCount = metrics.totalFlagged;
      }
    }
  });

  const flaggedPercentage =
    maxFlagCount > 0 ? (totalFlagged / maxFlagCount) * 100 : 0;

  return {
    totalFlagged,
    maxFlagCount,
    flaggedPercentage: Math.min(flaggedPercentage, 100),
  };
};

export const calculateOverallAverage = (
  data?: AnalyticsData | null
): number => {
  if (!data || !data.AuditLocationsAnalytics) {
    return 0;
  }

  let totalScoreSum = 0;
  let totalMaxScoreSum = 0;
  Object.values(data.AuditLocationsAnalytics).forEach((locationAnalytics) => {
    if (!locationAnalytics) {
      return;
    }

    Object.values(locationAnalytics).forEach((monthData) => {
      if (!monthData) {
        return;
      }

      const scoreSum =
        typeof monthData.totalScoreSum === 'number'
          ? monthData.totalScoreSum
          : 0;
      const maxScoreSum =
        typeof monthData.maxScoreSum === 'number' ? monthData.maxScoreSum : 0;

      totalScoreSum += scoreSum;
      totalMaxScoreSum += maxScoreSum;
    });
  });

  if (totalMaxScoreSum === 0) {
    return 0;
  }

  const overallAverage = (totalScoreSum / totalMaxScoreSum) * 100;

  return Math.round(overallAverage * 100) / 100;
};

export const calculateLocationBasedAverage = (
  data?: AnalyticsData | null
): number => {
  if (!data || !data.AuditLocationsAnalytics) {
    return 0;
  }

  const locations = Object.values(data.AuditLocationsAnalytics);
  const numLocations = locations.length;

  if (numLocations === 0) {
    return 0;
  }

  let sumOfAverages = 0;

  locations.forEach((locationAnalytics) => {
    if (!locationAnalytics) {
      sumOfAverages += 0;
      return;
    }

    let totalScoreSum = 0;
    let totalMaxScoreSum = 0;

    Object.values(locationAnalytics).forEach((monthData) => {
      if (!monthData) {
        return;
      }

      const scoreSum =
        typeof monthData.totalScoreSum === 'number'
          ? monthData.totalScoreSum
          : 0;
      const maxScoreSum =
        typeof monthData.maxScoreSum === 'number' ? monthData.maxScoreSum : 0;

      totalScoreSum += scoreSum;
      totalMaxScoreSum += maxScoreSum;
    });

    if (totalMaxScoreSum > 0) {
      const locationAverage = (totalScoreSum / totalMaxScoreSum) * 100;
      sumOfAverages += locationAverage;
    } else {
      sumOfAverages += 0;
    }
  });

  const overallAverage = sumOfAverages / numLocations;

  return Math.round(overallAverage * 100) / 100;
};
