import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { BoxHeader } from '../../../../../../../ui-components';
import AuditorItem from './AuditorItem';
import { AuditSessionEntity } from '../../../../../audit-tab/supervised/types';
import { auditStatus } from 'sub-components/audits/audit-tab/supervised/utils';

interface IProps {
  auditData: AuditSessionEntity | undefined;
}

const AuditorSection: FC<IProps> = ({ auditData }) => {
  const _auditStatus = useMemo(() => {
    let isAllCompleted = auditData?.isCompleted;
    let hasAuditStarted = auditData?.assignments?.some(
      (assignment) => assignment.startedBy
    );
    let status = auditStatus(
      !!isAllCompleted,
      auditData?.dueDate || new Date()?.toISOString(),
      !!hasAuditStarted
    );
    return status;
  }, [auditData]);

  const auditors = auditData?.auditors || [];

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='10px'
      p='16px 20px'
      gap={3}
      w='full'
      h='316px'
    >
      <BoxHeader
        boxWidth={3.5}
        fontSize='18px'
        title='Auditors'
        color='#B1E5FC'
      />

      <Flex overflowY='scroll' h='300px' flexDir='column' gap='12px'>
        {auditors?.map((auditor) => {
          return (
            <AuditorItem
              key={auditor.eid}
              eid={auditor.eid}
              name={auditor.name}
              profilePic={auditor.profilePic}
              auditStatus={_auditStatus}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default AuditorSection;
