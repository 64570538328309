import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { HeaderColors } from '../../../../../configs';
import { EmptyState, Loader } from '../../../../../sub-components';
import GenericColorHeader from '../../../../../sub-components/GenericColorHeader';
import AccordionSection from './components/AccordionSection';
import QuestionResponseSection from './components/QuestionResponseSection/QuestionResponseSection';
import { useControlLayer } from './control';
import SectionFilter from '../../../../../sub-components/audits/audit-tab/supervised/components/GridView/components/SectionFilter';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface IProps {
  isAuditRepeating: boolean;
}

const AuditQuestionSection: FC<IProps> = ({ isAuditRepeating }) => {
  let control = useControlLayer(isAuditRepeating);

  if (control?.loading) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <Flex w='full' justify='space-between' gap={4} flexDir='column'>
      <Flex align='center' justify='space-between'>
        <GenericColorHeader
          title='Question wise responses'
          color={HeaderColors.Purple}
        />
        <Flex align='center' gap={4}>
          <SectionFilter
            customTrigger={
              <Flex
                color='rgba(111, 118, 126, 1)'
                border='2px solid rgba(239, 239, 239, 1)'
                borderRadius='12px'
                p='8px 20px'
                fontWeight={600}
                cursor='pointer'
                align='center'
                justify='space-between'
              >
                <Box as='span' isTruncated maxW='100px'>
                  {control?.selectedLocationFilter?.label}
                </Box>
                <ChevronDownIcon fontSize='20px' mt='2px' />
              </Flex>
            }
            options={control?.auditLocations}
            value={control?.selectedLocationFilter}
            handleOptionClick={(value) =>
              control?.setSelectedLocationFilter(value)
            }
          />
          {isAuditRepeating && (
            <SectionFilter
              customTrigger={
                <Flex
                  color='rgba(111, 118, 126, 1)'
                  border='2px solid rgba(239, 239, 239, 1)'
                  borderRadius='12px'
                  p='8px 20px'
                  fontWeight={600}
                  cursor='pointer'
                  align='center'
                  justify='space-between'
                >
                  <Box as='span' isTruncated maxW='100px'>
                    {control?.selectedSession?.label}
                  </Box>
                  <ChevronDownIcon fontSize='20px' mt='2px' />
                </Flex>
              }
              options={control?.displaySessions}
              value={control?.selectedSession}
              handleOptionClick={(value) => {
                control?.setSelectedSession(value);
                control?.setSelectedAuditSessionId(value?.value);
              }}
            />
          )}
        </Flex>
      </Flex>
      {control?.questionData?.length === 0 ? (
        <EmptyState
          image='MyTask'
          title='No Audit Data Available'
          description='This audit has not yet been started at the assigned locations for the selected cycle. Try selecting a different date.'
        />
      ) : (
        <Flex gap={4}>
          <Flex w='30%'>
            <AccordionSection questionData={control?.questionData} />
          </Flex>
          <Flex w='70%'>
            <QuestionResponseSection
              auditData={control?.auditData}
              questionSectionData={control?.questionSectionData}
              refetchHandler={control?.refetchHandler}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default AuditQuestionSection;
