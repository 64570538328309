import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface IProps {
  heading: string;
  details: string;
  scoreSkip?: boolean;
}

const InfoCard: FC<IProps> = ({ heading, details }) => {
  return (
    <Box
      flex={1}
      borderTop='1px solid #EFEFEF'
      padding='16px 20px'
      display='inline-block'
      _notLast={{
        borderRight: '1px solid #EFEFEF',
      }}
    >
      <Flex direction='column' justifyContent='center' gap={2}>
        <Text
          lineHeight='20px !important'
          fontWeight={500}
          color='#6F767E'
          fontSize='15px'
        >
          {heading}
        </Text>
        {heading === 'Inspection score' ? (
          <Flex align='baseline'>
            <Box fontWeight={500} fontSize='22px' lineHeight='27px'>
              {details?.split(' ')[0]}
            </Box>
            <Box
              fontWeight={500}
              fontSize='18px'
              lineHeight='22px'
              color='#6F767E'
            >
              {details?.split(' ')[1]}
            </Box>
          </Flex>
        ) : (
          <Box fontWeight={500} fontSize='22px' lineHeight='27px'>
            {details}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default InfoCard;
