import React, { FC } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import Loader from '../../../../Loader';
import EvidenceUploadInput from './EvidenceUploadInput';
import { useUploadContext } from './EvidenceProvider';
import { QueImage } from './evidence-upload.store';

interface IUProps {
  data: QueImage;
  onCancelClick: (data: QueImage) => void;
}

const ImageUploading: FC<IUProps> = ({ data, onCancelClick }) => {
  return (
    <Box boxSize='48px' position='relative' borderRadius='4px'>
      <Center position='absolute' boxSize='full' bg='blackAlpha.200'>
        <Loader thickness='2px' />
      </Center>

      <Image src={data.thumbnail} boxSize='48px' borderRadius='4px' />

      <Center
        position='absolute'
        top='-9px'
        right='-9px'
        bg='#33383F'
        boxSize='16px'
        borderRadius='full'
        cursor='pointer'
        _hover={{
          shadow: 'md',
        }}
        onClick={() => onCancelClick?.(data)}
      >
        <CloseIcon boxSize='7px' color='white' />
      </Center>
    </Box>
  );
};

interface IProps {}

const EvidenceWrapper: FC<IProps> = () => {
  const uploadQue = useUploadContext((state) => state.uploadQue);
  const removeFromQue = useUploadContext((state) => state.removeFromQue);

  const onCancelClick = (data: QueImage) => {
    data.cancelToken?.cancel();
    removeFromQue(data.eid);
  };

  return (
    <>
      {uploadQue.map((que, index) => {
        return (
          <ImageUploading
            key={index}
            data={que}
            onCancelClick={onCancelClick}
          />
        );
      })}

      <EvidenceUploadInput />
    </>
  );
};

export default EvidenceWrapper;
