export const fileSize = (size: number): string => {
  const units = ['bytes', 'KB', 'MB', 'GB'];
  if (!size) {
    return '0 KB';
  }
  let l = 0;
  let n = parseInt(size + '', 10) || 0;
  while (n >= 1000 && ++l) {
    n = n / 1000;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};
