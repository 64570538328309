import React, { FC } from 'react';
import { Box, Flex, useFormControlContext } from '@chakra-ui/react';

interface IProps {
  value: string;
}

const OptionViewInput: FC<IProps> = ({ value }) => {
  const field = useFormControlContext();
  return (
    <Flex
      flex={1}
      height={12}
      borderRadius='12px'
      border='2px solid #EFEFEF'
      align='center'
      paddingInline='18px'
      fontWeight={500}
      color='#6F767E'
      bg='#FFFFFF'
      aria-invalid={field?.isInvalid}
      _invalid={{
        borderColor: '#FFBC99',
      }}
    >
      <Box as='span' noOfLines={1}>
        {value}
      </Box>
    </Flex>
  );
};

export default OptionViewInput;
