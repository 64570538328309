import { Box, Flex, Text, Image, Divider } from '@chakra-ui/react';
import { FC } from 'react';
import { formatDateTime } from 'sub-components/audits/listing/helper';
import { getImage } from 'utils';
import { AuditSessionAssignments, PdfDataType } from '../../types/report.types';

type pdfOverviewType = {
  pdfData: PdfDataType;
  assignment: AuditSessionAssignments;
  additionalData: any;
};

const PdfOverview: FC<pdfOverviewType> = ({
  pdfData,
  assignment,
  additionalData,
}) => {
  let addressDetail = '';
  if (pdfData?.address) {
    addressDetail = `${
      pdfData?.address?.city ? pdfData?.address?.city + ' ,' : ''
    } ${pdfData?.address?.state ? pdfData?.address?.state + ' ,' : ''} ${
      pdfData?.address?.zipCode ? pdfData?.address?.zipCode : ''
    }`;
  }

  return (
    <Box>
      <Flex paddingX={'1.5rem'} justifyContent={'space-between'}>
        <Box>
          <Flex gap={4} alignItems={'center'}>
            <Image
              borderRadius={'8px'}
              src={getImage(pdfData?.profilePic, pdfData?.companyName)}
              alt={'profile'}
              width={'94px'}
              height='94px'
            />
            <Flex direction={'column'} gap={2}>
              <Text fontSize={'20px'} fontWeight={600}>
                {pdfData?.companyName}
              </Text>
              <Flex direction={'column'}>
                <Text
                  lineHeight={'16px !important'}
                  fontWeight={600}
                  fontSize={'14px'}
                >
                  {addressDetail.trim() ? addressDetail.trim() : '--'}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        {assignment?.maxScore > 0 && (
          <Flex direction={'column'} gap={2} alignItems={'center'}>
            <Text fontSize={'20px'} fontWeight={600}>
              Audit Score
            </Text>
            <Text fontWeight={600} color={'#727272'} fontSize={'24px'}>
              {assignment?.totalScore}/{assignment?.maxScore}{' '}
              {`(${(
                (assignment?.totalScore / assignment?.maxScore
                  ? assignment?.totalScore / assignment?.maxScore
                  : 0) * 100
              )?.toFixed(2)}%)`}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex
        marginTop={'50px'}
        gap={'100px'}
        direction={'column'}
        alignItems={'center'}
      >
        <Text fontWeight={600} fontSize={'24px'}>
          {pdfData?.reportTitle?.toUpperCase()}
        </Text>
        <Text fontSize={'24px'} fontWeight={600}>
          General Details
        </Text>
        <Divider
          marginBottom={'2rem'}
          height={'1px'}
          backgroundColor={'black'}
        />
      </Flex>
      <Box paddingX={'1.5rem'} fontSize={'20px'} fontWeight={600}>
        <Box>
          <Flex justifyContent={'space-between'}>
            <Text>Report Number:</Text>
            <Text>{pdfData?.reportNumber}</Text>
          </Flex>
          <Divider
            height={'1px'}
            marginTop={'1rem'}
            backgroundColor={'#C7C7C7'}
          />
        </Box>
        <Box>
          <Flex marginTop={'1rem'} justifyContent={'space-between'}>
            <Text>Audit Date:</Text>
            <Text>
              {pdfData?.auditDate
                ? formatDateTime(pdfData?.auditDate, additionalData?.timeZone)
                    .dateString
                : '--'}
            </Text>
          </Flex>
          <Divider
            height={'1px'}
            marginTop={'1rem'}
            backgroundColor={'#C7C7C7'}
          />
        </Box>
        <Box>
          <Flex marginTop={'1rem'} justifyContent={'space-between'}>
            <Text>Conducted at:</Text>
            <Flex direction={'column'} alignItems={'flex-end'}>
              <Text>{addressDetail?.trim() ? addressDetail : '--'}</Text>
            </Flex>
          </Flex>
          <Divider
            height={'1px'}
            marginTop={'1rem'}
            backgroundColor={'#C7C7C7'}
          />
        </Box>
        <Box>
          <Flex marginTop={'1rem'} justifyContent={'space-between'}>
            <Text>Flagged Items:</Text>
            <Text>
              {pdfData?.flaggedItemCount
                ? pdfData?.flaggedItemCount + ' flagged items'
                : '--'}
            </Text>
          </Flex>
          <Divider
            height={'1px'}
            marginTop={'1rem'}
            backgroundColor={'#C7C7C7'}
          />
        </Box>
        <Box>
          <Flex marginTop={'1rem'} justifyContent={'space-between'}>
            <Text>Auditor Name:</Text>
            <Text>{pdfData?.auditorName}</Text>
          </Flex>
          <Divider height={'1px'} marginY={'1rem'} backgroundColor={'black'} />
        </Box>
      </Box>
      <Flex justifyContent={'center'}>
        <Text paddingY={'2rem'} fontSize={'24px'} fontWeight={600}>
          Audit responses
        </Text>
      </Flex>
    </Box>
  );
};

export default PdfOverview;
