import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { IconImage } from '../../../../../ui-components';
import { useUploadContext } from './EvidenceProvider';

interface IProps {}

const EvidenceList: FC<IProps> = () => {
  const images = useUploadContext((state) => state.value);
  const removeImage = useUploadContext((state) => state.removeImage);

  return (
    <>
      {images.map((image, index) => (
        <Box
          boxSize='48px'
          position='relative'
          key={index}
          _hover={{
            div: {
              visibility: 'visible',
            },
          }}
        >
          <IconImage
            boxSize={48}
            name=''
            thumbnail={image.url}
            borderRadius='4px'
          />

          <Center
            visibility='hidden'
            position='absolute'
            top='-9px'
            right='-9px'
            bg='#33383F'
            boxSize='16px'
            borderRadius='full'
            cursor='pointer'
            _hover={{
              shadow: 'md',
            }}
            onClick={() => removeImage?.(index)}
          >
            <CloseIcon boxSize='7px' color='white' />
          </Center>
        </Box>
      ))}
    </>
  );
};

export default EvidenceList;
