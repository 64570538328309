import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BasicDetails, EntityDetails } from '../Components';
import { ActionButton } from '../../../ui-components';

import { IFormInput } from './add-location.types';
import { deployBasicInfo } from './add-location.events';

interface IProps {}

const BasicInformation: FC<IProps> = () => {
  const { handleSubmit, setValue } = useFormContext<IFormInput>();

  const { t } = useTranslation(['common', 'location']);

  return (
    <Flex flexDir='column' gap={4}>
      <Box fontSize='32px' fontWeight='600' color='#111315' textAlign='center'>
        Basic Information
      </Box>

      <BasicDetails />

      <EntityDetails />

      <ActionButton
        size='lg'
        type='submit'
        colorScheme='blue'
        width='fit-content'
        minW='170px'
        alignSelf='flex-end'
        fontSize='15px'
        fontWeight='600'
        actionFn={handleSubmit((values) => {
          deployBasicInfo(values);
          return setValue('currentTab', 1);
        })}
      >
        {t('common:next')}
      </ActionButton>
    </Flex>
  );
};

export default BasicInformation;
