import {
  Box,
  Flex,
  Grid,
  Text,
  Tooltip as ChakraTooltip,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts-new';
import { HeaderColors } from 'shared/headerColors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { FlaggedGraphData } from '../types';

const COLORS = [
  'rgba(202, 189, 255, 1)',
  'rgba(177, 229, 252, 1)',
  'rgba(255, 188, 153, 1)',
  'rgba(255, 216, 141, 1)',
  'rgba(181, 228, 202, 1)',
  'rgba(255, 159, 146, 1)',
];

const GraphSection: React.FC<{
  graphData: FlaggedGraphData[];
  totalFlags: number;
}> = ({ graphData, totalFlags }) => {
  return (
    <Flex flexDir='column' gap={4}>
      <GenericColorHeader
        title='Location wise breakdown'
        color={HeaderColors.Purple}
      />
      <Flex justify='space-between'>
        <Box boxSize='400px'>
          <ResponsiveContainer width='100%' height='100%'>
            <PieChart>
              <Pie
                data={graphData}
                dataKey='flagCount'
                nameKey='locName'
                cx='50%'
                cy='50%'
                outerRadius={200}
                fill='#8884d8'
              >
                {graphData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Flex flexDir='column' w='50%' gap={4}>
          <Flex flexDir='column' mb={6}>
            <Box
              as='span'
              fontSize='16px'
              fontWeight={500}
              color='rgba(109, 109, 109, 1)'
            >
              Total flagged responses
            </Box>
            <Box as='span' fontWeight={600} fontSize='32px'>
              {totalFlags} {totalFlags === 1 ? 'flag' : 'flags'}
            </Box>
          </Flex>
          <Grid
            templateColumns={{
              md: '1fr',
              lg: 'repeat(2, 1fr)',
              xl: 'repeat(2, 1fr)',
              '2xl': 'repeat(2, 1fr)',
            }}
            gap={{ lg: '0px', xl: '0px', '2xl': '0px' }}
            w='full'
            overflowY='scroll'
            maxH='30vh'
            paddingRight='4px'
          >
            {graphData?.map((graph, index) => (
              <Flex key={index} gap='14px' align='center' p={4} bg='white'>
                {/* Colored Box */}
                <Box
                  boxSize='32px'
                  borderRadius='2px'
                  bg={COLORS[index % COLORS.length]}
                />
                <Flex flexDir='column'>
                  <ChakraTooltip label={graph?.locName} hasArrow>
                    <Text
                      fontWeight={600}
                      fontSize='15px'
                      isTruncated
                      maxW='100px'
                      cursor='pointer'
                    >
                      {graph?.locName}
                    </Text>
                  </ChakraTooltip>
                  <Box
                    color='rgba(111, 118, 126, 1)'
                    fontWeight={500}
                    fontSize='13px'
                  >
                    {graph?.flagCount}{' '}
                    {graph?.flagCount === 1 ? 'flag' : 'flags'}
                  </Box>
                </Flex>
              </Flex>
            ))}
          </Grid>
          {/* <Flex flexWrap='wrap' gap='40px' w='full' overflowY='scroll' maxH='20vh'>
            {graphData?.map((graph, index) => (
              <Flex gap='5px' align='center' w='40%' justify='space-between'>
                <Flex flexDir='column'>
                  <Box fontWeight={600} fontSize='15px' isTruncated maxW='150px'>{graph?.locName}</Box>
                  <Box color='rgba(111, 118, 126, 1)' fontWeight={500} fontSize='13px'>{graph?.flagCount} {graph?.flagCount === 1 ? 'flag' : 'flags'}</Box>
                </Flex>
                <Box
                  boxSize='32px'
                  minW='32px'
                  minH='32px'
                  maxW='32px'
                  maxH='32px'
                  borderRadius='2px'
                  bg={COLORS[index % COLORS.length]}
                />
              </Flex>
            ))}
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GraphSection;
