import { gql } from '@apollo/client';
import { ILocation } from '../../../sub-components/Locations/locationTypes/location-types';
import { FieldType, ISelectedFilterTab } from './LocationListContainer';

export interface LocationResponse {
  Locations: {
    items: ILocation[];
    hasMore: boolean;
    count: number;
    pageCount: number;
    currentPage: number;
  };
}

export type LocationSort =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'NAME_ASC'
  | 'NAME_DESC'
  | 'locationType_asc'
  | 'locationType_desc';

export interface LocationVariable {
  page?: number;
  query?: string;
  sort?: LocationSort;
  filter?: {
    locationStatus?: ISelectedFilterTab;
    city?: string[];
    state?: string[];
  };
  allLocations?: boolean;
  locationIds?: string[];
}

export interface EntityMetaResponse {
  createdAt: string;
  entityId?: string;
  eid: string;
  locationColumns: FieldType[];
  updatedAt: string;
  userId: string;
}

export const GET_LOCATIONS = gql`
  query Locations(
    $page: Int
    $query: String
    $sort: userLocationSort
    $filter: LocationQueryFilterInput
    $allLocations: Boolean
    $locationIds: [String]
  ) {
    Locations(
      page: $page
      query: $query
      sort: $sort
      filter: $filter
      allLocations: $allLocations
      locationIds: $locationIds
    ) {
      items {
        name
        address {
          address
          city
          state
          zipCode
        }
        locationStatus
        locationType
        phone
        status
        email
        username
        isDeleted
        isRoot
        eid
        members
        thumbnail
        createdAt
        timezone
        locationEmail
        locationPhone
        otherFields {
          createdAt
          fieldId
          files {
            name
            url
            type
            mimetype
            fileSize
            createdBy {
              eid
              name
            }
            createdAt
            updatedAt
          }
          options
          updatedAt
          value
        }
      }
      hasMore
      count
      pageCount
      currentPage
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation AddLocation($input: NewLocationInput) {
    addLocation(input: $input) {
      email
      username
      name
      phone
      profilePic
      onboarded
      isDemo
      onboardedAt
      authRole
      type
      role
      entityId
      loginCount
      lastLogin
      isDeleted
      isRoot
      isInvited
      status
      childrens
      updatedAt
      createdAt
      eid
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateUserById($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      name
      phone
      eid
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation ChangeUsersPassword($eid: String!, $password: String!) {
    changeUsersPassword(eid: $eid, password: $password) {
      succeed
    }
  }
`;

export const MOVE_USERS_TO_LOCATION = gql`
  mutation MoveUsersToLocation(
    $deleteLoc: String!
    $moveToLoc: String!
    $userIds: [String!]
  ) {
    moveUsersToLocation(
      deleteLoc: $deleteLoc
      moveToLoc: $moveToLoc
      userIds: $userIds
    ) {
      succeed
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocationWithUsers($locationId: String!) {
    deleteLocationWithUsers(locationId: $locationId) {
      succeed
    }
  }
`;

export const GET_STATE_CITY_DATA_FOR_LOCATIONS = gql`
  query Query($entityId: String!) {
    EntityLocationMeta(entityId: $entityId)
  }
`;

export const GET_INIT_LOCATION_TABLE_FIELDS = gql`
  query InitialLocationColumnsMeta($userId: String!) {
    FetchUserMeta(userId: $userId) {
      createdAt
      eid
      userId
      locationColumns
      userId
      updatedAt
      createdAt
    }
  }
`;

export const GET_LOCATION_TABLE_FIELDS = gql`
  query LocationColumnsMeta($userId: String!) {
    FetchUserMeta(userId: $userId) {
      createdAt
      eid
      userId
      locationColumns
      userId
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_INIT_LOCATION_TABLE_FIELD_VISIBILITY = gql`
  mutation InitUpdateUserMeta($input: UpdateUserMetaInputInput) {
    UpdateUserMeta(input: $input) {
      locationColumns
    }
  }
`;

export const UPDATE_LOCATION_TABLE_FIELD_VISIBILITY = gql`
  mutation UpdateUserMeta($input: UpdateUserMetaInputInput) {
    UpdateUserMeta(input: $input) {
      locationColumns
    }
  }
`;
