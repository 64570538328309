import React, { cloneElement, ReactElement, useMemo } from 'react';
import { TableProps } from 'antd';
import { AntdTable } from './table.styles';
import { ReactComponent as PrevIcon } from '../../assets/images/prev-page.svg';
import { ReactComponent as NextIcon } from '../../assets/images/next-page.svg';

function ForwardTable<RecordType extends object>(
  props: TableProps<RecordType>
): ReactElement {
  const { pagination, ...rest } = props;

  const paginationConfig = useMemo((): TableProps['pagination'] => {
    if (pagination === false) {
      return pagination;
    }
    return {
      hideOnSinglePage: true,
      itemRender: (page, type, element) => {
        switch (type) {
          case 'next':
            return cloneElement(element as ReactElement, {
              children: (
                <span role='img' className='anticon ant-icon-right'>
                  <NextIcon />
                </span>
              ),
            });
          case 'prev':
            return cloneElement(element as ReactElement, {
              children: (
                <span role='img' className='anticon ant-icon-left'>
                  <PrevIcon />
                </span>
              ),
            });
          default:
            return element;
        }
      },
      ...pagination,
    };
  }, [pagination]);

  return (
    <AntdTable<RecordType>
      {...rest}
      tableLayout='auto'
      pagination={paginationConfig}
    />
  );
}

const BaseTable = ForwardTable;

export type { TableProps };

export default BaseTable;
