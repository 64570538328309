import { useReactiveVar } from '@apollo/client';
import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SearchInput,
  SelectOption,
  SorterResult,
  Table,
  useChangeHandler,
} from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { useSafeState, useUserDataSelector } from 'hooks';
import moment from 'moment';
import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import {
  AuditSessionAssignments,
  AuditSessionEntity,
  AuditUser,
  ProcessData,
} from 'sub-components/audits/audit-tab/supervised/types';
import { processData } from 'sub-components/audits/audit-tab/supervised/utils/processData';
import { Filter } from 'sub-components/audits/detail/components';
import { useTakeAuditInfo } from 'sub-components/audits/location-owner/TakeAuditInfo';
import EmptyState from 'sub-components/EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import { getImage, toArray } from 'utils';
import { HeaderColors } from '../../../../../../shared/headerColors';
import GenericColorHeader from '../../../../../GenericColorHeader';
import { LocationOwnersAuditsResponse, UpcomingTableEntity } from '../../types';

interface IProps {
  auditData: LocationOwnersAuditsResponse | undefined;
  homePageRender?: boolean;
}

const UpcomingAuditSection: FC<IProps> = ({ auditData, homePageRender }) => {
  const [sorter, setSorter] = useSafeState<SorterResult<UpcomingTableEntity>>();
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState<
    SelectOption<string>[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const {
    loggedInUserLocations,
    loggedInUserType,
    loggedInUserEid,
    loggedInUserAuthRole,
  } = useUserDataSelector((state) => ({
    loggedInUserLocations: state?.locations,
    loggedInUserType: state?.type,
    loggedInUserEid: state?.eid,
    loggedInUserAuthRole: state?.authRole,
  }));
  const entityData = useReactiveVar(usersEntityObj);
  const takeAuditInfo = useTakeAuditInfo();

  const filterLocationsData = useMemo(() => {
    return (
      loggedInUserLocations?.map((loc) => ({
        label: loc?.name,
        value: loc?.eid,
      })) || []
    );
  }, [loggedInUserLocations]);

  const handleFilterChange = (option: SelectOption) => {
    setSelectedLocationsFilter((prevFilters) => {
      const isSelected = prevFilters.some(
        (filter) => filter.value === option.value
      );
      if (isSelected) {
        return prevFilters.filter((filter) => filter.value !== option.value);
      } else {
        return [...prevFilters, option];
      }
    });
  };

  const handleSearchChange = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  const filteredInitialData = (items: AuditSessionEntity[]) => {
    let locationsArr: AuditSessionEntity[] = [];
    items?.forEach((item) => {
      let hasAllLocationsCompletedAudit = item?.assignments?.every(
        (assignment) => assignment?.isCompleted
      );
      if (!hasAllLocationsCompletedAudit) {
        locationsArr.push(item);
      }
    });
    return locationsArr;
  };

  const getAuditors = (upcoming: ProcessData): AuditUser[] => {
    let auditors = upcoming?.auditors;
    let assignments = upcoming?.assignments;
    if (loggedInUserType === 'user') {
      if (
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(loggedInUserAuthRole)
      ) {
        return auditors ?? [];
      }

      const myAssignments =
        assignments?.filter((assignment) =>
          loggedInUserLocations?.some(
            (loc) => loc?.eid === assignment?.locationId
          )
        ) ?? [];

      const auditorEids = myAssignments?.flatMap(
        (assignment) => assignment?.auditors ?? []
      );
      const uniqueEids = Array.from(new Set(auditorEids));

      return (
        entityData
          ?.filter((entity) => uniqueEids?.includes(entity?.eid))
          .map(({ eid, name, profilePic }) => ({ eid, name, profilePic })) ?? []
      );
    } else {
      let foundLocation = assignments?.find(
        (assignment) => assignment?.locationId === loggedInUserEid
      );
      if (foundLocation) {
        return entityData
          ?.filter((entity) => foundLocation?.auditors?.includes(entity?.eid))
          ?.map((user) => ({
            eid: user?.eid,
            name: user?.name,
            profilePic: user?.profilePic,
          }));
      }
    }
    return [];
  };

  const upcomingAuditData = useMemo((): UpcomingTableEntity[] => {
    let initialData = filteredInitialData(
      auditData?.AuditSessionPagination?.items || []
    );
    let processedData = processData(initialData as AuditSessionEntity[]);

    // Filter for upcoming audits
    let filteredUpcomingAudits = processedData?.filter(
      (process) =>
        process?.auditStatus === 'upcoming' ||
        process?.auditStatus === 'ongoing'
    );

    // Map to UpcomingTableEntity
    let upcomingData: UpcomingTableEntity[] =
      filteredUpcomingAudits?.map((upcoming) => ({
        auditId: upcoming.auditId,
        auditName: upcoming?.title,
        auditors: getAuditors(upcoming),
        dueDate: upcoming?.dueDate,
        location: entityData
          ?.filter((entity) =>
            upcoming?.assignments?.some(
              (assignment) => assignment?.locationId === entity?.eid
            )
          )
          ?.map((location) => location?.name),
      })) || [];

    // Apply filtering based on selected locations
    if (selectedLocationsFilter.length > 0) {
      const selectedLocationNames = selectedLocationsFilter.map(
        (option) => option.label
      );
      upcomingData = upcomingData.filter((audit) =>
        audit.location.some((loc) => selectedLocationNames.includes(loc))
      );
    }

    // Apply filtering based on search query
    if (searchQuery.trim() !== '') {
      const lowercasedQuery = searchQuery.toLowerCase();
      upcomingData = upcomingData.filter(
        (audit) =>
          audit?.location?.some((loc) =>
            loc?.toLowerCase()?.includes(lowercasedQuery)
          )
        // audit.auditName.toLowerCase().includes(lowercasedQuery) ||
        // audit.auditor.name.toLowerCase().includes(lowercasedQuery)
      );
    }

    // Apply sorting based on sorter state
    if (sorter && sorter.field === 'dueDate') {
      upcomingData.sort((a, b) => {
        const dateA = moment.utc(a.dueDate);
        const dateB = moment.utc(b.dueDate);

        if (!dateA.isValid() && !dateB.isValid()) return 0;
        if (!dateA.isValid()) return sorter.order === 'ascend' ? -1 : 1;
        if (!dateB.isValid()) return sorter.order === 'ascend' ? 1 : -1;

        if (dateA.isBefore(dateB)) return sorter.order === 'ascend' ? -1 : 1;
        if (dateA.isAfter(dateB)) return sorter.order === 'ascend' ? 1 : -1;
        return 0;
      });
    }
    if (homePageRender) {
      return upcomingData?.slice(0, 3);
    }
    return upcomingData;
  }, [
    auditData,
    homePageRender,
    entityData,
    sorter,
    selectedLocationsFilter,
    searchQuery,
  ]);

  const onChange = useChangeHandler<UpcomingTableEntity>(
    (pagination, filters, _sorter, extra) => {
      if (Array.isArray(_sorter)) {
        _sorter = _sorter[0];
      }

      if (_sorter && _sorter.field === 'dueDate') {
        setSorter({
          field: _sorter.field,
          order: _sorter.order, // 'ascend' or 'descend'
        });
      } else {
        setSorter(undefined);
      }

      // Optionally handle other actions or fields
      switch (extra.action) {
        case 'sort':
          console.log('SORT : ', _sorter);
          break;
        default:
          console.log(pagination, filters, _sorter, extra);
      }
    }
  );

  const renderAvatars = (auditors: AuditUser[]) => {
    const displayCount = 2;
    const firstTwoAuditors = auditors.slice(0, displayCount);
    const remainingCount = auditors.length - displayCount;
    return (
      <HStack spacing='-10px'>
        {firstTwoAuditors.map((auditor, index) => (
          <Image
            src={getImage(auditor?.profilePic, auditor?.name)}
            boxSize='30px'
            borderRadius='50%'
            border='2px solid white'
          />
        ))}
        {remainingCount > 0 && (
          <Center
            borderRadius='50%'
            boxSize='30px'
            bg='gray.300'
            border='2px solid white'
          >
            <Box as='span'>+{remainingCount}</Box>
          </Center>
        )}
      </HStack>
    );
  };

  // Function to render auditor names with truncation
  const renderNames = (auditors: AuditUser[]) => {
    const displayCount = 2;
    const names = auditors.map((auditor) => auditor.name);
    const displayedNames = names.slice(0, displayCount).join(', ');
    const remainingCount = names.length - displayCount;

    return (
      <Text isTruncated maxW='150px'>
        {displayedNames}
        {remainingCount > 0 && `, +${remainingCount}`}
      </Text>
    );
  };

  const headingSection = () => {
    return (
      <Flex justify='space-between' align='center'>
        <GenericColorHeader
          color={HeaderColors.Yellow}
          title='Upcoming audits'
        />
        <Flex
          color='rgba(42, 133, 255, 1)'
          gap='5px'
          align='center'
          cursor='pointer'
        >
          <FontAwesomeIcon icon={faCircleQuestion as IconProp} />
          <Box as='span' fontWeight={500} onClick={takeAuditInfo}>
            How to prepare for an audit
          </Box>
        </Flex>
      </Flex>
    );
  };

  const filterSection = () => {
    return (
      <Authorize permittedFor={'user'}>
        <Flex align='center' justify='space-between' gap={4}>
          <Flex flex='1'>
            <SearchInput
              placeholder='Search location'
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
              hideShortcuts
            />
          </Flex>
          <Filter
            options={filterLocationsData}
            value={selectedLocationsFilter}
            placeholder='Filter by Location(s)'
            onChange={handleFilterChange}
          />
        </Flex>
      </Authorize>
    );
  };

  const tableRender = () => {
    return (
      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'auditName',
            key: 'auditName',
          },
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (i, j) => (
              <Flex flexDir='column' gap={1}>
                {j?.location?.map((loc, index) => (
                  <Flex key={index} flexDir='column' gap={2} cursor='pointer'>
                    <Tooltip label={loc} hasArrow placement='top'>
                      <Flex
                        w='fit-content'
                        gap='5px'
                        align='center'
                        p='2px 8px'
                        bg='rgba(181, 228, 202, 1)'
                        borderRadius='6px'
                      >
                        <Center
                          bg='rgba(131, 191, 110, 1)'
                          borderRadius='4px'
                          boxSize='20px'
                          color='white'
                        >
                          <FontAwesomeIcon icon={faLocationDot as IconProp} />
                        </Center>
                        <Box as='span' isTruncated maxW='100px'>
                          {loc}
                        </Box>
                      </Flex>
                    </Tooltip>
                  </Flex>
                ))}
              </Flex>
            ),
          },
          {
            title: 'Auditor(s)',
            dataIndex: 'auditor',
            key: 'auditor',
            render: (_, j) => (
              <Tooltip
                label={j?.auditors?.map((auditor) => auditor?.name)?.join(', ')}
                hasArrow
              >
                <HStack spacing={1} cursor='pointer'>
                  {renderAvatars(j?.auditors)}
                  {renderNames(j?.auditors)}
                </HStack>
              </Tooltip>
            ),
          },
          {
            title: 'Due By',
            dataIndex: 'dueDate',
            sorter: true,
            key: 'dueDate',
            render: (_, j) => (
              <Box minW='100px'>
                {moment(j?.dueDate)?.isValid()
                  ? moment.utc(j?.dueDate)?.format('DD MMM, YYYY')
                  : 'No Deadline'}
              </Box>
            ),
          },
        ]}
        dataSource={upcomingAuditData}
        rowKey='auditId'
        emptyState={
          <EmptyState
            image='MyTask'
            title='No Upcoming Audits Available'
            description='Your upcoming audits will appear here.'
          />
        }
        onChange={onChange}
        pagination={{
          hideOnSinglePage: true,
          pageSize: upcomingAuditData?.length || 10,
        }}
      />
    );
  };

  if (homePageRender) {
    return tableRender();
  }

  return (
    <Flex flexDir='column' w='full' gap={4}>
      {headingSection()}
      {filterSection()}
      {tableRender()}
    </Flex>
  );
};

export default UpcomingAuditSection;
