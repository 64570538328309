import { match, P } from 'ts-pattern';
import { SorterResult } from '../../../../atoms';
import { TemplateSort } from '../query/audits-listing.graphql';
import { toArray } from '../../../../utils';

export const getTemplateSortBy = <T extends object>(
  values?: SorterResult<T> | SorterResult<T>[]
): TemplateSort => {
  return match<SorterResult<T>, TemplateSort>(toArray(values)[0])
    .with({ columnKey: 'category', order: P.string }, (sel) =>
      sel.order === 'ascend' ? 'TITLE_DESC' : 'TITLE_ASC'
    )
    .with({ columnKey: 'updatedAt', order: P.string }, (sel) =>
      sel.order === 'ascend' ? 'UPDATEDAT_DESC' : 'UPDATEDAT_ASC'
    )
    .with({ columnKey: 'createdAt', order: P.string }, (sel) =>
      sel.order === 'ascend' ? 'CREATEDAT_DESC' : 'CREATEDAT_ASC'
    )
    .otherwise(() => '_ID_DESC');
};
