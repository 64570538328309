import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Authorize, AuthRole } from '../../../../authorization';
import { ActionButton } from '../../../../ui-components';
import { HideComponent } from '../../take-audit/components';

interface IProps {
  title: string;
  description?: string;

  status?: 'active' | 'inactive' | 'archived';

  onEditQuestion?: () => void;
  onImportAndSchedule?: () => void;
}

const PreviewHeader: FC<IProps> = ({
  title,
  description,
  status,
  onEditQuestion,
  onImportAndSchedule,
}) => {
  return (
    <Flex
      // position='sticky'
      // top={0}
      // zIndex={10}
      flexDir='column'
      padding={4}
      bg='#FFFFFF'
    >
      <Box fontSize='20px' fontWeight='600'>
        {title}
      </Box>
      <Box fontSize='12px' color='#272B30'>
        {description}
      </Box>

      <HideComponent
        hidden={
          !(onEditQuestion || onImportAndSchedule) || status === 'archived'
        }
      >
        <Flex gap={5} mt={5}>
          <Authorize permittedRoles={AuthRole.SUPER_ADMIN}>
            <ActionButton
              size='lg'
              flex={1}
              variant='outline'
              actionFn={onImportAndSchedule}
            >
              Import and schedule
            </ActionButton>
          </Authorize>
          <ActionButton
            size='lg'
            flex={1}
            colorScheme='blue'
            actionFn={onEditQuestion}
          >
            Edit Questions
          </ActionButton>
        </Flex>
      </HideComponent>
    </Flex>
  );
};

export default PreviewHeader;
