import React, { cloneElement, FC, ReactElement } from 'react';
import { AntdPagination, PaginationProps } from './pagination.styles';

import { ReactComponent as PrevIcon } from '../../assets/images/prev-page.svg';
import { ReactComponent as NextIcon } from '../../assets/images/next-page.svg';

interface IProps extends Omit<PaginationProps, 'itemRender'> {}

const Pagination: FC<IProps> = (props) => {
  return (
    <AntdPagination
      pageSize={10}
      hideOnSinglePage
      showSizeChanger={false}
      itemRender={(page, type, element) => {
        switch (type) {
          case 'next':
            return cloneElement(element as ReactElement, {
              children: (
                <span role='img' className='anticon ant-icon-right'>
                  <NextIcon />
                </span>
              ),
            });
          case 'prev':
            return cloneElement(element as ReactElement, {
              children: (
                <span role='img' className='anticon ant-icon-left'>
                  <PrevIcon />
                </span>
              ),
            });
          default:
            return element;
        }
      }}
      {...props}
    />
  );
};

export default Pagination;
