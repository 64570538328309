import React from 'react';
import addIcon from './resources/addGroup.svg';
import Translator from '../../../resources/localization/translator';
import {
  useCreateChannel,
  useUserCanCreateChannel,
} from '../../../../../../pages/Settings/ChatSetting/create-channel';
import { deployEvent } from 'shared/amplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';

const CreateChannelButton = ({ lang, onChannelCreated, className }) => {
  const createChannel = useCreateChannel();

  const userCanCreateChannel = useUserCanCreateChannel();

  const onCreateClick = () => {
    deployEvent(AmplitudeEventNames?.CREATE_NEW_CHANNEL_CLICK, {
      create_channel_funnel_id: 3,
      channel_id: '',
    });
    createChannel({
      onGroupCreated: async (_, group) => {
        await onChannelCreated(group);
      },
    });
  };

  if (!userCanCreateChannel) {
    return null;
  }

  return (
    <div
      className={`chat-create-channel ${className}`}
      title={Translator.translate('CREATE_CHANNEL', lang)}
      onClick={onCreateClick}
    >
      <img src={addIcon} />
      <span style={{ marginLeft: '5px' }}>
        {Translator.translate('CREATE_CHANNEL', lang)}
      </span>
    </div>
  );
};

export default CreateChannelButton;
