export const fileToDtaUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function () {
      reject(reader.error);
    };

    reader.readAsDataURL(file);
  });
};

export const convertToWebp = async (dataURL: string): Promise<Blob> => {
  // return new Promise((resolve, reject) => {
  //   const img = new Image();
  //   img.src = dataURL;
  //
  //   img.onload = () => {
  //     const canvas = document.createElement('canvas');
  //     const ctx = canvas.getContext('2d');
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     ctx!.drawImage(img, 0, 0);
  //
  //     // Convert the canvas content to WebP format
  //     // canvas.toDataURL('image/webp', (webpDataURL) => {
  //     //   resolve(dataURLtoBlob(webpDataURL));
  //     // });
  //
  //     canvas.toBlob((blob) => resolve(blob!), 'image/webp');
  //   };
  //
  //   img.onerror = (error) => reject(error);
  // });

  const canvas = document.createElement('canvas');

  try {
    const result = await new Promise<Blob>((resolve, reject) => {
      const img = new Image();
      img.src = dataURL;

      img.onload = () => {
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);

        canvas.toBlob((blob) => resolve(blob!), 'image/webp');
      };

      img.onerror = (error) => reject(error);
    });

    canvas.remove();

    return result;
  } catch (e) {
    canvas.remove();
    return Promise.reject(e);
  }
};

export const getWebpFilename = (fileName: string): string => {
  const _fileName = fileName.split('.');
  _fileName[_fileName.length - 1] = 'webp';

  return _fileName.join('.');
};
