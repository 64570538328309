import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

type MultipleChoiceProps = {
  answer?: string | undefined;
  media?: any;
  isFlagged?: boolean;
};

const MultipleChoice: FC<MultipleChoiceProps> = ({ answer, isFlagged }) => {
  return (
    <Box minWidth={'116px'} height={'41px'}>
      {answer && (
        <Flex
          backgroundColor={isFlagged ? '#FF6A55' : '#83BF6E'}
          color={'white'}
          px={'1rem'}
          height={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'6px'}
        >
          <Text>{answer}</Text>
        </Flex>
      )}
    </Box>
  );
};

export default MultipleChoice;
