import { Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo } from 'react';
import LocationReportSection from './LocationReportSection';
import SummarySection from './SummarySection/SummarySection';
import { AuditorSection } from './AuditorSection';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { useAuditDetailContext } from 'pages/Audits/Detail/store/context';

interface IProps {
  auditData: AuditSessionEntity | undefined;
  refetchHandler?: () => void;
}

const RepeatAuditWithNoScore: FC<IProps> = ({ auditData, refetchHandler }) => {
  const { updateShowLocationsFilter, selectedLocationFilter } =
    useAuditDetailContext((state) => ({
      updateShowLocationsFilter: state.updateShowLocationsFilter,
      selectedLocationFilter: state.selectedLocationFilter,
    }));

  useEffect(() => {
    updateShowLocationsFilter(true);
  }, []);
  const processedAuditData = useMemo<AuditSessionEntity | undefined>(() => {
    if (!auditData) return undefined;

    const { assignments } = auditData;

    if (
      !selectedLocationFilter ||
      selectedLocationFilter.value === 'allLocations'
    ) {
      return auditData;
    }

    const filteredAssignments = assignments?.filter(
      (assignment) => assignment?.locationId === selectedLocationFilter.value
    );

    return { ...auditData, assignments: filteredAssignments };
  }, [auditData, selectedLocationFilter]);

  return (
    <Flex gap={4} w='full'>
      <Flex w='70%'>
        <LocationReportSection
          auditData={processedAuditData}
          scored={false}
          refetchHandler={refetchHandler}
        />
      </Flex>
      <Flex flexDir='column' gap={4} w='30%'>
        <SummarySection auditData={auditData} />
        <AuditorSection auditData={auditData} />
      </Flex>
    </Flex>
  );
};

RepeatAuditWithNoScore.displayName =
  'sub-components/audits/detail/components/Overview/components/RepeatAuditWithNoScore';

export default RepeatAuditWithNoScore;
