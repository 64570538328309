import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { ReactComponent as NotFound } from '../../../assets/images/Illustrations.svg';

type EmptyStateProps = {
  title: string;
};

const EmptyState = ({ title }: EmptyStateProps) => {
  return (
    <Flex
      height={'100%'}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={'1rem'}
    >
      <Box marginBottom={'1rem'}>
        <NotFound height={'100px'} />
      </Box>
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        direction={'column'}
        wrap={'wrap'}
      >
        <Text fontWeight={500} fontSize={'md'}>
          No {title} found
        </Text>
        <Text
          lineHeight={'20px!important'}
          mt={4}
          align={'center'}
          color={'#9E9E9E'}
        >
          Oops! Try a different search to get results.
        </Text>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
