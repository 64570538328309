import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFieldArray } from 'react-hook-form';
import { QuestionType } from '../../../../../types';
import { AuditOptionsEntity } from '../../audit.types';
import { IFormInput } from '../../form-types';
import OptionViewInput from './OptionViewInput';
import OptionIcon from './OptionIcon';

interface IProps {
  questionIndex: number;
  options?: AuditOptionsEntity[];
  qType: QuestionType;
}

const OptionsView: FC<IProps> = ({ qType, questionIndex }) => {
  const { fields } = useFieldArray<IFormInput, `questions.${number}.options`>({
    name: `questions.${questionIndex}.options`,
  });

  return (
    <Flex flexDir='column' gap={3}>
      {fields.map((option, optionIndex) => (
        <Controller<IFormInput, `questions.${number}.options.${number}.label`>
          key={option.eid}
          name={`questions.${questionIndex}.options.${optionIndex}.label`}
          defaultValue=''
          render={({ field, fieldState }) => {
            return (
              <Flex gap={4} hidden={option.isDisabled}>
                <OptionIcon qType={qType} />
                <FormControl isInvalid={fieldState.invalid}>
                  <OptionViewInput value={field.value} />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            );
          }}
        />
      ))}
    </Flex>
  );
};

export default OptionsView;
