import { FC } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import { Button } from '../../../../atoms';
import { ReactComponent as WebView } from '../../../../assets/images/audits/webView.svg';
import { ReactComponent as PdfView } from '../../../../assets/images/audits/pdfView.svg';

export type ReportViewType = 'web' | 'pdf';

export type SelectViewButtonProps = {
  setSelectedViewType: (value: ReportViewType) => void;
  selectedViewType: ReportViewType;
};

const SelectViewButton: FC<SelectViewButtonProps> = ({
  selectedViewType,
  setSelectedViewType,
}) => {
  return (
    <ButtonGroup size='lg' variant='outline' isAttached>
      <Button
        leftIcon={<WebView />}
        borderRightWidth='1px'
        borderRightColor='#6F767E'
        color='#6F767E'
        aria-checked={selectedViewType === 'web'}
        onClick={() => setSelectedViewType('web')}
        _checked={{ color: 'blue.500' }}
      >
        Web View
      </Button>
      <Button
        leftIcon={<PdfView />}
        borderLeftWidth='1px'
        borderLeftColor='#6F767E'
        color='#6F767E'
        aria-checked={selectedViewType === 'pdf'}
        onClick={() => setSelectedViewType('pdf')}
        _checked={{ color: 'blue.500' }}
      >
        Pdf View
      </Button>
    </ButtonGroup>
  );
};

export default SelectViewButton;
