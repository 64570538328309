import { useMutation } from '@apollo/client';
import { AuditQuestionEntity } from '../../../../types';
import {
  CLONE_TEMPLATE,
  DuplicateInput,
} from '../query/audits-listing.graphql';

// function removeKeys<T extends object>(obj: T, keysToRemove: string[]): T {
//   if (Array.isArray(obj)) {
//     return obj.map((item) => removeKeys(item, keysToRemove)) as unknown as T;
//   } else if (obj !== null && typeof obj === 'object') {
//     return Object.keys(obj)
//       .filter((key) => !keysToRemove.includes(key))
//       .reduce<T>((acc, key) => {
//         // @ts-ignore
//         acc[key] = removeKeys(obj[key], keysToRemove);
//         return acc;
//       }, {} as T);
//   }
//   return obj as T;
// }

type AuditInput = {
  eid: string;
  title: string;
  description?: string;
  questions: AuditQuestionEntity[];
};

interface IProps {
  data: AuditInput;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTemplateImport() {
  const [cloneTemplate] = useMutation<never, DuplicateInput>(CLONE_TEMPLATE, {
    onError: () => {},
  });

  return async (props: IProps): Promise<string> => {
    const response = await cloneTemplate({
      variables: {
        eid: props.data.eid,
      },
    });

    if (response.errors) {
      return Promise.reject(response.errors);
    }
    // @ts-ignore
    return response.data?.CloneAuditTemplate?.eid!;
  };
}
