import { Checkbox, HStack, Text, VStack } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { DueDateOption, Filters } from '../types';

const DueDateView: FC<{
  filters: Filters;
  setCurrentView: React.Dispatch<
    React.SetStateAction<'initial' | 'dueDate' | 'locations'>
  >;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}> = ({ filters, setCurrentView, setFilters }) => {
  const handleDueDateChange = (option: DueDateOption) => {
    setFilters((prev) => ({
      ...prev,
      dueDate: prev.dueDate === option ? null : option,
    }));
  };
  return (
    <VStack align='start' spacing={4}>
      <HStack width='100%' justifyContent='space-between' alignItems='center'>
        <FontAwesomeIcon
          icon={faArrowLeft as IconProp}
          color='rgba(111, 118, 126, 1)'
          cursor='pointer'
          fontSize='20px'
          onClick={() => setCurrentView('initial')}
        />
        <Text fontWeight='semibold'>Filter by due date</Text>
      </HStack>
      <VStack align='start'>
        <Checkbox
          isChecked={filters.dueDate === 'dueToday'}
          onChange={() => handleDueDateChange('dueToday')}
        >
          Due today
        </Checkbox>
        <Checkbox
          isChecked={filters.dueDate === 'dueTomorrow'}
          onChange={() => handleDueDateChange('dueTomorrow')}
        >
          Due tomorrow
        </Checkbox>
        <Checkbox
          isChecked={filters.dueDate === 'dueNextWeek'}
          onChange={() => handleDueDateChange('dueNextWeek')}
        >
          Due next week
        </Checkbox>
        <Checkbox
          isChecked={filters.dueDate === 'noDueDate'}
          onChange={() => handleDueDateChange('noDueDate')}
        >
          No due date
        </Checkbox>
      </VStack>
    </VStack>
  );
};

export default DueDateView;
