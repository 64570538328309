import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Box, Center, Flex, IconButton, Image } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { Table } from '../../../../../../atoms/Table';
import { useUserDataSelector } from '../../../../../../hooks';
import { formatNumber, getImage } from '../../../../../../utils';
import { usersEntityObj } from '../../../../../Header';

import { ReactComponent as DownloadIcon } from '../../../../../../assets/images/download.svg';
import { ReactComponent as EyeIcon } from '../../../../../../assets/images/eyeIcon.svg';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { HeaderColors } from 'shared/headerColors';
import { SearchInput, SelectOption } from 'atoms';
import { EmptyState } from 'sub-components';
import { Filter } from 'sub-components/audits/detail/components';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import DownloadReport from 'sub-components/audits/common/download-report';

const statusOptions: SelectOption<string>[] = [
  { label: 'Completed', value: 'completed' },
  { label: 'Ongoing', value: 'ongoing' },
  { label: 'Upcoming', value: 'scheduled' },
  { label: 'Overdue', value: 'overdue' },
];

export interface LatestReport {
  location: string;
  locationId: string;
  auditor: {
    name: any;
    profilePic: string;
  };
  status: string;
  score: any;
  report: boolean;
  reportUrl?: string;
}

interface IProps {
  hideFooter?: boolean;
  scored?: boolean;
  auditData: AuditSessionEntity | undefined;
  refetchHandler?: () => void;
}

const Header: FC<{
  scored: boolean;
  searchQuery: string;
  selectedStatusFilter: SelectOption<string>[];
  setSearchQuery: Dispatch<SetStateAction<string>>;
  handleFilterChange: (option: SelectOption<string>) => void;
}> = ({
  scored,
  searchQuery,
  selectedStatusFilter,
  setSearchQuery,
  handleFilterChange,
}) => {
  return (
    <Flex
      flexDir={scored ? 'row' : 'column'}
      align={scored ? 'center' : undefined}
      justify={scored ? 'space-between' : undefined}
      gap={scored ? undefined : 4}
      width='100%'
    >
      <GenericColorHeader
        title='Latest Reports - Location wise'
        color={HeaderColors.Yellow}
      />
      <Flex flexDir='row' gap={2}>
        <SearchInput
          hideShortcuts
          placeholder='Search location'
          width='100%'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Filter
          options={statusOptions}
          value={selectedStatusFilter}
          placeholder='Filter by status'
          onChange={handleFilterChange}
        />
      </Flex>
    </Flex>
  );
};

const LocationReportSection: FC<IProps> = ({
  auditData,
  hideFooter = false,
  scored = true,
  refetchHandler,
}) => {
  const history = useHistory();
  const { auditId, sessionId } = useParams<{
    auditId: string;
    sessionId: string;
  }>();

  const locations = useUserDataSelector((state) => state?.entity?.locations);
  const entityUsers = useReactiveVar(usersEntityObj);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<
    SelectOption[]
  >([]);

  const latestReportData = useMemo((): LatestReport[] => {
    let arr: LatestReport[] = [];
    auditData?.assignments?.forEach((assignment) => {
      const foundLocation = locations?.find(
        (loc) => loc?.eid === assignment?.locationId
      );

      if (foundLocation) {
        const foundAuditor = entityUsers?.find(
          (user) => assignment?.startedBy === user?.eid
        );
        const locationName = foundLocation?.name?.toLowerCase();
        const searchLowerCase = searchQuery?.toLowerCase();

        let maxScore = assignment?.maxScore;
        let baggedScore = assignment?.totalScore;

        const calculatedScore = maxScore ? (baggedScore / maxScore) * 100 : 0;

        const currentDate = new Date();
        const dueDate = auditData?.dueDate
          ? new Date(auditData?.dueDate)
          : null;
        const isOverdue =
          dueDate && !assignment.isCompleted && currentDate > dueDate;

        const assignmentStatus = assignment.isCompleted
          ? 'completed'
          : isOverdue
          ? 'overdue'
          : assignment.startedBy
          ? 'ongoing'
          : 'scheduled';

        const isFilteredByStatus =
          selectedStatusFilter.length === 0 ||
          selectedStatusFilter.some((filter) => {
            const statusValue = filter.value.toLowerCase();
            return assignmentStatus === statusValue;
          });

        if (
          isFilteredByStatus &&
          (!searchQuery || locationName.includes(searchLowerCase))
        ) {
          arr.push({
            location: foundLocation?.name,
            locationId: foundLocation?.eid,
            auditor: foundAuditor
              ? {
                  name: foundAuditor?.name,
                  profilePic: getImage(
                    foundAuditor?.profilePic,
                    foundAuditor?.name
                  ),
                }
              : { name: '', profilePic: '' },
            status:
              assignmentStatus.charAt(0).toUpperCase() +
              assignmentStatus.slice(1),
            score: {
              baggedScore,
              maxScore,
              scorePercent: calculatedScore.toFixed(2),
            },
            report: assignment?.isCompleted,
            reportUrl: assignment?.reportsUrl,
          });
        }
      }
    });
    return arr;
  }, [auditData, locations, entityUsers, searchQuery, selectedStatusFilter]);

  const handleFilterChange = (option: SelectOption) => {
    setSelectedStatusFilter((prevFilters) => {
      const isSelected = prevFilters.some(
        (filter) => filter.value === option.value
      );
      if (isSelected) {
        return prevFilters.filter((filter) => filter.value !== option.value);
      } else {
        return [...prevFilters, option];
      }
    });
  };

  return (
    <Flex
      flexDir='column'
      border='2px solid #EFEFEF'
      borderRadius='10px'
      w='full'
      p={4}
      gap={4}
    >
      <Header
        scored={scored}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedStatusFilter={selectedStatusFilter}
        handleFilterChange={handleFilterChange}
      />
      <Table
        columns={[
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
          },
          {
            title: 'Auditor',
            dataIndex: 'auditor',
            key: 'auditor',
            render: (value) => (
              <Flex align='center' gap='5px' fontWeight='500'>
                {value?.name ? (
                  <>
                    <Image
                      src={value.profilePic}
                      boxSize='20px'
                      borderRadius='50%'
                    />
                    <Box>{value.name}</Box>
                  </>
                ) : (
                  <>-</>
                )}
              </Flex>
            ),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
              let bgColor = '';
              switch (value.toLowerCase()) {
                case 'completed':
                  bgColor = '#B5E4CAB2';
                  break;
                case 'scheduled':
                  bgColor = '#CABDFF';
                  break;
                case 'ongoing':
                  bgColor = '#FFD88D';
                  break;
                case 'overdue':
                  bgColor = HeaderColors.Red.Mid;
                  break;
                default:
                  bgColor = '';
              }
              return (
                <Box
                  as='span'
                  bg={bgColor}
                  h='26px'
                  p='4px 10px'
                  w='fit-content'
                  borderRadius='3px'
                  textTransform='capitalize'
                >
                  {value?.toLowerCase() === 'scheduled' ? 'Upcoming' : value}
                </Box>
              );
            },
          },
          ...(scored
            ? [
                {
                  title: 'Score',
                  dataIndex: 'score',
                  key: 'score',
                  render: (value: any) => (
                    <Flex w='fit-content'>
                      {value ? (
                        <>
                          <Box>{formatNumber(value?.scorePercent)}%</Box>
                          <Box>
                            ({value?.baggedScore}/{value?.maxScore})
                          </Box>
                        </>
                      ) : (
                        <Box>-</Box>
                      )}
                    </Flex>
                  ),
                },
              ]
            : []),
          {
            title: 'Report',
            dataIndex: 'report',
            key: 'report',
            width: 200,
            render: (value) => (
              <Box as='span' fontWeight='500'>
                {value ? 'Ready to download' : 'Yet to be generated'}
              </Box>
            ),
          },
          {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (_, j) => {
              if (!j?.report) {
                return <></>;
              }
              return (
                <Flex align='center' gap={4}>
                  <IconButton
                    size='sm'
                    variant='ghost'
                    aria-label='view report'
                    icon={<EyeIcon color='#6F767E' />}
                    onClick={() =>
                      history.push(
                        `/audits/report/lI/${j?.locationId}/sI/${sessionId}`
                      )
                    }
                  />
                  <DownloadReport
                    locationId={j?.locationId}
                    sessionId={sessionId}
                    reportsUrl={j?.reportUrl}
                    callbackFn={refetchHandler}
                  >
                    <IconButton
                      size='sm'
                      variant='ghost'
                      aria-label='download'
                      icon={<DownloadIcon />}
                    />
                  </DownloadReport>
                </Flex>
              );
            },
          },
        ]}
        rowKey='locationId'
        emptyState={<EmptyState image='Search' title='No Data Found' />}
        dataSource={latestReportData}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
      {!hideFooter && (
        <Center
          bg='rgba(42, 133, 255, 0.06)'
          borderRadius='6px'
          p='8px'
          fontWeight={500}
        >
          Older reports available in audit history
        </Center>
      )}
    </Flex>
  );
};

export default LocationReportSection;
