import { Center, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Loader } from '../../../../../sub-components';
import { useControlLayer } from './layers';
import ResponseSection from './components/ResponseSection';

interface IProps {
  isAuditRepeating: boolean;
}

const FlaggedResponse: FC<IProps> = ({ isAuditRepeating }) => {
  const control = useControlLayer(isAuditRepeating);

  if (control?.loading) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <Flex p={4} flexDir='column' gap={8}>
      <ResponseSection control={control} isAuditRepeating={isAuditRepeating} />
    </Flex>
  );
};

export default FlaggedResponse;
