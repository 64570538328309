import { gql } from '@apollo/client';

export const FLAGGED_RESPONSE_DETAILS = gql`
  query AuditSessionPagination($filter: FilterFindManyAuditSessionInput) {
    AuditSessionPagination(filter: $filter) {
      items {
        name
        eid
        title
        dueDate
        startDate
        isCompleted
        createdAt
        entityId
        updatedAt
        description
        auditId
        questions
        assignments {
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          startedBy
          totalScore
          response
        }
      }
    }
  }
`;

export const FLAGGED_RESPONSE_GRAPH_DATA = gql`
  query FlaggedResponseGraphData($filter: FilterFindManyAuditSessionInput) {
    AuditSessionPagination(filter: $filter) {
      items {
        name
        eid
        title
        entityId
        auditId
        questions
        assignments {
          completedPercent
          locationId
          response
        }
      }
    }
  }
`;

export const AUDIT_FLAGGED_RESPONSE_DETAILS_BY_SESSION_ID = gql`
  query AuditFlaggedResponseDetailsBySessionId($eid: String!) {
    AuditSessionById(eid: $eid) {
      name
      eid
      title
      dueDate
      startDate
      isCompleted
      createdAt
      entityId
      updatedAt
      description
      auditId
      questions
      assignments {
        auditors
        completedAt
        completedBy
        completedPercent
        isCompleted
        locationId
        startedBy
        totalScore
        response
      }
    }
  }
`;

export const AUDIT_FLAGGED_RESPONSES_CYCLES = gql`
  query AuditFlaggedResponsesCycles(
    $filter: FilterFindManyAuditSessionInput
    $sort: SortFindManyAuditSessionInput
    $page: Int
    $perPage: Int
  ) {
    AuditSessionPagination(
      filter: $filter
      sort: $sort
      page: $page
      perPage: $perPage
    ) {
      items {
        eid
        dueDate
        startDate
      }
    }
  }
`;
