import React, { createContext, FC, useContext, useEffect, useRef } from 'react';
import { toArray } from '../../../../../utils';
import { useStore } from '../../../../../zustandStore';
import { useUploadImage } from '../../../../../hooks';
import { AuditEvidenceEntity } from '../../../../../types';
import { createEvidenceStore, EvidenceAction } from './evidence-upload.store';

type UploadStore = ReturnType<typeof createEvidenceStore>;

const UploadContext = createContext<UploadStore>({} as never);

interface ProviderProps {
  value?: AuditEvidenceEntity[];
  onChange?: (value: AuditEvidenceEntity[]) => void;
}

const EvidenceProvider: FC<ProviderProps> = ({ children, value, onChange }) => {
  const storeRef = useRef<UploadStore>();

  const imageUpload = useUploadImage();

  useEffect(() => {
    if (storeRef.current) {
      storeRef.current.setState({
        value: toArray(value),
      });
    }
  }, [value]);

  if (!storeRef.current) {
    storeRef.current = createEvidenceStore({
      uploadAction: imageUpload,
      onChange: onChange,
    });
  }

  return (
    <UploadContext.Provider value={storeRef.current}>
      {children}
    </UploadContext.Provider>
  );
};

export function useUploadContext<T>(selector: (state: EvidenceAction) => T): T {
  const store = useContext(UploadContext);
  if (!store) throw new Error('Missing UploadContext.Provider in the tree');
  return useStore(store, selector);
}

export default EvidenceProvider;
