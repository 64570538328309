import React, { FC, useEffect } from 'react';
import { Flex, usePrevious } from '@chakra-ui/react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { SearchInput } from 'atoms';
import { IFormInput, SectionEntity } from '../types';
import QuestionSectionHeader from './QuestionSectionHeader';
import QuestionSectionFooter from './QuestionSectionFooter';
import { useSafeState } from '../../../../hooks';
import QuestionsList from './QuestionsList';
import QuestionFilter from './QuestionFilter';
import { useAuditSubmitHandler } from '../useAuditSubmitHandler';
import { AuditTake, isCompleted } from '../tabke-audit.helpers';
import PrevSectionButton from './PrevSectionButton';
import NextSectionButton from './NextSectionButton';

interface IProps {
  selectedSection: number;
}

const QuestionsSection: FC<IProps> = ({ selectedSection }) => {
  const { getValues, setValue, clearErrors } = useFormContext<IFormInput>();

  const methods = useForm<SectionEntity>();

  const prevIndex = usePrevious(selectedSection);

  const [searchQuery, setSearchQuery] = useSafeState('');
  const [filter, updateFilter] = useSafeState<string[]>([]);

  useEffect(() => {
    if (prevIndex !== selectedSection) {
      const sectionData = getValues(`sectionData.${selectedSection}`);
      methods.reset(sectionData);
      updateFilter([]);
      setSearchQuery('');
    }
  }, [selectedSection]);

  const handleSubmit = useAuditSubmitHandler(
    methods,
    (pathName, inputResponse) => {
      clearErrors('sectionData');
      const question = getValues(`sectionData.${selectedSection}.${pathName}`);
      const completed = isCompleted(inputResponse, question);
      setValue(`sectionData.${selectedSection}.${pathName}`, {
        ...question,
        ...inputResponse,
        isCompleted: completed,
        // @ts-ignore
        isNotFilled: !completed,
      });
    }
  );

  useEffect(() => {
    const subs = AuditTake.onSave((data) =>
      handleSubmit(data?.pathName, data?.questionId)
    );

    return () => subs();
  }, [handleSubmit]);

  useEffect(() => {
    const subs = AuditTake.onUploadProgress((data) => {
      if (data.status) {
        // @ts-ignore
        methods.setValue(`progress.${data.eid}`, data.status);
      } else {
        setTimeout(methods.setValue, 100, `progress.${data.eid}`, data.status);
      }
    });

    return () => subs();
  }, []);

  const onBackNavigation = () => setValue('selectedSection', undefined);

  return (
    <FormProvider {...methods}>
      <Flex flex={1} flexDir='column' gap={4} w='full' overflow='hidden'>
        <QuestionSectionHeader
          sectionIndex={selectedSection}
          title={getValues(`sectionData.${selectedSection}.sectionName`)}
          onBackClick={onBackNavigation}
          questionsCount={getValues(
            `sectionData.${selectedSection}.questionCount`
          )}
        />

        <Flex
          flex={1}
          bg='white'
          gap={4}
          borderRadius='8px'
          justify='center'
          p={4}
          overflow='auto'
        >
          <Flex w='60%' flexDir='column' gap={4} paddingY={2}>
            <Flex gap={4}>
              <Flex flex={1}>
                <SearchInput
                  size='lg'
                  hideShortcuts
                  placeholder='Seach questions, keywords'
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
              </Flex>
              <QuestionFilter
                filter={filter}
                onChange={(newValue) => updateFilter(newValue)}
              />
            </Flex>

            <QuestionsList
              filter={filter}
              sectionIndex={selectedSection}
              searchQuery={searchQuery}
            />

            <Flex minH={2} />
          </Flex>
        </Flex>
        <QuestionSectionFooter sectionCount={getValues('maxSectionCount')}>
          <PrevSectionButton
            selectedSection={selectedSection}
            getValues={getValues}
            setValue={setValue}
          />
          <NextSectionButton
            selectedSection={selectedSection}
            getValues={getValues}
            setValue={setValue}
          />
        </QuestionSectionFooter>
      </Flex>
    </FormProvider>
  );
};

export default QuestionsSection;
