import { gql } from '@apollo/client';

export const AUDIT_SESSION_DETAILS = gql`
  query AuditSessionById($eid: String!) {
    AuditSessionById(eid: $eid) {
      assignments {
        questions
        assignmentId
        auditors
        locationId
        completedBy
        startedBy
        totalScore
        isCompleted
        comments
        response
        completedAt
        totalScore
        maxScore
        completedPercent
        reportsUrl
        signature {
          font
          text
        }
      }
      auditId
      auditType
      auditors {
        eid
        name
        role
      }
      createdAt
      description
      dueDate
      eid
      entityId
      isLastSession
      name
      questions
      repeatCycle
      startDate
      title
      updatedAt
    }
  }
`;

export const LOCATION_DETAILS = gql`
  query UserById($eid: String!) {
    userById(eid: $eid) {
      timezone
      address {
        address
        city
        zipCode
        state
      }
      entityId
      entity {
        locations {
          eid
          name
        }
        name
        profilePic
      }
    }
  }
`;

export const GET_PDF_URL = gql`
  query AuditSessionById($eid: String!) {
    AuditSessionById(eid: $eid) {
      assignments {
        locationId
        reportsUrl
      }
    }
  }
`;

export const GENERATE_PDF_URL = gql`
  mutation AuditSessionReportsPdf($sessionId: String!, $locationId: String!) {
    AuditSessionReportsPdf(sessionId: $sessionId, locationId: $locationId)
  }
`;
