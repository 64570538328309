import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { AppRoute, useHistory } from '../../../../routes';
import { UserSignature } from '../../../../types';
import { BoxHeader } from '../../../../ui-components';
import SummaryHeader from './SummaryHeader';
import SectionCard from './SectionCard';

import { COMPLETE_AUDIT_SESSION } from '../take-audit.graphql';
import {
  CompleteAuditResponse,
  CompleteAuditVariables,
  IFormInput,
  SectionEntity,
} from '../types';
import { AuditSignature } from './audit-signature';

interface AuditInput {
  locationId: string;
  sessionId: string;
  comments: string;
  signature: UserSignature;
}

interface IProps {}

const AuditSummary: FC<IProps> = () => {
  const history = useHistory();
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
  });
  const { locationId, sessionId } = useParams<{
    locationId: string;
    sessionId: string;
  }>();
  const { control, getValues, setValue, handleSubmit, register } =
    useFormContext<IFormInput>();

  const [completeAuditSession] = useMutation<
    CompleteAuditResponse,
    CompleteAuditVariables
  >(COMPLETE_AUDIT_SESSION, {
    onCompleted: (data) => {
      if (data?.CompleteAuditSession?.succeed) {
        toast({
          status: 'success',
          title: 'Audit finished successfully',
        });
        history.replace({
          pathname: AppRoute.AUDIT_REPORT,
          params: {
            locationId,
            sessionId,
          },
        });
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Audit could not be completed',
      });
    },
  });

  const sectionData = useWatch<IFormInput, 'sectionData'>({
    control: control,
    name: 'sectionData',
  });

  const selectedSection = useWatch<IFormInput, 'selectedSection'>({
    control: control,
    name: 'selectedSection',
  });

  const onSectionClickHandler = (_: SectionEntity, index: number) => {
    setValue('selectedSection', index);
  };

  const finishAuditHandler = async (input: AuditInput) => {
    await completeAuditSession({
      variables: {
        input: {
          locationId,
          sessionId,
          comments: input.comments,
          signature: input.signature,
        },
      },
    });
  };

  const completedSections = useMemo(() => {
    return sectionData.reduce((acc, section) => {
      const requiredQues = section.data.filter((ques) => ques.isRequired);

      if (requiredQues.length) {
        if (requiredQues.every((ques) => ques.isCompleted)) {
          return acc + 1;
        }
      } else {
        if (section.data.some((ques) => ques.isCompleted)) {
          return acc + 1;
        }
      }

      return acc;
    }, 0);
  }, [sectionData]);

  const flaggedResponsesCount = useMemo(() => {
    return sectionData.reduce((acc, section) => {
      return acc + section.data.filter((ques) => ques.isFlagged).length;
    }, 0);
  }, [sectionData]);

  if (typeof selectedSection === 'number') {
    return null;
  }

  return (
    <Flex flex={1} flexDir='column' gap={5}>
      <SummaryHeader
        title={getValues('sessionData.title')}
        onFinishClick={handleSubmit(finishAuditHandler)}
      />

      <Flex
        flex={1}
        w='full'
        bg='white'
        flexDir='column'
        gap={4}
        borderRadius='8px'
        align='center'
        py={6}
      >
        <Flex w='70%' minW='600px' flexDir='column' gap={4}>
          <Flex justify='space-between' align='center'>
            <BoxHeader
              boxWidth={3}
              fontSize='18px'
              title='Audit Summary'
              color='#CABDFF'
            />

            <Flex
              fontSize='12px'
              fontWeight={500}
              color='#6F767E'
              align='center'
              gap='8px'
            >
              <Box color='#1A1D1F' fontWeight={600}>
                {completedSections}/{sectionData?.length}
              </Box>
              <Box>{sectionData?.length === 1 ? 'Section' : 'Sections'}</Box>

              {/*<Box boxSize={1} borderRadius='full' bg='#6F767E' />*/}
              {/*<Box color='#1A1D1F' fontWeight={600}>*/}
              {/*  0/45*/}
              {/*</Box>*/}
              {/*<Box>Points</Box>*/}

              {/*<Box boxSize={1} borderRadius='full' bg='#6F767E' />*/}
              {/*<Box color='#1A1D1F' fontWeight={600}>*/}
              {/*  NA*/}
              {/*</Box>*/}
              {/*<Box>Score</Box>*/}

              <Box boxSize={1} borderRadius='full' bg='#6F767E' />
              <Box color='#1A1D1F' fontWeight={600}>
                {flaggedResponsesCount ?? 'NA'}
              </Box>
              <Box>{flaggedResponsesCount === 1 ? 'Flag' : 'Flags'}</Box>
            </Flex>
          </Flex>

          <Flex flexDir='column' w='full' gap='3rem'>
            <Flex flexDir='column' gap={4}>
              {getValues('sectionData')?.map((section, index) => (
                <SectionCard
                  key={section?.eid}
                  section={section}
                  onClick={() => onSectionClickHandler(section, index)}
                />
              ))}
            </Flex>

            <Flex flexDir='column' gap={4}>
              <BoxHeader
                boxWidth={3}
                fontSize='18px'
                title='Signature'
                color='#FFBC99'
              />

              <Flex flexDir='column' gap={1}>
                <Box fontWeight={600} color='#6F767E'>
                  Auditor
                </Box>

                <Controller
                  control={control}
                  name='signature'
                  rules={{
                    required: 'Auditor signature is required',
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl isInvalid={fieldState.invalid}>
                        <AuditSignature
                          isInvalid={fieldState.invalid}
                          value={field.value}
                          onChange={field.onChange}
                        />
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />
              </Flex>

              <Textarea
                placeholder='Closing comments (optional)'
                bg='rgba(244, 244, 244, 1)'
                borderRadius='8px'
                border='none'
                resize='none'
                {...register('comments')}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AuditSummary;
