import React, { FC } from 'react';
import { Box, HStack, ListItem } from '@chakra-ui/react';
import logo from '../../assets/images/ic_logo.png';

import './MemberItem.scss';
import RoleAndAuthRole from '../../ui-components/role-authrole/RoleAndAuthRole';
import Image from '../../ui-components/Image/Image';

interface IProps {
  name?: string;
  role?: string;
  authRole?: string;
  thumbnail?: string;
  eid?: string;
}

const MemberItem: FC<IProps> = ({ name, role, authRole, thumbnail }) => {
  return (
    <ListItem>
      <HStack spacing='10px'>
        <Box>
          <div className='member-list-item'>
            <Image
              width={50}
              height={50}
              className='memberImage'
              src={thumbnail || logo}
            />
          </div>
        </Box>
        <Box flex='1' overflow='hidden'>
          <div
            className='title'
            style={{
              width: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name ? name : 'N/A'}
          </div>
          <RoleAndAuthRole
            authRole={authRole}
            role={role}
            defaultValue='N/A'
            roleProps={{
              maxWidth: '46%',
            }}
          />
        </Box>
      </HStack>
    </ListItem>
  );
};

export default MemberItem;
