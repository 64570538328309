import React, { cloneElement, FC, MouseEvent, useMemo } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { useSafeState } from 'hooks';
import { isThenable } from 'utils';
import { IActionMenuData } from './action-menu.types';

interface IProps extends Pick<FlexProps, 'cursor' | '_disabled'> {
  onClick?: (event: MouseEvent<HTMLDivElement>) => Promise<void>;
  menu: IActionMenuData;
}

const MenuItemWrapper: FC<IProps> = ({ menu, onClick, children, ...rest }) => {
  const [isLoading, setIsLoading] = useSafeState(false);

  const styles = useMemo((): FlexProps => {
    if (menu.type === 'divider') {
      return {
        mx: '10px',
        py: 1,
      };
    }

    return {
      px: '10px',
      cursor: isLoading ? 'not-allowed' : 'pointer',
      borderRadius: '12px',
      gap: 3,
      py: 3,
      _hover: {
        bg: '#F4F4F4',
      },
    };
  }, [menu.type, isLoading]);

  const handlePromise = (returnValue: PromiseLike<void>) => {
    if (!isThenable(returnValue)) {
      return;
    }
    setIsLoading(true);
    returnValue?.then(
      () => {
        setIsLoading(false);
      },
      (e: Error) => {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    );
  };

  const clickHandler = (e: MouseEvent<HTMLDivElement>) => {
    let returnValue = onClick?.(e);
    if (!returnValue) {
      return;
    }
    handlePromise(returnValue);
  };

  return (
    <Flex
      key={menu.value || menu.name}
      align='center'
      onClick={isLoading ? undefined : clickHandler}
      {...styles}
      {...rest}
    >
      {children &&
        cloneElement(children as never, {
          isLoading: isLoading,
        })}
    </Flex>
  );
};

export default MenuItemWrapper;
