import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFrequencyInfo } from './useFrequencyInfo';
import { InfoText } from '../../../../tasks/create-task/common';

interface IProps {}

const FrequencyInfoText: FC<IProps> = () => {
  const { t } = useTranslation('audit');

  const transObject = useFrequencyInfo();

  if (!transObject) {
    return null;
  }

  return (
    <InfoText>
      <Trans t={t} {...transObject} />
    </InfoText>
  );
};

export default FrequencyInfoText;
