import React, { useMemo } from 'react';
import { StepProps } from 'antd';
import { FieldArrayWithId, UseFieldArrayInsert } from 'react-hook-form';

import { IContentItem, IFormInput } from '../editor.types';
import { EditorEvent, useEditorContext } from '../context';
import { StepIconRender } from '../section/StepIconRender';

import AddSectionTitle from './AddSectionTitle';
import StepTitle from './StepTitle';

interface IStepProps extends StepProps {
  render?: (node: React.ReactNode) => React.ReactNode;
}

interface IProps {
  activeStep: number;
  insert: UseFieldArrayInsert<IFormInput, 'contents'>;

  maxIndex: number;
  onTitleUpdate: (index: number, newTitle: string) => void;
  onMoveClick: (currentIndex: number, nextIndex: number) => void;
  onDeleteClick: (index: number) => void;
}

export const useStepItems = (
  fields: FieldArrayWithId<IFormInput, 'contents', 'id'>[],
  props: IProps
): IStepProps[] => {
  const {
    activeStep,
    insert,
    maxIndex,
    onTitleUpdate,
    onDeleteClick,
    onMoveClick,
  } = props || {};

  const { emit } = useEditorContext();

  return useMemo(() => {
    return fields
      .slice()
      .map<IStepProps>((field, index, arr) => {
        return {
          title: (
            <StepTitle
              index={index}
              maxIndex={maxIndex}
              category={field.category}
              onTitleUpdate={onTitleUpdate}
              onDeleteClick={onDeleteClick}
              onMoveClick={onMoveClick}
            />
          ),
          status:
            activeStep === index ? 'process' : field.updateStatus || 'wait',
          icon: <StepIconRender index={index} />,
        };
      })
      .concat([
        {
          render: () => {
            return (
              <AddSectionTitle
                emptySection={!fields.length}
                onSubmit={async (value) => {
                  const res = await emit<string, [IContentItem, number]>(
                    EditorEvent.ADD_PHASE,
                    value
                  );
                  if (res?.[0]) {
                    insert(res[1], res[0]);
                  }
                }}
              />
            );
          },
        },
      ]);
  }, [fields, activeStep]);
};
