import { useLazyQuery } from '@apollo/client';
import {
  SessionDetailResponse,
  SessionDetailVariables,
} from 'sub-components/audits/take-audit/types';
import { AUDIT_OVERVIEW } from '../api';

export const useServiceLayer = () => {
  const [getOverviewData, overviewDataResponse] = useLazyQuery<
    SessionDetailResponse,
    SessionDetailVariables
  >(AUDIT_OVERVIEW, {
    fetchPolicy: 'network-only',
  });
  return {
    overviewData: {
      getOverviewData,
      overviewDataResponse,
    },
  };
};
