import React, { FC } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFormInput } from '../types';
import { useMediaIsUploading } from './useMediaIsUploading';
import { Button } from '../../../../atoms';

interface IProps {
  selectedSection: number;
  getValues: UseFormGetValues<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
}

const PrevSectionButton: FC<IProps> = ({
  selectedSection,
  getValues,
  setValue,
}) => {
  const { t } = useTranslation('audit');
  const _prevSectionIndex = selectedSection - 1;

  const mediaUploading = useMediaIsUploading();

  if (_prevSectionIndex >= 0) {
    return (
      <Tooltip
        placement='top-start'
        hasArrow
        label={t('uploadInProgressWarning')}
        offset={[16, 16]}
        isDisabled={!mediaUploading}
        shouldWrapChildren
      >
        <Button
          colorScheme='blue'
          variant='link'
          leftIcon={<ArrowBackIcon />}
          _hover={{
            textDecoration: 'none',
          }}
          isDisabled={mediaUploading}
          onClick={() => setValue('selectedSection', _prevSectionIndex)}
        >
          {getValues(`sectionData.${_prevSectionIndex}.sectionName`)}
        </Button>
      </Tooltip>
    );
  }

  return <div />;
};

export default PrevSectionButton;
