// TaskPopover.tsx
import React, { FC } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Flex,
  Box,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { QuestionResponseSectionEntity } from '../../types/audit-question.types';
import { faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { AuditTaskEntity } from '../../../../../../../sub-components/audits/take-audit/types';

interface TaskPopoverProps {
  _question: QuestionResponseSectionEntity;
  createTaskHandler: (_question: any) => void;
  redirectTask: (taskId: string, sessionId: string) => void;
}

const TaskPopover: FC<TaskPopoverProps> = ({
  _question,
  createTaskHandler,
  redirectTask,
}) => {
  return (
    <Flex>
      {_question?.responsesByLocation?.some((loc) => loc?.isFlagged) &&
        (_question?.taskData?.length === 0 ? (
          <Flex align='center' gap='5px'>
            <FontAwesomeIcon icon={faSquareCheck as IconProp} color='#2A85FF' />
            <Box
              fontWeight={500}
              color='#2A85FF'
              cursor='pointer'
              onClick={() => createTaskHandler(_question)}
            >
              Create task
            </Box>
          </Flex>
        ) : (
          <Popover placement='bottom-start' isLazy>
            <PopoverTrigger>
              <Flex align='center' gap='5px' cursor='pointer'>
                <FontAwesomeIcon
                  icon={faCircleCheck as IconProp}
                  color='rgba(111, 118, 126, 1)'
                />
                <Box
                  as='span'
                  color='rgba(111, 118, 126, 1)'
                  fontWeight={500}
                  fontSize='12px'
                >{`${_question?.taskData?.length} ${
                  _question?.taskData?.length === 1 ? 'task' : 'tasks'
                }`}</Box>
                <ChevronDownIcon
                  color='rgba(111, 118, 126, 1)'
                  fontSize='20px'
                />
              </Flex>
            </PopoverTrigger>
            <PopoverContent w='200px' borderRadius='16px'>
              <PopoverBody p={0}>
                <Flex flexDir='column' gap={2}>
                  <Box as='span' fontSize='15px' fontWeight={600} pt={2} pl={4}>
                    Tasks
                  </Box>
                  <Divider />
                  <VStack
                    align='flex-start'
                    spacing={2}
                    divider={<Divider />}
                    overflowY='scroll'
                    maxH='30vh'
                  >
                    {_question?.taskData?.map((task: AuditTaskEntity) => (
                      <Flex
                        key={task.eid}
                        justify='space-between'
                        align='center'
                        w='full'
                        cursor='pointer'
                        onClick={() =>
                          redirectTask(task?.eid, task?.lastActiveSession?.eid)
                        }
                      >
                        <Box as='span' px={4} py={2} maxW='80%'>
                          {task?.title}
                        </Box>
                        <Flex minW='20px'>
                          <ChevronRightIcon fontSize='20px' mr={2} />
                        </Flex>
                      </Flex>
                    ))}
                  </VStack>
                  <Flex
                    align='center'
                    gap='5px'
                    mb={2}
                    cursor='pointer'
                    onClick={() => createTaskHandler(_question)}
                  >
                    <AddIcon color='rgba(42, 133, 255, 1)' ml={4} />
                    <Box
                      as='span'
                      color='rgba(42, 133, 255, 1)'
                      fontWeight={500}
                      fontSize='12px'
                    >
                      Create new task
                    </Box>
                  </Flex>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ))}
    </Flex>
  );
};

TaskPopover.displayName =
  'pages/Audits/Detail/components/QuestionSection/components/QuestionResponseSection/TaskPopover';

export default TaskPopover;
