import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { Authorize } from 'authorization';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts-new';
import { UserEntityData } from 'shared/graphql/shared-types';
import { usersEntityObj } from 'sub-components/Header';
import GraphLocationSelection, {
  LocationCellRender,
} from 'sub-components/tasks/performance/overview/location/GraphLocationSelection';
import { toArray } from 'utils';
import { HeaderColors } from '../../../../../../shared/headerColors';
import { getIndexColor } from '../../../../../../utils/randomColor';
import GenericColorHeader from '../../../../../GenericColorHeader';
import {
  AxisPercentageDomain,
  toPercent,
  TrendAnalytic,
  getGraphLabel,
  GraphMode,
} from '../../../../../tasks/performance/common';
import {
  GraphFooter,
  GraphLoader,
  IGraphRange,
  useGraphRangeVariable,
} from '../../../../../tasks/performance/common/graph';
import {
  AnalyticsData,
  GetAuditLocationsAnalyticsVariables,
  MonthAnalytics,
} from './GraphSection';

const LOCATION_OWNER_LINE_ANALYTICS = gql`
  query LocationOwnerLineAnalytics(
    $locationIds: [String!]!
    $startDate: Date
    $endDate: Date
  ) {
    AuditLocationsAnalytics(
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        {payload.map((item) => (
          <div
            key={item.dataKey}
            style={{ color: item.color, marginBottom: '4px' }}
          >
            <strong>{item.name}:</strong> {item.value}%
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const LocationOwnerLineGraph: FC = () => {
  const { auditId } = useParams<{ auditId: string; sessionId: string }>();
  const { loggedInUserLocations, loggedInUserType, loggedInUserEid } =
    useUserDataSelector((state) => ({
      loggedInUserLocations: state?.locations,
      loggedInUserType: state?.type,
      loggedInUserEid: state?.eid,
    }));
  const entityData = useReactiveVar(usersEntityObj);
  const [selectedLocation, setSelectedLocation] = useState<string[]>();
  const [variables, setVariable] = useGraphRangeVariable();

  const loLocationIds = useMemo(() => {
    return loggedInUserType === 'branch'
      ? [loggedInUserEid]
      : loggedInUserLocations?.map((loc) => loc?.eid) || [];
  }, [loggedInUserLocations]);

  const { data, loading } = useQuery<
    AnalyticsData,
    GetAuditLocationsAnalyticsVariables
  >(LOCATION_OWNER_LINE_ANALYTICS, {
    fetchPolicy: 'network-only',
    variables: {
      ...variables,
      locationIds: loLocationIds,
    },
  });

  const getGraphTooltipLabel = (value: MonthAnalytics, type: GraphMode) => {
    if (type === 'weekly') {
      return moment(value.end).format('wo').concat(' Weeks');
    } else {
      return moment(value.start).format('MMMM');
    }
  };

  const [graphData, locationNames] = useMemo(() => {
    const resData = data?.AuditLocationsAnalytics || {};
    let filteredLocations: UserEntityData[] = [];
    if (loggedInUserType === 'user') {
      filteredLocations =
        entityData?.filter((loc) =>
          loggedInUserLocations?.some(
            (assignment) => assignment?.eid === loc?.eid
          )
        ) || [];
    } else {
      let foundLoc = entityData?.filter(
        (entity) => entity?.eid === loggedInUserEid
      );
      filteredLocations = foundLoc;
    }
    const locRecord = toArray(filteredLocations).reduce<Record<string, string>>(
      (acc, value) => {
        acc[value.eid] = value.name;
        return acc;
      },
      {}
    );
    const _locationNames = Object.values(locRecord);
    const newData = Object.entries(resData).reduce((acc, [locId, records]) => {
      Object.entries(records).forEach(([weekMonth, value]) => {
        const prev = acc[weekMonth];

        let locationName = locRecord[locId];
        if (locationName) {
          if (prev) {
            acc[weekMonth] = {
              ...prev,
              [locationName]: (
                ((value.totalScoreSum || 0) / (value.maxScoreSum || 0)) * 100 ||
                0
              )?.toFixed(2),
            };
          } else {
            acc[weekMonth] = {
              tooltipLabel: getGraphTooltipLabel(value, variables.type),
              month: getGraphLabel(
                value as unknown as TrendAnalytic,
                variables.type
              ),
              [locationName]: (
                ((value.totalScoreSum || 0) / (value.maxScoreSum || 0)) * 100 ||
                0
              )?.toFixed(2),
            };
          }
        }
      });

      return acc;
    }, {} as Record<string, object>);

    return [Object.values(newData), _locationNames];
  }, [data, entityData]);

  const onLocationClick = (value: string) => {
    setSelectedLocation((prevState = []) => {
      if (prevState.includes(value)) {
        return [...prevState.filter((it) => it !== value)];
      }
      return [...prevState, value];
    });
  };

  const filteredLocations = useMemo(() => {
    if (selectedLocation?.length) {
      return selectedLocation;
    }
    return locationNames;
  }, [locationNames, selectedLocation]);

  const onGraphTypeChange = (newVariables: IGraphRange) => {
    setVariable((prevState) => {
      return {
        ...prevState,
        ...newVariables,
      };
    });
  };

  return (
    <Flex
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
      w='full'
    >
      <Flex flexDir='column' gap='12px'>
        <Flex justify='space-between' align='center'>
          <GenericColorHeader
            title='Location wise performance'
            color={HeaderColors.Purple}
          />
          <Authorize permittedFor={'user'}>
            <GraphLocationSelection
              locations={locationNames}
              selected={selectedLocation}
              customPlaceholder='Compare Location'
              onChange={onLocationClick}
            />
          </Authorize>
        </Flex>
      </Flex>
      <Flex>
        <LocationCellRender
          selected={selectedLocation}
          onChange={onLocationClick}
        />
      </Flex>

      <GraphLoader isLoading={loading} minHeight={260}>
        <ResponsiveContainer width='100%' height={260}>
          <LineChart data={graphData}>
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis axisLine={false} dataKey='month' />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={toPercent}
              domain={AxisPercentageDomain}
            />

            <Tooltip
              content={<CustomTooltip />}
              // @ts-ignore
              itemSorter={(item) => 0 - item?.value}
            />

            {filteredLocations.map((locName, index) => {
              return (
                <Line
                  key={locName}
                  type='linear'
                  dataKey={locName}
                  stroke={getIndexColor(index)}
                  dot={false}
                  unit='%'
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </GraphLoader>

      <GraphFooter data={variables} onChange={onGraphTypeChange} />
    </Flex>
  );
};

export default LocationOwnerLineGraph;
