import React, { ChangeEvent, FC, useState } from 'react';
import { Divider, Flex } from '@chakra-ui/react';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import _uniqueId from 'lodash/uniqueId';
import { v4 as uuidv4 } from 'uuid';
import { useUploadContext } from './EvidenceProvider';

interface IProps {}

const AddEvidenceButton: FC<IProps> = () => {
  const [inputId] = useState(_uniqueId('addFile-'));
  const addEvidence = useUploadContext((state) => state.addEvidence);
  const notHaveImage = useUploadContext(
    (state) => !(state.value?.length + state.uploadQue?.length)
  );

  const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const fileList = event.target.files;

    if (fileList && fileList.length) {
      addEvidence({ eid: uuidv4(), file: fileList.item(0)! }).catch(() => null);
    }
  };

  if (!notHaveImage) {
    return null;
  }

  return (
    <>
      <Flex
        as='label'
        htmlFor={inputId}
        gap={1}
        align='center'
        cursor='pointer'
        fontWeight={500}
      >
        <FontAwesomeIcon icon={faPaperclip as IconProp} />
        Add evidence
      </Flex>

      <input
        type='file'
        onChange={onImageChange}
        id={inputId}
        style={{ display: 'none' }}
        accept='image/*'
      />

      <Divider orientation='vertical' height='20px' borderColor='#EFEFEF' />
    </>
  );
};

export default AddEvidenceButton;
