import React, { forwardRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DateInputButton from './DateInputButton';
import { DateInputWrapper } from './date-input.styles';
import CustomHeader from './CustomHeader';

export interface DateInputProps
  extends Omit<ReactDatePickerProps, 'value' | 'selected'> {
  value?: Date | null | undefined;
}

const DateInput = forwardRef<never, DateInputProps>(
  ({ disabled, value, onChange, children, ...props }) => {
    const { t } = useTranslation(['location']);

    return (
      <DateInputWrapper>
        <DatePicker
          disabled={disabled}
          selected={value && moment(value).toDate()}
          onChange={onChange}
          customInput={<DateInputButton />}
          renderCustomHeader={CustomHeader}
          formatWeekDay={(day) => day.substring(0, 3)}
          calendarStartDay={1}
          placeholderText={t('location:placeholder.selectDate')}
          dateFormat={'d MMMM yyyy'}
          minDate={props?.minDate || new Date()}
          {...props}
        />
      </DateInputWrapper>
    );
  }
);

DateInput.displayName = 'DateInput';

export default DateInput;
