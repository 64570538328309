import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { HeaderColors } from '../../../../shared/headerColors';
import { BoxHeader, useConfirm, UseConfirm } from '../../../../ui-components';
import ModalContent from './ModalContent';

interface IProps {}

type ITakeAuditInfo = (props: IProps) => void;

export const useTakeAuditInfo = (): ITakeAuditInfo => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({}) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirm({
        title: (
          <Flex gap='12px' justify='space-between'>
            <BoxHeader
              // @ts-ignore
              title='How to prepare for an audit'
              color={HeaderColors.Purple}
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: <ModalContent />,
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '10px',
          minW: '1050px',
        },
        okText: null,
        cancelText: null,
      });
    },
    [confirm]
  );
};
