import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRepeat } from '@fortawesome/pro-light-svg-icons';
import { faChartLine, faFlag } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuditSessionAssignments, ProcessData } from '../../../types';
import Outliner from './Outliner';
import moment from 'moment';
import SectionFilter from './SectionFilter';
import { SelectOption } from 'atoms';
import { completeFilterOptions } from './filter-options';
import AuditAssignee from './AuditAssignee';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import AuditCard from './AuditCard';

interface IProps {
  data: ProcessData[];
  refetchHandler?: () => void;
}

const Completed: FC<IProps> = ({ data, refetchHandler }) => {
  const entityUsers = useReactiveVar(usersEntityObj);

  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | undefined
  >(undefined);

  const filteredCompletedData = useMemo(() => {
    const completedData =
      data?.filter((completed) => completed?.auditStatus === 'completed') || [];
    if (!selectedFilter?.value) {
      return completedData;
    }
    let sortedData = [...completedData];
    switch (selectedFilter.value) {
      case 'dueDateFirst':
        sortedData.sort((a, b) => {
          const today = moment();

          const dueDateA = a.completedAt ? moment.utc(a.completedAt) : null;
          const dueDateB = b.completedAt ? moment.utc(b.completedAt) : null;

          const diffA = dueDateA ? Math.abs(dueDateA.diff(today)) : Infinity;
          const diffB = dueDateB ? Math.abs(dueDateB.diff(today)) : Infinity;

          return diffB - diffA;
        });
        break;
      case 'nameDesc':
        sortedData.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'nameAsc':
        sortedData.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'frequency':
        // Implement sorting by frequency if needed
        break;
      case 'scoreDesc':
        sortedData = sortedData
          ?.filter((data) => data?.isScoredAudit)
          ?.sort((a, b) => (b.scorePercent ?? 0) - (a.scorePercent ?? 0));
        break;
      case 'scoreAsc':
        sortedData = sortedData
          ?.filter((data) => data?.isScoredAudit)
          ?.sort((a, b) => (a.scorePercent ?? 0) - (b.scorePercent ?? 0));
        break;
      default:
        break;
    }

    return sortedData;
  }, [data, selectedFilter]);

  const flagCalc = (data: ProcessData) => {
    let flaggedArr: boolean[] = [];
    data?.questions
      ?.filter((question) => question?.qType !== 'section')
      ?.map((question) => {
        let assignments: AuditSessionAssignments[] = data.assignments;
        assignments?.map((assignment) => {
          const responseForQuestion = assignment?.response?.find(
            (response: any) => response?.qid === question?.eid
          );
          let isResponseFlagged = false;
          if (responseForQuestion) {
            isResponseFlagged = responseForQuestion.isFlagged;
            if (responseForQuestion.responseId.length > 0) {
              const selectedOptions = question.options.filter((option) =>
                responseForQuestion.responseId.includes(option.eid)
              );
              if (selectedOptions.some((option) => option.isFlagged)) {
                isResponseFlagged = true;
              }
            }
          }
          flaggedArr.push(isResponseFlagged);
        });
      });
    let flaggedCount = flaggedArr?.filter(Boolean)?.length;
    let bg = 'rgba(131, 191, 110, 1)'; // Green color (default value);
    if (flaggedArr?.filter(Boolean)?.length > 0) {
      bg = 'rgba(255, 106, 85, 1)';
    } else {
      bg = 'rgba(131, 191, 110, 1)';
    }
    return (
      <Flex
        bg={bg}
        w='fit-content'
        borderRadius='6px'
        color='white'
        align='center'
        gap='5px'
        p='4px 8px'
      >
        <FontAwesomeIcon icon={faFlag as IconProp} />
        <Box as='span'>
          {flaggedCount > 0
            ? `${flaggedCount} ${flaggedCount === 1 ? 'flag' : 'flags'}`
            : 'No flags raised'}
        </Box>
      </Flex>
    );
  };

  const scoreCalc = (data: ProcessData) => {
    let initScore = data?.scorePercent || 0;
    let bg = 'rgba(131, 191, 110, 1)'; // Green color (default value);
    if (initScore < 60) {
      bg = 'rgba(255, 106, 85, 1)';
    } else if (initScore < 80 && initScore >= 60) {
      bg = 'rgba(255, 216, 141, 1)';
    }
    let score = data?.scorePercent?.toFixed(2);
    return (
      <Flex
        bg={bg}
        w='fit-content'
        borderRadius='6px'
        color='white'
        align='center'
        gap='5px'
        p='4px 8px'
      >
        <FontAwesomeIcon icon={faChartLine as IconProp} />
        <Box as='span'>Score {score}%</Box>
      </Flex>
    );
  };

  const handleFilterChange = (filter: SelectOption) => {
    setSelectedFilter((prevVal) =>
      prevVal?.value === filter.value ? undefined : filter
    );
  };

  const titleRender = (completedData: ProcessData) => {
    return (
      <Tooltip label={completedData?.title} hasArrow placement='top'>
        <Text fontWeight={600} isTruncated maxW='190px'>
          {completedData?.title}
        </Text>
      </Tooltip>
    );
  };

  const scheduleRender = (completedData: ProcessData) => {
    return (
      <Flex align='center' gap='3px' color='#4D4D4D' fontSize='10px'>
        <FontAwesomeIcon icon={faCheck as IconProp} />
        <Text>
          {moment(completedData?.completedAt)?.isValid()
            ? moment.utc(completedData?.completedAt)?.format('DD MMM YYYY')
            : '-'}
        </Text>
        <Box>&bull;</Box>
        <FontAwesomeIcon icon={faRepeat as IconProp} />
        <Text textTransform='capitalize' isTruncated maxW='110px'>
          {completedData?.repeatStatus || 'Does not repeat'}
        </Text>
      </Flex>
    );
  };

  const footerRender = (completedData: ProcessData) => {
    return (
      <Flex align='center' justify='space-between'>
        {completedData?.isScoredAudit
          ? scoreCalc(completedData)
          : flagCalc(completedData)}
        <AuditAssignee
          assignees={entityUsers?.filter((user) =>
            completedData?.auditors?.some(
              (auditor) => auditor?.eid === user?.eid
            )
          )}
        />
      </Flex>
    );
  };

  return (
    <Flex flexDir='column' gap={4} minW='244px' maxW='244px'>
      <Outliner bg='rgba(181, 228, 202, 0.5)'>
        <Flex>
          <Text fontWeight={600} fontSize='14px'>
            Completed
          </Text>
          &nbsp;
          <Text fontWeight={600} fontSize='14px'>
            ({filteredCompletedData?.length})
          </Text>
        </Flex>
        {filteredCompletedData?.length > 0 && (
          <SectionFilter
            options={completeFilterOptions}
            value={selectedFilter}
            handleOptionClick={handleFilterChange}
          />
        )}
      </Outliner>
      {filteredCompletedData?.map((completedData, index) => (
        <AuditCard
          key={completedData?.eid}
          auditData={completedData}
          auditTitle={titleRender(completedData)}
          scheduleDetails={scheduleRender(completedData)}
          footerDetails={footerRender(completedData)}
          refetchHandler={refetchHandler}
        />
      ))}
    </Flex>
  );
};

export default Completed;
