import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ResponsesByLocationEntity } from '../../../../pages/Audits/Detail/components/QuestionSection/types/audit-question.types';
import QuestionTypes from '../../../../pages/Audits/Detail/components/QuestionTypes/QuestionTypes';

interface IProps {
  questionTitle?: string;
  responses?: ResponsesByLocationEntity[];
}

const FlagDetails: FC<IProps> = ({ questionTitle, responses }) => {
  return (
    <Flex flexDir='column' gap={2}>
      <Box fontSize='13px' fontWeight='500' color='#33383F'>
        {questionTitle}
      </Box>
      {responses?.map((record) => {
        return (
          <QuestionTypes
            key={record.locationId}
            rawQuestion={record?.question!}
            rawResponse={record?.rawResponse}
            responseStatus={record?.responseStatus}
          />
        );
      })}
    </Flex>
  );
};

export default FlagDetails;
