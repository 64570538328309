import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  useModalContext,
} from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSignature } from '../../../../../types';
import { Button, FormInput } from '../../../../../atoms';
import { signatureConfigs } from '../../../../../configs';
import { ActionButton } from '../../../../../ui-components';
import { BaseSignature } from '../../../../forms/from-preview/component/Acknowledge/Signature';

import * as Yup from 'yup';

const scheme = Yup.object().shape({
  font: Yup.string().required('Select a signature format'),
  text: Yup.string().trim().required('Please enter your name'),
});

interface IProps {
  onCreate: (signature: UserSignature) => void;
}

const AuditSignatureContent: FC<IProps> = ({ onCreate }) => {
  const { onClose } = useModalContext();

  const { control, watch, reset, handleSubmit } = useForm<UserSignature>({
    resolver: yupResolver(scheme),
  });

  const trimValue = watch('text');

  return (
    <Flex flexDir='column' gap={2}>
      <Box fontWeight='600' color='#1A1D1F'>
        Enter your full name to sign the Audit
      </Box>

      <Controller
        control={control}
        name='text'
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid}>
              <FormInput variant='auditOutline' {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Controller
        control={control}
        name='font'
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid}>
              <Grid
                templateColumns='repeat(2, 1fr)'
                gap='8px'
                pt={3}
                alignItems='center'
              >
                {signatureConfigs.map((sign, index) => (
                  <BaseSignature
                    borderRadius='12px'
                    key={index}
                    isSelected={sign.fontFamily === field.value}
                    onClick={() => field.onChange(sign.fontFamily)}
                    signConfig={sign}
                  >
                    {trimValue || 'Delightree'}
                  </BaseSignature>
                ))}
              </Grid>
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Flex gap='10px' justify='end' pt={4}>
        <Button variant='outline' onClick={() => reset()}>
          Clear
        </Button>
        <ActionButton
          colorScheme='blue'
          actionFn={handleSubmit(callAll(onCreate, onClose))}
        >
          Submit
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AuditSignatureContent;
