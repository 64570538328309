import { FC, useEffect, useState } from 'react';
import { Flex, Text, Img, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import { GET_ENTITY_USERS } from 'shared/graphql/queries';

export const LOCATION_DETAILS = gql`
  query UserById($eid: String!) {
    userById(eid: $eid) {
      entityId
    }
  }
`;

interface IProps {
  answer?: any;
  url?: string;
  entityUserFromPublicReport?: any;
}

const Members: FC<IProps> = ({ answer, entityUserFromPublicReport }) => {
  const { t } = useTranslation('invite', { keyPrefix: 'authLevel' });
  const queryParams = new URLSearchParams(location?.search);
  const tokenId = queryParams.get('token');

  const { locationId } = useParams<{
    locationId: string;
  }>();

  let entityUsers: any;

  if (tokenId) {
    entityUsers = entityUserFromPublicReport;
  } else {
    entityUsers = useReactiveVar(usersEntityObj);
  }

  const foundMember = answer?.map((memberId: string) => {
    const temp = entityUsers?.find((item) => {
      if (item?.eid === memberId) {
        return item;
      }
    });
    return temp;
  });

  return (
    <Flex
      direction={'column'}
      alignItems={'flex-end'}
      justifyContent={'center'}
      gap={2}
    >
      {foundMember?.length > 0 &&
        foundMember?.map((item: any) => {
          return (
            <Box key={item?.eid}>
              <Flex
                width={'max-content'}
                padding={'8px 16px'}
                borderRadius={'8px'}
                backgroundColor={'#DCF5FF'}
                gap={2}
                alignItems={'center'}
                alignSelf={'flex-start'}
                fontWeight={500}
              >
                <Img
                  src={getImage(item?.profilePic, item?.name)}
                  alt={'profile'}
                  width={'25px'}
                  height={'25px'}
                />
                <Text color={'#2A85FF'}>{item?.name},</Text>
                <Text textTransform='capitalize'>
                  {t(item?.authRole, {
                    defaultValue: item?.authRole,
                  })}
                </Text>
              </Flex>
            </Box>
          );
        })}
    </Flex>
  );
};

export default Members;
