import React, { FC } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActionButton } from 'ui-components';

import DashboardContainer from 'sub-components/DashboardContainer';
import {
  Address,
  BasicDetails,
  EntityDetails,
  LoginDetails,
  OtherDetails,
} from '../Components';
import AddLocationHeader from './AddLocationHeader';
import { IFormInput } from './add-location.types';
import {
  addLocationFormat,
  addPreLaunchLocationFormat,
} from './formatSubmitData';
import {
  ADD_LOCATION_QUERY,
  ADD_PRE_LAUNCH_LOCATION_QUERY,
  AddLocationVariable,
  AddLocationResponse,
} from './add-location.graphql';
import { getDefaultFormData } from './default-form-data';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';
import { ADD_PRE_LAUNCH_LOCATION, LOCATIONS_PAGE } from 'appRoutes';

interface IProps {}

const AddLocation: FC<IProps> = () => {
  const [preLaunchLocationId, setPreLaunchLocationId] =
    React.useState<string>('');
  const [locationId, setLocationId] = React.useState<string>('');
  const { t } = useTranslation(['common', 'location']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const history = useHistory();
  const location = useLocation();
  console.log('location : ', location);
  const isPreLaunch = location?.pathname === ADD_PRE_LAUNCH_LOCATION;

  const methods = useForm<IFormInput>({
    defaultValues: getDefaultFormData(),
  });

  const onGetUserSuccess = () => {
    history.push(LOCATIONS_PAGE);
  };

  const onGetUserError = () => {
    history.push(LOCATIONS_PAGE);
  };

  const { execute, loading: gettingLoggedInUserDetails } =
    getLoggedInUserDataHandler(onGetUserSuccess, onGetUserError);

  const [addLocation, { loading }] = useMutation<
    AddLocationResponse,
    AddLocationVariable
  >(ADD_LOCATION_QUERY, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('location:location_created'),
      });
      execute?.();
    },
    onError: (error) => {
      if (error?.message?.includes('Username')) {
        methods.setError(
          'username',
          {
            type: 'custom',
            message: error.message,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t('location:location_error'),
        });
      }
    },
  });

  const [addPreLaunchLocation, { loading: addingPreLaunchLocation }] =
    useMutation(ADD_PRE_LAUNCH_LOCATION_QUERY, {
      onCompleted: (response) => {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('location:location_created'),
        });
        execute?.();
      },
      onError: (error) => {
        if (error?.message?.includes('Username')) {
          methods.setError(
            'username',
            {
              type: 'custom',
              message: error.message,
            },
            {
              shouldFocus: true,
            }
          );
        } else {
          toast({
            status: 'error',
            title: t('common:error'),
            description: t('location:location_error'),
          });
        }
      },
    });

  const onSubmit = async (data: IFormInput) => {
    let mutationResponse;
    if (isPreLaunch) {
      const inputData = addPreLaunchLocationFormat(cloneDeep(data));
      mutationResponse = await addPreLaunchLocation({
        variables: {
          input: inputData,
        },
      });
    } else {
      const inputData = addLocationFormat(cloneDeep(data));
      mutationResponse = await addLocation({
        variables: {
          input: inputData,
        },
      });
    }
    deployEvent(AmplitudeEventNames.LOCATION_ADD_BUTTON, {
      add_location_funnel_id: 5,
      location_id: isPreLaunch
        ? mutationResponse?.data?.AddPrelaunchLocation?.eid
        : mutationResponse?.data?.addLocation?.eid,
      location_type: data.locationType,
      location_name: data.locationName ?? 'Miami',
      status: data.locationStatus.value,
      email_address: data.locationEmail ?? 'xyz@gmail.com',
      owner: data.locationOwners ?? ['Joe', 'Ryan'],
      location_user_name: data.username ?? 'abctest',
      job_edit_funnel_id: 5,
      Address: data.address ?? 'Abc, Floor 1',
      State: data.state ?? 'Haryana',
      City: data.city ?? 'Gurugram',
      Zipcode: data.zipCode ?? '123412',
      entity_name: data.entityName ?? 'Foodapanda',
      entity_type: data.entityType ?? 'LLC',
      country_of_formation: data.countryOfFormation ?? 'USA',
      state_of_formation: data.stateOfFormation ?? 'Alaska',
      location_Type: data.locationType ?? 'Affiliate',
      location_edit_funnel_id: 5,
      source: data.locationStatus.label ?? 'Pre- launch location',
    });
  };

  return (
    <DashboardContainer>
      <FormProvider {...methods}>
        <Flex flexDir='column' gap={4} pb={5}>
          <AddLocationHeader
            title={
              isPreLaunch ? 'Add pre-launch location' : 'Add open location'
            }
          />

          <BasicDetails />

          <LoginDetails />

          <Address />

          <EntityDetails />

          <OtherDetails />

          <ActionButton
            size='lg'
            type='submit'
            colorScheme='blue'
            width='fit-content'
            minW='170px'
            alignSelf='flex-end'
            fontSize='15px'
            fontWeight='600'
            borderRadius='7px'
            isLoading={
              loading || addingPreLaunchLocation || gettingLoggedInUserDetails
            }
            actionFn={methods.handleSubmit(onSubmit)}
          >
            {t('common:add')}
          </ActionButton>
        </Flex>
      </FormProvider>
    </DashboardContainer>
  );
};

export default AddLocation;
