import {
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React, { FC, useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts-new';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { AnalyticsData, AuditLocationsAnalytics } from './GraphSection';
import {
  calculateAverageAuditScoreAndDifference,
  calculateLocationBasedAverage,
  calculateOverallAverage,
} from './helper';

const AverageAuditScore: FC<{
  data: AnalyticsData | undefined;
}> = ({ data }) => {
  const overallAverage = calculateLocationBasedAverage(data);
  const result = calculateAverageAuditScoreAndDifference(new Date(), data);

  const graphData = useMemo(() => {
    return [
      {
        name: 'current',
        value: overallAverage,
        color: 'rgba(181, 228, 202, 1)',
      },
      {
        name: 'total',
        value: 100 - overallAverage,
        color: 'rgba(244, 244, 244, 1)',
      },
    ];
  }, [result]);

  if (
    !data ||
    (result?.currentMonthPercentage === 0 &&
      result?.differenceFromPreviousMonth === 0)
  ) {
    return (
      <Flex flexDir='column'>
        <GenericColorHeader
          title='Avg.Audit Score'
          color={HeaderColors.Green}
        />
        <Center flexDir='column' h='full' w='full'>
          <Box as='span' fontWeight={600} fontSize='16px'>
            No Audit Score Yet!
          </Box>
          <Box
            as='span'
            fontWeight={500}
            color='rgba(111, 118, 126, 1)'
            textAlign='center'
          >
            Your audit Score will be generated once audits have been conducted
          </Box>
        </Center>
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap={4} h='fit-content'>
      <GenericColorHeader title='Avg. Audit Score' color={HeaderColors.Green} />

      <Box position='relative' width='250px' h='200px'>
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart>
            <Pie
              dataKey='value'
              startAngle={180}
              endAngle={0}
              data={graphData}
              innerRadius={80}
              outerRadius={100}
              stroke='none'
            >
              {graphData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <Flex
          position='absolute'
          top='0'
          left='0'
          right='0'
          bottom='0'
          align='center'
          justify='center'
          flexDir='column'
        >
          <Flex align='center'>
            <Text fontSize='32px' fontWeight='600' as='span'>
              {overallAverage}
            </Text>
            <Text fontWeight={500} as='span'>
              %
            </Text>
          </Flex>
          {result?.differenceFromPreviousMonth && (
            <HStack mt={1}>
              <Text
                fontSize='sm'
                color={
                  (result?.differenceFromPreviousMonth || 0) > 0
                    ? 'green.500'
                    : 'red.500'
                }
              >
                {(result?.differenceFromPreviousMonth || 0) > 0
                  ? `▲ ${result?.differenceFromPreviousMonth || 0}`
                  : `▼ ${Math.abs(result?.differenceFromPreviousMonth || 0)}`}
              </Text>
              <Text fontSize='sm' color='gray.500'>
                this month
              </Text>
            </HStack>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default AverageAuditScore;
