import { gql } from '@apollo/client';

export const GET_AUDITS_LIST = gql`
  query AuditPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyAuditInput
  ) {
    AuditPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        eid
        title
        expiresAt
        completedOn
        createdAt
        startDate
        sessions {
          auditId
          auditType
          auditors {
            eid
            name
          }
          isLastSession
          name
          questions
          repeatCycle
          title
          updatedAt
          dueDate
          eid
          startDate
          assignments {
            auditors
            comments
            completedAt
            completedBy
            completedPercent
            isCompleted
            locationId
            response
            startedBy
            totalScore
          }
        }
      }
    }
  }
`;

export const GET_AUDIT_SESSIONS_LIST = gql`
  query SupervisedByMeAudits(
    $filter: FilterFindManyAuditSessionInput
    $sort: SortFindManyAuditSessionInput
    $page: Int
    $perPage: Int
  ) {
    AuditSessionPagination(
      filter: $filter
      sort: $sort
      page: $page
      perPage: $perPage
    ) {
      items {
        repeatDetails
        assignments {
          auditors
          comments
          completedAt
          completedBy
          completedPercent
          isCompleted
          locationId
          maxScore
          reportsUrl
          response
          startedBy
          totalFlagged
          totalScore
        }
        audit {
          repeatDetails
          status
        }
        eid
        name
        isCompleted
        createdAt
        completedAt
        title
        auditId
        entityId
        repeatCycle
        dueDate
        startDate
        auditType
        questions
        auditors {
          eid
          name
          profilePic
        }
      }
    }
  }
`;
