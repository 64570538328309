import React, { FC } from 'react';
import DashboardContainer from '../../../sub-components/DashboardContainer';
import { TakeAuditContainer } from 'sub-components/audits/take-audit';

const TakeAudit: FC = () => {
  return (
    <DashboardContainer>
      <TakeAuditContainer />
    </DashboardContainer>
  );
};

export default TakeAudit;
