import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { WeekDaysData } from '../../../../../tasks/create-task/Scheduler';
import { IFormInput } from '../../../audit-schedule.types';
import { OptionButton } from '../../shared';

const Weekly: FC = () => {
  const { t } = useTranslation(['task']);

  const onChange = (
    field: ControllerRenderProps<IFormInput, 'repeatDetails.weekly'>,
    opt: typeof WeekDaysData[number]
  ) => {
    const newValues = [...(field.value || [])];

    if (newValues.includes(opt.value)) {
      return field.onChange(newValues.filter((val) => val !== opt.value));
    }

    field.onChange(newValues.concat(opt.value));
  };

  return (
    <Controller<IFormInput, 'repeatDetails.weekly'>
      name='repeatDetails.weekly'
      rules={{
        required: 'Please select the days when audit repeats',
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <Flex w='full' gap={4} wrap='wrap'>
            {WeekDaysData.map((opt) => (
              <OptionButton
                key={opt.value}
                // isReadOnly={isEdit}
                isDisabled={field.disabled}
                isSelected={field.value?.includes(opt.value)}
                clickDisabled={false}
                onClick={() => onChange(field, opt)}
              >
                <Trans t={t} i18nKey={opt.label} />
              </OptionButton>
            ))}
          </Flex>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default Weekly;
