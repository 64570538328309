import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  Flex,
  HStack,
  Image,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType, SearchInput, Table, useChangeHandler } from 'atoms';
import { useSafeState } from 'hooks';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { ActionMenu } from 'ui-components';
import {
  AuditPaginateResponse,
  AuditPaginateVariables,
} from '../supervised/types';
import { ARCHIVED_AUDITS } from './service/archived-audits.graphql';
import { AuditorEntity, AuditTableEntity } from './types/archived-audits.types';
import EmptyArchivedAudits from './EmptyArchivedAudits';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import { useHistory } from 'react-router-dom';

interface IProps {
  hideSearch?: boolean;
}

const ArchivedAudits: FC<IProps> = ({ hideSearch }) => {
  const mounted = useRef(false);
  const history = useHistory();
  const entityData = useReactiveVar(usersEntityObj);
  const [currentPage, setCurrentPage] = useSafeState(1);
  const [searchQuery, setSearchQuery] = useSafeState('');
  const [templateData, updateData] =
    useSafeState<AuditPaginateResponse['AuditPagination']>();
  const [getArchivedAudits, { loading }] = useLazyQuery<
    AuditPaginateResponse,
    AuditPaginateVariables
  >(ARCHIVED_AUDITS, {
    fetchPolicy: 'network-only',
    onCompleted: (archived) => updateData(archived?.AuditPagination),
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });

  const archivedData = useMemo((): AuditTableEntity[] => {
    return (
      templateData?.items?.map((item) => {
        let assigneesLength = item?.auditors?.[0]?.locations?.length;
        let foundAuditors = entityData
          ?.filter((entity) =>
            item?.auditors?.[0]?.userId?.some((id) => entity?.eid === id)
          )
          ?.map((user) => ({
            eid: user?.eid,
            name: user?.name,
            profilePic: user?.profilePic,
          }));
        return {
          auditName: item?.title,
          deletedOn: moment(item?.statusLogs?.[0]?.statusDate)?.isValid()
            ? moment(item?.statusLogs?.[0]?.statusDate)?.format('DD MMM, YYYY')
            : '-',
          assignees: `${assigneesLength} ${
            assigneesLength === 1 ? 'location' : 'locations'
          }`,
          updatedAt: moment(item?.lastUpdatedOn)?.isValid()
            ? moment.utc(item?.lastUpdatedOn)?.format('DD MMM, YYYY')
            : '-',
          auditors: foundAuditors,
          auditId: item?.eid,
          sessionId: item?.sessions?.[0]?.eid,
        };
      }) || []
    );
  }, [templateData, entityData]);

  const actionHandler = (clickedItem: string, data: AuditTableEntity) => {
    switch (clickedItem) {
      case 'viewAudit':
        data?.auditId &&
          data?.sessionId &&
          history.push(`/audits/details/${data?.auditId}/${data?.sessionId}`);
        // templatePreview({
        //   templateId: data.eid,
        // });
        return void 0;
      default:
        // eslint-disable-next-line no-console
        console.log(clickedItem, data);
    }
  };

  const inputVariables = useMemo(() => {
    const inputs: AuditPaginateVariables = {
      page: currentPage,
      perPage: 10,
      filter: {
        status: 'AUDIT_DELETED',
      },
      sort: '_ID_DESC',
      sessionLimit: 1,
      sessionSort: '_ID_DESC',
    };

    if (searchQuery?.trim() && inputs['filter']) {
      inputs['filter']['title'] = searchQuery.trim();
    }

    return inputs;
  }, [searchQuery]);

  const debouncedFetch = debounce(async (variables) => {
    getArchivedAudits({
      variables: variables,
    });
  }, 300);

  useEffect(() => {
    if (!mounted.current) {
      getArchivedAudits({
        variables: {
          page: 1,
          perPage: 10,
          filter: {
            status: 'AUDIT_DELETED',
          },
          sort: '_ID_DESC',
          sessionLimit: 1,
          sessionSort: '_ID_DESC',
        },
      });
      setTimeout(() => {
        mounted.current = true;
      });
    }
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return undefined;
    }

    debouncedFetch(inputVariables);

    return () => debouncedFetch.cancel();
  }, [inputVariables]);

  const onChange = useChangeHandler<AuditTableEntity>(
    (pagination, filters, _sorter, extra) => {
      switch (extra.action) {
        case 'paginate':
          setCurrentPage(pagination.current!);
          getArchivedAudits({
            variables: {
              page: pagination.current || 1,
              perPage: 10,
              filter: {
                status: 'AUDIT_DELETED',
              },
              sort: '_ID_DESC',
              sessionLimit: 1,
              sessionSort: '_ID_DESC',
            },
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(pagination, filters, _sorter, extra);
      }
    }
  );

  const renderAvatars = (auditors: AuditorEntity[]) => {
    if (auditors?.length === 0) {
      return <>-</>;
    }
    const displayCount = 2;
    const firstTwoAuditors = auditors.slice(0, displayCount);
    const remainingCount = auditors.length - displayCount;
    return (
      <HStack spacing='-10px'>
        {firstTwoAuditors.map((auditor, index) => (
          <Image
            key={auditor.eid}
            src={getImage(auditor?.profilePic, auditor?.name)}
            boxSize='30px'
            border='2px solid white'
            borderRadius='50%'
            zIndex={firstTwoAuditors?.length + index}
          />
        ))}
        {remainingCount > 0 && (
          <Center
            boxSize='30px'
            bg='gray.300'
            fontSize='14px'
            border='2px solid white'
            borderRadius='50%'
            zIndex={firstTwoAuditors?.length + 1}
          >
            +{remainingCount}
          </Center>
        )}
      </HStack>
    );
  };

  const columns: ColumnsType<AuditTableEntity> = [
    {
      title: 'Audit name',
      dataIndex: 'auditName',
    },
    {
      title: 'Deleted on',
      dataIndex: 'deletedOn',
      key: 'deletedOn',
    },
    {
      title: 'Assignees',
      dataIndex: 'assignees',
      key: 'assignees',
    },
    {
      title: 'Last Activity',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Auditor(s)',
      dataIndex: 'auditors',
      key: 'auditors',
      render: (auditors: AuditorEntity[]) => {
        return <HStack>{renderAvatars(auditors)}</HStack>;
      },
    },
    {
      dataIndex: 'action',
      align: 'center',
      render: (__, data) => (
        <Center>
          <ActionMenu
            menuData={[
              {
                name: 'View Audit',
                value: 'viewAudit',
                icon: faArrowUpRight,
                normalIcon: 'fontAwesome',
                iconColor: '#6F767E',
              },
            ]}
            arrowSize={10}
            closeOnBlur={true}
            offset={[24, 8]}
            clickedItem={(_, clickedItem) => {
              return actionHandler?.(clickedItem, data);
            }}
            strategy={'fixed'}
          >
            <Box w='min-content' px={1}>
              <FontAwesomeIcon
                cursor={'pointer'}
                fontSize={'1.2rem'}
                icon={faEllipsis as IconProp}
              />
            </Box>
          </ActionMenu>
        </Center>
      ),
    },
  ];

  return (
    <Flex flexDir='column' gap={4}>
      {!hideSearch && (
        <SearchInput
          placeholder='Search'
          hideShortcuts
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      )}
      <Table
        columns={columns}
        dataSource={archivedData}
        rowKey='auditId'
        isLoading={loading}
        onChange={onChange}
        searchActive={!!searchQuery}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 10,
          total: templateData?.count,
        }}
        emptyState={<EmptyArchivedAudits />}
      />
    </Flex>
  );
};

export default ArchivedAudits;
