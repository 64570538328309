import React, { FC } from 'react';
import EmptyState from '../../../EmptyState';

interface IProps {
  searchActive?: boolean;
}

const EmptyArchivedAudits: FC<IProps> = ({ searchActive }) => {
  if (searchActive) {
    return (
      <EmptyState
        image='Search'
        imageSize='160px'
        title='Hmm, couldn’t find an audit with that name'
        description='Try using different search terms'
      />
    );
  }

  return (
    <EmptyState
      image='Form'
      imageSize='200px'
      title='No audits archived!'
      description="You will find your archived audits here! Archive audits when you don't want to use them anymore"
    />
  );
};

export default EmptyArchivedAudits;
