import { gql } from '@apollo/client';

export const filteredTemplateSearchQuery = gql`
  query AuditTemplatePagination($filter: FilterFindManyAuditTemplateInput) {
    AuditTemplatePagination(filter: $filter) {
      items {
        title
        eid
        thumbnail
        category {
          name
        }
        createdAt
        createdBy
        description
        entityId
        lastUpdatedBy
        optionsMapping
        questions
        updatedAt
        status
      }
    }
  }
`;

export const shareAuditReportViaEmail = gql`
  mutation ShareAuditReport(
    $emails: [String]!
    $url: String!
    $auditId: String!
  ) {
    ShareAuditReport(emails: $emails, url: $url, auditId: $auditId) {
      succeed
    }
  }
`;

export const getAuditId = gql`
  query AuditSessionById($eid: String!) {
    AuditSessionById(eid: $eid) {
      auditId
    }
  }
`;
