import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Flex, Spacer, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import NoContent from 'sop-commons/src/card-components/elements/NoContent';
import { isCheckList, toArray } from 'utils/utils';
import CustomFormHeader from './component/CustomFormHeader';

import './component/custom-form.scss';
import Question from './component/Question';
import Section from './component/Section';
import { IFormInput } from '../create-form/form.types';
import { toFormResponse, toMobilePreview } from '../dataConvert';
import { FormPreviewInput } from './form-preview.types';
import { addFormResponse, FormSubmitResponse } from './form-preview.graphql';
import SubmitButton from './SubmitButton';
import { QuestionType } from '../../../types';
import TextSection from './component/TextSection';
import { Acknowledge } from './component/Acknowledge';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

interface FormDataType extends IFormInput {
  category?: string;
}

interface FormProps {
  content: FormDataType;
  title?: string;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  sessionId?: string;
  shouldMoveToNextCard?: () => void;
  shouldSetFixedHeight?: boolean;
  event?: any;
}

interface IProps {
  content: FormDataType[];
  title?: string;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  sessionId?: string;
  shouldMoveToNextCard?: () => void;
  shouldSetFixedHeight?: boolean;
  event?: any;
}

const simulate = () => new Promise((resolve) => setTimeout(resolve, 2000));

const CustomFormTypeForm: FC<FormProps> = ({
  content,
  isPreview,
  isReadOnly,
  isDisabled: _isDisabled,
  sessionId,
  shouldMoveToNextCard,
  shouldSetFixedHeight,
  event,
}) => {
  const { t } = useTranslation(['common', 'form']);
  const methods = useForm<FormPreviewInput>();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const { isSubmitSuccessful } = methods.formState;

  const [submitForm, { loading }] = useMutation<FormSubmitResponse>(
    addFormResponse,
    {
      onCompleted: () => {
        setTimeout(() => shouldMoveToNextCard?.(), 300);
      },
    }
  );

  const { fields } = useFieldArray<FormPreviewInput, 'response'>({
    control: methods.control,
    name: 'response',
  });

  useEffect(() => {
    const newValue = toMobilePreview(content as any);
    methods.reset(newValue);
  }, [content]);

  const onFinish = async (values: FormPreviewInput) => {
    if (_isDisabled) {
      return;
    }
    if (isPreview) {
      console.log('form is in preview');
      return simulate().then(() => {
        deployEvent(AmplitudeEventNames?.SUBMIT_FORM, {
          fill_form_funnel_id: 3,
          form_id: content?.eid,
          form_category: content?.category,
          form_title: content?.title,
        });
        return setTimeout(() => shouldMoveToNextCard?.(), 500);
      });
    } else {
      event && deployEvent(event);
      const finalValues = toFormResponse(values);
      let _finalValues = JSON.parse(JSON.stringify(finalValues));
      if (sessionId) {
        _finalValues.sessionId = sessionId;
      }
      const response = await submitForm({
        variables: {
          input: _finalValues,
        },
      });

      if (response.errors) {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t('form:responseNotSubmitted'),
        });
        throw 'Form submission failed';
      }
    }
  };

  const isDisabled = isSubmitSuccessful || _isDisabled;

  return (
    <Flex
      direction='column'
      className='custom-form-preview'
      minHeight={shouldSetFixedHeight ? '80vh' : 'none'}
    >
      <CustomFormHeader
        thumbnail={content?.thumbnail}
        title={content?.title}
        description={content?.description}
        category={content?.category}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFinish)}>
          {fields?.map((question, index) => {
            if (question.qType === QuestionType.SECTION) {
              return <Section key={index} question={question} />;
            }
            if (question.qType === QuestionType.TEXT_SECTION) {
              return <TextSection key={index} question={question} />;
            }
            if (question.qType === QuestionType.ACKNOWLEDGMENT) {
              return (
                <Acknowledge
                  key={index}
                  currentIndex={index}
                  question={question}
                  isPreview={isPreview}
                  isReadOnly={isReadOnly}
                  isDisabled={isDisabled}
                />
              );
            }
            return (
              <Question
                key={index}
                currentIndex={index}
                question={question}
                isPreview={isPreview}
                isReadOnly={isReadOnly}
                isDisabled={isDisabled}
              />
            );
          })}
          <Spacer display='flex' bg='white' pb='24px' pt='16px'>
            {!(_isDisabled || isReadOnly) && <SubmitButton loading={loading} />}
          </Spacer>
        </form>
      </FormProvider>
    </Flex>
  );
};

const CustomFormType: FC<IProps> = ({
  content: contentList,
  isPreview,
  isDisabled,
  sessionId,
  shouldMoveToNextCard,
  shouldSetFixedHeight,
  event,
}) => {
  const content = toArray(contentList)?.[0];

  const hasNoData = useMemo(() => {
    if (isPreview) {
      let status = !(
        content?.thumbnail ||
        content?.title?.trim() ||
        content?.questions?.length
      );
      if (
        isCheckList(content?.category || content?.formCategory?.name) &&
        !status
      ) {
        status = !content?.questions?.[0]?.label?.trim();
      }
      return status;
    } else {
      return false;
    }
  }, [content, isPreview]);

  if (hasNoData) {
    return <NoContent />;
  }
  return (
    <CustomFormTypeForm
      content={content}
      isPreview={isPreview}
      isDisabled={isDisabled}
      shouldMoveToNextCard={shouldMoveToNextCard}
      shouldSetFixedHeight={shouldSetFixedHeight}
      sessionId={sessionId}
      event={event}
    />
  );
};

export default CustomFormType;
