import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import { CancelTokenSource, useUploadImage } from '../../../../../hooks';
import { convertToWebp, fileToDtaUrl, getWebpFilename } from './image.helper';
import { AuditTake } from '../../tabke-audit.helpers';

interface ImageInput {
  eid: string;
  file: File;
}

export interface QueImage extends ImageInput {
  cancelToken?: CancelTokenSource;
  thumbnail?: string;
  imageUrl?: string;
  isUploaded?: boolean;
  isUploading?: boolean;
  uploadFailed?: boolean;
}

export interface UploadAction {
  value: string[];
  uploadQue: QueImage[];
  addImage: (data: ImageInput) => Promise<void>;
  removeImage: (index: number) => void;
  removeFromQue: (eid: string) => void;
}

type UseUploadImage = ReturnType<typeof useUploadImage>;

interface IProps {
  uploadAction: UseUploadImage;
  onChange?: (value: string[]) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createUploadStore = (props: IProps) => {
  return createStore<UploadAction>()(
    immer((set, getState) => ({
      value: [] as string[],
      uploadQue: [] as QueImage[],

      addImage: async (data) => {
        const index = getState().uploadQue?.length;
        const cancelToken = useUploadImage.CancelToken();

        // const reader = new FileReader();
        //
        // reader.onloadend = function () {
        //   set((state) => {
        //     state.uploadQue[index].thumbnail = reader.result as string;
        //   });
        // };

        try {
          const dataUrl = await fileToDtaUrl(data.file);

          AuditTake.updateProgress(data.eid, true);

          set((state) => {
            state.uploadQue[index] = {
              isUploading: true,
              uploadFailed: false,
              thumbnail: dataUrl,
              ...data,
              cancelToken,
            };
          });

          const blobData = await convertToWebp(dataUrl);

          // reader.readAsDataURL(data.file);
          const imageUrl = await props.uploadAction(
            blobData,
            getWebpFilename(data?.file?.name),
            undefined,
            cancelToken
          );

          set((state) => {
            state.uploadQue[index].isUploading = false;
            state.uploadQue[index].isUploaded = true;
            state.uploadQue[index].imageUrl = imageUrl;
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          set((state) => {
            if (state.uploadQue[index]) {
              state.uploadQue[index].uploadFailed = true;
              state.uploadQue[index].isUploading = false;
            }
          });
        } finally {
          // reader.abort();
          AuditTake.updateProgress(data.eid, false);
          if (props?.onChange) {
            const uploadQue = getState().uploadQue;
            if (!uploadQue.some((a) => a.isUploading)) {
              props.onChange([
                ...getState().value,
                ...uploadQue
                  .filter((a) => a.isUploaded)
                  .map((a) => a.imageUrl!),
              ]);

              set((state) => {
                state.uploadQue = state.uploadQue.filter(
                  (a) => a.isUploading || a.uploadFailed
                );
              });
            }
          }
        }
      },

      removeImage: (index) => {
        props.onChange?.(getState().value.filter((_, i) => i !== index));
      },

      removeFromQue: (eid) => {
        set((state) => {
          state.uploadQue = state.uploadQue.filter((q) => q.eid !== eid);
        });
      },
    }))
  );
};
