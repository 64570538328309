import React, { FC } from 'react';
import { Box, Flex, Divider } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';

import { stringToColor } from '../../common/helpers';
import { AuditTemplateItem } from '../../query/audits-listing.graphql';

type IProps = {
  template: AuditTemplateItem;
  onClick: () => void;
};

const SingleTemplateList: FC<IProps> = ({ template, onClick }) => {
  const { t } = useTranslation(['audit']);
  return (
    <>
      <Flex flexDir={'column'} gap={1} cursor='pointer' onClick={onClick}>
        <Flex gap={4} color='#1A1D1F' align='center'>
          <Box fontWeight={600} fontSize='15px'>
            {template?.title ? template?.title : 'Title not provided'}
          </Box>
          <Box
            padding='3px 10px'
            fontWeight={600}
            borderRadius='4px'
            bg={stringToColor(template?.category?.name)}
          >
            {template?.category?.name || 'Not defined'}
          </Box>
        </Flex>

        <Flex gap={2} align='center'>
          <FontAwesomeIcon
            fontSize='12px'
            icon={faArrowDownToBracket as IconProp}
            color='#6F767E'
          />
          <Box
            fontSize='12px'
            fontWeight={500}
            whiteSpace='nowrap'
            color='#6F767E'
          >
            <Trans
              t={t}
              i18nKey='audit:downloadCount'
              count={template.downloads}
              values={{
                count: template.downloads,
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Divider marginY={4} />
    </>
  );
};

export default SingleTemplateList;
