import { Box, Flex, Text } from '@chakra-ui/react';
import {
  components,
  MenuListProps,
  OptionProps,
  Select,
} from 'chakra-react-select';
import CardColorHeader from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/AssignPublishForm/CardColorHeader';
import React, {
  FC,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MultiSelectChipDropdown from 'sub-components/MultiSelectChipDropdown';
import TrainingPathContext from '../../TrainingPathStore/training-path-context';
import { getChakraSelectStyles } from 'atoms/Dropdown/dropdown.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { IconImage } from '../../../../../../ui-components';

import GenericColorHeader from 'sub-components/GenericColorHeader';
import { Card } from 'antd';
import AddRole from 'sub-components/SettingDataCenter/RolesList/AddRole';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_USER } from 'pages/Login/login.graphql';
import { AuthRole, userObj } from 'sop-commons/src/client';
import { IAssignEntity } from 'pages/Training/CreateTrainingPath/training-path-types';
import { Trans, useTranslation } from 'react-i18next';

import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { deployEvent } from 'shared';
import { usersEntityObj } from 'sub-components/Header';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface SelectOption {
  id: string;
  label: string;
  value: string;
  url?: string;
}

const Option: FC<OptionProps<SelectOption, true>> = (props) => {
  const { isSelected, data, children } = props;

  return (
    <components.Option {...props}>
      <Flex align='center' cursor='pointer'>
        <IconImage
          name={data?.label}
          thumbnail={data?.url}
          boxSize={20}
          borderRadius='5px'
        />
        <Text flex={1} noOfLines={1} px='8px'>
          {children}
        </Text>
        {/* <Checkbox isChecked={isSelected} onClick={(e) => e.preventDefault()} /> */}
        {isSelected && (
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            size='lg'
          />
        )}
      </Flex>
    </components.Option>
  );
};

interface IProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  members: SelectOption[];
  placeholder: string;
  type: 'role' | 'location' | 'member' | 'supervisor';
  value?: string[];
  onChange: (newValue: string[]) => void;
  setNewRole?: React.Dispatch<React.SetStateAction<boolean>>;
}

const GenericSelect = forwardRef<unknown, IProps>(
  ({
    isLoading,
    isDisabled,
    members,
    placeholder,
    type,
    value = [],
    onChange,
    setNewRole,
  }) => {
    const { t } = useTranslation('common');
    const records = useMemo(() => {
      return members?.reduce<{ [key: string]: SelectOption }>(
        (previousValue, currentValue) => {
          if (!previousValue[currentValue.id]) {
            previousValue[currentValue.id] = currentValue;
          }
          return previousValue;
        },
        {}
      );
    }, [members]);

    const selectedValue = useMemo(() => {
      return value?.map((it) => {
        if (typeof it === 'object') {
          return it;
        }
        return records[it];
      });
    }, [records, value]);

    const handleRemove = useCallback(
      (item: SelectOption) => {
        onChange(value.filter((val) => val !== item.id));
      },
      [value, onChange]
    );

    const MenuList = (props: MenuListProps<SelectOption, true>) => {
      return (
        <components.MenuList {...props}>
          <Box backgroundColor='white'>
            {type === 'role' && (
              <Box w='full' pt='10px' pb='5px' pl='15px'>
                <Text
                  color={'rgb(42, 133, 255)'}
                  as='b'
                  cursor='pointer'
                  onClick={() => setNewRole && setNewRole(true)}
                >
                  + {t('create_new_role')}
                </Text>
              </Box>
            )}
            {props.children}
          </Box>
        </components.MenuList>
      );
    };

    return (
      <div>
        <Select<SelectOption, true>
          placeholder={placeholder}
          chakraStyles={getChakraSelectStyles({
            menuList: {
              padding: '12px',
            },
          })}
          options={members}
          isLoading={isLoading}
          isDisabled={isDisabled}
          value={selectedValue}
          isMulti
          onChange={(newValue) => {
            onChange(newValue.map((val) => val.id));
          }}
          styles={{
            option: (base, optionProps) => ({
              ...base,
              color: 'black',
              borderBottom: '1px solid #efefef',
              borderRadius:
                optionProps?.isSelected || optionProps?.isFocused ? 8 : 0,
              ':last-child': {
                borderBottom: 'none',
              },
              backgroundColor: optionProps?.isSelected
                ? '#2A85FF1F'
                : optionProps?.isFocused
                ? '#EEEEEE'
                : 'none',
            }),
          }}
          getOptionValue={(option) => option?.id}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
          components={{
            MenuList,
            Option,
          }}
        />
        {selectedValue?.length > 0 && (
          <Flex align='center' gap='10px' wrap='wrap' mt='1rem'>
            {selectedValue?.map((item) => {
              return (
                <Flex
                  key={item?.id}
                  align='center'
                  bg='#b1e5fc'
                  p='5px 6px'
                  borderRadius='5px'
                  gap='5px'
                >
                  <IconImage
                    name={item?.value}
                    thumbnail={item?.url}
                    boxSize={20}
                    borderRadius='5px'
                  />
                  <Text as='span' fontWeight='600' pr='5px' noOfLines={1}>
                    {item?.value}
                  </Text>
                  <span
                    onClick={() => handleRemove(item)}
                    style={{
                      cursor: 'pointer',
                      fontSize: '20px',
                      fontWeight: '500',
                    }}
                  >
                    &times;
                  </span>
                </Flex>
              );
            })}
          </Flex>
        )}
      </div>
    );
  }
);

const TrainingPathAssignForm = () => {
  const { t } = useTranslation('training');
  const trainingPathCtx = useContext(TrainingPathContext);
  const entityUserData = useReactiveVar(usersEntityObj);
  const userData = useReactiveVar(userObj);
  const [newRole, setNewRole] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');

  useEffect(() => {
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      trainingPathCtx.setShouldShowHiddenItemsHandler(true);
    }
  }, []);

  const [getUser, { loading: userLoading }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNewRoleName('');
      let _data = JSON.parse(JSON.stringify(data));
      userObj(_data?.user || {});
      let foundData: IAssignEntity = {
        value: '',
        label: '',
        id: '',
        selected: false,
      };
      _data?.user?.entity?.roles?.map((role) => {
        if (role?.name === newRoleName) {
          (foundData.id = role?.eid),
            (foundData.label = role?.name),
            (foundData.value = role?.name),
            (foundData.selected = true);
        }
      });
      if (foundData) {
        let allData = [...trainingPathCtx?.rolesList, foundData];
        trainingPathCtx?.setRolesListHandler(allData);

        let selectedData = [...trainingPathCtx?.selectedRoles, foundData.id];
        trainingPathCtx?.setSelectedRolesHandler(selectedData);
      }
    },
  });

  const getMembersList = useCallback(() => {
    return trainingPathCtx?.membersList;
  }, [trainingPathCtx?.membersList]);

  const onSupervisorChange = (value: string[]) => {
    trainingPathCtx?.setSelectedSupervisorsHandler(value);
    let supervisorNames =
      entityUserData
        ?.filter((entity) =>
          value?.some((supervisor) => supervisor === entity?.eid)
        )
        ?.map((user) => user?.name) || [];
    deployEvent(AmplitudeEventNames.SUPERVISOR_SELECT_TRAINING, {
      training_path_name: trainingPathCtx?.trainingPathName,
      training_path_id: '',
      supervisors: supervisorNames,
      add_new_path_funnel_id: 4,
      edit_training_funnel_id: 4,
    });
  };

  return (
    <Flex direction={'column'} gap={4}>
      <Box>
        <Card>
          <Flex gap={4} direction={'column'}>
            <GenericColorHeader
              title={t('who_will_undergo')}
              color={'#cabdff'}
            />
            {(userData?.authRole === AuthRole.SUPER_ADMIN ||
              userData?.authRole === AuthRole.ADMIN) && (
              <>
                <Text as={'b'}>{t('assign_to_roles')}</Text>
                <GenericSelect
                  members={trainingPathCtx?.rolesList}
                  type={'role'}
                  isLoading={userLoading}
                  isDisabled={userLoading}
                  placeholder={t('assign_to_roles_placeholder')}
                  value={trainingPathCtx?.selectedRoles}
                  onChange={(value: string[]) => {
                    trainingPathCtx?.setSelectedRolesHandler(value);
                    let assigneeNames =
                      trainingPathCtx?.rolesList
                        ?.filter((role) => value?.includes(role?.id))
                        ?.map((role) => role?.label) || [];
                    deployEvent(AmplitudeEventNames.ASSIGNEE_SELECT_TRAINING, {
                      training_path_name: trainingPathCtx?.trainingPathName,
                      add_new_path_funnel_id: 4,
                      training_path_id: '',
                      edit_training_funnel_id: 4,
                      assign_category: 'Roles',
                      assignees: assigneeNames,
                    });
                  }}
                  setNewRole={setNewRole}
                />
              </>
            )}
            {!trainingPathCtx?.shouldShowHiddenItems ? (
              <Text
                textDecoration={'underline'}
                cursor={'pointer'}
                color={'#2a85ff'}
                onClick={() =>
                  trainingPathCtx?.setShouldShowHiddenItemsHandler(true)
                }
              >
                {t('like_to_assign_instead')}
              </Text>
            ) : (
              <>
                {userData?.authRole === AuthRole.SUPER_ADMIN ||
                userData?.authRole === AuthRole.ADMIN ? (
                  <>
                    <Text as={'b'}>{t('assign_to_locations')}</Text>
                    <GenericSelect
                      members={trainingPathCtx?.locationsList}
                      type={'location'}
                      placeholder={t('assign_to_locations_placeholder')}
                      value={trainingPathCtx?.selectedLocations}
                      onChange={(value: string[]) => {
                        trainingPathCtx?.setSelectedLocationsHandler(value);
                        let locationNames =
                          entityUserData
                            ?.filter((loc) => value?.includes(loc?.eid))
                            ?.map((loc) => loc?.name) || [];
                        deployEvent(
                          AmplitudeEventNames.ASSIGNEE_SELECT_TRAINING,
                          {
                            training_path_name:
                              trainingPathCtx?.trainingPathName,
                            add_new_path_funnel_id: 4,
                            training_path_id: '',
                            edit_training_funnel_id: 4,
                            assign_category: 'Locations',
                            assignees: locationNames,
                          }
                        );
                      }}
                    />
                  </>
                ) : null}
                <Text as={'b'}>{t('assign_to_members')}</Text>
                {/* <GenericSelect
                  members={trainingPathCtx?.membersList}
                  type={'member'}
                  placeholder={t('assign_to_members_placeholder')}
                  value={trainingPathCtx?.selectedMembers}
                  onChange={(value: string[]) => {
                    console.log('value : ', value);
                    trainingPathCtx?.setSelectedMembersHandler(value);
                  }}
                /> */}
                <CustomSelectComponent
                  options={getMembersList()}
                  value={trainingPathCtx?.selectedMembers}
                  placeholder='Select member(s)'
                  showHeader
                  headerTitle='Select member(s)'
                  headerBarColor={HeaderColors.Purple}
                  showHeaderCloseIcon
                  showSearchField
                  showDivider
                  bottomOutsideBtn={{
                    show: true,
                    title: 'Add selected members',
                  }}
                  showFilter
                  showSelectAll
                  onChange={(value: string[]) => {
                    trainingPathCtx?.setSelectedMembersHandler(value);
                    const valueSet = new Set(value);
                    let memberNames =
                      entityUserData
                        ?.filter((entity) => valueSet.has(entity?.eid))
                        ?.map((user) => user?.name) || [];
                    deployEvent(AmplitudeEventNames.ASSIGNEE_SELECT_TRAINING, {
                      training_path_name: trainingPathCtx?.trainingPathName,
                      add_new_path_funnel_id: 4,
                      training_path_id: '',
                      edit_training_funnel_id: 4,
                      assign_category: 'Members',
                      assignees: memberNames,
                    });
                  }}
                />
              </>
            )}
          </Flex>
        </Card>
      </Box>
      <Box>
        <Card>
          <Flex direction={'column'} gap={4}>
            <GenericColorHeader
              title={t('who_will_supervise')}
              color={'#b1e5fc'}
            />
            <Box
              bg='rgba(202, 189, 255, 0.35)'
              borderRadius='10px'
              py='12px'
              px='24px'
              color='#33383F'
            >
              <Trans
                t={t}
                i18nKey='locationOwnerSupervisor'
                components={{
                  span: <span style={{ fontWeight: 600 }} />,
                }}
              />
            </Box>
            {/* <GenericSelect
              members={trainingPathCtx?.supervisorsList}
              type={'supervisor'}
              placeholder={t('assign_to_supervisors_placeholder')}
              value={trainingPathCtx?.selectedSupervisors}
              onChange={(value: string[]) => {
                trainingPathCtx?.setSelectedSupervisorsHandler(value);
              }}
            /> */}
            <CustomSelectComponent
              options={trainingPathCtx?.supervisorsList}
              value={trainingPathCtx?.selectedSupervisors}
              placeholder='Select supervisor(s)'
              showHeader
              headerTitle='Select supervisor(s)'
              headerBarColor={HeaderColors.Purple}
              showHeaderCloseIcon
              showSearchField
              showDivider
              bottomOutsideBtn={{
                show: true,
                title: 'Add selected supervisors',
              }}
              showFilter
              showSelectAll
              onChange={(value: string[]) => onSupervisorChange(value)}
            />
          </Flex>
        </Card>
      </Box>
      <AddRole
        open={newRole}
        type='add'
        onClose={() => setNewRole(false)}
        zIndex={2002}
        onRoleCreate={(value: string) => {
          setNewRoleName(value);
          getUser({ variables: {} });
        }}
      />
    </Flex>
  );
};

GenericSelect.displayName = 'Generic Select';

export default TrainingPathAssignForm;
