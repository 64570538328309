import React, { FC } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FormInput, Input } from 'atoms';
import { AuditHelper } from 'configs';

import { IFormInput } from '../../../form-types';

function isNumber(value: string) {
  return value.match(/[0-9]+/);
}

interface IProps {
  questionIndex: number;
  optionIndex: number;
  totalOptions: number;
  isReadOnly?: boolean;
  hasScoring?: boolean;
  onRemove?: () => void;
}

const AuditOptionInput: FC<IProps> = ({
  questionIndex,
  optionIndex,
  totalOptions,
  isReadOnly,
  hasScoring,
  onRemove,
}) => {
  const { t } = useTranslation('form');

  const isDisabled = useWatch<
    IFormInput,
    `questions.${number}.options.${number}.isDisabled`
  >({
    name: `questions.${questionIndex}.options.${optionIndex}.isDisabled`,
  });

  const isOther = useWatch<
    IFormInput,
    `questions.${number}.options.${number}.isOther`
  >({
    name: `questions.${questionIndex}.options.${optionIndex}.isOther`,
  });

  const evidence = useWatch<
    IFormInput,
    `questions.${number}.options.${number}.requiresEvidence`
  >({
    name: `questions.${questionIndex}.options.${optionIndex}.requiresEvidence`,
  });

  if (isDisabled) {
    return null;
  }

  return (
    <Flex mt={5} gap={3}>
      <Controller<IFormInput, `questions.${number}.options.${number}.label`>
        name={`questions.${questionIndex}.options.${optionIndex}.label`}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl
              isInvalid={!!fieldState.error}
              isReadOnly={isReadOnly || isOther}
            >
              <FormInput
                size='lg'
                variant='auditOutline'
                {...field}
                placeholder={isOther ? t('other') : undefined}
                onBlur={AuditHelper.save(field.onBlur)}
                rightIcon={
                  evidence ? (
                    <FontAwesomeIcon
                      icon={faPaperclip as IconProp}
                      style={{
                        transform: 'rotate(90deg) scale(-1, 1)',
                      }}
                      color='#6F767E'
                    />
                  ) : undefined
                }
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Controller<IFormInput, `questions.${number}.options.${number}.score`>
        name={`questions.${questionIndex}.options.${optionIndex}.score`}
        defaultValue={undefined}
        render={({ field, fieldState }) => {
          return (
            <Input
              size='lg'
              variant='auditOutline'
              width='64px'
              isInvalid={!!fieldState.error}
              isReadOnly={isReadOnly}
              hidden={!hasScoring}
              placeholder='/'
              textAlign='center'
              {...field}
              onKeyPressCapture={(event) => {
                if (!isNumber(event.key)) {
                  event.preventDefault();
                }
              }}
              onBlur={AuditHelper.save(field.onBlur)}
            />
          );
        }}
      />

      <Flex h={12} align='center' pl={1}>
        <IconButton
          aria-label={'remove'}
          variant='solid'
          colorScheme='whiteAlpha'
          size='xs'
          icon={<CloseIcon color='#6F767E' />}
          onClick={onRemove}
          hidden={totalOptions === 1}
        />
      </Flex>
    </Flex>
  );
};

export default AuditOptionInput;
