import xlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { orderHeaders } from './OrderHeaders';
import { locationOrgHeaders } from './LocationOrgHeaders';
import { IMember } from '../../../ui-components/Members/memberTypes/memberTypes';
import { ILocation } from '../../../sub-components/Locations/locationTypes/location-types';
import { AuthRole } from '../../../authorization';
import { toArray } from '../../../utils/utils';
import { FieldType } from 'pages/Teams/locations/LocationListContainer';
import { generateLocationsDetailSheet } from 'pages/Teams/locations/LocationDetailSheet';

export type ICreateReport = {
  type: string;
  selectedMembers: IMember[];
  createSheetsHandlerFn: () => void;
};

export type ILocations = {
  eid: string;
  name: string;
  isDeleted: boolean;
  username: string;
  email: string;
  status: string;
  phone: string;
  thumbnail: string;
  createdAt: string;
  members: {
    name: string;
    eid: string;
    username: string;
    authRole: string;
    role: string;
    email: string;
    thumbnail: string;
    status: string;
  }[];
};

// const CreateReport = ({ type, selectedMembers, createSheetsHandlerFn }) => {
const generateCharacterFromNumber = (number: number) => {
  var baseChar = 'A'.charCodeAt(0),
    letters = '';
  do {
    number -= 1;
    letters = String.fromCharCode(baseChar + (number % 26)) + letters;
    number = (number / 26) >> 0; // quick `floor`
  } while (number > 0);

  return letters;
};

const addHeadersToSheet = (
  sheetArg: xlsxPopulate.Sheet
): xlsxPopulate.Sheet => {
  orderHeaders.forEach((header, index) => {
    let string = generateCharacterFromNumber(index + 1) + '1';
    sheetArg.cell(string).value(header.heading);
  });
  return sheetArg;
};

const addDataToSheet = (sheetArg: xlsxPopulate.Sheet, data: any) => {
  let orderData: any = [];
  data?.users?.forEach((sheetData: any) => {
    orderData.push({
      firstName: sheetData?.name,
      lastName: '',
      email: sheetData?.email,
      phone: sheetData?.phone,
      primaryUserId: sheetData?.eid,
      accessLevel: sheetData?.authRole,
      role: sheetData?.role,
      addedOn: moment(sheetData?.createdAt).isValid()
        ? moment(sheetData?.createdAt).format('DD-MM-YYYY, hh:mm:ss A')
        : '',
      lastActive: moment(sheetData?.lastLogin).isValid()
        ? moment(sheetData?.lastLogin)?.format('DD-MM-YYYY, hh:mm:ss A')
        : '',
    });
  });
  orderData.forEach((order: any, index: any) => {
    Object.entries(order).forEach((val, orderIndex) => {
      let string = generateCharacterFromNumber(orderIndex + 1) + `${index + 2}`;
      sheetArg.cell(string).value(val[1]);
    });
  });
  return sheetArg;
};

const filterUsersBasedOnLocation = (selectedUsers: any) => {
  let filteredArray: {
    location: string;
    users?: IMember[];
  }[] = [];
  const categorisedMembers = selectedUsers.reduce(
    (memberSoFar: any, member: any) => {
      if (!memberSoFar[member?.locations?.[0]?.name]) {
        memberSoFar[member?.locations?.[0]?.name] = [];
      }
      memberSoFar[member?.locations?.[0]?.name].push(member);
      return memberSoFar;
    },
    {}
  );
  let entriesCategorisedMembers = Object.entries(categorisedMembers);
  entriesCategorisedMembers.map((category: any) => {
    filteredArray.push({
      location: category[0],
      users: category[1],
    });
  });
  return filteredArray;
};

const addHeadersToSheetLocationOrg = (
  sheetArg: xlsxPopulate.Sheet
): xlsxPopulate.Sheet => {
  locationOrgHeaders.forEach((header, index) => {
    let string = generateCharacterFromNumber(index + 1) + '1';
    sheetArg.cell(string).value(header.heading);
  });
  return sheetArg;
};

const addDataToSheetLocationOrg = (
  sheetArg: xlsxPopulate.Sheet,
  data: any,
  selectedUsers?: any
) => {
  let locationOrgData: {
    location: string;
    addedOn: string;
    manager: string;
  }[] = [];
  toArray(selectedUsers)?.forEach((sheetData) => {
    let managerString = sheetData?.members
      ?.filter(
        (member) =>
          member?.authRole?.toLowerCase() ===
          AuthRole.LOCATION_OWNER?.toLowerCase()
      )
      ?.map((filteredData) => filteredData.name)
      ?.join(', ');
    locationOrgData.push({
      location: sheetData.name,
      addedOn: moment(sheetData.createdAt).format('DD-MM-YYYY, hh:mm:ss A'),
      manager: managerString,
    });
  });

  locationOrgData.forEach((order, index) => {
    Object.entries(order).forEach((val, orderIndex) => {
      let string = generateCharacterFromNumber(orderIndex + 1) + `${index + 2}`;
      sheetArg.cell(string).value(val[1]);
    });
  });
  return sheetArg;
};

interface ReportType {
  value: 'Locations' | 'Members';
}

export const createSheetsHandler = async <T = never,>(
  selectedUsers: T[],
  selectedReportType: ReportType,
  locationData?: ILocation,
  _locationList?: ILocation[],
  _fieldsData?: FieldType[]
): Promise<void> => {
  let type: 'location' | 'team' | undefined;

  if (selectedReportType.value === 'Locations') {
    type = 'location';
  }
  if (selectedReportType.value === 'Members') {
    type = 'team';
  }

  if (type) {
    let filteredArray;
    if (type === 'team') {
      filteredArray = filterUsersBasedOnLocation(selectedUsers);
    } else {
      filteredArray = (selectedUsers as ILocation[]).map((user) => {
        return {
          location: user?.name,
          users: user?.members,
        };
      });
    }

    let combinedSheets: any = [];
    let combinedSheetsArray: Array<{
      location: string;
      res: ReturnType<xlsxPopulate.Workbook['outputAsync']>;
    }> = [];

    for (let i in filteredArray) {
      let workbook = await xlsxPopulate.fromBlankAsync();
      let sheet = await workbook.sheet(0);
      let addedSheet = await addHeadersToSheet(sheet);
      sheet = await addDataToSheet(addedSheet, filteredArray[i]);
      combinedSheets.push(sheet);
      let res = await workbook.outputAsync();
      combinedSheetsArray.push({
        location: filteredArray[i].location,
        res: res,
      });
    }
    if (type === 'location') {
      let workbook = await xlsxPopulate.fromBlankAsync();
      let sheet = await workbook.sheet(0);
      let addedSheet = await addHeadersToSheetLocationOrg(sheet);
      sheet = await addDataToSheetLocationOrg(
        addedSheet,
        filteredArray,
        selectedUsers
      );
      combinedSheets.push(sheet);
      let res = await workbook.outputAsync();
      combinedSheetsArray.push({
        location: 'Location Organization Report',
        res: res,
      });

      let locationDetailWorkbook = generateLocationsDetailSheet(
        _fieldsData || [],
        _locationList || []
      );
      let locDetailRes = await (await locationDetailWorkbook).outputAsync();
      combinedSheetsArray.push({
        location: 'Location Detail',
        res: locDetailRes,
      });
    }

    const zip = require('jszip')();
    for (let i of combinedSheetsArray) {
      zip.file(`${i.location}.xlsx`, i.res);
    }
    zip.generateAsync({ type: 'blob' }).then(function (content: string | Blob) {
      saveAs(
        content,
        `${
          type === 'location' ? 'Locations' : 'Team Members'
        }_${moment().format('YYYY_M_D')}.zip`
      );
    });
  }
};
