import { useRef, FC } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { SelectViewButton, SelectViewButtonProps } from './components';
import { generateValidId } from './helper';

interface NavbarProps extends SelectViewButtonProps {
  setSelectedSection: any;
  selectedSection: string;
  mappedQuestionData: any;
}

const navItemSelectionStyle = {
  textDecoration: 'underline',
  textDecorationColor: '#2A85FF',
  textDecorationThickness: '3px',
  textUnderlineOffset: '12px',
  fontWeight: '500',
};

const Navbar: FC<NavbarProps> = ({
  selectedViewType,
  setSelectedViewType,
  setSelectedSection,
  selectedSection,
  mappedQuestionData,
}) => {
  const navbarComponentRef = useRef<HTMLDivElement>(null);
  const navbarScrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (item: string) => {
    const element = document.querySelector(`#${generateValidId(item)}`);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handleNavbarScroll = (scrollDirection: string) => {
    if (!navbarComponentRef.current) {
      return undefined;
    }
    if (scrollDirection === 'left') {
      navbarComponentRef.current.scrollLeft -= 100;
    } else if (scrollDirection === 'right') {
      navbarComponentRef.current.scrollLeft += 100;
    }
  };

  return (
    <Box
      zIndex={'1'}
      position={'sticky'}
      top={'-20px'}
      backgroundColor={'white'}
      marginBottom={'3vh'}
      userSelect={'none'}
      height={'8vh'}
    >
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        height={'110%'}
      >
        {selectedViewType === 'web' ? (
          <Flex
            ref={navbarScrollContainerRef}
            height={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {navbarComponentRef.current?.scrollWidth >
              navbarScrollContainerRef.current?.scrollWidth && (
              <Button
                backgroundColor={'#FCFCFC'}
                className='left-btn'
                onClick={() => handleNavbarScroll('left')}
              >
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
              </Button>
            )}

            <Flex
              ref={navbarComponentRef}
              style={{ scrollBehavior: 'smooth' }}
              className='scrollable-navbar-element'
              gap={4}
              maxWidth={'43vw'}
              alignItems={'center'}
              overflowX={'auto'}
              height={'100%'}
            >
              <Flex
                whiteSpace={'nowrap'}
                onClick={() => {
                  setSelectedSection('overview');
                  handleScroll('overview');
                }}
                cursor={'pointer'}
                backgroundColor={
                  selectedSection === 'overview' ? '#FCFCFC' : undefined
                }
              >
                <Text
                  id={`nav-overview`}
                  fontSize={'15px'}
                  style={
                    selectedSection === 'overview'
                      ? navItemSelectionStyle
                      : undefined
                  }
                >
                  {'Audits'}
                </Text>
              </Flex>

              {Object.values(mappedQuestionData).map((item: any) => {
                return item?.title === 'Media Summary' &&
                  item?.responses?.length <= 0 ? null : (
                  <Flex
                    whiteSpace={'nowrap'}
                    fontSize={'15px'}
                    onClick={() => {
                      setSelectedSection(item?.title);
                      handleScroll(item?.title);
                    }}
                    cursor={'pointer'}
                    key={item?.title}
                    backgroundColor={
                      selectedSection === item?.title ? '#FCFCFC' : undefined
                    }
                  >
                    <Text
                      id={`nav-${generateValidId(item?.title)}`}
                      style={
                        selectedSection === item?.title
                          ? navItemSelectionStyle
                          : undefined
                      }
                    >
                      {item?.title}
                    </Text>
                  </Flex>
                );
              })}
              <Flex />
            </Flex>
            {navbarComponentRef.current?.scrollWidth >
              navbarScrollContainerRef.current?.scrollWidth && (
              <Button
                backgroundColor={'#FCFCFC'}
                className='right-btn'
                onClick={() => handleNavbarScroll('right')}
              >
                <FontAwesomeIcon icon={faChevronRight as IconProp} />
              </Button>
            )}
          </Flex>
        ) : (
          <Box></Box>
        )}
        <Flex gap={2} alignItems={'center'}>
          <SelectViewButton
            setSelectedViewType={setSelectedViewType}
            selectedViewType={selectedViewType}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
