import moment from 'moment';
import { AmplitudeEvent, deployEvent } from '../../../shared';
import { IFormInput } from './add-location.types';
import { toArray } from '../../../utils';

export const deployBasicInfo = (values: IFormInput): void => {
  deployEvent(AmplitudeEvent.LAUNCHER_ADD_BASIC_INFO, {
    location_launcher_visit_funnel_id: 5,
    location_type: values.locationType,
    lifecycle_stage: values.locationStatus?.value,
    upload_current_license: !!values?.licenceDocument,
    location_name: values.locationName,
    entity_name: values.entityName,
    entity_type: values.entityType,
    country_of_formation: values.countryOfFormation,
    state_of_formation: values.stateOfFormation,
    tax_payer_id_provided: !!values.taxPayerId,
  });
};

export const deployLocationDetails = (values: IFormInput): void => {
  deployEvent(AmplitudeEvent.LAUNCHER_ADD_LOCATION_DETAILS, {
    location_launcher_visit_funnel_id: 5,
    address: values.address,
    zipcode: values.zipCode,
    country: values.country,
    city: values.city,
    state: values.state,
    timezone: values.timezone,
    email_address: values.locationEmail?.[0]?.email,
  });
};

export const deployProjectDetails = (values: IFormInput): void => {
  const checkListData = toArray(values.checkListData).reduce((acc, value) => {
    if (value.selected) {
      acc.push(value.category);
    }
    return acc;
  }, [] as string[]);

  deployEvent(AmplitudeEvent.LAUNCHER_ADD_PROJECT_DETAILS, {
    location_launcher_visit_funnel_id: 5,
    phases_to_complete: checkListData,
    estimated_go_live_date: moment(values.startDate).format('DD-MMM'),
  });
};

export const deployFinishDetails = (
  values: IFormInput,
  locationId: string
): void => {
  const checkListData = toArray(values.checkListData).reduce((acc, value) => {
    if (value.selected) {
      acc.push(value.category);
    }
    return acc;
  }, [] as string[]);

  let lo_count = 0;
  let LO: IFormInput['locationOwnerOne'] = {} as never;

  if (values.locationOwnerOne?.name) {
    lo_count += 1;
    LO = values.locationOwnerOne;
  }
  if (values.locationOwnerTwo?.name) {
    lo_count += 1;
    LO = LO ? LO : values.locationOwnerTwo;
  }

  deployEvent(AmplitudeEvent.LAUNCHER_ADD_FINISH, {
    location_launcher_visit_funnel_id: 5,
    total_owners_added: lo_count,
    name: LO?.name,
    job: LO?.job?.value,
    phone_number: LO?.phone,
    email: LO?.email,
    total_locations_added: 1,
    location_id: locationId,
    phases_to_complete: checkListData,
    estimated_go_live_date: moment(values.startDate).format('DD-MMM'),
    location_type: values.locationType,
    lifecycle_stage: values.locationStatus?.value,
  });
};
