import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClockThree, faRepeat } from '@fortawesome/pro-light-svg-icons';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Outliner from './Outliner';
import { AuditSessionEntity, ProcessData } from '../../../types';
import SectionFilter from './SectionFilter';
import { SelectOption } from 'atoms';
import { filterOptions } from './filter-options';
import AuditAssignee from './AuditAssignee';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import AuditCard from './AuditCard';

interface IProps {
  data: ProcessData[];
  refetchHandler?: () => void;
}

const Overdue: FC<IProps> = ({ data, refetchHandler }) => {
  const entityUsers = useReactiveVar(usersEntityObj);
  const history = useHistory();

  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | undefined
  >(undefined);

  const filteredOverdueData = useMemo(() => {
    const overdueData =
      data?.filter((item) => item.auditStatus === 'overdue') || [];

    if (!selectedFilter?.value) {
      return overdueData;
    }

    let sortedData = [...overdueData];

    switch (selectedFilter.value) {
      case 'dueDateFirst':
        sortedData.sort((a, b) => {
          const today = moment();

          const dueDateA = a.dueDate ? moment.utc(a.dueDate) : null;
          const dueDateB = b.dueDate ? moment.utc(b.dueDate) : null;

          const diffA = dueDateA ? Math.abs(dueDateA.diff(today)) : Infinity;
          const diffB = dueDateB ? Math.abs(dueDateB.diff(today)) : Infinity;

          return diffA - diffB;
        });
        break;
      case 'nameDesc':
        sortedData.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'nameAsc':
        sortedData.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'frequency':
        // Implement sorting by frequency if needed
        break;
      default:
        break;
    }

    return sortedData;
  }, [data, selectedFilter]);

  const handleFilterChange = (filter: SelectOption) => {
    setSelectedFilter((prevVal) =>
      prevVal?.value === filter.value ? undefined : filter
    );
  };

  const missedLocations = (assignments: AuditSessionEntity['assignments']) => {
    const count =
      assignments?.filter((assignment) => !assignment?.isCompleted)?.length ||
      0;
    return `${count} location${count === 1 ? '' : 's'} missed`;
  };

  // Pluralization helper
  const pluralize = (
    count: number,
    singular: string,
    plural?: string
  ): string => {
    if (count === 1) return singular;
    return plural || `${singular}s`;
  };

  // Helper function to get expired duration with correct pluralization
  const getExpiredDuration = (dueDate: string): string => {
    const now = moment();
    const expiredTime = moment.utc(dueDate);
    const diffSeconds = now.diff(expiredTime, 'seconds');
    const diffMinutes = now.diff(expiredTime, 'minutes');
    const diffHours = now.diff(expiredTime, 'hours');
    const diffDays = now.diff(expiredTime, 'days');
    const diffMonths = now.diff(expiredTime, 'months');
    const diffYears = now.diff(expiredTime, 'years');

    if (diffSeconds < 45) {
      return 'Expired a few seconds ago';
    } else if (diffSeconds < 90) {
      return 'Expired 1 minute ago';
    } else if (diffMinutes < 45) {
      return `Expired ${diffMinutes} ${pluralize(diffMinutes, 'minute')} ago`;
    } else if (diffMinutes < 90) {
      return 'Expired 1 hour ago';
    } else if (diffHours < 22) {
      return `Expired ${diffHours} ${pluralize(diffHours, 'hour')} ago`;
    } else if (diffHours < 36) {
      return 'Expired 1 day ago';
    } else if (diffDays < 25) {
      return `Expired ${diffDays} ${pluralize(diffDays, 'day')} ago`;
    } else if (diffDays < 45) {
      return 'Expired 1 month ago';
    } else if (diffMonths < 12) {
      return `Expired ${diffMonths} ${pluralize(diffMonths, 'month')} ago`;
    } else if (diffMonths < 18) {
      return 'Expired 1 year ago';
    } else {
      return `Expired ${diffYears} ${pluralize(diffYears, 'year')} ago`;
    }
  };

  const repeatString = useCallback((overdue: ProcessData) => {
    let str = '';
    if (moment(overdue.dueDate)?.isValid()) {
      return getExpiredDuration(overdue.dueDate);
    } else {
      return 'No Deadline';
    }
  }, []);

  const titleRender = (overdue: ProcessData) => {
    return (
      <Tooltip label={overdue?.title} hasArrow placement='top'>
        <Text fontWeight={600} isTruncated maxW='190px'>
          {overdue.title}
        </Text>
      </Tooltip>
    );
  };

  const scheduleRender = (overdue: ProcessData) => {
    return (
      <Flex align='center' gap='2px' color='#4D4D4D' fontSize='10px'>
        <Tooltip label={repeatString(overdue)} hasArrow>
          <Flex cursor='pointer' align='center' gap='2px'>
            <FontAwesomeIcon icon={faClockThree as IconProp} />
            <Text isTruncated maxW='100px'>
              {repeatString(overdue)}
            </Text>
          </Flex>
        </Tooltip>
        <>
          <Box>&bull;</Box>
          <FontAwesomeIcon icon={faRepeat as IconProp} />
          <Tooltip label={overdue.repeatStatus} hasArrow placement='top'>
            <Text textTransform='capitalize' isTruncated maxW='80px'>
              {overdue.repeatStatus || 'Does not repeat'}
            </Text>
          </Tooltip>
        </>
      </Flex>
    );
  };

  const footerRender = (overdue: ProcessData) => {
    return (
      <Flex align='center' justify='space-between'>
        <Flex
          color='#FF6A55'
          fontWeight={600}
          fontSize='12px'
          align='center'
          gap='5px'
        >
          <FontAwesomeIcon icon={faLocationDot as IconProp} />
          <Box>{missedLocations(overdue.assignments)}</Box>
        </Flex>
        <AuditAssignee
          assignees={entityUsers?.filter((user) =>
            overdue?.auditors?.some((auditor) => auditor?.eid === user?.eid)
          )}
        />
      </Flex>
    );
  };

  return (
    <Flex flexDir='column' gap={4} minW='244px' maxW='244px'>
      <Outliner bg='rgba(255, 188, 153, 1)'>
        <Flex align='center'>
          <Text fontWeight={600} fontSize='14px'>
            Overdue
          </Text>
          &nbsp;
          <Text fontWeight={600} fontSize='14px'>
            ({filteredOverdueData.length})
          </Text>
        </Flex>
        {filteredOverdueData.length > 0 && (
          <SectionFilter
            options={filterOptions}
            value={selectedFilter}
            handleOptionClick={handleFilterChange}
          />
        )}
      </Outliner>
      {filteredOverdueData.map((overdue) => (
        <AuditCard
          key={overdue?.eid}
          auditData={overdue}
          auditTitle={titleRender(overdue)}
          scheduleDetails={scheduleRender(overdue)}
          footerDetails={footerRender(overdue)}
          refetchHandler={refetchHandler}
        />
      ))}
    </Flex>
  );
};

Overdue.displayName =
  'sub-components/audits/audit-tab/supervised/components/GridView/components/Overdue';

export default Overdue;
