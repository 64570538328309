import React, { FC, useMemo } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';
import { TFuncKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Dropdown, SelectOption } from 'atoms';
import { IFormInput } from '../../../audit-schedule.types';
import Weekly from './Weekly';
import Monthly from './Monthly';
import Yearly from './Yearly';
import { Custom } from './custom';

interface IOption {
  label: TFuncKey<'task', undefined>;
  value: string;
}

const options: IOption[] = [
  { label: 'weekly', value: 'weekly' },
  { label: 'monthly', value: 'monthly' },
  { label: 'yearly', value: 'yearly' },
  { label: 'custom', value: 'custom' },
];

const toValue = (value?: string): SelectOption | undefined =>
  value ? { value: value, label: value } : undefined;

const MultipleTime: FC = () => {
  const { t } = useTranslation('task');
  const { resetField, getValues } = useFormContext<IFormInput>();
  const repeatCycle = useWatch<IFormInput, 'repeatDetails.repeatType'>({
    name: 'repeatDetails.repeatType',
  });

  const repeatSelection = useMemo(() => {
    switch (repeatCycle) {
      case 'weekly':
        return <Weekly />;
      case 'monthly':
        return <Monthly />;
      case 'yearly':
        return <Yearly />;
      case 'custom':
        return <Custom />;
      default:
        return null;
    }
  }, [repeatCycle]);

  const resetFields = (value: IFormInput['repeatDetails']['repeatType']) => {
    switch (value) {
      case 'weekly':
        resetField('repeatDetails.monthly');
        resetField('repeatDetails.yearly');
        resetField('repeatDetails.customRepeatValue');
        resetField('repeatDetails.customRepeatType');
        resetField('repeatDetails.customRepeatWeek');
        resetField('repeatDetails.customRepeatMonth');
        break;
      case 'monthly':
        resetField('repeatDetails.weekly');
        resetField('repeatDetails.yearly');
        resetField('repeatDetails.customRepeatValue');
        resetField('repeatDetails.customRepeatType');
        resetField('repeatDetails.customRepeatWeek');
        resetField('repeatDetails.customRepeatMonth');
        break;
      case 'yearly':
        resetField('repeatDetails.weekly');
        resetField('repeatDetails.monthly');
        resetField('repeatDetails.customRepeatValue');
        resetField('repeatDetails.customRepeatType');
        resetField('repeatDetails.customRepeatWeek');
        resetField('repeatDetails.customRepeatMonth');
        break;
      case 'custom':
        if (!getValues('repeatDetails.customRepeatType')) {
          resetField('repeatDetails.customRepeatValue', { defaultValue: 2 });
          resetField('repeatDetails.customRepeatType', {
            defaultValue: 'days',
          });
        }
        resetField('repeatDetails.weekly');
        resetField('repeatDetails.monthly');
        resetField('repeatDetails.yearly');
        break;
    }
  };

  return (
    <Flex flexDir={repeatCycle === 'yearly' ? 'row' : 'column'} gap={4}>
      <Controller<IFormInput, 'repeatDetails.repeatType'>
        name='repeatDetails.repeatType'
        rules={{
          required: 'Repeat cycle required',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl width='unset' isInvalid={fieldState.invalid}>
              <Flex gap={4} align='center'>
                <Box
                  as='span'
                  fontWeight={600}
                  color='#33383F'
                  whiteSpace='nowrap'
                >
                  When does it repeat?
                </Box>
                <Dropdown
                  size='lg'
                  options={options}
                  isSearchable={false}
                  value={toValue(field.value)}
                  isDisabled={field.disabled}
                  onChange={(newValue) =>
                    callAll(field.onChange, resetFields)(newValue.value)
                  }
                  getOptionLabel={(option) => t(option.label)}
                  selectStyles={{
                    container: { minWidth: '150px' },
                    menuList: {
                      minWidth: '100%',
                    },
                    singleValue: {
                      fontSize: '14px',
                      fontWeight: '500',
                    },
                    placeholder: {
                      fontSize: '14px',
                    },
                  }}
                  inputStyle={{
                    borderWidth: '2px',
                    borderRadius: '12px',
                    outline: 'none',
                    backgroundColor: 'white',
                  }}
                />
              </Flex>
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
      {repeatSelection}
    </Flex>
  );
};

export default MultipleTime;
