import React, { FC, useCallback, useMemo } from 'react';
import { Center, Flex, Text, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';

import { ActionMenu, IActionMenuData } from 'ui-components';
import EmptyState from 'sub-components/EmptyState';

import { Column, SortingTable } from 'sub-components/ChakraTable/SortingTable';
import { IFilesEntity } from 'pages/LocationDetails/utils/location-details.types';
import { UPDATE_FILES } from 'pages/LocationDetails/service/location-details.graphql';

import DocumentFileName from './DocumentFileName';
import DocumentUploadedBy from './DocumentUploadedBy';
// import { ReactComponent as EditIcon } from '../../../../../../../assets/images/editIcon.svg';
import EditIcon from '../../../../../../../assets/images/editIcon.svg';
import DownloadIcon from '../../../../../../../assets/images/downloadIcon.svg';
import DeleteIcon from '../../../../../../../assets/images/trashRed.svg';

const Wrapper = styled.div`
  width: 100%;
  table {
    background-color: white;
  }
`;

interface IProps {
  documents?: IFilesEntity[];
  locationId: string;
  refetchHandler: () => void;
  onEditFileClickHandler: (index: number) => void;
}

const DocumentListing: FC<IProps> = ({
  documents,
  locationId,
  refetchHandler,
  onEditFileClickHandler,
}) => {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const [updateFiles, { loading }] = useMutation(UPDATE_FILES, {
    onCompleted: () => {
      toast({
        title: 'File deleted successfully',
        status: 'success',
      });
      refetchHandler();
    },
    onError: () => {
      toast({
        title: 'File could not be deleted',
        status: 'error',
      });
      refetchHandler();
    },
  });

  const getMenuData = useCallback((menuData) => {
    const menuList: Array<IActionMenuData> = [
      {
        name: 'Edit',
        value: 'edit',
        icon: EditIcon,
      },
      {
        name: 'Download',
        value: 'download',
        icon: DownloadIcon,
      },
      {
        name: 'Delete',
        value: 'delete',
        icon: DeleteIcon,
        textColor: '#FF6A55',
      },
    ];

    return menuList;
  }, []);

  const onClickHandler = useCallback(
    (item: string, data: IFilesEntity, index: number) => {
      if (item === 'edit') {
        onEditFileClickHandler(index);
      } else if (item === 'download') {
        window.open(data?.url, '_blank');
      } else if (item === 'delete') {
        const updatedFiles: IFilesEntity[] | undefined = JSON.parse(
          JSON.stringify(documents)
        );
        if (!Array.isArray(updatedFiles) || updatedFiles?.length === 0) return;
        console.log('updated files initial : ', updatedFiles);
        console.log('index : ', index);
        updatedFiles.splice(index, 1);
        console.log('UPDATED ARRAAY : : : : : ', updatedFiles);
        let _updatedFiles = updatedFiles?.map((file) => {
          return {
            createdBy: file?.createdBy?.eid,
            fileSize: file?.fileSize,
            mimetype: file?.mimetype,
            name: file?.name,
            type: file?.type,
            url: file?.url,
          };
        });
        console.log('UPDATED FILES : : : : : ', _updatedFiles);
        updateFiles({
          variables: {
            input: {
              eid: locationId,
              files: [..._updatedFiles],
            },
          },
        });
      }
    },
    [documents]
  );

  const columns = useMemo((): Column<IFilesEntity>[] => {
    return [
      {
        accessor: 'name',
        id: 'fileDetails',
        width: '40%',
        Header: () => <Text>Name</Text>,
        Cell: ({ cell: { value, row } }) => {
          return <DocumentFileName details={row?.original} />;
        },
        disableSortBy: true,
      },
      {
        Header: () => <Text>Uploaded by</Text>,
        accessor: 'createdBy',
        width: '25%',
        Cell: ({ cell: { value, row } }) => {
          return <DocumentUploadedBy details={row?.original} />;
        },
        disableSortBy: true,
      },
      {
        Header: () => <Text>Last modified</Text>,
        accessor: 'updatedAt',
        width: '25%',
        Cell: ({ cell: { value } }) => {
          return <Text>{moment(value)?.format('LL')}</Text>;
        },
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'url',
        width: '10%',
        disableSortBy: true,
        Cell: ({ cell: { value, row } }) => {
          return (
            <ActionMenu
              menuData={getMenuData(row.original)}
              arrowSize={10}
              closeOnBlur={true}
              offset={[5, 5]}
              clickedItem={(_, clickedItem) =>
                onClickHandler(clickedItem, row?.original, row?.index)
              }
              //   clickedItem={(_, clickedItem) => {
              //     return onClickedHandler?.(clickedItem, row.original);
              //   }}
            >
              <Flex justify='center' cursor='pointer'>
                <FontAwesomeIcon
                  icon={faEllipsis as IconProp}
                  size='2x'
                  color='#6F767E'
                />
              </Flex>
            </ActionMenu>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Wrapper>
        <SortingTable
          colorScheme='blue'
          page={1}
          columns={columns}
          data={documents || []}
          isResponsive
          isHidePagination
          emptyData={{
            content: (
              <Center id='document-listing-empty' w='full'>
                <EmptyState
                  title='No Document uploaded yet'
                  description='Click on Add documents to upload documents'
                  image='Location'
                />
              </Center>
            ),
          }}
        />
      </Wrapper>
    </>
  );
};

export default DocumentListing;
