import React, { FC } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep } from '@apollo/client/utilities';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../atoms';
import { HeaderColors } from '../../../configs';
import { BoxHeader } from '../../../ui-components';
import { useHistory } from '../../../routes';
import { deepEqual } from '../../../utils';

import { IFormInput } from '../create-audit/form-types';
import { getDefaultValues } from '../create-audit/audit-default.helper';
import PreviewContainer from '../create-audit/PreviewContainer';
import AuditFormLoader from '../create-audit/AuditFormLoader';
import {
  AuditContentSection,
  AuditTemplateInfo,
} from '../create-audit/component';
import { validationSchema } from './validation';
import EditQuestionHeader from './EditQuestionHeader';
import { editedQuestionData } from './edit-question.helper';
import {
  AUDIT_QUESTION,
  AUDIT_QUESTION_UPDATE,
  AuditQuestionResponse,
  UpdateQuestionVariable,
} from './edit-question.graphql';

interface Params {
  auditId: string;
}

interface IProps {}

const EditQuestions: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'audit']);
  const client = useApolloClient();
  const params = useParams<Params>();
  const history = useHistory();

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const methods = useForm<IFormInput>({
    resolver: yupResolver(validationSchema as never),
    // shouldFocusError: false,
    defaultValues: async () => {
      const res = await client.query<AuditQuestionResponse>({
        query: AUDIT_QUESTION,
        fetchPolicy: 'network-only',
        variables: {
          eid: params.auditId,
        },
      });

      const auditData = res?.data?.AuditById;

      if (auditData?.status === 'AUDIT_DELETED') {
        history.goBack();
        toast({
          status: 'warning',
          title: t('common:warning'),
          description: 'The requested audit has been deleted.',
        });
        return Promise.reject('This audit is deleted');
      }

      return getDefaultValues(auditData as never, true);
    },
  });

  const [updateAuditQuestion] = useMutation(AUDIT_QUESTION_UPDATE, {
    onError: (err: Error) => {
      // eslint-disable-next-line no-console
      console.log(err.message);

      toast({
        status: 'error',
        title: t('common:error'),
        description: t('audit:questionUpdateFailed'),
      });
    },
  });

  const onFinish = async (values: IFormInput) => {
    const _values = cloneDeep(values);

    const isEqual = deepEqual(
      _values.questions,
      methods.control._defaultValues?.questions
    );

    const _inputs: UpdateQuestionVariable = {
      eid: params.auditId,
      title: _values.title,
      description: _values.description,
    };

    if (!isEqual) {
      _inputs['questions'] = editedQuestionData(_values.questions);
    }

    const res = await updateAuditQuestion({
      variables: { input: _inputs },
    });

    if (res.data) {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('audit:questionUpdateSuccess'),
      });

      setTimeout(history.goBack);
    }
  };

  return (
    <FormProvider {...methods}>
      <EditQuestionHeader onUpdateClick={methods.handleSubmit(onFinish)} />

      <Flex
        flexDir='column'
        position='relative'
        w='full'
        mt='30px'
        bg='white'
        borderRadius='8px'
      >
        <Flex gap={2} paddingX='24px' paddingTop='24px' paddingBottom='12px'>
          <Flex flex={1}>
            <BoxHeader
              fontSize='18px'
              color={HeaderColors.Purple}
              title={t('audit:templateDetails')}
            />
          </Flex>
          <Flex width='300px' justify='space-between'>
            <BoxHeader
              fontSize='18px'
              color={HeaderColors.Blue}
              title={t('common:preview')}
            />

            <Controller
              control={methods.control}
              name='previewHidden'
              render={({ field }) => {
                return (
                  <Button
                    fontSize='13px'
                    variant='outline'
                    onClick={() => field.onChange(!field.value)}
                  >
                    {t(
                      field?.value ? 'audit:unHidePreview' : 'audit:hidePreview'
                    )}
                  </Button>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex as='form' w='full' align='start' gap={2}>
          <Flex
            flexDir='column'
            gap={6}
            w='full'
            paddingX='24px'
            paddingBottom='24px'
            paddingTop='12px'
          >
            <AuditTemplateInfo />
            {/*<AuditTemplateType />*/}
            <AuditContentSection />
          </Flex>

          <PreviewContainer />

          <AuditFormLoader />
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default EditQuestions;
