import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../atoms';
import { BoxHeader } from '../../../ui-components';
import { AppRoute, useRouteState } from '../../../routes';
import { HeaderColors } from '../../../configs';

import BaseLayoutWrapper from '../../BaseLayoutWrapper';
import AuditTabs from '../audit-tab/AuditTabs';
import SupervisedHome from './components/supervised-home';
import MyAuditsHome from './components/my-audits-home';
import ArchivedHome from './components/archived-home';

interface IProps {}

const AuditHomeAdmin: FC<IProps> = () => {
  const history = useHistory();

  const { state } = useRouteState('audit', {
    selectedTab: 'supervisedByMe',
  });

  const onViewAll = () => {
    sessionStorage.setItem('auditInitialTab', state.selectedTab);
    history.push(AppRoute.AUDIT_LIST);
  };

  return (
    <BaseLayoutWrapper p={5} flexDir='column' gap={4} w='full'>
      <Flex align='center' justify='space-between' w='full'>
        <BoxHeader title='Audits' fontSize='18px' color={HeaderColors.Green} />
        <Button variant='outline' onClick={onViewAll}>
          View all
        </Button>
      </Flex>
      <AuditTabs />
      <Flex flexDir='column'>
        {state?.selectedTab === 'supervisedByMe' ? (
          <SupervisedHome />
        ) : state?.selectedTab === 'myAudits' ? (
          <MyAuditsHome />
        ) : (
          <ArchivedHome />
        )}
      </Flex>
    </BaseLayoutWrapper>
  );
};

export default AuditHomeAdmin;
