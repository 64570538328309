import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { SubItem } from '../category/SidebarItemHandbook';

import { ReactComponent as CheckCircleIcon } from '../../../assets/images/sidebar/task.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/images/cheveronIcon.svg';
import { Text } from '@chakra-ui/react';
import {
  AmplitudeEventNames,
  IAmplitudeEventNamesEntity,
} from 'shared/amplitudeEvents/amplitude-events-types';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

interface IProps {
  isSelected: boolean;
  isExpand: boolean;
}

const SidebarTasks: FC<IProps> = ({ isSelected, isExpand }) => {
  const { t } = useTranslation(['sidebar', 'task']);
  const userData = useReactiveVar(userObj);
  const history = useHistory();

  const routeMatch = useRouteMatch<{ taskType: string }>();

  const selectedType = routeMatch?.params?.taskType;

  const [isExpanded, setIsExpanded] = useState(!!selectedType || isExpand);

  const ItemData = useMemo(() => {
    return [
      {
        route: 'my-tasks',
        transKey: 'task:myTasks',
      },
      {
        route: 'supervised',
        transKey: 'task:supervisedByMe',
      },
    ];
  }, [userData]);

  let className = 'sidebar-item-container';
  if (isSelected) {
    className = `sidebar-item-container selected`;
  }

  const onExpandStateChange = () => {
    setIsExpanded((s) => !s);
    deployEvent(`${AmplitudeEventNames.NAVIGATE_ROUTE}: /tasks/dashboard`);
    history.push('/tasks/dashboard');
  };

  const onClickNavigationHandler = (routeLink: string) => {
    let eventName: IAmplitudeEventNamesEntity = `${AmplitudeEventNames.NAVIGATE_ROUTE}: ${routeLink}`;
    // deployEvent(eventName);

    if (routeLink.toLowerCase().includes('my-tasks')) {
      //my tasks
      deployEvent(CommonAmplitudeEvents.MY_TASKS_TAB);
    } else if (routeLink.toLowerCase().includes('supervised')) {
      //supervised by me
      deployEvent(CommonAmplitudeEvents.SUPERVISED_BY_ME_TAB);
    }
  };

  return (
    <>
      <div className={className} onClick={onExpandStateChange}>
        <CheckCircleIcon />
        <div
          className='sidebar-item-title-container task-container'
          // style={{
          //   display: 'flex',
          //   alignItems: 'center',
          //   gap: '10px',
          //   flexDirection: 'row',
          // }}
        >
          {t('sidebar:tasks')}
          {/* <Text
            bg='#A80505'
            color='white'
            fontWeight={600}
            fontSize='13px'
            borderRadius='4px'
            p='0px 6px'
          >
            beta
          </Text> */}
        </div>
        <div className='sidebar-item-menu-container'>
          <ChevronIcon />
        </div>
      </div>
      {isExpanded && (
        <div
          className='sidebar-item-list-container'
          style={{ marginBottom: '20px' }}
        >
          {ItemData.map((item, index: number) => {
            const getClassName = () => {
              if (selectedType === item?.route) {
                return `sidebar-item-list-item-container selected`;
              }
              return `sidebar-item-list-item-container`;
            };
            return (
              <Link
                to={{
                  pathname: `/tasks/${item?.route}`,
                  state: { from_where: 'Navigation Panel' },
                }}
                key={index}
                onClick={() =>
                  onClickNavigationHandler(`/tasks/${item?.route}`)
                }
              >
                <SubItem
                  isSelected={selectedType === item?.route}
                  title={t(item.transKey as never)}
                  className={getClassName()}
                  // hide={!(index + 1 < category.length)}
                />
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SidebarTasks;
