import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { QuestionsState } from '../../types';

interface IProps {
  options: QuestionsState['options'];
  value: string[];
  onChange: (value: string[]) => void;
  isFlagged?: boolean;
}

const MultiChoice: FC<IProps> = ({ options, value, onChange, isFlagged }) => {
  const _options = useMemo(() => {
    return options?.filter((opt) => !opt.isDisabled);
  }, [options]);

  return (
    <Flex gap={3} flexDir='column'>
      {_options?.map((option) => {
        const isSelected = value?.[0] === option?.eid;
        return (
          <React.Fragment key={option.eid}>
            <Flex
              key={option?.eid}
              cursor='pointer'
              border='2px solid #EFEFEF'
              borderRadius='12px'
              p='12px 20px'
              fontWeight={500}
              color='#6F767E'
              onClick={() => {
                if (isSelected) {
                  return onChange([]);
                } else {
                  return onChange([option?.eid]);
                }
              }}
              aria-selected={isSelected}
              _selected={{
                color: '#FFFFFF',
                bg: '#2A85FF',
                borderColor: '#2A85FF',
              }}
            >
              {option?.label}
            </Flex>

            {isSelected && isFlagged && (
              <Flex
                width='max-content'
                align='center'
                height='28px'
                px='10px'
                bg='#FF6A55'
                borderRadius='6px'
                fontWeight={500}
                color='#FFFFFF'
              >
                Item Flagged
              </Flex>
            )}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default MultiChoice;
