import React, { FC, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import {
  Controller,
  FieldErrors,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import differenceBy from 'lodash/differenceBy';
import { useTranslation } from 'react-i18next';

import { Button } from 'atoms';
import { AuditHelper, HeaderColors } from 'configs';
import { deepEqual, toArray } from 'utils';
import { BoxHeader } from '../../../ui-components';

import { IFormInput, OptionWhiteList } from './form-types';
import {
  AuditContentSection,
  AuditTemplateInfo,
  AuditTemplateType,
} from './component';
import { validationSchema } from './validation';
import {
  AUDIT_GRAPHQL_QUERY,
  AuditTemplateResponse,
  UPDATE_AUDIT_GRAPHQL,
  UpdateAuditInput,
  UpdateAuditResponse,
} from './audit-create.graphql';
import { createAuditData } from './data-helper';
import { getDefaultValues } from './audit-default.helper';
import { AuditInput } from './audit.types';
import { getQuestionDiff } from './question-diff.helper';
import AuditFormLoader from './AuditFormLoader';
import CreateAuditHeader from './CreateAuditHeader';
import PreviewContainer from './PreviewContainer';

interface LocationState {
  data?: {
    eid: string;
  };
}

interface Params {
  templateId: string;
}

const AuditCreation: FC = () => {
  const { t } = useTranslation(['common', 'audit']);
  const client = useApolloClient();
  const history = useHistory();
  const state = useLocation<LocationState>().state;
  const params = useParams<Params>();
  const submittingRef = useRef(false);

  const inputsRef = useRef<AuditInput>();

  const methods = useForm<IFormInput>({
    resolver: yupResolver(validationSchema as never),
    shouldFocusError: false,
    defaultValues: async () => {
      if (state?.data) {
        history.replace(history.location.pathname, {
          ...state,
          data: undefined,
        });
        return Promise.resolve(state.data as IFormInput);
      }
      const res = await client.query<AuditTemplateResponse>({
        query: AUDIT_GRAPHQL_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          eid: params?.templateId,
        },
      });
      return getDefaultValues(res.data?.AuditTemplateById);
    },
  });

  const [updateAuditTemplate] = useMutation<
    UpdateAuditResponse,
    UpdateAuditInput
  >(UPDATE_AUDIT_GRAPHQL, {
    onError: (err: Error) => {
      // eslint-disable-next-line no-console
      console.log(err.message);
    },
  });

  const onFinish = async (values: IFormInput) => {
    // eslint-disable-next-line no-console
    console.log('Template saving');

    const inputs = createAuditData(values);

    if (deepEqual(inputs, inputsRef.current) || submittingRef.current) {
      // eslint-disable-next-line no-console
      console.log('Audit Saving Skipped');
      return;
    }

    submittingRef.current = true;

    const res = await updateAuditTemplate({
      variables: { input: inputs },
    });

    if (res.data) {
      // eslint-disable-next-line no-console
      console.log('Template saved');
      // const _questions = getDefaultValues(
      //   res.data.UpdateAuditTemplate
      // ).questions;
      inputsRef.current = inputs;
      //
      // const diff = getQuestionDiff(_questions, inputs.questions);
      //
      // diff.forEach((value) => {
      //   const index = _questions.findIndex((q) => q.eid === value.eid);
      //   console.log(index, 'getQuestionDiff');
      //   if (index >= 0) {
      //     methods.setValue(`questions.${index}.eid`, value.eid);
      //
      //     if (OptionWhiteList.indexOf(value.qType)) {
      //       const _options = methods.getValues(`questions.${index}.options`);
      //
      //       const optionsDiff = differenceBy(
      //         value.options,
      //         toArray(_options),
      //         'eid'
      //       );
      //
      //       optionsDiff.forEach((option) => {
      //         const optIndex = value.options!.findIndex(
      //           (o) => o.eid === option.eid
      //         );
      //
      //         methods.setValue(
      //           `questions.${index}.options.${optIndex}.eid`,
      //           option.eid
      //         );
      //       });
      //     }
      //   }
      // });
    }

    submittingRef.current = false;
  };

  // eslint-disable-next-line no-console
  const onError = (error: FieldErrors<IFormInput>) => console.log(error);

  useEffect(() => {
    const subscribe = AuditHelper.onSave(
      methods.handleSubmit(onFinish, onError)
    );
    return () => subscribe();
  }, []);

  return (
    <FormProvider {...methods}>
      <CreateAuditHeader onFinishHandler={onFinish} />

      <Flex
        flexDir='column'
        position='relative'
        w='full'
        mt='30px'
        bg='white'
        borderRadius='8px'
      >
        <Flex gap={2} paddingX='24px' paddingTop='24px' paddingBottom='12px'>
          <Flex flex={1}>
            <BoxHeader
              fontSize='18px'
              color={HeaderColors.Purple}
              title={t('audit:templateDetails')}
            />
          </Flex>
          <Flex width='300px' justify='space-between'>
            <BoxHeader
              fontSize='18px'
              color={HeaderColors.Blue}
              title={t('common:preview')}
            />

            <Controller
              control={methods.control}
              name='previewHidden'
              render={({ field }) => {
                return (
                  <Button
                    fontSize='13px'
                    variant='outline'
                    onClick={() => field.onChange(!field.value)}
                  >
                    {t(
                      field?.value ? 'audit:unHidePreview' : 'audit:hidePreview'
                    )}
                  </Button>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex
          as='form'
          w='full'
          align='start'
          gap={2}
          onSubmit={methods.handleSubmit(onFinish)}
          // onBlur={methods.handleSubmit(onFinish, onError)}
        >
          <Flex
            flexDir='column'
            gap={6}
            w='full'
            paddingX='24px'
            paddingBottom='24px'
            paddingTop='12px'
          >
            <AuditTemplateInfo />
            <AuditTemplateType />
            <AuditContentSection />
          </Flex>

          <PreviewContainer />

          <AuditFormLoader />
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default AuditCreation;
