import { SyntheticEvent } from 'react';
import { eventBus } from '../shared/eventEmit';

const AUDIT_SAVE_EVENT = '@@delightree-audit-save';

type AuditSave = ((event?: SyntheticEvent) => void) | undefined;

export class AuditHelper {
  static onSave(handleSubmit: { (): Promise<void> }): () => void {
    return eventBus.on(AUDIT_SAVE_EVENT, handleSubmit);
  }
  static save(callbackOrEvent?: Function | SyntheticEvent): AuditSave {
    if (typeof callbackOrEvent === 'function') {
      return function (event?: SyntheticEvent) {
        callbackOrEvent(event);
        eventBus.emit(AUDIT_SAVE_EVENT);
      };
    }
    eventBus.emit(AUDIT_SAVE_EVENT);
  }
}
