import React, { FC, useMemo, useState } from 'react';
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import PrimaryButton from 'atoms/PrimaryButton';
import { useUploadImage } from 'hooks';
import { PushProps } from './types';
import DragDropComponent from './common/DragDropComponent';

import { FileItem } from './common';
import { FileDataEntity } from './create-chapter.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const FooterText = () => {
  const { t } = useTranslation(['chapter']);

  return (
    <Box textAlign='center'>
      <Text fontWeight={500} fontSize='14px' color='#6F767E'>
        {t('chapter:currentlySupportOnlyPdf')}
      </Text>
      <Text fontWeight={500} fontSize='14px' color='#6F767E'>
        <Trans
          t={t}
          i18nKey='chapter:ifYouWantOtherFormat'
          components={{
            span: (
              <Button
                fontSize='14px'
                colorScheme='blue'
                variant='link'
                onClick={() => {
                  return window.open(
                    'https://smallpdf.com/pdf-converter',
                    '_blank'
                  );
                }}
              />
            ),
          }}
        />
      </Text>
    </Box>
  );
};

interface IProps extends PushProps {}

const MAX_FILE_SIZE = 5000000;

const ExtractFromPdf: FC<IProps> = ({ pushTo }) => {
  const { t } = useTranslation(['common', 'chapter']);
  const [file, setFile] = useState<FileDataEntity>();
  const [isUploading, setIsUploading] = useState(false);

  const [progress, setProgress] = useState(0);

  const uploadImage = useUploadImage();

  const onCreateChapter = async () => {
    deployEvent(AmplitudeEventNames.CREATE_CHAPTER_PDF, {
      add_chapter_funnel_id: 5,
    });
    try {
      setProgress(0);
      setIsUploading(true);
      const fileUrl = await uploadImage(file?.file!, undefined, setProgress);
      pushTo?.('/create-chapter?fileUrl=' + fileUrl);
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
    }
  };

  const onFileDrop = (fileLists: File[]) => {
    const newFile = fileLists?.[0];

    setFile({
      name: newFile?.name,
      fileSize: newFile?.size,
      type: newFile?.type,
      mimetype: newFile?.type,
      file: newFile,
    });
  };

  const isLargerFile = useMemo(() => {
    if (!file?.fileSize) {
      return false;
    }
    return file.fileSize > MAX_FILE_SIZE;
  }, [file]);

  if (!file) {
    return (
      <Center flexDir='column' gap={4}>
        <DragDropComponent
          subTitle={
            <Trans t={t} i18nKey='chapter:pdfMaxSizeMb' values={{ size: 5 }} />
          }
          accept={{ 'application/pdf': ['.pdf'] }}
          onFileDrop={onFileDrop}
        />

        <FooterText />
      </Center>
    );
  }

  return (
    <div>
      <Flex flexDir='column' gap={4} minH='240px'>
        <FileItem
          isUploading={isUploading}
          progress={progress}
          file={file}
          isLargerFile={isLargerFile}
          onRemove={() => setFile(undefined)}
        />

        {isLargerFile ? (
          <PrimaryButton
            title={t('chapter:removeAndUploadFromDevice')}
            variant='solid'
            colorScheme='blue'
            size='lg'
            onClick={() => setFile(undefined)}
          />
        ) : (
          <PrimaryButton
            title={t('chapter:createChapter')}
            variant='solid'
            colorScheme='blue'
            size='lg'
            // isLoading={isUploading}
            disabled={isUploading}
            onClick={onCreateChapter}
          />
        )}
      </Flex>

      <FooterText />
    </div>
  );
};

export default ExtractFromPdf;
