import { Flex, Text } from '@chakra-ui/react';
import React, { FC, useContext } from 'react';
import { TaskDetailSection } from 'sub-components/audits/task-detail';
import SupervisedTaskDetailsDataContext from '../../store/supervised-task-details-context';
import TaskOverviewSteps from './TaskOverviewSteps';

interface IProps {
  sessionId: string;
}

const TaskOverviewDetails: FC<IProps> = ({ sessionId }) => {
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  return (
    <Flex
      id='task-overview-details-flex-root'
      flexDir='column'
      gap='20px'
      w='full'
    >
      <Text fontWeight={600} fontSize='18px'>
        {supervisedCtx?.taskDetails?.title}
      </Text>
      <Text fontWeight={400} color='#6F767E'>
        {supervisedCtx?.taskDetails?.description || '-'}
      </Text>
      <TaskDetailSection
        auditId={supervisedCtx?.taskDetails?.lastSession?.task?.audit?.auditId}
        sessionId={
          supervisedCtx?.taskDetails?.lastSession?.task?.audit?.sessionId
        }
        flagged={supervisedCtx?.taskDetails?.lastSession?.task?.audit?.flagged}
        qid={supervisedCtx?.taskDetails?.lastSession?.task?.audit?.qid}
      />
      <TaskOverviewSteps sessionId={sessionId} />
    </Flex>
  );
};

TaskOverviewDetails.displayName = 'TaskDetails/C/TaskOverviewDetails';

export default TaskOverviewDetails;
