import React, { createContext, FC, useContext, useRef } from 'react';

import {
  AuditDetailAction,
  createAuditDetailStore,
} from './audit-details.store';

import { useStore } from '../../../../zustandStore';
import { useRouteState } from '../../../../routes';

type AuditDetailStore = ReturnType<typeof createAuditDetailStore>;

const AuditDetailContext = createContext<AuditDetailStore | null>(null);

const AuditDetailProvider: FC = ({ children }) => {
  const storeRef = useRef<AuditDetailStore>();

  const { state, updateState } = useRouteState({
    selectedTab: 'overview',
  });

  if (!storeRef.current) {
    storeRef.current = createAuditDetailStore({
      selectedTab: state.selectedTab as never,
    });
  }

  React.useEffect(() => {
    const subscription = storeRef.current?.subscribe((newState, prevState) => {
      if (newState.selectedTab !== prevState?.selectedTab) {
        updateState({ selectedTab: newState.selectedTab });
      }
    });
    return () => {
      subscription?.();
    };
  }, []);

  return (
    <AuditDetailContext.Provider value={storeRef.current}>
      {children}
    </AuditDetailContext.Provider>
  );
};

function useAuditDetailContext<T>(
  selector: (state: AuditDetailAction) => T
): T {
  const store = useContext(AuditDetailContext);
  if (!store)
    throw new Error('Missing AuditDetailContext.Provider in the tree');
  return useStore(store, selector);
}

export { AuditDetailProvider, useAuditDetailContext };
