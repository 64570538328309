import { useMemo } from 'react';
import { FetchPolicy, gql, useQuery } from '@apollo/client';
import { toArray } from '../../../../utils';
import { SelectOption } from '../../../../atoms';
import { useUserDataSelector } from '../../../../hooks';

interface AuditTemplate {
  items: Array<{
    eid: string;
    title: string;
  }>;
}

const TEMPLATE_QUERY = gql`
  query AuditTemplatePagination(
    $perPage: Int
    $filter: FilterFindManyAuditTemplateInput
  ) {
    AuditTemplatePagination(perPage: $perPage, filter: $filter) {
      items {
        eid
        title
      }
    }
  }
`;

interface TemplatesData {
  options: SelectOption[];
  loading?: boolean;
}

interface IProps {
  skip?: boolean;
  fetchPolicy?: FetchPolicy;
}

export const useTemplatesData = (props?: IProps): TemplatesData => {
  const entityId = useUserDataSelector((state) => state?.entityId);
  const { data, loading } = useQuery<
    Record<'AuditTemplatePagination', AuditTemplate>
  >(TEMPLATE_QUERY, {
    fetchPolicy: props?.fetchPolicy,
    skip: props?.skip,
    variables: {
      perPage: 100,
      filter: {
        status: 'active',
        entityId: entityId,
        templateType: 'business',
      },
    },
  });

  const options = useMemo(() => {
    return toArray(data?.AuditTemplatePagination?.items).map((item) => ({
      label: item.title,
      value: item.eid,
    }));
  }, [data?.AuditTemplatePagination]);

  return {
    options,
    loading,
  };
};
