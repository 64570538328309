import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { IFormInput } from '../../../audit-schedule.types';
import { OptionButton } from '../../shared';

const Monthly: FC = () => {
  return (
    <Controller<IFormInput, 'repeatDetails.monthly'>
      name='repeatDetails.monthly'
      rules={{
        required: 'Please select monthly repeat period',
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={!!fieldState.error}>
          <Flex w='full' gap={4}>
            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'startMonth'}
              onClick={() => field.onChange('startMonth')}
            >
              First day of month
            </OptionButton>
            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'middleMonth'}
              onClick={() => field.onChange('middleMonth')}
            >
              Middle of the month
            </OptionButton>
            <OptionButton
              // isReadOnly={isEdit}
              isDisabled={field.disabled}
              isSelected={field.value === 'endMonth'}
              onClick={() => field.onChange('endMonth')}
            >
              Last day of month
            </OptionButton>
          </Flex>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default Monthly;
