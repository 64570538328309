import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import React, {
  FC,
  useMemo,
  useRef,
  useState,
  useCallback,
  forwardRef,
} from 'react';
import { IProps } from './types/status-filter.types';
import Options from './Options';
import { SelectOption } from 'atoms';

const Filter: FC<IProps> = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { options, placeholder, value, onChange, onCloseCallback } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const label = useMemo(() => {
    if (value?.length === 0) {
      return placeholder;
    }
    if (value?.length === 1) {
      return value?.[0]?.label;
    }
    return `${value?.length} selected`;
  }, [placeholder, value]);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      setIsOpen(false);
      onCloseCallback?.();
    },
  });

  const toggleDropdown = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleOptionClick = useCallback(
    (option: SelectOption<string>) => {
      onChange(option);
    },
    [onChange]
  );

  return (
    <Flex direction='column' position='relative' ref={ref || dropdownRef}>
      <Flex
        cursor='pointer'
        gap={2}
        border='2px solid rgba(239, 239, 239, 1)'
        borderRadius='12px'
        p='8px 22px'
        justify='space-between'
        align='center'
        minW='220px'
        onClick={toggleDropdown}
      >
        <Box color='rgba(111, 118, 126, 1)' fontWeight={600}>
          {label}
        </Box>
        <ChevronDownIcon
          fontSize='20px'
          color='rgba(111, 118, 126, 1)'
          mt='2px'
        />
      </Flex>

      {isOpen && (
        <Options
          options={options}
          value={value}
          handleOptionClick={handleOptionClick}
        />
      )}
    </Flex>
  );
});

export default React.memo(Filter);
