import { gql } from '@apollo/client';
import { AuditInput } from './audit.types';

export interface CreateAuditInput {
  input: Omit<AuditInput, 'eid'>;
}

export interface CreateAuditResponse {
  CreateAuditTemplate: {
    eid: string;
  };
}

export const CREATE_AUDIT_GRAPHQL = gql`
  mutation CreateAuditTemplate($input: AddAuditTemplateTypeInput) {
    CreateAuditTemplate(input: $input) {
      eid
    }
  }
`;

export interface UpdateAuditInput {
  input: AuditInput;
}

export interface UpdateAuditResponse {
  UpdateAuditTemplate: Required<AuditInput>;
}

export const UPDATE_AUDIT_GRAPHQL = gql`
  mutation UpdateAuditTemplate($input: UpdateAuditTemplateTypeInput) {
    UpdateAuditTemplate(input: $input) {
      eid
      title
      description
      thumbnail
      category {
        eid
        name
      }
      categoryId
      questions
      status
    }
  }
`;

export interface AuditTemplateResponse {
  AuditTemplateById: AuditInput;
}

export const AUDIT_GRAPHQL_QUERY = gql`
  query AuditTemplateById($eid: String!) {
    AuditTemplateById(eid: $eid) {
      eid
      title
      description
      thumbnail
      category {
        eid
        name
      }
      categoryId
      questions
      status
    }
  }
`;
