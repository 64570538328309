import { gql, useLazyQuery } from '@apollo/client';
import { Box, Center } from '@chakra-ui/react';
import { useEffect, useState, FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { formatQuestionAnswerData } from '../../../sub-components/audits/report/helper';
import { ReportPdfView } from '../../../sub-components/audits/report';
import { Loader } from '../../../sub-components';
import React from 'react';
import { defaultCSTTimezone } from '../../../sub-components/tasks/create-task/validation/helper';

const getTokenFromLocalStorage = () => {
  const authToken = localStorage.getItem('authToken');
  return authToken;
};

export const AUDIT_SESSION_DETAILS = gql`
  query AuditSessionById($eid: String!) {
    AuditSessionById(eid: $eid) {
      assignments {
        questions
        assignmentId
        auditors
        locationId
        completedBy
        startedBy
        totalScore
        maxScore
        completedPercent
        isCompleted
        comments
        response
        completedAt
        reportsUrl
        signature {
          font
          text
        }
      }
      auditId
      auditType
      auditors {
        eid
        name
        role
      }
      createdAt
      description
      dueDate
      eid
      entityId
      isLastSession
      name
      startDate
      title
      updatedAt
    }
  }
`;

export const GET_ENTITY_USERS = gql`
  query SHARED_DATA_EntityUser($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      authRole
      email
      name
      type
      role
      status
      locations {
        eid
        name
      }
      username
      profilePic
      timezone
    }
  }
`;

export const LOCATION_DETAILS = gql`
  query UserById($eid: String!) {
    userById(eid: $eid) {
      timezone
      address {
        address
        city
        zipCode
        state
      }
      entityId
      entity {
        locations {
          eid
          name
        }
        name
        profilePic
      }
    }
  }
`;

const PublicReport: FC = () => {
  const [assignment, setAssignment] = useState([]);
  const [auditData, setAuditData] = useState({});
  const [entityUsers, setEntityUsers] = useState([]);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [checkIfMembersLoaded, setCheckIfMembersLoaded] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    locationData: {},
    cardInfo: {},
    moreInfo: {},
    address: {},
    companyDetails: {},
    entityId: '',
  });
  const [isPageContentLoaded, setIsPageContentLoaded] = useState({
    auditDataStatus: false,
    addressStatus: false,
    entityUserStatus: false,
    mappedDataStatus: false,
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const tokenId = queryParams.get('token')
    ? queryParams.get('token')
    : getTokenFromLocalStorage();

  const locations = businessLocations;

  const { locationId, sessionId } = useParams<{
    locationId: string;
    sessionId: string;
  }>();

  let locationData = locations?.find((loc) => loc?.eid === locationId);

  // console.log('audit --- ', auditData);
  // console.log('assignment --- ', assignment);
  // console.log('additionalData --- ', additionalData);
  // console.log('entityUsers --- ', entityUsers);
  // console.log('businessLocations --- ', businessLocations);
  // console.log('locationData --- ', locationData);
  // console.log('companyData --- ', companyData);

  const [getSessionDetails, { data, loading: getSessionDetailsLoading }] =
    useLazyQuery(AUDIT_SESSION_DETAILS, {
      fetchPolicy: 'network-only',
      context: {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      },
      onCompleted: (data) => {
        let cardInfo = {
          score: {
            title: 'Inspection score',
            totalScore: 0,
            obtainedScore: 0,
          },
          flaggedItemCount: {
            title: 'Flagged items',
            count: 0,
          },
          reportNumber: {
            title: 'Report Number',
            reportNumber: '---',
          },
        };

        let moreInfo = {
          location: {
            title: 'Location',
            address: '',
          },
          conductedOn: {
            title: 'Conducted on',
            date: '',
          },
          conductedBy: {
            title: 'Conducted by',
            userDetails: '',
          },
        };

        setAuditData(data?.AuditSessionById);

        const mappedAssignment = data?.AuditSessionById?.assignments?.filter(
          (item: any) => {
            if (item?.locationId === locationId) {
              return item;
            }
          }
        );

        let auditorDetails = entityUsers?.filter((item) => {
          if (item?.eid === mappedAssignment?.[0]?.completedBy) {
            return item;
          }
        });

        setAssignment(mappedAssignment?.[0]);

        // let flaggedItems = mappedAssignment?.[0]?.response?.filter(
        //   (item: any) => {
        //     if (item?.isFlagged) {
        //       return item;
        //     }
        //   }
        // );

        //handling total flag items count
        const flagMemory: string[] = [];
        //getting flag items count from response
        let flaggedItems = mappedAssignment?.[0]?.response?.filter(
          (item: any) => {
            if (item?.isFlagged && !flagMemory?.includes(item?.qid)) {
              flagMemory?.push(item?.qid);
              return item;
            }
          }
        );

        //getting flag items count from question
        mappedAssignment[0]?.questions?.map((item: any) => {
          if (item?.hasFlaggedResponse && !flagMemory?.includes(item?.eid)) {
            flaggedItems?.push(item);
            flagMemory?.push(item?.eid);
          }
        });

        const totalObtainedScore = data?.AuditSessionById?.questions?.reduce(
          (acc: number, item: any) => {
            return acc + item?.score;
          },
          0
        );

        setAdditionalData((prevState: any) => {
          return {
            ...prevState,
            locationData: locationData,
            cardInfo: {
              ...cardInfo,
              flaggedItemCount: {
                ...cardInfo?.flaggedItemCount,
                count: flaggedItems?.length,
              },
              score: {
                ...cardInfo?.score,
                totalScore: mappedAssignment?.[0]?.maxScore,
                obtainedScore: mappedAssignment?.[0]?.totalScore,
              },
              reportNumber: {
                ...cardInfo?.reportNumber,
                reportNumber: mappedAssignment?.[0]?.assignmentId
                  ? mappedAssignment?.[0]?.assignmentId
                  : '--',
              },
            },
            moreInfo: {
              ...moreInfo,
              location: {
                ...moreInfo?.location,
                address: additionalData?.address,
              },
              conductedOn: {
                ...moreInfo?.conductedOn,
                date: mappedAssignment?.[0]?.completedAt,
              },
              conductedBy: {
                ...moreInfo?.conductedBy,
                userDetails: auditorDetails,
              },
            },
          };
        });

        if (additionalData?.cardInfo?.score?.totalScore === 0) {
          delete additionalData?.cardInfo?.score;
        }

        setIsPageContentLoaded((prevState) => {
          return {
            ...prevState,
            auditDataStatus: true,
          };
        });
      },
    });

  const [getAddress, { loading: getAddressLoading }] = useLazyQuery(
    LOCATION_DETAILS,
    {
      fetchPolicy: 'network-only',
      context: {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      },
      onCompleted: (data) => {
        setAdditionalData((prevState) => {
          return {
            ...prevState,
            timeZone: data?.userById?.timezone
              ? data?.userById?.timezone
              : defaultCSTTimezone,
            address: data?.userById?.address,
            entityId: data?.userById?.entityId,
            moreInfo: {
              ...prevState?.moreInfo,
              location: { title: 'Location', address: data?.userById?.address },
            },
            companyDetails: {
              name: data?.userById?.entity?.name,
              profilePic: data?.userById?.entity?.profilePic,
            },
          };
        });

        setBusinessLocations(data?.userById?.entity?.locations);
        setIsPageContentLoaded((prevState) => {
          return {
            ...prevState,
            addressStatus: true,
          };
        });

        getEntityUsers({
          variables: {
            entityId: data?.userById?.entityId,
          },
        });
      },
    }
  );

  const [getEntityUsers, { loading: getEntityUsersLoading }] = useLazyQuery(
    GET_ENTITY_USERS,
    {
      fetchPolicy: 'network-only',
      context: {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      },
      onCompleted: (data) => {
        let auditorDetails = data?.EntityUser?.filter((item) => {
          if (item?.eid === assignment?.completedBy) {
            return item;
          }
        });

        setAdditionalData((prevState) => {
          return {
            ...prevState,
            moreInfo: {
              ...prevState?.moreInfo,
              conductedBy: {
                title: 'Conducted by',
                userDetails: auditorDetails,
              },
            },
          };
        });

        setEntityUsers(data?.EntityUser);
        setIsPageContentLoaded((prevState) => {
          return {
            ...prevState,
            entityUserStatus: true,
          };
        });
      },
    }
  );

  useEffect(() => {
    getAddress({
      variables: {
        eid: locationId,
      },
    });

    getSessionDetails({
      variables: {
        eid: sessionId,
      },
    });
  }, []);

  let mappedQuestionData = formatQuestionAnswerData(
    assignment?.questions,
    assignment?.response,
    assignment,
    auditData,
    additionalData
  );

  const flaggedCount = mappedQuestionData?.find(
    (item) => item?.title === 'Flagged Items'
  );

  useEffect(() => {
    setAdditionalData((prevState) => {
      return {
        ...prevState,
        cardInfo: {
          ...prevState?.cardInfo,
          flaggedItemCount: {
            title: 'Flagged items',
            count: flaggedCount?.responses?.length,
          },
        },
      };
    });
  }, [assignment]);

  if (getSessionDetailsLoading || getAddressLoading || getEntityUsersLoading) {
    return (
      <Center h='89vh'>
        <Loader />
      </Center>
    );
  }

  const addEmptyDivElement = () => {
    const pdfContainer = document.querySelector('#report-pdf-view');
    let emptyDivElement = document.createElement('div');
    emptyDivElement.id = 'someUniqueId';

    if (!pdfContainer?.querySelector('#someUniqueId')) {
      pdfContainer?.appendChild(emptyDivElement);
    }
  };

  return (
    <Box height={'99vh'} overflowY={'auto'}>
      <ReportPdfView
        auditData={auditData}
        assignment={assignment}
        additionalData={additionalData}
        mappedQuestionData={mappedQuestionData}
        companyData={additionalData?.companyDetails}
        entityUsers={entityUsers}
        setCheckIfMembersLoaded={setCheckIfMembersLoaded}
      />

      {isPageContentLoaded?.addressStatus &&
        isPageContentLoaded?.auditDataStatus &&
        isPageContentLoaded?.entityUserStatus &&
        mappedQuestionData?.length >= 4 &&
        addEmptyDivElement()}
    </Box>
  );
};

export default PublicReport;
