import React, { useCallback, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { toArray } from '../../../../utils';
import TaskCrud from './TaskCrud';
import { ResponsesByLocationEntity } from '../../../../pages/Audits/Detail/components/QuestionSection/types/audit-question.types';

interface ILocationDrawerProps {
  auditId: string;
  auditName: string;
  flaggedOptions?: string | string[];
  qid: string;
  sessionId: string;

  questionTitle?: string;
  responses?: ResponsesByLocationEntity[];

  flaggedLocationIds: string[];
  // flaggedQuestionResponse: FlaggedQuestionResponse;

  onTaskSuccess?: () => void;
}

export const useLocationDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerProps, setDrawerProps] = useState<ILocationDrawerProps | null>(
    null
  );

  const openDrawer = useCallback(
    (props: ILocationDrawerProps) => {
      setDrawerProps(props); // Set the drawer props with incoming data
      onOpen(); // Open the drawer
    },
    [onOpen]
  );

  // Drawer component to render
  const LocationDrawerComponent = () => (
    <TaskCrud
      auditId={drawerProps?.auditId}
      auditDescription=''
      flaggedOptions={toArray(drawerProps?.flaggedOptions)}
      qid={drawerProps?.qid}
      sessionId={drawerProps?.sessionId}
      // TODO
      auditTitle={drawerProps?.auditName}
      assignedLocations={drawerProps?.flaggedLocationIds}
      questionTitle={drawerProps?.questionTitle}
      responses={drawerProps?.responses}
      // TODO
      open={isOpen}
      onClose={onClose}
      onTaskSuccess={drawerProps?.onTaskSuccess}
      onTaskError={() => {}}
    />
  );

  return { openDrawer, LocationDrawerComponent };
};
