import { useLazyQuery } from '@apollo/client';
import { AUDIT_QUESTION_CYCLES } from '../../QuestionSection/service/audit-question.graphql';
import {
  AUDIT_FLAGGED_RESPONSE_DETAILS_BY_SESSION_ID,
  FLAGGED_RESPONSE_DETAILS,
  FLAGGED_RESPONSE_GRAPH_DATA,
} from '../flagged-response.graphql';
import {
  AuditCycleResponse,
  AuditCycleVariable,
  FlaggedResponse,
  FlaggedResponseVariables,
  FlaggedSessionResponse,
  FlaggedSessionVariable,
  ServiceLayer,
} from '../types';

export const useServiceLayer = (): ServiceLayer => {
  // const [getFlaggedResponseDetails, flaggedResponseDetails] = useLazyQuery<
  //   FlaggedResponse,
  //   FlaggedResponseVariables
  // >(FLAGGED_RESPONSE_DETAILS, {
  //   fetchPolicy: 'network-only',
  // });

  const [getGraphData, graphDataDetails] = useLazyQuery<
    FlaggedResponse,
    FlaggedResponseVariables
  >(FLAGGED_RESPONSE_GRAPH_DATA, {
    fetchPolicy: 'network-only',
  });

  const [getFlaggedResponseSessionDetails, flaggedResponseSessionDetails] =
    useLazyQuery<FlaggedSessionResponse, FlaggedSessionVariable>(
      AUDIT_FLAGGED_RESPONSE_DETAILS_BY_SESSION_ID,
      {
        fetchPolicy: 'network-only',
      }
    );

  const [getAuditCycles, auditCyclesResponse] = useLazyQuery<
    AuditCycleResponse,
    AuditCycleVariable
  >(AUDIT_QUESTION_CYCLES, {
    fetchPolicy: 'network-only',
  });

  return {
    // flaggedResponseQuery: {
    //   getFlaggedResponseDetails,
    //   flaggedResponseDetails,
    // },
    graphData: {
      getGraphData,
      graphDataDetails,
    },
    flaggedSession: {
      getFlaggedResponseSessionDetails,
      flaggedResponseSessionDetails,
    },
    auditCycles: {
      getAuditCycles,
      auditCyclesResponse,
    },
  };
};
