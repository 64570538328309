import { useCallback, useEffect } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { init } from 'emoji-mart';
import data from '@emoji-mart/data';
import { chatStatus } from 'sop-commons/src/client';
import { useUserDataSelector, useUserEntity } from './useUserDataQuery';

export const useChatInitialize = (): void => {
  const authUserId = useUserDataSelector((state) => state?.eid);
  const isChatEnabled = useUserEntity((entity) => entity?.features?.chat);

  const loginChatUser = useCallback((userId: string) => {
    CometChat.login(userId, process.env.REACT_APP_COMET_CHAT_AUTH_KEY).then(
      () => {
        // eslint-disable-next-line no-console
        console.log('Login Successful');
        chatStatus(true);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log('Login failed with exception:', error);
        if (error?.code === 'ERR_UID_NOT_FOUND') {
          // eslint-disable-next-line no-console
          console.log('Create user');
        }
      }
    );
  }, []);

  useEffect(() => {
    if (authUserId) {
      init({ data }).catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('emoji-mart initialisation failed ==> ', err);
      });
      if (isChatEnabled) {
        const appSetting = new CometChat.AppSettingsBuilder()
          .subscribePresenceForAllUsers()
          .setRegion(process.env.REACT_APP_COMET_CHAT_REGION)
          .build();

        CometChat.init(
          process.env.REACT_APP_COMET_CHAT_APP_ID,
          appSetting
        ).then(
          () => {
            // eslint-disable-next-line no-console
            console.log('Initialization completed successfully');

            // You can now call login function.
            CometChat.getLoggedinUser().then(
              (user) => {
                if (!user) {
                  loginChatUser(authUserId);
                } else {
                  // eslint-disable-next-line no-console
                  console.log('Already Logged-in');
                  chatStatus(true);
                }
              },
              (error) => {
                // eslint-disable-next-line no-console
                console.log('CometChat getLoggedInUser: ', error);
              }
            );
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.log('CometChat Initialization failed: ', error);
            // Check the reason for error and take appropriate action.
          }
        );
      } else {
        CometChat.disconnect();
      }
    }
  }, [authUserId, isChatEnabled]);
};
