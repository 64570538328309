import { SelectOption } from 'atoms';

export const filterOptions: SelectOption[] = [
  { label: 'Due date: Latest first', value: 'dueDateFirst' },
  { label: 'Audit name: Z to A', value: 'nameDesc' },
  { label: 'Audit name: A to Z', value: 'nameAsc' },
  // { label: 'Frequency', value: 'frequency' },
];

export const completeFilterOptions: SelectOption[] = [
  { label: 'Completion date: Latest first', value: 'compleDateFirst' },
  { label: 'Audit name: Z to A', value: 'nameDesc' },
  { label: 'Audit name: A to Z', value: 'nameAsc' },
  // { label: 'Frequency', value: 'frequency' },
  { label: 'Score: High to low', value: 'scoreDesc' },
  { label: 'Score: Low to high', value: 'scoreAsc' },
  // { label: 'Flags: High to low', value: 'flagsDesc' },
  // { label: 'Flags: Low to high', value: 'flagsAsc' },
];
