import React, { FC } from 'react';
import { AuthRole, withAuthorization } from '../../authorization';
import { AppRoute } from '../../routes';
import { DashboardContainer } from '../../sub-components';
import { AuditSettingComponent } from '../../sub-components/audits';

const AuditSettings: FC = () => {
  return (
    <DashboardContainer>
      <AuditSettingComponent />
    </DashboardContainer>
  );
};

export default withAuthorization({
  permittedFor: 'user',
  permittedRoles: [AuthRole.SUPER_ADMIN],
  redirectTo: AppRoute.AUDIT_LIST,
})(AuditSettings);
