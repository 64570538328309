import { useLazyQuery } from '@apollo/client';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ColumnsType, Table } from 'atoms';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { useRouteState } from 'routes';
import { HeaderColors } from 'shared/headerColors';
import { GET_AUDIT_SESSIONS_LIST } from 'sub-components/audits/audit-tab/audit-tab.graphql';
import {
  AuditSessionPaginateResponse,
  AuditSessionPaginateVariables,
  AuditStatusFunctionEntity,
} from 'sub-components/audits/audit-tab/supervised/types';
import { auditStatus } from 'sub-components/audits/audit-tab/supervised/utils';
import EmptyState from 'sub-components/EmptyState';

interface SupervisedEntity {
  auditName: string;
  assignedOn: string;
  expectedBy: string;
  auditStatus: AuditStatusFunctionEntity;
}

const SupervisedHome: FC = () => {
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const { state } = useRouteState('audit', {
    selectedTab: 'supervisedByMe',
  });

  useEffect(() => {
    if (state?.selectedTab === 'supervisedByMe') {
      getAuditSessionList({
        variables: {
          page: 1,
          perPage: 100,
          sort: '_ID_DESC',
          ...(loggedInUserAuthRole === AuthRole.ADMIN
            ? { filter: { isAssigned: true } }
            : {}),
        },
      });
    }
  }, [state?.selectedTab, loggedInUserAuthRole]);

  const [getAuditSessionList, { loading, data }] = useLazyQuery<
    AuditSessionPaginateResponse,
    AuditSessionPaginateVariables
  >(GET_AUDIT_SESSIONS_LIST, {
    fetchPolicy: 'network-only',
  });

  const supervisedData = useMemo((): SupervisedEntity[] => {
    return (
      data?.AuditSessionPagination?.items
        ?.filter((item) => item?.audit?.status !== 'AUDIT_DELETED')
        ?.slice(0, 3)
        ?.map((item) => {
          let isAllCompleted = item?.isCompleted;
          let hasAuditStarted = item?.assignments?.some(
            (assignment) => assignment.startedBy
          );
          let status = auditStatus(
            isAllCompleted,
            item?.dueDate,
            hasAuditStarted
          );
          return {
            eid: item.eid,
            auditName: item?.title,
            assignedOn: moment(item?.createdAt)?.isValid()
              ? moment.utc(item?.createdAt)?.format('DD MMM YYYY')
              : '-',
            expectedBy: moment(item?.dueDate)?.isValid()
              ? moment.utc(item?.dueDate)?.format('DD MMM YYYY')
              : 'No Deadline',
            auditStatus: status,
          };
        }) || []
    );
  }, [data?.AuditSessionPagination?.items]);

  const columns: ColumnsType<SupervisedEntity> = [
    {
      title: 'Audit name',
      dataIndex: 'auditName',
    },
    {
      title: 'Assigned on',
      dataIndex: 'assignedOn',
      key: 'assignedOn',
    },
    {
      title: 'Expected by',
      dataIndex: 'expectedBy',
      key: 'expectedBy',
    },
    {
      title: 'Status',
      dataIndex: 'auditStatus',
      key: 'auditStatus',
      render: (value) => {
        let bgColor = '';
        switch (value.toLowerCase()) {
          case 'completed':
            bgColor = '#B5E4CAB2';
            break;
          case 'upcoming':
            bgColor = '#CABDFF';
            break;
          case 'ongoing':
            bgColor = '#FFD88D';
            break;
          case 'overdue':
            bgColor = HeaderColors.Red.Mid;
            break;
          default:
            bgColor = '';
        }
        return (
          <Box
            as='span'
            bg={bgColor}
            h='26px'
            p='4px 10px'
            w='fit-content'
            borderRadius='3px'
            textTransform='capitalize'
          >
            {value?.toLowerCase() === 'scheduled' ? 'Upcoming' : value}
          </Box>
        );
      },
    },
  ];

  return (
    <Flex>
      <Table
        columns={columns}
        dataSource={supervisedData}
        rowKey='eid'
        isLoading={loading}
        searchActive={false}
        pagination={{
          hideOnSinglePage: true,
        }}
        emptyState={<EmptyState image='MyTask' title='No audit to supervise' />}
      />
    </Flex>
  );
};

export default SupervisedHome;
