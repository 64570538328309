import { gql } from '@apollo/client';
import { AuditSessionEntity } from '../audit-tab/supervised/types';

export interface AuditTaskSessionDetailResponse {
  AuditSessionById: AuditSessionEntity;
}

export interface AuditTaskSessionDetailVariable {
  eid: string;
}

export const AUDIT_TASK_DETAIL = gql`
  query AuditTaskSessionDetail($eid: String!) {
    AuditSessionById(eid: $eid) {
      audit {
        repeatDetails
      }
      name
      eid
      title
      task {
        title
        description
        eid
        audit {
          auditId
          sessionId
          qid
        }
      }
      dueDate
      startDate
      isCompleted
      createdAt
      entityId
      updatedAt
      description
      auditId
      questions
      assignments {
        completedAt
        completedBy
        completedPercent
        isCompleted
        locationId
        startedBy
        totalScore
        response
      }
    }
  }
`;
