/* eslint-disable react/no-unescaped-entities */
// @ts-ignore
import React, { FC, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useIntercom } from 'react-use-intercom';
import { Checkbox, FormControl, FormErrorMessage } from '@chakra-ui/react';

import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';
import {
  authTokenVar,
  chatStatus,
  userObj,
} from 'sop-commons/src/client/clientFactory';

import { bundleObj, roleObj } from '../../ui-components/DashboardMenu';
import '../../ui-components/ResponsiveModal/ResponsiveModal.css';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import locked from '../../assets/lotties/locked.json';
import inviteMembers from '../../assets/lotties/inviteMembers.json';
import brokenLink from '../../assets/lotties/brokenLink.json';

// import css
import './InviteRegistration.scss';
import AuthContainer from '../../sub-components/AuthContainer';
import PasswordInput from '../../atoms/PasswordInput';
import FormInput from '../../atoms/FormInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import ErrorContainer from '../../sub-components/ErrorContainer';
import { GET_USER } from '../Login/login.graphql';
import { useSearchParam } from '../../hooks';
import {
  GET_INVITE_DATA,
  InviteResponse,
  JOIN_INVITE,
  JoinResponse,
} from './invite-register.graphql';
import { deployEvent } from '../../shared';
import { AmplitudeEventNames } from '../../shared/amplitudeEvents';

interface IFormInput {
  email: string;
  password: string;
  confirmPassword: string;
}

const InviteRegistration: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const submitRef = useRef(false);
  const authCode = useSearchParam<{ code: string }>().code;
  const [isLoading, setIsLoading] = useState(false);
  const { shutdown } = useIntercom();
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const history = useHistory();
  const [updateAccessToken, setUpdateAccessToken] = useState(false);

  const { data } = useQuery<InviteResponse>(GET_INVITE_DATA, {
    variables: {
      authCode: authCode,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    setError,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onClearData = () => {
    const delightreeEmail = localStorage.getItem('delightree-email');

    localStorage.clear();
    setTimeout(() => {
      localStorage.setItem('delightree-email', delightreeEmail);
    });

    authTokenVar('');
    userObj({} as never);
    chatStatus(false);
    bundleObj({});
    roleObj({});
    shutdown();
    // client.clearStore();
  };

  useEffect(() => {
    onClearData();
  }, []);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (response) => {
      setIsLoading(false);
      userObj(response?.user || {});
      if (updateAccessToken) {
        authTokenVar(localStorage.getItem('authToken'));
      }
      setTimeout(() => history.push('/'), 300);
    },
    fetchPolicy: 'network-only',
  });

  const [joinInvite] = useMutation<JoinResponse>(JOIN_INVITE, {
    onCompleted: (response) => {
      localStorage.setItem('authToken', response?.joinInvite?.accessToken);
      localStorage.setItem(
        'refreshAuthToken',
        response?.joinInvite?.refreshToken
      );
      setUpdateAccessToken(true);
      getUser({ variables: {} });
    },
    onError: (error) => {
      setIsLoading(false);
      if (error.graphQLErrors) {
        if (error?.message.includes('Email')) {
          setError('email', {
            message: error?.message,
            type: 'validate',
          });
        }
      }
    },
  });

  const onFinish = (values: IFormInput) => {
    if (submitRef.current) {
      return;
    }
    submitRef.current = true;
    setIsLoading(true);
    joinInvite({
      variables: {
        input: {
          authCode: authCode,
          email: values.email,
          password: values.password,
        },
      },
    })
      .then((response) => {
        if (isRememberMeChecked) {
          localStorage.setItem('delightree-email', values.email);
        }

        deployEvent(AmplitudeEventNames.MEMEBER_INVITED_JOINS, {
          member_id: userObj().eid,
          add_member_funnel_id: 5,
          resend_funnel_id: 7,
        });
      })
      .finally(() => (submitRef.current = false));
  };

  const invitationStatus = data?.FetchInviteByCode?.inviteDetails?.status;

  const getRightIcon = (mailError) => {
    if (isDirty) {
      if (mailError && mailError?.type !== 'required') {
        return (
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color={'#ff6a55'}
            style={{ cursor: 'pointer' }}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={faCheck as IconProp}
            color={'#83BF6E'}
            style={{ cursor: 'pointer' }}
          />
        );
      }
    }
  };

  return (
    <>
      {invitationStatus === 'pending' && (
        <AuthContainer>
          <div className='invite-form-container'>
            <div className='login-heading-container'>
              {t('auth:hello_user', {
                name: data?.FetchInviteByCode?.name,
              })}
            </div>
            <div className='invite-register-welcome-business-text'>
              {t('welcome_app')}
            </div>
            <div className='login-sub-heading-container'>
              {t('auth:lets_start')}
            </div>
            <form
              className='login-form-container'
              onSubmit={handleSubmit(onFinish)}
            >
              <FormControl isInvalid={!!errors?.email}>
                <FormInput
                  size='lg'
                  leftIcon={faEnvelope as IconProp}
                  id='email'
                  type='email'
                  placeholder={t('auth:your_email')}
                  _autofill={{
                    color: 'red !important',
                  }}
                  {...register('email', {
                    required: t('validation.email_required'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('validation.email_invalid'),
                    },
                  })}
                  rightIcon={getRightIcon(errors?.email)}
                />

                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={3} isInvalid={!!errors?.password}>
                <PasswordInput
                  id='login-password'
                  leftIcon={true}
                  rightIcon={true}
                  placeholder={t('auth:password')}
                  formProps={{
                    ...register('password', {
                      required: t('validation.password_required'),
                      minLength: {
                        value: 6,
                        message: t('validation.password_min_six'),
                      },
                    }),
                  }}
                  error={errors?.password}
                />
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={3} isInvalid={!!errors?.confirmPassword}>
                <PasswordInput
                  id='confirmPassword'
                  leftIcon={true}
                  rightIcon={true}
                  placeholder={t('placeholder.confirm_password')}
                  formProps={{
                    ...register('confirmPassword', {
                      required: t('validation.conf_pass_required'),
                      minLength: {
                        value: 6,
                        message: t('validation.password_min_six'),
                      },
                      validate: (value) => {
                        return (
                          value === getValues('password') ||
                          t('validation.password_not_match')
                        );
                      },
                    }),
                  }}
                  error={errors?.confirmPassword}
                />
                <FormErrorMessage>
                  {errors?.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>
              <div className='login-remember-forgot-container'>
                <div style={{ flex: '1 1 auto' }}>
                  <Checkbox
                    className='login-check-box-container'
                    onChange={(e) => setIsRememberMeChecked(e.target.checked)}
                  >
                    {t('auth:remember_me')}
                  </Checkbox>
                </div>
                {/* <Link to='/forgot-password'>
        <Text size='sm' className='login-forgot-text-container'>
          {t('auth:forgot_password_ques')}
        </Text>
      </Link> */}
              </div>
              <PrimaryButton
                mt={4}
                type='submit'
                size='lg'
                isLoading={isLoading}
                title={t('auth:sign_in_btn')}
                variant='solid'
                colorScheme='blue'
              />
              <div className='invite-privacy-policy'>
                <Trans
                  t={t}
                  i18nKey='auth:agree_application'
                  components={[<span key='privacy' />]}
                />
              </div>
            </form>
          </div>
          <div className='invite-already-member'>
            <Trans
              t={t}
              i18nKey='auth:already_member'
              components={[<Link key='login' to='/login' />]}
            />
          </div>
        </AuthContainer>
      )}

      {invitationStatus === 'expired' && (
        <ErrorContainer
          animationData={brokenLink}
          title={t('auth:expired_title')}
          description={t('auth:expired_desc')}
        />
      )}
      {invitationStatus === 'completed' && (
        <ErrorContainer
          animationData={inviteMembers}
          title={t('auth:complete_title', {
            name: data?.FetchInviteByCode?.name,
          })}
          description={t('auth:complete_desc')}
          showButtons
        />
      )}
      {invitationStatus === 'revoked' && (
        <ErrorContainer
          animationData={locked}
          title={t('auth:revoke_title')}
          description={t('auth:revoke_desc')}
        />
      )}
    </>
  );
};

export default InviteRegistration;
