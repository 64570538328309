import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { chakraComponents } from 'chakra-react-select';

import SearchInput from '../../../atoms/SearchInput';
import Dropdown, { SelectOption } from '../../../atoms/Dropdown';
import ListGridToggle, { ListPropsProps } from '../ListGridToggle';
import BulkMove from './components';
import useCombinedStore from 'zustandStore/store';
import { Authorize, AuthRole } from 'authorization';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { useRouteMatch } from 'react-router-dom';

interface IProps extends ListPropsProps {
  searchQuery?: string;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
  sortBy?: SelectOption | null;
  typeFilter?: SelectOption<string> | null;
  onTypeFilterChange?: (value: SelectOption | null) => void;
  selectedData: { eid: string; type: string }[];
  selectedPageNumber?: number;
  shouldHideBulkMove: boolean;
  getChaptersListHandler?: () => void;
  checkboxSelectHandler: (
    type: 'single' | 'all',
    selectedValue: boolean,
    selectedId?: string,
    selectedType?: string
  ) => void;
}

const ChapterHeader: FC<IProps> = ({
  viewType,
  searchQuery,
  typeFilter,
  onSearch,
  sortBy,
  onTypeFilterChange,
  selectedData,
  selectedPageNumber,
  shouldHideBulkMove,
  getChaptersListHandler,
  checkboxSelectHandler,
}) => {
  const { t } = useTranslation(['common', 'handbook', 'chapter']);
  const { updateSortBy, updateFilterBy } = useCombinedStore();
  const routeMatch = useRouteMatch<{
    folderId: string;
    subFolderId?: string;
  }>();
  const selectedFolderId = routeMatch?.params?.folderId;
  const { selectedFolder } = useContext(DashboardDataContext);

  const _onSortChange = useCallback(
    (option: SelectOption) => {
      deployEvent(AmplitudeEventNames.SOP_FILTER_DROPDOWN_CLICK);
      updateSortBy(undefined);
      if (option?.value === sortBy?.value) {
        updateFilterBy(null);
        // onSortChange?.(null);
      } else {
        updateFilterBy(option);
        // onSortChange?.(option);
      }
    },
    [sortBy]
  );

  const selectedFolderName = useMemo(() => {
    if (!selectedFolderId) {
      return 'All List';
    }
    return selectedFolder?.name || <></>;
  }, [selectedFolder, selectedFolderId]);

  return (
    <Flex align='center' justify='space-between'>
      <Box fontSize='25px' fontWeight={600}>
        {selectedFolderName}
      </Box>
      <Flex justify='flex-end' gap='12px' mb='8px' align='center'>
        <Flex gap={4}>
          {/* <SearchInput
            disabled={true}
            size='lg'
            width='350px'
            hideShortcuts={true}
            placeholder={t('common:search')}
            onChange={onSearch}
            value={searchQuery}
          /> */}
          {(selectedData?.length > 0 || viewType === 'grid') &&
            !shouldHideBulkMove && (
              <Authorize
                restrictedFor={['branch']}
                restrictedRoles={[AuthRole.LOCATION_OWNER, AuthRole.WORKER]}
              >
                <Flex
                  align='center'
                  gap='5px'
                  border='1px solid #EFEFEF'
                  borderRadius='12px'
                  p='8px 16px'
                  h='48px'
                >
                  <Checkbox
                    colorScheme='green'
                    isChecked={selectedData?.length > 0}
                    readOnly={viewType === 'list'}
                    onChange={(e) => {
                      checkboxSelectHandler('all', e?.target?.checked);
                      if (e?.target?.checked) {
                        deployEvent(
                          AmplitudeEventNames.TURN_ON_BULK_GRID_VIEW_ALL_SELECT
                        );
                      } else {
                        deployEvent(
                          AmplitudeEventNames.TURN_OFF_BULK_GRID_VIEW_ALL_SELECT
                        );
                      }
                    }}
                  />
                  {selectedData?.length > 0 && (
                    <Text fontWeight={700}>
                      {selectedData?.length} selected
                    </Text>
                  )}
                </Flex>
              </Authorize>
            )}
        </Flex>
        <Flex>
          {selectedData?.length > 0 && !shouldHideBulkMove && (
            <BulkMove
              selectedData={selectedData}
              viewType={viewType}
              getChaptersListHandler={getChaptersListHandler}
            />
          )}
        </Flex>
        {selectedData?.length === 0 && (
          <Flex gap='12px'>
            {(!selectedPageNumber || selectedPageNumber === 1) && (
              <Box width='210px' zIndex='100'>
                <Dropdown
                  placeholder={t('handbook:type')}
                  value={typeFilter}
                  onChange={onTypeFilterChange}
                  options={[
                    {
                      label: 'All',
                      value: 'all',
                    },
                    {
                      label: 'Subfolders',
                      value: 'subFolders',
                    },
                    {
                      label: 'Chapters',
                      value: 'chapters',
                    },
                  ]}
                  selectStyles={{
                    singleValue: {
                      color: '#6f767e',
                      fontWeight: '600',
                    },
                  }}
                  components={{
                    SingleValue: (valProps) => {
                      return (
                        <chakraComponents.SingleValue {...valProps}>
                          {/* @ts-ignore */}
                          {t('handbook:type_by', {
                            value: valProps.children,
                          })}
                        </chakraComponents.SingleValue>
                      );
                    },
                  }}
                />
              </Box>
            )}
            <Box width='210px' zIndex='9'>
              <Dropdown
                placeholder={t('handbook:select_filter')}
                value={sortBy}
                onChange={_onSortChange}
                options={[
                  {
                    label: t('handbook:filter.last_updated'),
                    value: 'Last Updated',
                  },
                  {
                    label: t('handbook:filter.last_created'),
                    value: 'Last Created',
                  },
                  {
                    label: t('handbook:filter.alphabetically'),
                    value: 'Alphabetically',
                  },
                  // { label: t('handbook:filter.active'), value: 'Active' },
                  // { label: t('handbook:filter.inactive'), value: 'Inactive' },
                ]}
                selectStyles={{
                  singleValue: {
                    color: '#6f767e',
                    fontWeight: '600',
                  },
                }}
                components={{
                  SingleValue: (valProps) => {
                    return (
                      <chakraComponents.SingleValue {...valProps}>
                        {/* @ts-ignore */}
                        {t('handbook:sort_by', {
                          value: valProps.children,
                        })}
                      </chakraComponents.SingleValue>
                    );
                  },
                }}
              />
            </Box>
            <ListGridToggle viewType={viewType} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ChapterHeader;

ChapterHeader.displayName = 'Pages/ChapterHeader';
