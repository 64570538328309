import React, { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { AuthUser, Permission } from './authorization.types';
import { checkPermission } from './checkPermission';

type AuthorizationType = Permission & {
  redirectTo?: string;
};

const withAuthorization = (
  authorization?: AuthorizationType,
  callback?: () => void
) => {
  return function HOC<T>(Component: ComponentType<T>): ComponentType<T> {
    function RenderComponent(props: T) {
      const authUser = useReactiveVar(userObj) as AuthUser;
      const canAccess = checkPermission(authUser, authorization as Permission);
      if (canAccess) return <Component {...props} />;

      if (authorization?.canAccess && authorization.canAccess(authUser)) {
        return <Component {...props} />;
      }

      if (callback) callback();

      if (authorization?.redirectTo) {
        return <Redirect to={authorization.redirectTo} />;
      } else {
        return <Redirect to={'/unauthorized'} />;
      }
    }

    return RenderComponent;
  };
};

export default withAuthorization;
