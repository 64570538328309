import { Box, Center, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { HeaderColors } from 'shared/headerColors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { Dropdown } from 'atoms';
import { ControlLayer } from '../types';
import { EmptyState } from 'sub-components';
import GraphSection from './GraphSection';
import FlaggedResponseTable from './FlaggedResponseTable';
import SectionFilter from 'sub-components/audits/audit-tab/supervised/components/GridView/components/SectionFilter';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface IProps {
  control: ControlLayer;
  isAuditRepeating: boolean;
}

const ResponseSection: React.FC<IProps> = ({ control, isAuditRepeating }) => {
  const data = useMemo(() => {
    return control?.flaggedData
      ?.filter((data) => data?.flaggedData?.length > 0)
      ?.map((data) => {
        return {
          key: data?.section?.eid,
          ...data,
        };
      });
  }, [control?.flaggedData]);

  const totalFlags = useMemo(() => {
    return control?.graphDataFlagged?.reduce((acc, current) => {
      return acc + current?.flagCount || 0;
    }, 0);
  }, [control?.graphDataFlagged]);

  if (totalFlags === 0) {
    return (
      <Center h='55vh'>
        <EmptyState
          image='NoFlaggedResponse'
          title='Smooth Sailing'
          description='No flags have been raised at any location for the selected date range'
        />
      </Center>
    );
  }

  return (
    <Flex gap={4} flexDir='column'>
      <GraphSection
        graphData={control?.graphDataFlagged}
        totalFlags={totalFlags}
      />
      <GenericColorHeader
        title='Flagged responses details'
        color={HeaderColors.Purple}
      />
      {/* {isAuditRepeating && (
        <SectionFilter
          customTrigger={
            <Flex
              color='rgba(111, 118, 126, 1)'
              border='2px solid rgba(239, 239, 239, 1)'
              borderRadius='12px'
              p='8px 20px'
              fontWeight={600}
              cursor='pointer'
              align='center'
              justify='space-between'
            >
              <Box as='span' isTruncated maxW='100px'>
                {control?.selectedSession?.label}
              </Box>
              <ChevronDownIcon fontSize='20px' mt='2px' />
            </Flex>
          }
          options={control?.displaySessions}
          value={control?.selectedSession}
          handleOptionClick={(value) => {
            control?.setSelectedSession(value);
            control?.setSelectedAuditSessionId(value?.value);
          }}
        />
      )} */}
      <Flex flexDir='column'>
        <Box
          as='span'
          color='rgba(111, 118, 126, 1)'
          fontSize='14px'
          fontWeight={600}
        >
          Location
        </Box>
        <Box w='300px'>
          <Dropdown
            value={control?.selectedLocation}
            options={control?.locationsList}
            onChange={(e) => control?.setSelectedLocation(e)}
          />
        </Box>
      </Flex>
      {data?.length === 0 ? (
        <EmptyState
          image='NoFlaggedResponse'
          title='Smooth Sailing'
          description={
            control?.selectedLocation?.value
              ? 'No flags have been raised for the selected location'
              : 'Select location to see the flagged responses'
          }
        />
      ) : (
        <FlaggedResponseTable data={data} />
      )}
    </Flex>
  );
};

export default ResponseSection;
