import { ApolloError, gql, useMutation } from '@apollo/client';

interface CreateTemplateResponse {
  CreateAuditTemplate: {
    eid: string;
  };
}

const CREATE_TEMPLATE = gql`
  mutation CreateAuditTemplate($input: AddAuditTemplateTypeInput) {
    CreateAuditTemplate(input: $input) {
      eid
    }
  }
`;

interface IProps {
  onCompleted?: (data: CreateTemplateResponse) => void;
  onError?: (error: ApolloError) => void;
}

const NULL = () => {};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCreateTemplate = (props: IProps) => {
  return useMutation<CreateTemplateResponse>(CREATE_TEMPLATE, {
    onCompleted: props.onCompleted,
    onError: props.onError || NULL,
  });
};
