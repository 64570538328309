import { gql } from '@apollo/client';

export const SEND_REMINDER_TO_AUDITOR = gql`
  mutation SendDirectMessage(
    $senderId: String!
    $message: String!
    $receiverIds: [String]
  ) {
    sendDirectMessage(
      senderId: $senderId
      message: $message
      receiverIds: $receiverIds
    ) {
      succeed
    }
  }
`;
