import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { IFormInput } from '../../../../audit-schedule.types';
import CustomWeekDaySelect from './CustomWeekDaySelect';
import CustomRepeatDate from './CustomRepeatDate';

interface IProps {}

const CustomRight: FC<IProps> = () => {
  const type = useWatch<IFormInput, 'repeatDetails.customRepeatType'>({
    name: 'repeatDetails.customRepeatType',
  });

  if (!type || type === 'days') {
    return null;
  }

  return (
    <Flex flex={1} align='start' gap={3}>
      <Flex align='center' fontWeight={600} color='#33383F' h={12}>
        {type === 'weeks' ? 'On' : 'Before'}
      </Flex>
      <CustomWeekDaySelect />
      <CustomRepeatDate />
    </Flex>
  );
};

export default CustomRight;
