import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Switch, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { LocationFieldEntity } from 'sop-commons/src/client';

import { useUserData } from '../../../hooks';
import EmptyState from 'sub-components/EmptyState';

import {
  Column,
  SortingTable,
} from '../../../sub-components/ChakraTable/SortingTable';

import LocationSettingAction from './LocationSettingAction';
import { FieldTypeString } from './location-field.graphql';

const Wrapper = styled.div`
  table {
    .action-button {
      svg {
        path {
          fill: #6f767e;
        }
      }
    }
  }
`;

interface IProps {
  onClickedHandler?: (
    action: 'edit' | 'delete',
    data: LocationFieldEntity
  ) => void;
  isLoading?: boolean;
}

const LocationFieldList: FC<IProps> = ({ onClickedHandler, isLoading }) => {
  const { t, i18n } = useTranslation(['common', 'location', 'setting']);

  const fieldEntities = useUserData()?.entity?.locationFields || [];

  const columns = useMemo((): Column<LocationFieldEntity>[] => {
    return [
      // {
      //   Header: '',
      //   id: 'temp',
      //   accessor: 'eid',
      //   width: '16px',
      //   Cell: () => <Box width='12px' />,
      //   disableSortBy: true,
      // },
      {
        Header: t('setting:fieldName'),
        accessor: 'fieldName',
        Cell: ({ cell: { value } }) => {
          return (
            <Text fontWeight={500} fontSize='14px' color='#33383F'>
              {value || '-'}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('setting:fieldType'),
        accessor: 'fieldType',
        Cell: ({ cell: { value } }) => {
          return (
            <Box
              maxW='max-content'
              px='6px'
              color='#8E59FF'
              bg='#CABDFF4D'
              fontWeight={600}
              fontSize='12px'
              borderRadius='4px'
              lineHeight='24px'
            >
              {FieldTypeString[value] || '-'}
            </Box>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('common:required'),
        accessor: 'validations',
        Cell: ({ cell: { value } }) => {
          if (value?.isRequired) {
            return (
              <Box pl={5}>
                <FontAwesomeIcon icon={faCheck as IconProp} />
              </Box>
            );
          }
          return null;
        },
        disableSortBy: true,
      },
      {
        Header: t('setting:team_section.added_on'),
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => {
          return (
            <Text fontWeight={500} fontSize='14px' color='#272B30'>
              {moment(value)?.format('DD MMMM')}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('setting:locations_section.columnVisibleToLocation'),
        accessor: 'visibleToLocations',
        Cell: ({ cell: { value } }) => {
          return <Switch isChecked={value} isReadOnly />;
        },
        disableSortBy: true,
      },
      {
        Header: t('common:action'),
        accessor: 'eid',
        width: 70,
        id: 'action',
        Cell: ({ cell: { row } }) => {
          return (
            <LocationSettingAction
              onClick={(action) => {
                return onClickedHandler?.(action, row.original);
              }}
            />
          );
        },
        disableSortBy: true,
      },
    ];
  }, [t, i18n.language]);

  return (
    <Wrapper id='location-field-list-wrapper'>
      <SortingTable
        colorScheme='blue'
        showDivider
        emptyData={{
          content: (
            <EmptyState
              image='Location'
              title={'No locations field are present in your organisation!'}
            />
          ),
        }}
        page={1}
        columns={columns}
        data={fieldEntities}
        isLoading={isLoading}
        isResponsive
        isHidePagination
      />
    </Wrapper>
  );
};

export default LocationFieldList;
