import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../../../atoms/PrimaryButton';
import FormInput from '../../../atoms/FormInput';
import {
  ADD_ROLE,
  UPDATE_ROLE,
} from '../../../sub-components/SettingDataCenter/RolesList/roles.graphql';
import { useTranslation } from 'react-i18next';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IFormInput {
  role: string;
}

export interface AddRoleRef {
  initialise: (values: IFormInput) => void;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  onRoleCreate?: (value: string) => void;
  zIndex?: number;
  type: 'add' | 'edit';
  roleName?: string;
}

const AddJobModal = forwardRef<AddRoleRef, IProps>((props, ref) => {
  const { t } = useTranslation(['common', 'role']);
  const role = useReactiveVar(userObj)?.entity?.roles || [];
  const userData = useReactiveVar(userObj);

  const zIndex = props.zIndex || '1403';

  const { control, handleSubmit, reset, getValues, setValue } =
    useForm<IFormInput>();

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  useEffect(() => {
    if (props?.type === 'edit' && props?.roleName) {
      setValue('role', props?.roleName);
    }
  }, [open]);

  const [addRole, { loading: addingJob }] = useMutation(ADD_ROLE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('role:jobCreatedSuccess'),
      });
      props?.onRoleCreate?.(getValues('role'));
      onModalClosed?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('role:jobNotAdded'),
      });
    },
  });

  const [updateRole, { loading: updatingRolesLoading }] = useMutation(
    UPDATE_ROLE,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('role:jobUpdateSuccess'),
        });
        props?.onRoleCreate?.(getValues('role'));
        onModalClosed?.();
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('role:jobNotUpdated'),
        });
      },
    }
  );

  const roleData = useMemo(() => {
    return role.map((data) => data.name);
  }, [role]);

  useImperativeHandle(
    ref,
    () => {
      return {
        initialise: (values: IFormInput) => {
          reset(values);
        },
      };
    },
    [reset]
  );

  const onModalClosed = () => {
    reset({
      role: '',
    });
    props.onClose?.();
  };

  const submitHandler = (value: IFormInput) => {
    if (props?.type === 'add') {
      deployEvent(AmplitudeEventNames.JOB_ADD_BUTTON, {
        add_job_funnel_id: 5,
        job_name: value.role,
        job_id: userData?.entityId,
        job_edit_funnel_id: 5,
      });
      return addRole({
        variables: {
          eid: userData?.entityId,
          name: value.role,
          color: '',
        },
      });
    } else {
      deployEvent(AmplitudeEventNames.JOB_EDIT_BUTTON, {
        add_job_funnel_id: 5,
        job_name: getValues('role'),
        job_id: userData?.entityId,
        job_edit_funnel_id: 5,
      });
      return updateRole({
        variables: {
          oldRole: props?.roleName,
          newRole: getValues('role'),
        },
      });
    }
  };

  return (
    <Modal
      isOpen={props.open}
      isCentered
      onClose={onModalClosed}
      closeOnOverlayClick={false}
    >
      <ModalOverlay zIndex={zIndex} />
      <ModalContent
        minWidth='642px'
        borderRadius='16px'
        containerProps={{
          zIndex: zIndex,
        }}
      >
        <ModalHeader px='36px' pt='36px' pb='24px'>
          <Flex align='center' justify='space-between'>
            <Flex gap='10px' flex='1'>
              <Box h='32px' w='16px' borderRadius='4px' bg='#CABDFF' />
              <div>
                {t(props?.type === 'edit' ? 'role:editJob' : 'role:createJob')}
              </div>
            </Flex>
            <Flex>
              <ModalCloseButton
                pos='relative'
                top='unset'
                right='unset'
                borderRadius='full'
                bg='rgb(239, 239, 239, 0.6)'
              />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody px='36px' pt={0} pb='30px' id='add-role'>
          <form onSubmit={handleSubmit(submitHandler)}>
            {/* <Box
              bg='rgba(177, 229, 252, 0.4)'
              borderRadius='10px'
              py='16px'
              px='22px'
              color='#33383F'
            >
              {t('role:addRoleHelp')}
            </Box> */}

            <Controller
              name='role'
              control={control}
              defaultValue=''
              rules={{
                required: t('role:jobValidation.nameRequired'),
                validate: (value) => {
                  if (value?.trim()?.length === 0) {
                    return t('validation.enter_title');
                  } else if (
                    roleData.some((cat) => cat?.trim() === value?.trim())
                  ) {
                    return t('role:jobValidation.alreadyExists', {
                      job: value,
                    });
                  } else if (value?.length > 32) {
                    return t('role:jobValidation.exceedLength');
                  }
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={4} isInvalid={!!fieldState.error}>
                    <FormInput
                      id='role'
                      size='lg'
                      placeholder={t('role:jobName')}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <Flex gap={2} justify='flex-end' mt='20px'>
              <PrimaryButton
                width='160px'
                type='reset'
                size='lg'
                title={t('common:cancel')}
                variant='outline'
                // onClick={() => reset()}
                onClick={() => props.onClose?.()}
              />
              <PrimaryButton
                width='160px'
                type='submit'
                size='lg'
                title={t(
                  props?.type === 'edit' ? 'common:update' : 'common:add'
                )}
                variant='solid'
                colorScheme='blue'
                isLoading={addingJob || updatingRolesLoading}
                disabled={addingJob || updatingRolesLoading}
              />
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

AddJobModal.displayName = 'Add Job Modal';

export default AddJobModal;
