import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AuditSessionEntity } from 'sub-components/audits/audit-tab/supervised/types';
import { AuditorSection } from './AuditorSection';
import LocationLineGraph from './LocationLineGraph';
import SummarySection from './SummarySection/SummarySection';
import FlaggedResponseAnalytics from './FlaggedResponsesAnalytics';

interface IProps {
  auditData: AuditSessionEntity | undefined;
}

const SpecificLocation: FC<IProps> = ({ auditData }) => {
  return (
    <Flex gap={4} w='full'>
      <Flex w='60%'>
        <LocationLineGraph auditData={auditData} />
      </Flex>
      <Flex gap={4} w='40%' flexDir='column'>
        <SummarySection auditData={auditData} />
        <AuditorSection auditData={auditData} />
        <FlaggedResponseAnalytics />
      </Flex>
    </Flex>
  );
};

export default SpecificLocation;
