import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useWatch } from 'react-hook-form';

import { Dropdown, SelectOption } from '../../../../../../../atoms';
import { WeekDaysData } from '../../../../../../tasks/create-task/Scheduler';
import { IFormInput } from '../../../../audit-schedule.types';

const toValue = (value?: string): SelectOption | undefined =>
  value ? { value: value, label: value } : undefined;

interface IProps {}

const CustomWeekDaySelect: FC<IProps> = () => {
  const type = useWatch<IFormInput, 'repeatDetails.customRepeatType'>({
    name: 'repeatDetails.customRepeatType',
  });

  if (type !== 'weeks') {
    return null;
  }

  return (
    <Controller<IFormInput, 'repeatDetails.customRepeatWeek'>
      name='repeatDetails.customRepeatWeek'
      rules={{
        required: 'Custom week day required',
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl width='unset' isInvalid={fieldState.invalid}>
            <Flex gap={4} align='center'>
              <Dropdown
                size='lg'
                options={WeekDaysData}
                isSearchable={false}
                value={toValue(field.value)}
                isDisabled={field.disabled}
                onChange={(newValue) => field.onChange(newValue.value)}
                // onChange={(newValue) =>
                //   callAll(field.onChange, resetFields)(newValue.value)
                // }
                getOptionLabel={(option) => option.value}
                selectStyles={{
                  container: { minWidth: '150px' },
                  menuList: {
                    minWidth: '100%',
                  },
                  singleValue: {
                    fontSize: '14px',
                    fontWeight: '500',
                  },
                  placeholder: {
                    fontSize: '14px',
                  },
                }}
                inputStyle={{
                  borderWidth: '2px',
                  borderRadius: '12px',
                  outline: 'none',
                  backgroundColor: 'white',
                }}
              />
            </Flex>
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default CustomWeekDaySelect;
