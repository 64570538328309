import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../atoms';
import { Authorize, AuthRole } from '../../../authorization';
import AuditTabs from './AuditTabs';
import TabsContent from './TabsContent';
import { AuditTabProvider } from './store/context';
import { useUserDataSelector } from 'hooks';
import { gql, useQuery } from '@apollo/client';
import EmptyState from 'sub-components/EmptyState';
import Loader from 'sub-components/Loader';
import CreateAuditButton from './CreateAuditButton';

const QUERY = gql`
  query AuditAssignedCount($filter: FilterFindManyAuditSessionInput) {
    AuditSessionPagination(filter: $filter) {
      count
    }
  }
`;

interface Response {
  AuditSessionPagination: { count: number };
}

const AuditTabContainer: FC = () => {
  const { t } = useTranslation(['audit']);
  const isAdmin = useUserDataSelector(
    (state) => state.type === 'user' && state.authRole === AuthRole.ADMIN
  );

  const { data, loading } = useQuery<Response>(QUERY, {
    skip: !isAdmin,
    variables: {
      filter: {
        isAssigned: true,
      },
    },
  });

  if (loading) {
    return (
      <Center h='70vh'>
        <Loader />
      </Center>
    );
  }

  if (isAdmin && !data?.AuditSessionPagination?.count) {
    return (
      <Center h='70vh'>
        <EmptyState image='MyTask' title='You are not supervising any audits' />
      </Center>
    );
  }

  return (
    <AuditTabProvider>
      <Flex position='relative' flexDir='column' gap={4}>
        <Authorize
          permittedFor={'user'}
          permittedRoles={[AuthRole.SUPER_ADMIN]}
        >
          <CreateAuditButton>
            <Button
              position='absolute'
              top='-62px'
              right={0}
              variant='solid'
              colorScheme='blue'
              leftIcon={<AddIcon />}
            >
              {t('audit:createNewAudit')}
            </Button>
          </CreateAuditButton>
        </Authorize>
        <AuditTabs />
        <TabsContent />
      </Flex>
    </AuditTabProvider>
  );
};

AuditTabContainer.displayName =
  'sub-components/audits/audit-tab/AuditTabContainer';

export default AuditTabContainer;
