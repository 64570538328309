import { match, P } from 'ts-pattern';
export function toArray<T>(data: T | T[]): NonNullable<T>[] {
  if (data === undefined || data === null) {
    return [];
  }
  return (Array.isArray(data) ? data : [data]) as NonNullable<T>[];
}

export function isThenable<T>(things?: PromiseLike<T>): boolean {
  return !!(things && !!things.then);
}

export function isCheckList(value?: string): boolean {
  if (!value) return false;
  return value.toUpperCase() === 'CHECKLIST';
}

export function compareArrayReturnSame(
  mainArr: any[],
  subArr: any[],
  compareByMain: string,
  compareBySub: string
): any[] {
  const mainEids = new Set(mainArr.map((item) => item[compareByMain]));
  return subArr.filter((item) => mainEids.has(item[compareBySub]));
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const getUrlFileSize = async (url: string): Promise<number> => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob.size;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching image:', error);
    return 0;
  }
};

export const getVideoUrlFileSize = async (url: string): Promise<number> => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    const contentLength = response.headers.get('Content-Length');

    if (contentLength) {
      return parseInt(contentLength, 10);
    }

    // eslint-disable-next-line no-console
    console.error('Content-Length header not found.');
    return 0;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching headers:', error);
    return 0;
  }
};

const Excel = [
  'vnd.ms-excel',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const Word = [
  'msword',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
];
const PowerPoint = [
  'vnd.ms-powerpoint',
  'vnd.openxmlformats-officedocument.presentationml.presentation',
];
const Audio = [
  'mpeg',
  'x-wav',
  'mp4',
  'aac',
  'x-ms-wma',
  'vnd.rn-realaudio',
  'vorbis',
];
const Text = ['plain'];
const Zip = ['zip', 'x-zip-compressed'];

export type ChapterFileType =
  | 'docs'
  | 'xlsx'
  | 'video'
  | 'pdf'
  | 'gif'
  | 'image'
  | 'ppt'
  | 'zip'
  | 'audio'
  | 'txt'
  | 'file';

export const getFileType = (fileType = ''): ChapterFileType => {
  return match<string[], ChapterFileType>(fileType?.split('/'))
    .with(
      ['application', P.when((value) => Word.includes(value))],
      () => 'docs'
    )
    .with(
      ['application', P.when((value) => Excel.includes(value))],
      () => 'xlsx'
    )
    .with(
      ['application', P.when((value) => PowerPoint.includes(value))],
      () => 'ppt'
    )
    .with(['application', P.when((value) => Zip.includes(value))], () => 'zip')
    .with(['audio', P._], () => 'audio')
    .with(['text', P.when((value) => Text.includes(value))], () => 'txt')
    .with(['video', P._], () => 'video')
    .with(['image', P.when((value) => ['gif'].includes(value))], () => 'gif')
    .with(['image', P._], () => 'image')
    .with(['application', 'pdf'], () => 'pdf')
    .otherwise(() => 'file');
};

export const getCharFromNumber = (number: number): string => {
  let baseChar = 'A'.charCodeAt(0),
    letters = '';
  do {
    number -= 1;
    letters = String.fromCharCode(baseChar + (number % 26)) + letters;
    number = (number / 26) >> 0; // quick `floor`
  } while (number > 0);

  return letters;
};

export function capitalizeFirstLetter(string: string): string {
  if (!string) {
    return '';
  }
  return string[0].toUpperCase() + string.slice(1);
}

export const formatNumber = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
}).format;

function toNumber<T>(value?: T): number | undefined;

// eslint-disable-next-line no-redeclare
function toNumber<T>(value?: unknown, defaultValue?: T): number | T;

// eslint-disable-next-line no-redeclare
function toNumber(
  value?: unknown,
  defaultValue: number | undefined = undefined
): number | undefined {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  const number = Number(value);

  return isNaN(number) ? defaultValue : number;
}

export { toNumber };
