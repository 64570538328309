import { toArray } from 'utils';
import { AuditInput } from './audit.types';
import { IFormInput } from './form-types';

export const getDefaultValues = (
  response: AuditInput,
  isAuditQuestion?: boolean
): IFormInput => {
  return {
    ...response,
    description: response.description || '',
    isAuditQuestion: isAuditQuestion,
    questions: toArray(response.questions).map((question) => {
      return {
        eid: question.eid,
        tempEid: question.eid!,
        label: question.label,
        qType: question.qType,
        isRequired: question.isRequired,
        hasScoring: question.hasScoring,
        isDisabled: question.isDisabled,
        hasFlaggedResponse: question.hasFlaggedResponse,
        requiresEvidence: question.requiresEvidence,
        options: toArray(question.options).map((option) => ({
          eid: option.eid,
          tempEid: option.eid,
          label: option.label,
          isFlagged: option.isFlagged,
          requiresEvidence: option.requiresEvidence,
          score: option.score || 0,
          isDisabled: option.isDisabled,
        })),
      };
    }),
  };
};
