import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput, PrimaryButton } from '../../../../../atoms';
import { TASK_DEPENDENCY } from '../../../../../types';
import { ActionButton } from '../../../../../ui-components';
import TitleHeader from '../../../../../sub-components/CardEditor/TitleHeader';
import { LauncherTaskStep } from 'pages/launcher/config-editor/common';
import { ITaskItem } from 'pages/launcher/config-editor/editor-form/editor.types';
import EmptyComponent from 'pages/launcher/config-editor/editor-form/section/EmptyComponent';
// import AddStepForm from 'sub-components/tasks/create-task/TaskSteps/AddStepForm';
import StepItem from 'pages/launcher/config-editor/editor-form/section/StepItem';
// import TaskDependSelect from 'pages/launcher/config-editor/editor-form/section/TaskDependSelect';
// packages\sop-web\src\pages\launcher\config-editor\editor-form\section\TaskDependSelect.tsx
import TaskDependSelect from 'pages/launcher/config-editor/editor-form/section/TaskDependSelect';
import TaskMoreAction from 'pages/launcher/config-editor/editor-form/section/TaskMoreAction';
import AddStepForm from 'pages/launcher/config-editor/editor-form/section/AddStepForm';
import { SimpleDatePicker } from 'ui-components/DateFilter/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarLines } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import useCombinedStore from 'zustandStore/store';
import { getUserTimezoneConvertedNow } from '../../helper';
import { useUserDataSelector } from 'hooks';
import { useGoLiveDateConfirmation } from './useGoLiveDateConfirmation';
import { getRefactoredLiveDate } from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Horizontal/util/helper';
import { getFormattedDateTime } from '../../helper/getFormattedDateTime';

const UNDEFINED = undefined as unknown as string;

export interface ITaskInput {
  eid?: string;
  title: string;
  description?: string;
  duration: number;
  dependency: TASK_DEPENDENCY;
  docRequired: boolean;
  selected?: boolean;
  dueDate?: string;
  steps: LauncherTaskStep[];
}

export interface BaseTaskFormRef {
  validate: () => Promise<boolean>;
  focusForm: () => void;
}

interface IProps {
  isFirstTask?: boolean;
  isEdit?: boolean;
  initialValue?: ITaskItem & { [k: string]: string };
  launchId?: string;
  layout?: 'vertical';
  onClose: () => void;
  onCollapse?: (values: ITaskInput, valueChanged?: boolean) => void;
  actionHandler?: (name: string, value: string) => void;
  phaseComponent?: React.ReactNode;
  locId: string;
}

const GET_LIVE_DATE_DATA = gql`
  query LocationLaunchById($eid: String!) {
    LocationLaunchById(eid: $eid) {
      liveDate
      tasks
    }
  }
`;

const UPDATE_LOCATAION_LAUNCH_TASK = gql`
  mutation UpdateLocationLaunchTask(
    $launchId: String!
    $taskId: String!
    $dueDate: Date
  ) {
    UpdateLocationLaunchTask(
      launchId: $launchId
      taskId: $taskId
      dueDate: $dueDate
    ) {
      succeed
    }
  }
`;

const EditDueDateDrawer = forwardRef<BaseTaskFormRef, IProps>(
  (
    {
      isEdit,
      isFirstTask,
      initialValue,
      launchId,
      layout,
      onClose,
      onCollapse,
      actionHandler,
      phaseComponent,
      locId,
    },
    ref
  ) => {
    const [goLiveDate, setGoLiveDate] = useState(moment()?.toISOString());
    const [locationData, setLocationData] = useState({});
    const goLiveDateConfirmation = useGoLiveDateConfirmation();
    const [openCalender, setOpenCalender] = useState<boolean>(false);
    const { t } = useTranslation(['common', 'task']);
    const loggedInUserTimezone = useUserDataSelector(
      (state) => state?.timezone
    );
    const toast = useToast({
      isClosable: true,
      duration: 3000,
      position: 'top-right',
    });
    const { control, handleSubmit, getValues, setValue, trigger, setFocus } =
      useForm<ITaskInput>({
        defaultValues: initialValue,
      });

    const isDirty = useFormState({
      control,
    }).isDirty;

    const [getLiveDateData, { data }] = useLazyQuery(GET_LIVE_DATE_DATA, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setLocationData(data?.LocationLaunchById);
      },
    });

    useEffect(() => {
      getLiveDateData({
        variables: {
          eid: locId,
        },
      });
    }, []);

    useEffect(() => {
      let duration = moment.duration(
        moment(initialValue?.dueDate)?.diff(initialValue?.startDate)
      );
      console.log('DURATION : ', duration?.asDays());
      if (duration) {
        setValue('duration', duration?.asDays());
      }
    }, [initialValue]);

    useImperativeHandle(
      ref,
      () => ({
        validate: async () => {
          const status = await trigger(undefined, {
            shouldFocus: true,
          });

          if (status) {
            setFocus('title');
          }

          return status;
        },
        focusForm: () => setFocus('title'),
      }),
      [trigger, setFocus]
    );

    const { fields, append, remove, update } = useFieldArray<
      ITaskInput,
      'steps'
    >({
      control: control,
      name: 'steps',
      // rules: {
      //   required: t('task:validation.taskStepRequired'),
      // },
    });

    const { emitEvent } = useCombinedStore();

    const [updateTaskDueDate, { loading }] = useMutation(
      UPDATE_LOCATAION_LAUNCH_TASK,
      {
        onCompleted: (data) => {
          toast({
            status: 'success',
            title: "Task's due date updated successfully",
          });
          emitEvent('EditLauncherTaskSideDrawer', 'fetchCompleteData', {
            msg: 'Emitted successfully',
          });
          onClose();

          //scroll to top and click on go-live-date edit button
          if (openCalender) {
            const editBtn = document.querySelector('#goLiveEditBtn');
            editBtn?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            editBtn?.click();
          }
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: "Task's due date could not be updated",
          });
        },
      }
    );

    const contentError = useFormState<ITaskInput>({
      control: control,
      name: 'steps',
      exact: true,
    }).errors?.steps;

    const isVertical = layout === 'vertical';

    const flex2 = isVertical ? undefined : 2;
    const flex3 = isVertical ? undefined : 3;
    const flex5 = isVertical ? undefined : 5;

    const updateButtonWidth = isVertical ? 'full' : 'auto';

    const flexDir = isVertical ? 'column' : 'row';

    const TaskPhase = phaseComponent || EmptyComponent;

    useEffect(() => {
      //calculate the go-live-date if API returns null as go-live-date
      setGoLiveDate(
        getRefactoredLiveDate(locationData?.liveDate, locationData?.tasks)
      );
    }, [locationData]);

    const updateDateHandler = () => {
      //selected date for current individual task
      let selectedDateForTask = moment
        .utc(getValues('dueDate'))
        ?.add(1, 'day')
        ?.startOf('day')
        ?.toISOString();

      //compare selected current individual task with the go-live-date
      if (selectedDateForTask > goLiveDate) {
        //if current individual task is greater than go-live-date show the warning popup
        goLiveDateConfirmation({
          textContent: `Updating the task due date has caused the final due date of all tasks (${
            getFormattedDateTime(selectedDateForTask).dateString
          }) to exceed the location's go-live date (${
            getFormattedDateTime(goLiveDate).dateString
          })
`,

          //update only the individual task date
          updateTaskDate: () => {
            setOpenCalender(false);
            updateTaskDueDate({
              variables: {
                launchId: launchId,
                taskId: initialValue?.eid,
                dueDate: moment
                  .utc(getValues('dueDate'))
                  ?.add(1, 'day')
                  ?.startOf('day')
                  ?.toISOString(),
              },
            });
          },

          //update the individual task date & open edit go-live-date calender
          updateLiveDate: () => {
            setOpenCalender(true);
            updateTaskDueDate({
              variables: {
                launchId: launchId,
                taskId: initialValue?.eid,
                dueDate: moment
                  .utc(getValues('dueDate'))
                  ?.add(1, 'day')
                  ?.startOf('day')
                  ?.toISOString(),
              },
            });

            //close modal
            onClose();

            //emit event from zustand store to acknowledge that task has been updated in background (using refetch)
            emitEvent('EditLauncherTaskSideDrawer', 'onUpdateDueTaskDate', {
              msg: 'Emitted successfully',
            });
          },
        });
      } else {
        //if the current individual task date is <= to the go-live-date
        updateTaskDueDate({
          variables: {
            launchId: launchId,
            taskId: initialValue?.eid,
            dueDate: moment
              .utc(getValues('dueDate'))
              ?.add(1, 'day')
              ?.startOf('day')
              ?.toISOString(),
          },
        });
      }
    };

    const todayGreaterThanTaskDueDate = () => {
      let appropriateDate: Date = new Date();
      const now = getUserTimezoneConvertedNow(loggedInUserTimezone);
      const start = moment.utc(initialValue?.startDate);
      if (start.isSameOrBefore(now, 'day')) {
        appropriateDate = moment()?.add(1, 'day')?.startOf('day')?.toDate();
      } else {
        appropriateDate = moment(initialValue?.startDate)
          ?.add(1, 'day')
          ?.startOf('day')
          ?.toDate();
      }
      return appropriateDate;
    };

    return (
      <Flex flexDir='column' gap={4}>
        <Flex flexDir={flexDir} gap={4}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Task title'}
              desc={'Explain what needs to be done'}
              isRequired
            />
          </Box>
          <Box flex={flex5}>
            <Controller
              control={control}
              name='title'
              defaultValue=''
              rules={{
                required: t('task:validation.titleRequired'),
                maxLength: {
                  value: 256,
                  message: 'Task title is too long (maximum is 256 character)',
                },
                validate: (value) => {
                  if (value?.trim()?.length === 0) {
                    return t('task:validation.titleEnter');
                  }
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState?.error}>
                    <FormInput
                      id='title'
                      size='lg'
                      variant='outline'
                      isDisabled
                      // placeholder={t('task:placeholder.title')}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState?.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={control}
              name='description'
              defaultValue=''
              rules={{
                maxLength: {
                  value: 512,
                  message:
                    'Task description is too long (maximum is 512 character)',
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={3} isInvalid={!!fieldState?.error}>
                    <FormInput
                      id='description'
                      size='lg'
                      isDisabled
                      // placeholder={t('task:placeholder.description')}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState?.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader title={'Steps'} desc={'Add steps to your tasks'} />
          </Box>
          <Box flex={flex5}>
            <AddStepForm
              isDisabled
              onAddClick={(newValue) => {
                append({
                  type: 'text',
                  stepId: UNDEFINED,
                  title: newValue,
                });
              }}
            />

            <FormControl isInvalid={!!contentError} isDisabled>
              <Flex flexDir='column' gap='6px' pt={fields?.length ? 2 : 0}>
                {fields?.map((value, index) => (
                  <StepItem
                    key={value.id}
                    index={index}
                    isDisabled
                    data={value}
                    onDelete={() => {}}
                    onUpdate={() => {}}
                  />
                ))}
              </Flex>

              <FormErrorMessage>
                {contentError?.root?.message || contentError?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>

        {/*// @ts-ignore */}
        <TaskPhase control={control} isDisabled />

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Due date'}
              desc={'Deadline for task completion'}
              isRequired
            />
          </Box>
          <Box flex={flex5}>
            <Controller
              control={control}
              name='dueDate'
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <SimpleDatePicker
                      customInput={
                        <Flex
                          align='center'
                          border='2px solid rgba(239, 239, 239, 1)'
                          borderRadius='12px'
                          p='10px 12px'
                          gap='5px'
                          cursor='pointer'
                        >
                          <FontAwesomeIcon icon={faCalendarLines as IconProp} />
                          <Text>
                            {moment(getValues('dueDate'))?.format(
                              'MMMM DD, YYYY'
                            )}
                          </Text>
                        </Flex>
                      }
                      minDate={todayGreaterThanTaskDueDate()}
                      selectedValue={moment(getValues('dueDate'))?.toDate()}
                      placeholderText='Due date'
                      onChange={(date) => {
                        setValue(
                          'dueDate',
                          moment(date)?.startOf('day')?.toISOString()
                        );
                      }}
                    >
                      <PrimaryButton
                        title='Update'
                        variant='solid'
                        colorScheme='blue'
                        style={{ marginTop: '10px' }}
                        onClick={updateDateHandler}
                        isLoading={loading}
                        disabled={loading}
                      />
                    </SimpleDatePicker>

                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Time duration'}
              desc={'No of days to finish this task'}
              isRequired
            />
          </Box>
          <Box flex={flex5}>
            <Controller
              control={control}
              name='duration'
              rules={{
                required: t('task:validation.thisFieldRequired'),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t('task:validation.onlyNumberAccepted'),
                },
                min: {
                  value: 1,
                  // @ts-ignore
                  message: t('task:validation.minValues', {
                    value: 1,
                  }),
                },
              }}
              defaultValue={'' as never}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormInput
                      // min={minValue}
                      id='time-duration'
                      size='lg'
                      variant='outline'
                      isDisabled
                      // placeholder={placeholder || t('task:enterNumberGreater')}
                      {...field}
                      rightIcon={
                        <Box fontSize='14px' color='#BFBFBF' pr={2}>
                          days
                        </Box>
                      }
                    />

                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Triggers after'}
              desc={'If a task should trigger after previous'}
            />
          </Box>

          <Flex align='center' flex={flex5}>
            <Controller
              control={control}
              name='dependency'
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <TaskDependSelect
                      isDisabled
                      isFirstTask={isFirstTask}
                      value={field.value}
                      onChange={field.onChange}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Box flex={flex2}>
            <TitleHeader
              title={'Document mandatory'}
              desc={'Task can’t be completed without it'}
            />
          </Box>
          <Flex align='center' flex={flex5}>
            <Controller
              control={control}
              name='docRequired'
              defaultValue={false}
              render={({ field }) => {
                return (
                  <FormControl>
                    <Switch
                      isChecked={field.value}
                      onChange={field.onChange}
                      isDisabled
                    />
                  </FormControl>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex flexDir={flexDir} gap={3}>
          <Flex flex={flex2} align='center'>
            {actionHandler && (
              <TaskMoreAction isEdit={isEdit} clickedItem={actionHandler}>
                <Box fontSize='15px' color='#6F767E' py={2} cursor='pointer'>
                  More options
                </Box>
              </TaskMoreAction>
            )}
          </Flex>
          <Flex flexDir={flexDir} align='center' flex={flex5} gap={3}>
            {onCollapse ? (
              <PrimaryButton
                size='lg'
                width='auto'
                title='Collapse'
                variant='outline'
                style={{
                  flex: flex3,
                }}
                disabled={!isEdit}
                onClick={
                  onCollapse
                    ? handleSubmit((it) => onCollapse(it, isDirty))
                    : undefined
                }
              />
            ) : (
              <Box flex={flex3} />
            )}

            <ActionButton
              size='lg'
              colorScheme='blue'
              width={updateButtonWidth}
              flex={flex5}
              fontSize='15px'
              isDisabled
              actionFn={() => {}}
            >
              {t(isEdit ? 'common:update' : 'common:add')}
            </ActionButton>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

EditDueDateDrawer.displayName = 'EditDueDateDrawer';

export default EditDueDateDrawer;
