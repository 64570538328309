import * as yup from 'yup';
import { QuestionType } from '../../../types';
import { getObject } from '../../../utils/objectHelper';

import { OptionWhiteList } from './form-types';

export const questionsSchema = yup.array().of(
  yup.object().shape({
    eid: yup.string().trim(),
    label: yup.string().trim().required('Please enter a valid question'),
    qType: yup.string().trim(),
    isRequired: yup.boolean(),
    imageRequired: yup.boolean(),
    hasFlaggedResponse: yup.boolean(),
    hasScoring: yup.boolean(),

    options: yup.array().when('qType', {
      is: (val: QuestionType) => OptionWhiteList.includes(val),
      then: () =>
        yup.array().of(
          yup.object().shape({
            eid: yup.string().trim(),
            label: yup.string().trim().required('Please enter an option'),

            score: yup.number().when((_, __, ctx) => {
              if (getObject(ctx, ['from', 1, 'value', 'hasScoring'])) {
                return yup.number().typeError('Score must be a number');
                // .required('Please enter score');
              }

              return yup.number().notRequired().default(undefined);
            }),
            isFlagged: yup.boolean(),
          })
        ),
      otherwise: () => yup.array().default([]),
    }),
  })
);

export const titleSchema = yup
  .string()
  .trim()
  .required('Please enter a name for your template')
  .max(50, 'The name field allows a maximum of 50 characters.');

export const validationSchema = yup.object().shape({
  eid: yup.string().trim(),
  title: titleSchema,
  description: yup.string().nullable().trim(),
  category: yup
    .object()
    .shape({
      eid: yup.string().required(),
      name: yup.string().required(),
    })
    .default(undefined)
    .required('Please select a template type'),

  questions: questionsSchema,
});
