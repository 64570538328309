import React, { FC, forwardRef, useMemo } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import { Dropdown, DropDownProps, SelectOption } from '../../../../../atoms';
import { Any } from '../../../../../types';
import { toArray } from '../../../../../utils';

interface UserData {
  label: string;
  isSelected?: boolean;
}

const otherProps = {
  isMulti: true,
  hideSelectedOptions: false,
  isClearable: false,
  backspaceRemovesValue: false,
  closeMenuOnSelect: false,
};

function formatValues(values: Readonly<Any[]>): string {
  const _values = values.slice();
  const label = _values
    .splice(0, 2)
    .map((op) => op.label)
    .join(', ');
  if (_values.length) {
    return label.concat(` +${_values.length} more`);
  }
  return label;
}

const UserComponent: FC<UserData> = ({ label, isSelected }) => {
  return (
    <Flex gap={2} fontSize='14px' fontWeight='500'>
      <Checkbox isChecked={isSelected} isReadOnly />
      {label}
    </Flex>
  );
};

interface IProps
  extends Omit<DropDownProps<SelectOption<string>>, 'value' | 'onChange'> {
  value?: string[];
  onChange?: (value: string[]) => void;
}

const AuditorUserSelect = forwardRef<never, IProps>((props, ref) => {
  const { options, value, onChange, ...rest } = props;

  const _value = useMemo(() => {
    return options?.filter((op) => value?.includes(op.value)) as Any;
  }, [options, value]);

  return (
    <Dropdown
      ref={ref}
      {...rest}
      size='lg'
      value={_value}
      {...otherProps}
      onChange={(newValue) => {
        return onChange?.(
          toArray<SelectOption>(newValue).map((op) => op.value)
        );
      }}
      options={options}
      placeholder='Select auditor'
      inputStyle={{
        borderWidth: '2px',
        borderRadius: '12px',
        outline: 'none',
        backgroundColor: 'white',
      }}
      components={{
        MultiValue: (valProps) => {
          if (valProps.index === 0) {
            return (
              <Box as='span' fontSize='14px' fontWeight='500' color='#272B30'>
                {formatValues(valProps.getValue())}
              </Box>
            );
          }
          return <React.Fragment />;
        },
        SingleValue: (valProps) => {
          return (
            <chakraComponents.SingleValue {...valProps}>
              {/*// @ts-ignore */}
              <UserComponent {...valProps.data} />
            </chakraComponents.SingleValue>
          );
        },
        Option: (optionProps) => {
          return (
            <chakraComponents.Option {...optionProps} isSelected={false}>
              {/*// @ts-ignore */}
              <UserComponent
                {...optionProps.data}
                isSelected={optionProps.isSelected}
              />
            </chakraComponents.Option>
          );
        },
      }}
    />
  );
});

AuditorUserSelect.displayName = 'AuditorUserSelect';

export default AuditorUserSelect;
