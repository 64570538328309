import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../ui-components';
import { LAUNCHER_OPENING_TASKS } from '../../../../../appRoutes';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';

import { useLocalChangesCount } from '../../common';
import { selected } from '../helper';
import { EditorEvent, useEditorContext } from '../context';
import { IFormInput } from '../editor.types';

interface IProps {
  isLastSection?: boolean;
}

const EditorBottomAction: FC<IProps> = ({ isLastSection }) => {
  const { t } = useTranslation('launcher');
  const history = useHistory();
  const { getValues, setValue } = useFormContext<IFormInput>();
  const { emit } = useEditorContext();
  const changes = useLocalChangesCount();

  const onSaveClick = async () => {
    const [contents, index] = getValues(['contents', 'activeStep']);

    const maxLength = contents.filter(selected)?.length;

    if (maxLength <= index + 1 || changes) {
      try {
        // await emit(EditorEvent.VALIDATE_FINISH);
        await emit(EditorEvent.PUBLISH_CONFIG);
        deployEvent(AmplitudeEvent.LAUNCHER_TASK_PUBLISH, {
          view: 'Editor mode',
        });
        history.replace(LAUNCHER_OPENING_TASKS);
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } catch (err) {
        console.log('something went wrong');
      }
    } else {
      await emit(EditorEvent.VALIDATE_ACTIVE_STEP, index);
      await emit(EditorEvent.SAVE_CHECKLIST);
      setValue('activeStep', index + 1);
    }
  };

  return (
    <Flex gap={8}>
      <Box minW='40px' />
      <ActionButton
        flex={1}
        size='lg'
        colorScheme='blue'
        fontSize='15px'
        actionFn={onSaveClick}
      >
        <Trans
          t={t}
          i18nKey={
            changes
              ? 'publishLauncherChanges'
              : isLastSection
              ? 'saveAndPublishTasks'
              : 'moveToNextCheckList'
          }
          count={changes}
          values={{ value: changes }}
        />
      </ActionButton>
    </Flex>
  );
};

export default EditorBottomAction;
