import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller } from 'react-hook-form';
import { Any } from '../../../../../types';
import { QuestionsState, SectionEntity } from '../../types';
import { ImageUpload } from '../question-types';
import { AuditTake } from '../../tabke-audit.helpers';

interface IProps {
  question: QuestionsState;
  sectionIndex: number;
  questionIndex: number;
}

const ImageUploadSection: FC<IProps> = ({
  question,
  sectionIndex,
  questionIndex,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveHandler = (...args: Any) => {
    AuditTake.save(`data.${questionIndex}`, question.eid);
  };

  return (
    <Controller<SectionEntity, `data.${number}.responseUrl`>
      name={`data.${questionIndex}.responseUrl`}
      rules={{
        required: question?.isRequired ? 'This field is required' : undefined,
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <ImageUpload
            {...field}
            onChange={callAll(field.onChange, saveHandler)}
          />

          <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default ImageUploadSection;
