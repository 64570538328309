import React, { FC, useCallback } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { AppRoute, useHistory } from '../../../routes';
import { HeaderColors } from '../../../configs';
import { useLatest } from '../../../hooks';

import { useAuditConfirmation } from '../schedule/audit-settings';

// @ts-ignore
import { ReactComponent as EditIcon } from '../../../assets/images/edit-dark.svg';

const Content: FC = () => {
  return (
    <Center flexDir='column' gap={4} pb={2}>
      <Center boxSize='56px' bg='#FF6A5533' borderRadius='full'>
        <EditIcon color='#FF6A55' />
      </Center>
      <Box color='#33383F' textAlign='center' paddingX={3}>
        Editing audit questions will impact future audits, but it will also{' '}
        <strong>reset ongoing audits</strong>, causing{' '}
        <strong>progress to be lost </strong> and requiring the{' '}
        <strong>audit process to be restarted.</strong>
      </Box>
    </Center>
  );
};

export const useEditQuestionWarning = (auditId: string): (() => void) => {
  const history = useHistory();

  const confirmation = useAuditConfirmation();

  const historyPushRef = useLatest(() => {
    history.push({
      pathname: AppRoute.AUDIT_QUESTIONS_EDIT,
      params: {
        auditId: auditId,
      },
    });
  });

  return useCallback(() => {
    confirmation({
      title: 'Edit audit?',
      headerColor: HeaderColors.Orange,
      content: <Content />,
      width: '540px',
      buttonText: 'Continue',
      onConfirm: () => historyPushRef.current?.(),
    });
  }, []);
};
